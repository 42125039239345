import React from "react";
import { Link } from "react-router-dom";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      height: "calc(100vh - 66px)",
      marginLeft: "100px",
      marginTop: "100px",
    },
    headerTitle: {
      marginTop: "20px",
    },
  })
);

export default function NotFound(props: { path?: string; default: boolean }) {
  const classes = useStyles();
  return (
    <div className={classes.paper}>
      <Typography component="h4" variant="h4" color="primary">
        <Link to="/app/lists">&larr; Go to homepage</Link>
      </Typography>
      <Typography
        component="h1"
        variant="h1"
        className={classes.headerTitle}
      >
        Page Not Found
      </Typography>
    </div>
  );
}
