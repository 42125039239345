import React, { useEffect, useRef } from "react"
import { Virtuoso } from "react-virtuoso";

const InnerItem = React.memo(({ children }: any) => {
  return <>{children}</>;
});

const getListHeight = (length: any) => {
  return length < 8 ? length * 38 : 8 * 38;
};

const VirtualizedMenuList = ({ options, children, getValue, ...rest }: any) => {
  const virtuosoRef = useRef<any>(null);
  const [option] = getValue();

  useEffect(() => {
    if (virtuosoRef?.current) {
      let selectedOptionIndex = 0;

      if (option) {
        selectedOptionIndex = options.findIndex(
          (item: any) => item.value === option.value
        );
      }

      virtuosoRef.current.scrollToIndex({
        index: selectedOptionIndex,
        align: "start",
        behavior: "auto"
      });
    }
  }, [children, virtuosoRef, options, option]);

  return (
    <Virtuoso
      // ref={virtuosoRef}
      overscan={{ main: 80, reverse: 80 }}
      style={{ height: `${getListHeight(children.length)}px` }}
      totalCount={children.length}
      itemContent={(index: any) => <InnerItem children={children[index]} />}
    />
  )
}

export default VirtualizedMenuList;