import axios from 'axios';
import idx from 'idx';
import { getCSRF } from '../helpers/helpers';

const ROUTES: any = {
  validate: '/connector/qbo/valid',
  callback: '/connector/qbo/callback'
};

// I don't think validate is used
export const qboConnector: any = {
  validate: async (taskId: any) => {
    const response = await axios.get(ROUTES.validate, {
      headers: {
        'X-CSRF-Token': getCSRF()
      },
      params: {
        task_id: taskId
      }
    });
    return idx(response, (r: any) => r.data.status);
  },
  callback: async (urlParams: any) => {
    const response = await axios.get(ROUTES.callback, {
      headers: {
        'X-CSRF-Token': getCSRF()
      },
      params: {
        code: urlParams.get('code'),
        realmId: urlParams.get('realmId'),
        state: urlParams.get('state')
      }
    });
    return idx(response, (r: any) => r.data.success);
  }
}
