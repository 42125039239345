import React, { useState, useEffect, useRef } from 'react';
import idx from 'idx';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Dropdown from '../../../../../../../common/Dropdown';
import { Skeleton } from '../../../../../../../common/Skeleton';
import { useReconSupportTasks } from '../../../../../../../../graphql/queries/ReconSupportTasks';
import { useReconFilesForTask } from '../../../../../../../../graphql/queries/ReconFilesForTask';
import { useLinkFileReconSupport } from '../../../../../../../../graphql/mutations/LinkFileReconSupport';

import { ReconSourceType } from '../ReconciliationSection';
import { IntegrationInfoReconPopper } from './IntegrationInfoReconPopper';
import MultiFileReconPopper from './MultiFileReconPopper';

const useLocalStyles = makeStyles(() =>
  createStyles({
    fullWidth: { width: '100%', height: '32px', marginTop: '10px' },
    container: {
      padding: '0 16px 10px 16px',
      border: '1px solid lightgrey',
      overflow: 'auto'
    }
  })
);

interface TaskReconSupportProps {
  sourceType: ReconSourceType;
  task: any;
  updateTask: (task: any) => void;
  updateTaskInCache: any;
  updateTaskStatus: (task: any, status: string, isAutomation: boolean) => void;
  tasks: any;
  allTasks: any;
  setTasks: any;
  lists: any;
  updatePrimaryDisplaySkeletons: any;
  updateSecondaryDisplaySkeletons: any;
  updateReconcilingItemsDisplaySkeletons: any;
  toggleReconcilingItems?: any;
  updateToggleReconcilingItems?: any;
  displaySkeleton?: any;
  onPopoverLeave?: any;
}

/**
 * The link to task input should always be empty unless the user is interacting with it
 * i.e : Selecting a task (that value is maintained by the FileSelectionSection component internally)
 */

export function TaskReconSupport({
  task,
  sourceType,
  updateTask,
  updateTaskInCache,
  updateTaskStatus,
  tasks,
  allTasks,
  setTasks,
  lists,
  updatePrimaryDisplaySkeletons,
  updateSecondaryDisplaySkeletons,
  updateReconcilingItemsDisplaySkeletons,
  displaySkeleton,
  onPopoverLeave,
}: TaskReconSupportProps) {
  const [linkFileReconParams, setLinkFileReconParams] = useState<Object | null>(null);
  const [linkFileReconSupportMutation, linkFileReconSupportMutationResponse] = useLinkFileReconSupport(linkFileReconParams);
  const headerText = task[sourceType + 'Label'] + ' Source'
  const source = task[`${sourceType}Source`];
  // intacct or qbo
  const openIntegrationPopper = task[`${sourceType}SourceType`] === 3 || task[`${sourceType}SourceType`] === 5 || task[`${sourceType}SourceType`] === 6;
  const skeletonActive = (!task.hasOwnProperty('primaryLabel') || displaySkeleton)

  function updateSourceFileDetails(fileVersionId: string, taskId: string) {
    setLinkFileReconParams({
      linkedFileVersionId: fileVersionId,
      linkedTaskId: taskId,
      currentTaskId: task.id,
      sourceType: sourceType
    })
  }

  useEffect(() => {
    if (linkFileReconParams) {
      linkFileReconSupportMutation();

      if (sourceType === 'primary') {
        updatePrimaryDisplaySkeletons(false);
      } else if (sourceType === 'secondary') {
        updateSecondaryDisplaySkeletons(false)
      } else {
        updateReconcilingItemsDisplaySkeletons(false)
      }
    }
  }, [linkFileReconParams, task, sourceType, task.primaryTotal]);

  useEffect(() => {
    if (!linkFileReconSupportMutationResponse.loading && linkFileReconSupportMutationResponse.called && linkFileReconSupportMutationResponse.data) {
      // Async code updates still needed

      const returnedData = linkFileReconSupportMutationResponse.data as any;

      if (returnedData.linkFileReconSupport && returnedData.linkFileReconSupport.success) {
        const returnedTaskData = returnedData.linkFileReconSupport.task;
        updateTaskInCache({
          ...task,
          primaryTotal: returnedTaskData.primaryTotal,
          secondaryTotal: returnedTaskData.secondaryTotal,
          primaryFileReconSources: returnedTaskData.primaryFileReconSources,
          primaryFileReconSourcesTotal: returnedTaskData.primaryFileReconSourcesTotal,
          secondaryFileReconSources: returnedTaskData.secondaryFileReconSources,
          secondaryFileReconSourcesTotal: returnedTaskData.secondaryFileReconSourcesTotal,
          reconcilingItems: returnedTaskData.reconcilingItems,
          reconcilingItemTotal: returnedTaskData.reconcilingItemTotal,
          isReconciled: returnedTaskData.isReconciled,
          reconDifference: returnedTaskData.reconDifference
        });

        updateTask({
          ...task,
          primaryTotal: returnedTaskData.primaryTotal,
          secondaryTotal: returnedTaskData.secondaryTotal,
          primaryFileReconSources: returnedTaskData.primaryFileReconSources,
          primaryFileReconSourcesTotal: returnedTaskData.primaryFileReconSourcesTotal,
          secondaryFileReconSources: returnedTaskData.secondaryFileReconSources,
          secondaryFileReconSourcesTotal: returnedTaskData.secondaryFileReconSourcesTotal,
          reconcilingItems: returnedTaskData.reconcilingItems,
          reconcilingItemTotal: returnedTaskData.reconcilingItemTotal,
          isReconciled: returnedTaskData.isReconciled,
          reconDifference: returnedTaskData.reconDifference
        });
      }

      if (sourceType === 'primary') {
        updatePrimaryDisplaySkeletons(false);
      } else if (sourceType === 'secondary') {
        updateSecondaryDisplaySkeletons(false)
      } else {
        updateReconcilingItemsDisplaySkeletons(false)
      }
    }

  }, [linkFileReconSupportMutationResponse.loading, linkFileReconSupportMutationResponse.called, linkFileReconSupportMutationResponse.data])

  return (
    <div className="p-4 pt-0">
      <Header
        headerText={headerText}
        skeletonActive={displaySkeleton}
      />
      {openIntegrationPopper ? (
        <IntegrationInfoReconPopper
          task={task}
          updateTask={updateTask}
          updateTaskInCache={updateTaskInCache}
          updateTaskStatus={updateTaskStatus}
          sourceType={sourceType}
          erpSourceType={task[`${sourceType}SourceType`]}
          project={lists}
          updatePrimaryDisplaySkeletons={updatePrimaryDisplaySkeletons}
          updateSecondaryDisplaySkeletons={updateSecondaryDisplaySkeletons}
          onPopoverLeave={onPopoverLeave}
        />
      ) : (
        <MultiFileReconPopper
          FileSelectionSection={FileSelectionSection}
          allTasks={allTasks}
          setTasks={setTasks}
          source={source}
          sourceType={sourceType}
          task={task}
          tasks={tasks}
          updatePrimaryDisplaySkeletons={updatePrimaryDisplaySkeletons}
          updateSecondaryDisplaySkeletons={updateSecondaryDisplaySkeletons}
          updateSourceFileDetails={updateSourceFileDetails}
          updateTask={updateTask}
          updateTaskInCache={updateTaskInCache}
          updateTaskStatus={updateTaskStatus}
        />
      )}
    </div>
  );
}

function Header({ headerText, skeletonActive }: any) {
  return (
    <p className='py-4 text-xs font-bold text-gray-400 capitalize border-t border-gray-200'>
      {skeletonActive ? (
        <div className="flex items-center">
          <Skeleton
            isLoaded={skeletonActive}
            width="100px"
            style={{ marginRight: "5px" }}
          />
          <span> Support</span>
        </div>
      ) : `${headerText} Support`}
    </p>
  );
}

const SELECT_LABEL_WIDTH = 90;

/** Displays 2 dropdowns */
function FileSelectionSection({ updateSourceFileDetails, task }: any) {
  const [selectedSourceTaskId, setSelectedSourceTaskId] = useState<string>('');

  const selectedSourceTaskIdRef = useRef('');
  const showFileDropdown = selectedSourceTaskId !== '';

  const selectedSourceFileId = '';

  function handleSelectSourceTask(taskId: string) {
    setSelectedSourceTaskId(taskId);
    selectedSourceTaskIdRef.current = taskId;
  }

  const handleFileSelect = (file: any) => {
    updateSourceFileDetails(file.id, selectedSourceTaskIdRef.current);

    setSelectedSourceTaskId('');
    selectedSourceTaskIdRef.current = '';
  }

  return (
    <>
      <TaskSelect
        handleSelectSourceTask={handleSelectSourceTask}
        selectedSourceTaskId={selectedSourceTaskId}
        task={task}
      />
      {
        showFileDropdown &&
        <FileSelect
          handleFileSelect={handleFileSelect}
          selectedSourceFileId={selectedSourceFileId}
          selectedSourceTaskId={selectedSourceTaskId}
        />
      }
    </>
  );
}

interface TaskSelectProps {
  handleSelectSourceTask: (taskId: string) => void;
  selectedSourceTaskId: string;
  task: any
}
function TaskSelect({
  handleSelectSourceTask,
  selectedSourceTaskId,
  task
}: TaskSelectProps) {
  const listId = idx(task, (t: any) => t.list.id)
  const { data: tasksData } = useReconSupportTasks({ listId: listId });
  const tasks = idx(tasksData, (td: any) => td.list.tasks) || [];
  const taskOptions = tasks.sort(byListNumber)
    .map(asOptionsWithListNumber);

  const localStyles = useLocalStyles();
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: 6, marginBottom: 6 }}>
      <Dropdown
        classes={localStyles.fullWidth}
        ellipsis
        goneSoon
        handleUpdate={handleSelectSourceTask}
        options={taskOptions}
        placeholder="Link to files from another task"
        selected={selectedSourceTaskId}
        small
        textLength={1_000_000}
      />
    </div>
  );
}

interface FileSelectPops {
  handleFileSelect: (file: any) => void;
  selectedSourceFileId: string;
  selectedSourceTaskId: string;
}

function FileSelect({
  handleFileSelect,
  selectedSourceTaskId,
  selectedSourceFileId
}: FileSelectPops) {
  const { data: filesData } = useReconFilesForTask({
    taskId: selectedSourceTaskId
  });
  const fileOptions = idx(filesData, (fd: any) =>
    fd.task.files.map(withFileNames)
  ) || [];
  const selectedSourceTaskHasFiles = fileOptions.length;

  function handleFileSelection(fileId: string) {
    const fullFileVersionData = idx(filesData, (f: any) =>
      f.task.files.find((file: any) => file.id === fileId)
    );

    handleFileSelect(fullFileVersionData)
  }

  const localStyles = useLocalStyles();
  return (
    selectedSourceTaskHasFiles
      ? <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          style={{ width: SELECT_LABEL_WIDTH }}
        >
          Link to file:
        </Typography>
        <Dropdown
          textLength={1_000_000}
          goneSoon
          classes={localStyles.fullWidth}
          handleUpdate={handleFileSelection}
          options={fileOptions}
          selected={selectedSourceFileId}
          small
        />
      </div>
      : <Typography variant='h6'>
        No files exist for this task.
      </Typography>
  );
}

/** utilities for sorting && mapping */
function byListNumber(firstTask: any, secondTask: any) {
  return (Number(firstTask.listNumber) - Number(secondTask.listNumber));
}

function asOptionsWithListNumber(task: any) {
  return ({
    id: task.id,
    name: `${task.listNumber} - ${task.name}`
  })
}

function withFileNames(file: any) {
  return ({
    id: file.id,
    name: file.fileName
  });
}
