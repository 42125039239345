import { gql} from '../graphqlHelpers';

type ID = number | string;

export interface RemoveFileVariables {
  fileVersionId: ID;
  deleteForAll?: boolean;
}

export interface Error {
  path: string;
  message: string;
}

export interface RemoveFileData {
  success: boolean;
  errors: Error[];
}

export const removeFile = gql `
  mutation removeFile($fileVersionId: ID!, $deleteForAll: Boolean){
    removeFile(fileVersionId: $fileVersionId, deleteForAll: $deleteForAll){
      success
      errors{
        path
        message
      }
    }
  }`;