import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { createStyles, Button, DialogContentText, makeStyles, Theme, Typography } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import ProjectTypesStep from './components/ProjectTypesStep';
import ProjectTaskDeatilsReview from './components/ProjectTaskDeatilsReview';
import ProjectDetailsStep from './components/ProjectDetailsStep';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import Footer from './components/Footer';
import Header from './components/Header';
import { useDispatchContext, useStateContext } from '../../../store';
import { ClassNameMap } from '@material-ui/styles';
import { IProjectNumber, IProjectType, ISteps, IProjectOptionsSelected, IProject, INewCompanyType, ITableHeader, ITask } from './components/common/interfaces';
import idx from 'idx';
import { useTemplateLists } from '../../../graphql/queries/TemplateLists';
import { useCreateList } from '../../../graphql/mutations/CreateList';
import AlertModal from './components/common/AlertModal';
import UpdateList from '../../../graphql/mutations/UpdateList';
import CircularLoader from '../../common/CircularLoader';
import { useSetFilters } from '../../../hooks/SetFilters';
import { TaskNameFilterPopper } from '../../task/TaskPage/components/TaskNameFilterPopper';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		flex: {
			display: 'flex',
		},
		container: {
			height: '100%',
			width: '100%',
		},
		screen: {
			height: '100vh',
			width: '100vw',
		},
		column: {
			flexDirection: 'column',
		},
		row: {
			flexDirection: 'row',
		},
		projectIcons: {
			height: 144,
			width: 144,
			color: '#C3DAFF'
		},
		t2: {
			fontSize: "18px",
			lineHeight: "22px",
			color: "#707070",
			opacity: 1,
			fontWeight: "bold",
		},
		dialogText: {
			fontSize: '12px',
			lineHeight: '15px',
			color: '#606060',
			opacity: 1,
			fontWeight: 'normal',
		},
		dialogButton: {
			width: '200px',
			fontWeight: 'bold',
			borderRadius: '2px',
			paddingTop: '12px',
			paddingBottom: '12px',
		},
		spinner: {
			position: 'absolute',
			zIndex: 1301,
			background: 'rgba(255, 255, 255, .8)',
			display: 'flex',
			top: '0',
			left: '0',
			width: '100%',
			height: '100%',
			alignContent: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
		}
	})
);

interface CreateProjectPageProps {
	isOnlyImportTasks?: boolean;
	currentList?: any;
}
interface CompanyModule {
  name: string;
  url: string;
  __typename: string;
}

function getStepperLabels() {
	return ['Type', 'Tasks', 'Review', 'Details'];
}

function getProjectNumberOptions(classes: ClassNameMap<string>) {
	var options: IProjectNumber = {
		single: {
			header: <Typography data-cy='single' className={classes.t2} style={{ marginTop: 10 }}>One Project</Typography>,
			value: 'single',
			icon: <ListAltIcon className={classes.projectIcons} />
		},
		multiple: {
			header: <Typography data-cy='multiple' className={classes.t2} style={{ marginTop: 23 }}>Multiple Projects</Typography>,
			value: 'multiple',
			icon: <FilterNoneIcon className={classes.projectIcons} style={{ marginTop: '13px', height: '118px', width: '118px' }} />
		}
	}
	return options;
}

function getProjectTypeOptions(isAdmin: boolean, classes: ClassNameMap<string>) {
	var options: IProjectType = {
		internal: {
			header: <Typography data-cy='internal' className={classes.t2}>Internal</Typography>,
			value: 'internal',
			icon: <PersonOutlineOutlinedIcon className={classes.projectIcons} />,
			description1: 'Collaborate within your company.',
			description2: 'Use this setting when sharing information with your colleagues.'
		},
		request: {
			header: <Typography data-cy='issue' className={classes.t2}>Request</Typography>,
			value: 'issue',
			icon: <PeopleAltIcon className={classes.projectIcons} />,
			description1: 'Request information to an external party.',
			description2: 'Use this setting when another party has the primary responsibility for providing the information in a project.'
		}
	};
	if (isAdmin) {
		options.share = {
			header: <Typography data-cy='share' className={classes.t2}>Share</Typography>,
			value: 'share',
			icon: <PeopleAltOutlinedIcon className={classes.projectIcons} />,
			description1: 'Send information to an external party.',
			description2: 'Use this setting when your company has the primary responsibility for providing the information in a project.'
		};
	}
	return options;
}

function allHeadersOptions(): ITableHeader[] {
	return [
		{ label: 'Task', value: 'name', width: '450px', optional: false },
		{ label: 'Section', value: 'section', width: '240px', optional: false },
		{ label: 'Priority', value: 'priority', width: '140px', optional: false },
		{ label: 'Description', value: 'description', width: '250px', optional: true },
		{ label: 'Entity', value: 'tags', width: '250px', optional: true },
		{ label: 'Calendar Due Date', value: 'dueDate', width: '200px', optional: true },
		{ label: 'Relative Due Date', value: 'relativeDueDate', width: '200px', optional: true },
		{ label: 'Calendar Start Date', value: 'startDate', width: '210px', optional: true },
		{ label: 'Relative Start Date', value: 'relativeStartDate', width: '200px', optional: true },
		{ label: 'Dependencies', value: 'dependencies', width: '350px', optional: true },
		{ label: 'Internal Task Owner', value: 'userOwners', width: '250px', optional: true },
		{ label: 'Internal Task Reviewer', value: 'userReviewers', width: '250px', optional: true },
		{ label: 'External Task Owner', value: 'externalUserOwners', width: '250px', optional: true },
		{ label: 'External Task Reviewer', value: 'externalUserReviewers', width: '250px', optional: true },
	];
}

const CreateProjectPage: React.FC<CreateProjectPageProps & RouteComponentProps> = (props) => {
	const submitTitle = 'Create Project';
	const classes = useStyles();
	const dispatch = useDispatchContext();
	const state = useStateContext();
	const setFilters = useSetFilters();
	const history = useHistory();
	const isAdmin = state.currentUser.isAdmin;
	
	// Props definition
	const { isOnlyImportTasks, currentList } = props;
	
	const hasDashboardAccess: CompanyModule = state.currentUser.companyModules.find(
		(companyModule: CompanyModule) => companyModule.name === 'DashboardHome'
	);

	const projectNumberOptions = getProjectNumberOptions(classes);
	const projectTypeOptions = getProjectTypeOptions(isAdmin, classes);
	const stepperLabels = getStepperLabels();
	const allTableHeaderOptions = allHeadersOptions();
	const [displayedHeaders, setDisplayedHeaders] = useState<ITableHeader[]>([...allTableHeaderOptions.slice(0, 4)]);
	const [showModal, setShowModal] = useState(false);
	const [selectedTasksIds, setSelectedTasksIds] = useState<string[]>([]);
	const [dialogToDisplay, setDialogToDisplay] = useState<string>('');
	const [additionalInternalOwners, setAdditionalInternalOwners] = useState<any[]>([]);
	const [additionalExternalOwners, setAdditionalExternalOwners] = useState<any[]>([]);


	const [steps, setSteps] = useState<ISteps>({
		activeStep: 0,
		activeSubStep: 0,
	})

	const [projectOptionsSelected, setProjectOptionsSelected] = useState<IProjectOptionsSelected | any>({
		projectNumber: undefined,
		projectType: undefined,
	})

	const [inviteCompany, setInviteCompany] = useState<INewCompanyType>({
		newCompanyName: '',
		ownerEmail: '',
		companyId: '',
	});

	// The attributes for the new List
	// the type of this is list attributes
	const [newList, setNewList] = useState<IProject>({
		id: '',
		name: '',
		description: '',
		isVolatile: true,
		requester: { id: state.selectedCompany },
		scheduleType: null,
		reconciliations: '0',
		userCompanyOwners: [{
			__typename: 'User',
			id: state.currentUser.id,
			email: state.currentUser.email,
			fullName: state.currentUser.fullName,
			profileUrl: state.currentUser.profileUrl,
		}]
	});

	const externalCompanyId =
		idx(newList, l => {
			if (projectOptionsSelected.projectType == projectTypeOptions.request.value) {
				return l.responder.id;
			} else {
				return l.requester.id;
			}
		}) || 'a';

	const [selectedTemplate, setSelectedTemplate] = useState<any>({
		__typename: 'List',
		id: '',
		name: '',
		labelName: '',
		tasks: [],
	});

	function parseOwners(owners: any[]) {
		const finalOwners = owners.map((owner: any) => {
			const tempOwner : any= {};
			if(owner.hasOwnProperty('id')) tempOwner.id = owner.id;
			if(owner.hasOwnProperty('name')) tempOwner.name = owner.name;
			if(owner.hasOwnProperty('fullName')) tempOwner.fullName = owner.fullName;
			if(owner.hasOwnProperty('displayName')) tempOwner.displayName = owner.displayName;
			if(owner.hasOwnProperty('email')) tempOwner.email = owner.email;
			if(owner.hasOwnProperty('reviewer')) tempOwner.reviewer = owner.reviewer;
			if(owner.hasOwnProperty('profileUrl')) tempOwner.profileUrl = owner.profileUrl;
			if(owner.hasOwnProperty('__typename')) tempOwner.__typename = owner.__typename;
			return tempOwner;
		});
		return finalOwners;
	}

	function getTaskDetails(task: any) {
		return {
			__typename: 'Task',
			id: task.id.toString(),
			name: String(task.name),
			section: task.section && (task.section.name || task.section.name === '') ? task.section.name : task.section,
			description: task.description ? String(task.description) : '',
			priority: task.priority,
			status: task.status,
			tags: task.tags,
			dueDate: task.dueDate ? task.dueDate : null,
			startDate: task.startDate ? task.startDate : null,
			relativeDueDate: task.relativeDueDate,
			relativeStartDate: task.relativeStartDate,
			isVolatile: true,
			list: { id: task.list.id.toString(), name: task.list.name, __typename: "List" },
			listNumber: parseInt(task.listNumber),
			userOwners: task.userOwners ? parseOwners(task.userOwners) : [],
			teamOwners: task.teamOwners ? parseOwners(task.teamOwners) : [],
			userReviewers: task.userReviewers ? parseOwners(task.userReviewers) : [],
			teamReviewers: task.teamReviewers ? parseOwners(task.teamReviewers) : [],
			externalUserOwners: task.externalUserOwners ? parseOwners(task.externalUserOwners) : [],
			externalTeamOwners: task.externalTeamOwners ? parseOwners(task.externalTeamOwners) : [],
			externalUserReviewers: task.externalUserReviewers ? parseOwners(task.externalUserReviewers) : [],
			externalTeamReviewers: task.externalTeamReviewers ? parseOwners(task.externalTeamReviewers) : []
		} as any;
	}

	function filterTasks() {
		let tasks = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		let selectedIds = [...selectedTasksIds];
		const tasks2 = tasks.filter((t: ITask) => t.name)
			.filter((t: ITask) => selectedIds.indexOf(t.id) !== -1)
			.map((t: any) => ({ ...getTaskDetails(t) }));
		return tasks2;
	}

	function filterList() {
		let finalList = JSON.parse(JSON.stringify(newList));
		if ((projectOptionsSelected.projectType === projectTypeOptions.internal.value) && idx(finalList, l => l.otherCompanyOwners.length > 0)) {
			delete finalList.otherCompanyOwners;
		}

		if ((projectOptionsSelected.projectType !== 'share') && finalList.deliverFileOnUpload !== undefined) {
			delete finalList.deliverFileOnUpload;
		}

		if(!finalList?.name){
			finalList.name = 'Empty Project Name'
		}
		return finalList;
	}

	// templates get loaded up front
	const { loading, data, error, fetchMore } = useTemplateLists({});
	const templateLists = idx(data, data => data.templateLists) || [];

	// final step to create a project
	const [createList, createListResponse] : any = useCreateList({
		listAttributes: filterList(),
		tasks: filterTasks()
	});

	const [updateList, { loading: updateLoading, data: updateData, error: updateError }] = UpdateList({ list: parseList(newList) });

	// update list mutation
	function parseList(list: any) {
		var newList: any = {
			id: list.id,
			name: list.name,
			description: list.description,
			retentionPolicyId: list.retentionPolicyId ? list.retentionPolicyId : null,
			reconciliations: list.reconciliations ? list.reconciliations : '0',
		}
		if (list.scheduleType) newList.scheduleType = list.scheduleType;
		if (list.customSchedule) newList.customSchedule = list.customSchedule;
		if (list.estimatedStartDate) newList.estimatedStartDate = list.estimatedStartDate;
		if (list.estimatedEndDate) newList.estimatedEndDate = list.estimatedEndDate;
		if (list.deliverFileOnUpload !== undefined) newList.deliverFileOnUpload = list.deliverFileOnUpload;
		if (list.labels) newList.labels = list.labels;
		return newList;
	}

	const createLoading = idx(createListResponse, r => r.loading) || false;
	const createSuccess = idx(createListResponse, r => (r as any).data.createList.success);
	const copySuccess = idx(createListResponse, r => (r as any).data.copyList.success);
	const createError = idx(createListResponse, r => (r as any).error);

	// When the list name changes
	useEffect(() => {
		if (selectedTemplate.name !== newList.name) {
			setNewList({ ...newList, name: selectedTemplate.name as string });
			setSelectedTemplate({ ...selectedTemplate, tasks: selectedTemplate.tasks.map((t: any) => { t.list.name = selectedTemplate.name as string; return t }) });
		}
	}, [selectedTemplate.name]);

	useEffect(() => {
		if (selectedTemplate.name !== newList.name) {
			setSelectedTemplate({ ...selectedTemplate, name: newList.name as string, tasks: selectedTemplate.tasks.map((t: any) => { t.list.name = newList.name as string; return t }) });
		}
	}, [newList.name]);

	useEffect(() => {
		if (Boolean(newList.id)) {
			updateList();
		}
	}, [newList]);

	useEffect(() => {
		if (isOnlyImportTasks) {
			setSteps({
				activeStep: 0,
				activeSubStep: 0,
			});
		}
	}, [isOnlyImportTasks])

	useEffect(() => {
		if (currentList) {
			const formatCurrentList = {
				id: currentList.id,
				name: currentList.name,
				description: currentList.description,
				isVolatile: currentList.isVolatile,
				requester: { id: currentList.requesterCompany?.id },
				scheduleType: currentList.schedule?.repeatType,
				reconciliations: currentList.reconciliations,
				userCompanyOwners: currentList.owners,
		  }
		  setNewList(formatCurrentList);
		  setSelectedTemplate({...selectedTemplate, id: currentList.id, name: currentList.name})
		}
	
	}, [currentList]);

	// When a new list has been created
	useEffect(() => {
		setNewList({ ...newList, id: selectedTemplate.id as string, name: selectedTemplate.name as string });
	}, [selectedTemplate.id]);

	useEffect(() => {
		if (!projectOptionsSelected.projectType) return;
		handleChangeSharing(projectOptionsSelected.projectType)
	}, [projectOptionsSelected.projectType])

	useEffect(() => {
		if (!loading && steps.activeStep === 0 && steps.activeSubStep === 2) {
			loadMore();
		}
	}, [steps]);

	useEffect(() => {
		const lists = idx(data, data => data.templateLists);
		if (loading || !lists) return;
	}, [loading, idx(data, data => data.templateLists)]);

	useEffect(() => {
		if (createSuccess || copySuccess) {

			//Update the user lists for the project selection dropdown
			const userListsCopy = state.userLists.slice();
			const newList = Object.assign({}, createListResponse?.data?.createList?.list as any);
			userListsCopy.push(newList);
			dispatch({
				type: 'SET_USER_LISTS',
				userLists: userListsCopy
			})

			dispatch({
				type: 'SET_NOTIFICATION',
				notification: {
					variant: 'success',
					message: 'Project created successfully',
				},
			});
			if (projectOptionsSelected.projectNumber === projectNumberOptions.multiple.value) {
				return;
			}

			setFilters(
				{
					listPageSelectedLists: [newList.id],
					taskPageLists: [newList.id],
					listPagePane: 0,
				},
				{ defaultTaskFilters: true }
			);

			//Same issue here as the recon summary page
			dispatch({
				type: 'UPDATE_PROJECT_SELECTION_OPTONS',
				selectedListIds: [newList.id],
				selectedSectionIds: null
			})

			// The redirect for users of nexdd is different than non-users so this
			// redirects depending on their access level.
			if(hasDashboardAccess) {
				dispatch({
					type: 'SET_TASK_PAGE_GROUPING',
					taskPageGrouping: state.currentUser.filters.taskPageGroupBySort,
				});
				setTimeout(() => history.push('/app/newtasks'), 250);
			} else {
				setTimeout(() => history.push('/app/lists'), 250);
			}
		}
		if (createError) {
			dispatch({
				type: 'SET_NOTIFICATION',
				notification: {
					variant: 'error',
					message: 'Error occurred while creating the project',
				},
			});
		}
	}, [createListResponse.called, createListResponse.loading, createListResponse.data]);

	const loadMore = () => {
		fetchMore({
			variables: {},
			updateQuery: (
				previousResult: any,
				options: {
					fetchMoreResult?: any;
					variables?: {};
				}
			) => {
				const fetchMoreResult = idx(
					options,
					options => options.fetchMoreResult
				);

				if (!fetchMoreResult) return previousResult;

				return {
					templateLists: [...fetchMoreResult.templateLists],
				};
			},
		});
	};

	function nextDisabled() {
		if (steps.activeStep === 0 && steps.activeSubStep === 0 && !Boolean(projectOptionsSelected.projectNumber)) {
			return true;
		}
		if ((steps.activeStep === 0 && steps.activeSubStep === 1) || (steps.activeStep === 3 && steps.activeSubStep === 0)) {
			if (!projectOptionsSelected.projectType) {
				return true;
			}
			if (projectOptionsSelected.projectType !== projectTypeOptions.internal.value) {
				if (!Boolean(idx(newList, (list) => list.otherCompanyOwners.length > 0))) {
					return true;
				}
			}
		}
		return false;
	}


	function handleNext() {
		if (steps.activeStep === 0 && steps.activeSubStep !== 2) {
			setSteps((prevState) => ({ ...prevState, activeSubStep: prevState.activeSubStep + 1 }));
		} else if (steps.activeStep === 1 && selectedTasksIds.length === 0) {
			setDialogToDisplay('noSelectedTasks');
			setShowModal(true);
		} else if (steps.activeStep === 3 && steps.activeSubStep === 0) {
			handleSubmit();
		}
		else {
			setSteps((prevState) => ({ ...prevState, activeSubStep: 0, activeStep: prevState.activeStep + 1 }));
		}
	};

	function handleBack() {
		if (steps.activeStep === 0 || (steps.activeStep === 3 && steps.activeSubStep === 1) || (steps.activeStep === 2 && steps.activeSubStep === 1)) {
			if (steps.activeStep === 0 && steps.activeSubStep === 0) {
				history.goBack();
			}
			setSteps((prevState) => ({ ...prevState, activeSubStep: prevState.activeSubStep - 1 }));
		} else if (steps.activeStep === 1) {
			setSteps((prevState) => ({ ...prevState, activeSubStep: 2, activeStep: prevState.activeStep - 1 }));
		} else if (steps.activeStep === 3 && steps.activeSubStep === 0) {
			setSteps((prevState) => ({ ...prevState, activeSubStep: 0, activeStep: prevState.activeStep - 1 }));
		} else {
			setSteps((prevState) => ({ ...prevState, activeSubStep: 1, activeStep: prevState.activeStep - 1 }));
		}
	};

	function handleClose() {
		if (steps.activeStep > 0) {
			setDialogToDisplay('closeDialog');
			setShowModal(true);
		} else {
			history.goBack()
		}
	};

	// When the user clicks on the stepper to select the type of project he wants to create.
	// Avaliable options are internal, request, and share
	function handleChangeSharing(value: string) {
		const companyType = inviteCompany.companyId ? { id: inviteCompany.companyId } : inviteCompany.newCompanyName ? { name: inviteCompany.newCompanyName } : null;
		if (value === projectTypeOptions.request.value) {
			setNewList({
				...newList,
				responder: companyType,
				requester: { id: state.selectedCompany },
			});
		} else if (value === projectTypeOptions.share?.value) {
			setNewList({
				...newList,
				requester: companyType,
				responder: { id: state.selectedCompany },
			});
		} else {
			setDisplayedHeaders(displayedHeaders.filter(header => (header.value !== 'externalUserOwners' && header.value !== 'externalUserReviewers')));
			setNewList({
				...newList,
				requester: { id: state.selectedCompany },
				responder: null,
			});
		}
	};

	const handleSubmit = () => {
		if (projectOptionsSelected.projectType === projectTypeOptions.request.value && (!newList.responder || newList.responder.id === state.selectedCompany)) {
			dispatch({
				type: 'SET_NOTIFICATION',
				notification: {
					variant: 'error',
					message: 'Must select an external company',
				},
			});
		} else if (projectOptionsSelected.projectType === projectTypeOptions.share?.value && (!newList.requester || newList.requester.id === state.selectedCompany)) {
			dispatch({
				type: 'SET_NOTIFICATION',
				notification: {
					variant: 'error',
					message: 'Must select an external company',
				},
			});
		} else {
			if (projectOptionsSelected.projectNumber === projectNumberOptions.single.value) {
				createList();
			} else {
				setDialogToDisplay('multipleProjects');
				setShowModal(true);
			}
		}
	};

	function createProject() {
		setShowModal(false);
		setDialogToDisplay('');
		setProjectOptionsSelected({ ...projectOptionsSelected, projectNumber: projectNumberOptions.single.value });
		createList();
	}

	function createAnotherProject() {
		setShowModal(false);
		setDialogToDisplay('');
		createList();
		resetValues();
	}

	function resetValues() {
		setProjectOptionsSelected({ ...projectOptionsSelected, projectType: undefined });
		setInviteCompany({ newCompanyName: '', ownerEmail: '', companyId: '' });
		setNewList({
			name: '',
			description: '',
			isVolatile: true,
			requester: { id: state.selectedCompany },
			scheduleType: null,
			reconciliations: '0',
			userCompanyOwners: [{
				__typename: 'User',
				id: state.currentUser.id,
				email: state.currentUser.email,
				fullName: state.currentUser.fullName,
				profileUrl: state.currentUser.profileUrl,
			}]
		});
		setSelectedTemplate({
			__typename: 'List',
			id: '',
			name: '',
			labelName: '',
			tasks: [],
		});
		setDisplayedHeaders(allTableHeaderOptions.slice(0, 4));
		setSteps({ ...steps, activeStep: 0, activeSubStep: 1 });
	}

	function dialogHeader() {
		return (
			<div>
				{dialogToDisplay === 'closeDialog' &&
					<Typography style={{ color: '#707070', fontSize: '18px', fontWeight: 'bold' }}>
						Close Creating A Project
				  </Typography>
				}
				{ dialogToDisplay === 'multipleProjects' &&
					<Typography style={{ color: '#707070', fontSize: '18px', fontWeight: 'bold' }}>
						Multiple Projects
				  </Typography>
				}
			</div>
		)
	}

	function dialogContent() {
		return (
			<>
				{dialogToDisplay === 'closeDialog' &&
					<div className={classes.flex} style={{ flexDirection: 'row' }}>
						<DialogContentText className={classes.dialogText} style={{ marginBottom: '0px' }}>
							You are about to navigate away from this page and any changes you have made will be lost.
						</DialogContentText>
					</div>
				}
				{ dialogToDisplay === 'multipleProjects' &&
					<div data-cy='multi-project-dialog' className={classes.flex} style={{ flexDirection: 'row' }}>
						<DialogContentText className={classes.dialogText} style={{ marginBottom: '0px' }}>
							Would you like to create another project?
						</DialogContentText>
					</div>
				}
				{ dialogToDisplay === 'noSelectedTasks' &&
					<div className={classes.flex} style={{ flexDirection: 'column', width: '100%' }}>
						<DialogContentText className={classes.dialogText} style={{ marginBottom: '8px' }}>
							You haven't selected any tasks to include in your project.
						</DialogContentText>
						<DialogContentText className={classes.dialogText} style={{ marginBottom: '0px' }}>
							Do you still want to continue?
						</DialogContentText>
					</div>
				}
			</>
		)
	}

	function dialogActions() {
		return (
			<>
				{dialogToDisplay === 'closeDialog' &&
					<React.Fragment>
						<Button data-cy="cancel" onClick={onCancel} variant="outlined" className={classes.dialogButton}>
							Cancel
						</Button>
						<Button data-cy="ok" onClick={onOk} variant="contained" className={classes.dialogButton} style={{ marginLeft: '24px' }}>
							Okay
						</Button>
					</React.Fragment>
				}
				{ dialogToDisplay === 'multipleProjects' &&
					<React.Fragment>
						<Button onClick={createProject} variant="outlined">
							No, I’m finished
						</Button>
						<Button autoFocus onClick={createAnotherProject} variant="contained" style={{ marginLeft: '24px' }}>
							Yes, create another project
						</Button>
					</React.Fragment>
				}
				{dialogToDisplay === 'noSelectedTasks' &&
					<React.Fragment>
						<Button data-cy="cancel" onClick={onCancel} variant="outlined" className={classes.dialogButton}>
							Cancel
						</Button>
						<Button data-cy="ok" onClick={onOk} variant="contained" className={classes.dialogButton} style={{ marginLeft: '24px' }}>
							Okay
						</Button>
					</React.Fragment>
				}
			</>
		)
	}

	function onOk() {
		setShowModal(false);
		if (dialogToDisplay === 'closeDialog') {
			history.goBack();
		} else if (dialogToDisplay === 'noSelectedTasks') {
			setSteps((prevState) => ({ ...prevState, activeSubStep: 0, activeStep: prevState.activeStep + 1 }));
		}
		setDialogToDisplay('');
	}

	function onCancel() {
		setShowModal(false);
		setDialogToDisplay('');
	}

	const [nameFilterPopperAnchorEl, setNameFilterPopperAnchorEl] = useState(null);
	const [selectedHeader, setSelectedHeader] = useState('');
	const [sortByName, setSortByName] = useState<string | null>(null);
	const [sortBySection, setSortBySection] = useState<string | null>(null);
	const [nameFilter, setNameFilter] = useState('');
	const [sectionFilter, setSectionFilter] = useState('');

	function setNameFilterPopper(header: string, selectedRef: any) {
		setSelectedHeader(header);
		const refInUseForPopper = nameFilterPopperAnchorEl == selectedRef.current;
		if (refInUseForPopper) setNameFilterPopperAnchorEl(null);
		else setNameFilterPopperAnchorEl(selectedRef.current);
	}

	function closeNameFilterPopper() {
		setNameFilterPopperAnchorEl(null);
	}

	function getSortValue() {
		if (selectedHeader === 'name') return sortByName;
		else if (selectedHeader === 'section') return sortBySection;
		else return null;
	}

	function handleSortAsc() {
		if (selectedHeader === 'name') {
			setSortBySection(null);
			if (sortByName === 'ASC') setSortByName(null);
			else setSortByName('ASC');
		} else if (selectedHeader === 'section') {
			setSortByName(null);
			if (sortBySection === 'ASC') setSortBySection(null);
			else setSortBySection('ASC');
		}
	}

	function handleSortDesc() {
		if (selectedHeader === 'name') {
			setSortBySection(null);
			if (sortByName === 'DESC') setSortByName(null);
			else setSortByName('DESC');
		} else if (selectedHeader === 'section') {
			setSortByName(null);
			if (sortBySection === 'DESC') setSortBySection(null);
			else setSortBySection('DESC');
		}
	}

	function getFilterValue() {
		if (selectedHeader === 'name') return nameFilter;
		else if (selectedHeader === 'section') return sectionFilter;
		else return '';
	}

	function setFilterValue(e: React.ChangeEvent<HTMLInputElement>) {
		if (selectedHeader === 'name') setNameFilter(e.target.value)
		else if (selectedHeader === 'section') setSectionFilter(e.target.value)
		else return '';
	}

	return (
		<div data-cy="project-create-modal" className={clsx(classes.flex, classes.screen, classes.column)} style={{ position: 'relative' }}>
			{showModal &&
				<AlertModal open={showModal} dialogContent={dialogContent()} dialogHeader={dialogHeader()} dialogActions={dialogActions()} onCancel={onCancel} onOk={onOk} />
			}
			{/* Used to filter the task table columns */}
			<TaskNameFilterPopper
				anchorEl={nameFilterPopperAnchorEl}
				closePopper={closeNameFilterPopper}
				nameSearchValue={getFilterValue()}
				handleNameSearch={setFilterValue}
				handleSortAsc={handleSortAsc}
				handleSortDesc={handleSortDesc}
				sortByName={getSortValue()}
				containerStyle={{ width: 425 }}
			/>
			{/* Used to display the steppers in the top of the component */}
			{!isOnlyImportTasks &&
				<Header activeStep={steps.activeStep} steps={stepperLabels} handleClose={handleClose} submitTitle={submitTitle} />
			}

			<div className={clsx(classes.flex, classes.container)} style={{ alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} >
				{!isOnlyImportTasks && steps.activeStep === 0 &&
					<ProjectTypesStep activeSubStep={steps.activeSubStep} isAdmin={isAdmin} newTemplate={newList} setNewTemplate={setNewList} selectedTemplate={selectedTemplate}
						setSelectedTemplate={setSelectedTemplate} projectOptionsSelected={projectOptionsSelected} setProjectOptionsSelected={setProjectOptionsSelected}
						projectNumberOptions={projectNumberOptions} projectTypeOptions={projectTypeOptions} externalCompanyId={externalCompanyId} data={templateLists}
						inviteCompany={inviteCompany} setInviteCompany={setInviteCompany} templateSelected={handleNext} setSelectedTasksIds={setSelectedTasksIds}
						additionalExternalOwners={additionalExternalOwners} setAdditionalExternalOwners={setAdditionalExternalOwners} />
				}
				{
					(isOnlyImportTasks || steps.activeStep === 1 || steps.activeStep === 2) &&
						<ProjectTaskDeatilsReview
							isOnlyImportTasks={isOnlyImportTasks}
							isAdmin={isAdmin}
							externalCompanyId={externalCompanyId}
							allTableHeaderOptions={allTableHeaderOptions}
							displayedHeaders={displayedHeaders}
							setDisplayedHeaders={setDisplayedHeaders} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} steps={steps}
							selectedTasksIds={selectedTasksIds} setSelectedTasksIds={setSelectedTasksIds} projectOptionsSelected={projectOptionsSelected}
							onFilesDropped={() => setSteps((prevState) => ({ ...prevState, activeSubStep: prevState.activeSubStep + 1 }))} filesUploaded={handleBack}
							sortByName={sortByName} sortBySection={sortBySection} nameFilter={nameFilter} sectionFilter={sectionFilter} setNameFilterPopper={setNameFilterPopper}
							additionalInternalOwners={additionalInternalOwners} setAdditionalInternalOwners={setAdditionalInternalOwners}
							additionalExternalOwners={additionalExternalOwners} setAdditionalExternalOwners={setAdditionalExternalOwners}
						/>
				}
				{!isOnlyImportTasks && steps.activeStep === 3 &&
					<ProjectDetailsStep activeSubStep={steps.activeSubStep} submitTitle={submitTitle} isAdmin={isAdmin} newTemplate={newList} setNewTemplate={setNewList} selectedTemplate={selectedTemplate}
						projectOptionsSelected={projectOptionsSelected} setProjectOptionsSelected={setProjectOptionsSelected} externalCompanyId={externalCompanyId}
						setSelectedTemplate={setSelectedTemplate} reconSettingsClicked={() => setSteps((prevState) => ({ ...prevState, activeSubStep: prevState.activeSubStep + 1 }))} inviteCompany={inviteCompany} setInviteCompany={setInviteCompany}
						additionalInternalOwners={additionalInternalOwners} setAdditionalInternalOwners={setAdditionalInternalOwners}
						additionalExternalOwners={additionalExternalOwners} setAdditionalExternalOwners={setAdditionalExternalOwners}
					/>
				}
			</div>
			{!isOnlyImportTasks &&<>
				<Footer step={steps} stepLabels={stepperLabels} nextDisabled={nextDisabled()} handleBack={handleBack} handleNext={handleNext} />
				{ (createLoading && (projectOptionsSelected.projectNumber === projectNumberOptions.single.value)) &&
					<div className={classes.spinner}>
						<CircularLoader />
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '36px' }}>
							<Typography>Please give us a second while we create your project</Typography>
						</div>
					</div>
				}</>
			}
		</div>
	)
}

export default withRouter(CreateProjectPage);

