import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition, Tab } from '@headlessui/react';
import RelativePanel from '../../../tailwind/dates/RelativePanel';
import moment from 'moment';

interface FluxDateRangeProps {
  withHeader?: boolean;
  task?: any;
  priorPeriodEndDate: moment.Moment;
  buildTitle:(range: string) => void;
  handleShowPeriodFlux: (range: string) => void;
}

/**
 * Reusable `FluxDateRange` component.
 * This component is using the `TailwindCSS` design pattern.
 *
 * @return {React.JSXElementConstructor} The `FluxDateRange` component.
 */
export default function  FluxDateRange(props: FluxDateRangeProps) {
  // Fetching the current task integration info
  // to retrieve the start date locked status.
  const primarySourceIntegrationInfo = props.task?.primarySource?.integrationInfo;
  const isStartDateLocked = primarySourceIntegrationInfo?.startDateLocked;

  const fluxDateRangeStartDate = (props.task?.primarySourceType === 3) ?
    primarySourceIntegrationInfo?.inStartDate : (props.task?.primarySourceType === 5) ?
    primarySourceIntegrationInfo?.qboStartDate :
    primarySourceIntegrationInfo?.nsStartDate;
  const fluxDateRangeEndDate = (props.task?.primarySourceType === 3) ?
    primarySourceIntegrationInfo?.inEndDate : (props.task?.primarySourceType === 5) ?
    primarySourceIntegrationInfo?.qboEndDate :
    primarySourceIntegrationInfo?.nsEndDate;

  const fluxDateRangeDateFormat = (props.task?.primarySourceType === 6) ?
    "MMM YYYY" :
    "MM-DD-YYYY";
    // States
  const [date, setDate] = useState<moment.Moment>(moment());
  const [startDate, setStartDate] = useState<moment.Moment>(moment(fluxDateRangeStartDate));
  const [endDate, setEndDate] = useState<moment.Moment>(moment(fluxDateRangeEndDate));

  useEffect(() => {
    if(fluxDateRangeStartDate) {
      setStartDate(moment(fluxDateRangeStartDate));
    };

    if(fluxDateRangeEndDate) {
      setEndDate(moment(fluxDateRangeEndDate));
    };
  }, [props.task, props.priorPeriodEndDate])

  // Custom calendar tab view state. Shows the relative picker by default
  // and, switch to the absolute date picker on click.
  const pickRange = (range: string, rangeStart: any, rangeEnd: any) => {
    props.handleShowPeriodFlux(range)
  }

  return (
    <Menu as="div" className="inline-block relative mt-2 text-left">
      <div className='flex items-center'>
        <Menu.Button className="flex justify-center items-center w-full text-xs font-medium text-gray-700 bg-white rounded border border-gray-100 focus:outline-none">
          <div className="-space-x-px flex pr-3 w-56">
            <div className="relative flex-1 w-1/2 min-w-0">
              <div
                id="start-date"
                className="block relative pr-0 py-2 pl-3 w-full text-xs text-left text-gray-400 rounded-none border-0 hover:cursor-pointer focus:outline-none focus:ring-0 focus:cursor-pointer sm:text-sm"
              >
                <span>{endDate.format(fluxDateRangeDateFormat)}</span>
              </div>

              <div className="flex absolute inset-y-0 right-0 items-center pointer-events-none">
                <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.6422 4.31563L9.07969 1.06563C9.03296 1.00629 8.97339 0.958321 8.90547 0.925306C8.83755 0.892291 8.76302 0.875092 8.6875 0.875H7.675C7.57031 0.875 7.5125 0.995312 7.57656 1.07812L9.83125 3.9375H0.375C0.30625 3.9375 0.25 3.99375 0.25 4.0625V5C0.25 5.06875 0.30625 5.125 0.375 5.125H11.2484C11.6672 5.125 11.9 4.64375 11.6422 4.31563Z" fill="black" fillOpacity="0.25"/>
                </svg>
              </div>
            </div>
            <div className="relative flex-1 min-w-0">
              <div
                id="end-date"
                className="block relative py-2 pr-4 w-full text-xs text-gray-400 rounded-none border-0 hover:cursor-pointer focus:outline-none focus:ring-0 focus:cursor-pointer sm:text-sm"
              >
                <span>{fluxDateRangeDateFormat === "MM-DD-YYYY" ? props.priorPeriodEndDate.format(fluxDateRangeDateFormat) : props.priorPeriodEndDate.format(fluxDateRangeDateFormat)}</span>
              </div>
              <div className="flex absolute inset-y-0 right-0 items-center pointer-events-none">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.75 1.87689H10.125V0.876892C10.125 0.808142 10.0688 0.751892 10 0.751892H9.125C9.05625 0.751892 9 0.808142 9 0.876892V1.87689H5V0.876892C5 0.808142 4.94375 0.751892 4.875 0.751892H4C3.93125 0.751892 3.875 0.808142 3.875 0.876892V1.87689H1.25C0.973438 1.87689 0.75 2.10033 0.75 2.37689V12.7519C0.75 13.0285 0.973438 13.2519 1.25 13.2519H12.75C13.0266 13.2519 13.25 13.0285 13.25 12.7519V2.37689C13.25 2.10033 13.0266 1.87689 12.75 1.87689ZM12.125 12.1269H1.875V6.18939H12.125V12.1269ZM1.875 5.12689V3.00189H3.875V3.75189C3.875 3.82064 3.93125 3.87689 4 3.87689H4.875C4.94375 3.87689 5 3.82064 5 3.75189V3.00189H9V3.75189C9 3.82064 9.05625 3.87689 9.125 3.87689H10C10.0688 3.87689 10.125 3.82064 10.125 3.75189V3.00189H12.125V5.12689H1.875Z" fill="black" fillOpacity="0.25"/>
                </svg>
              </div>
            </div>
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-[9999] w-72 bg-white rounded origin-top-right focus:outline-none border border-gray-100">
          <div className="w-full max-w-md sm:px-0">
            <Tab.Group>
              <Tab.Panels>
                <RelativePanel
                  primarySourceType={props.task?.primarySourceType}
                  pickRange={pickRange}
                  startDate={startDate}
                  endDate={endDate}
                  date={date}
                  isStartDateLocked={isStartDateLocked}
                />
              </Tab.Panels>
            </Tab.Group>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}