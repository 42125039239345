import {createMutationHook, gql} from '../graphqlHelpers';

export const useUpdateMessageLabelType = createMutationHook(gql`
  mutation UpdateMessageLabelType (
    $id: ID!,
    $name: String!,
    $color: String!
  ) {
    updateMessageLabelType(id: $id, name: $name, color: $color){
      success
      id
    }
  }
`);

