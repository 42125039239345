import { Input, Typography } from "@material-ui/core";
import { PostAdd } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import TagDropdown from "../../../../../../list/CreateListPage/components/CreateTemplateStep/components/TagDropdown";
import { useUserTags } from "../../../../../../../graphql/queries/UserTags";
import idx from "idx";
import { useAddTaskTags } from "../../../../../../../graphql/mutations/AddTaskTags";
import { useRemoveTaskTags } from "../../../../../../../graphql/mutations/RemoveTaskTags";
import TagLabel from "../../../../../../common/TagLabel";
import { customStyles, useStyles } from "./styles";
import { CustomValueContainer } from "./CustomValueContainer";
import { CreatableMulti } from "./CustomInput";
import { useDispatchContext, useStateContext } from "../../../../../../../store";

interface IProps {
  task: any;
  tasks: any;
  multiTasks: string[];
  setTasks: React.SetStateAction<any>;
}

export const TaskEntityInput = (props: IProps) => {
  const { task, tasks, multiTasks, setTasks } = props;
  const classes = useStyles();
  const [tag, setTag] = useState<string | null>(null);
  const [removeTag, setRemoveTag] = useState<string | null>(null);
  const dispatch = useDispatchContext();
  const state = useStateContext(); 
  const { data: tagData } = useUserTags({});
  const { addedTags } = state;

  const [addTags] = useAddTaskTags({
    taskIds: [task.id],
    tag: tag,
  });

  const [removeTags] = useRemoveTaskTags({
    taskIds: [task.id],
    tag: removeTag,
  });

  const tagsFromBE =
    idx(tagData, (d: any) =>
      d.tags.map((x: any) => ({ label: x.tag, value: x.id }))
    ) || [];

  const newTags =
    idx(addedTags, (d: any) =>
      d.map((x: any) => ({ label: x.tag, value: x.id }))
    ) || [];
  const tagOptions = [...tagsFromBE, ...newTags];

  const handleChangeEntity = (event: any) => {
    if (task.tags && task.tags.map((t: any) => t.tag).includes(event.label)) {
      return;
    }
    const allOptions = tagOptions.map((t: any) => t.label);

    let tag = { tag: event.label, id: event.value };
    setTag(event.label);
    if (!allOptions.includes(event.label)) {
      dispatch({
        type: "ADD_NEW_TAG",
        addedTag: tag,
      });
    }

    const newTasks = tasks.map((t: any) =>
      (multiTasks as any[]).includes(t.id)
        ? { ...t, tags: [...t.tags, tag] }
        : t
    );
    setTasks(newTasks);
  };

  const handleRemoveTag = (tag: any) => {
    const newTasks = tasks.map((t: any) => ({ ...t }));
    const index = newTasks.findIndex((t: any) => t.id === task.id);
    setRemoveTag(tag.tag);
    newTasks[index] = {
      ...task,
      tags: task.tags.filter((t: any) => t.tag !== tag.tag)
    };
    setTasks(newTasks);
  };

  useEffect(() => {
    if (tag) addTags();
  }, [tag]);

  useEffect(() => {
    if (removeTag) removeTags();
  }, [removeTag]);

  const input: any = document.querySelector(".react-select__input input");

  const [hovered, setHovered] = useState(false);

  return (
    <div data-cy="entity-container" className={classes.entityContainer}>
      <Typography
        className={clsx(classes.secondary, classes.label)}
        variant="h6"
      >
        Entity
      </Typography>

      <div className={classes.rightContainer}>
        <div
          className={clsx(
            classes.inputContainer,
            task.tags && task.tags.length && classes.inputColors
          )}
        >
          <PostAdd className={clsx(classes.postAddIcon, "post-add")} />

          <div
            className={clsx(
              task.tags && !task.tags.length && classes.selectContainer
            )}
            style={task.tags && task.tags.length > 0 ? { display: "flex" } : {}}
            onMouseLeave={() => setHovered(false)}
            onMouseOver={() => setHovered(true)}
          >
            <CreatableMulti
              options={tagOptions}
              value={
                task.tags &&
                task.tags.map(({ tag }: any) => ({ label: tag, value: tag }))
              }
              handleAdd={(x: string) => handleChangeEntity({ label: x, value: x })}
              handleRemove={(x: string) => handleRemoveTag({ tag: x })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
