import { createMutationHook, gql } from '../graphqlHelpers';

export const useBulkUpdateIntacctRecons = createMutationHook(gql`
  mutation BulkUpdateIntacctRecons(
    $listId: ID!
  ) {
    bulkUpdateIntacctRecons(
      listId: $listId
    ) {
      success
    }
  }
`);
