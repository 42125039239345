import { createMutationHook, gql } from '../graphqlHelpers';

const deleteFiles = createMutationHook(gql`
  mutation DeleteFiles($fileIds: [ID!]!) {
    deleteFiles(fileIds: $fileIds) {
      success
    }
  }
`);

export default deleteFiles;
