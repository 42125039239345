/* The purpose of this component is:
 */

import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle, Link, Typography } from '@material-ui/core';

export function NetSuiteEnable(props: any) {

  const setupInstructions = "To enable the NetSuite integration, please ensure you have enabled the feature inside of the NetSuite portal before proce"
  return (
    <div>
      { /* Instuctions on how to enable functionality*/}
      <Dialog open={props.open}>

        <div style={{ padding: 20 }}>
          <DialogTitle>
            <Typography variant='h2'>
              {`Connect Your NetSuite Account`}
            </Typography>
          </DialogTitle>
          <div style={{ padding: '16px 24px' }}>
            <Typography>
              Before moving to the next step to authorize PrepDD for usage of your
              NetSuite account, please make sure you have enabled NetSuite SOAP web
              services and appropriate access role permissions:
            </Typography>
            <br />

            <Typography>
              {`1. Create an integration record through the NetSuite Web Portal and Save the consumer key and consumer secret to put into the next form` }
            </Typography>
            <br />

            <Typography>
              {`2. Enable Token Based Authentication through the NetSuite Web Portal by navigating to Setup > Company > Setup Tasks > Enable Features > SuiteCloud > Manage Authentication` }
            </Typography>
            <br />

            <Typography>
              {`3. Create a Token Role with "Full" selected for "User Access Token," "Access Token Management" and "SOAP Web Services"` }
            </Typography>
            <br />

            <Typography>
              {`4. Add the Token Role to the employee account you would like to use for querying data` }
            </Typography>
            <br />

            <Typography>
              {`5. Create Access Tokens and save the "Token ID" and "Token Secret" to enter on the next page` }
            </Typography>
            <br />

            <Typography>
              Please contact our customer success team at <Link href='mailto:support@prepdd.com'>support@prepdd.com</Link> with any questions about configuring your integration
            </Typography>
          </div>
        </div>
        <DialogActions>
          <Button onClick={props.denyAction} variant='outlined'>
            Cancel
          </Button>
          <Button variant='contained' onClick={props.confirmAction} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
