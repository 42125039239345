import { gql } from '../graphqlHelpers';
import {useLazyQuery, useQuery} from '@apollo/client';

const ProgressReportQuery = gql`
  query ProgressReport($listId: ID!) {
    list(id: $listId) {
      id
      projectProgressReport
    }
  }
`

export const useLazyProgressReportQuery = (variables: any) =>
  useLazyQuery(ProgressReportQuery, { variables })
