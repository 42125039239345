import React from 'react';
import Header from './components/Header';
import ImportTasks from './components/Body/ImportTasks';

/**
 * Component for the Create Task page.
 *
 * This component renders the Create Task page, which includes a header section
 * (likely containing navigation or other UI elements) and an 'ImportTasks' component.
 * It serves as the main entry point for users to create tasks.
 *
 * @returns {JSX.Element} The rendered Create Task page.
 */
export default function CreateTaskPage() {
  return (
    <div>
      {/* Render the header section */}
      <Header />

      {/* Render the 'ImportTasks' component */}
      <ImportTasks />
    </div>
  );
}

