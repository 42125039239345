
import { gql } from '../graphqlHelpers';

type ID = number | string;

export interface UpdateWorkspaceVariables {
  id: ID;
  name?: string;
  description?: string;
}

export interface Error {
  path: string;
  message: string;
}

export interface UpdateWorkspaceData {
  success: boolean;
  errors: Error[];
}

export const updateWorkspace =gql `
mutation UpdateWorkspace($id: ID!, $name: String, $description: String){
  updateWorkspace(id: $id, name: $name, description: $description){
    success
    errors{
      path
      message
    }
  }
}`;