import React from 'react';

import Header from './components/Header';
import Body from './components/Body';

export default function FilesPage(props: any) {
  return (
    <div style={(Boolean(props.isCreateProject) ? {height: '100%', overflow: 'hidden scroll'} : undefined)}>
		{!props.isCreateProject &&
	      <Header />
		}
      <Body isCreateProject={props.isCreateProject} companyPosition = {props.companyPosition} filesUploaded={props.filesUploaded}/>
    </div>
  );
}

/*
 * In the dropdown for tasks, we want to implement a sort order
 */
export function sortTasks(tasks: any) {
	if (!tasks) return;
	var priorities = [
		'high',
		'medium',
    'low'
	];

	return tasks.sort((task1: any, task2: any) => {
		// First, sort by priority
		if ( priorities.indexOf(task1.priority) > priorities.indexOf(task2.priority) ) return 1;
		if ( priorities.indexOf(task1.priority) < priorities.indexOf(task2.priority) ) return -1;
    // Then, sort by list Number
		if ( task1.listNumber > task2.listNumber ) return 1;
		if ( task1.listNumber < task2.listNumber ) return -1;
	});
}
