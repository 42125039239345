/* linted */
/* We get the task rather than just the status, so we can get the allowed
 * statuses from the RBAC constants */
import React, { useEffect } from 'react';
import {
  ClickAwayListener,
  List,
  ListItem,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import {StatusIcon} from './OnlyStatusIcon';
import {getStatusesFor} from '../../constants/rbac';
import { useStateContext } from '../../store/index';

interface StatusDropdownProps {
  changeOpen: any;
  onChange: any;
  open: boolean;
  task: any;
  containerStyles?: any;
  top?: string;
  left?: string;
  isExternalUser?: boolean
}

export function StatusDropdown(props: StatusDropdownProps) {
  const state = useStateContext();
  const STATUSES = getStatusesFor(props.task, state.currentUser);
  const STYLE: any = {
    width: '150px',
    position: 'absolute',
    top: props.top || '29px',
    left: props.left || 0,
    backgroundColor: '#FFFFFF',
    border: '1px solid #D8D8D8',
    borderRadius: '3px',
    zIndex: 32,
    paddingBottom: 12,
    whiteSpace: 'nowrap',
    cursor: props.isExternalUser ? 'not-allowed' : 'pointer'
  };

  const handleClickAway = () => {
    props.changeOpen(false);
  };

  let statuses: string[] = STATUSES;
  if (state.listGroupedCustomTaskStatuses[props.task?.list?.id]) {
    statuses = state.listGroupedCustomTaskStatuses[props.task?.list?.id].filter((ts:any) => ts.isCustom || (!ts.isCustom && STATUSES.includes(ts.key)) || (!ts.isCustom && ts.key === 'Rejected' && (STATUSES.some(item => item.includes('Rejected'))))).map((s: any) => s.key);
  }
  
  return (
    <div data-cy="status-dropdown-icon" style={props.containerStyles}>
      <StatusIcon
        onClick={props.changeOpen}
        selected
        status={props.task.status || ''}
        isExternalUser={props.isExternalUser}
        style={props.containerStyles}
        listId={props.task?.list?.id}
      />
      {props.open && (
      <ClickAwayListener onClickAway={handleClickAway}>
          <List data-cy="side-panel-status-list" style={STYLE}>
            <ListSubheader
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                marginLeft: 10,
                marginRight: 10,
                borderBottom: '2px solid lightgrey',
                marginBottom: 8,
                cursor: props.isExternalUser ? 'not-allowed' : 'pointer'
              }}
            >
              {/* TODO: generalize the cursor behavior for Typography */}
              <Typography
                style={{fontSize: 14, cursor: props.isExternalUser ? 'not-allowed' : 'default'}}
                variant="h5"
              >
                Status
              </Typography>
            </ListSubheader>

            {statuses.filter((s: string) => s !== props.task.status).map(
              (s: string, index: number) => (
                <ListItem
                  data-cy={`status-dropdown-select-${s.toLowerCase()}`}
                  key={s}
                  onClick={(e: any) => props.onChange(e, s)}
                  style={{paddingLeft: 0, paddingRight: 5, cursor: state.currentUser.isExternalUser ? 'not-allowed' : 'pointer'}}
                >
                  <StatusIcon status={s} listId={props.task?.list?.id} style={{cursor: props.isExternalUser? 'not-allowed' : 'pointer'}}/>
                </ListItem>
              )
            )}
          </List>
        </ClickAwayListener>
      
      )}
    </div>
  );
}
