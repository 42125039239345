import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getCSRF } from '../../helpers/helpers';
import idx from 'idx';
import { Typography, Button, Dialog, DialogActions, DialogTitle, Checkbox } from '@material-ui/core';
import { useGlobalStyles } from '../common/Style';
import { MoneyDisplay } from '../common/MoneyDisplay';
import { DatePicker } from '../common/DatePicker';
import { useError } from '../../hooks/UseError';
import TagLabel from '../common/TagLabel'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select, { components } from 'react-select';

import LockSwitchToggle from './LockSwitch';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  LEVELS,
  buildParams,
  disableOption,
  buildAdjBookParams,
  formatDateForDatePicker,
  ACCOUNT_METHOD_OPTIONS,
  STATISTICAL_OPTIONS,
} from '../../constants/dimensions';

import { useLazyAdjBookQuery } from '../../graphql/queries/AdjBook';

interface IntacctValueSelectionProps {
  open: boolean;
  denyAction: any;
  confirmAction: any;
  task: any;
  project: any;
  sourceType: any;
}

interface Option {
  label: string;
  value: string;
}
interface IntegrationField {
  label: string;
  value?: string;
  isRequired?: boolean;
  isMulti?: boolean;
  options?: Option[];
  component?: any;
}

const datePickerOnChange = (date: MaterialUiPickersDate, dateParamName: string, { setState, state }: any) => {
  //If date is invalid, we don't want to update the state
  if (date && date.toString() !== "Invalid Date") {
    //This format works for the Intacct Integration
    const formattedDate = date.toDateString();
    setState({ ...state, [`${dateParamName}`]: formattedDate });
  }
}

function PeriodAdjustmentField({ setState, state }: any) {

  const [startDateLocked, setStartDateLocked] = useState(state.startDateLocked || false);
  const [endDateLocked, setEndDateLocked] = useState(state.endDateLocked || false);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <DatePicker
          onChange={(d: MaterialUiPickersDate) => datePickerOnChange(d, 'inStartDate', { setState, state })}
          value={formatDateForDatePicker(state.inStartDate)}
        />
        <LockSwitchToggle name="startDateLockSwitch" locked={startDateLocked} handleChange={() => { setState({ ...state, startDateLocked: !startDateLocked }); setStartDateLocked(!startDateLocked) }} />
      </div>
      <Typography component='span'>
        to
      </Typography>
      <div>
        <DatePicker
          onChange={(d: MaterialUiPickersDate) => datePickerOnChange(d, 'inEndDate', { setState, state })}
          value={formatDateForDatePicker(state.inEndDate)}
        />
        <LockSwitchToggle name="endDateLockSwitch" locked={endDateLocked} handleChange={() => { setState({ ...state, endDateLocked: !endDateLocked }); setEndDateLocked(!endDateLocked) }} />
      </div>
    </div>
  )
}

export function IntacctValueSelection({
  sourceType, confirmAction, denyAction, open, task, project
}: IntacctValueSelectionProps
) {
  const showError = useError();
  const [books, setBooks] = useState<any>({});

  const [balanceQueryLoading, setBalanceQueryLoading] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  const source = task[`${sourceType}Source`];
  const info = idx(source, (s: any) => s.integrationInfo);
  const projectReconEnd = idx(project, (p: any) => p.reconEndDate && new Date(p.reconEndDate));
  let [balance, setBalance] = useState(idx(source, (source: any) => source.value));
  // using this to cancel earlier API call to /balance endpoint
  const [tokenSource, setTokenSource] = useState({} as any);

  // Fetch the current task account AdjBooks
  const { loading, data, error } = useLazyAdjBookQuery({ taskId: task.id });

  useEffect(() => {
    if (data) {
      setBooks(data)
    }
  }, [data]);

  const optimistic = books.adjBook && books.adjBook.map((adj: any) => ({ 'adjbook' : adj.name }));

  const companyAdjBooks = (optimistic && optimistic.length > 0) ? optimistic : [{adjbook : 'GAAP'}, {adjbook : 'TAX'}];

  const handleInvert = (event: { target: { checked: Boolean }; }) => {
    setState({ ...state, ...{ invert: !state.invert } });
    setBalance(-1 * balance);
  };

  /** defaults */
  const [state, setState] = useState<any>({
    inEntities: idx(info, (i: any) => i.inEntities),
    inDepartments: idx(info, (i: any) => i.inDepartments),
    inUnderliers: idx(info, (i: any) => i.inUnderliers),
    inAccounts: idx(info, (i: any) => i.inAccounts),
    inServices: idx(info, (i: any) => i.inServices),
    inCustomers: idx(info, (i: any) => i.inCustomers),
    inVendors: idx(info, (i: any) => i.inVendors),
    inAccountingMethod: idx(info, (i: any) => i.inAccountingMethod?.map((it:any) => ({...it, label: it.label.charAt(0).toUpperCase() + it.label.slice(1)?.toLowerCase(), value: ['Accrual', 'Cash'].includes(it.value) ? it.value.toUpperCase() : it.value }))) || [],
    inStartDate: idx(info, (i: any) => formatDateForDatePicker(i.inStartDate)) || formatDateForDatePicker(new Date(2000, 0, 1)),
    inEndDate: idx(info, (i: any) => formatDateForDatePicker(i.inEndDate)) || projectReconEnd || formatDateForDatePicker(new Date()),
    startDateLocked: idx(info, (i: any) => i.startDateLocked) || false,
    endDateLocked: idx(info, (i: any) => i.endDateLocked) || false,
    invert: idx(info, (i: any) => i.invert),
  });

  const [OPTIONS, SETOPTIONS] = useState<any>({});

  /** Get the menu options at the first load. */
  useEffect(() => {
    if (open) {
      axios.get('/connector/intacct/menuitems', { headers: { 'X-CSRF-TOKEN': getCSRF() } })
        .then((a: any) => {
          setShowSpinner(false);
          const responseData = a.data;
          if (responseData) {
            for (let i = 0; i < responseData.inAccounts.length; ++i) {
              responseData.inAccounts[i] = responseData.inAccounts[i]
              responseData.inAccounts[i].name = responseData.inAccounts[i].id.toString() + ' - ' + responseData.inAccounts[i].name.toString()
            }
            SETOPTIONS(responseData);
          }
        }).catch((e: any) => {
          showError('There is a problem with your company\'s Intacct connection, please contact your company\'s administrator or PrepDD support for assistance.');
          setShowSpinner(false)
        });
    }
  }, [open]);

  /** Under what conditions do we query the balance? */
  useEffect(() => {
    if (!open) { return; }

    if (tokenSource && tokenSource.cancel) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    // Return if there are no accounts selected
    if (!state.inAccounts || !state.inAccounts.length) {
      setBalance(null);
      return;
    }

    const source = axios.CancelToken.source();
    setTokenSource(source);

    // What are the required items to check a balance?
    // in this case, accounts are necessary, if none select, assumes all
    //    if ( state.accounts && state.accounts.length ) {
    // 📍 What should we require for the balance check?
    // Currently we're requesting: `entities, departments, underliers, accounts, services, customers and vendors`.
    // I am also worrying why I keep seeing the `services` and also in our seed db.
    // Should we reconsider our diagram for the Intacct dimensions or just remove it from here?
    // A safe consideration would be to check in the available prod DB if there is data with the `in_services`
    // Which doesn't make any sense to me. The code logic is there in the Modal, no record for it.
    setBalanceQueryLoading(true);
    axios.get('/connector/intacct/balance', {
      cancelToken: source.token,
      params: {
        entities: idx(state, s => s.inEntities.map((n: any) => n.value).toString()),
        departments: idx(state, s => s.inDepartments.map((n: any) => n.value).toString()),
        underliers: idx(state, s => s.inUnderliers.map((n: any) => n.value).toString()),
        accounts: idx(state, s => s.inAccounts.map((n: any) => n.value).toString()),
        services: idx(state, s => s.inServices.map((n: any) => n.value).toString()),
        customers: idx(state, s => s.inCustomers.map((n: any) => n.value).toString()),
        vendors: idx(state, s => s.inVendors.map((n: any) => n.value).toString()),
        accountingMethod: state.inAccountingMethod.map((n: any) => n.value).toString(),
        startDate: formatDateForDatePicker(state.inStartDate),
        endDate: formatDateForDatePicker(state.inEndDate),
        startDateLocked: state.startDateLocked,
        endDateLocked: state.endDateLocked,
        invert: state.invert,
        reportingBook: buildParams('reportingBook', state.inAccountingMethod),
        statistical: buildParams('statistical', state.inAccountingMethod),
        adjBooks: buildAdjBookParams(state.inAccountingMethod, companyAdjBooks),
        task_id: task.id
      }
    }).then((a: any) => {
      setBalanceQueryLoading(false);
      setBalance(a.data);
    }).catch((e: any) => {
      if (!axios.isCancel(e)) {
        setBalanceQueryLoading(false);
        showError('There is a problem for getting the balance, please contact your company\'s administrator or PrepDD support for assistance.');
        console.error(e);
      }
    });
  }, [state.inEntities, state.inDepartments, state.inUnderliers, state.inAccounts,
  state.inServices, state.inCustomers, state.inVendors, state.inAccountingMethod,
  state.inStartDate, state.inEndDate, state.startDateLocked, state.endDateLocked])

  function handleAccept() { confirmAction({ state: { ...state, balance: balance } }); }
  
  // Check if at least one account is selected while editing the dimensions for reconciliation.
  // Otherwise disable the "Accept" button and block form submission.
  const isFormValid = state.inAccounts && state.inAccounts.length > 0;

  const allDimensions = LEVELS.concat({
    label: 'Period',
    component: PeriodAdjustmentField,
    isMulti: false
  });

  // Only allow the combination for 1 reportingbook + 1 or many adjbook + 1 statistical.
  // For ReportingBook, either "Cash" or "Accrual" can be selected.
  // For Adjbooks, both "Gaap" and "Tax" can be selected.
  // For Statistical, either "Inclue" or "Exclude" can be selected
  const availableDimensions = allDimensions.slice(0).map((dimension: any) => {
    if (dimension.value === 'inAccountingMethod') {
      const selectedOption = state['inAccountingMethod'];
      const selectedValues = selectedOption.map((opt: any) => opt.value);
      const allOptions = dimension.options.slice(0);

      const optimistic = companyAdjBooks.map((adj: any) => ({'label': adj.adjbook, 'value': adj.adjbook, 'isDisabled': false}));
      const kudoOptions = allOptions.concat(optimistic);

      for (let j = 0; j < dimension.options.length; j++) {
        kudoOptions[j].isDisabled = false;
        const prevValue = dimension.options[j].value;

        if (ACCOUNT_METHOD_OPTIONS.includes(prevValue) ) {
          disableOption(j, dimension, selectedValues, ACCOUNT_METHOD_OPTIONS);
        } else if (STATISTICAL_OPTIONS.includes(prevValue) ) {
          disableOption(j, dimension, selectedValues, STATISTICAL_OPTIONS);
        }
      }
 
      dimension = Object.assign({}, {
        ...dimension,
        options: dimension.options.concat(optimistic)
      });
    }
    return {...dimension};
  });

  // 📍 This component logic here can reuse the `IntacctPopper.tx` component.
  // TODO: Find a time to check or create a common component.
  return (
    <div>
      <Dialog
        onClose={denyAction}
        open={open}
      >
        <div className='min-w-[750px] px-5 py-3.5 flex flex-col'>
          <DialogTitle>
            <Typography variant='h2'>
              Edit Reconciliation Source Intacct Connection
            </Typography>
          </DialogTitle>

          {availableDimensions.map((level: any, i: number) =>
            <LevelDropdown
              i={i}
              key={i}
              level={level}
              options={OPTIONS[level.value] && OPTIONS[level.value].map((o: any) =>
                o.id ? { value: o.id, label: o.name } : { value: o, label: o }
              ) || level.options}
              set={(x: any) => {
                setState({ ...state, [level.value]: x });
              }}
              setState={setState}
              showSpinner={showSpinner}
              state={state}
              v={state[level.value] || level.label === 'Period'}
              dimension={OPTIONS[level.value]}
            />
          )}

          <div className='ml-[105px] mb-2.5 flex items-center'>
            <Typography>
              Other Options:
            </Typography>

            <div className='flex items-center ml-[48px] justify-between w-4_9'>
              <div className='flex items-center'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.invert}
                      color='primary'
                      onChange={handleInvert}
                      value={state.invert}
                    />
                  }
                  label={<Typography className='!cursor-pointer'>Invert total(s)</Typography>}
                />
              </div>
            </div>
            
          </div>

          <div className='flex ml-[120px] mt-[30px]'>
            <div className='flex items-end mr-2.5 w-5'>
              {balanceQueryLoading && <CircularProgress size="1.25rem" />}
            </div>

            <div style={{ marginRight: 60 }}>
              <Typography className='!mt-2.5'>
                Project
              </Typography>
              <Typography className='!mt-2.5'>
                Section
              </Typography>
              <Typography className='!mt-2.5'>
                Entity
              </Typography>
              <Typography className='!mt-2.5'>
                Task
              </Typography>
              <Typography className='!mt-2.5'>
                Report Period
              </Typography>
              <Typography className='!mt-2.5'>
                Value
              </Typography>
            </div>
            <div>
              <Typography className='truncate w-[268px] !mt-2.5'>
                {idx(project, p => p.name)}
              </Typography>
              <Typography className='truncate w-[268px] !mt-2.5'>
                {idx(task, t => t.section.name) || 'No Section'}
              </Typography>
              <Typography className='truncate w-[268px] !mt-2.5 !mr-0'>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left' }}>

                  {idx(task, t => t.tags.length)
                    ? task.tags.map((tag: any) => <TagLabel label={tag.tag} />)
                    : <Typography>
                      {' '}
                      None
                      {' '}
                    </Typography>}

                </div>

              </Typography>
              <Typography className='truncate w-[268px] !mt-2.5'>
                {idx(task, t => t.name)}
              </Typography>
              <Typography className='truncate w-[268px] !mt-2.5'>
                {
                  idx(project, p => p.reconEndDate)
                    ? new Date(project.reconEndDate).toLocaleDateString(undefined,
                      { year: 'numeric', month: 'short', day: 'numeric' }
                    )
                    : new Date().toLocaleDateString(undefined,
                      { year: 'numeric', month: 'short', day: 'numeric' }
                    )
                }
              </Typography>
              <Typography className='truncate w-[268px] !mt-2.5'>
                {balance ?
                  <>
                    <MoneyDisplay
                      style={{ fontWeight: 500 }}
                      value={balance}
                      displaySymbol
                    />
                  </> : '$ 0.00'
                }

              </Typography>
            </div>
          </div>

          <DialogActions>
            <Button
              onClick={denyAction}
              variant='outlined'
            >
              Cancel
            </Button>
            <Button
              autoFocus
              onClick={handleAccept}
              variant='contained'
              disabled={!isFormValid}
            >
              Accept
            </Button>
          </DialogActions>

        </div>
      </Dialog>
    </div>
  )
}

function DropdownIndicator(props: any) {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDownIcon />
    </components.DropdownIndicator>
  );
}

// Disable the select box input to not allow value modification
// when the dimension is not available from the user Intacct dimensions
// after the Api request.
const disableSelectStyles: any = {
  control: () => ({
    alignItems: 'center',
    border: '1px solid hsl(0,0%,80%)',
    borderRadius: '4px',
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: 38,
    position: 'relative',
    transition: 'all 100ms',
    boxSizing: 'border-box',
    backgroundColor: 'hsl(0deg 0% 80% / 44%)'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (provided: any) => ({
    ...provided,
    border: '1px solid hsl(0,0%,80%)',
    boxShadow: 'none',
    marginTop: -1
  }),
}

const selectStyles: any = {
  control: () => ({
    alignItems: 'center',
    border: '1px solid hsl(0,0%,80%)',
    borderRadius: '4px',
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: 38,
    position: 'relative',
    transition: 'all 100ms',
    boxSizing: 'border-box'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (provided: any) => ({
    ...provided,
    border: '1px solid hsl(0,0%,80%)',
    boxShadow: 'none',
    marginTop: -1
  }),
  multiValue: () => ({
    background: '#FFF',
    border: '1px solid hsl(0,0%,80%)',
    borderRadius: '2px',
    display: 'flex',
    margin: '2px',
    padding: '2px',
    minWidth: 0,
    boxSizing: 'border-box'
  }),
  multiValueRemove: (provided: any, state: any) => ({
    //    ...provided,
    background: '#FFF',
    color: 'lightgrey',
    paddingLeft: 4,
    paddingRight: 4,
    alignItems: 'center',
    display: 'flex'
  })
};

function LevelDropdown(props: any) {
  const classes = useGlobalStyles();
  const { state, setState, dimension } = props;
  

  // props.v can be a string || an array
  const emptyProps = !props.v || (Array.isArray(props.v) && !props.v.length);

  // Check if at least one account is selected while editing the dimensions for reconciliation.
  // Ohterwise show warning message alert.
  const isFormValid = props.level.label === 'GL Account' && (Array.isArray(props.v) && !props.v.length);

  return (
    <div className='mb-2.5 flex items-center justify-center'>
      <div className='mb-2.5 flex items-center justify-start'>
        <div className='mr-5 w-5'>
          {(props.showSpinner && !emptyProps)
            ? <CircularProgress size="1.5rem" />
              : (dimension && dimension.length === 0)
                ? <CancelIcon style={{ color: 'green', opacity: 0.1 }}/>
                  : <CircleCheckedFilled style={{ color: 'green', opacity: props.v ? 1 : 0.1 }} />}
        </div>
        <Typography className='w-[140px]'>
          {props.level.label}
        </Typography>
        <div
          className={classes.dropdownContainer}
          style={{ width: 400 }}
        >
          {props.level.component
            ? props.level.component({ state, setState })
            : <Select
                components={{ DropdownIndicator }}
                isClearable={false}
                isDisabled={dimension && dimension.length === 0}
                isMulti={props.level.isMulti}
                onChange={props.set}
                options={props.options}
                styles={dimension && dimension.length === 0 ? disableSelectStyles : selectStyles}
                value={props.v}
                isOptionDisabled={(option: any) => option.isDisabled}
                id={props.level.label.split(' ').join('_').toLowerCase().concat('_select')}
              />
          }
          { isFormValid ? <span className='text-xs text-red-500'>Please select an account to proceed</span> : null }
        </div>
      </div>
    </div>
  )
}
