import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

import useLogFileDownload from '../../../graphql/mutations/LogFileDownload';
import {
  Typography,
  Tooltip,
  Button,
  Divider,
  Collapse,
  ListItem,
  ListItemText,
  List
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ActiveFileOptionsPopper from './ActiveFileOptionsPopper';

import GetApp from '@material-ui/icons/GetApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { Skeleton } from '@material-ui/lab';

import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

import { FileIcon } from '../FileIcon';
import TaskFileTogglePopper from './TaskFileTogglePopper';
import { useDebounce } from '../../../hooks/useDebounce';

const StyledBadge = withStyles({
  badge: {
    top: '12%',
    right: '12px',
    minWidth: '10px',
    height: '10px',
    backgroundColor: '#6EB81D',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#FFFFFF',
    letterSpacing: '0',
    textAlign: 'center',
  },
})(Badge);

const CheckedOutBadge = withStyles({
  badge: {
    top: '95%',
    right: '35px',
    minWidth: '10px',
    height: '10px',
    backgroundColor: '#e6d222',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#FFFFFF',
    letterSpacing: '0',
    textAlign: 'center',
  },
})(Badge);

const ProcessingBadge = withStyles({
  badge: {
    top: '95%',
    right: '35px',
    minWidth: '10px',
    height: '10px',
    backgroundColor: 'deepskyblue',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#FFFFFF',
    letterSpacing: '0',
    textAlign: 'center',
  },
})(Badge)

const newStyles = makeStyles((theme: Theme) => {
  return createStyles({
    flexRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    fileRow: {
      height: '50px',
      cursor: 'default'
    },
    fileRowText: {
      color: "#606060",
      fontSize: '12px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      lineHeight: 1.6
    },
    fileRowName: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    moreIcon: {
      float: 'right',
      color: '#606060'
    },
    expandIcons: {
      color: "#606060",
      fontSize: '18px'
    },
    fileIcon: {
      height: '25px'
    },
    downloadAllFiles: {
      flex: '1',
      minWidth: '120px',
      '&:hover': {
        cursor: 'pointer',
        margin: '0 2px',
        backgroundColor: '#FFF',
        border: '1px solid #3A84FF',
        borderRadius: '3px',
      },
    }
  })
});

interface ActiveFilesProps {
  files: any[];
  filesLoading: boolean;
  task: any;
  tasks: any;
  updateTaskStatus: any;
  updateStatusAfterUpload: any;
  updateTask: any;
  updateTaskInCache: any;
  setTasks: any;
  archivedFiles: any[];
  setArchivedFiles: any;
  currentPage: any;
  lists: any;
  setLists: any;
  isExternalUser: boolean;
  downloadAllFiles?: any;
  downloadAll?: boolean;
  fileDisplay?: any;
  setFileDisplay?: any;
  isRequester?: boolean;
  onUpdateFile?(context: string, id: string): void
}

/**
 * Reusable `ActiveFiles` component.
 * This component is using the `TailwindCSS` design pattern.
 *
 * @return {React.JSXElementConstructor} The `ActiveFiles` component.
 */
export default function  ActiveFiles(props: ActiveFilesProps) {
  const { 
    files, 
    filesLoading, 
    task, 
    tasks, 
    setTasks, 
    updateTaskStatus, 
    updateStatusAfterUpload, 
    updateTask,
    updateTaskInCache,
    archivedFiles, 
    setArchivedFiles, 
    currentPage, 
    lists, 
    setLists, 
    isExternalUser,
    downloadAllFiles, 
    downloadAll,
    fileDisplay, 
    setFileDisplay, 
    isRequester,
    onUpdateFile
  } = props;

  const classes = newStyles();

  const [open, setOpen] = useState(true);

  const [onClose, setOnClose] = useState(() => { return () => { } });
  const [selectedFile, setSelectedFile] = useState<{ id: string, fileName: string, taskId: string }>();

  const [anchorEl, setAnchorEl] = useState();

  const handleEllipsesClick = (event: any, file: any) => {
    const newOnClose = () => {
      setAnchorEl(undefined);
      setSelectedFile(undefined);
    }

    setOnClose(() => { return newOnClose })

    setAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };

  const [onCloseTogglePopper, setOnCloseTogglePopper] = useState(() => { return () => { } });
  const [anchorElTogglePopper, setAnchorElTogglePopper] = useState();

  const handleTaskFilesToggleClick = (event: any) => {
    const newOnCloseTogglePopper = () => {
      setAnchorElTogglePopper(undefined);
    }

    setOnCloseTogglePopper(() => { return newOnCloseTogglePopper })
    setAnchorElTogglePopper(event.currentTarget);
  };

  const [
    logFileDownload,
    { loading: logLoading, data: logData, error: logError },
  ] = useLogFileDownload({ id: selectedFile?.id, filename: selectedFile?.fileName, taskId: selectedFile?.taskId });


  const handleFileDownload = (file: any) => {
    setSelectedFile({
      id: file.id,
      fileName: file.fileName,
      taskId: file.taskId
    })
  }

  useEffect(() => {
    if (selectedFile) {
      logFileDownload();
    }
  }, [selectedFile])

  useEffect(() => {
    //Maybe do something after download
    if (logData) {
    }
  }, [logData])

  const handleDownloadAllFiles = useDebounce(() => {
    downloadAllFiles()
  });

  return (
    <>
      <ActiveFileOptionsPopper 
        file={selectedFile} 
        task={task} 
        tasks={tasks} 
        setTasks={setTasks} 
        updateTaskStatus={updateTaskStatus} 
        updateStatusAfterUpload={updateStatusAfterUpload} 
        anchorEl={anchorEl} 
        onClose={onClose} 
        updateTask={updateTask} 
        updateTaskInCache={updateTaskInCache}
        archivedFiles={archivedFiles} 
        setArchivedFiles={setArchivedFiles} 
        currentPage={currentPage} 
        lists={lists} 
        setLists={setLists} 
        isExternalUser={isExternalUser} 
        onUpdateFile={onUpdateFile}
      />
      <TaskFileTogglePopper 
        onClose={onCloseTogglePopper} 
        anchorEl={anchorElTogglePopper} 
        fileDisplay={fileDisplay} 
        setFileDisplay={setFileDisplay} 
        isRequester={isRequester} 
      />
      <ListItem 
        onClick={(e) => e.target.tagName.toLowerCase() === "div" && setOpen(!open)} 
        className={classes.fileRow}
      >
        <ListItemText 
          className={"!grow-0 !shrink-0 !basis-auto"}
          primary={"Active Files"} 
          primaryTypographyProps={{ className: classes.fileRowText }} 
        />
        <div 
          className='flex justify-between items-center w-full'
          style = {{ paddingLeft: '5.5rem', paddingRight: '5.5rem'}}
        >
          <Button
            onClick={handleDownloadAllFiles}
            className={classes.downloadAllFiles}
            disabled={downloadAll}
          >
            Download all files
          </Button>
        </div>
        <div style={{ flex: 0 }}>
          {currentPage === "Task" && 
            <MoreHorizIcon 
              className={classes.moreIcon} 
              style={{ cursor: 'pointer' }} 
              onClick={(e) => handleTaskFilesToggleClick(e)} 
            />
          }
        </div>
      </ListItem>
      <Divider />
      <Collapse in={open}>
        {filesLoading ? (
          <>
            <ListItem>
              <Skeleton variant="rect" width="7%" height="34px" style={{ marginRight: '10px' }} />
              <Skeleton variant="text" width="38%" height="20px" style={{ marginRight: '40px' }} />
              <Skeleton variant="text" width="24%" height="20px" style={{ marginRight: '30px' }} />
              <Skeleton variant="rect" width="5%" height="20px" style={{ marginRight: '10px' }} />
              <Skeleton variant="rect" width="5%" height="20px" />
            </ListItem>
            <Divider />
          </>
        ) : (
          <List component='div' disablePadding>
            {files && files.length ? (
              <>
                {files.map((file: any, index: number) => {
                  let fileIconTooltip = '';

                  if (file.isCheckedOut && file.isCheckedOut !== 'false') {
                    fileIconTooltip = "The file is currently checked out"
                  }

                  if (!file.alterable) {
                    fileIconTooltip = "The file is currently being processed"
                  }

                  const handleDownloadFile = () => {
                    logFileDownload({
                      variables: {
                        id: file?.id,
                        filename: file?.fileName,
                        taskId: file?.taskId
                      }
                    })
                    window.open(file.fileLocation, '_blank');
                  }

                  return (
                    <>
                      <ListItem className={clsx(classes.fileRow, classes.fileRowText, classes.flexRow)} key={index} >
                        <Tooltip title={fileIconTooltip}>
                          <StyledBadge variant={!file.viewed ? 'dot' : undefined}>
                            <CheckedOutBadge
                              variant={
                                file.isCheckedOut && file.isCheckedOut !== 'false'
                                  ? 'dot'
                                  : undefined
                              }
                            >
                              <ProcessingBadge variant={!file.alterable ? 'dot' : undefined}>
                                <FileIcon fileName={file.fileName} className={classes.fileIcon} style={{ flex: 1, marginRight: '10px' }} />
                              </ProcessingBadge>
                            </CheckedOutBadge>
                          </StyledBadge>
                        </Tooltip>
                        <Tooltip title={file.fileName}>
                          <Typography className={clsx(classes.fileRowText, classes.fileRowName)} style={{ flex: 8, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', textAlign: 'left', marginRight: '40px' }} onClick={handleDownloadFile}>
                            {file.fileName}
                          </Typography>
                        </Tooltip>
                        <Typography className={classes.fileRowText} style={{ flex: 5, textAlign: 'right', marginRight: '30px', opacity: 0.5 }}>
                          {file.updatedAt}
                        </Typography>
                        <a target="_blank" data-cy="task-file-download" href={file.fileLocation} onClick={() => handleFileDownload(file)} style={{ flex: 1, marginRight: '10px' }}>
                          <GetApp style={{ display: 'flex', color: 'dimgray' }} />
                        </a>
                        <MoreHorizIcon style={{ flex: 1, cursor: 'pointer' }} onClick={(e) => handleEllipsesClick(e, file)} />
                      </ListItem>
                      <Divider />
                    </>
                  )
                })}
              </>
            ) : (
              <>
                <ListItem className={clsx(classes.fileRow, classes.fileRowText)} style={{ justifyContent: 'center' }} >There are currently no active files associated with this task.</ListItem>
                <Divider />
              </>
            )}
          </List>
        )}
      </Collapse>
    </>
  )

}