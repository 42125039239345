import React, { useEffect, useState } from 'react';
import idx from 'idx';
import clsx from 'clsx';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Paper, Divider, List } from '@material-ui/core';

import useArchiveFile from '../../../graphql/mutations/ArchiveFile';
import useUpdateFileCheckout from '../../../graphql/mutations/UpdateFileCheckout';

import useLazyArchivedFileVersions from '../../../graphql/queries/ArchivedFiles';

import useLazyDownloadAllFiles from '../../../graphql/queries/DownloadAllFiles'

import * as cs from '../../../constants/theme';
import { useDispatchContext, useStateContext } from '../../../store';

import { useSuccess } from '../../../hooks/Toast';

import CircularLoader from '../CircularLoader';
import { ReconciliationSection } from '../../task/NewTaskPage/components/SidePanel/components/Reconciliation/ReconciliationSection';

import FluxAnalysis from './FluxAnalysis';
import TaskFiles from './TaskFiles';
import { useListFilesQuery } from '../../../graphql/queries/DetailedListInfo';
import { INTEGRATION_SOURCE_TYPE } from '../../../constants/integration_source_type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'none',
      height: '100%',
      overflow: 'auto',
      paddingBottom: '100px',
    },
    grow: {
      flexGrow: 1,
      height: '100%',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: '12px',
      height: '25px'
    },
    modified: {
      opacity: 0.5,
      color: '#606060',
      marginRight: '40px',
    },
    download: {
      cursor: 'pointer',
      marginLeft: '12px',
      color: 'dimgray',
    },
    popper: {
      zIndex: 9999,
      position: 'absolute',
      width: 'fit-content',
      top: '45px',
      right: '12px',
      backgroundColor: '#FFFFFF',
      border: '2px solid #D8D8D8',
      borderRadius: '3px',
    },
    card: {
      width: 275,
      height: 100,
      position: 'absolute',
      boxShadow: 'none',
      left: '50%',
      transform: 'translate(-50%, -30%)',
    },
    content: {
      padding: 0,
    },
    text: {
      fontFamily: cs.FONT.family,
      fontSize: '12px',
      fontWeight: 500,
    },
    fileBorder: {
      border: '3px dashed #D8D8D8',
      padding: 10,
      height: 'calc(100% - 50px)',
      paddingBottom: '175px',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '10%',
    },
    downloadAllFiles: {
      minWidth: '120px',
      '&:hover': {
        cursor: 'pointer',
        margin: '0 2px',
        backgroundColor: '#FFF',
        border: '1px solid #3A84FF',
        borderRadius: '3px',
      },
    },
    downloadAllDivider: {
      height: '0.5px',
      marginTop: '5px',
    },
    noHover: {
      '&:hover': {
        background: 'none',
      },
    },
  })
);

interface FilesPaneProps {
  lists?: any;
  setLists?: any;
  value?: number;
  index?: number;
  task?: any;
  filesSeen?: boolean;
  tasks?: any;
  allTasks?: any;
  setTasks?: any;
  updateTaskStatus: any;
  updateStatusAfterUpload: any;
  currentPage?: any;
  updateAfterComplete?: any; // function to update the DOM after version change
  selectedItem?: any;
  isCreateProject?: boolean;
  taskListPrimaryDisplaySkeleton?: any;
  taskListSecondaryDisplaySkeleton?: any;
  taskListReconcilingItemsDisplaySkeleton?: any;
  setTaskListPrimaryDisplaySkeleton?: any; //Dispatch
  setTaskListSecondaryDisplaySkeleton?: any;
  setTaskListReconcilingItemsDisplaySkeleton?: any;
  detailedTaskLoading?: any;
  updateTaskInCache?: any
  onPopoverLeave?: any;
  paneIndex?: number;
  sidePanelIsOpened?: boolean;
}

export default function FilesPane(props: FilesPaneProps) {
  const classes = useStyles();
  const dispatch = useDispatchContext()
  const state = useStateContext();

  const success = useSuccess();
  const {
    value,
    index,
    task,
    tasks,
    allTasks,
    setTasks,
    updateTaskStatus,
    updateStatusAfterUpload,
    currentPage,
    selectedItem,
    lists,
    setLists,
    taskListPrimaryDisplaySkeleton,
    taskListSecondaryDisplaySkeleton,
    taskListReconcilingItemsDisplaySkeleton,
    setTaskListPrimaryDisplaySkeleton,
    setTaskListSecondaryDisplaySkeleton,
    setTaskListReconcilingItemsDisplaySkeleton,
    detailedTaskLoading,
    updateTaskInCache,
    onPopoverLeave,
    paneIndex,
    sidePanelIsOpened
  } = props;

  if (task && Object.keys(task).length !== 0) {
    var rows = task.files;
  }
  
  const [toDelete, setToDelete] = useState<string>('');
  const [toCheckOut, setToCheckOut] = useState<string>('');
  const [toCheckIn, setToCheckIn] = useState<string>('');
  const [deleteFile, res] = useArchiveFile({ fileId: toDelete });
  const [checkOutFile, checkOutRes] = useUpdateFileCheckout({
    fileVersionId: toCheckOut,
    isCheckedOut: true,
  });
  const [checkInFile, checkInRes] = useUpdateFileCheckout({
    fileVersionId: toCheckIn,
    isCheckedOut: false,
  });

  const [fileVersionIds, setFileVersionIds] = useState<string[]>([]);
  const [downloadId, setDownloadId] = useState<string>('');

  const [lazyDownloadAllFiles, { loading: downloadAllFilesLoading, data: downloadAllFilesData, error: downloadAllFilesError }] = useLazyDownloadAllFiles({ fileVersionIds, currentPage, fileDownloadReportId: downloadId })

  const [downloadAll, setDownloadAll] = useState<boolean>(false);

  const [archivedFiles, setArchivedFiles] = useState([]);
  const [getArchivedFiles, { loading: archivedFilesLoading, data: archivedFilesData }] = useLazyArchivedFileVersions();

  // Query hook that fetch the available files associated to a given list with `id`.
  const [getListFiles, { loading: detailedListFilesLoading, data: detailedListFilesData, error: detailedListFilesError, called: detailedListFilesCalled }] = useListFilesQuery({id: selectedItem.id});

  const [currentFiles, setCurrentFiles] = useState<any>([]);

  useEffect(() => {
    if (!detailedListFilesLoading) {
      if(detailedListFilesData?.list?.files) {
        setCurrentFiles(detailedListFilesData?.list?.files);
      }
    }
  }, [detailedListFilesLoading]);

  useEffect(() => {
    if (!archivedFilesLoading && archivedFilesData) {
      const archivedFiles = archivedFilesData.archivedFileVersions;
      setArchivedFiles(archivedFiles);
    }
  }, [archivedFilesLoading, archivedFilesData])

  useEffect(() => {
    // Check to prevent users associated with the requester company from seeing archived files
    const isRequester = task?.companyPosition === 'requester';
    if (task?.id && !isRequester) {
      getArchivedFiles({
        variables: {
          taskId: task.id
        }
      })
    }
  }, [task.id]);

  useEffect(() => {
    if (currentPage === 'List' && selectedItem?.id) {
      getListFiles();
    }
  }, [selectedItem?.id]);

  useEffect(() => {
    if (toDelete.length) {
      deleteFile();
      if (!tasks && props.lists) {
        setCurrentFiles(currentFiles.filter((f: any) => f.id != toDelete)); 
        setToDelete('');
        getListFiles();
      } else {
        var newTasks = tasks.slice();//
        newTasks.find((t: any) => t.id === task.id).files = newTasks
          .find((t: any) => t.id === task.id)
          .files.filter((f: any) => f.id !== toDelete);
        var task5 = newTasks.find((t: any) => t.id === task.id);
        task5.files = task5.files.filter((f: any) => f.id !== toDelete);
        setTasks(newTasks);
        setToDelete('');
      }
    }
  }, [toDelete]);

  useEffect(() => {
    if (toCheckOut !== '') {
      checkOutFile();
      if (!tasks && props.lists) {
        setCurrentFiles(currentFiles.find((f: any) => f.id === toCheckOut).isCheckedOut = true);
      } else {
        var newTasks = tasks.slice();
        newTasks
          .find((t: any) => t.id === task.id)
          .files.find((f: any) => f.id === toCheckOut).isCheckedOut = true;
        var nTask = newTasks.find((t: any) => t.id === task.id);
        nTask.files.find((f: any) => f.id === toCheckOut).isCheckedOut = true;
        setTasks(newTasks);
      }
      setToCheckOut('');
    } else {
    }
  }, [toCheckOut]);

  useEffect(() => {
    if (toCheckIn.length) {
      checkInFile();
      if (!tasks && props.lists) {
        setCurrentFiles(currentFiles.find((f: any) => f.id === toCheckIn).isCheckedOut = false);
      } else {
        var newTasks = tasks.slice();
        newTasks
          .find((t: any) => t.id === task.id)
          .files.find((f: any) => f.id === toCheckIn).isCheckedOut = false;
        var nTask = newTasks.find((t: any) => t.id === task.id);
        nTask.files.find((f: any) => f.id === toCheckIn).isCheckedOut = false;
        setTasks(newTasks);
      }
      setToCheckIn('');
    }
  }, [toCheckIn]);

  useEffect(() => {
    if (fileVersionIds.length) {
      dispatch({
        type: 'SET_FILE_DOWNLOAD_STATUS_STATE',
        fileDownloadStatusState: {
          isOpen: true,
          type: 'downloadAllFiles',
          payload: null,
          downloadId: 0
        }
      });
      lazyDownloadAllFiles();
    }
  }, [fileVersionIds])

  useEffect(() => {
    if (downloadAllFilesData && !downloadAllFilesLoading) {
      if (downloadAllFilesData.downloadAllFiles.success) {
        if (downloadAllFilesData.downloadAllFiles.url) {
          dispatch({
            type: 'SET_FILE_DOWNLOAD_STATUS_STATE',
            fileDownloadStatusState: {
              isOpen: false,
              type: '',
              payload: null,
              downloadId: 0
            },
          });
          window.open(downloadAllFilesData.downloadAllFiles.url, '_blank');
        } else if (downloadAllFilesData.downloadAllFiles.downloadId) {
          if (downloadAllFilesData.downloadAllFiles.downloadId === downloadId) {
            lazyDownloadAllFiles();
          } else {
            setDownloadId(downloadAllFilesData.downloadAllFiles.downloadId);
          }
        }
      }
    }
  }, [downloadAllFilesLoading, downloadAllFilesData]);

  useEffect(() => {
    if (downloadId) {
      lazyDownloadAllFiles();
    }
  }, [downloadId]);

  async function downloadAllFiles() {
    if (currentPage === 'List') {
      const ids = currentFiles.map((file: any) => file.id); 
      setFileVersionIds(ids)
    } else {
      success('Your files are being prepared for download');
      const ids = rows.map((file: any) => file.id);
      setFileVersionIds(ids)
    }
  }

  // conditions under which a user can see the recociliation box
  const reconciliationVisible = idx(state, s => {
    const { reconciliations } = task.list;
    const { companyPosition } = task;

    if (
      reconciliations &&
      (companyPosition == 'responder' || companyPosition == 'internal') &&
      Number(task.reconciliations)
    ) {
      return true;
    }

    if (
      reconciliations === '1' &&
      companyPosition == 'requester' &&
      task.responderDelivered
    ) {
      return true;
    }
  });

  const updateTask3 = (task: any) => {
    const tasksClone = tasks.map((t: any) => ({ ...t }));
    const index = tasksClone.findIndex((t: any) => t.id === task.id);
    if (index !== -1) {
      tasksClone[index] = { ...tasksClone[index], ...task };
      updateTaskInCache({ ...tasksClone[index], ...task });
      setTasks(tasksClone);
    }
  }

  const onUpdateFile = (context: string, id: string) => {
    switch (context) {
      case 'deleted':
        setCurrentFiles(currentFiles.filter((f: any) => f.id != id));
        break;
      case 'checkedOut':
        setCurrentFiles(currentFiles.map((it:any) => {
          if (it.id === id) {
            return {...it, isCheckedOut: true };
          }
          return it;
        }));
        break;
      case 'checkedIn':
        setCurrentFiles(currentFiles.map((it:any) => {
          if (it.id === id) {
            return {...it, isCheckedOut: false };
          }
          return it;
        }));
        break;
    }
  }

  const [fileDisplay, setFileDisplay] = useState<string>("Active");
  const isRequester = task?.companyPosition === 'requester';

  const taskHasSummaryAndPrimarySource = task?.taskDataSummary && [INTEGRATION_SOURCE_TYPE.INTACCT, INTEGRATION_SOURCE_TYPE.NETSUITE, INTEGRATION_SOURCE_TYPE.QBO].includes(task?.taskDataSummary?.primarySourceType);

  return (
    <Paper
      className={clsx(classes.root)}
      role="tabpanel"
      hidden={value !== index}
      id={`tab-${index}`}
      aria-labelledby={`tab-${index}`}
      elevation={0}
      style={Boolean(props.isCreateProject) ? { height: 'calc(100% - 64px)', paddingBottom: 0 } : undefined}
    >
      {detailedListFilesLoading || !selectedItem ? (
        <CircularLoader height={'35vh'} />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            paddingBottom: Boolean(props.isCreateProject) ? '0px' : '210px',
          }}
        >
          {reconciliationVisible && (
            <ReconciliationSection
              task={task}
              tasks={tasks}
              allTasks={allTasks}
              setTasks={setTasks}
              updateTaskInCache={updateTaskInCache}
              updateTask={updateTask3}
              updateTaskStatus={updateTaskStatus}
              taskListPrimaryDisplaySkeleton={taskListPrimaryDisplaySkeleton}
              taskListSecondaryDisplaySkeleton={taskListSecondaryDisplaySkeleton}
              taskListReconcilingItemsDisplaySkeleton={taskListReconcilingItemsDisplaySkeleton}
              lists={lists} 
              setTaskListPrimaryDisplaySkeleton={setTaskListPrimaryDisplaySkeleton}
              setTaskListSecondaryDisplaySkeleton={setTaskListSecondaryDisplaySkeleton}
              setTaskListReconcilingItemsDisplaySkeleton={setTaskListReconcilingItemsDisplaySkeleton}
              onPopoverLeave={onPopoverLeave}
            />
          )}
          
          {reconciliationVisible && taskHasSummaryAndPrimarySource ? (
            <FluxAnalysis task={task} displaySkeleton={taskListPrimaryDisplaySkeleton || taskListSecondaryDisplaySkeleton} />
          ) : (
            <Divider /> 
          ) }

          <List style={{ width: '100%', paddingTop: 0 }}>
            <TaskFiles 
              paneIndex={paneIndex}
              sidePanelIsOpened={sidePanelIsOpened}
              currentPage={currentPage} 
              reconciliationVisible={reconciliationVisible} 
              downloadAllFiles={downloadAllFiles} 
              downloadAll={downloadAll} 
              fileDisplay={fileDisplay} 
              setFileDisplay={setFileDisplay} 
              isRequester={isRequester} 
              files={currentPage === 'List' ? currentFiles : rows} 
              filesLoading={detailedTaskLoading} 
              task={task} 
              tasks={tasks} 
              updateTaskStatus={updateTaskStatus} 
              updateStatusAfterUpload={updateStatusAfterUpload} 
              updateTask={updateTask3}
              updateTaskInCache={updateTaskInCache}
              setTasks={setTasks} 
              archivedFiles={archivedFiles} 
              setArchivedFiles={setArchivedFiles} 
              lists={lists} 
              setLists={setLists} 
              isExternalUser={state.currentUser.isExternalUser} 
              archivedFilesLoading={archivedFilesLoading} 
              onUpdateFile={currentPage === 'List' ? onUpdateFile : () => null}
            />
          </List>
        </div>
      )}
    </Paper>
  );
}
