import { gql } from '@apollo/client';

export const netsuiteValueQuery = gql`
  query NetsuiteValue (
    $subsidiaryNames: [String!]
    $departmentNames: [String!]
    $classNames: [String!]
    $accountIds: [ID!]
    $itemNames: [String!]
    $entityNames: [String!]
    $bookNames: [String!]
    $startDate: String
    $endDate: String
  ) {
    netsuiteValue (
      subsidiaryNames: $subsidiaryNames,
      departmentNames: $departmentNames,
      classNames: $classNames,
      accountIds: $accountIds,
      itemNames: $itemNames,
      entityNames: $entityNames,
      bookNames: $bookNames,
      startDate: $startDate,
      endDate: $endDate
    ) {
        value
        currency
      }
  }
`;

