import { gql} from '../graphqlHelpers';

type ID = number | string;

export interface CopyWorkspaceVariables {
  workspaceId: ID;
  destinationWorkspaceId: ID;
}

export interface Error {
  path: string;
  message: string;
}

export interface CopyWorkspaceData {
  success: boolean;
  errors: Error[];
}

export const copyWorkspace = gql `
mutation copyWorkspace($workspaceId: ID!, $destinationWorkspaceId: ID!) {
  copyWorkspace(workspaceId: $workspaceId, destinationWorkspaceId: $destinationWorkspaceId) {
    success
    errors {
      path
      message
    }
  }
}
`;