
import { gql} from '../graphqlHelpers';

export const createWorkspace = gql `
  mutation createWorkspace($name: String!, $description: String, $spaceId: ID!, $isDashboard: Boolean, $isInternal: Boolean){
    createWorkspace(name: $name, description: $description, spaceId: $spaceId, isDashboard: $isDashboard, isInternal: $isInternal){
      success
      errors{
        path
        message
      }
    }
  }`;