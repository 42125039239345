import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FlashMessage from '../common/FlashMessage';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import idx from 'idx';
import Link from '@material-ui/core/Link';
import React, {useCallback, useState, useEffect} from 'react';
import {withRouter} from 'react-router';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import {Theme, makeStyles, createStyles, withStyles} from '@material-ui/core/styles';
import {useSignInUser} from '../../graphql/mutations/SignInUser';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useStateContext } from '../../store';
import OktaSignInButton from './OktaSignInButton';
import { useLazyGetEmailRememberMe } from '../../graphql/queries/GetRememberEmail';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

    },
    flash: {
      marginBottom: theme.spacing(1),
    },
    form: {
      width: '86%',
      marginLeft: '7%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      boxShadow:
        'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px',
    },
    socialGmail: {
      fontSize: '14px',
      width: '100%',
      textAlign: 'center',
      marginBottom: 10,
      height: 43,
      borderRadius: 2,
      justifyContent: 'flex-start',
      display: 'flex',
      cursor: 'pointer',
      backgroundColor: '#0F3B5C',
      alignItems: 'center'
    },
    socialLinkedIn: {
      fontSize: '14px',
      width: '100%',
      // marginLeft: '10%',
      marginBottom: 10,
      backgroundColor: '#0F3B5C',
      color: 'white',
      height: 43,
      border: 2,
      borderRadius: 2,
      cursor: 'pointer',
      display: 'flex',
      boxShadow:
        'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px',
      fontWeight: 500,
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingLeft: 0,
      paddingRight: 0,
    },
    linkedInText: {
      marginRight: 10,
      display: 'inline',
      fontWeight: 700,
      color: 'white'
    },
    prepLink: {
      textDecoration: 'none',
      color: '#0F3B5C',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    signInTitle: {
      textAlign: 'center',
    },
    socialButtons: {
      width: '86%',
      marginLeft: '7%',
      marginTop: '15px',
      justifyContent: 'center'
    },
    socialButtonsGrid: {
      maxWidth: '100%',
      flexBasis: '80%',
      marginBottom: '10px',
    },
    notchedOutline: {},
    focused: {
      "& $notchedOutline": {
        borderColor: "#0F3B5C"
      }
    }
  })
);

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: "#0F3B5C",
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: "#0F3B5C",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: "#0F3B5C",
      },
      '&:hover fieldset': {
        borderColor: "#0F3B5C",
      },
      '&.Mui-focused fieldset': {
        borderColor: "#0F3B5C",
      },
    },
  },
})(TextField);

const SignInPage = (props: any) => {
  const state = useStateContext();
  const isAuthenticated = state.currentUser.email ? true : false;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const authMinTimeAlive = queryParams.get('mta');
  
  // The react context Okta sign in response from the Okta sign in widget
  const oktaSignInResponse = state.oktaSignInResponse;

  const classes = useStyles({});

  const [signInState, setSignInState] = useState<{
    email: string;
    password: string;
    remember: boolean;
    socialLogin: boolean;
    tokenId: string;
    provider: string;
    uuId: string;
    companyName: string;
    workspaceId: string;
  }>({
    email: '',
    password: '',
    remember: false,
    socialLogin: false,
    tokenId: '',
    provider: '',
    uuId: '',
    companyName: '',
    workspaceId: ''
  });
  const [customMessage, setCustomMessage] = useState<string>("");

  const { isLoading } = useAuth0();

  const [signInUser, {loading, data, error}] = useSignInUser({
    email: signInState.email,
    password: signInState.password,
    socialLogin: signInState.socialLogin,
    tokenId: signInState.tokenId,
    provider: signInState.provider,
    uuId: signInState.uuId,
    companyName: signInState.companyName,
    timeStamp: (new Date()).toString(),
    workspaceId: signInState.workspaceId,
    rememberMe: !!signInState.remember,
  });

  const [getEmailRememberMe, {}] = useLazyGetEmailRememberMe({});

  useEffect(() => {
    getEmailRememberMe().then((response: any) => {
      const emailRememberMe = response?.data?.getEmailRememberMe;
      if (emailRememberMe) {
        setSignInState(state => ({...state, email: emailRememberMe, remember: true}));
      }
    });
  }, []);

  // handle message to display when the user has been auto signed-out
  useEffect(() => {
    if (authMinTimeAlive) {
      setCustomMessage("You have been signed-out due to inactivity.")
    } else {
      setCustomMessage("")
    }
  }, [authMinTimeAlive])

  // Success handling for the Okta sign in
  useEffect(() => {
    if (oktaSignInResponse) {
      const { user, accessToken } = oktaSignInResponse;
      successOktaSignIn(user, accessToken);
    }
  }, [oktaSignInResponse]);

  useEffect(() => {
    var url = window.location.href;

    if(isAuthenticated){
      window.location.href = '/app/dashboard';
    }
    const signInUser = idx(data, data => data.signInUser?.currentUser);
    
    if (isLoading || loading || !signInUser) return;
    if (!url.includes('/app/')) {
      window.location.href = '/app/dashboard';
    }
    else window.location.href = url;
  }, [isLoading, loading, idx(data, data => data.signInUser)]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const companyName = url.searchParams.get('companyName');
    const workspaceId = url.searchParams.get('workspaceId');
    if (companyName !== null) {
      setSignInState(state => ({...state, companyName: companyName}));
      if(workspaceId !== null){
        setSignInState(state => ({...state, workspaceId: workspaceId}));
      }
    }
  }, []);

  const errors = idx(data, x => x.signInUser.errors);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
			/* TODO, find a more elegant way to do this */

			resetModals();

      signInUser();
    },
    [signInUser]
  );

	const resetModals = () => {
		sessionStorage.clear();
		sessionStorage.setItem('nagPastEnd', 'true')
		sessionStorage.setItem('nagReview', 'true')
		sessionStorage.setItem('nagExpired', 'true')
	}

  const onChangeInput = useCallback(
    e => {
      let {name, value} = e.target;
      if (name === "remember") {
        value = !!!signInState.remember;
      }
      setSignInState(state => ({...state, [name]: value}));
    },
    [signInState]
  );

  const responseGoogle = (response: string) => {
  };

  function isGoogleLoginResponse(arg: any): arg is GoogleLoginResponse {
    return arg.getBasicProfile !== undefined;
  }

  const successGoogle = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
		resetModals();
    if (isGoogleLoginResponse(response)) {
      setSignInState(state => ({
        ...state,
        email: response.getBasicProfile().getEmail(),
        fullName: response.getBasicProfile().getName(),
        socialLogin: true,
        provider: 'gmail',
        tokenId: response.getAuthResponse().id_token,
        uuId: response.getBasicProfile().getId(),
      }));
      signInUser();
    }
  };

  // Success handling for Okta sign in
  const successOktaSignIn = (user: any, accessToken: string) => {
    setSignInState(state => ({
      ...state,
      email: user.email,
      fullName: user.name || user.nickname,
      socialLogin: true,
      provider: 'okta_oauth',
      tokenId: accessToken,
      uuId: user.sub,
    }));
    signInUser();
  };

  return (
    <Container component="main" maxWidth="xl">
      <div className={classes.paper}>
        <Grid container spacing={5}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <div className={classes.signInTitle}>
              <Typography component="h1" variant="h5">
                Sign In
              </Typography>
            </div>
            <form className={classes.form} noValidate onSubmit={onSubmit}>
              {customMessage && 
                <FlashMessage
                  className={classes.flash}
                  variant="warning"
                  message={customMessage}
                />
              }
              {errors &&
                errors.map((error, index) => (
                  <FlashMessage
                    key={index}
                    className={classes.flash}
                    variant="warning"
                    message={error.message}
                  />
                ))}
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={onChangeInput}
                value={signInState.email}
              />
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"

                autoComplete="current-password"
                onChange={onChangeInput}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="remember"
                    color="primary"
                    onChange={onChangeInput}
                    checked={signInState.remember}
                    value={signInState.remember}
                  />
                }
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{background: "#3A84FF"}}
                className={classes.submit}
              >
                Sign In
              </Button>
            </form>

            <Grid className={classes.socialButtons} container>
              <Grid item xs={6} lg={6} md={6} className={classes.socialButtonsGrid}>
                <GoogleLogin
                  clientId="1090849701177-kq5gufe0g2vssa71lu9jkg1tid11k6ib.apps.googleusercontent.com"
                  buttonText="Sign In Gmail"
                  render={renderProps => (
                    <div onClick={renderProps.onClick} className={classes.socialGmail} color='primary'>
                      <div style={{display: 'flex', width: '50px', backgroundColor: "#0F3B5C", alignItems: 'center', justifyContent: 'center'}}>
                        <div style={{width: '30px', backgroundColor: 'white', display: 'flex', height: '30px', justifyContent: 'center', alignItems: 'center'}}>
                          <SvgIcon style={{ height: 24, color: '#0F3B5C', alignItems: 'center', justifyContent: 'center'}}>
                            <path d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z" />
                          </SvgIcon>
                        </div>
                      </div>
                      <Typography variant="h5" style={{width: '80%', textAlign: 'center', justifyContent: 'center', color: 'white', cursor: 'pointer'}}>
                        Sign in with Gmail
                      </Typography>
                    </div>
                  )}
                  onSuccess={successGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                  className={classes.socialGmail}
                />
              </Grid>

              {/* Okta login button*/}

              <Grid item xs={6} lg={6} md={6} className={classes.socialButtonsGrid}>
                <Grid item>
                  <OktaSignInButton />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs>
                  <Link component={RouterLink} variant="body2" to="/forgot"   className={classes.prepLink}>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <a
                    className={classes.prepLink}
                    href={'https://www.prepdd.com/pricing'}
                  >
                    {"Don't have an account? Sign Up"}
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default withRouter(SignInPage);
