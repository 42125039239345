import { gql} from '../graphqlHelpers';

type ID = number | string;

export interface RemoveReportsVariables {
  dataReportId: ID;
  deleteForAll?: boolean;
}

export interface Error {
  path: string;
  message: string;
}

export interface RemoveReportData {
  success: boolean;
  errors: Error[];
}

export const removeReport = gql `
  mutation removeReport($dataReportId: ID!, $deleteForAll: Boolean){
    removeReport(dataReportId: $dataReportId, deleteForAll: $deleteForAll){
      success
      errors{
        path
        message
      }
    }
  }`;