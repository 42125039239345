import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import CloseIcon from '@material-ui/icons/Close';

import * as cs from '../../constants/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: '36px 36px 24px 36px',
    },
    back: {
      color: cs.COLORS.primary,
      cursor: 'pointer',
    },
    close: {
      color: '#D8D8D8',
      cursor: 'pointer',
    },
    grow: {
      flexGrow: 1,
    },
    secondary: {
      color: '#D8D8D8',
    },
  })
);

const Header = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClickBack = () => {
    if ( props.onClickBack ) { props.onClickBack(); }
    else if (props.step > 0) {
      props.setStep(props.step - 1);
    } else { history.goBack(); }
  };

  const handleClose = () => {
    if (props.onClose) { props.onClose(); }
    else { history.goBack(); }
  }

  return (
    <div className={classes.root}>
      <ArrowLeftIcon className={classes.secondary} />
      <Typography
        variant="h3"
        className={classes.back}
        onClick={handleClickBack}
      >
        Back
      </Typography>
      <div className={classes.grow} />
      <CloseIcon className={classes.close} onClick={handleClose} />
    </div>
  );
};

export { Header }
