import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import idx from 'idx';
import { usePopperRef } from '../hooks/usePopperRef';
import { useStyles } from '../Styles';
import { createModalOptions, ICreateModalSelected } from '../../../constants';
import { SpacesPopper } from '.';
import { FolderTypePopper } from '.';
import { FoldersPopper } from '.';
import { Box, Input, TextField, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { getInternalFolders } from '../../../../../../../graphql/queries/getInternalFolders';
import { getExternalFolders } from '../../../../../../../graphql/queries/getExternalFolders';
import { getNestedFolders } from '../../../../../../../graphql/queries/getNestedFolders';
import { useDispatchContext, useStateContext } from '../../../../../../../store';

interface ICreateSpaceForm {
  name: string;
  type: ICreateModalSelected;
  setType: any;
  setCurrentSpace: (val: any) => void;
  folder: any;
  setFolder: (val: any) => void;
  setName: (val: string) => void;
  currentSpace: any;
}

export const CreateSpaceForm = (props: ICreateSpaceForm) => {
  const {
    name,
    type,
    setType,
    setCurrentSpace,
    folder,
    setFolder,
    setName,
    currentSpace,
  } = props;
  const classes = useStyles();

  const [folderFilter, setFolderFilter] = useState('');

  const state = useStateContext();
  const rootSpace = idx(state, (data: any) => data.currentUser.workspace) || {};
  const nexddFilter =
    idx(state, (s) => s.currentUser.filters.nexddFilter) || {};
  const selectedId =
    nexddFilter.selectedFolderId ||
    nexddFilter.selectedSpaceId ||
    rootSpace.id ||
    null;

  const [
    changeTypeAnchorRef,
    changeTypeAnchorEl,
    handleTypeToggle,
  ] = usePopperRef();

  const [
    changeSpaceAnchorRef,
    changeSpaceAnchorEl,
    handleSpaceToggle,
  ] = usePopperRef();

  const [
    changeFolderAnchorRef,
    changeFolderAnchorEl,
    handleFolderToggle,
  ] = usePopperRef();

  const internalSpacesQuery: any = useQuery(getInternalFolders, {
    variables: {
      spaceId: rootSpace.id,
    },
  });
  const externalSpacesQuery: any = useQuery(getExternalFolders, {
    variables: {
      spaceId: rootSpace.id,
    },
  });
  const internalFoldersQuery: any = useQuery(getNestedFolders, {
    variables: { spaceId: currentSpace.id },
  });

  const handleFolderInputClick = () => {
    setFolder(false);
    handleFolderToggle();
  };

  useEffect(() => {
    internalFoldersQuery.refetch();
  }, [currentSpace]);

  const internalSpaces =
    internalSpacesQuery?.data?.getInternalFolders?.map(
      (internalFolder: any) => {
        return {
          ...internalFolder,
          isInternal: true,
        };
      }
    ) || [];
  const externalSpaces =
    externalSpacesQuery?.data?.getExternalFolders?.map(
      (externalFolder: any) => {
        return {
          ...externalFolder,
          isInternal: false,
        };
      }
    ) || [];
  let spaces = [...internalSpaces, ...externalSpaces];

  useEffect(() => {
    if (selectedId === rootSpace.id) {
      if (spaces.length > 0) {
        setCurrentSpace(spaces[0]);
      } else {
        setCurrentSpace({ id: '-1', name: 'No Spaces Available' });
      }
    }
  }, [internalSpacesQuery.loading]);

  const isWorkspace = type?.includes('Space');

  const folders =
    internalFoldersQuery?.data?.getNestedFolders?.filter(
      (dataItem: any) => dataItem.isDashboard === false
    ) || [];

  return (
    <Box className={classes.formContainer}>
      <FolderTypePopper
        selectCreateOption={setType}
        anchorEl={changeTypeAnchorEl}
        setAnchorEl={handleTypeToggle}
      />
      <SpacesPopper
        selectCreateOption={setCurrentSpace}
        anchorEl={changeSpaceAnchorEl}
        setAnchorEl={handleSpaceToggle}
        spaces={spaces}
      />
      <FoldersPopper
        selectCreateOption={setFolder}
        anchorEl={changeFolderAnchorEl}
        setAnchorEl={handleFolderToggle}
        folders={folders}
      />
      <Typography>
        Create a new{' '}
        <span
          className={classes.editable}
          style={{ fontWeight: 'bold' }}
          ref={changeTypeAnchorRef}
          onClick={handleTypeToggle}
        >
          {type ? createModalOptions[type] : ''}
        </span>
      </Typography>
      <TextField
        value={name}
        onChange={(event) => setName(event.target.value)}
        className={classes.formInput}
        variant='outlined'
        placeholder='Enter name here'
      />
      {!isWorkspace && (
        <React.Fragment>
          <Typography className={classes.marginTop}>
            <strong>Location</strong>
          </Typography>
          <Box className={classes.workspaceContainer}>
            <Typography
              ref={changeSpaceAnchorRef}
              onClick={handleSpaceToggle}
              className={classes.spaceInput}
            >
              <span className={clsx(classes.editable)}>
                {currentSpace.name}
              </span>
            </Typography>

            <Typography
              style={{
                flexBasis: '5%',
                color: '#60606080',
              }}
            >
              |
            </Typography>
            <Input
              style={{
                color: '#60606080',
                flexBasis: '40%',
              }}
              className={classes.removePadding}
              value={folder ? folder.name : folderFilter}
              onChange={(event: any) => setFolderFilter(event.target.value)}
              ref={changeFolderAnchorRef}
              onClick={handleFolderInputClick}
              placeholder={'Search subfolders here...'}
              disableUnderline
            />
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};
