import {createLazyQueryHook, createQueryHook, gql} from '../graphqlHelpers';
import { DETAILED_TASK_INFO } from '../fragments/detailedTaskInfo';
import { useLazyQuery } from '@apollo/client'

const query = gql`
  ${DETAILED_TASK_INFO}
	query DetailedTaskInfo(
		$id: ID!
	) {
		task(id: $id) {
      ...DetailedTaskInfo
		}
	}
`;

export const useDetailedTaskInfo = createQueryHook(query);
export function useLazyDetailedTaskInfo() {
  return useLazyQuery(query, {fetchPolicy: 'network-only'});
}

