import {gql} from '@apollo/client';

export const SEARCH_COMPANY_USERS = gql`
query SearchCompanyUsers($text: String!, $companyId: ID!) {
  searchCompanyUsers(text: $text, companyId: $companyId) {
    users {
      id
      email
      displayName
      fullName
      profileUrl
    }
    teams {
      id
      name
    }
  }
}
`;

export const GET_ALL_EVENTS = gql`
query GetAllEvents($text: String!) {
  getAllEvents(text: $text) {
    events {
      id
      name
      user {
        id
        email
        displayName
        fullName
        profileUrl
      }
    }
  }
}
`;


