import React from 'react';
import { Table } from '@material-ui/core';
import { IProjectOptionsSelected, ISteps, ITableHeader } from '../../interfaces';
import TaskTableBody from './components/TaskTableBody';
import TaskTableHeader from './components/TaskTableHeader';

interface IProjectTaskTable {
	isAdmin: boolean;
	externalCompanyId: string;
	selectedTemplate: any;
	onAddButtonHover?: boolean;
	setOnAddButtonHover?: React.Dispatch<React.SetStateAction<boolean>>;
	setSelectedTemplate: React.Dispatch<React.SetStateAction<any>>;
	selectedTasksIds: string[];
	setSelectedTasksIds: React.Dispatch<React.SetStateAction<string[]>>;
	allTableHeaderOptions: ITableHeader[];
	displayedHeaders: ITableHeader[];
	setDisplayedHeaders?: React.Dispatch<React.SetStateAction<ITableHeader[]>>;
	selectedTask?: any;
	updateSelectedTask?(task: any): void;
	step: ISteps;
	projectOptionsSelected: IProjectOptionsSelected;
	skinnyLists?: any[];
	setSkinnyLists?: React.Dispatch<React.SetStateAction<any[]>>;
	dependencies?: any;
	setAddTasks?: React.Dispatch<React.SetStateAction<any>>;
	sortByName: string | null;
	sortBySection: string | null;
	nameFilter: string;
	sectionFilter: string;
	setNameFilterPopper: any;
	additionalInternalOwners: any[];
	additionalExternalOwners: any[];
	setAdditionalInternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
	setAdditionalExternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
	isOnlyImportTasks?: boolean;
}

export default function ProjectTaskTable(props: IProjectTaskTable) {

	const { isAdmin, externalCompanyId, selectedTemplate, onAddButtonHover, setOnAddButtonHover, setSelectedTemplate, selectedTasksIds, setSelectedTasksIds,
		allTableHeaderOptions, displayedHeaders, setDisplayedHeaders, selectedTask, updateSelectedTask, step, projectOptionsSelected,
		skinnyLists, setSkinnyLists, dependencies, setAddTasks, sortByName, sortBySection, nameFilter, sectionFilter, setNameFilterPopper,
		additionalInternalOwners, additionalExternalOwners, setAdditionalInternalOwners, setAdditionalExternalOwners, isOnlyImportTasks } = props;

	return (
		<Table style={{ tableLayout: 'fixed', borderCollapse: 'separate', height: '1px' }}>
			<TaskTableHeader allTableHeaderOptions={allTableHeaderOptions} displayedHeaders={displayedHeaders} setDisplayedHeaders={setDisplayedHeaders}
				selectedTemplate={selectedTemplate} selectedTasksIds={selectedTasksIds} setSelectedTasksIds={setSelectedTasksIds}
				onAddButtonHover={onAddButtonHover} setOnAddButtonHover={setOnAddButtonHover} step={step} projectOptionsSelected={projectOptionsSelected}
				sortByName={sortByName} sortBySection={sortBySection} nameFilter={nameFilter} sectionFilter={sectionFilter} setNameFilterPopper={setNameFilterPopper} />
			<TaskTableBody isAdmin={isAdmin} externalCompanyId={externalCompanyId} onAddButtonHover={onAddButtonHover} displayedHeaders={displayedHeaders}
				selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} selectedTasksIds={selectedTasksIds}
				setSelectedTasksIds={setSelectedTasksIds} selectedTask={selectedTask} updateSelectedTask={updateSelectedTask} step={step}
				skinnyLists={skinnyLists} setSkinnyLists={setSkinnyLists} dependencies={dependencies} setAddTasks={setAddTasks}
				sortByName={sortByName} sortBySection={sortBySection} nameFilter={nameFilter} sectionFilter={sectionFilter}
				additionalInternalOwners={additionalInternalOwners} setAdditionalInternalOwners={setAdditionalInternalOwners}
				additionalExternalOwners={additionalExternalOwners} setAdditionalExternalOwners={setAdditionalExternalOwners} isOnlyImportTasks={isOnlyImportTasks} />
		</Table>
	)
}