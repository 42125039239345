import { createMutationHook, gql } from '../graphqlHelpers';

export const useRefreshQboReconSource = createMutationHook(gql`
  mutation RefreshQboReconSource(
    $reconSourceId: ID!
    $taskId: ID!
  ) {
    refreshQboReconSource(
      reconSourceId: $reconSourceId
      taskId: $taskId
    ) {
      success
      reconSource {
        id
        value
      }
      task {
        isReconciled
      }
    }
  }
`);
