import { useEffect, useState } from 'react';
import {gql, useMutation} from '@apollo/client';

const mutation = gql`
  mutation UpdateTaskReconSetting(
    $taskId: ID!
    $enableRecon: Boolean!
  ) {
    updateTaskRecon(
      taskId: $taskId
      enableRecon: $enableRecon
    ) {
      success
      task {
        id
      }
    }
  }
`;

export function useUpdateTaskReconSettings() {
  const [taskId, setTaskId] = useState<any>(null);
  const [enableRecon, setEnableRecon] = useState<any>(false);
  const [ updateTaskReconSettings, response ] = useMutation(mutation);

  useEffect(() => {
    if (taskId) {
      const variables: any = { taskId, enableRecon };
      updateTaskReconSettings({ variables });
    }
  }, [taskId])

  return function(taskId: string, enableRecon: boolean) {
    setEnableRecon(enableRecon);
    setTaskId(taskId);
    return response;
  }
}
