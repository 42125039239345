import axios from 'axios';
import idx from 'idx';
import { getCSRF } from '../helpers/helpers';

const ROUTES = {
  project_summary: '/api/export_project_summary',
};


export async function exportProjectSummary(listId: string) {
  const response = await axios.post(
    ROUTES.project_summary,
    {list_id: listId},
    {
      headers: {
        'X-CSRF-Token': getCSRF(),
      },
    }
  );
  return idx(response, (r: any) => r.data.url);
}