import { createMutationHook, gql } from '../graphqlHelpers';

const unarchiveFile = createMutationHook(gql`
  mutation UnarchiveFile($fileVersionId: ID!) {
    unarchiveFile(fileVersionId: $fileVersionId) {
      task {
        id
        currency
        primaryFileReconSources {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
            alterable
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        primaryFileReconSourcesTotal
        secondaryFileReconSources {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
            alterable
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        secondaryFileReconSourcesTotal
        isReconciled
        primaryTotal
        secondaryTotal
        reconcilingItemTotal
        reconDifference
        files {
          id
          fileName
          fileLocation
          alterable
          viewed
          updatedAt
          taskId
          createdAt
          isCheckedOut
          checkedOutBy{
            fullName
          }
          checkedOutAt
          companyId
        }
      }
      success
    }
  }
`);

export default unarchiveFile;
