import { useState, useEffect } from 'react';
import {gql, useMutation} from '@apollo/client';

const mutation = gql`
  mutation BulkTaskReconAssign(
    $listId: ID!
    $reconEnabledValue: Boolean!
  ) {
    bulkTaskReconAssign(
      listId: $listId,
      reconEnabledValue: $reconEnabledValue
    ) {
      success
    }
  }
`

export function useBulkTaskReconAssign() {
  const [ values, setValues] = useState<any>({});
  const [ update, response ] = useMutation(mutation);

  useEffect(() => {
    if (values.listId) {
      update({
        variables: {
          listId: values.listId, 
          reconEnabledValue: values.reconEnabledValue
      }})
    }
  }, [values])

  return function(listId: string, reconEnabledValue: boolean) {
    setValues({
      listId,
      reconEnabledValue
    })
  }
}
