import { useLazyQuery, gql } from '@apollo/client';

export const resolveObjects = gql`
  query ResolveObjects {
    resolveObjects {
      id
      description
      resolveStatusOptions {
        id
        description
      }
    }
  }
`

export const useLazyResolveObjects = () => {
  return useLazyQuery(resolveObjects);
}
