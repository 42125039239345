import {gql} from '../graphqlHelpers';
import { useLazyQuery } from '@apollo/client';

const getArchivedFileVersionsQuery = gql`
	query getArchivedFileVersions($taskId: ID!){
		archivedFileVersions(taskId: $taskId) {
    	id
    	taskId
    	fileName
    	fileLocation
    	createdAt
    }
	}
`;

const useLazyArchivedFileVersions = () => {
	return useLazyQuery(getArchivedFileVersionsQuery)
}
export default useLazyArchivedFileVersions;
