/* linted */
import React, {useState} from 'react';
import clsx from 'clsx';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import { NoSymbolIcon } from '@heroicons/react/24/outline';
import { NOT_FOUND_STATUS, STATUSES_OPTIONS } from './Constants';
import { useStateContext } from '../../store';

interface StatusIconProps {
  style?: any;
  hoverChange?: boolean;
  onClick?: any;
  status: string;
  selected?: any;
  isExternalUser?: boolean;
  noCursor?: boolean;
  listId?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: '3px 6px',
      userSelect: 'none',
      minWidth: '105px',
      // TODO -- figure out bouncing cause.
      border: '1px transparent',
    },
    status: {
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      marginRight: '6px',
      display: 'inline-block',
      marginLeft: '3px',
    },
    statusText: (props: any) => ({
      display: 'inline-block',
      paddingLeft: '3px',
      cursor: props.cursor,
    }),
    selected: (props: any) => ({
      background: '#FFFFFF',
      border: '1px solid #ECECEC',
      borderRadius: '3px',
      // width: 'fit-content',
      cursor: props.cursor,
      textAlign: 'center',
      alignItems: 'center',
    }),
  })
);

export function StatusIcon(props: StatusIconProps) {
  const styleProps = {cursor: props.isExternalUser ? 'not-allowed' : (props.noCursor ? 'default': 'pointer')}
  const classes = useStyles(styleProps);
  const [hover, setHover] = useState<boolean>(false);
  const state = useStateContext();

  const statuses = STATUSES_OPTIONS;
  let statusesCustom: any = {};

  const listCustomTaskStatuses = props.listId ? (state.listGroupedCustomTaskStatuses[props.listId] || []) : [];
  listCustomTaskStatuses.forEach((status: any) => {
    const hoverTitle = statuses[status.key] ? (statuses[status.key]['hover'] || status.label) : status.label;
    if (status.key === 'Rejected') {
      statusesCustom[status.key] = {
        label: status.label,
        hover: hoverTitle,
        color: status.color
      }
      statusesCustom['RejectedExternal'] = {
        label: status.label,
        hover: hoverTitle,
        color: status.color
      }
      statusesCustom['RejectedInternal'] = {
        label: status.label,
        hover: hoverTitle,
        color: status.color
      }
    } else {
      statusesCustom[status.key] = {
        label: status.label,
        hover: hoverTitle,
        color: status.color
      }
    }
  });
  
  const currentStatus = statusesCustom[props.status] || statuses[props.status] || (listCustomTaskStatuses.length ? NOT_FOUND_STATUS : statuses.Unstarted);

  return (
    <div
      style={props.style}
      className={clsx(classes.root, props.selected && classes.selected)}
      onClick={props.onClick}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-cy={`status-icon-${props.status}`}
    >
      {
        props.status === 'NotApplicable' ?
        <NoSymbolIcon className={clsx(classes.status, "!w-3 !h-3 !text-black !font-bold !ml-[1.5px]")} aria-hidden="true" />
        : <div className={classes.status} style={{backgroundColor: currentStatus.color}} />
      }

      <Typography
        data-cy={'status-text'}
        variant="h6"
        className={classes.statusText}
      >
        {hover && props.hoverChange ? currentStatus.hover : currentStatus.label}
      </Typography>
    </div>
  );
}
