const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function dependencyDataGeneratorFunc(dependency: any, task: any, skinnyList: any) {
    let prefix: string;
    let suffix: string;
    let taskDisplayId: string;
    let taskDisplayName: string;
    let fullTaskDisplayName: string;
    let isBlocking: Boolean;
    let relatedId: string;
    prefix = '';
    suffix = '';
    taskDisplayId = '';
    taskDisplayName = '';
    fullTaskDisplayName = '';
    relatedId = '';
    isBlocking = dependency.relatedId == task.id;
    let isDependent = dependency.isDependent;
    relatedId = isBlocking ? dependency.taskId : dependency.relatedId;

    let hasStartDate = dependency.hasStartDate;
    let displayDueDate = '';
    let [taskName, taskDueDate, taskListNumber] = getTaskData(
      dependency.taskId, skinnyList
    );
    let [relatedName, relatedDueDate, relatedListNumber] = getTaskData(
      dependency.relatedId, skinnyList
    );
    // is dependent but not blocking
    if (!isBlocking && isDependent) {
      displayDueDate = relatedTaskDueDate(relatedDueDate);
      [prefix, suffix] = dependentTasktext(
        dependency,
        hasStartDate,
        relatedListNumber,
        displayDueDate
      );
      taskDisplayName = relatedName;
      taskDisplayId = relatedId;
    } else {
      //either blocking or linked
      [
        prefix,
        suffix,
        taskDisplayName,
        taskDisplayId,
      ] = linkedAndBlockedTaskText(
        dependency.taskId,
        dependency.relatedId,
        isDependent,
        isBlocking,
        taskName,
        relatedName,
        taskListNumber,
        relatedListNumber
      );
    }
    let bufferTruncate = (58 - (prefix.length + suffix.length ))
    fullTaskDisplayName = taskDisplayName;
    if (prefix.length + suffix.length + taskDisplayName.length > 60) {
      taskDisplayName = taskDisplayName.substring(0, bufferTruncate) + '...';
      
    }

    return [
      prefix,
      suffix,
      taskDisplayName,
      fullTaskDisplayName,
      bufferTruncate,
      taskDisplayId,
      relatedId,
      isBlocking,
    ];
  }

  function dependentTasktext(
    dependency: any,
    hasStartDate: Boolean,
    relatedListNumber: string,
    displayDueDate: string
  ) {
    let prefix = hasStartDate ? 'Starts ' : 'Due ';
    let suffix = '';
    let dayPrefix = '';
    let hourPrefix = '';
    let days = dependency.dueDays;
    //let hours = dependency.dueHours;
    let hours = '';
    if (typeof dependency.dueHours == 'undefined') {
      hours = '';
    } else {
      hours = parseFloat(dependency.dueHours).toString();
    }
    if (days && days.length > 0 && days != '' && days != '0.0') {
      if (days != '0' && days != 'NaN') {
        dayPrefix = dayPrefix.concat(parseInt(days).toString());
        dayPrefix = dayPrefix.concat(
          parseInt(days) <= 1.0 ? ' day ' : ' days '
        );
      }
    }
    if (hours && hours.length > 0 && hours != '' && hours != '0.0') {
      if (hours != '0' && hours != 'NaN') {
        if (dayPrefix.length > 0) {
          hourPrefix = hourPrefix.concat('and ');
        }
        hourPrefix = hourPrefix.concat(hours);
        hourPrefix = hourPrefix.concat(
          parseFloat(hours) <= 1.0 ? ' hour ' : ' hours '
        );
      }
    }
    if (dayPrefix.length > 0) {
      prefix = prefix.concat(dayPrefix);
    }
    if (hourPrefix.length > 0) {
      prefix = prefix.concat(hourPrefix);
    }
    prefix = prefix.concat(' after task ');
    prefix = prefix.concat(relatedListNumber);

    if (displayDueDate && displayDueDate.length > 0) {
      suffix = suffix.concat(' due on ');
      suffix = suffix.concat(displayDueDate);
    }
    return [prefix, suffix];
  }

  function linkedAndBlockedTaskText(
    taskId: string,
    relatedId: string,
    isDependent: Boolean,
    isBlocking: Boolean,
    taskName: string,
    relatedName: string,
    taskListNumber: string,
    relatedListNumber: string
  ) {
    let prefix = isDependent ? 'Blocking task ' : 'Linked to task ';
    let suffix = '';
    let taskDisplayName = isBlocking ? taskName : relatedName;
    prefix = prefix.concat(isBlocking ? taskListNumber : relatedListNumber);
    let taskDisplayId = isBlocking ? taskId : relatedId;
    return [prefix, suffix, taskDisplayName, taskDisplayId];
  }

  function relatedTaskDueDate(taskDueDate: string) {
    if (taskDueDate && taskDueDate.length > 0) {
      let date_hours = taskDueDate.split(' ');
      let date = date_hours[0].split('-');
      let idx = date[1];
      let day = date[2].split('T')[0];
      let displayedDueDate = months[parseInt(idx) - 1]
        .concat(' ')
        .concat(day);
      return displayedDueDate;
    } else {
      return '';
    }
  }

  function getTaskData(taskId: string, skinnyLists: any) {
    let listNumber = '';
    let dueDate = '';
    let name = '';
    skinnyLists.forEach((_list: any) => {
      let _tasks = _list.tasks;
      _tasks.forEach((t: any) => {
        if (t.id == taskId) {
          listNumber = t.listNumber;
          dueDate = t.dueDate;
          name = t.name;
        }
      });
    });
    return [name, dueDate, listNumber];
  }

  export function dependenciesPresentFunc(dependencies: any, task: any) {
    return (
      dependencies && dependencies[task.id] && dependencies[task.id].length > 0
    );
  }



  // const [
  //   deleteTaskDependency,
  //   {loading: mutationLoading, data: mutationData, error: mutationError},
  // ] = useMutation(DELETE_TASK_DEPENDENCY);

export const handleDeleteTaskDependencyFunc = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    isBlocking: Boolean,
    relatedId: any,
    task: any,
    skinnyLists: any,
    setSkinnyLists: any,
    deleteTaskDependency: any,
    mutationError: any,
    setOpenDeleteSnackbar?: any,
    refreshTaskNotifications?: any
  ) => {
    let taskId = '';
    if (isBlocking) {
      taskId = relatedId;
      relatedId = task.id;
    } else {
      taskId = task.id;
    }
    deleteTaskDependency({
      variables: {
        taskId: taskId,
        relatedId: relatedId,
        dependencyOperationName: isBlocking ? 'blocking' : null // Currently, only required to provide this paramater for Blocking dependency
      },
    })
      .then((response: any) => {
        if (refreshTaskNotifications && response?.data?.deleteTaskDependency?.success) {
          refreshTaskNotifications();
        }
        //handle response
        let _skinnyLists = skinnyLists.map((a: any) => {
          return {...a};
        });
        for (let list_iter = 0; list_iter < _skinnyLists.length; list_iter++) {
          let _current_list = _skinnyLists[list_iter];
          let _list_of_tasks = _current_list.tasks;
          for (let i = 0; i < _list_of_tasks.length; i++) {
            if (
              _list_of_tasks[i].dependencies &&
              _list_of_tasks[i].dependencies.length > 0
            ) {
              for (let j = 0; j < _list_of_tasks[i].dependencies.length; j++) {
                let curdep = _list_of_tasks[i].dependencies[j];
                if (
                  (curdep.taskId == taskId && curdep.relatedId == relatedId) ||
                  (curdep.relatedId == taskId && curdep.taskId == relatedId)
                ) {
                  //delete _list_of_tasks[i].dependencies[j]; // leaves empty element
                  if (_list_of_tasks[i].id == taskId) {
                    _list_of_tasks[i].dueDate =
                      response.data.deleteTaskDependency.taskNewDate;
                  }
                  if (_list_of_tasks[i].id == relatedId) {
                    _list_of_tasks[i].dueDate =
                      response.data.deleteTaskDependency.relatedNewDate;
                  }
                  _list_of_tasks[i].dependencies.splice(j, 1);
                }
              }
            }
          }
        }
        setSkinnyLists(_skinnyLists);
      })
      .catch((err: any) => {
        if (setOpenDeleteSnackbar) setOpenDeleteSnackbar(true);
      });

    // search all the lists and tasks for that dependency
    // remove from active tasks object so UI will update
    // is there a better way?
  };