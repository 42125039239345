import React, { useState } from 'react';
import idx from 'idx';
import clsx from 'clsx'

import MoreVertIcon from '@material-ui/icons/MoreVert';
import DescriptionIcon from '@material-ui/icons/Description';
import { Box, createStyles, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
// import { MoneyDisplay } from '../../../../../../../common/MoneyDisplay';
import { MultiFileReconMoneyDisplay } from '../components/MultiFileReconMoneyDisplay'

import FileOptionsPopper from './FileOptionsPopper';

//This path is insane
import { chooseFileIcon } from '../../../../../../../../modules/nexdd/DashboardHome/common/chooseFileIcon'
import { MultiFileReconSourceValue } from './MultiFileReconSourceValue';

const useStyles = makeStyles(() =>
  createStyles({
    hoverRow: {
      background: '#EBF2FF',
    },
    tooltipFont: {
      fontSize: '1em'
    }
  })
);


export default function ReconFileList(props: any) {
  const { task, sourceType, updateTask, updateTaskInCache, tasks, allTasks, setTasks, updatePrimaryDisplaySkeletons, updateSecondaryDisplaySkeletons, updateTaskStatus } = props;

  const reconFiles = task[`${sourceType}FileReconSources`]
  const reconFilesTotal = task[`${sourceType}FileReconSourcesTotal`]
  return (
    <div style={{ paddingBottom: '20px' }}>
      <ReconFileListHeader reconFilesTotal={reconFilesTotal} />
      <ReconFileListBody reconFiles={reconFiles} sourceType={sourceType} task={task} updateTask={updateTask} updateTaskInCache={updateTaskInCache} tasks={tasks} allTasks={allTasks} setTasks={setTasks} updatePrimaryDisplaySkeletons={updatePrimaryDisplaySkeletons} updateSecondaryDisplaySkeletons={updateSecondaryDisplaySkeletons} updateTaskStatus={updateTaskStatus} />
    </div>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}

const ReconFileListHeader = (props: any) => {
  const { reconFilesTotal } = props;

  return (
    <Grid container style={{ paddingBottom: '5px', }}>
      <Grid item xs={6}>
        <Typography style={{ font: 'normal normal bold 12px/15px Montserrat', color: '#707070', display: 'flex', alignItems: 'center' }} data-cy='file-type-support-name'>
          File
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{ font: 'normal normal bold 12px/15px Montserrat', color: '#707070', textAlign: 'end', paddingRight: '10px', display: 'flex', alignItems: 'center' }}>
          Total
        </Typography>
      </Grid>
      <Grid item xs={3} style={{ borderBottom: '1px solid #D8D8D8', font: 'normal normal normal 12px/15px Montserrat', display: 'flex', alignItems: 'center' }}>
        <MultiFileReconMoneyDisplay value={reconFilesTotal} dollarSign={true} />
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}

const ReconFileListBody = (props: any) => {
  const classes = useStyles()
  const { reconFiles, task, updateTask, updateTaskInCache, tasks, allTasks, setTasks, sourceType, updatePrimaryDisplaySkeletons, updateSecondaryDisplaySkeletons, updateTaskStatus } = props;

  const [anchorEl, setAnchorEl] = useState();
  const [onClose, setOnClose] = useState(() => { return () => { } });

  const [selectedReconFile, setSelectedReconFile] = useState({ id: -1 });
  const [visibleRecon, setVisibleRecon] = useState({ id: -1 });

  const handleFileClick = (fileLocation: string) => {
    if (fileLocation) {
      window.open(fileLocation, '_blank');
    }
  }

  const handleEllipsesClick = (event: any, reconFile: any) => {
    const newOnClose = () => {
      setAnchorEl(undefined);
      setSelectedReconFile({ id: -1 });
    }

    setOnClose(() => { return newOnClose })

    setAnchorEl(event.currentTarget);
    setSelectedReconFile(reconFile);
  };

  const displayTempRecon = (reconFile: any) => {

    const splitFileName = reconFile.fileVersion.fileName.split('.');
    const fileName = splitFileName[0]; //Might need to update this in the future as well
    const fileType = splitFileName[splitFileName.length - 1]; //In case the file has multiple '.' in the name.


    return (
      <Tooltip classes={{ tooltip: classes.tooltipFont }} title={"This file is currently being processed."}>
        <Grid
          container
          key={'tempReconKey'}
          onMouseEnter={() => { setVisibleRecon(reconFile) }}
          onMouseLeave={() => { setVisibleRecon({ id: -1 }) }}
          style={{ paddingTop: '2px', paddingBottom: '2px', height: '26px', backgroundColor: 'lightgrey' }}
        >
          <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
            <img style={{ height: '24px', paddingRight: '6px' }} src={chooseFileIcon(fileType)} />
            <Typography noWrap={true} style={{ color: '#3A84FF', cursor: 'pointer', font: 'normal normal normal 12px/15px Montserrat' }}>
              {fileName}
            </Typography>
          </Grid>
          <Grid item xs={2}>
          </Grid>
          <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', font: 'normal normal normal 12px/15px Montserrat' }}>
            <MultiFileReconSourceValue task={task} updateTask={updateTask} updateTaskInCache={updateTaskInCache} sourceType={sourceType} fileValue={reconFile.value} fileExtenstion={fileType} fileReconSourceId={reconFile.id} disabled={true}/>
          </Grid>
          <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}/>
        </Grid>
      </Tooltip>
    )
  }

  return (
    <React.Fragment>
      <FileOptionsPopper onClose={onClose} task={task} updateTask={updateTask} updateTaskInCache={updateTaskInCache} tasks={tasks} allTasks={allTasks} setTasks={setTasks} sourceType={sourceType} selectedReconFile={selectedReconFile} anchorEl={anchorEl} updatePrimaryDisplaySkeletons={updatePrimaryDisplaySkeletons} updateSecondaryDisplaySkeletons={updateSecondaryDisplaySkeletons} updateTaskStatus={updateTaskStatus} />
      {reconFiles && reconFiles.map((reconFile: any, index: any) => {

        if(reconFile?.id === '-1'){
          return displayTempRecon(reconFile);
        }

        const splitFileName = reconFile.fileVersion.fileName.split('.');
        
        const fileName = splitFileName[0]; //Might need to update this in the future as well
        const fileType = splitFileName[splitFileName.length - 1]; //In case the file has multiple '.' in the name.

        const displayEllipses = (reconFile.id === visibleRecon.id || reconFile.id === selectedReconFile.id)
        return (
          <Grid
            container
            key={index}
            onMouseEnter={() => { setVisibleRecon(reconFile) }}
            onMouseLeave={() => { setVisibleRecon({ id: -1 }) }}
            className={clsx(displayEllipses && classes.hoverRow)}
            style={{ paddingTop: '2px', paddingBottom: '2px', height: '26px' }}
          >
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              <img style={{ height: '24px', paddingRight: '6px' }} src={chooseFileIcon(fileType)} />
              <Typography noWrap={true} onClick={() => { handleFileClick(reconFile.fileVersion.fileLocation) }} style={{ color: '#3A84FF', cursor: 'pointer', font: 'normal normal normal 12px/15px Montserrat' }}>
                {fileName}
              </Typography>
            </Grid>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', font: 'normal normal normal 12px/15px Montserrat' }}>
              <MultiFileReconSourceValue task={task} updateTask={updateTask} updateTaskInCache={updateTaskInCache} sourceType={sourceType} fileValue={reconFile.value} fileExtenstion={fileType} fileReconSourceId={reconFile.id} />
            </Grid>
            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
              <MoreVertIcon style={{ visibility: (displayEllipses) ? 'visible' : 'hidden', height: '18px' }} onClick={(event) => { handleEllipsesClick(event, reconFile) }} />
            </Grid>
          </Grid>
        )
      })}
    </React.Fragment>
  )
}
