import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import CompanyForm from './CompanyForm';
import { INewCompanyType, IProject } from './interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      alignItems: 'center',
    },
  })
);

interface IExternalCompany {
  sharing: string,
  newTemplate: IProject,
  setNewTemplate: React.Dispatch<React.SetStateAction<IProject>>,
  inviteCompany: INewCompanyType,
  setInviteCompany: React.Dispatch<React.SetStateAction<INewCompanyType>>,
  showWarning: boolean,
	additionalExternalOwners: any[];
	setAdditionalExternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function ExternalCompany(props: IExternalCompany) {
  const classes = useStyles();
  const { sharing, newTemplate, setNewTemplate, inviteCompany, setInviteCompany, showWarning, additionalExternalOwners, setAdditionalExternalOwners } = props;
  return (
    <div className={classes.root} data-cy='external-company'>
      <CompanyForm
        sharing={sharing}
        newTemplate={newTemplate}
        setNewTemplate={setNewTemplate}
        inviteCompany={inviteCompany}
        setInviteCompany={setInviteCompany}
        showWarning={showWarning}
        additionalExternalOwners={additionalExternalOwners}
        setAdditionalExternalOwners={setAdditionalExternalOwners}
      />
    </div>
  )
}