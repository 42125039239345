import { useLazyQuery, gql } from '@apollo/client';

export const timePeriods = gql`
  query TimePeriods {
    timePeriods {
      id
      name
      slug
    }
  }
`

export const useLazyTimePeriods = () => {
  return useLazyQuery(timePeriods);
}