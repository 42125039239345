interface Option {
  label: string;
  value: string;
  isDisabled?: boolean;
}

interface IntegrationField {
  label: string;
  value?: string;
  isRequired?: boolean;
  isMulti?: boolean;
  options?: Option[];
  component?: any;
}

interface AdjbookField {
  property: String;
  inAccountingMethod: any;
  companyAdjBooks?: any;
}

export const ACCOUNT_METHOD_OPTIONS = ['CASH', 'ACCRUAL'];
export const STATISTICAL_OPTIONS = ['Include', 'Exclude'];

export const LEVELS: IntegrationField[] = [
  { label: 'Entity', value: 'inEntities', isRequired: false, isMulti: true },
  { label: 'Department', value: 'inDepartments', isMulti: true },
  { label: 'Class', value: 'inUnderliers', isMulti: true },
  { label: 'GL Account', value: 'inAccounts', isMulti: true },
  { label: 'Item', value: 'inServices', isMulti: true },
  { label: 'Vendor', value: 'inVendors', isMulti: true },
  { label: 'Accounting method', value: 'inAccountingMethod',
    options: [
      { label: 'Cash', value: 'CASH', isDisabled: false },
      { label: 'Accrual', value: 'ACCRUAL', isDisabled: false },
      { label: 'Include Statistical Accounts', value: 'Include', isDisabled: false },
      { label: 'Exclude Statistical Accounts', value: 'Exclude', isDisabled: false },
    ],
    isMulti: true
  }
];

/**
 * Multi-select isn't great for some combinations, so disable the options that should not be combined.
 * @param {Number} index - the index of the selected option that will be disabled.
 * @param {Array} dimension - array of the options of the account methods select field.
 * @param {Array} selectedValues - array of the values of the selection options.
 * @param {Array} initialValues - array of the original values of the selection options to compare.
 * @returns {Object} - the mueted select option which should not be selected.
 * 
*/
export const disableOption = (index: number, dimension: any, selectedValues: Array<string>, initialValues: Array<string>) => {
  for (let i = 0; i < selectedValues.length; i++) {
    if (initialValues.includes(selectedValues[i]) ) {
      dimension.options[index].isDisabled = true;
    }
  }
};

/**
 * Returns true if arrays are “similar”, i.e. they both contain the same items
 * disregarding their order in the respective arrays.
 *
 * Source: https://stackoverflow.com/a/19746771
 *
 * @param {array} array1
 * @param {array} array2
 * @returns boolean
 */
export const areArraysSimilar = (array1: any, array2: any) => {
  const array1Sorted = array1.slice().sort();
  return (
    array2.length === array1Sorted.length &&
    array2
      .slice()
      .sort()
      .every((value: any, index: any) => value === array1Sorted[index])
  );
};

const isValueEqual = (selectedValues: any, adj_books: any) => areArraysSimilar(adj_books, selectedValues);

/**
 * Build the Balance query parameters for Intacct API,
 * with the `reportingBook`, the `statistical` and the `adjbooks`.
 * @param {String} property - the trial balances parameters
 * @returns {String} - the selected parameters to pass to the trial balances API.
 * 
*/
export const buildParams = (property: String, inAccountingMethod: any) => {
  let params;
  const selectedValues = inAccountingMethod.map((opt: any) => opt.value);

  for (let i = 0; i < selectedValues.length; i++) {
    const value =  selectedValues[i];
    if (value === "ACCRUAL" && property === "reportingBook") {
      params = "ACCRUAL"
    } else if (value ==="CASH" && property === "reportingBook") {
      params = "CASH";
    } else if (value ==="Include" && property === "statistical") {
      params = "Include";
    } else if (value ==="Exclude" && property === "statistical") {
      params = "Exclude";
    }
  }
  
  return params;
}

/**
 * Build the Balance query parameters for Intacct API,
 * with the `reportingBook`, the `statistical` and the `adjbooks`.
 * @param {Object} inAccountingMethod - the accounting methods balances parameters
 * @param {Object} companyAdjBooks - the selected AdjBooks parameters to pass to the trial balances API.
 * @returns {Object} params - the selected AdjBooks list for Intacct API.
 *
*/
export const buildAdjBookParams = (inAccountingMethod: any, companyAdjBooks: any) => {
  let params = [];
  const adj_books = companyAdjBooks.map((opt: any) => opt.adjbook);
  const selectedValues = inAccountingMethod.map((opt: any) => opt.value);

  const coolArray = adj_books.filter((a: string) => selectedValues.includes(a));

  if (coolArray.length) {
    for (let i = 0; i < coolArray.length; i++) {
      params.push({adjbook : coolArray[i]});
    }
  }

  return params;
}

// Specific Date formatter for Intacct
export const formatDateForDatePicker = (date: Date | string) => {
  if (date) {
    if(typeof date === "object"){
      return date.toDateString();
    } else{
      //This check is here for legacy dates that are still in ISO format
      if(date.includes('Z')){
        return date.substr(0,10).replace(/-/g, '/');
      } else if(date.charAt(4) === '-' && date.charAt(7) === '-' && date.includes('T')){
        return date.substr(0,10).replace(/-/g, '/');
      } else {
        //Intacct format is already the same as dateString.toDateString()
        return date;
      }
    }
  }
  return date;
}

export const NEW_INTEGRATION_OPTION = { label: "+ Add New Integration", value: "ADD_NEW_INTEGRATION" };
