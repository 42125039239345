import React from 'react'
import ProjectsNumber from '../ProjectTypesStep/components/ProjectsNumber';
import ProjectType from './components/ProjectType'
import ProjectTemplates from './components/ProjectTemplates';
import { IProjectNumber, IProjectType, IProjectOptionsSelected, IProject, INewCompanyType } from '../common/interfaces';
import { TemplateLists_templateLists } from '../../../../../graphql/queries/__generated__/TemplateLists';

interface IProjectTypesStep {
	activeSubStep: number;
	projectNumberOptions: IProjectNumber;
	projectTypeOptions: IProjectType;
	projectOptionsSelected: IProjectOptionsSelected;
	isAdmin: boolean;
	externalCompanyId: string;
	newTemplate: IProject;
	setNewTemplate: React.Dispatch<React.SetStateAction<IProject>>;
	setProjectOptionsSelected: React.Dispatch<React.SetStateAction<IProjectOptionsSelected>>;
	inviteCompany: INewCompanyType;
	setInviteCompany: React.Dispatch<React.SetStateAction<INewCompanyType>>;
	data: TemplateLists_templateLists[];
	selectedTemplate: any;
	setSelectedTemplate: React.Dispatch<React.SetStateAction<any>>;
	templateSelected: any;
	setSelectedTasksIds: React.Dispatch<React.SetStateAction<string[]>>;
	additionalExternalOwners: any[];
	setAdditionalExternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function ProjectTypesStep(props: IProjectTypesStep) {
	const {
		activeSubStep, projectNumberOptions, projectTypeOptions, projectOptionsSelected, isAdmin, externalCompanyId, setProjectOptionsSelected,
		newTemplate, setNewTemplate, inviteCompany, setInviteCompany, data, selectedTemplate, setSelectedTemplate, templateSelected, setSelectedTasksIds,
		additionalExternalOwners, setAdditionalExternalOwners} = props;
	return (
		<>
			{
				activeSubStep === 0 &&
				<ProjectsNumber projectOptions={projectNumberOptions} projectOptionsSelected={projectOptionsSelected}
					onOptionChanged={(value: string) => setProjectOptionsSelected({ ...projectOptionsSelected, projectNumber: value })} />
			}
			{
				activeSubStep === 1 &&
				<ProjectType projectOptions={projectTypeOptions} projectOptionsSelected={projectOptionsSelected} isAdmin={isAdmin}
					onOptionChanged={(value: string) => setProjectOptionsSelected({ ...projectOptionsSelected, projectType: value })}
					newTemplate={newTemplate} setNewTemplate={setNewTemplate} externalCompanyId={externalCompanyId}
					inviteCompany={inviteCompany} setInviteCompany={setInviteCompany}
					additionalExternalOwners = {additionalExternalOwners} setAdditionalExternalOwners = {setAdditionalExternalOwners}/>
			}
			{
				activeSubStep === 2 &&
				<ProjectTemplates data={data} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate}
					templateSelected={templateSelected} setSelectedTasksIds={setSelectedTasksIds} />
			}
		</>
	)
}
