import React, {useEffect, useState} from 'react';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import {
  ClickAwayListener,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckIcon from '@material-ui/icons/Done';
import { TemplateLists_templateLists } from '../../../../../../graphql/queries/__generated__/TemplateLists';
import * as cs from '../../../../../../constants/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: 'fit-content',
      zIndex: 12,
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    paper: {
      position: 'absolute',
      top: '33px',
      left: '0px',
      border: '1px solid #D8D8D8',
      minWidth: '100%',
    },
    check: {
      color: cs.COLORS.primary,
    }
  })
);

interface DropdownProps {
  data: TemplateLists_templateLists[];
  listId: string;
  setListId: React.Dispatch<React.SetStateAction<string>>;
  openDefault?: boolean; // Force open the dropdown
  autoSize?: boolean; // Force the dropdown to be the same width as the parent
}

export default function Dropdown(props: DropdownProps) {
  const {data, listId, setListId, openDefault, autoSize } = props;
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(!!openDefault);
  }, [openDefault]);

  const handleClick = (newId: string) => {
    setListId(newId);
    setOpen(false);
  };

  const renderListName = () => {
    const selectList = data.find(item => item.id === listId);

    if (selectList) return selectList.name;
    return 'Select Project';
  };

  return (
    <div className={classes.root + (autoSize ? " flex !w-full" : "")}>
      <div className={classes.flex + (autoSize ? " flex !w-full justify-between" : "")} onClick={() => setOpen(!open)}>
        <Typography variant="h2" className='truncate'>{renderListName()}</Typography>
        <ArrowDownIcon fontSize="large" />
      </div>
      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Paper className={classes.paper} elevation={0} square>
            <List>
              {data &&
                data.map((item: TemplateLists_templateLists) => {
                  return (
                    <ListItem
                      key={item.id}
                      onClick={() => handleClick(item.id)}
                    >
                      <div className='w-full flex items-center justify-between'>
                        <ListItemText primary={item.name} />
                        { item.id === listId && <CheckIcon className={classes.check} />}
                      </div>
                    </ListItem>
                  );
                })}
              {(!data || !data.length) && (
                <ListItem>
                  <ListItemText primary="No available projects" />
                </ListItem>
              )}
            </List>
          </Paper>
        </ClickAwayListener>
      )}
    </div>
  );
}
