import { gql, createLazyQueryHook } from '../graphqlHelpers';

type ID = string | number;

interface downloadAllFilesData {
  downloadAllFiles: {
    url: string;
    success: boolean;
    downloadId: string;
  }
}

interface downloadAllFilesVariables {
  fileVersionIds: ID[];
  currentPage: string;
  fileDownloadReportId: string;
}

const useLazyDownloadAllFiles = createLazyQueryHook<downloadAllFilesData,
  downloadAllFilesVariables>(gql`
  query DownloadAllFiles(
    $fileVersionIds: [ID!]!
    $currentPage: String!
    $fileDownloadReportId: String
  ) {
    downloadAllFiles(
      fileVersionIds: $fileVersionIds
      currentPage: $currentPage
      fileDownloadReportId: $fileDownloadReportId
    ) {
      url
      success
      downloadId
    }
  }
`);

export default useLazyDownloadAllFiles;
