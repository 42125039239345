import React, { useEffect, useState } from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, Button, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/WarningRounded';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: '12px',
      backgroundColor: 'rgba(58, 132, 255, 0.1)',
      border: '1px solid #D8D8D8',
      borderRadius: '3px'
    }
  })
);

interface DialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onAuth: any;
  timeout: number;
}

export default function AuthTimeoutDialog(props: DialogProps) {
  const { open, setOpen, timeout } = props;
  const [timeCounterText, setTimeCounterText] = useState<string>('');
  const [intervalTimer, setIntervalTimer] = useState<any>(null);
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      clearInterval(intervalTimer);
      startCountdown();
    }
    return () => {
      clearInterval(intervalTimer);
    }
  }, [open, timeout]);

  // Start the countdown to handle the user action
  const startCountdown = () => {
    let remainingTime = timeout / 1000; // convert millliseconds to seconds
  
    const interval = setInterval(() => {
      const minutes = Math.floor(remainingTime / 60);
      const seconds = remainingTime % 60;
  
      setTimeCounterText(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
  
      if (remainingTime <= 0) {
        clearInterval(interval);
        setTimeCounterText('0');
      }
  
      remainingTime--;
    }, 1000); // Update every second
    setIntervalTimer(interval);
  }

  const handleClose = (event: any, reason: string) => {
    if (reason === 'backdropClick') {
      return false;
    }

    if (reason === 'escapeKeyDown') {
      return false;
    }

    setOpen(false)
  }

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      disableEscapeKeyDown
      onClose={handleClose}
      open={open}
    >
      <div className={classes.root}>
        <WarningIcon />

        <Typography
          style={{ marginLeft: '12px' }}
          variant="h6"
        >
          You will be logged out of the application in <span className='inline-block w-[25px] mx-[2px]'>{timeCounterText}</span> due to
          inactivity. Please Reauthenticate if you wish to remain logged in.
        </Typography>

        <DialogActions>
          <Button
            onClick={props.onAuth}
            variant="contained"
          >
            Reauthenticate
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
