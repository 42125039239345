import React from 'react';
import clsx from 'clsx';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import DefaultUserImage from './DefaultUserImage';
import Clear from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      /*display: 'inline-block',*/
      width: 'fit-content',
      alignItems: 'center',
      marginRight: '6px',
      marginBottom: '0px',
      padding: '3px 6px 3px 6px',
      /*padding: '0px 0.3em 2px 0.23em',*/
      /*background: '#FFFFFF',*/
      background: '#FFFFFF',
      border: '1px solid #CACACA',
      borderRadius: '3px',
      cursor: 'pointer',
      lineHeight: '1.714',
      wordBreak: 'keep-all',
      whiteSpace: 'nowrap',
      breakInside: 'avoid',
      maxWidth: '100%'
    },
    label: {
      border: '0px solid #ECECEC',
      borderRadius: '3px',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: 600,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
     },
    close: {
      fontSize: '12px',
      marginLeft: '12px',
    },
    clearButton: {
			background: 'transparent',
      '&:hover': {
				background: 'transparent'
			},
		},
  })
);

interface NameLabelProps {
  label: string;
  selected?: boolean;
  onClose?: () => void;
  overrideWidth?: boolean;
  additionalClasses?: any;
}

const NameLabel = React.forwardRef(
  (props: NameLabelProps, ref: React.Ref<HTMLDivElement>) => {
    const {label, selected, onClose} = props;
    const classes = useStyles();
    const passedClasses = props.additionalClasses || {};

    return (
      <div style={props.overrideWidth ? {maxWidth: '80%'} : {}} className={clsx(classes.root, passedClasses)} ref={ref}>
        <div data-cy='name-tag-label' className={classes.label}>{label}</div>
        {onClose && (
          <IconButton className={classes.clearButton} disableRipple disableFocusRipple={true} onClick={onClose} style={{    padding: '4px 8px 4px 4px ',}}>
						<Clear style={{fontSize: '12px'}}/>
					</IconButton>
        )}
      </div>
    );
  }
);

export default NameLabel;
