/**
    The purpose of this component is to Create and Edit the properties of a
    custom recurring project. It's a bigass form, and accordingly has a lot
    of markup.
**/
interface CustomRecurringProjectProps {
  options?: any; // existing custom RPT options
  onAccept: any; // function that recieves the new/edited options
  onClose: any;
};

import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  makeStyles
} from '@material-ui/core';

import Dropdown from '../../common/Dropdown';
import { DatePicker } from '../../common/DatePicker';
import { WeekDays } from '../../common/WeekDays';
import { MonthDays } from '../../common/MonthDays';
import { useGlobalStyles } from '../../common/Style';
import { CALENDAR_VIEW_DAYS } from '../../../constants/days';


/** The 'number' dropdown should be less wide that default **/
const createStyles = makeStyles({
  smallDropdown: {
    minWidth: 80
  }
});

const DEFAULT_OPTIONS = {
  repeatNumber:     1,
  repeatInterval:   '1',
  calendarType:     '1',
  ends:             'never',
  endsOn:           null,
  endsAfter:        null,
  copyRoles:        true,
  copyFiles:        true,
  copyMessages:     true,
  copyDueDates:     true,
  copyStatuses:     false,
  selectedWeekday:  1,
  selectedMonthday: 1,
  selectedMonth: 1
};

/**! The two below must be synced with the ids on the BE **/
const TYPE_OPTIONS = [
  { id: '1', name: 'Day' },
  { id: '2', name: 'Week' },
  { id: '3', name: 'Month' },
  { id: '4', name: 'Year' },
];
const CALENDAR_OPTIONS = [
  { id: '1', name: 'Business (Mon-Fri)'},
  { id: '2', name: 'Calendar (Mon-Sun)'},
];

/** Generate an array of 1..x formatted as our options dropdowns require**/
const getNumberOptions = (amount: number) => {
  var options = [{id: '1', name: '1'}]
  for ( let i = 2; i < amount; i++ ) {
    var newOption = {id: String(i), name: String(i)}
    options.push(newOption);
  }
  return options;
};

const NUMBER_OPTIONS = getNumberOptions(20);

export function CustomRecurringProjects(props: CustomRecurringProjectProps) {
  const classes = createStyles();
  const global = useGlobalStyles();
  /** SOT for the options **/
  const [ options, setOptions ] = useState<any>(props.options || DEFAULT_OPTIONS)
  const allCopiesChecked: boolean = options.copyRoles && options.copyFiles &&
    options.copyMessages && options.copyDueDates && options.copyStatuses;

  /** Pass one of the boolean options and it will switch within options SOT **/
  const toggleOption = (option: string) => {
    // do you like the word options?
    setOptions({...options, [option]: !options[option]});
  }

  const toggleAllCopies = (e: any) => {
    if ( allCopiesChecked ) {
      setOptions({...options, copyRoles: false, copyFiles: false, copyMessages: false, copyDueDates: false, copyStatuses: false})
    } else {
      setOptions({...options, copyRoles: true, copyFiles: true, copyMessages: true, copyDueDates: true, copyStatuses: true})
    }
  }
  /** Similar to getNumberOptions, but adds 'occurances' for particular UI **/
  const manyOccurances = (num: number) => {
    var occurances = [{id: '0', name: 'Choose Occurrences'}, {id: '1', name: '1 occurrence'}];
    for ( let i = 2; i < num; i++ ) {
      occurances.push({id: (i).toString(), name: `${i} occurrence`});
    }

    return occurances;
  }

  const handleEndChange = (e: any) => {
    setOptions({...options, ends: e.target.value});
  };

  /** When updating the # of occurances field, we want to sent the 'ends' type
     for the UI */
  const handleOccuranceUpdate = (value: string) => {
    setOptions({...options, endsAfter: Number(value), ends: 'after'});
  };
  /** As above, update endsOn, and set the 'ends' type for UI */
  const handleDateUpdate = (date: string | Date) => {
    setOptions({...options, endsOn: date, ends: 'on'});
  };
  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.keyCode === 27 ) { props.onClose(); }
  }

  return(
    <Dialog 
      onKeyUp={handleKeyUp}
      open={true}
    >
      <DialogTitle>
        <Typography variant={'h5'}>Custom Repeating Project</Typography>
      </DialogTitle>

      <DialogContent>
        {/* start first row */}
        <div style={{display: 'flex', alignItems: 'center', marginBottom: 14}} id='first-row'>
          <div style={{display: 'flex', flexDirection: 'column'}} id='rightside'>
            <div>
              <Typography>Repeat Every</Typography>
            </div>
            <div style={{display: 'flex', marginRight: 20}}>
              <div data-cy='repeat-every' style={{marginRight: 10}}>
                <Dropdown
                  classes={ classes.smallDropdown}
                  options={NUMBER_OPTIONS}
                  selected={String(options.repeatNumber)}
                  handleUpdate={(e: any) => setOptions({...options, repeatNumber: Number(e)})}
                  dHeight={'45px'}
                />
              </div>
              <div data-cy='repeat-time'>
                <Dropdown
                  handleUpdate={(e: any) => setOptions({...options, repeatInterval: e})}
                  options={TYPE_OPTIONS}
                  selected={String(options.repeatInterval)}
                  dHeight={'45px'}
                />
              </div>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column'}} id='leftside'>
            <Typography>Calendar Type</Typography>
            <Dropdown
              options={CALENDAR_OPTIONS}
              selected={String(options.calendarType)}
              handleUpdate={(a: any) => setOptions({...options, calendarType: a})}
              dHeight={'45px'}
            />
          </div>
        </div>
        { /* end first row */ }

        { /* Weekday Picker for 'Weekly' RPTs */ }
        { /* We want the == coercion here */ }
        { options.repeatInterval == '2' &&
          <div style={{marginBottom: 14}}>
            <Typography>Repeat on</Typography>
            <WeekDays disableWeekends={options.calendarType === '1'} selected={options.selectedWeekday} onChange={(i: any) => setOptions({...options, selectedWeekday: i})} />
          </div>
        }

        { /* We want the == coercion here*/ }
        { options.repeatInterval == '3' &&
          <div
            className={global.flexColumnCenter}
            style={{marginBottom: 14}}
          >
            <Typography>Repeat on</Typography>
            <MonthDays
              selected={options.selectedMonthday}
              onChange={(num: string) =>
                setOptions({...options, selectedMonthday: Number(num)})
              }
              days={
                String(options.calendarType) === '2' 
                  ? CALENDAR_VIEW_DAYS.monthly.calendar.positive
                  : CALENDAR_VIEW_DAYS.monthly.business.positive
              }
            />
          </div>
        }

        { /* start second row */ }
        <div style={{marginTop: 6}}>
          <div style={{display: 'flex', alignItems: 'flex-end'}}>
            <div id='col1'>
              <FormControl>
                <FormLabel>Ends</FormLabel>
                <RadioGroup value={options.ends} onChange={handleEndChange}>
                  <FormControlLabel value={'never'} control={<Radio disableRipple={true} color='primary'/>} label={'Never'} />
                  <FormControlLabel value={'on'} control={<Radio disableRipple={true} color='primary'/>} label={'On'} />
                  <FormControlLabel value={'after'} control={<Radio disableRipple={true} color='primary'/>} label={'After'} />
                </RadioGroup>
              </FormControl>
            </div>
            <div id='col2'>
              <div>
                <DatePicker
                  value={options.endsOn}
                  onChange={handleDateUpdate}
                />
              </div>
              <div data-cy='after' style={{marginBottom: 6, marginTop: 12}}>
                <Dropdown  small handleUpdate={handleOccuranceUpdate} options={manyOccurances(21)} selected={options.endsAfter && String(options.endsAfter)} />
              </div>
            </div>
          </div>
        </div>
          <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
            <Typography style={{marginRight: 8}}>Retain</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allCopiesChecked}
                  color='primary'
                  onClick={toggleAllCopies}
                />
              }
              label={'All'}
            />
          </div>
          <div>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={options.copyRoles}
                    onClick={() => toggleOption('copyRoles')}
                  />
                }
                label={'Roles'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.copyFiles}
                    color='primary'
                    onClick={() => toggleOption('copyFiles')}
                  />
                }
                label={'Files'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.copyMessages}
                    color='primary'
                    onClick={() => toggleOption('copyMessages')}
                  />
                }
                label={'Messages'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.copyDueDates}
                    color='primary'
                    onClick={() => toggleOption('copyDueDates')}
                  />
                }
                label={'Due Dates'}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options.copyStatuses}
                    color='primary'
                    onClick={() => toggleOption('copyStatuses')}
                  />
                }
                label={'Task Status'}
              />
            </FormGroup>
          </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => props.onAccept(options)}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
