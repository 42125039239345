import React from 'react';

import {Theme, makeStyles} from '@material-ui/core/styles';
import {
  Button,
  Popper,
  Tab,
  Grid,
  Paper,
  Typography,
  ListItemIcon,
  ClickAwayListener,
  List,
  ListItem,
  Snackbar,
  Tooltip,
} from '@material-ui/core/';
import clsx from 'clsx';

import {TabPanel, TabContext, TabList, Alert} from '@material-ui/lab/';

import {dependencyDataGeneratorFunc, dependenciesPresentFunc, handleDeleteTaskDependencyFunc} from './DependencyFunctions'

import {
  Add,
  CalendarToday,
  Link,
  Clear,
  InsertLink,
  RemoveCircleOutline,
} from '@material-ui/icons';

import {gql, useMutation} from '@apollo/client';
import {IconButton} from '@material-ui/core';
import WaitingOn from './components/WaitingOn';
import BlockingOn from './components/BlockingOn';
import RelatedOnly from './components/RelatedOnly';
import { useDispatchContext } from '../../../../../../../store';

const tabOptions = [
  {tabVal: '1', tabName: 'Waiting On'},
  {tabVal: '2', tabName: 'Blocking'},
  {tabVal: '3', tabName: 'Related To'},
];

export const INSERT_TASK_DEPENDENCY = gql`
  mutation CreateTaskDependency($taskDependency: TaskDependencyAttributes!) {
    createTaskDependency(taskDependency: $taskDependency) {
      success
    }
  }
`;
export const DELETE_TASK_DEPENDENCY = gql`
  mutation DeleteTaskDependency($taskId: ID!, $relatedId: ID!, $dependencyOperationName: String) {
    deleteTaskDependency(taskId: $taskId, relatedId: $relatedId, dependencyOperationName: $dependencyOperationName) {
      errors {
        path
        message
      }
      success
      relatedNewDate
      taskNewDate
    }
  }
`;

interface TaskDependencyProps {
  lists: any;
  task: any;
  skinnyLists: any;
  setSkinnyLists: React.Dispatch<any>;
  dependencies: any;
  setDependencies: React.Dispatch<{}>;
}

export default function TaskDependency(props: any) {
  const {
    lists,
    task,
    updateMultipleTasksInCache,
    skinnyLists,
    setSkinnyLists,
    dependencies,
    setDependencies,
    refreshTaskNotifications
  } = props;
  const dispatch = useDispatchContext();
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState('1');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const dependencyDataGenerator = dependencyDataGeneratorFunc;
  const dependenciesPresent = dependenciesPresentFunc;
  const handleDeleteTaskDependency = handleDeleteTaskDependencyFunc;

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openDeleteSnackbar, setOpenDeleteSnackbar] = React.useState(false);

  const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const snackState = (value: any) => {
    setOpenSnackbar(value);
  };

  const [
    deleteTaskDependency,
    {loading: mutationLoading, data: mutationData, error: mutationError},
  ] = useMutation(DELETE_TASK_DEPENDENCY);

  const handleClose = () => {
    setAnchorEl(null);
  };

  function getMessage(_msg: string) {
  }

  const handleCloseSnackBar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  function onDependencyClick(taskToOpen: any){
    if(props.isCreateProject){
      props.onDependencyClick && props.onDependencyClick(taskToOpen);
    } else {
      openTask(taskToOpen)
    }
  }

  function openTask(taskToOpen: string) {
    let listToOpen = -1;

    // 1. determine list to which this task belongs
    for (let i = 0; i < skinnyLists.length; ++i) {
      for (let j = 0; j < skinnyLists[i].tasks.length; ++j) {
        if (skinnyLists[i].tasks[j].id == taskToOpen) {
          listToOpen = skinnyLists[i].id;
          break;
        }
      }
    }

    const parsedFilters = {
      customTaskPageSelectedTaskIds: [taskToOpen.toString()],
      taskPageLists: [listToOpen.toString()],
      taskPagePane: 0,
    };

    dispatch({
      type: 'SET_USER_FILTERS',
      filters: parsedFilters
    });

    dispatch({
      type: 'UPDATE_PROJECT_SELECTION_OPTONS',
      selectedListIds: [parsedFilters?.taskPageLists[0]],
      selectedSectionIds: null
    });
  }

  return (
    <div className={classes.root}>
      {dependenciesPresent(dependencies, task) ? (
        // if we have dependencies
        <div>
          {!props.fromCreateProject && <Grid
            container
            direction="column"
            spacing={1}
            style={{marginLeft: '10px'}}
          >
            <Grid item xs>
              <Typography
                className={clsx(classes.secondary, classes.label)}
                style={{
                  minWidth: '0px',
                  paddingTop: '3px',
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
                variant="h6"
              >
                Dependencies
              </Typography>
            </Grid>
            <Grid item xs>
              {dependencies[task.id].map((dependency: any, index: number) => {
                let [
                  text_prefix,
                  text_suffix,
                  taskDisplayName,
                  fullTaskDisplayName,
                  bufferTruncate,
                  taskDisplayId,
                  relatedId,
                  isBlocking,
                ] = dependencyDataGenerator(dependency, task, skinnyLists);
                return (
                  <List
                    key={index}
                    component="nav"
                    aria-label="main mailbox folders"
                    disablePadding={true}
                  >
                    <ListItem
                      data-cy="list-task-dependency"
                      button
                      disableGutters={true}
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        {text_prefix.toString().split(' ')[0] == 'Linked' ? (
                          <InsertLink
                            fontSize="small"
                            style={{color: '#60606099'}}
                          />
                        ) : text_prefix.toString().split(' ')[0] ==
                          'Blocking' ? (
                          <RemoveCircleOutline
                            fontSize="small"
                            style={{color: '#60606099'}}
                          />
                        ) : (
                          <CalendarToday
                            fontSize="small"
                            style={{color: '#60606099'}}
                          />
                        )}
                      </ListItemIcon>
                      <Typography
                        variant="inherit"
                        className={classes.prefixStyling}
                      >
                        {text_prefix}
                      </Typography>
                      <Typography
                        onClick={e => onDependencyClick(taskDisplayId.toString())}
                        variant="inherit"
                        data-cy="task-links"
                      >
                        {taskDisplayName.toString().length > bufferTruncate ? (
                          <Tooltip
                            title={
                              <div style={{fontSize: '1em'}}>
                                {fullTaskDisplayName}
                              </div>
                            }
                          >
                            <Typography className={classes.taskDisplayName}>
                              {taskDisplayName}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography className={classes.taskDisplayName}>
                            {taskDisplayName}
                          </Typography>
                        )}
                      </Typography>
                      <Typography
                        variant="inherit"
                        className={
                          text_suffix == ''
                            ? classes.hideSuffixStyling
                            : classes.suffixStyling
                        }
                      >
                        {text_suffix}
                      </Typography>
                      <IconButton
                        onClick={e => {
                          handleDeleteTaskDependency(
                            e,
                            Boolean(isBlocking),
                            relatedId,
                            task,
                            skinnyLists,
                            setSkinnyLists,
                            deleteTaskDependency,
                            mutationError,
                            setOpenDeleteSnackbar,
                            refreshTaskNotifications
                          )
                          dispatch({type: 'DEPEDENCIES_UPDATED'})
                          }
                        } 
                        data-cy="delete-dependency"
                      >
                        <Clear style={{fontSize: '12px'}} />
                      </IconButton>
                    </ListItem>
                  </List>
                );
              })}
            </Grid>
          </Grid>
          }
          <Button
            variant="contained"
            // color="primary"
            onClick={handleOpenPopper}
            startIcon={<Add />}
            className={classes.linkButton}
            data-cy="dependency-link"
            style={props.fromCreateProject && {marginLeft: 0}}
          >
            <span style={{whiteSpace: 'nowrap', color: '#3A84FF'}}>
              Link task
            </span>
          </Button>
        </div>
      ) : (
        // if we dont have dependencies
        <div style={{display: 'flex'}}>
          {!props.fromCreateProject && <div style={{marginLeft: '15px'}}>
            <Typography
              className={clsx(classes.secondary, classes.label)}
              style={{
                minWidth: '0px',
                paddingTop: '3px',
                flex: 1,
                display: 'flex',
                alignItems: 'center',
              }}
              variant="h6"
            >
              Dependencies
            </Typography>
          </div>
          }
          <div style={props.fromCreateProject ? undefined : {marginLeft: '27px', marginTop: '-8px'}}>
            <Button
              color="inherit"
              onClick={handleOpenPopper}
              className={classes.linkButton2}
              data-cy="no-dependency-link"
              startIcon={
                <Link
                  style={{
                    marginTop: '2px',
                    fontWeight: 600,
                    // marginRight: '4px',
                    // marginLeft: '8px',
                    // color: '#606060',
                  }}
                />
              }
            >
              <span style={{marginTop: '2px'}}>Link tasks</span>
            </Button>
          </div>
        </div>
      )}
      <Popper
        open={open}
        id={id}
        placement="bottom-start"
        anchorEl={anchorEl}
        className={classes.popper}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper variant="outlined" square className={classes.box}>
            <TabContext value={tabValue}>
              <Grid container direction="column">
                <TabList
                  onChange={handleTabChange}
                  TabIndicatorProps={{style: {backgroundColor: 'white'}}}
                >
                  {tabOptions.map(idx => (
                    <Tab
                      key={idx.tabVal}
                      value={idx.tabVal}
                      label={
                        <span>
                          <Button
                            variant="outlined"
                            color="inherit"
                            data-cy={idx.tabName}
                            className={
                              tabValue === idx.tabVal
                                ? classes.active
                                : classes.tabButton
                            }
                          >
                            {idx.tabName}
                          </Button>
                        </span>
                      }
                    />
                  ))}
                </TabList>
              </Grid>

              <TabPanel value="1">
                <WaitingOn
                  snackbarStat={snackState}
                  getMsg={getMessage}
                  anchorEl={handleOpenPopper}
                  task={task}
                  skinnyLists={skinnyLists}
                  setSkinnyLists={setSkinnyLists}
                  updateMultipleTasksInCache={updateMultipleTasksInCache}
                />
              </TabPanel>
              <TabPanel value="2">
                <BlockingOn
                  snackbarStat={snackState}
                  getMsg={getMessage}
                  anchorEl={handleOpenPopper}
                  task={task}
                  skinnyLists={skinnyLists}
                  setSkinnyLists={setSkinnyLists}
                  updateMultipleTasksInCache={updateMultipleTasksInCache}
                  refreshTaskNotifications={refreshTaskNotifications}
                />
              </TabPanel>
              <TabPanel value="3">
                <RelatedOnly
                  snackbarStat={snackState}
                  getMsg={getMessage}
                  anchorEl={handleOpenPopper}
                  task={task}
                  skinnyLists={skinnyLists}
                  setSkinnyLists={setSkinnyLists}
                  updateMultipleTasksInCache={updateMultipleTasksInCache}
                  refreshTaskNotifications={refreshTaskNotifications}
                />
              </TabPanel>
            </TabContext>
          </Paper>
        </ClickAwayListener>
      </Popper>
      <Snackbar
        open={openSnackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{marginLeft: '4rem'}}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
      >
        <Alert severity="success">Dependency successfully added</Alert>
      </Snackbar>
      {mutationError?.message.length === 0 ? (
        <Snackbar
          open={!openDeleteSnackbar}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={{marginLeft: '4rem'}}
          autoHideDuration={2000}
          onClose={handleCloseSnackBar}
        >
          <Alert severity="success">Delete successful</Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={openDeleteSnackbar}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          autoHideDuration={2000}
          style={{marginLeft: '4rem'}}
          onClose={handleCloseSnackBar}
        >
          <Alert severity="error">Delete failed</Alert>
        </Snackbar>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },

  '.MuiButton-label': {
    marginRight: '-1px',
    whiteSpace: 'nowrap',
  },
  active: {
    background: '#EFF5FF',
    fontWeight: 'bolder',
    fontFamily: 'Montserrat',
    borderWidth: 'thin',
    zIndex: 1,
    '&:hover': {
      borderWidth: 'thin',
    },
  },
  linkButton2: {
    color: '#AFAFAF',
    backgroundColor: 'transparent',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    borderWidth: 'thin',
    borderColor: 'transparent',
    // border: '0px',
    paddingLeft: '6px',
    paddingRight: '4px',
    '&:hover': {
      borderWidth: 'thin',
      // borderRadius: '0px',
      backgroundColor: 'transparent',
      paddingLeft: '6px',
      paddingRight: '4px',
    },
  },
  linkButton: {
    color: '#3A84FF',
    opacity: '1',
    backgroundColor: 'transparent',
    fontWeight: 'normal',
    fontFamily: 'Montserrat',
    borderWidth: 'thin',
    paddingLeft: '6px',
    paddingRight: '4px',
    marginLeft: '11px',
    '&:hover': {
      borderWidth: 'thin',
      backgroundColor: 'transparent',
    },
  },

  listItem: {
    paddingTop: '0px',
    paddingBottom: '0px',
    '&:hover': {
      borderWidth: 'thin',
      backgroundColor: 'transparent',
    },
  },

  tabButton: {
    borderColor: '#D8D8D8',
    color: '#A0A0A0',
    fontWeight: 'bolder',
    fontFamily: 'Montserrat',
    borderWidth: 'thin',

    '&:hover': {
      borderWidth: 'thin',
    },
  },
  tab: {
    marginLeft: '12px',
  },
  popper: {
    zIndex: 1200,
    marginLeft: '5.5rem',
    width: '400px',
    height: '260px',
  },
  box: {
    borderStyle: 'solid',
    borderWidth: 'thin',
    boxSizing: 'border-box',
    paddingLeft: '10px',
    borderColor: '#D8D8D8',
    fontFamily: 'Montserrat',
  },
  secondary: {
    color: '#606060',
  },
  label: {
    minWidth: '80px',
  },
  taskDisplayName: {
    fontSize: '12px',
    color: '#1159D0',
    fontFamily: 'Montserrat',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  suffixStyling: {
    fontSize: '12px',
    color: '#606060',
    fontFamily: 'Montserrat',
    margin: '0px 0px 0px 4px',
    whiteSpace: 'nowrap',
  },
  prefixStyling: {
    fontSize: '12px',
    color: '#606060',
    fontFamily: 'Montserrat',
    margin: '0px 4px 0px -12px',
    whiteSpace: 'nowrap',
  },
  hideSuffixStyling: {
    display: 'none',
  },
}));
