// This is a helper service that saves the previous state based on the user preference
// Whenever the values change, it keeps updating the state and makes sure it persist in the app.
// To use it, you need to: `import { useLocalStorage, useSessionStorage } from './utils/useStorage';`
// then get the localState by: `const prevLocalStatus = useLocalStorage("isFluxTabOpen", [open, setOpen]);`
// and get the sessionState by: `const prevSessionStatus = useSessionStorage("isFluxTabOpen", [open, setOpen]);`
// PS: make sure [open, setOpen] is from your state declaration.
// The ggiven state can be anything. E-g: [text, setText], [title, setTitle], [name, setName]...

import { useEffect, useRef } from 'react';

function getItem(storage: any, key: string) {
  const value = storage.getItem(key);

  if (!value) return null;

  try {
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
}

function setItem(storage: any, key: string, value: string) {
  storage.setItem(key, JSON.stringify(value));
}

/**
 * A wrapper for useState that retrieves the initial state from a
 * WebStorage object and saves the state there as it changes.
 */
// @ts-ignore: Unreachable code error
export default function useStorage(storage: any, key: string, [state, setState]) {
  const checkStorageRef = useRef(true);

  // Check for an existing value on the initial render...
  if (checkStorageRef.current) {
    checkStorageRef.current = false;
    const storedState = getItem(storage, key);
    if (storedState) setState(storedState);
  }

  useEffect(() => {
    setItem(storage, key, state);
  }, [storage, key, state]);

  return [state, setState];
}

/**
 * A convenient wrapper for useStorage(window.localStorage, ...)
 */
export function useLocalStorage(key: string, [state, setState]: any) {
  return useStorage(getStorage('localStorage'), key, [state, setState]);
}

/**
 * A convenient wrapper for useStorage(window.sessionStorage, ...)
 */
export function useSessionStorage(key: string, [state, setState]: any) {
  return useStorage(getStorage('sessionStorage'), key, [state, setState]);
}

function getStorage(name: string) {
  // @ts-ignore: Unreachable code error
  return typeof window === 'object' && window[name]
  // @ts-ignore: Unreachable code error
    ? window[name]
    : createMemoryStorage();
}

function createMemoryStorage() {
  const storage = {};
  return {
    getItem(key: string) {
      // @ts-ignore: Unreachable code error
      return storage[key];
    },
    setItem(key: string, value: string) {
      // @ts-ignore: Unreachable code error
      storage[key] = value;
    }
  };
}