import React from 'react';
import clsx from 'clsx';
import * as cs from '../../constants/theme';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      height: 44,
      width: 34,
      border: '1px solid #CACACA',
      borderRadius: 3,
      marginRight: 3,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 700,
      color: '#CACACA',
      cursor: 'pointer'
    },
    container: {
      display: 'flex'
    },
    selected: {
      borderColor: cs.COLORS.primary,
      backgroundColor: cs.COLORS.primary,
      color: '#FFF'
    },
    disabled: {
      backgroundColor: '#CACACA',
      color: '#0000001F'
    }
  })
);

interface WeekDaysProps {
  selected: number;
  onChange: any;
  disableWeekends?: boolean;
}

function WeekDays(props: WeekDaysProps) {
  const classes = useStyles();

  const handleClick = (index: number) => {
    if (props.disableWeekends && [0, 6].includes(index)) { return; }
    props.onChange(index);
  }

  const DAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  return(
    <div className={classes.container}>
      { DAYS.map((day: any, index: number) => {
        const isSelected = index == props.selected;
        const disabled = [0, 6].includes(index) && props.disableWeekends;
        return(
        <div onClick={() => handleClick(index)} className={clsx(classes.box, isSelected && classes.selected, disabled && classes.disabled, (!disabled && !isSelected ? 'hover:bg-gray-180' : ''))}>
          <span>{day}</span>
        </div>);
      })}
    </div>
  )
}

export {WeekDays};
