import { createQueryHook, gql } from '../graphqlHelpers';
import { TemplateLists } from './__generated__/TemplateLists';

export const useTemplateLists = createQueryHook<TemplateLists, {}>(gql`
  query TemplateLists {
    templateLists {
      id
      name
      labelName
    }
  }
`);
