import { Tab, Tabs, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useDetailedTaskInfo } from '../../../../../../../graphql/queries/DetailedTaskInfo';
import { useRejectMessageNavigation } from '../../../../../../../hooks/RejectMessageNavigate';
import { useUpdateTask } from '../../../../../../../graphql/mutations/UpdateTaskHook';
import SidePanel from '../../../../../../task/TaskPage/components/SidePanel';
import idx from 'idx';
import clsx from 'clsx';
import { IProjectOptionsSelected } from '../../../common/interfaces';

const primaryColor = '#3A84FF';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flex: {
            display: 'flex',
        },
        container: {
            width: '100%',
            height: '100%',
        },
        column: {
            flexDirection: 'column',
        },
        row: {
            flexDirection: 'row',
        },
        tabsRoot: {
            paddingLeft: '16px',
            minHeight: '41px'
        },
        tabRoot: {
            color: '#606060',
            padding: '0px 0px 6px',
            alignItems: 'flex-end',
            minHeight: '40px',
        },
        pmColor: {
            color: primaryColor,
        },
        bgColor: {
            backgroundColor: primaryColor,
        },
        hover: {
            cursor: 'pointer'
        },
        t2: {
            fontSize: '18px',
            lineHeight: '22px',
            fontWeight: 'bold',
        },
    })
);

interface ISidePanelContainer {
    selectedTask: any;
    setSelectedTask: any;
    tasks: any[];
    setTasks: React.Dispatch<React.SetStateAction<any[]>>;
    projectOptionsSelected: IProjectOptionsSelected;
    dependencies: any;
    skinnyLists: any[];
    setSkinnyLists: React.Dispatch<React.SetStateAction<any[]>>;
    onFilesDropped(): void;
    open: boolean;
    paneIndex: number;
    setPaneIndex: React.Dispatch<React.SetStateAction<number>>;
    panelPaneIndex: number;
    setPanelPaneIndex: React.Dispatch<React.SetStateAction<number>>;
    externalCompanyId: any;
    updatedStatusFromFiles: String;
    setUpdatedStatusFromFiles: React.Dispatch<React.SetStateAction<String>>;
    additionalInternalOwners: any[];
	additionalExternalOwners: any[];
	setAdditionalInternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
	setAdditionalExternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
    selectedTasksIds: string[];
}

export default function SidePanelContainer(props: ISidePanelContainer) {

    const { selectedTask, setSelectedTask, tasks, setTasks, projectOptionsSelected, skinnyLists, setSkinnyLists,
        onFilesDropped, open, paneIndex, setPaneIndex, panelPaneIndex, setPanelPaneIndex, externalCompanyId, updatedStatusFromFiles, setUpdatedStatusFromFiles,
        additionalInternalOwners, additionalExternalOwners, setAdditionalInternalOwners, setAdditionalExternalOwners, selectedTasksIds } = props;
    const classes = useStyles();
    
    const [taskToUpdate, setTaskToUpdate] = useState<any>({});
    const [updateTask, _] = useUpdateTask(taskToUpdate);
    const rejectMessageNavigate = useRejectMessageNavigation();


    const panelLabels = [{ label: 'Internal' }, { label: 'External' }];

    const [selectedTaskDetails, setSelectedTaskDetails] = useState({});

    const detailedTaskResponse = useDetailedTaskInfo({ id: Boolean(tasks.length && selectedTask) && selectedTask.id });

    function getTaskDetails(task: any) {
        return {
            __typename: 'Task',
            id: task.id.toString(),
            name: String(task.name),
            section: task.section,
            description: task.description ? String(task.description) : '',
            priority: task.priority,
            status: task.status,
            tags: task.tags,
            dueDate: task.dueDate,
            startDate: task.startDate,
            relativeDueDate: task.relativeDueDate,
            relativeStartDate: task.relativeStartDate,
            isVolatile: true,
            // list: { id: task.list.id.toString(), name: task.list.name, __typename: "List" },
            listNumber: parseInt(task.listNumber),
            userOwners: task.userOwners ? task.userOwners : [],
            teamOwners: task.teamOwners ? task.teamOwners : [],
            userReviewers: task.userReviewers ? task.userReviewers : [],
            teamReviewers: task.teamReviewers ? task.teamReviewers : [],
            externalUserOwners: task.externalUserOwners ? task.externalUserOwners : [],
            externalTeamOwners: task.externalTeamOwners ? task.externalTeamOwners : [],
            externalUserReviewers: task.externalUserReviewers ? task.externalUserReviewers : [],
            externalTeamReviewers: task.externalTeamReviewers ? task.externalTeamReviewers : []
        } as any;
    }

    useEffect(() => {
        if (!taskToUpdate.id) {
          return;
        }
        updateTask();
        setTaskToUpdate({});
      }, [taskToUpdate]);

    useEffect(() => {
        const detailedTaskData = idx(detailedTaskResponse, (r: any) => r.data.task);
        if (detailedTaskData && selectedTask && selectedTask.id && open) {
            const companyPosition = projectOptionsSelected.projectType === 'internal' ? 'internal' : projectOptionsSelected.projectType === 'share' ? 'responder' : 'requester';
            const newTasks = JSON.parse(JSON.stringify(tasks));
            const newTaskIndex = newTasks.findIndex((t: any) => t.id === selectedTask.id);
            if (newTaskIndex !== -1) {
                const updatedStatus = Boolean(idx(updatedStatusFromFiles, status => status.length > 0)) ? updatedStatusFromFiles : newTasks[newTaskIndex].status;
                newTasks[newTaskIndex] = { ...detailedTaskData, ...getTaskDetails(newTasks[newTaskIndex]), companyPosition: companyPosition, status: updatedStatus };
                setSelectedTaskDetails(newTasks[newTaskIndex]);
                setUpdatedStatusFromFiles('');
                // setTasks(newTasks);
            }
        }
    }, [detailedTaskResponse.called, detailedTaskResponse.loading, detailedTaskResponse.data, selectedTask, open, panelPaneIndex, tasks]);

    function updateTaskStatus(taskId: string, newStatus: string) {
        const newTasks = JSON.parse(JSON.stringify(tasks));
        const updatedTask = newTasks.find((task: any) => task.id === taskId);
        updatedTask.status = newStatus;
        setTasks(newTasks);
        setTaskToUpdate({ id: taskId, status: newStatus });

        setTimeout(() => {
          if (newStatus === 'RejectedInternal') {
            rejectMessageNavigate(taskId, 'internal');
          }
          if (newStatus === 'RejectedExternal') {
            rejectMessageNavigate(taskId, 'public');
          }      
        }, 100);
    }

    function updateTaskName(taskId: string, newName: string) {
        const newTasks = JSON.parse(JSON.stringify(tasks));
        const updatedTask = newTasks.find((task: any) => task.id === taskId);
        updatedTask.name = newName;
        setTasks(newTasks);
    }

    function toggleSidePanelType(_: React.ChangeEvent<{}>, value: number) {
        setPaneIndex(0);
        setPanelPaneIndex(value);
    }

    function createProjectSelectedTask(task : any) {
        if(selectedTasksIds.includes(task.id)){
            setSelectedTask(task);
        }
    }

    return (
        <div className={clsx(classes.flex, classes.container, classes.column)} style={{ padding: '0px calc((100% - 500px)/2)' }} data-cy='side-panel-container'>
            {(open && selectedTask && selectedTask.id) ?
                (
                    <>
                        <Tabs
                            aria-label="Tabs"
                            className={classes.tabsRoot}
                            classes={{ indicator: classes.bgColor }}
                            style={{ visibility: (projectOptionsSelected.projectType === 'internal' ? 'hidden' : 'visible') }}
                            onChange={toggleSidePanelType}
                            value={panelPaneIndex}
                            data-cy='project-type-tab'
                        >
                            {
                                panelLabels.map((tab, index) => (
                                    <Tab
                                        classes={{ root: clsx(classes.tabRoot, classes.t2), selected: clsx(classes.pmColor, classes.t2) }}
                                        disableRipple
                                        key={tab.label}
                                        label={
                                            <Typography
                                                className={clsx(classes.hover, classes.t2)}
                                                color="inherit"
                                            >
                                                {tab.label}
                                            </Typography>}
                                        value={index}
                                    />
                                ))
                            }
                        </Tabs>
                        <SidePanel
                            isInternalCreateProject={panelPaneIndex === 0}
                            isExternalCreateProject={panelPaneIndex === 1}
                            multiTasks={selectedTask ? [selectedTask] : []}
                            open={open}
                            paneIndex={paneIndex}
                            selectedTask={selectedTaskDetails}
                            setPaneIndex={(index: number) => setPaneIndex(index)}
                            setTasks={(tasks : any) => setTasks([...tasks])}
                            tasks={tasks}
                            allTasks={tasks}
                            updateTaskStatus={updateTaskStatus}
                            updateTaskName={updateTaskName}
                            onFilesDropped={onFilesDropped}
                            skinnyLists={skinnyLists}
                            setSkinnyLists={setSkinnyLists}
                            externalCompanyId={panelPaneIndex === 1 ? externalCompanyId : undefined}
                            createProjectSelectedTask={createProjectSelectedTask}
                            additionalNewOwners = {(panelPaneIndex === 0) ? additionalInternalOwners : (panelPaneIndex === 1) ? additionalExternalOwners : undefined}
                            setAdditionalNewOwners = {(panelPaneIndex === 0) ? setAdditionalInternalOwners : (panelPaneIndex === 1) ? setAdditionalExternalOwners : undefined}
                        />
                    </>
                ) : (
                    <div className={clsx(classes.flex, classes.container)} style={{ justifyContent: 'center', alignItems: 'center', width: '500px' }}>
                        <Typography>Please select a task to preview</Typography>
                    </div>
                )
            }
        </div>
    )
}
