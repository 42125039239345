/* Usage Examples:
 * (within a function component in which useSetFilters has been invoked as
 * `setFilters`) 
 *
 *  // Will set the list(s) shown on the TaskTable to `list.id`, and leave
 *  // every thing else in place, example: status filter will be the same
 *  setFilters({
 *    taskPageSelectedLists: [list.id]
 *  })
 *
 *
 *  // Will set the lists, and selected task on TaskTable, but will RESET
 *  // all filters like 'status, priority, assigned... etc.'
 *  setFilters({
 *    taskPageSelectedLists: listArray,
 *    taskPageSelectedTask: task.id,
 *  },
 *  {defaultTaskFilters: true})
 */

import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { defaultTaskFilters, defaultCustomTaskPageFilters } from '../constants/filters';
import { useDispatchContext, useStateContext } from '../store';
import { convertTaskPageViewTabStringToInt } from '../helpers/helpers';

const mutation = gql`
  mutation UpdateUserFilters(
    $filters: UserFiltersAttributes!
  ) {
    updateUserFilters(
      filters: $filters
    ) {
      success
    }
  }
`;

export function useSetFilters() {
  const dispatch = useDispatchContext()
  const [ updateFilters ] = useMutation(mutation);
  const [filters, setFilters] = useState<any>(null);

  useEffect(() => {
    if (filters) {
      dispatch({
        type: 'SET_USER_FILTERS',
        filters: filters
      });
      if (filters && filters.taskPageView) {
        filters.taskPageView = convertTaskPageViewTabStringToInt(filters.taskPageView);
      }
      updateFilters({variables: {filters}});
    }
  }, [filters])

  return function(filters: any, options: any = {}) {
    var filtersToSet;
    // see resetTaskFilters example above
    if (options.resetQueryTasksFilters || options.resetTaskFilters) {
      dispatch({
        type: 'SET_QUERY_TASKS_FILTERS',
        queryTasksFilters: {
          tasks_ids: null,
          list_numbers: null
        }
      });
    }
    if ( options.resetTaskFilters) {
      filtersToSet = {...defaultTaskFilters, ...defaultCustomTaskPageFilters, ...filters};
    } else {
      if (options.resetQueryTasksFilters) {
        filtersToSet = {...filters, customTaskPageIdFilter: null}; 
      } else {
        filtersToSet = filters;
      }
    }
    setFilters(filtersToSet);
  }
}
