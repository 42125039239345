import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useState } from "react";

type SimpleDialogProps = {
  title?: string;
  className?: string;
  style?: any;
  children?: React.ReactNode;
  onClose?(): void
};
/**
 * Our design system `SimpleDialog` component reusable anywhere accross the app.
 * @param  `props.title` - The SimpleDialog modal title
 * @param  `props.className` - The SimpleDialog class names to use
 * @param `props.style` - The SimpleDialog inline style to apply
 * @param `props.children` - The SimpleDialog modal content
 * @param `props.onClose` - The SimpleDialog function to callback when the modal is closed
 * @return {React.JSXElementConstructor} The SimpleDialog component.
 */
export const SimpleDialog: React.FC<SimpleDialogProps> = ({title, children, onClose, style}) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (!open && onClose) {
      onClose();
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[1202]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel style={style} className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-sm shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    data-cy="close--simple-dialog-button"
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="w-5 h-5" aria-hidden="true" />
                  </button>
                </div>
                <div className="text-left -mt-[10px]">
                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                   {title}
                  </Dialog.Title>
                </div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}