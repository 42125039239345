import {createMutationHook, gql} from '../graphqlHelpers';
//import {RemoveListOwner, RemoveListOwnerVariables} from './__generated__/RemoveListOwner';

const removeTaskOwner = createMutationHook<any, any>(gql`
	mutation RemoveTaskOwner($taskId: ID!, $owner: OwnerAttributes!) {
		removeTaskOwner(taskId: $taskId, owner: $owner) {
			success
		}
	}
`);

export default removeTaskOwner;

