import {createQueryHook, gql} from '../graphqlHelpers';

export const useTemplateDetails = createQueryHook(gql`
  query TemplateDetails($id: ID!) {
    list(id: $id) {
      id
      name
      labelName
      isVolatile
      tasks {
        list {
          id
          name
        }
        listNumber
        id
        name
        currency
        dueDate
        section {
          id
          name
        }
        dependencies {
          taskId
          relatedId
        }
        description
        priority
        status
        tags {
          id
          tag
        }
        classifications{
          id
          name
        }
        isVolatile
      }
    }
  }
`);
