import React from 'react';
import clsx from 'clsx';
import {Checkbox} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    customCheck: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: 3,
      width: 14,
      height: 14,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#ECECEC',
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#137cbd',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 14,
        height: 14,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
  })
);

interface CheckBoxProps {
  indeterminate?: boolean;
  checkedValue: boolean;
  onClick?: any;
  onChange?: any;
  disabled?: any;
}

export default function CheckBox(props: CheckBoxProps) {
  const classes = useStyles();
  const {indeterminate, checkedValue, onClick, onChange} = props;
  return (
    <Checkbox
      disabled={props.disabled}
      indeterminate={indeterminate}
      checked={checkedValue}
      onClick={onClick}
      onChange={onChange}
      className={classes.customCheck}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
    />
  );
}
