import {createMutationHook, gql} from '../graphqlHelpers';

export const useDeleteMessageLabelType = createMutationHook(gql`
  mutation DeleteMessageLabelType (
    $id: ID!
  ) {
    deleteMessageLabelType(id: $id){
      success
      id
    }
  }
`);

