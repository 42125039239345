import { createQueryHook, gql } from '../graphqlHelpers';

// This Query will return the `AdjBook` matching the current viewing task's company.
// The returned result with have the following format and properties.
const query = gql`
  query AdjBook($taskId: ID!) {
    adjBook(taskId: $taskId) {
      name
    }
  }
`

export const useLazyAdjBookQuery = createQueryHook(query);
