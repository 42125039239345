import React from "react";
import idx from 'idx';
import { Box, Button } from "@material-ui/core";
import { useStyles } from "../Styles";
import { useMutation } from '@apollo/client'
import { ICreateModalSelected } from "../../../constants";
import { createWorkspace } from "../../../../../../../graphql/mutations/createWorkspace";
import { useDispatchContext, useStateContext } from "../../../../../../../store";

interface IActionBar {
  name: string;
  type: ICreateModalSelected;
  currentSpace: any;
  folder: any;
  handleModalClose: () => void;
}

export const ActionBar = ({
  name,
  type,
  currentSpace,
  folder,
  handleModalClose,
}: IActionBar) => {
  const classes = useStyles();
  const dispatch = useDispatchContext();
  const state = useStateContext();
  
  const updateCurrentSpace = (isUpdated: boolean): void => {
    dispatch({
      type: "SET_IS_SPACE_UPDATED",
      isUpdated: isUpdated,
    });
  };

  const [createSpace] = useMutation(createWorkspace);
  const handleCreateNewItem = async () => {
   if (type?.includes('Space')) {
      await createSpace({
        variables: {
          name: name,
          spaceId: state.currentUser.workspace.id,
          isInternal: type === 'internalSpace',
        },
      });
    } else if (type === "folder") {
      await createSpace({
        variables: {
          name: name,
          spaceId: folder.id || currentSpace.id,
          isInternal: currentSpace.isInternal
        },
      });
    }
    updateCurrentSpace(true);
    handleModalClose();
  };
  return (
    <Box className={classes.actionContainer}>
      <Button
        className={classes.button}
        variant="contained"
        onClick={handleCreateNewItem}
      >
        Create
      </Button>
    </Box>
  );
};
