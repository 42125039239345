import { gql} from '../graphqlHelpers';

type ID = number | string;

export interface MoveReportsVariables {
  dataReportIds: ID[];
  workspaceId: ID;
  destinationWorkspaceId: ID;
}

export interface Error {
  path: string;
  message: string;
}

export interface MoveReportsData {
  success: boolean;
  errors: Error[];
}

export const moveReports = gql `
  mutation moveReports($dataReportIds: [ID!]!, $workspaceId: ID!, $destinationWorkspaceId: ID!) {
    moveReports(dataReportIds: $dataReportIds, workspaceId: $workspaceId, destinationWorkspaceId: $destinationWorkspaceId) {
      success
      errors {
        path
        message
      }
    }
  }`;