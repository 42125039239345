import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import idx from 'idx';
import {
  Theme,
  makeStyles,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
import {Typography, ClickAwayListener, TextField} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import Markdown from '../../../../common/Markdown';

import * as cs from '../../../../../constants/theme';
import UserImage from '../../../../common/UserImage';
import Delete from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '12px',
      borderRadius: '3px',
      margin: '3px 0',
      paddingLeft: '16px',
    },
    mb12: {
      marginBottom: '24px',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    grow: {
      flexGrow: 1,
    },
    avatar: {
      width: '25px',
      height: '25px',
      // fontSize: '9px',
      backgroundColor: 'rgba(42,137,75,0.80)',
      marginRight: '12px',
    },
    internal: {
      backgroundColor: 'rgba(39, 146, 162, 0.1)',
    },
    viewed: {
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: '#6EB81D',
      marginRight: '12px',
    },
    primary: {
      color: cs.COLORS.primary,
    },
    secondary: {
      color: '#606060',
      opacity: 0.5,
    },
    content: {
      overflowWrap: 'break-word',
      marginTop: '12px',
      marginLeft: '37px',
      whiteSpace: 'pre-line',
    },
    input: {
      width: '100%',
      color: 'dimgrey',
      lineHeight: '15px',
      wordBreak: 'break-word',
      letterSpacing: '0em',
      fontFamily: cs.FONT.family,
      fontSize: '12px',
      // paddingBottom: '5px',
      fontWeight: 500,
      outline: 'none',
      border: 'none',
      backgroundColor: '#F2F2F2',
      padding: '15px 12px 10px',
      '&$focused': {
        backgroundColor: '#F2F2F2',
      },
    },
    h6: {
      fontSize: cs.FONT.size.xs,
      fontWeight: cs.FONT.weight.regular,
      lineHeight: '21px',
      borderBottom: 'none',
      marginTop: 0,
    },
    form: {
      width: '100%',
      // backgroundColor: '#dbdbdb',
      // padding: 10,
    },
    inputRoot: {
      backgroundColor: '#F2F2F2',
      width: '100%',
      '&:hover': {
        backgroundColor: '#F2F2F2',
      },
      '&$focused': {
        backgroundColor: '#F2F2F2',
      },
    },
    focused: {
      backgroundColor: '#F2F2F2',
    },
    messageMarkdown: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontFamily: cs.FONT.family,
      letterSpacing: '0.0075em',
      fontSize: 12,
      margin: 0,
    },
    internalMessageMark: {
      backgroundColor: '#fdf4d9',
    },
    publicMessageMark: {
      backgroundColor: '#e8f5fb',
    },
    table: {
      border: '1px solid gray',
    },
    td: {
      border: '1px solid gray',
    },
    tr: {
      border: '1px solid gray',
    },
    th: {
      border: '1px solid gray',
    },
  })
);

interface MessageProps {
  data: {
    message: string;
    status: boolean;
    viewed?: boolean; // the conditional we send along to trigger the status
    isPublic?: boolean;
    user: any;
    createdAt: string;
    id: any;
  };
  padding?: boolean;
  className?: string;
  internal?: boolean;
  users: any;
  role?: any;
  userId?: any;
  userName?: any;
  updateMessage?: any;
  setShowDeleteConfirm?: any;
  showDeleteConfirm?: any;
  setMessageToDelete?: any;
  noEdits?: boolean;
  messagePopperRef?: any; 
}

export default function Message(props: MessageProps) {
  const {
    data,
    padding,
    className,
    internal,
    users,
    role,
    userId,
    userName,
    updateMessage,
    setShowDeleteConfirm,
    showDeleteConfirm,
    setMessageToDelete,
    messagePopperRef,
    ...others
  } = props;
  const classes = useStyles();
  const [message, setMessage] = useState<any>('');
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(false);

  const decryptMessage = async (message: any) => {
    if (!message) return;
    var a = await fetch(
      'https://mk04owoex8.execute-api.us-east-2.amazonaws.com/default/PrepddMsgSvc?key=' +
        message,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': '9GCHYOQqA23WhlQ2RWJz09rAsPaeXmlx7l3Zsoz8',
        },
      }
    );
    var b = await a.json();
    //Need to update the popper position on the last message
    //Without this, the popper will overflow
    if(messagePopperRef){
      messagePopperRef.current.update();
    }
    return b.message;
  };

  useEffect(() => {
    if (data.message) {
      decryptMessage(data.message)
        .then((b: any) => setMessage(b))
        .catch((e: any) => setMessage(''));
    }
  }, [data]);

  const showFunctions = () => {
    if (props.noEdits) { return; }
    if (!role && !userName && !data && !message) {
      return;
    }
    if (role === 1) {
      setShowEdit(true);
      setShowDelete(true);
    } else if (role == 2 || role == 3 || role == 4) {
      setShowDelete(true);
      if (userName == data.user.fullName) {
        setShowEdit(true);
      }
    } else if (role == 5 && userName == data.user.fullName) {
      setShowEdit(true);
      setShowDelete(true);
    }
  };

  const hideFunctions = () => {
    setShowEdit(false);
    setShowDelete(false);
  };

  const handleDeleteClick = () => {
    setMessageToDelete(data.id);
    setShowDeleteConfirm(!showDeleteConfirm);
  };

  return message ? (
    <div
      className={clsx(
        classes.root,
        !data.isPublic && classes.internal,
        padding && classes.mb12
      )}
      onMouseOver={showFunctions}
      onMouseLeave={hideFunctions}
    >
      <div className={classes.flex}>
        <UserImage
          user={data.user}
          className={classes.avatar}
          // style={{height: '25px', width: '25px'}}
        />
        <Typography variant="h6">
          {data.user && (data.user.fullName || data.user.email)}
        </Typography>
        <div className={classes.grow} />
        {/* {showEdit && ( */}
        <CreateIcon
          onClick={() => setShowInput(!showInput)}
          style={{
            height: '.85em',
            color: 'dimgray',
            cursor: 'pointer',
            visibility: showEdit ? 'visible' : 'hidden',
          }}
        />
        {/* )} */}
        {/* {showDelete && ( */}
        <Delete
          onClick={handleDeleteClick}
          style={{
            height: '.85em',
            color: 'dimgray',
            cursor: 'pointer',
            visibility: showDelete ? 'visible' : 'hidden',
          }}
        />
        {/* )} */}
        {!data.viewed && <div className={classes.viewed} />}
        <Typography variant="h6" className={classes.secondary}>
          {data.createdAt}
        </Typography>
      </div>
      <div className={classes.content}>
        {!showInput ? (
          <Markdown
            message={message}
            className={classes.messageMarkdown}
            internal={internal}
            users={users}
          />
        ) : (
          <MessageInputForm
            message={message}
            handleUpdate={updateMessage}
            showInput={showInput}
            messageId={data.id}
            setShowInput={setShowInput}
            id={data.id}
          />
        )}
      </div>
    </div>
  ) : null;
}

function MessageInputForm(props: any) {
  const {message, handleUpdate, showInput, setShowInput, messageId} = props;
  const classes = useStyles();
  const [newMessage, setNewMessage] = useState<any>(message);

  const handleChange = (event: any) => {
    setNewMessage(event.target.value);
  };

  const sendUpdate = (event: any) => {
    event.preventDefault();
    setShowInput(false);
    handleUpdate(event, newMessage, messageId);
  };

  return (
    <ClickAwayListener onClickAway={(event: any) => sendUpdate(event)}>
      <div>
        <form
          className={classes.form}
          onSubmit={(event: any) => sendUpdate(event)}
        >
          <TextareaAutosize
            className={clsx(classes.input, classes.h6)}
            id={messageId}
            autoFocus
            onChange={(event: any) => handleChange(event)}
            defaultValue={newMessage}
          />
        </form>
      </div>
    </ClickAwayListener>
  );
}
