import { createMutationHook, gql } from '../graphqlHelpers';

export const useArchiveTasks = createMutationHook(gql`
  mutation ArchiveTasks($taskIds: [ID!]!) {
    archiveTasks(taskIds: $taskIds) {
      success
      taskIds
    }
  }
`);
