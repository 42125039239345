import React, {useEffect, useState, useRef} from 'react';
import clsx from 'clsx';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import DefaultUserImage from '../../../../../../common/DefaultUserImage';
import idx from 'idx';
import {List, ListItem, ClickAwayListener} from '@material-ui/core';
import StatusLabel from '../../../../../../common/StatusLabel';
import * as cs from '../../../../../../../constants/theme';
import {Check} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      width: '95%',
      // top: '147px',
      bottom: 40,
      right: 0,
      left: 0,
      marginLeft: '10px',
      backgroundColor: '#FFFFFF',
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
      zIndex: 100000,
      maxHeight: 175,
      'overflow-y': 'scroll',
    },
    listItemContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    check: {
      color: 'blue',
      marginLeft: '10px',
    },
    hidden: {
      visibility: 'hidden',
    },
    userImage: {
      width: '18px',
      height: '18px',
      backgroundColor: '#2792A2',
      fontSize: '9px',
      marginRight: '6px',
    },
    itemStatus: {
      fontFamily: cs.FONT.family,
      color: cs.FONT.fontColor,
      fontSize: cs.FONT.size.xs,
    },
    listItem: {
      paddingTop: 3,
      paddingBottom: 3,
      '&:hover': {
        backgroundColor: 'white',
      },
      '&:selected': {
        backgroundColor: '#EBF2FF',
      },
      '&.MuiInput-selected': {
        backgroundColor: '#EBF2FF',
      },
      '&$selected, &$selected:hover': {
        backgroundColor: '#EBF2FF',
      },
    },
    itemSelected: {
      backgroundColor: '#EBF2FF',
      '&:selected': {
        backgroundColor: '#EBF2FF',
      },
    },
    teamImage: {
      width: '18px',
      height: '18px',
      backgroundColor: '#FFFFFF',
      border: '1px solid #2792A2',
      fontSize: '9px',
      marginRight: '6px',
      '& p': {
        color: '#2792A2',
      },
    },
  })
);

export const MessageTaggingBox = (props: any) => {
  const classes = useStyles();
  const {
    onClick,
    peopleList,
    setShowAddBox,
    selectedItem,
    setSelectedItem,
    listRef,
    selectedRef,
    listStyles = {}
  } = props;

  // const [pList, setPList] = useState<any[]>(peopleList);

  const handleClick = (event: any, person: any) => {
    setShowAddBox(false);
    onClick();
  };

  return (
    <ClickAwayListener onClickAway={() => setShowAddBox(false)}>
      <List style={{left: 0, paddingTop: 0, borderRadius: 3, ...listStyles}} className={classes.root} ref={listRef} data-cy="message-tagging-box">
        {peopleList &&
          peopleList.map((person: any, index: number) => (
            <UserLabel
              person={person}
              classes={classes}
              index={index}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              selectedRef={selectedRef}
              onClick={handleClick}
            />
          ))}
      </List>
    </ClickAwayListener>
  );
};

const UserLabel = (props: any) => {
  const {
    person,
    classes,
    index,
    selectedItem,
    setSelectedItem,
    selectedRef,
    onClick,
  } = props;

  return (
    <ListItem
      onClick={event => onClick(event, person)}
      key={person.name}
      selected={index === selectedItem}
      onMouseEnter={() => setSelectedItem(index)}
      className={classes.listItem}
      classes={{root: classes.listItem, selected: classes.itemSelected}}
      style={{backgroundColor: index === selectedItem ? '#EBF2FF' : '#FFFFFF'}}
      ref={index === selectedItem ? selectedRef : null}
    >
      {person.profileUrl ? (
        <img
          src={person.profileUrl as string}
          width="18"
          height="18"
          style={{marginRight: '6px'}}
          alt={person.displayName || person.name || person.email}
        />
      ) : (
        <DefaultUserImage
          key={index}
          userName={person.displayName || person.name || person.email}
          className={classes.userImage}
          style={{}}
        />
      )}
      <div className={classes.listItemContainer}>
        <StatusLabel
          currentStatus={person.displayName || person.name || person.email}
        />
        <div className={classes.itemStatus}>{person.taskRelationship}</div>
      </div>
    </ListItem>
  );
};
