import { useState, useEffect } from 'react';
import { useDispatchContext } from '../store';
import { useUpdateUserFilters } from '../graphql/mutations/UpdateUserFilters';

export const ALL_STATUSES = [
	'Unstarted',
  'Started',
	'Prepared',
	'Delivered',
  'Completed',
	'Rejected',
  'NotApplicable'
];

export const DEFAULT_PRIORITIES: string[] = [
  'high',
  'medium',
  'low'
];

export enum TaskViewTabs {
  All = 'All',
  Tasks = 'Tasks',
  Reconciliations = 'Reconciliations',
  Analysis = 'Analysis',
  Calendar = 'Calendar'
}

// The presence or absence of ?listId in the url determines whether or not to 
export function handleExternalNavigation(location: any) {
  var url, filters: any;
  const urlParams = new URLSearchParams(location.search);
  const listId = urlParams.get('listId');
  const pane = urlParams.get('pane');
  const companyId = urlParams.get('companyId');

  if ( location!.href!.includes('/app/lists') ) {
    url = '/app/lists';
    filters = {
      listPageSelectedLists: [listId],
      listPagePane: Number(pane),
      companyId: companyId
    };
  } else if ( location!.href!.includes('/app/tasks')) {
    //THIS IS WHERE THE NAVIGATION FILTERS GET SET
    //NEED THIS FOR NEW TASKS PAGE
    url = '/app/tasks';
    const taskId = urlParams.get('taskId');
    filters = {
      taskPageLists: [listId],
      taskPageSelectedTasks: [taskId],
      taskPagePane: Number(pane),
      taskPagePriority: DEFAULT_PRIORITIES,
      taskPageStatus: ALL_STATUSES,
      companyId: companyId
    };
  } else if(location!.href!.includes('/app/newtasks')) {
    url = '/app/newtasks';
    //UPDATE THESE FILTERS FOR THE NEW TASK PAGE
    const taskId = urlParams.get('taskId');
    filters = {
      taskPageLists: [listId],
      customTaskPageSelectedTaskIds: [taskId],
      companyId: companyId
    };
  }

  return [ url, filters ];
}

export function useNavigateToProjectPage() {
  const [list, setList] = useState<any>({listId: null, pane: 0}); 
  const dispatch = useDispatchContext();
  const [updateUserFilter, ] = useUpdateUserFilters({
    listPageSelectedLists: [list.listId],
    listPagePane: list.pane,
  });
  
  useEffect(() => {
    if (list.listId) updateUserFilter();
  }, [list])

  return function(a: any) {
    if (!a) return;
    setList(a)
    dispatch({
      type: 'SET_USER_FILTERS',
      filters: {
        listPageSelectedLists: [a.listId],
        listPagePane: a.pane,
      }
    });
  }
}

export function useNavigateToTask() {
  const [task, setTask] = useState<any>(null);
  const dispatch = useDispatchContext();
  const [updateUserFilter, ] = useUpdateUserFilters(task)
  
  useEffect(() => {
    if (task) updateUserFilter();
  }, [task])

  return function(a: any) {
    if (!a) return;
    setTask(a)
    dispatch({
      type: 'SET_USER_FILTERS',
      filters: {
        taskPageSelectedTasks: [a.taskId],
        taskPageLists: [a.listId],
        taskPagePane: a.pane,
        taskPageStatus: ALL_STATUSES,
        taskPagePriority: DEFAULT_PRIORITIES
      }
    });
  }
}
