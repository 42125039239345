import { createMutationHook, gql } from '../graphqlHelpers';

export const useUpdateTask = createMutationHook(gql`
  mutation UpdateTask(
    $id: ID!
    $name: String
    $description: String
    $priority: String
    $status: String
    $dueDate: ISO8601DateTime
    $startDate: ISO8601DateTime
    $section: String
    $relativeDueDate: Int
    $relativeStartDate: Int
    $isAutomation: Boolean
    $scheduleRepeatType: String
  ) {
    updateTask(
      id: $id
      name: $name
      description: $description
      priority: $priority
      status: $status
      dueDate: $dueDate
      startDate: $startDate
      section: $section
      relativeDueDate: $relativeDueDate
      relativeStartDate: $relativeStartDate
      isAutomation: $isAutomation
      scheduleRepeatType: $scheduleRepeatType
    ) {
      success
      task {
      id
      currency
      primaryLabel
      primarySourceType
      primaryValue
      primaryTotal
      isReconciled
      reconcilingItemTotal
      reconDifference
      isBlocked
      dependencies {
        id
        relatedId
        relatedName
        relatedDueDate
        taskId
        taskName
        taskDueDate
        dueDays
        dueHours
        hasStartDate
        status
        isDependent
        relatedListNumber
        taskListNumber
      }
      schedule {
        id
        repeatType
        repeatNumber
        repeatInterval
        calendarType
        ends
        endsOn
        endsAfter
        selectedWeekday
        selectedMonthday
        selectedMonth
        selectedYearday
        selectedSecond
        nextRunningDate
      }
      primarySource {
        id
        fileVersion {
          id
          fileName
          fileLocation
          taskId
          alterable
        }
        value
      }
      secondaryLabel
      secondarySourceType
      secondaryValue
      secondaryTotal
      secondarySource {
        id
        fileVersion {
          taskId
          id
          fileName
          fileLocation
          alterable
        }
        value
      }
      primaryFileReconSources {
        id
        sourceType
        fileVersion {
          id
          fileName
          fileLocation
          alterable
        }
        name
        isCurrent
        description
        prettyDate
        value
        associatedTaskIds
      }
      primaryFileReconSourcesTotal
      secondaryFileReconSources {
        id
        sourceType
        fileVersion {
          id
          fileName
          fileLocation
          alterable
        }
        name
        isCurrent
        description
        prettyDate
        value
        associatedTaskIds
      }
      secondaryFileReconSourcesTotal
      relativeDueDate
      relativeStartDate
      modifiedAtText
      modifiedAtTime
      name
      description
      section {
          id
          name
       }
      companyPosition
      priority
      status
      hasFiles
      hasMessages
			hasUnreadMessages
			hasUnseenFiles
      startDate
      dueDate
      updatedAt
      reconciliations
			listNumber
			currentUserIsReviewer
      responderDelivered
			list {
				id
        reconciliations
				rank
        currentUserIsOwner
        name
        deliverFileOnUpload
        schedule {
          id
          repeatType
          calendarType
          repeatInterval
        }
        requesterCompany {
          id
          name
        }
        responderCompany {
          id
          name
        }
			}
			files {
				id
				fileName
				fileLocation
				viewed
        alterable
				updatedAt
				taskId
				createdAt
				isCheckedOut
				checkedOutBy{
          fullName
        }
        checkedOutAt
        companyId
			}
      userOwners {
        id
        email
        fullName
        displayName
        profileUrl
      }
			messages {
        id
				isPublic
				message
				createdAt
        task {
          id
        }
				company {
					id
				}
				viewed
				user {
          id
					fullName
					email
          profileUrl
				}
        resolveDate
        resolveUser {
          fullName
          profileUrl
        }
        resolveStatusOption {
          id
          description
          resolveObject {
            id
            description
          }
        }
        resolveStatusDescription
        isEdited
			}
      teamOwners {
        id
        name
      }
      firstUserReviewers {
        id
        email
        fullName
        displayName
        profileUrl
      }
      firstTeamReviewers {
        id
        name
      }
      secondUserReviewers {
        id
        email
        fullName
        displayName
        profileUrl
      }
      secondTeamReviewers {
        id
        name
      }
      notifications {
        id
        name
        notifiable {
          ...on Task {
            listId
          }
        }
        file {
          id
          fileLocation
          fileName
          alterable
        }
        notifiableId
				actionType
        notifiableType
        pane
        readAt
        updatedAt
        url
        description
        createdAt
        isAutomation
        isAutomation
        isEdited
        isDeleted
        user {
          id
          fullName
          profileUrl
        }
        company {
          id
          name
        }
        messageParent
      }
      tags { 
        id 
        tag
      }
      classifications{
          id
          name
        }
      }
    }
  }
`);
