import CompanyForm from '../../modules/list/shared/CompanyForm';
import { gql} from '../graphqlHelpers';
export const createVolatileList = gql`
mutation CreateVolatileList( $listId: ID!, $workspaceId: ID){
  createVolatileList( listId: $listId, workspaceId: $workspaceId ) {
        id,
        labelName,
        name,
        isVolatile,
        tasks {
          id
          name
          section {
            id
            name
          }
          description
          priority
          status
          tags {
            id
            tag
          }
          classifications{
            id
            name
          }
          isVolatile
        }
        errors {
          message,
          path
        }
      }
  }
`;