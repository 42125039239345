import React, {useState, useEffect} from 'react';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';
import UpdateIcon from '@material-ui/icons/Create';
import {Tooltip} from '@material-ui/core';
import clsx from 'clsx';

import * as cs from '../../constants/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '24px',
      height: 'fit-content',
    },
    title: {
      width: '100%',
      height: 'fit-content',
      borderBottom: '1px solid #D8D8D8',
      wordBreak: 'break-all',
    },
    input: {
      width: '100%',
      color: 'dimgrey',
      lineHeight: '24px',
      wordBreak: 'break-word',
      letterSpacing: '0em',
      fontFamily: cs.FONT.family,
      fontSize: cs.FONT.size.sm,
      paddingBottom: '5px',
      paddingTop: '2px',
      fontWeight: 500,
      outline: 'none',
      border: 'none',
      display: 'inline-block',
      whiteSpace: 'pre-wrap',
    },
    nonEditable: {
      display: 'flex',
      height: 'fit-content',
      alignItems: 'center',
    },
    nonEditableIcon: {
      fontSize: '15px',
      marginLeft: '12px',
      color: '#3A84FF',
      marginBottom: '-3px',
    },
    secondary: {
      color: '#606060',
      marginTop: '24px',
      marginBottom: '12px',
    },
    hidingIcon: {
      display: 'none',
    },
    h2: {
      fontSize: cs.FONT.size.lg,
      fontWeight: cs.FONT.weight.bold,
      lineHeight: '32px',
      borderBottom: 'none',
    },
    h6: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '21px',
      borderBottom: 'none',
      color: '#606060',
      marginTop: 0,
    },
    tooltip: {
      maxWidth: '500px',
      color: '#FFFFFF',
      fontFamily: cs.FONT.family,
      fontSize: cs.FONT.size.xs,
      fontWeight: cs.FONT.weight.regular,
    },
    ellipsisText: {
      textOverflow: 'ellipsis',
    },
    inputPointer: {
      cursor: 'text',
    },
  })
);

interface EditableInputFormProps {
  label?: string;
  value: string;
  onChange?: (newValue: string) => void;
  onUpdate?: () => void;
  onBlur?: () => void;
  onKeyDown?: (e: any) => void;
  size?: string;
  readOnly?: any;
  noMarg?: any;
  onlyEnter?: any;
  textArea?: boolean;
  fullWidth?: boolean;
  elementId?: string;
  fontWeight?: number;
  dataCy?: string;
}

export default function EditableInputForm(props: EditableInputFormProps) {
  const {
    readOnly,
    label,
    value,
    onChange,
    onUpdate,
    size,
    onBlur,
    onKeyDown,
    elementId,
    fontWeight,
    dataCy,
  } = props;
  const classes = useStyles();
  const [editable, setEditable] = useState<boolean>(false);
  const [hovered, setHovered] = useState<boolean>(false);
  const Id = elementId || 'text-area-input';

  /*
  useEffect(() => {
    if (!editable) return;

    let editableObj = document.getElementById('editor');

    if (editableObj) {
      editableObj.addEventListener(
        'blur',
        event => {
          if (
            onChange &&
            event.target &&
            (event.target as HTMLDivElement).innerText
          )
            onChange((event.target as HTMLDivElement).innerText);
        },
        false
      );

      editableObj.addEventListener('keyup', event => {
        if (event.keyCode === 13) {
          if (
            onChange &&
            event.target &&
            (event.target as HTMLDivElement).innerText
          )
            onChange((event.target as HTMLDivElement).innerText);

          setEditable(false);
        }
      });
    }

    return () => {
      if (editableObj) {
        editableObj.removeEventListener('input', event => {}, false);

        editableObj.removeEventListener('keyup', event => {});
      }
    };
  }, [editable]);
*/

  const handleChange = (e: any) => {
    onChange && onChange(e.target.value);
  };

  const handleClickAway = () => {
    if (onUpdate && !props.onlyEnter) onUpdate();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (onUpdate) onUpdate();
  };

  const determineWidth = () => {
    if (props.textArea) {
      return '88%';
    }
    if (props.fullWidth) {
      return '100%';
    } else {
      return '50%';
    }
  };

  const truncate = (input: string) =>
    input.length > 20 ? `${input.substring(0, 20)}...` : input;
  return (
    <div
      className={classes.root}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        marginTop: props.noMarg ? '-6px' : '24px',
        width: props.fullWidth ? '95%' : 'inital',
      }}
    >
      {label && (
        <Typography className={classes.secondary} variant="h6">
          {label}
        </Typography>
      )}
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <form onSubmit={handleSubmit} style={{width: determineWidth()}}>
            {props.textArea ? (
              <div
                data-cy={props.dataCy}
                style={{fontWeight: fontWeight ? fontWeight : 500}}
                className={clsx(
                  classes.inputPointer,
                  classes.input,
                  size ? (size === 'h2' ? classes.h2 : classes.h6) : null
                )}
                contentEditable={!readOnly}
                id={Id}
                onBlur={() => {
                  if (readOnly) return;
                  var ok = document.getElementById(Id);
                  if (ok && ok.innerText) {
                    if (onChange) onChange(ok.innerText);
                    if (onUpdate) setTimeout(() => onUpdate(), 400);
                  }
                }}
                onKeyDown={onKeyDown}
                suppressContentEditableWarning={!readOnly}
              >
                {value}
              </div>
            ) : (
              <Tooltip title={value || ''}>
                <input
                  className={clsx(
                    classes.input,
                    size ? (size === 'h2' ? classes.h2 : classes.h6) : null,
                    classes.ellipsisText
                  )}
                  id={Id}
                  onBlur={onBlur}
                  // contentEditable={true}
                  // suppressContentEditableWarning={true}
                  onChange={handleChange}
                  onKeyDown={onKeyDown}
                  readOnly={readOnly}
                  type="text"
                  value={value}
                />
              </Tooltip>
            )}
          </form>
          {/* {hovered && !readOnly && <UpdateIcon className={classes.nonEditableIcon} />} */}
        </div>
      </ClickAwayListener>
    </div>
  );
}
