import { gql } from '../graphqlHelpers';
import { useQuery } from '@apollo/client';

const query = gql`
  query ReconSummary($listId: ID!) {
    list(id: $listId) {
      id
      name
      companyPosition
      tasks {
        id
        name
        currency
        list {
          # for navigation -- easier to access here
          id
        }
        section {
          id
          name
        }
        dueDate
        primaryValue
        secondaryValue
        responderDelivered
        reconciliations
        userOwners {
          id
          fullName
          email
          profileUrl
        }
        primaryLabel
        primarySourceType
        primarySource {
          id
          fileVersion {
            id
            fileName
            fileLocation
            taskId
          }
        }
        secondaryLabel
        secondarySourceType
        secondarySource {
          id
          fileVersion {
            taskId
            id
            fileName
            fileLocation
          }
        }
        isReconciled
        tags { 
          id
          tag
        }
        classifications{
          id
          name
        }
        primaryFileReconSources {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        primaryFileReconSourcesTotal
        secondaryFileReconSources {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        secondaryFileReconSourcesTotal
        primaryTotal
        secondaryTotal
        reconcilingItemTotal
        reconDifference
      }
    }
  }
`

export const useReconSummary = (variables: any) =>
  useQuery(query, { variables })
