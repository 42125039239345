import React, {useEffect} from 'react';
import clsx from 'clsx';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import {Tabs, Tab} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

const tabHeight = 42;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid #D8D8D8',
      paddingLeft: '16px',
      minHeight: tabHeight,
      height: tabHeight,
    },
    tab: {
      minHeight: tabHeight,
      height: tabHeight,
      paddingBottom: 5,
    }
  })
);

const CheckedOutBadge = withStyles({
  badge: {
    top: '90%',
    right: '50%',
    minWidth: 6,
    height: 6,
    backgroundColor: '#e6d222',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#FFFFFF',
    letterSpacing: '0',
    textAlign: 'center',
  },
})(Badge);

const StyledBadge = withStyles({
  badge: {
    top: '12%',
    right: 3,
    minWidth: 6,
    height: 6,
    backgroundColor: '#6EB81D',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '11px',
    color: '#FFFFFF',
    letterSpacing: '0',
    textAlign: 'center',
  },
})(Badge);

interface PanelProps {
  children: React.ReactNode;
  title?: string;
  labels: any[];
  paneIndex?: number;
  padding?: boolean;
  setPanelIndex?: any;
  task?: any;
  badges?: number[];
  passedClasses?: string;
}

interface NewPaneType {
  hidden: boolean;
  value: number;
  index: number;
  children?: React.ReactNode;
}

export default function Panel(props: PanelProps) {
  const {
    children,
    title,
    labels,
    paneIndex,
    padding,
    setPanelIndex,
    task,
    ...others
  } = props;
  const [showCheckOutBadge, setShowCheckOutBadge] = React.useState<boolean>(
    false
  );
  const classes = useStyles();
  const [index, setIndex] = React.useState<number>(0);

  useEffect(() => {
    if (paneIndex === 0 || paneIndex) {
      setIndex(paneIndex);
      setPanelIndex(paneIndex);
    }
  }, [paneIndex]);

  const handleChange = (event: unknown, newIndex: number) => {
    setIndex(newIndex);
    if (setPanelIndex) {
      setPanelIndex(newIndex);
    }
  };

  const isHidden = (id: number) => index !== id;

  const newPanes = React.Children.map(children, (child: any, id: number) =>
    React.cloneElement<NewPaneType>(child, {
      hidden: isHidden(id),
      value: index,
      index: id,
    })
  );

  return (
    <div style={{flexGrow: 1, height: '100%'}} className={Boolean(props.passedClasses) ? props.passedClasses : undefined}>
      <Tabs
        classes={{root: classes.root}}
        value={index}
        aria-label={title ? title : ''}
        onChange={handleChange}
        {...others}
      >
        {labels.map((label: any, id: number) => (
          <Tab
            key={`pane-${id}`}
            classes={{root: classes.tab}}
            data-cy={'panel-tab-' + label.label}
            onClick={label.onClick}
            label={
              <CheckedOutBadge
                variant={
                  showCheckOutBadge == true && id == 1 ? 'dot' : undefined
                }
              >
                <StyledBadge
                  variant={
                    props.badges && props.badges.includes(id)
                      ? 'dot'
                      : undefined
                  }
                >
                  {label.label}
                </StyledBadge>
              </CheckedOutBadge>
            }
          />
        ))}
      </Tabs>
      {newPanes}
    </div>
  );
}
