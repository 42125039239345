import React, { useState, useEffect, SyntheticEvent } from 'react';
import idx from 'idx';
import Snackbar from '@material-ui/core/Snackbar';
import { useIdleTimer } from 'react-idle-timer';

import Router from '../../modules/route';
import LoadingFallback from '../../modules/common/LoadingFallback';
import FlashMessage from '../../modules/common/FlashMessage';
import AuthTimeoutDialog from './components/AuthTimeoutDialog';

import { useCurrentUser } from '../../graphql/queries/CurrentUser';
import { useLazyMessageLabels } from '../../graphql/queries/MessageLabels';
import { useLazyListLabels } from '../../graphql/queries/ListLabels';
import { useLazyResolveObjects } from '../../graphql/queries/ResolveObjects';
import { useLazyTaskPageUserLists } from '../../graphql/queries/TaskPageUserLists';
import { useDispatchContext, useStateContext } from '../../store';
import { useSignOutUser } from '../../graphql/mutations/SignOutUser';
import { useUpdateUserFilters } from '../../graphql/mutations/UpdateUserFilters';

import { handleExternalNavigation } from '../../helpers/navigation';
import { isAdmin } from '../../helpers/roleHelpers';
import { shareWorkspaceViaUrl } from '../../graphql/mutations/shareWorkspaceViaUrl';
import { useMutation } from '@apollo/client';
import { keepAliveSession } from '../../helpers/api';
import { AUTH_ROUTES, AUTH_TIMEOUT, AUTH_TIMEOUT_ALERT_DURATION } from '../../modules/common/Constants';
import { useLazyTimePeriods } from '../../graphql/queries/TimePeriods';

export default function App() {
  const dispatch = useDispatchContext()
  const state = useStateContext();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const [currentCompanyId, setCurrentCompanyId] = useState<any>(null);

  const { data, loading, error } = useCurrentUser({});
  const [signOutUser, signOutResponse] = useSignOutUser({});
  const [filters, setFilters] = useState<any>(null); // the type should become 'filters'
  const [updateFilters,] = useUpdateUserFilters(filters);
  const [useShareWorkspaceViaUrl, shareWorkspaceViaUrlData] = useMutation(shareWorkspaceViaUrl);

  const [getUserLists, {
    loading: listsLoading,
    data: listsData,
    called: listsCalled
  }] = useLazyTaskPageUserLists();

  const [getMessageLabels, {
    loading: messageLabelTypesLoading,
    data: messageLabelTypesData,
    called: messageLabelTypesCalled
  }] = useLazyMessageLabels({
    companyId: currentCompanyId
  });

  const [getListLabels] = useLazyListLabels({
    companyId: currentCompanyId
  });

  const [getResolvedObjects, {
    loading: resolveObjectsLoading,
    data: resolveObjectsData,
    called: resolveObjectsCalled
  }] = useLazyResolveObjects();

  const [getTimePeriods, {
  }] = useLazyTimePeriods();
  
  useEffect(() => {
    if (loading) return;

    const currentUser = idx(data, data => data.currentUser.user);
    setLoaded(true);
    if (!currentUser) {
      if (location!.href!.includes('workspaceId=') &&
        location!.href!.includes('companyId=')
      ) {
        const workspaceUrlParams = new URLSearchParams(location.search);
        const urlWorkspaceId = workspaceUrlParams.get('workspaceId');
        const urlCompanyId = workspaceUrlParams.get('companyId');
        let base_url = window.location.origin
        window.location.href = base_url + '/signup/' + urlCompanyId + '/' + urlWorkspaceId
      }
      return
    };
    if (location!.href!.includes('?listId=')) {
      const [url, filters] = handleExternalNavigation(location);
      setFilters(filters);
      window.location.href = url;
    }
    if (currentUser && location!.href!.includes('?workspaceId=')) {
      const urlParams = new URLSearchParams(location.search);
      const workspaceId = urlParams.get('workspaceId');
      useShareWorkspaceViaUrl({
        variables: {
          accessUrl: workspaceId
        },
      }).then((response: any) => {
        if (response.data &&
          response.data.shareWorkspaceViaUrl &&
          response.data.shareWorkspaceViaUrl.success) {
          const currentWorkspaceId = response.data.shareWorkspaceViaUrl.currentWorkspaceId;
          // dispatch({
          //   type: 'SET_CURRENT_WORKSPACE_ID',
          //   workspaceId: currentWorkspaceId
          // });
        }
      });
    }

    dispatch({
      type: 'SET_CURRENT_USER',
      user: {
        ...currentUser,
        isAdmin: isAdmin(currentUser.companyRole)
      }
    });

    if (currentUser.lastViewedCompanyId) {
      dispatch({
        type: 'SET_SELECTED_COMPANY',
        companyId: currentUser.lastViewedCompanyId,
      });
      setCurrentCompanyId(currentUser.lastViewedCompanyId)
    } else if (currentUser.ownedCompanies && currentUser.ownedCompanies.length) {
      dispatch({
        type: 'SET_SELECTED_COMPANY',
        companyId: currentUser.ownedCompanies[0].id,
      });
      setCurrentCompanyId(currentUser.ownedCompanies[0].id)
    } else if (currentUser.companies) {
      dispatch({
        type: 'SET_SELECTED_COMPANY',
        companyId: currentUser.companies[0].id,
      });
      setCurrentCompanyId(currentUser.companies[0].id)
    }

    if (!listsCalled) {
      getUserLists().then((response: any) => {
        const userLists = response?.data?.userLists?.lists || [];
        dispatch({
          type: 'SET_USER_LISTS',
          userLists: userLists
        })
      });
    }

    getResolvedObjects().then((response: any) => {
      const resolveObjects = response?.data?.resolveObjects;
      if (resolveObjects) {
        dispatch({
          type: 'SET_RESOLVE_OBJECTS',
          resolveObjects: resolveObjects
        });
      }
    });

    getTimePeriods().then((response: any) => {
      const timePeriods = response?.data?.timePeriods;
      if (timePeriods) {
        dispatch({
          type: 'SET_TIME_PERIODS',
          timePeriods: timePeriods
        });
      }
    });
  }, [loading]);

  useEffect(() => {
    if (currentCompanyId) {
      getMessageLabels().then((response: any) => {
        const messageLabelTypes = response?.data?.messageLabels;
        if (messageLabelTypes) {
          dispatch({
            type: 'SET_MESSAGE_LABEL_TYPE',
            messageLabelTypes: messageLabelTypes
          });
        }
      });
      getListLabels().then((response: any) => {
        const listLabelTypes = response?.data?.listLabels;
        if (listLabelTypes) {
          dispatch({
            type: 'SET_LIST_LABEL_TYPE',
            listLabelTypes: listLabelTypes
          });
        }
      });
    }
  }, [currentCompanyId]);

  useEffect(() => {
    if (state.notification.message) {
      setNotificationOpen(true);
    }
  }, [state.notification]);

  // check if the current route is protected
  // We should not display the dialog if the user is on a protected route
  const isProtectedRoute = () => {
    return !AUTH_ROUTES.some(prefix => location.pathname.startsWith(prefix));
  }

  const handleCloseNotification = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotificationOpen(false);
  };

  // Handle on action when the user is active (currently using the app)
  const handleOnAction = async  (e: any) => {
    if (!dialogOpen && isProtectedRoute()) {
      await keepUserSessionAlive();
    }
  }
  const handleActive = () => {};

  const handleWarningIdle = (e: any) => {
    if (isProtectedRoute()) {
      setDialogOpen(true);
      const ok = setTimeout(() => { signOutUser(); }, AUTH_TIMEOUT_ALERT_DURATION);
      setTimeoutId(ok);
    }
  };

  const { reset } = useIdleTimer({
    timeout: AUTH_TIMEOUT - AUTH_TIMEOUT_ALERT_DURATION,
    onIdle: handleWarningIdle,
    onActive: handleActive,
    onAction: handleOnAction,
    debounce: 2 * 60 * 1000 // 2 minutes before calling onAction
  });

  // Handle the user click on Reauthenticate button on alert dialog
  const handleAuth = async () => {
    clearTimeout(timeoutId);
    setDialogOpen(false);
    await keepUserSessionAlive();
  }

  // Make request to api to keep the user session alive when the session is active
  const keepUserSessionAlive = async () => {
    const result = await keepAliveSession();
    if (result) {
      // When the session exist, reset the idle timer
      reset();
    } else {
      // When the session does not exist, signout the user (redirect to signin page)
      signOutUser();
    }
  }

  useEffect(() => {
    if (!filters) { return; }
    updateFilters();
    setFilters(null);
  }, [filters])
  useEffect(() => {
    if (signOutResponse.called == true && signOutResponse.loading == false) {
      window.location.href = '/signin?mta=1';
    }
  }, [signOutResponse.called, signOutResponse.loading, signOutResponse.data])

  return !loaded ? (
    <LoadingFallback />
  ) : (
    <div>
      <AuthTimeoutDialog timeout={AUTH_TIMEOUT_ALERT_DURATION} onAuth={handleAuth} open={dialogOpen} setOpen={setDialogOpen} />
      {state.notification.message && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={notificationOpen}
          autoHideDuration={state.notification.time || 2000}
          onClose={handleCloseNotification}
        >
          <FlashMessage
            variant={state.notification.variant}
            message={state.notification.message}
            onClose={handleCloseNotification}
          />
        </Snackbar>
      )}
      <Router />
    </div>
  );
}
