import React, { useState, useEffect }  from 'react';
import idx from 'idx';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  IconButton
} from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';

import { WeekDays } from '../../../../common/WeekDays';
import { MonthDays } from '../../../../common/MonthDays';
import { DatePicker } from '../../../../common/DatePicker';
import { CALENDAR_VIEW_DAYS, ALL_CALENDAR_VIEW_DAYS, days } from '../../../../../constants/days';

import { useGetRelativeDueDate } from '../../../../../graphql/queries/GetRelativeDueDate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      /* setting a static width, avoid growing/shrinking as content changes */
      minHeight: 36,
      width: 325
    },
    radioButtons: {
      padding: 6, // less padding that normal radios
      marginLeft: 3
    },
    icon: {
      padding: 0,
      width: 20,
      height: 20
    },
    iconButton: {
      padding: 0,
    },
    centerBox: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 12
    },
    dood: {
      width: 184,
      marginTop: 8,
      marginBottom: 8,
      backgroundColor: '#CACACA',
      color: '#0000001F',
      '& input': {
        paddingLeft: 29
      },
      '& .MuiButtonBase-root': {
        marginRight: 20
      }
    }
  })
);

interface DueDateSelectProps {
  task: any; //should be task type
  onAccept: any;
  onClose: any;
}

function DueDateSelect(props: any) {
  const classes = useStyles();

  const relativeDueDate = idx(props.task, t => t.relativeDueDate);
  const [relativeDateType, setRelativeDateType] = useState<string>(
    Number(relativeDueDate) < 0
      ? 'negative' 
      : 'positive'
  );
  const [dueDate, setDueDate] = useState<any>(idx(props.task, t => t.dueDate));
  const [selected, setSelected ] = useState<any>(relativeDueDate);
  const [relativeDate, setRelativeDate] = useState<any>(undefined);
  const [selectedScheduleStep, setSelectedScheduleStep ] = useState(4);
  const getRelativeDueDate = useGetRelativeDueDate({
    taskId: props.task.id,
    relativeDateNumber: Number(selected)
  });
  /* schedule.repeatType determines which selector box type to be rendered */
  const boxType = idx( props.task, t => t.list.schedule.repeatType );
  const annualCustom = idx(props.task, t => 
    t.list.schedule.repeatType == 6 && t.list.schedule.repeatInterval == 4
  )
  const calendarMonth = idx(props.task, t => t.list.schedule.repeatType == 6 && t.list.schedule.repeatInterval == 3 && t.list.schedule.calendarType == 2);
  const customBusinessMonth = idx(props.task, t => t.list.schedule.repeatType == 6 && t.list.schedule.repeatInterval == 3 && t.list.schedule.calendarType == 1);

  useEffect(() => {
    if (!getRelativeDueDate.data || !selected) { return; }
    setRelativeDate((getRelativeDueDate as any).data.getRelativeDueDate);
  }, [getRelativeDueDate.called, getRelativeDueDate.loading, getRelativeDueDate.data]);

  /* dynamically select the wording for the DialogTitle */
  const REPEAT_TYPE_DESCRIPTIONS = [
    null,
    null,
    'Weekly',
    'Monthly',
    'Quarterly',
    'Annual'
  ];
  const repeatTypeDescription = REPEAT_TYPE_DESCRIPTIONS[boxType as number];

  /* basically, a map of the different selector box types to the repeatType
     ids that a task has.*/
  function renderBox() {
    const calendarDays = idx(props.task, t => t.list.schedule.calendarType == 2);
    let repeatInterval = idx(props.task, t => t.list.schedule.repeatType);
    if(repeatInterval === '6'){
      repeatInterval = idx(props.task, t => t.list.schedule.repeatInterval);
      if ( repeatInterval == 4 ) {repeatInterval = 5}
    }
    const type2 = repeatInterval ? String(repeatInterval) : boxType;
    const calendarType: string = calendarDays ? 'calendar' : 'business';
    
    switch (type2) {
      case '2': return(
        <>
          <div className={classes.centerBox} style={{marginTop: 0, marginBottom: 18}}>
          <WeekDays
            disableWeekends={!(Boolean(calendarDays))}
            selected={selected}
            onChange={(e: any) => setSelected(e)}
          />
          </div>
          <Typography>Each { days[selected as number] || '__________'  } </Typography>
        </>
      );
      case '3': {
        const dueDatePrompt = selected < 0
          ? `Your task will repeat ${Math.abs(Number(selected))} ${calendarDays ? 'calendar' : 'business'} days before the project's schedule start date`
          : `Your task will repeat on the ${renderNth()} ${calendarDays ? 'calendar' : 'business'} day of the project`
          
        return(
          <>
            <div className={classes.centerBox} style={{marginTop: 0, marginBottom: 18}}>
            <MonthDays
              days={ALL_CALENDAR_VIEW_DAYS[calendarType][selectedScheduleStep]}
              selected={selected}
              onChange={(e: any) => setSelected(e)}
            />
            </div>
            <Typography>
                {dueDatePrompt}
            </Typography>
          </>
        )
      };
      case '4': return(
        <>
          <div className={classes.centerBox} style={{marginTop: 0, marginBottom: 18}}>
          <MonthDays
            days={ALL_CALENDAR_VIEW_DAYS[calendarType][selectedScheduleStep]}
            selected={selected}
            onChange={(e: any) => setSelected(e)}
          />
          </div>
          <Typography>
              {`${renderNth()} ${calendarDays ? 'calendar' : 'business'} day of every quarter`}
          </Typography>
        </>
      );
      case '5': return(
        <>
          <div className={classes.centerBox} style={{marginTop: 0, marginBottom: 18}}>
          <MonthDays
            days={ALL_CALENDAR_VIEW_DAYS[calendarType][selectedScheduleStep]}
            selected={selected}
            onChange={(e: any) => setSelected(e)}
          />
          </div>
          <Typography>
            {`${renderNth()} ${calendarDays ? 'calendar' : 'business'} day of every year`}
          </Typography>
        </>
      )
      default: return null;
    }
  }

  /* uses selected state variable to set the nth string in description */
  const renderNth = (): string => {
    switch (selected) {
      case null: {
        return '____ ';
      }
      case '1': return '1st ';
      case '2': return '2nd ';
      case '3': return '3rd ';
      default: return selected + "th ";
    }
  };

  const disabledNav = (arrowType: string) => {
    if(arrowType === 'prev') {
      return selectedScheduleStep === 0 ? true : false;
    } else if(arrowType === 'next') {
      const calendarDays = idx(props.task, t => t.list.schedule.calendarType == 2);      
      const calendarType: string = calendarDays ? 'calendar' : 'business';
      return ((selectedScheduleStep === (ALL_CALENDAR_VIEW_DAYS[calendarType].length - 1)) ? true : false);
    }
  }

  const handleAnnualChange = (e: any) => {
    setDueDate(e);
    setRelativeDate(e);
  }

  function handleRelativeDateTypeChange(e: any) {
    setRelativeDateType(e.target.value);
  }

  const showRelativeDateSelector = 
    (boxType == '3' || boxType == '4' || boxType == '5' || customBusinessMonth || calendarMonth || annualCustom);

  return(
  <Dialog open={true}>
    <DialogTitle disableTypography style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', padding: '18px 24px'}}>
      <Typography variant='h4'>
        Repeating {repeatTypeDescription} Due Date
      </Typography>
      { showRelativeDateSelector && 
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
          <IconButton onClick={() => setSelectedScheduleStep(selectedScheduleStep-1)} 
            disabled={disabledNav('prev')} className={classes.iconButton}>
            <ArrowLeft className={classes.icon} />
          </IconButton>
          <IconButton onClick={() => setSelectedScheduleStep(selectedScheduleStep+1)}
            disabled={disabledNav('next')} className={classes.iconButton}>
            <ArrowRight className={classes.icon} />
          </IconButton>
        </div>
      }
    </DialogTitle>
    <DialogContent>
      <div className={classes.root} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'}}>
      {/* { showRelativeDateSelector && 
        <FormControl component="fieldset">
          <FormLabel component="legend">Relative To:</FormLabel>
          <RadioGroup 
            value={relativeDateType}
            onChange={handleRelativeDateTypeChange}
          >
            <FormControlLabel value="positive" control={<Radio className={classes.radioButtons} disableRipple color={'primary'} />} label="Month Start" />
            <FormControlLabel value="negative" control={<Radio className={classes.radioButtons} disableRipple color={'primary'} />} label="Month End" />
          </RadioGroup>
        </FormControl>
      } */}
          { renderBox() }

        <div className={classes.centerBox}>
        {/* { (boxType === '5' || annualCustom )
          ? <DatePicker onChange={handleAnnualChange} format={'MM/dd'} value={dueDate}/> */}
          <DatePicker classes={classes.dood} disabled={true} onChange={() => null} value={relativeDate} />
        {/* } */}
        </div>
      </div>
    </DialogContent>
      <DialogActions style={{width: '100%', borderTop: '1px solid #D8D8D8', justifyContent: 'flex-start', marginTop: 10, padding: '15px 20px 10px 20px'}} >
        <Button onClick={props.onClose}>
          Cancel
        </Button>
        <div style={{flexGrow: 1}} />
        <Button variant="contained" onClick={() => props.onAccept(Number(selected), relativeDate)}>
          Accept
        </Button>
      </DialogActions>
  </Dialog>
  );
}

export { DueDateSelect }
