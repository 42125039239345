import { Divider } from '@material-ui/core';
import React from 'react';
import { FileDropSection } from './FileDropSection';
import ReconFileList from './ReconFileList';

export default function MultiFileReconPopper(props: any) {
  const { source, sourceType, task, updateTask, updateTaskInCache, tasks, allTasks, setTasks, FileSelectionSection, updateSourceFileDetails, updatePrimaryDisplaySkeletons, updateSecondaryDisplaySkeletons, updateTaskStatus } = props;

  return (
    <div>
      <ReconFileList
        allTasks={allTasks}
        setTasks={setTasks}
        sourceType={sourceType}
        task={task}
        tasks={tasks}
        updatePrimaryDisplaySkeletons={updatePrimaryDisplaySkeletons}
        updateSecondaryDisplaySkeletons={updateSecondaryDisplaySkeletons}
        updateTask={updateTask}
        updateTaskInCache={updateTaskInCache}
        updateTaskStatus={updateTaskStatus}
      />
      <FileDropSection
        source={source}
        sourceType={sourceType}
        task={task}
        updateTask={updateTask}
        updateTaskInCache={updateTaskInCache}
        updateTaskStatus={updateTaskStatus}
      />
      {/* hide FileSelectionSection component because Link Files to Task is not working anymore */}
      {/* <Divider
        orientation="horizontal"
        style={{ marginTop: '15px' }}
        variant="fullWidth"
      />
       <FileSelectionSection
        task={task}
        updateSourceFileDetails={updateSourceFileDetails}
      /> */}
    </div>
  )
}
