import { createMutationHook, gql } from '../graphqlHelpers';

export const useRemoveFileReconSupport = createMutationHook(gql`
  mutation RemoveFileReconSupport(
    $fileReconSourceId: ID!,
    $taskId: ID!
  ) {
    removeFileReconSupport(
      fileReconSourceId: $fileReconSourceId
      taskId: $taskId
    ) {
      id
      task {
        currency
        primaryFileReconSources {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        primaryFileReconSourcesTotal
        secondaryFileReconSources {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        reconcilingItems {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
            alterable
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        secondaryFileReconSourcesTotal
        isReconciled
        primaryTotal
        secondaryTotal
        reconcilingItemTotal
        reconDifference
      }
      success
    }
  }
`);
