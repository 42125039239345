import React from 'react';
import Linkify from 'linkifyjs/react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';

const reactStringReplace = require('react-string-replace');

const internalMarkStyle = {
  backgroundColor: '#fdf4d9'
};

const publicMarkStyle = {
  backgroundColor: '#e8f5fb'
};

interface MarkdownProps {
  message: string;
  users?: any;
	className: string;
	internal: boolean | undefined;
}

export default function Markdown(props: MarkdownProps) {
  const {message, users, className, internal} = props;

	//TODO get flattenUsers() to properly return generic object with just "id" and "item"
	//let flattenedUsers = flattenUsers(message, users);

	let markedMsg = messageMark(message, internal, users);

	let linkedMarkedMsg = messageLink(markedMsg);

  return linkedMarkedMsg;
}


const messageLink = (message: any)  => {
	return (message == '' ? <Skeleton /> : <Linkify options={{className: 'text-blue-100'}}>{message}</Linkify>);
}

// intended to be somewhat generic, basically taking a message
// and an array of objects to "mark" 
// for now, did the simplest case of just handling custom user objects
const messageMark = (message: any, internal: boolean | undefined, markObjs: any) => {

	if (typeof markObjs === 'undefined') {
		return message; 
	}


	for (var i = 0; i < markObjs.length; i++) {
		var user = markObjs[i];
		var name = user.displayName || user.name;
		var searchName = "@" + name;
		if (internal===true) {
			//var replaceStr = "<mark style=" + internalMarkStyle + ">" + searchName + "</mark>";
			message = reactStringReplace(message, searchName, (match: any, i: any) => (
				<mark style={internalMarkStyle}>{match}</mark>
			));
		} else {
			message = reactStringReplace(message, searchName, (match: any, i: any) => (
				<mark style={publicMarkStyle}>{match}</mark>
			));
		}

	}


	return message;

}
