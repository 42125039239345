import axios from 'axios';
import { useEffect } from 'react';
import { SlAuthentication } from './SlAuthentication';
import { IntacctAuthentication } from './IntacctAuthentication';

import React from 'react';

export function IntegrationAuthentication(props: any) {

  if (props.integrationType == '5') {
    return(
     <div> 
     </div>
    )
  }

  if (props.integrationType == '3') {  
    return(
      <IntacctAuthentication 
        open={props.open}
        denyAction={props.denyAction}
        integrationType={'Intacct'}
        confirmAction={props.confirmAction}
        loading={props.loading}
      />
    )
  }
  else if (props.integrationType == '4') {  
    return(
      <SlAuthentication 
        open={props.open}
        denyAction={props.denyAction}
        integrationType={'Softledger'}
        confirmAction={props.confirmAction}
      />
    )
  }

    return(
      <div/>
    )

}


