import React, { ReactNode, useEffect, useState } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useDispatchContext, useStateContext } from '../../store';
import { OKTA_AUTH0_CALLBACK_URL, OKTA_CONFIG_KEY_NAME } from '../common/Constants';

interface Auth0ProviderWithHistoryProps {
  children: ReactNode;
}
/**
 * Auth0ProviderWithHistory is a custom component that wraps the Auth0Provider from @auth0/auth0-react.
 * It manages the authentication state and redirects the user after authentication.
 * 
 * @param {object} children - The child components to be rendered within the Auth0Provider.
 * @returns {JSX.Element|null} The Auth0ProviderWithHistory component.
 */
const Auth0ProviderWithHistory = ({ children }: Auth0ProviderWithHistoryProps) => {
  // Configuration variables for the Auth0 provider
  const [oktaOAuthConfig, setOktaOAuthConfig] = useState<any>({});
  
  const dispatch = useDispatchContext();

  // Assign configuration values to variables
  const redirectUri = OKTA_AUTH0_CALLBACK_URL;

  const state = useStateContext();
  const currentOktaIntegration = state.currentOktaIntegration;

  useEffect(() => {
    const config = localStorage.getItem(OKTA_CONFIG_KEY_NAME);
    if (config) {
      dispatch({
        type: 'SET_CURRENT_OKTA_INTEGRATION',
        currentOktaIntegration: JSON.parse(config)
      });
    }
  }, []);

  useEffect(() => {
    if (currentOktaIntegration?.domain) {
      setOktaOAuthConfig({
        domain: currentOktaIntegration?.domain,
        clientId: currentOktaIntegration?.clientId,
        audience: currentOktaIntegration?.audience,
        isInitConfig: false
      });
    }
  }, [currentOktaIntegration?.domain]);

  useEffect(() => {
    if (oktaOAuthConfig?.domain) {
      dispatch({
        type: 'SET_OKTA_CONFIG_IS_READY',
        oktaConfigIsReady: true
      });
      const config = JSON.stringify({
        domain: oktaOAuthConfig?.domain,
        clientId: oktaOAuthConfig?.clientId,
        audience: oktaOAuthConfig?.audience
      })
      localStorage.setItem(OKTA_CONFIG_KEY_NAME, config);
    }
  }, [oktaOAuthConfig]);

  /**
   * Redirects the user to the specified URL after authentication.
   * 
   * @param {object} _appState - The application state object.
   */
  const onRedirectCallback = (_appState: any) => {
    // We can do other side effect here when the user is redirected from okta page
  };

  // Render the Auth0Provider with the provided configuration
  // The audience is the identifier of the API.
  // It is used here to link the Okta SPA app and the Okta API Backend App.
  return (
    <Auth0Provider
      key={oktaOAuthConfig?.domain || currentOktaIntegration?.domain}
      domain={oktaOAuthConfig?.domain || currentOktaIntegration?.domain}
      clientId={oktaOAuthConfig?.clientId || currentOktaIntegration?.clientId}
      authorizationParams={{ redirect_uri: redirectUri, audience: oktaOAuthConfig?.audience || currentOktaIntegration?.audience }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
