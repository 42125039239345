import { gql } from '../graphqlHelpers';

export const skinnyListWithTasks = (gql`
    query skinnyListsWithTasks ($id: ID!, $listId: ID) {
        skinnyListsWithTasks(id: $id, listId: $listId) {
            id
            name
            tasks {
                id
                name
                listNumber
                dueDate
                isVolatile
                dependencies{
                    id
                    relatedId
                    taskId
                    dueDays
                    dueHours
                    isDependent
                    hasStartDate
                    status
                    relatedName
                    taskName
                    relatedListNumber
                    taskListNumber
                    taskDueDate
                    relatedDueDate
                }
            }
        }
    }
`);
