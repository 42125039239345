import {createMutationHook, gql} from '../graphqlHelpers';

export const useCreateList = createMutationHook(gql`
  mutation CreateList(
    $listAttributes: ListAttributes!
    $tasks: [TaskAttributes!]!
  ) {
    createList(
      listAttributes: $listAttributes
      tasks: $tasks
    ) {
      list {
        reconciliations
        companyPosition
        deliverFileOnUpload
        currentUserIsOwner
        isArchived
        isDeleted
        id
        name
        taggingUsers {
          ... on User {
				    id
            email
            fullName
            profileUrl
				  }
				  ... on Team {
				    id
				    name
				  }
			  }
        sections {
          id
          name
        }
      }
      errors {
        path
        message
      }
      success
    }
  }
`);
