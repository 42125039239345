import React from "react";
import clsx from "clsx";
import {
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Popper,
} from "@material-ui/core";
import { useStyles } from "../Styles";
import { ICreateModalSelected } from "../../../constants";

interface IfoldersPopper {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  selectCreateOption: (createOptionSelected: ICreateModalSelected) => void;
  folders: [];
}

export const FoldersPopper = (props: IfoldersPopper) => {
  const { anchorEl, setAnchorEl, selectCreateOption, folders } = props;
  const classes = useStyles();

  function close() {
    setAnchorEl(null);
  }

  const handleTypeSelection = (space: any) => {
    selectCreateOption(space);
    close();
  };

  return (
    <Popper
      anchorEl={anchorEl}
      data-cy="test"
      open={Boolean(anchorEl)}
      placement={"bottom-start"}
      style={{
        zIndex: 1301,
        maxHeight: "300px",
        width: "200px",
        overflowY: "auto",
      }}
    >
      <ClickAwayListener onClickAway={close}>
        <List
          classes={{ root: clsx(classes.root, classes.list) }}
          disablePadding
        >
          {folders.length > 0 ? (
            folders.map((folder: any, index: number) => {
              return (
                <ListItem
                  key={index + folder.name + folder.id}
                  classes={{ root: classes.listItem }}
                  onClick={() => handleTypeSelection(folder)}
                >
                  <ListItemText
                    primary={folder.name}
                    classes={{ primary: classes.t2 }}
                  />
                </ListItem>
              );
            })
          ) : (
            <ListItem classes={{ root: classes.listItem }} onClick={close}>
              <ListItemText
                primary={"No folders found"}
                classes={{ primary: classes.t2 }}
              />
            </ListItem>
          )}
        </List>
      </ClickAwayListener>
    </Popper>
  );
};
