/* The purpose of this component is:
 */

import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle, Link, Typography } from '@material-ui/core';

export function IntacctEnable(props: any) {
  return(
    <div>
      { /* Instuctions on how to enable functionality*/ }
      <Dialog open={props.open}>
      
      <div style={{padding: 20, width: 600, height: 448}}>
          <DialogTitle>
            <Typography variant='h2'>
            {`Connect Your ${props.integrationType} Account`}
            </Typography>
          </DialogTitle>
      <div style={{padding: '16px 24px'}}>
      <Typography>
      To ensure the best possible performance, please have an Intacct user with
        <Box display='inline' fontWeight={600}>&nbsp; Full Admin &nbsp;</Box>
        privileges connect PrepDD to your Intacct account.</Typography>
      <br />
      <Typography style={{fontWeight: 600}}>
        Your Intacct account must have Web Services enabled and add PrepDD's Sender ID (PrepDDMPP)
        to successfully connect to your account.
      </Typography>
      <br />

    <Typography>
      1. <Box display='inline' fontWeight={600}>Web Services</Box>{`: You can enable Web Services within your Intacct portal
       by using the main navigation menu to select Company > Admin > Subscriptions.
       The Web Services option is near the bottom of the Subscription page
       (options are ordered alphabetically).` }
    </Typography>
      <br />

    <Typography>
    2. <Box display='inline' fontWeight={600}>Sender ID</Box>{`: Add PrepDD's Sender ID to your Intacct company portal in
      the Security tab of your Company Information page. Company > Company Info > Edit > Security` }
    </Typography>
      <br />

    <Typography>
      Please contact our customer success team at <Link href='mailto:support@prepdd.com'>support@prepdd.com</Link> with any questions about configuring your integration
    </Typography>
    </div>
    </div>
        <DialogActions>
          <Button onClick={props.denyAction} variant='outlined'>
            Cancel
          </Button>
          <Button variant='contained' onClick={props.confirmAction} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
