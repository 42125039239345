import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { classNames, measureTextInPixel } from "../../helpers/helpers";
import { usePrevious } from "../../hooks/usePrevious";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";

type MessageLabelsBoxProps = {
  className?: string;
  style?: any;
  items: any[];
  searchText: string;
  onSelectItem(p: any): void;
  onEscape?(e?: any): void;
  onClickAddNew?(text: string): void;
  onClickCustomize?(): void;
};

/** Component to display a dropdown for custom label selection
 * @param `props.className` - The component attribute that contains the root class name
 * @param `props.style` - The component attribute that contains the root style
 * @param `props.items` - The component attribute that contains all labels to select from
 * @param `props.onSelectItem` - The component function to call when a tag has been selected
 * @param `props.onEscape` - The component function to call when the escape key has been pressed
 * @return {React.JSXElementConstructor} The output component.
 */
export default function MessageLabelsBox(props: MessageLabelsBoxProps) {
  const [selected, setSelected] = useState(null);
  const isFirstRun = useRef(true);
  const listElementRef: any = useRef(null);
  const prevPeopleList = usePrevious(props.items);

  // Effect to set first item of list selected when props list changed
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (prevPeopleList?.length !== props.items.length) {
      // List item list has changed: we use length to assume this change
    }
  }, [props.items]);


  // Keyboard event listener
  const handleKeyUpTag = useCallback((event: any) => {
    // Enter
    if (event.keyCode === 13) {
      event.preventDefault();
      if (selected && props.items.length) {
        props.onSelectItem(selected);
      }
    }
    // Escape
    if (event.keyCode === 27) {
      event.preventDefault();
      if (props.onEscape) {
        props.onEscape();
      }
    }
    // Arrow up
    if (event.keyCode === 38) {
      // const idx = props.items.findIndex(d => d.id === selected.id);
      const idx = findItemIndex(props.items, selected);
      if (idx > 0 && idx < props.items.length) {
        setSelected(props.items[idx - 1]);
        handleScrollOnArrowUp(props.items, idx);
      }
    }
    // Arrow down
    if (event.keyCode === 40) {
      // const idx = props.items.findIndex(d => d.id === selected.id);
      const idx = findItemIndex(props.items, selected);
      if (idx > -1 && idx < (props.items.length - 1)) {
        setSelected(props.items[idx + 1]);
      } else if (idx === props.items.length - 1) {
        if (props.items.length) {
          setSelected(props.items[0]);
        }
      }
      handleScrollOnArrowDown(props.items, idx)
    }
  },[selected, props.items]);

  // Keyboard event listener effect
  useEffect(() => {
    document.addEventListener("keyup", handleKeyUpTag);
    return () => {
      document.removeEventListener("keyup", handleKeyUpTag);
    };
  }, [handleKeyUpTag]);

  // Fire when item has been selected
  const onClickItem = (item: any) => {
    const idx = findItemIndex(props.items, item);
    if (idx !== -1) {
      setSelected(item);
      props.onSelectItem(item);
    }
  }

  const findItemIndex = (list: any[], item: any): number => {
    if (list && item) {
      return list.findIndex(d => {
        return d.name === item.name;
      });
    }
    return -1;
  }

  const handleScrollOnArrowUp = (listItems: any[], index: number) => {
    var newSelection = (index + listItems.length - 1) % listItems.length;
    var list = listElementRef.current;
    var { scrollTop } = list || {};
    if (listItems.length - 1 == newSelection) {
      if (list) {
        list.scrollTo(0, 40 * listItems.length);
      }
    } else if (newSelection * 40 < scrollTop) {
      if (list) {
        list.scrollTo(0, scrollTop - 40);
      }
    }
  }

  const handleScrollOnArrowDown = (listItems: any[], index: number) => {
    var newSelection = (index + 1) % listItems.length;
    var list = listElementRef.current;
    var scrollBottom = (list ? list.scrollTop : 0) + 175;
    if (0 == newSelection) {
      if (list) {
        list.scrollTo(0, 0);
      }
    } else if (newSelection * 45 >= scrollBottom) {
      if (list) {
        list.scrollTo(0, list.scrollTop + 45);
      }
    }
  }

  return (
    <Listbox value={selected} onChange={onClickItem}>
      <div data-cy='msg-labels-selection-list' className={classNames("absolute z-10 mt-1", props.className)} style={props.style}>
        <Transition
          show={true}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          >
          <Listbox.Options ref={listElementRef} className="mt-1 max-h-52 w-full overflow-auto border border-gray-280 rounded-sm bg-white py-0 text-base focus:outline-none sm:text-sm">
            {props.items.map((label, index) => (
              <Listbox.Option
                key={index}
                className={({ active, selected }) => classNames( selected ? 'bg-gray-180' : 'text-gray-900',
                  "relative hover:bg-gray-180 cursor-pointer select-none py-2 pl-3 pr-9"
                )}
                value={label}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <span
                      style={{backgroundColor: label.color || '#4b5563'}}
                      className={classNames('inline-block h-2 w-2 flex-shrink-0 rounded-full'
                      )}
                      aria-hidden="true"
                    />
                    <span className={classNames("font-semibold text-xs ml-2 block max-w-[120px] truncate")} title={measureTextInPixel(label.name, 12) > 105 ? label.name : ""}>
                      {label.name}
                    </span>
                  </div>
                </div>
              </Listbox.Option>
            ))}
            <Listbox.Option
              data-cy="msg-labels-customize"
              className="relative cursor-pointer select-none text-gray-900 py-2 pl-3"
              value={null}
              onClick={() => props.onClickCustomize && props.onClickCustomize()}
            >
              <div className="flex items-center">
                <Cog6ToothIcon className="h-3 w-3 -ml-[2px] cursor-pointer text-gray-400 hover:text-black" />
                <span className={classNames("font-normal text-xs ml-[0.3rem] block truncate text-gray-400")}>
                  Customize
                </span>
              </div>
            </Listbox.Option>
            {
              !!!props.items.length && 
                <Listbox.Option
                  className={({ active, selected }) => classNames( selected ? 'bg-gray-180' : 'text-gray-900',
                    "relative cursor-pointer select-none py-2 px-1"
                  )}
                  value={null}
                  onClick={() => props.onClickAddNew && props.onClickAddNew(props.searchText)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <span className={classNames("font-semibold text-xs block max-w-[150px] truncate")} title={measureTextInPixel(`+ Create ${props.searchText}`, 12) > 135 ? `+ Create ${props.searchText}` : ""}>
                        + Create {props.searchText}
                      </span>
                    </div>
                  </div>
                </Listbox.Option>
            }
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}