import React, {useEffect, useState} from 'react';

import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import {
  FormControlLabel,
  Button,
  Popper,
  Tab,
  Grid,
  Paper,
  Typography,
  MenuItem,
  ListItemIcon,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Tooltip,
} from '@material-ui/core/';
import clsx from 'clsx';

import SidePanel from '../../SidePanel';
import {TabPanel, TabContext, TabList, Alert} from '@material-ui/lab/';

import {dependencyDataGeneratorFunc, dependenciesPresentFunc, handleDeleteTaskDependencyFunc} from './DependencyFunctions'

import {
  Add,
  AttachFile,
  CalendarToday,
  Delete,
  Link,
  LinkedCamera,
  Clear,
  Close,
  RemoveCircle,
  InsertLink,
  RemoveCircleOutline,
} from '@material-ui/icons';

import {gql, useMutation} from '@apollo/client';
import {useCreateAutoGeneratedTaskDependency} from '../../../../../../../graphql/mutations/CreateAutoGeneratedTaskDependency';
import {useSetFilters} from '../../../../../../../hooks/SetFilters';
import {IconButton} from '@material-ui/core';
// local component imports
import SearchDependency from './components/SearchDependency';
import SaveButton from './components/SaveButton';
import DateDependency from './components/DateDependency';
import WaitingOn from './components/WaitingOn';
import BlockingOn from './components/BlockingOn';
import RelatedOnly from './components/RelatedOnly';
import { useDispatchContext, useStateContext } from '../../../../../../../store';

const tabOptions = [
  {tabVal: '1', tabName: 'Waiting On'},
  {tabVal: '2', tabName: 'Blocking'},
  {tabVal: '3', tabName: 'Related To'},
];

export const INSERT_TASK_DEPENDENCY = gql`
  mutation CreateTaskDependency($taskDependency: TaskDependencyAttributes!) {
    createTaskDependency(taskDependency: $taskDependency) {
      success
    }
  }
`;
export const DELETE_TASK_DEPENDENCY = gql`
  mutation DeleteTaskDependency($taskId: ID!, $relatedId: ID!) {
    deleteTaskDependency(taskId: $taskId, relatedId: $relatedId) {
      errors {
        path
        message
      }
      success
      relatedNewDate
      taskNewDate
    }
  }
`;

interface TaskDependencyProps {
  lists: any;
  task: any;
  skinnyLists: any;
  setSkinnyLists: React.Dispatch<any>;
  dependencies: any;
  setDependencies: React.Dispatch<{}>;
}

export default function TaskDependency(props: any) {
  const {
    lists,
    task,
    skinnyLists,
    setSkinnyLists,
    dependencies,
    setDependencies,
  } = props;
  const dispatch = useDispatchContext();
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState('1');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const setFilters = useSetFilters();

  const dependencyDataGenerator = dependencyDataGeneratorFunc;
  const dependenciesPresent = dependenciesPresentFunc;
  const handleDeleteTaskDependency = handleDeleteTaskDependencyFunc;

  /* deprecated
  const {
    loading: TaskDependencyLoading,
    error: TaskDependencyError,
    data: TaskDependencyData,
  } = useQuery(TASK_DEPENDENCY, {
    variables: {id: 1240},
  });
*/

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openDeleteSnackbar, setOpenDeleteSnackbar] = React.useState(false);

  const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const snackState = (value: any) => {
    setOpenSnackbar(value);
  };

  const [insertTaskDependency] = useMutation(INSERT_TASK_DEPENDENCY);
  const [
    deleteTaskDependency,
    {loading: mutationLoading, data: mutationData, error: mutationError},
  ] = useMutation(DELETE_TASK_DEPENDENCY);

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleDeleteTaskDependency = (
  //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  //   isBlocking: Boolean,
  //   relatedId: any
  // ) => {
  //   let taskId = '';
  //   if (isBlocking) {
  //     taskId = relatedId;
  //     relatedId = task.id;
  //   } else {
  //     taskId = task.id;
  //   }
  //   deleteTaskDependency({
  //     variables: {taskId: taskId, relatedId: relatedId},
  //   })
  //     .then((response: any) => {
  //       //handle response
  //       console.log('mutation data', response);
  //       let _skinnyLists = skinnyLists.map((a: any) => {
  //         return {...a};
  //       });
  //       for (let list_iter = 0; list_iter < _skinnyLists.length; list_iter++) {
  //         let _current_list = _skinnyLists[list_iter];
  //         let _list_of_tasks = _current_list.tasks;
  //         for (let i = 0; i < _list_of_tasks.length; i++) {
  //           if (
  //             _list_of_tasks[i].dependencies &&
  //             _list_of_tasks[i].dependencies.length > 0
  //           ) {
  //             for (let j = 0; j < _list_of_tasks[i].dependencies.length; j++) {
  //               let curdep = _list_of_tasks[i].dependencies[j];
  //               if (
  //                 (curdep.taskId == taskId && curdep.relatedId == relatedId) ||
  //                 (curdep.relatedId == taskId && curdep.taskId == relatedId)
  //               ) {
  //                 //delete _list_of_tasks[i].dependencies[j]; // leaves empty element
  //                 if (_list_of_tasks[i].id == taskId) {
  //                   _list_of_tasks[i].dueDate =
  //                     response.data.deleteTaskDependency.taskNewDate;
  //                 }
  //                 if (_list_of_tasks[i].id == relatedId) {
  //                   _list_of_tasks[i].dueDate =
  //                     response.data.deleteTaskDependency.relatedNewDate;
  //                 }
  //                 _list_of_tasks[i].dependencies.splice(j, 1);
  //               }
  //             }
  //           }
  //         }
  //       }
  //       setSkinnyLists(_skinnyLists);
  //     })
  //     .catch((err: any) => {
  //       console.log('mutation error', mutationError);
  //       setOpenDeleteSnackbar(true);
  //     });

  //   // search all the lists and tasks for that dependency
  //   // remove from active tasks object so UI will update
  //   // is there a better way?
  // };

  // let dependencies = [] as any;
  // const [taskId, setTaskId] = useState<any>('');
  // const [taskDisplayedDueDate, setTaskDisplayedDueDate] = useState<any>('');
  // const [taskListNumber, setTaskListNumber] = useState<any>('');
  // const [taskName, setTaskName] = useState<any>('');

  // const [relatedId, setRelatedId] = useState<any>('');
  // const [relatedDisplayedDueDate, setRelatedDisplayedDueDate] = useState<any>('');
  // const [relatedListNumber, setRelatedListNumber] = useState<any>('');
  // const [relatedName, setRelatedName] = useState<any>('');
  // fill dependencies with the dependencies of the tasks passed
  // from Overview Panel

  // {
  //   skinnyLists.forEach((_list:any)=>{
  //     let _tasks = _list.tasks;
  //     _tasks.forEach((t: any) => {
  //       if (t.id == task.id) {
  //         t.dependencies.forEach((d: any) => {
  //           dependencies.push(d);
  //         });
  //       }
  //     });
  //   });
  // }

  function getMessage(msg: string) {
    console.log('Dependency Added : ' + msg);
  }

  // function getTaskName(taskId: string) {
  //   let taskName: string;
  //   taskName = '';
  //   skinnyLists.forEach((_list:any)=>{
  //     let _tasks = _list.tasks;
  //     _tasks.forEach((t: any) => {
  //       if (t.id == taskId) {
  //         taskName = t.name;
  //       }
  //     });
  //   });
  //   return taskName;
  // }

  // //
  // function getTaskData(taskId: string) {
  //   let listNumber = '';
  //   let dueDate = '';
  //   let name = '';
  //   skinnyLists.forEach((_list: any) => {
  //     let _tasks = _list.tasks;
  //     _tasks.forEach((t: any) => {
  //       if (t.id == taskId) {
  //         listNumber = t.listNumber;
  //         dueDate = t.dueDate;
  //         name = t.name;
  //       }
  //     });
  //   });
  //   return [name, dueDate, listNumber];
  // }
  // // // // //
  // function getDueDate(taskId: string) {
  //   let listNumber: string;
  //   listNumber = '';
  //   skinnyLists.forEach((_list:any)=>{
  //     let _tasks = _list.tasks;
  //     _tasks.forEach((t: any) => {
  //       if (t.id == taskId) {
  //         listNumber = t.listNumber;
  //       }
  //     });
  //   });
  //   return listNumber;
  // }

  const handleCloseSnackBar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  function onDependencyClick(taskToOpen: any){
    if(props.isCreateProject){
      props.onDependencyClick && props.onDependencyClick(taskToOpen);
    } else {
      openTask(taskToOpen)
    }
  }

  function openTask(taskToOpen: string) {
    let listToOpen = -1;

    // 1. determine list to which this task belongs
    for (let i = 0; i < skinnyLists.length; ++i) {
      for (let j = 0; j < skinnyLists[i].tasks.length; ++j) {
        if (skinnyLists[i].tasks[j].id == taskToOpen) {
          listToOpen = skinnyLists[i].id;
          break;
        }
      }
    }

    // 2. determine if task is in current display
    //
    // 3. if so, change filters to active list/task,
    //    don't reset filters
    //
    // 4. if not, activate list/task and reset filters
    //
    //
    /*
    console.log("TASK");
    console.log(taskToOpen.toString());
    console.log("LIST");
    console.log(listToOpen.toString());
    */

    /*
    setFilters({
      taskPageSelectedTasks: [taskToOpen.toString()],
      taskPageLists: [listToOpen.toString()],
    });
*/

    setFilters(
      {
        taskPageSelectedTasks: [taskToOpen.toString()],
        taskPageLists: [listToOpen.toString()],
        taskPagePane: 0,
      },
      {defaultTaskFilters: true}
    );
  }

  // function dependenciesPresent() {
  //   return (
  //     dependencies && dependencies[task.id] && dependencies[task.id].length > 0
  //   );
  // }

  // let months = [
  //   'Jan',
  //   'Feb',
  //   'Mar',
  //   'Apr',
  //   'May',
  //   'Jun',
  //   'Jul',
  //   'Aug',
  //   'Sep',
  //   'Oct',
  //   'Nov',
  //   'Dec',
  // ];
  // function dependencyDataGenerator(dependency: any) {
  //   let prefix: string;
  //   let suffix: string;
  //   let taskDisplayId: string;
  //   let taskDisplayName: string;
  //   let fullTaskDisplayName: string;
  //   let isBlocking: Boolean;
  //   let relatedId: string;
  //   prefix = '';
  //   suffix = '';
  //   taskDisplayId = '';
  //   taskDisplayName = '';
  //   fullTaskDisplayName = '';
  //   relatedId = '';
  //   isBlocking = dependency.relatedId == task.id;
  //   let isDependent = dependency.isDependent;
  //   relatedId = isBlocking ? dependency.taskId : dependency.relatedId;

  //   let hasStartDate = dependency.hasStartDate;
  //   let displayDueDate = '';
  //   let [taskName, taskDueDate, taskListNumber] = getTaskData(
  //     dependency.taskId
  //   );
  //   let [relatedName, relatedDueDate, relatedListNumber] = getTaskData(
  //     dependency.relatedId
  //   );
  //   // is dependent but not blocking
  //   if (!isBlocking && isDependent) {
  //     displayDueDate = relatedTaskDueDate(relatedDueDate);
  //     [prefix, suffix] = dependentTasktext(
  //       dependency,
  //       hasStartDate,
  //       relatedListNumber,
  //       displayDueDate
  //     );
  //     taskDisplayName = relatedName;
  //     taskDisplayId = relatedId;
  //   } else {
  //     //either blocking or linked
  //     [
  //       prefix,
  //       suffix,
  //       taskDisplayName,
  //       taskDisplayId,
  //     ] = linkedAndBlockedTaskText(
  //       dependency.taskId,
  //       dependency.relatedId,
  //       isDependent,
  //       isBlocking,
  //       taskName,
  //       relatedName,
  //       taskListNumber,
  //       relatedListNumber
  //     );
  //   }
  //   let bufferTruncate = (58 - (prefix.length + suffix.length ))
  //   fullTaskDisplayName = taskDisplayName;
  //   if (prefix.length + suffix.length + taskDisplayName.length > 60) {
  //     taskDisplayName = taskDisplayName.substring(0, bufferTruncate) + '...';

  //   }

  //   return [
  //     prefix,
  //     suffix,
  //     taskDisplayName,
  //     fullTaskDisplayName,
  //     bufferTruncate,
  //     taskDisplayId,
  //     relatedId,
  //     isBlocking,
  //   ];
  // }

  // function dependentTasktext(
  //   dependency: any,
  //   hasStartDate: Boolean,
  //   relatedListNumber: string,
  //   displayDueDate: string
  // ) {
  //   let prefix = hasStartDate ? 'Starts ' : 'Due ';
  //   let suffix = '';
  //   let dayPrefix = '';
  //   let hourPrefix = '';
  //   let days = dependency.dueDays;
  //   //let hours = dependency.dueHours;
  //   let hours = '';
  //   if (typeof dependency.dueHours == 'undefined') {
  //     hours = '';
  //   } else {
  //     hours = parseFloat(dependency.dueHours).toString();
  //   }
  //   if (days && days.length > 0 && days != '' && days != '0.0') {
  //     if (days != '0' && days != 'NaN') {
  //       dayPrefix = dayPrefix.concat(parseInt(days).toString());
  //       dayPrefix = dayPrefix.concat(
  //         parseInt(days) <= 1.0 ? ' day ' : ' days '
  //       );
  //     }
  //   }
  //   if (hours && hours.length > 0 && hours != '' && hours != '0.0') {
  //     if (hours != '0' && hours != 'NaN') {
  //       if (dayPrefix.length > 0) {
  //         hourPrefix = hourPrefix.concat('and ');
  //       }
  //       hourPrefix = hourPrefix.concat(hours);
  //       hourPrefix = hourPrefix.concat(
  //         parseFloat(hours) <= 1.0 ? ' hour ' : ' hours '
  //       );
  //     }
  //   }
  //   if (dayPrefix.length > 0) {
  //     prefix = prefix.concat(dayPrefix);
  //   }
  //   if (hourPrefix.length > 0) {
  //     prefix = prefix.concat(hourPrefix);
  //   }
  //   prefix = prefix.concat(' after task ');
  //   prefix = prefix.concat(relatedListNumber);

  //   if (displayDueDate && displayDueDate.length > 0) {
  //     suffix = suffix.concat(' due on ');
  //     suffix = suffix.concat(displayDueDate);
  //   }
  //   return [prefix, suffix];
  // }

  // function linkedAndBlockedTaskText(
  //   taskId: string,
  //   relatedId: string,
  //   isDependent: Boolean,
  //   isBlocking: Boolean,
  //   taskName: string,
  //   relatedName: string,
  //   taskListNumber: string,
  //   relatedListNumber: string
  // ) {
  //   let prefix = isDependent ? 'Blocking task ' : 'Linked to task ';
  //   let suffix = '';
  //   let taskDisplayName = isBlocking ? taskName : relatedName;
  //   prefix = prefix.concat(isBlocking ? taskListNumber : relatedListNumber);
  //   let taskDisplayId = isBlocking ? taskId : relatedId;
  //   return [prefix, suffix, taskDisplayName, taskDisplayId];
  // }

  // function relatedTaskDueDate(taskDueDate: string) {
  //   if (taskDueDate && taskDueDate.length > 0) {
  //     let date_hours = taskDueDate.split(' ');
  //     let date = date_hours[0].split('-');
  //     let idx = date[1];
  //     let displayedDueDate = months[parseInt(idx) - 1]
  //       .concat(' ')
  //       .concat(date[2]);
  //     return displayedDueDate;
  //   } else {
  //     return '';
  //   }
  // }

  return (
    <div className={classes.root}>
      {dependenciesPresent(dependencies, task) ? (
        // if we have dependencies
        <div>
          {!props.fromCreateProject && <Grid
            container
            direction="column"
            spacing={1}
            style={{marginLeft: '10px'}}
          >
            <Grid item xs>
              <Typography
                className={clsx(classes.secondary, classes.label)}
                style={{
                  minWidth: '0px',
                  paddingTop: '3px',
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}
                variant="h6"
              >
                Dependencies
              </Typography>
            </Grid>
            <Grid item xs>
              {dependencies[task.id].map((dependency: any, index: number) => {
                let [
                  text_prefix,
                  text_suffix,
                  taskDisplayName,
                  fullTaskDisplayName,
                  bufferTruncate,
                  taskDisplayId,
                  relatedId,
                  isBlocking,
                ] = dependencyDataGenerator(dependency, task, skinnyLists);
                return (
                  <List
                    key={index}
                    component="nav"
                    aria-label="main mailbox folders"
                    disablePadding={true}
                  >
                    <ListItem
                      data-cy="list-task-dependency"
                      button
                      disableGutters={true}
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        {text_prefix.toString().split(' ')[0] == 'Linked' ? (
                          <InsertLink
                            fontSize="small"
                            style={{color: '#60606099'}}
                          />
                        ) : text_prefix.toString().split(' ')[0] ==
                          'Blocking' ? (
                          <RemoveCircleOutline
                            fontSize="small"
                            style={{color: '#60606099'}}
                          />
                        ) : (
                          <CalendarToday
                            fontSize="small"
                            style={{color: '#60606099'}}
                          />
                        )}
                      </ListItemIcon>
                      <Typography
                        variant="inherit"
                        className={classes.prefixStyling}
                      >
                        {text_prefix}
                      </Typography>
                      <Typography
                        onClick={e => onDependencyClick(taskDisplayId.toString())}
                        variant="inherit"
                        data-cy="task-links"
                      >
                        {taskDisplayName.toString().length > bufferTruncate ? (
                          <Tooltip
                            title={
                              <div style={{fontSize: '1em'}}>
                                {fullTaskDisplayName}
                              </div>
                            }
                          >
                            <Typography className={classes.taskDisplayName}>
                              {taskDisplayName}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography className={classes.taskDisplayName}>
                            {taskDisplayName}
                          </Typography>
                        )}
                      </Typography>
                      <Typography
                        variant="inherit"
                        className={
                          text_suffix == ''
                            ? classes.hideSuffixStyling
                            : classes.suffixStyling
                        }
                      >
                        {text_suffix}
                      </Typography>
                      <IconButton
                        onClick={e => {
                          handleDeleteTaskDependency(
                            e,
                            Boolean(isBlocking),
                            relatedId,
                            task,
                            skinnyLists,
                            setSkinnyLists,
                            deleteTaskDependency,
                            mutationError,
                            setOpenDeleteSnackbar
                          )
                          dispatch({type: 'DEPEDENCIES_UPDATED'})
                          }
                        }
                        data-cy="delete-dependency"
                      >
                        <Clear style={{fontSize: '12px'}} />
                      </IconButton>
                    </ListItem>
                  </List>
                );
              })}
            </Grid>
          </Grid>
          }
          <Button
            variant="contained"
            // color="primary"
            onClick={handleOpenPopper}
            startIcon={<Add />}
            className={classes.linkButton}
            data-cy="dependency-link"
            style={props.fromCreateProject && {marginLeft: 0}}
          >
            <span style={{whiteSpace: 'nowrap', color: '#3A84FF'}}>
              Link task
            </span>
          </Button>
        </div>
      ) : (
        // if we dont have dependencies
        <div style={{display: 'flex'}}>
          {!props.fromCreateProject && <div style={{marginLeft: '15px'}}>
            <Typography
              className={clsx(classes.secondary, classes.label)}
              style={{
                minWidth: '0px',
                paddingTop: '3px',
                flex: 1,
                display: 'flex',
                alignItems: 'center',
              }}
              variant="h6"
            >
              Dependencies
            </Typography>
          </div>
          }
          <div style={props.fromCreateProject ? undefined : {marginLeft: '27px', marginTop: '-8px'}}>
            <Button
              color="inherit"
              onClick={handleOpenPopper}
              className={classes.linkButton2}
              data-cy="no-dependency-link"
              startIcon={
                <Link
                  style={{
                    marginTop: '2px',
                    fontWeight: 600,
                    // marginRight: '4px',
                    // marginLeft: '8px',
                    // color: '#606060',
                  }}
                />
              }
            >
              <span style={{marginTop: '2px'}}>Link tasks</span>
            </Button>
          </div>
        </div>
      )}
      <Popper
        open={open}
        id={id}
        placement="bottom-start"
        anchorEl={anchorEl}
        className={classes.popper}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper variant="outlined" square className={classes.box}>
            <TabContext value={tabValue}>
              <Grid container direction="column">
                <TabList
                  onChange={handleTabChange}
                  TabIndicatorProps={{style: {backgroundColor: 'white'}}}
                >
                  {tabOptions.map(idx => (
                    <Tab
                      key={idx.tabVal}
                      value={idx.tabVal}
                      label={
                        <span>
                          <Button
                            variant="outlined"
                            color="inherit"
                            data-cy={idx.tabName}
                            className={
                              tabValue === idx.tabVal
                                ? classes.active
                                : classes.tabButton
                            }
                          >
                            {idx.tabName}
                          </Button>
                        </span>
                      }
                    />
                  ))}
                </TabList>
              </Grid>

              <TabPanel value="1">
                <WaitingOn
                  snackbarStat={snackState}
                  getMsg={getMessage}
                  anchorEl={handleOpenPopper}
                  task={task}
                  skinnyLists={skinnyLists}
                  setSkinnyLists={setSkinnyLists}
                />
              </TabPanel>
              <TabPanel value="2">
                <BlockingOn
                  snackbarStat={snackState}
                  getMsg={getMessage}
                  anchorEl={handleOpenPopper}
                  task={task}
                  skinnyLists={skinnyLists}
                  setSkinnyLists={setSkinnyLists}
                />
              </TabPanel>
              <TabPanel value="3">
                <RelatedOnly
                  snackbarStat={snackState}
                  getMsg={getMessage}
                  anchorEl={handleOpenPopper}
                  task={task}
                  skinnyLists={skinnyLists}
                  setSkinnyLists={setSkinnyLists}
                />
              </TabPanel>
            </TabContext>
          </Paper>
        </ClickAwayListener>
      </Popper>
      <Snackbar
        open={openSnackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{marginLeft: '4rem'}}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
      >
        <Alert severity="success">Dependency successfully added</Alert>
      </Snackbar>
      {mutationError?.message.length === 0 ? (
        <Snackbar
          open={!openDeleteSnackbar}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          style={{marginLeft: '4rem'}}
          autoHideDuration={2000}
          onClose={handleCloseSnackBar}
        >
          <Alert severity="success">Delete successful</Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={openDeleteSnackbar}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          autoHideDuration={2000}
          style={{marginLeft: '4rem'}}
          onClose={handleCloseSnackBar}
        >
          <Alert severity="error">Delete failed</Alert>
        </Snackbar>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },

  '.MuiButton-label': {
    marginRight: '-1px',
    whiteSpace: 'nowrap',
  },
  active: {
    background: '#EFF5FF',
    fontWeight: 'bolder',
    fontFamily: 'Montserrat',
    borderWidth: 'thin',
    zIndex: 1,
    '&:hover': {
      borderWidth: 'thin',
    },
  },
  linkButton2: {
    color: '#AFAFAF',
    backgroundColor: 'transparent',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    borderWidth: 'thin',
    borderColor: 'transparent',
    // border: '0px',
    paddingLeft: '6px',
    paddingRight: '4px',
    '&:hover': {
      borderWidth: 'thin',
      // borderRadius: '0px',
      backgroundColor: 'transparent',
      paddingLeft: '6px',
      paddingRight: '4px',
    },
  },
  linkButton: {
    color: '#3A84FF',
    opacity: '1',
    backgroundColor: 'transparent',
    fontWeight: 'normal',
    fontFamily: 'Montserrat',
    borderWidth: 'thin',
    paddingLeft: '6px',
    paddingRight: '4px',
    marginLeft: '11px',
    '&:hover': {
      borderWidth: 'thin',
      backgroundColor: 'transparent',
    },
  },

  listItem: {
    paddingTop: '0px',
    paddingBottom: '0px',
    '&:hover': {
      borderWidth: 'thin',
      backgroundColor: 'transparent',
    },
  },

  tabButton: {
    borderColor: '#D8D8D8',
    color: '#A0A0A0',
    fontWeight: 'bolder',
    fontFamily: 'Montserrat',
    borderWidth: 'thin',

    '&:hover': {
      borderWidth: 'thin',
    },
  },
  tab: {
    marginLeft: '12px',
  },
  popper: {
    zIndex: 1200,
    marginLeft: '5.5rem',
    width: '400px',
    height: '260px',
  },
  box: {
    borderStyle: 'solid',
    borderWidth: 'thin',
    boxSizing: 'border-box',
    paddingLeft: '10px',
    borderColor: '#D8D8D8',
    fontFamily: 'Montserrat',
  },
  secondary: {
    color: '#606060',
  },
  label: {
    minWidth: '80px',
  },
  taskDisplayName: {
    fontSize: '12px',
    color: '#1159D0',
    fontFamily: 'Montserrat',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  suffixStyling: {
    fontSize: '12px',
    color: '#606060',
    fontFamily: 'Montserrat',
    margin: '0px 0px 0px 4px',
    whiteSpace: 'nowrap',
  },
  prefixStyling: {
    fontSize: '12px',
    color: '#606060',
    fontFamily: 'Montserrat',
    margin: '0px 4px 0px -12px',
    whiteSpace: 'nowrap',
  },
  hideSuffixStyling: {
    display: 'none',
  },
}));
