import {DocumentNode} from 'graphql';
import {gql, ApolloError, useQuery, useMutation, QueryResult, MutationTuple, useLazyQuery, LazyQueryHookOptions, QueryLazyOptions} from '@apollo/client'
export {gql, useQuery};

export function createQueryHook<TData, TVariables>(
  query: DocumentNode
): (variables: TVariables) => QueryResult<TData, TVariables> {
  return function(variables) {
    return useQuery(query, {variables, fetchPolicy: 'network-only'});
  };
}

export function createLazyQueryHook<TData, TVariables>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<TData, TVariables>
): (variables: TVariables) => [
  (options?: QueryLazyOptions<TVariables>) => void,
  QueryResult<TData, TVariables>
]{
  return function(variables) {
    return useLazyQuery(query, {variables, fetchPolicy: 'network-only'});
  };
}

export function createMutationHook<TData, TVariables>(
  mutation: DocumentNode
): (
  variables: TVariables,
  onCompleted?: (data: TData) => void,
  onError?: (error: ApolloError) => void
) => MutationTuple<TData, TVariables> {
  return function(variables) {
    return useMutation(mutation, {
      variables,
    });
  };
}

