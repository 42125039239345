import { useLazyQuery, gql, useQuery } from '@apollo/client';

export const netsuiteMenuItemsQuery = gql`
  query NetsuiteMenuItems {
    netsuiteMenuItems {
      subsidiaryNames,
      departmentNames,
      classNames,
      accountNames {
        accountId,
        accountName,
        accountNumber
      },
      itemNames,
      entityNames,
      bookNames,
      startDates,
      endDates,
      currencies
    }
  }
`;
