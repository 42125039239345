import React, { useState, useEffect, useRef } from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ClickAwayListener, Paper, Popper, Snackbar, Typography } from '@material-ui/core';

//Icons
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CallSplitOutlinedIcon from '@material-ui/icons/CallSplitOutlined';

import { useRemoveFileReconSupport } from '../../../../../../../../graphql/mutations/RemoveFileReconSupport';
import useDeleteReconSourceFile from '../../../../../../../../graphql/mutations/DeleteFileReconSource';
import { useLazyGetTasksById } from '../../../../../../../../graphql/queries/getTasksById';

import { FileUploadProperties, FileUploadWorkerProperties, useFileUploadWorker } from '../../../../../../../../hooks/useFileUploadWorker';

import clsx from 'clsx';
import idx from 'idx';
import { Alert, Color } from '@material-ui/lab';

import { useDispatchContext, useStateContext } from '../../../../../../../../store';
import { useSetFilters } from '../../../../../../../../hooks/SetFilters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '175px',
      border: '1px solid #D8D8D8',
    },
    items: {
      fontSize: '12px',
      color: '#606060',
      font: 'normal normal normal 12px/24px Montserrat',
      width: '100%',
      '&:hover': {
        backgroundColor: '#EBF2FF',
      }
    },
    itemDiv: (props: any) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '29px',
      cursor: props.cursor
    }),
    typography: (props: any) => ({
      cursor: props.cursor
    }),
    iconTypography: (props: any) => ({
      display: 'flex',
      width: '16px',
      marginRight: '10px',
      paddingLeft: '5px',
      cursor: props.cursor,
    }),
    icon: (props: any) => ({
      fontSize: '18px',
      color: '#606060',
      cursor: props.cursor,
    })
  })
);


export default function FileOptionsPopper(props: any) {
  const { onClose, task, updateTask, updateTaskInCache, tasks, allTasks, setTasks, sourceType, anchorEl, selectedReconFile, updatePrimaryDisplaySkeletons, updateSecondaryDisplaySkeletons, updateTaskStatus } = props;
  const open = Boolean(anchorEl);

  const state = useStateContext();
  const dispatch = useDispatchContext();

  const styleProps = { cursor: state.currentUser.isExternalUser ? 'not-allowed' : 'pointer' };
  const classes = useStyles(styleProps);


  const setFilters = useSetFilters();
  const fileUploadWorker = useFileUploadWorker();

  const [reconFileToRemoveParams, setReconFileIdToRemove] = useState<Object | null>(null);
  const [removeReconFileMutation, removeReconFileMutationResponse] = useRemoveFileReconSupport(reconFileToRemoveParams);

  const [reconFileToDeleteParams, setReconFileIdToDelete] = useState<Object | null>(null);
  const [deleteReconSourceFileMutation, deleteReconSourceFileMutationResponse] = useDeleteReconSourceFile(reconFileToDeleteParams);

  const [taskIds, setTaskIds] = useState<Object | null>(null);
  const [getTasks, { loading: tasksLoading, data: tasksData, error: tasksError }] = useLazyGetTasksById({ taskIds });

  const inputFile = useRef(null);

  //Snackbar state
  const [isSnackbarOpen, setIsSnackBarOpen] = useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = useState<Color>("info");
  const [alertMessage, setAlertMessage] = useState<string>("");

  const handleSelectAssociatedTasks = () => {

    const associatedTaskIds = selectedReconFile?.associatedTaskIds;

    setFilters({
      customTaskPageSelectedTaskIds: associatedTaskIds
    })
  }

  const handleRemoveAsSupportClick = () => {
    if (state.currentUser.isExternalUser) { return; }
    setReconFileIdToRemove({
      fileReconSourceId: selectedReconFile.id,
      taskId: task.id
    })
    //Probably disable some things until the response
  }

  const handleDeleteFileClick = () => {
    if (state.currentUser.isExternalUser) { return; }
    setReconFileIdToDelete({
      fileVersionId: selectedReconFile.fileVersion.id,
      taskId: task.id
    })

    //Probably disable some things until the response
  }

  const handleUpdateFileVersionClick = () => {
    if (state.currentUser.isExternalUser) { return; }
    const fileInput: any = idx(inputFile, inputFile => inputFile.current);
    if (fileInput) fileInput.click();
  }

  const updateFile = async (e: any) => {

    const formattedFiles: FileUploadProperties[] = [
      {
        file: e.target.files[0],
        taskId: task.id,
        sourceType: sourceType,
        fileVersionId: selectedReconFile.fileVersion.id
      }
    ]

    const fileUploadWorkerProperties: FileUploadWorkerProperties = {
      files: formattedFiles,
      uploadType: 'task',
      selectedTask: task,
      updateTask: updateTaskInCache, //not sure about this
      updateTaskStatus: updateTaskStatus,
    }

    fileUploadWorker(fileUploadWorkerProperties);

    onClose()
  }

  // ---------- REMOVE AS SUPPORT ----------
  useEffect(() => {
    if (reconFileToRemoveParams) {
      removeReconFileMutation();
      if (sourceType === 'primary') {
        updatePrimaryDisplaySkeletons(true);
      } else {
        updateSecondaryDisplaySkeletons(true)
      }
    }
  }, [reconFileToRemoveParams])

  useEffect(() => {
    if (!removeReconFileMutationResponse.loading && removeReconFileMutationResponse.called && removeReconFileMutationResponse.data) {
      //Async code updates still needed
      const returnedData = removeReconFileMutationResponse.data as any;

      if (returnedData.removeFileReconSupport && returnedData.removeFileReconSupport.success) {
        const returnedTaskData = returnedData.removeFileReconSupport.task;

        updateTaskInCache({
          ...task,
          primaryTotal: returnedTaskData.primaryTotal,
          secondaryTotal: returnedTaskData.secondaryTotal,
          primaryFileReconSources: returnedTaskData.primaryFileReconSources,
          primaryFileReconSourcesTotal: returnedTaskData.primaryFileReconSourcesTotal,
          secondaryFileReconSources: returnedTaskData.secondaryFileReconSources,
          secondaryFileReconSourcesTotal: returnedTaskData.secondaryFileReconSourcesTotal,
          reconcilingItems: returnedTaskData.reconcilingItems,
          reconcilingItemTotal: returnedTaskData.reconcilingItemTotal,
          isReconciled: returnedTaskData.isReconciled,
          reconDifference: returnedTaskData.reconDifference
        });
        updateTask({
          ...task,
          primaryTotal: returnedTaskData.primaryTotal,
          secondaryTotal: returnedTaskData.secondaryTotal,
          primaryFileReconSources: returnedTaskData.primaryFileReconSources,
          primaryFileReconSourcesTotal: returnedTaskData.primaryFileReconSourcesTotal,
          secondaryFileReconSources: returnedTaskData.secondaryFileReconSources,
          secondaryFileReconSourcesTotal: returnedTaskData.secondaryFileReconSourcesTotal,
          reconcilingItems: returnedTaskData.reconcilingItems,
          reconcilingItemTotal: returnedTaskData.reconcilingItemTotal,
          isReconciled: returnedTaskData.isReconciled,
          reconDifference: returnedTaskData.reconDifference
        });
        
        if (sourceType === 'primary') {
          dispatch({
            type: 'SET_PRIMARY_TOTAL',
            primaryTotal: { balance: returnedTaskData.primaryTotal, task_id: task.id },
          });
        } else if (sourceType === 'secondary') {
          dispatch({
            type: 'SET_SECONDARY_TOTAL',
            secondaryTotal: { balance: returnedTaskData.secondaryTotal, task_id: task.id },
          });
        }
      }

      if (sourceType === 'primary') {
        updatePrimaryDisplaySkeletons(false);
      } else {
        updateSecondaryDisplaySkeletons(false)
      }

      setReconFileIdToRemove(null);
      onClose();
    }
  }, [removeReconFileMutationResponse.loading, removeReconFileMutationResponse.called, removeReconFileMutationResponse.data])


  // ---------- DELETE FILE ----------
  useEffect(() => {
    if (reconFileToDeleteParams) {
      deleteReconSourceFileMutation();
      if (sourceType === 'primary') {
        updatePrimaryDisplaySkeletons(true);
      } else {
        updateSecondaryDisplaySkeletons(true)
      }
    }
  }, [reconFileToDeleteParams])

  useEffect(() => {
    if (!deleteReconSourceFileMutationResponse.loading && deleteReconSourceFileMutationResponse.called && deleteReconSourceFileMutationResponse.data) {
      //Async code updates still needed
      const returnedData = deleteReconSourceFileMutationResponse.data as any;

      if (returnedData.deleteFileReconSource && returnedData.deleteFileReconSource.success) {
        //Need to update allTasks here
        const returnedTaskData = returnedData.deleteFileReconSource.tasks;

        //Copy all tasks
        const allTasks = tasks.slice();

        //Update all tasks with their new data
        returnedTaskData.forEach((task: any) => {
          const index = allTasks.findIndex((taskClone: any) => taskClone.id === task.id)
          if (index !== -1) {
            allTasks[index] = { ...allTasks[index], ...task }
            
            if (sourceType === 'primary') {
              dispatch({
                type: 'SET_PRIMARY_TOTAL',
                primaryTotal: { balance: allTasks[index].primaryTotal, task_id: task.id },
              });
            } else if (sourceType === 'secondary') {
              dispatch({
                type: 'SET_SECONDARY_TOTAL',
                secondaryTotal: { balance: allTasks[index].secondaryTotal, task_id: task.id },
              });
            }
          }
        })

        setTasks(allTasks);
      }

      if (sourceType === 'primary') {
        updatePrimaryDisplaySkeletons(false);
      } else {
        updateSecondaryDisplaySkeletons(false)
      }

      setReconFileIdToDelete(null);
      onClose()
    }
  }, [deleteReconSourceFileMutationResponse.loading, deleteReconSourceFileMutationResponse.called, deleteReconSourceFileMutationResponse.data])

  useEffect(() => {
    if (taskIds) {
      getTasks();
      setAlertSeverity("info");
      setAlertMessage("Fetching new support values")
    }
  }, [taskIds]);

  useEffect(() => {
    if (!tasksLoading && tasksData && !tasksError) {

      //Copy all tasks
      const allTasks = tasks.slice();

      //Update all tasks with their new data
      tasksData.tasks.forEach((task: any) => {
        const index = allTasks.findIndex((taskClone: any) => taskClone.id === task.id)
        if (index !== -1) {
          allTasks[index] = { ...allTasks[index], ...task }
        }
      })

      setTasks(allTasks);
      setIsSnackBarOpen(true);
      setAlertSeverity("success");
      setAlertMessage("New support values have been successfully retrieved")
    }

    if (tasksError) {
      setIsSnackBarOpen(true);
      setAlertSeverity("error");
      setAlertMessage("There was an error retrieving the update support values.")
    }
  }, [tasksLoading, tasksData, tasksError])

  const handleSnackbarClose = () => {
    setIsSnackBarOpen(false);
  }

  return (
    <>
      <Snackbar
        open={isSnackbarOpen}
        transitionDuration={1000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={alertSeverity}>{alertMessage}</Alert>
      </Snackbar>
      <Popper style={{ zIndex: 9999 }} anchorEl={anchorEl} open={open} placement='left-start'>
        <ClickAwayListener onClickAway={onClose}>
          <Paper className={classes.root} elevation={0} square>
            <div onClick={handleSelectAssociatedTasks} className={clsx(classes.items, classes.itemDiv)}>
              <Typography variant="h6" className={clsx(classes.items, classes.iconTypography)}>
                <CallSplitOutlinedIcon className={classes.icon} />
              </Typography>
              <Typography variant="h6" className={clsx(classes.items, classes.typography)}>
                Select associated tasks
              </Typography>
            </div>
            <div onClick={handleRemoveAsSupportClick} className={clsx(classes.items, classes.itemDiv)}>
              <Typography variant="h6" className={clsx(classes.items, classes.iconTypography)}>
                <CloseIcon className={classes.icon} />
              </Typography>
              <Typography variant="h6" className={clsx(classes.items, classes.typography)}>
                Remove as support
              </Typography>
            </div>
            <div onClick={handleDeleteFileClick} className={clsx(classes.items, classes.itemDiv)}>
              <Typography variant="h6" className={clsx(classes.items, classes.iconTypography)}>
                <DeleteIcon className={classes.icon} />
              </Typography>
              <Typography variant="h6" className={clsx(classes.items, classes.typography)}>
                Delete file
              </Typography>
            </div>
            <div onClick={handleUpdateFileVersionClick} className={clsx(classes.items, classes.itemDiv)}>
              <Typography variant="h6" className={clsx(classes.items, classes.iconTypography)}>
                <AutorenewIcon className={classes.icon} />
              </Typography>
              <Typography variant="h6" className={clsx(classes.items, classes.typography)}>
                Update file version
              </Typography>
            </div>
            <input
              onChange={updateFile}
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
