/* linted */
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import { parseISO } from 'date-fns'; 
import * as cs from '../../constants/theme';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { isString } from 'lodash';
import { convertDateTimeZoneInUniversalDate } from '../../helpers/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      '& input::placeholder': {
        color: '#AFAFAF',
        opacity: 1,
      },
    },
    date: (props: any) => ({
      marginTop: 0,
      marginBottom: 0,
      paddingLeft: 10,
      paddingRight: 10,
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
      cursor: props.cursor,

      '& .MuiIconButton-root': {
        padding: 0,
        cursor: props.cursor
      },
      '& input': {
        fontFamily: cs.FONT.family,
        color: '#606060',
        fontWeight: 500,
        fontSize: cs.FONT.size.xs,
        width: '80px',
        textTransform: 'none',
        cursor: props.cursor
      },
      '&:selected': {
        color: '#3A84FF',
      },
      '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: 'none',
      },
      '& .MuiInputBase-root.Mui-disabled': {
        cursor: props.cursor
      }
    })
  })
);

interface DatePickerProps {
  value: string;
  onChange: any;
  onClick?: any;
  disabled?: boolean;
  classes?: any;
  format?: string;
  InputAdornmentProps?: any;
  placeholder?: string;
  dataCyInput?: string;
  open?: boolean;
  onClose?: any;
  isExternalUser?: boolean;
}

/** The purpose of this component is to function as an input type=date
 *  It is a wrapper with our styling and defaults around:
 *  https://material-ui.com/components/pickers/
 */
function DatePicker(props: DatePickerProps) {
  const passedClasses = props.classes || {};
  const inputRef: any = useRef(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const styleProps = {
    cursor: props.isExternalUser ? 'not-allowed' : 'pointer'
  }
  
  const classes = useStyles(styleProps);

  useEffect(() => {
    if (props.open) setOpenDialog(props.open);
  }, [props.open])


  /** WIP: getting rid of this! Firefox doesn't like our default Date strings.  */
  /** The value prop to KeyboardDatePicker cannot be an empty string. 
   * Possible values of props.value - null, undefined, string
  */

  let safeValue: any;

  if (props.value) {
    safeValue = isString(props.value) && props.value === '' ? null : props.value;
  }else {
    safeValue = null;
  }

  useEffect(() => {
    safeValue = isString(props.value) && props.value === '' ? null : props.value;
  }, [props.value]);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  if (inputRef.current) {
    inputRef.current.addEventListener('click', props.onClick);
  }

  const onButtonClick = () => {
    !props.disabled && setOpenDialog(true);
  }

  const onClose = () => {
    setOpenDialog(false);
    props.onClose && props.onClose();
  }

  const onChange = (date: any) => {
    setOpenDialog(false);
    props.onChange(date);
  }

  const displayedDate = safeValue ? parseISO(convertDateTimeZoneInUniversalDate(safeValue)) : safeValue;

  return (
    <div
      data-cy={'date-picker-div'}
      onClick={e => e.nativeEvent.stopImmediatePropagation()}
      className={clsx(classes.container)}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          open={openDialog}
          InputAdornmentProps={props.InputAdornmentProps ? { ...props.InputAdornmentProps, onClick: onButtonClick } : { position: 'end', onClick: onButtonClick }}
          KeyboardButtonProps={{
            style: {
              color: safeValue ? ' #606060' : '#AFAFAF',
            },
          }}
          onClose={onClose}
          className={clsx(classes.date, passedClasses)}
          disabled={props.disabled} // TODO: do we use this id anywhere?
          format={props.format || 'MM/dd/yyyy'}
          id="date-picker-inline"
          margin="normal"
          onChange={onChange}
          onClick={props.onClick}
          placeholder={props.placeholder}
          value={displayedDate}
          inputProps={{
            onKeyDown: handleKeyDown,
            ref: inputRef,
            'data-cy': props.dataCyInput,
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

export { DatePicker };
