import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getCSRF } from '../../helpers/helpers';
import idx from 'idx';
import { Typography, Button, Dialog, DialogActions, DialogTitle, Tooltip } from '@material-ui/core';
import { useGlobalStyles } from '../common/Style';
import { DatePicker } from '../common/DatePicker';
import { MoneyDisplay } from '../common/MoneyDisplay';
import Dropdown from '../common/Dropdown';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircularProgress from "@material-ui/core/CircularProgress";

const newDate = new Date(new Date().toISOString().slice(0, 10));

export function SlValueSelection(props: any) {
  const projectReconEnd = idx(props, (p: any) => p.project.reconEndDate && new Date(p.project.reconEndDate));
  const levels = [
    'Location',
    'GL Account',
    'Transaction Type (optional)',
    'Period',
    //'Journal Entry Type (optional)'
  ];
  const taskSource = props.task[`${props.sourceType}Source`];
  const [state, setState] = useState<any>({
    'Location': idx(taskSource, (t: any) => t.integrationInfo.companyId),
    'GL Account': idx(taskSource, (t: any) => t.integrationInfo.ledgerId),
    'Transaction Type (optional)': idx(taskSource, (t: any) => t.integrationInfo.transactionType),
    'Period Adjustment (optional)': idx(taskSource, (t: any) => t.integrationInfo.period),
    //'Journal Entry Type (optional)': idx(taskSource, (t: any) => t.integrationInfo.ledgerId),
    startDate: idx(taskSource,(t:any) => t.integrationInfo.slStartDate) || new Date(2000, 0, 1),
    endDate: idx(taskSource,(t:any) => t.integrationInfo.slEndDate) || projectReconEnd || newDate
  });
  const [opts, setOpts] = useState<any>({}); 
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const classes = useGlobalStyles();

  /** update the state only when the modal opens */
  useEffect(() => {
    props.open && setState({
    'Location': idx(taskSource, (t: any) => t.integrationInfo.companyId),
    'GL Account': idx(taskSource, (t: any) => t.integrationInfo.ledgerId),
    'Transaction Type (optional)': idx(taskSource, (t: any) => t.integrationInfo.transactionType),
    'Period Adjustment (optional)': idx(taskSource, (t: any) => t.integrationInfo.period),
    //'Journal Entry Type (optional)': idx(taskSource, (t: any) => t.integrationInfo.ledgerId),
    balance: idx(taskSource, (t: any) => t.value),
    startDate: idx(taskSource,(t:any) => t.integrationInfo.slStartDate) || new Date(2000, 0, 1),
    endDate: idx(taskSource,(t:any) => t.integrationInfo.slEndDate) || projectReconEnd || newDate
  })}, [props.task.id, props.sourceType, props.open]);

  // calling the endpoint only if modal is open
  useEffect(() => {
    props.open && 
    axios.get('/connector/sl/menuitems', {headers: {'X-CSRF-TOKEN': getCSRF()}})
      .then((r: any) => {
        var ledgers_tmp = idx(r, (r: any) => JSON.parse(r.data.ledgers.replace(/=>/g, ':').replace(/nil/g, 'null')).data)
        var temps = idx(r, (r: any) => JSON.parse(r.data.locations.replace(/=>/g, ':').replace(/nil/g, 'null')).data)

        // account/ledger menu items should show as " <id> - <name> " 
        var ledgers = [];
        for (let i = 0; i < ledgers_tmp.length; ++i) {
          ledgers[i] = ledgers_tmp[i]
          ledgers_tmp[i].name = ledgers_tmp[i]._id.toString() + " - " + ledgers_tmp[i].name.toString()
        }

        for (let i = 0; i < temps.length; ++i) {
          if (temps[i].id === "0") {
            temps[i].name = temps[i].name + ' (Consolidated)'  
          } 
        }

        const entities = temps;
        
        setOpts({
          ...opts,
          /* 'Entity': [{name: 'All', id: 'all'}, ...(entities.map((e: any) => ({id: e._id, name: e.name})))], */
          'Location': entities.map((e: any) => ({id: e._id.toString(), name: e.name})),
          'GL Account': ledgers.map((l: any) => ({id: l._id.toString(), name: l.name})),
          'Transaction Type (optional)': [
                                         {name: 'Posted Transactions (default)', id: 'postedt'}, 
                                         {name: 'Draft Transactions', id: 'draftt'}, 
                                         {name: 'All Transactions (posted + draft)', id: 'allt'},
                                         {name: 'Posted Journal Entries', id: 'postedj'},
                                         {name: 'Draft Journal Entries', id: 'draftj'},
                                         {name: 'All Journal Entries (posted + draft)', id: 'allj'},
                                         ],
          'Period Adjustment (optional)': [{name: 'None (Ending Balance)', id: 'monthend'}, {name: 'Prior Month', id: 'monthprior'}],
          //'Journal Entry Type (optional)': [{name: 'All', id: 'all'}, {name: 'Standard', id: 'standard'}, {name: 'Reversing', id: 'reversing'}],
        });
      })
      .catch((e: any) => console.error(e));
  }, [props.task.id, props.open])



  //useEffect(() => { updateBalance('a', 'a'); }, [state.startDate.toISOString(), state.endDate.toISOString()])
  useEffect(() => { updateBalance('a', 'a'); }, [state.startDate, state.endDate])

  function updateBalance(selection: any, level: any) { 
    let location_id = state['Location'];
    
    // there's probably a better way to do this
    let account_id = -1
    if (level === 'GL Account') { account_id = selection; }
    else { account_id = state['GL Account'] }

    let posted = 'postedt';
    if (level === 'Transaction Type (optional)') { posted = selection; }
    else { 
      if (state['Transaction Type (optional)'])  { posted  = state['Transaction Type (optional)'] } 
      else { posted = 'postedt'; }
    }
    if (!posted) { posted = 'postedt' } 



    var enddate = null;
    var d = null;
    if (state.endDate) {
      d = new Date(state.endDate);
    }
    else if (props.project.reconEndDate) { 
      d = new Date(props.project.reconEndDate); } 
    else { 

      d = new Date(); 
    } 

    enddate = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()

    var startdate = '';
    var period = 'all';
    if (level === 'Period Adjustment (optional)') { period = selection; }
    else {
      if (state['Period Adjustment (optional)']) { period = state['Period Adjustment (optional)']; } 
    }

//    if (period === 'monthprior') { startdate = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + '01'; }
    if ( state.startDate ) {
      var x = new Date(state.startDate);
      startdate = x.getFullYear() + '-' + (x.getMonth() + 1) + '-' + x.getDate()
    }

    else { startdate = '1900-01-01'; } 
    let connect_url = '/connector/sl/balance?location_id=' + location_id + 
                      '&account_id=' + account_id +
                      '&posted=' + posted +
                      '&start_date=' + startdate + 
                      '&end_date=' + enddate

    axios.get(connect_url, {headers: {'X-CSRF-TOKEN': getCSRF()}})
      .then((r: any) => {
        if (r.data.balance) 
        {

        const bal = idx(r, (r: any) => r.data.balance);
          setState({
            ...state,
            //[level]: selection,
            balance: bal
          });
        }
      })
      .catch((e: any) => console.error(e));
  }

  return(
    <div>
      <Dialog
        open={props.open}
        onClose={props.denyAction}
        fullWidth={false}
        maxWidth = {'md'}
      >
        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '15px', paddingBottom: '15px', minWidth: '750px' }}>
          <DialogTitle>
            <Typography variant='h2' align='center'>
              {`Edit Reconciliation Source SoftLedger Connection`}
            </Typography>
          </DialogTitle>

          {levels.map((level: any, i: number) => 
            <LevelDropdown state={state} setState={setState} showSpinner={showSpinner} key={i} opts={opts} level={level} v={state[level] || level === 'Period' } set={(x: any) => {
              setState({...state, [level]: x})
              updateBalance(x, level);
            }}/>
          )}

          <div style={{display: 'flex', marginLeft: 120, marginTop: 30}}>
            <div style={{marginRight: 60}}>
              <Typography style={{marginTop: 10}}>
                 Project
              </Typography>
              <Typography style={{marginTop: 10}}>
                 Section 
              </Typography>
              <Typography style={{marginTop: 10}}>
                  Entity 
              </Typography>
              <Typography style={{marginTop: 10}}>
                 Task 
              </Typography>
              <Typography style={{marginTop: 10}}>
                 Report Period
              </Typography>
              <Typography style={{marginTop: 10}}>
                 Value
              </Typography>
            </div>
            <div>
              <Tooltip title={props.project.name} classes={{tooltip: classes.tooltipFont}}>
                <Typography style={{width: 268, marginTop: 10}} className={classes.ellipsis}>
                  {idx(props.project, p => p.name)}
                </Typography>
              </Tooltip>
              <Tooltip title={idx(props.task, t => t.section.name) || ''} classes={{tooltip: classes.tooltipFont}}>
                <Typography style={{width: 268, marginTop: 10}} className={classes.ellipsis}>
                  { idx(props.task, t => t.section.name) || 'No Section' }
                </Typography>
              </Tooltip>
              <Typography style={{width: 268, marginTop: 10}} className={classes.ellipsis}>
                { idx(props.task, t => t.tags.length)
                  ? props.task.tags.map((tag: any) => tag.tag )
                    : <Typography> None </Typography>
                }
              </Typography>
              <Tooltip title={props.task.name} classes={{tooltip: classes.tooltipFont}}>
                <Typography style={{width: 268, marginTop: 10}} className={classes.ellipsis}>
                  { idx(props.task, t => t.name) }
                </Typography>
              </Tooltip>
              <Typography style={{width: 268, marginTop: 10}} className={classes.ellipsis}>
                {
                  idx(props.project, p => p.reconEndDate)
                    ? new Date(props.project.reconEndDate).toLocaleDateString( undefined,
                        { year: 'numeric', month: 'short', day: 'numeric' }
                      )
                    : new Date().toLocaleDateString( undefined,
                        { year: 'numeric', month: 'short', day: 'numeric' }
                      )
                } 
              </Typography>
              <Typography style={{width: 268, marginTop: 10}} className={classes.ellipsis}>
                 { state['GL Account'] 
                    ? <>
                        {
                          Number(state.balance) ?
                            (
                              <MoneyDisplay
                                displaySymbol
                                style={{fontWeight: 500}} value={state.balance} customSymbol={props.task.currency}
                              />
                            ) : null
                        }
                    </> : null
                  }
              </Typography>
            </div>
          </div>
          
          <DialogActions>
            <Button onClick={props.denyAction} variant='outlined'>
              Cancel
            </Button>
            <Button variant='contained'
              onClick={() => props.confirmAction({
                // the higher level knows the task/id... etc.
                ...state
              })}
              autoFocus
            >
              Accept
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
 ) 
}

function PeriodAdjustmentField({setState, state}: any) {
  return(
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <DatePicker
        value={state.startDate}
        onChange={(d: any) => { 
          setState({...state, startDate: d});
        }}
      />
      <Typography component='span'>
        to
      </Typography>
      <DatePicker
        value={state.endDate}
        onChange={(d: any) => { 
          setState({...state, endDate: d});
        }}
      />
    </div>
  )
}

function LevelDropdown(props: any) {
  const classes = useGlobalStyles();

  return(
    <div className={classes.flexCenter} style={{marginBottom: 10}}>
    <div className={classes.flexCenter} style={{justifyContent: 'flex-start'}}>
      <div style={{width: 20, marginRight: 20}}>
        { props.showSpinner && props.level === 'Location'
          ? <CircularProgress size={'1.5rem'} />
          : <CircleCheckedFilled style={{color: 'green', opacity: props.v ? 1 : .1}}/>     
        }
      </div>
      <Typography style={{width: 200}}>{props.level}</Typography>
      <div style={{width: 400}} className={classes.dropdownContainer}>
      { props.level === 'Period' 
        ? <PeriodAdjustmentField 
            state={props.state}
            setState={props.setState}
          />
        : <Dropdown 
            selected={props.v}
            small
            options={props.opts[props.level]}
            handleUpdate={props.set}
            textLength={37}
          />
      }
      </div>
    </div>
    </div>
  )
}
