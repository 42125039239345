import { createQueryHook, gql } from '../graphqlHelpers';
import { useLazyQuery } from '@apollo/client';

const query = gql`
  query UserTagging($id: ID!) {
    task(id: $id) {
      id
      allOwners {
        ... on User {
				  id
				  displayName
				  profileUrl
					taskRelationship
          email
					roleInCurrentUserCompany
				}
				... on Team {
				  id
				  name
					taskRelationship
					users {
				  id
				  displayName
				  profileUrl
					taskRelationship
          email
					roleInCurrentUserCompany
				}
				}
      }
    }
  }`

export const useUserTagging = createQueryHook(query);

export const useLazyUserTagging = () => {
	return useLazyQuery(query);
}
