import { createQueryHook, gql } from '../graphqlHelpers';

const useListsFiles = createQueryHook(gql`
	query ListsFiles($listIds: [ID!]!) {
		 lists(listIds: $listIds) {
    id
		name
		estimatedEndDate
		retentionEnd
		retentionPolicy {
			id
			name
			duration
		}
    files {
      id
      fileLocation
      fileName
      alterable
      task {
        name
        section {
          name
        }
      }
    }
  }
	}
`);

export default useListsFiles;
