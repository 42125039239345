import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

interface CircularLoaderProps {
  height?: string;
  width?: string;
}

export default function  CircularLoader(props:  CircularLoaderProps) {
  const classes = useStyles();
  const { height, width } = props;

  return (
    <div style={{height: height, width: width}} className={classes.box}>
      <CircularProgress />
    </div>
  );
}

export const TaskCellSkeleton = () => <div className="self-center px-2 flex items-center justify-center w-full h-[10px] animate-pulse">
<div className="w-full h-full bg-gray-200"></div>
</div>
