import idx from 'idx';

export const isSuperAdmin = (role: string) => role === 'SuperAdmin';
export const isAnAdmin = (role: string) => role === 'Admin';
export const isOwner = (role: string) => role === 'Owner';
export const isManager = (role: string) => role === 'Manager';
export const isUser = (role: string) => role === 'User';

export const canBeAdmin = (role: string) =>
  isSuperAdmin(role) || isAnAdmin(role) || isOwner(role);

/* If the users's role is 3 or higher, can access certain features */
/* default to false */
function adminPlus(state: any) {
	var userCompanyRole = idx(state, state => state.currentUser.companyRole);

	return userCompanyRole <= 3;
}

const ADMIN_ROLES = [1, 2, 3, 8];

export function isAdmin( role: number | null ) {
	return ADMIN_ROLES.includes(Number(role));
}

export default adminPlus

