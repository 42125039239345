import React, {useState} from 'react';
import clsx from 'clsx';
import idx from 'idx';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import {
  ClickAwayListener,
  Paper,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';

import * as cs from '../../../../../../constants/theme';
import { truncate } from '../../../../../../helpers/helpers';
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
			display: 'flex',
      cursor: 'pointer',
      width: 'fit-content',
    },
    primary: {
      color: cs.COLORS.primary,
    },
		noWrap: {
			whiteSpace: 'nowrap'
		},
    hover: {
/*
      position: 'absolute',
      top: '-12px',
      left: '-6px',
      padding: '3px 6px',
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
      backgroundColor: cs.COLORS.primaryHover,
*/
//marginLeft: '12px',
//paddingLeft: '9px'
   },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    paper: {
      width: 'fit-content',
      position: 'absolute',
      border: '1px solid #D8D8D8',
      top: '17px',
      left: '-6px',
      zIndex: 2,
    },
    invisible: {
      //display: 'none',
			visibility: 'hidden'
    },
    mr12: {
      marginRight: '12px',
    },
    icon: {
      marginRight: '10px'
    },
  })
);

interface DropdownProps {
  value?: string;
  placeholder?: string;
  handleUpdate?: (value: string) => void;
	files?: any;
	setFileProperties?: any;
	fileProperties?: any;
	index?: any;
	short?: boolean;
}

export default function VersionDropdown(props: DropdownProps) {
  const {index, value, handleUpdate, setFileProperties, fileProperties} = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
//	const [files, setFiles] = useState<any[]>([]);

  const toggleMenu = () => setOpen(prev => !prev);

  const handleClick = (value: string) => {
    setOpen(prev => !prev);
		var fileProps = fileProperties.slice();
		if (index >= 0) fileProps[index].version = value;
		setFileProperties(fileProps);
    if (handleUpdate) handleUpdate(value);
  };

	const hasVersion = idx(props, props => props.files.find((o: any) => o.id == fileProperties[index].version))

  return (
<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '180px'}}>
		{ index >= 0 && fileProperties[index].version &&
            <div className={classes.flex} style={{marginRight: '12px'}}>
              <InsertDriveFileOutlinedIcon className={classes.icon} />
              <Typography className={classes.noWrap} variant="h6">{hasVersion ? truncate(hasVersion.fileName, props.short ? 12 : 24) : null}</Typography>
            </div>
		}
    <div
      className={classes.root}
      onMouseOver={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div className={clsx(open && classes.hover)} >
        <Typography
          variant="h6"
          className={clsx(classes.primary, classes.flex)}
        >
          Update
          {open && (
            <i className="fa fa-caret-down" style={{marginLeft: '12px'}}></i>
          )}
        </Typography>
      </div>
      <Paper
        className={clsx(classes.paper, !open && classes.invisible)}
        elevation={0}
        square
      >
				{ props.files && props.files.length ? (
        <List>
					{ props.files.map( (f:any, i: number) =>
          <ListItem onClick={() => handleClick(f.id)} key={i}>
            <div className={classes.flex}>
              <InsertDriveFileOutlinedIcon className={classes.icon} />
              <Typography className={classes.noWrap} variant="h6">{f.fileName}</Typography>
            </div>
          </ListItem>
				)}
        </List> ) : (
          <List>
            <ListItem >
            <div style={{width: '60px'}}>
              <Typography variant="h6" >No Files</Typography>
            </div>
            </ListItem>
          </List>
        )
				}
      </Paper>
    </div>
</div>
  );
}
