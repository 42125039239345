import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import ProjectOptionGroup from '../../common/ProjectOptionsGroup';
import { IProjectNumber, IProjectOptionsSelected } from '../../common/interfaces';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: '100%',
			width: '100%',
			overflow: 'auto',
			padding: '0px 50px',
		},
		flex: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
		t: {
			color: "#2C2C2C",
			opacity: 1,
			fontWeight: "normal",
		},
		t1: {
			fontSize: "22px",
			lineHeight: "27px",
		},
		t2: {
			fontSize: "16px",
			lineHeight: "19px",
		},
		margin: {
			margin: 10
		}
	})
);

interface IProjectsNumber {
	projectOptions: IProjectNumber,
	projectOptionsSelected: IProjectOptionsSelected;
	onOptionChanged(selectedOption: string): void;
}

export default function ProjectsNumber(props: IProjectsNumber) {
	const classes = useStyles();
	const { projectOptions, projectOptionsSelected, onOptionChanged } = props;

	return (
		<div className={classes.root}>
			<div className={classes.flex}>
				<Typography className={clsx(classes.t, classes.t1, classes.margin)}>How many projects are you creating?</Typography>
				<Typography className={clsx(classes.t, classes.t2, classes.margin)}>
					You can create one or multiple projects at a time. You will choose the type of project you're creating later.</Typography>
			</div>
			<ProjectOptionGroup projectOptions={projectOptions} onOptionChange={onOptionChanged} selectedValue={projectOptionsSelected.projectNumber} />
		</div>
	)
}