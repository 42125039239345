import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import { FONT, COLORS } from '../../constants/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
     '@global': {
        'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
       }
     },
     dropdownContainer: {
       '*::-webkit-scrollbar': {
         width: '0.6em',
         backgroundColor: '#EFEFEF'
       },
       '*::-webkit-scrollbar-thumb': {
         backgroundColor: '#D8D8D8',
         outline: '1px solid D8D8D8',

       },
       '*::-webkit-scrollbar-thumb:hover':{
         backgroundColor: '#2C2C2C',
         outline: '1px solid #2C2C2C',

       }
     },
    input: {
        border: 'none',
        background: 'none',
        fontFamily: FONT.family,
        fontSize: FONT.size.xs,
        fontWeight: 500,
        '&:hover': {
          border: 'none',
        },
        '&:focus': {
          border: 'none',
          outline: 'none',
        }
    },
    w180: {maxWidth: 140},
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    cool: {
      border: '1px solid transparent',
      '&:hover': {
        border: `1px solid ${COLORS.primary}`,
        background: 'none'
      }
    },
    difference: {
      textAlign: 'center',
      width: 120,
      fontWeight: 'bold',
      cursor: 'default'
    },
    taskSummary: {
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      borderBottom: '1px solid lightgrey',
    },
		hover: {
			backgroundColor: COLORS.primaryHover
		},
    flex: { display: 'flex' },
    flexColumnCenter: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    center: {
      alignItems: 'center',
      justifyContent: 'center'
    },
    capital: { textTransform: 'capitalize' },
    completeStatus: {
      color: 'dimgray',
      fontWeight: 500,
      width: '100px'
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    primarySource: {
      textAlign: 'center',
      border: '1px solid rgba(128, 128, 128, .3)',
      borderRight: 'none',
      borderBottom: '1px solid rgba(128, 128, 128, .3)',
      justifyContent: 'center',
      alignItems: 'center',
      height: 49
    },
    secondarySource: {
      textAlign: 'center',
      border: '1px solid rgba(128, 128, 128, .3)'
    },
    tooltipFont: {
      fontSize: '1em',
    },
    showIconOnHover: {
      '& svg': { visibility: 'hidden' },
      '&:hover': {
        '& svg': { visibility: 'visible' }
      }
    },
    leftAlign: {
      '& div': { justifyContent: 'flex-start'}
    },
    headerPointer: {
      cursor: 'pointer',
    },
    reconTableCell: {
      padding: '5px 16px',
      verticalAlign: 'top',
    },
    reconSettingsInputForm : {
      lineHeight: '21px',
      paddingTop: '9px'
    },
    reconSettingsDropdownStyle: {
      marginTop: '9px'
    },
    disabled: {
      backgroundColor: 'lightgray'
    }
  })
);
export const useGlobalStyles = useStyles;
