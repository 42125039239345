import {makeStyles, Typography, createStyles, Tooltip} from '@material-ui/core';
import idx from 'idx';
import useUpdateFileCheckout from '../../../../../../../graphql/mutations/UpdateFileCheckout';
import {GetApp} from '@material-ui/icons';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import {truncate} from 'lodash';
import React, {useState} from 'react';
import {NotificationText} from '../../../../../../common/NotificationText';
import * as cs from '../../../../../../../constants/theme';
import {useNotificationNavigation} from '../../../../../../../hooks/NotificationNavigation';
import UserImage from '../../../../../../common/UserImage';
import { FileIcon } from '../../../../../../common/FileIcon';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      background: 'none',
      height: '100%',
      overflow: 'auto',
    },
    grow: {
      flexGrow: 1,
      height: '100%',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: '12px',
    },
    modified: {
      opacity: 0.5,
      color: '#606060',
      marginRight: '40px',
    },
    download: {
      cursor: 'pointer',
      marginLeft: '12px',
      color: 'dimgray',
    },
    popper: {
      zIndex: 9999,
      position: 'absolute',
      width: 'fit-content',
      top: '45px',
      right: '12px',
      backgroundColor: '#FFFFFF',
      border: '2px solid #D8D8D8',
      borderRadius: '3px',
    },
    card: {
      width: 275,
      height: 275,
      boxShadow: 'none',
      top: '50%',
      left: '50%',
    },
    content: {
      position: 'absolute',
      top: '50%',
      transform: 'translate(0, -50%)',
      padding: 0,
    },
    text: {
      fontFamily: cs.FONT.family,
      fontSize: '12px',
      fontWeight: 500,
    },
    fileBorder: {
      border: '3px dashed #D8D8D8',
      padding: 10,
      height: 'calc(100% - 50px)',
      paddingBottom: '175px',
    },
    downloadAllFiles: {
      minWidth: '120px',
      '&:hover': {
        cursor: 'pointer',
        margin: '0 2px',
        backgroundColor: '#FFF',
        border: '1px solid #3A84FF',
        borderRadius: '3px',
      },
    },
    downloadAllDivider: {
      height: '0.5px',
      marginTop: '5px',
    },
    noHover: {
      '&:hover': {
        background: 'none',
      },
    },
  })
);

function TimeLineFile({notification}: any) {
  const [open, setOpen] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [toCheckIn, setToCheckIn] = useState<string>('');
  const classes = useStyles();

  const {name, description, createdAt, id} = notification;
  const notificationNavigation = useNotificationNavigation();
  const handleNotification = (notification: any) => {
    notificationNavigation(notification);
  };
  const [toCheckOut, setToCheckOut] = useState<string>('');

  const isCheckedOut = false;
  const [checkOutFile, checkOutRes] = useUpdateFileCheckout({
    fileVersionId: toCheckOut,
    isCheckedOut: true,
  });

  const [checkInFile, checkInRes] = useUpdateFileCheckout({
    fileVersionId: toCheckIn,
    isCheckedOut: false,
  });

  const cancelCheckout = (e: any) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    // setToCheckIn(row.id);
    setOpen(false);
  };

  function handleDownloadClick(e: React.MouseEvent) {
    e.stopPropagation();
    const notificationUrl = idx(notification, function(n: any) {
      return n.file.fileLocation;
    });

    if (notificationUrl) {
      window.open(notificationUrl);
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        height: '45px',
        paddingLeft: '16px',
        marginBottom: '24px',
      }}
      onClick={() => handleNotification(notification)}
    >
      <div
        style={{
          flex: 0.75,
          display: 'flex',
          justifyContent: 'flex-start',
          paddingTop: '2px',
        }}
      >
        <UserImage
          user={idx(notification, n => n.user)}
          style={{
            height: '25px',
            width: '25px',
            backgroundColor: 'rgba(42,137,75,0.80)',
          }}
        />
      </div>
      <Tooltip
        title={
          name.replace(/<\/?[^>]+(>|$)/g, '').length > 50
            ? name.replace(/<\/?[^>]+(>|$)/g, '')
            : ''
        }
        aria-label="task-name"
        style={{fontSize: '1em'}}
      >
        <div
          style={{
            flex: 8,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            textAlign: 'center',
            overflowX: 'hidden',
            paddingLeft: '10px',
          }}
        >
          <div style={{lineHeight: '16px'}}>
            <Typography
              style={{
                lineHeight: '16px',
                fontSize: '12px',
                color: '#606060',
                textAlign: 'left',
              }}
            >
              <NotificationText message={name} />
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <FileIcon
              fileName={description}
              style={{
                height: '20px',
                color: '#606060',
                marginRight: '5px',
                paddingBottom: '2px',
              }}
            />
            <div>
              <Typography
                style={{lineHeight: '16px', fontSize: '12px', color: '#606060'}}
                onClick={handleDownloadClick}
              >
                {truncate(description, {
                  length: 35,
                })}
              </Typography>
            </div>
          </div>
        </div>
      </Tooltip>
      <div
        style={{
          flex: 1.6,
          paddingRight: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Typography
          style={{
            fontSize: '12px',
            color: '#606060',
            lineHeight: '16px',
            whiteSpace: 'nowrap',
            textAlign: 'right',
          }}
        >
          {createdAt}
        </Typography>
        <div style={{display: 'flex', alignItems: 'flex-end'}}>
          <GetApp
            style={{color: 'dimgray', fontSize: '21px'}}
            onClick={handleDownloadClick}
            data-cy="timeline-file-download"
          />
        </div>
      </div>
    </div>
  );
}

export default TimeLineFile;
