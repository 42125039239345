import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    secondary: {
      color: "#606060",
      minWidth: "0px",
      paddingTop: "3px",
      flex: 1,
      display: "flex",
      alignItems: "center",
    },
    label: {
      marginTop: 6,
      minWidth: "80px",
    },
    tagLabel: {
      maxWidth: 248,
      marginBottom: 6,
    },
    entityContainer: {
      marginTop: "24px",
      paddingLeft: "16px",
      display: "flex",
      alignItems: 'flex-start'
    },
    tagLabelsContainer: {
      paddingLeft: "16px",

      display: "flex",
    },
    leftContainerLabels: {
      flex: 1,
    },
    rightContainerLabels: {
      flex: 3,
      display: "flex",
      flexWrap: "wrap",
    },
    postAddIcon: {
      fontSize: 21,
      marginRight: 4,
      marginTop: 6,
      color: "#AFAFAF",
    },
    rightContainer: {
      flex: 3,
    },
    inputContainer: {
      display: "flex"
    },
    inputColors: {
      "& .react-select__placeholder,svg.post-add": {
        color: "#606060 !important",
      },
    },
    selectContainer: {
      "& .react-select__control": {
        width: 300,
      },
    },
  })
);

const customStyles: any = {
  control: () => ({
    alignItems: "center",
    border: "none",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    position: "relative",
    transition: "all 100ms",
    boxSizing: "border-box",
    cursor: "pointer",
  }),
  placeholder: (base: any, state: any) => ({
    color: "#AFAFAF",
    position: "absolute",
    top: "45%",
    paddingLeft: 3,
    transform: "translateY(-40%)",
    display:
      state.isFocused || state.isSelected || state.selectProps.inputValue
        ? "none"
        : "block",
  }),
};

export { useStyles, customStyles };
