import { createMutationHook, gql } from '../graphqlHelpers';

export const useUpdateFileReconSourceValue = createMutationHook(gql`
  mutation UpdateFileReconSourceValue(
    $fileReconSourceId: ID!,
    $taskId: ID!,
    $value: Float!
  ) {
    updateFileReconSourceValue(
      fileReconSourceId: $fileReconSourceId
      taskId: $taskId
      value: $value
    ) {
      id
      task {
        currency
        primaryFileReconSources {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        primaryFileReconSourcesTotal
        secondaryFileReconSources {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        reconcilingItems {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
            alterable
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        secondaryFileReconSourcesTotal
        isReconciled
        primaryTotal
        secondaryTotal
        reconcilingItemTotal
        reconDifference
      }
      success
    }
  }
`);
