import React, {useState, useEffect} from 'react';
import idx from 'idx';

import { Header } from '../../common/Header';
import CreateTemplateStep from './components/CreateTemplateStep';
import CreateListStep from '../shared/CreateListStep';
import { useDispatchContext, useStateContext } from '../../../store';

import { useCopyList } from '../../../graphql/mutations/CopyList';
import { TemplateLists_templateLists } from '../../../graphql/queries/__generated__/TemplateLists';

export default function CopyListPage() {
  const [step, setStep] = useState<number>(0);
  const [selectedTemplate, setSelectedTemplate] = useState<
    TemplateLists_templateLists
    >({
    __typename: 'List',
    id: '',
    labelName: '',
    name: '',
    tasks: [],
  });
  const state = useStateContext();
  const [selectedList, setSelectedList] = useState<any>({tasks: []})

  const [newList, setNewList] = useState<any>({
    name: '',
    description: '',
    reconciliations: '',
    requester: {id: state.selectedCompany},
    scheduleType: null,
    userCompanyOwners: [{
      __typename: 'User',
      id: state.currentUser.id,
      email: state.currentUser.email,
      fullName: state.currentUser.fullName,
      profileUrl: state.currentUser.profileUrl,
    }]
  });

  //Trying to keep copy list similar to create project
  const filterList = () => {
    let finalList = JSON.parse(JSON.stringify(newList));

    if(!finalList.name){
      finalList.name = 'Empty Project Name'
    }

    return finalList;
  }
  const [copyList, copyListResponse] = useCopyList({
    listAttributes: filterList(),
    tasks: selectedList.tasks.map((t: any) => ({...t, section: t.section ? t.section.name : '', tags: t.tags.length ? t.tags.map((tag: any) => tag.tag) : [] }))
  })

  const updateSelectedList = (list: any) => {
    const listCopy = JSON.parse(JSON.stringify(list));
    {listCopy.tasks.map(function(task: any){
      task['owner'] = true;
      task['dueDateCopy'] = true;
      task['files'] = true;
      task['internalMessages'] = true;
      task['externalMessages'] = true;
      task['statusCopy'] = false;
    })}
    setNewList(
      {...newList,
        scheduleType: idx( listCopy, l => l.schedule.repeatType)
      }
    )
    setSelectedList(listCopy)
  }

  const handleDelete = (selected: any) => {
    const newTasks = selectedList.tasks.filter(
      (task:any, index: number) => {
        return selected.indexOf(index) === -1;
      }
    );
    setSelectedList({...selectedList, tasks: newTasks})
  };

  const setSelectedListTasks = (cool: any) => {
    var ok = Object.assign({}, selectedList)
    ok.tasks = cool;
    setSelectedList(ok);
  }

  return (
      <div>
        <Header step={step} setStep={setStep} />
        { step === 0 &&
        <CreateTemplateStep
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          setStep={setStep}
          selectedList={selectedList}
          handleDelete={handleDelete}
          updateSelectedList={updateSelectedList}
          setSelectedListTasks={setSelectedListTasks}
        />
       }
       { step === 1 &&
       <CreateListStep
          selectedTemplate={selectedList}
          newTemplate= {newList}
          setNewTemplate={ setNewList }
          onSubmit = { copyList }
          createListResponse = { copyListResponse }
          submitTitle={'Copy Project'}
          setStep={setStep}
          hideRecons={true}
        />
       }
      </div>
  );
}
