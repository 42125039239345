import { createQueryHook, gql } from '../graphqlHelpers';
import {
  CurrentUser,
  CurrentUser_currentUser_user
} from './__generated__/CurrentUser';

export type User = CurrentUser_currentUser_user;

export const useCurrentUser = createQueryHook<CurrentUser, {}>(gql`
  query CurrentUser {
    currentUser {
      id
      user {
        id
        email
        fullName
        displayName
        profileUrl
        socialLoginProvider
        lastViewedCompanyId
        companyRole
        isExternalUser
        ownedCompanies {
          id
          name
        }
        filters {
          listPageSelectedLists
          listPagePane
          listPageGroupBy
          taskPageLists
          taskPageTags
          taskPageModifiedSort
          taskPageAssigneeType
          taskPageDueDateSelected
          taskPageDueDateSort
          taskPageNameSort
          taskPageName
          taskPageId
          taskPageDateSort
          taskPageIdSort
          nexddFilter {
            selectedSpaceId
            selectedDashboardId
            selectedFolderId
            selectedReportId
            selectedProjectId
            selectedItemParentId
          }
          nexddHomePaneOrder
          taskPageAssignees {
            id
            __typename
          }
          taskPageOwners {
            id
            __typename
          }
          taskPageReviewers {
            id
            __typename
          }
          taskPagePane
          taskPagePriority
          taskPageSection
          taskPageMessages
          taskPageFiles
          taskPageSelectedTasks
          taskPageStatus
          taskPageRecons
          taskPageReconSort
          taskPageView
          taskPageGroupBySort
          taskPagePrimarySort
          taskPageSecondarySort
          taskPageDifferenceSort
          taskPageDifferencesFilter
          taskPageSelectedOtherOptions
          taskPageMyTasks
          taskPageCustomViewFields
          taskPageCustomViewOptions
          customTaskPagePriorityFilter
          customTaskPageNameAndEntityFilter
          customTaskPageNameFilter
          customTaskPageEntityFilter
          customTaskPageClassificationFilter
          customTaskPageStatusFilter
          customTaskPageAssigneesFilter
          customTaskPageOwnersFilter
          customTaskPageReviewersFilter
          customTaskPageDueDateFilter
          customTaskPageStartDateFilter
          customTaskPageMessagesFilter
          customTaskPageFilesFilter
          customTaskPageIsReconciledFilter
          customTaskPagePrimaryValueFilter
          customTaskPageSecondaryValueFilter
          customTaskPageReconcilingItemValueFilter
          customTaskPageDifferenceFilter
          customTaskPageIdFilter
          customTaskPageCompanyNameFilter
          customTaskPageProjectNameFilter
          customTaskPageSectionNameFilter
          customTaskPageExternalPartyFilter
          customTaskPageShowCompletedTasks
          customTaskPageSelectedTaskIds
          customTaskPageMyTasks
          customTaskPageListFieldsChanged
          customTaskPageReconciliationFieldsChanged
          customTaskPageTaskFieldsChanged
        }
        allCompanies {
          id
          reviewType
          date
          name
          logoUrl
        }
        companies {
          id
          name
          logoUrl
        }
        teams {
          id
          companyId
          name
        }
        roles {
          id
          name
          companyId
        }
        emailPreferenceCompany{
          id
          name
        }
        notifications {
          id
          name
          description
          url
          createdAt
          isAutomation
          readAt
          actionType
          notifiableId
          notifiableType
            notifiable {
              ...on Task {
                  listId
              }
            }
          pane
        }
        notificationFrequency{
          time
          timeZone
          frequency
          day
        }
        notificationScope{
          newListEmail
          newListInApp
          listArchivedEmail
          listArchivedInApp
          newTaskInListEmail
          newTaskInListInApp
          taskArchivedEmail
          taskArchivedInApp
          taskAssignmentEmail
          taskAssignmentInApp
          taskStatusEmail
          taskStatusInApp
          taskReadyEmail
          taskReadyInApp
          fileUploadEmail
          fileUploadInApp
          fileDeleteEmail
          fileDeleteInApp
          newInternalMessageEmail
          newInternalMessageInApp
          newExternalMessageEmail
          newExternalMessageInApp
          listUpdateEmail
          listUpdateInApp
          changeListOwnersEmail
          changeListOwnersInApp
          taskUpdateEmail
          taskUpdateInApp
          userAcceptedInvitationEmail
          userAcceptedInvitationInApp
          taskDependencyResolvedEmail
          taskDependencyResolvedInApp
          taskReconEmail
          taskReconInApp
          taskEntityEmail
          taskEntityInApp
          taskClassificationEmail
          taskClassificationInApp
        }
        companyModules{
          name
          url
        }
        workspace{
          id
          name
          description
        }
        currentCompany {
          id
          name
          oktaIntegrations {
            id
            domain
            clientId
            audience
            domainNames
            companyId
            isActive
            itype
            samlIdpCert
            samlIdpSsoServiceUrl
            samlIssuer
            samlTenantKey
          }
        }
      }
    }
  }
`);
