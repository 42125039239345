import React, { useState } from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { slice } from 'lodash';
import { SearchCompanyUsers_searchCompanyUsers_teams, SearchCompanyUsers_searchCompanyUsers_users } from '../../../../../helpers/__generated__/SearchCompanyUsers';
import NameLabel from '../../../../common/NameLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    showMoreButton: {
      color: '#606060',
      border: '1px solid #D8D8D8',
      backgroundColor: '#FFFFFF',
      borderRadius: '3px',
      fontSize: '12px',
      padding: '0px',
      marginBottom: '0px',
      marginRight: '6px',
      zIndex: 1,
      height: '26px',
      minWidth: '26px',
      '& span': {
        marginTop: '1px',
      },
      '&:hover': {
        border: '1px solid #3A84FF;',
        backgroundColor: '#FFFFFF',
      },
    },
  })
);

interface RenderOwnersRowProps {
  owners: any;
  handleRemoveOwnerClick: any;
  truncate?: number;
  style?: any
}

export default function RenderOwnersRow(props: RenderOwnersRowProps) {
  const classes = useStyles();
  const { truncate, owners, handleRemoveOwnerClick } = props;

  const isOverSize = truncate ? owners.length > truncate : false;
  const [showMore, setShowMore] = useState(true);
  const renderOwners = truncate
    ? !showMore
      ? owners
      : slice(owners, 0, truncate)
    : owners;

  return (
    <>
      {renderOwners &&
        renderOwners.map((owner: SearchCompanyUsers_searchCompanyUsers_users | SearchCompanyUsers_searchCompanyUsers_teams | any, index: number) => {
          return owner.__typename === 'User' ? (
            <NameLabel
              key={index}
              type="user"
              style={props.style}
              label={owner.fullName || owner.email}
              logo={owner.profileUrl as string}
              labelStyle={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              truncate={25}
              onClose={() => handleRemoveOwnerClick(owner)}
            />
          ) : (
            <NameLabel
              key={index}
              type="user"
              style={props.style}
              label={owner.name || owner.email}
              labelStyle={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              truncate={25}
              onClose={() => handleRemoveOwnerClick(owner)}
            />
          );
        }
        )}
      {!!truncate && isOverSize && (
        <div className={classes.flex} style={{ height: 26 }} data-cy='show-more'>
          {showMore ? (
            <Button className={classes.showMoreButton} onClick={() => { setShowMore(prev => !prev); }}>
              +{owners.length - truncate}
            </Button>
          ) : (
            <Button className={classes.showMoreButton} onClick={() => { setShowMore(prev => !prev); }}>
              -
            </Button>
          )}
        </div>
      )}
    </>
  );
}