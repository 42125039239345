import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import clsx from 'clsx';
import idx from 'idx';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import {
  ClickAwayListener,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TextField,
  Tooltip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';

import InputForm from '../../../../common/ListInputForm';
import PriorityForm from '../../../../common/PriorityForm';
import PriorityInputForm from '../../../../common/PriorityInputForm';
import CommonDropdown from '../../../../common/Dropdown';
import { DatePicker } from '../../../../common/DatePicker';
import CheckBox from './components/CheckBox';

import * as cs from '../../../../../constants/theme';

import useDeleteFiles from '../../../../../graphql/mutations/DeleteFiles';
import useArchiveFiles from '../../../../../graphql/mutations/ArchiveFiles';

interface UserList {
  id: string;
  name: string;
  tasks: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      height: 'calc(100vh - 144px)',
      padding: '0px 50px 0px 50px',
      borderBottom: '1px solid #D8D8D8',
      overflow: 'auto',
    },
    footer: {
      height: '60px',
      padding: '0px calc((100% - 1380px) / 2) 0px calc((100% - 1380px) / 2)',
    },
    title: {
      height: '42px',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    grow: {
      flexGrow: 1,
    },
    selection: {
      padding: '6px 12px',
      color: cs.COLORS.primary,
      border: `2px solid ${cs.COLORS.primary}`,
      borderRadius: '3px',
      marginRight: '12px',
    },
    content: {
      display: 'flex',
      marginTop: '10px',
      height: 'calc(100% - 100px)',
    },
    textFlow: {
      display: 'inline-block',
			cursor: 'default',
      width: 'fit-content',
      maxWidth: 'calc(80%)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: 'rgba(0, 0, 0, 0.54)',
			fontWeight: 400
    },
    stickyHeader: {
      position: 'sticky',
      top: '0px',
      backgroundColor: '#FFFFFF',
      zIndex: 1,
    },
    doubleStickyHeader: {
      position: 'sticky',
      top: '0px',
      backgroundColor: '#FFFFFF',
      zIndex: 1,
      width: 60,
      padding: 0,
    },
    doubleFirstStickyHeader: {
      position: 'sticky',
      top: '0px',
      backgroundColor: '#FFFFFF',
      zIndex: 1,
      width: 60,
      borderLeft: '1px solid rgb(224, 224, 224)',
      padding: 0,
      borderRadius: 3
    },
    doubleLastStickyHeader: {
      position: 'sticky',
      top: '0px',
      backgroundColor: '#FFFFFF',
      zIndex: 1,
      width: 60,
      borderRight: '1px solid rgb(224, 224, 224)',
      paddingRight: '0 !important',
      borderRadius: 3
    },
    topHeader: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    topCheckHeader: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      borderTop: '1px solid rgb(224, 224, 224)',
      paddingTop: 10
    },
    bottomHeader: {
      height: '50px',
      display: 'flex',
      alignItems: 'center'
    },
    bottomCheckHeader: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    input: {
      display: 'block',
      width: '100%',
      marginTop: '6px',
      color: '#606060',
      fontFamily: cs.FONT.family,
      fontWeight: cs.FONT.weight.regular,
      fontSize: cs.FONT.size.xs,
      textTransform: 'none',
      border: 'none',
      '& label': {
        color: '#606060',
        fontFamily: cs.FONT.family,
        fontWeight: cs.FONT.weight.regular,
        fontSize: cs.FONT.size.xs,
      },
      '&:selected': {
        color: '#3A84FF',
      },
      '& input::placeholder': {
        fontSize: '12px',
      },
      '& div': {
        width: '100%',
      },
      '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: 'none',
      },
    },
    checkCell: {
      width: 60,
      padding: '0 0 0 0px',
    },
    flexCenter: {
      display: 'flex',
      justifyContent: 'center'
    },
    firstCellBody: {
      width: 60,
      padding: '0 0 0 0px',
      borderLeft: '1px solid rgb(224, 224, 224)'
    },
    lastCellBody: {
      width: 60,
      paddingRight: 0,
      borderRight: '1px solid rgb(224, 224, 224)'
    },
    deleteIcon: {
      cursor: 'pointer',
    },
    tooltipFont: {
      fontSize: '1em'
    }
  })
);

const fileReviewTable = (props: any) => {
  const {
		lists,
    updateList,
		files,
		history
  } = props;
  const classes = useStyles();

	const [ selectedArchive, setSelectedArchive ] = useState([]);
	const [ selectedDelete, setSelectedDelete ] = useState([]);
	const [ archiveFiles, archiveFilesResponse ] = useArchiveFiles({fileIds: selectedArchive})
	const [ deleteFiles, deleteFilesResponse ] = useDeleteFiles({fileIds: selectedDelete})
	const [ filesToArchive, setFilesToArchive ] = useState<any>([]);
	const [ filesToDelete, setFilesToDelete ] = useState<any>([]);

	useEffect(() => {
		if (files && files.length) {
			setFilesToArchive(files.map((file: any) => file.id))
		}
	}, [files])

  const handleOneItemClick = (event: React.MouseEvent<unknown>, id: any, type: string) => {
		var archive = JSON.parse(JSON.stringify(filesToArchive));
		var toDelete = JSON.parse(JSON.stringify(filesToDelete));
		if ( type == 'archive') {
			archive.push(id);
			setFilesToArchive(archive);
			setFilesToDelete(filesToDelete.filter((a:any) => a != id));
		} else {
			toDelete.push(id)
			setFilesToDelete(toDelete);
			var ok = filesToArchive.filter((a: any) =>  a != id)
			setFilesToArchive(ok);
		}

  };

  const handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    // if (event.target.checked && selectedList && selectedList.tasks) {
    //   const newSelecteds = selectedList.tasks.map(
    //     (tasks: AllTemplates_templateLists_tasks, index: number) => index
    //   );
    //   setSelected(newSelecteds);
    //   return;
    // }
    // setSelected([]);
  };

  const handleGlobalItemClick = (event: any, type: string) => {
		if (type === 'delete') {
			setFilesToArchive([]);
			setFilesToDelete(files.map((f:any) => f.id));
		} else {
			setFilesToDelete([]);
			setFilesToArchive(files.map((f:any) => f.id));
		}
  };

  const renderCheckbox = () => {

    return (
      <CheckBox
        checkedValue={false}
        onChange={handleSelectAllClick}
      />
    );
  };

	const handleUpdate = () => {
		archiveFiles();
		deleteFiles();
		props.archiveLists();
		history.goBack();
	};

	const multipleLists = lists && lists.length > 1

	// The only time we're making this available is for the single list case
	const updateRetentionPolicy = (id: any) => {
		updateList({
			id: lists[0].id,
			retentionPolicyId: id
		})
	}

	const updateRetentionPolicyMultiple = (listId: any, policyId: any) => {
		updateList({
			id: listId,
			retentionPolicyId: policyId
		})
	}

  return (
    <div>
      <div className={classes.body}>
    <div className={clsx(classes.flex, classes.title)}>
      { lists && lists.length === 1 &&
			<div style={{display: 'flex', alignItems: 'center'}}>
			<Typography variant="h2">{lists[0].name} </Typography>
      <Typography variant="h2" style={{fontWeight: 300, paddingLeft: 5}}>|</Typography>
      <Typography variant="h6" style={{paddingLeft: '8px', paddingTop: 5, alignSelf: 'center'}}> Retention Policy </Typography>
			<div style={{paddingTop: 8}}>
			<CommonDropdown
				options={props.policies}
				selected={lists[0].retentionPolicy && lists[0].retentionPolicy.id}
				overrideValue={lists[0].retentionPolicy && lists[0].retentionPolicy.name}
				handleUpdate={updateRetentionPolicy}
				small
        manage
			/>
			</div>
      { false && <Typography variant="h6" style={{paddingLeft: '8px', paddingTop: 5, alignSelf: 'center'}}> Policy Expiration </Typography> }
			</div>}
			{ lists && lists.length > 1 &&
				<Typography variant="h2">File Retention Review </Typography>
			}
    </div>
        <div className={classes.content}>
          <div style={{ overflow: 'auto' }}>
            <Table style={{tableLayout: 'fixed', borderCollapse: 'separate'}}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.stickyHeader}
                    style={{width: '100px'}}
                  >
                    <div className={classes.topHeader} />
                    <div className={classes.bottomHeader}>File Name</div>
                  </TableCell>
                  <TableCell
                    className={classes.stickyHeader}
                    style={{width: '100px'}}
                  >
                    <div className={classes.topHeader} />
                    <div className={classes.bottomHeader}>Section</div>
                  </TableCell>
                  <TableCell
                    className={classes.stickyHeader}
                    style={{width: '150px'}}
                  >
                    <div className={classes.topHeader} />
                    <div className={classes.bottomHeader}>Task Name</div>
                  </TableCell>
{ multipleLists &&
                  <TableCell
                    className={classes.stickyHeader}
                    style={{width: '100px'}}
                  >
                    <div className={classes.topHeader} />
                    <div className={classes.bottomHeader}>Project Name</div>
                  </TableCell> }
{ multipleLists &&
                  <TableCell
                    className={classes.stickyHeader}
                    style={{width: '100px'}}
                  >
                    <div className={classes.topHeader} />
                    <div className={classes.bottomHeader}>Retention Policy Name</div>
                  </TableCell> }

{ multipleLists &&
                  <TableCell
                    className={classes.stickyHeader}
                    style={{width: '80px'}}
                  >
                    <div className={classes.topHeader} />
                    <div className={classes.bottomHeader}>Retention Policy End</div>
                  </TableCell> }

                  <TableCell
                    padding="checkbox"
                    className={classes.doubleFirstStickyHeader}
                  >
                    <div className={classes.topCheckHeader}>Archive</div>
                    <div className={classes.bottomCheckHeader}>
											<CheckBox
												checkedValue={filesToArchive && files && filesToArchive.length == files.length}
												onChange={(event: React.MouseEvent<unknown>) =>
													handleGlobalItemClick(event, 'archive')
												}
											/>
                    </div>
                  </TableCell>
                  <TableCell
                    padding="checkbox"
                    className={classes.doubleLastStickyHeader}
                  >
                    <div className={classes.topCheckHeader}>Delete</div>
                    <div className={classes.bottomCheckHeader}>
											<CheckBox
												checkedValue={filesToDelete && files && filesToDelete.length == files.length}
												onChange={(event: React.MouseEvent<unknown>) =>
													handleGlobalItemClick(event, 'delete')
												}
											/>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
							{		props.files && props.files.length == 0 &&
								<div style={{paddingLeft: 14, marginTop: 14}}>
								<Typography>The Selected Project(s) have no files to display</Typography>
								</div>
							}
                {
								//currentList && currentList.tasks &&
                //currentList.tasks.sort((t: any, t1: any) => Number(t.id) - Number(t1.id))
									props.files && props.files.length != 0 && props.files
                  .map(
                  (item: any, index: number) => {
                    // const isSelected = selected.indexOf(index) !== -1;
                    return (
                      <TableRow key={index}>
                        <TableCell>
                        <Tooltip title={item.name} aria-label="export" classes={{tooltip: classes.tooltipFont}}>
                          <Typography className={classes.textFlow}>
                              { item.name }
                          </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.textFlow}>
                            { item.taskSection ? item.taskSection.name : 'no section' }
                        </Typography>
                        </TableCell>
                        <TableCell>
                        <Tooltip title={item.taskName} aria-label="export" classes={{tooltip: classes.tooltipFont}}>
                          <Typography className={classes.textFlow}>
                            { item.taskName }
                          </Typography>
                          </Tooltip>
                        </TableCell>
                        { multipleLists && <TableCell>
                          <Tooltip title={item.listName} aria-label="export" classes={{tooltip: classes.tooltipFont}}>
                          <Typography className={classes.textFlow}>
                            { item.listName }
                          </Typography>
                          </Tooltip>
                        </TableCell> }
                        { multipleLists && <TableCell>
			<div id='offset-dropdown-padding' style={{marginLeft: -14}}>
			<CommonDropdown
				options={props.policies}
				selected={item.retentionPolicyId}
				overrideValue={item.retentionPolicy}
				handleUpdate={(policyId: any) => updateRetentionPolicyMultiple(item.listId, policyId)}
				small
				manage
			/>
			</div>
                        </TableCell> }
                        { multipleLists && <TableCell>
                          <DatePicker
                            disabled
                            value={item.date}
                            onChange={undefined}
                          />
                        </TableCell> }
                        <TableCell
                          className={classes.firstCellBody}
                        >
                          <div className={classes.flexCenter}>
                            <CheckBox
                              checkedValue={filesToArchive.includes(item.id)}
                              onClick={(e: React.MouseEvent<unknown>) =>
                                handleOneItemClick(e, item.id, 'archive')
                              }
                            />
                          </div>
                        </TableCell>

                        <TableCell
                          className={classes.lastCellBody}
                        >
                          <div className={classes.flexCenter}>
                            <CheckBox
                              checkedValue={filesToDelete.includes(item.id)}
                              onClick={(e: React.MouseEvent<unknown>) =>
                                handleOneItemClick(e, item.id, 'delete')
                              }
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>

            </Table>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div
          className={classes.flex}
          style={{paddingTop: '12px'}}
        >
          <div className={classes.grow} />
          <Button variant="contained" onClick={handleUpdate}>
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
}

export const FileReviewTable = withRouter(fileReviewTable)
