import {createQueryHook, gql} from '../graphqlHelpers';

export const getExternalFolders = gql`
  query GetExternalFolders($spaceId: ID){
    getExternalFolders(spaceId: $spaceId){
      id
      spaceId
      name
      description
      isDashboard
      url
      companyId
    }
  }`
;
