import React from "react";
import { useStyles } from "../Styles";
import { Box, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface ITitle {
  handleModalClose: () => void;
}

export const Title = (props: ITitle) => {
  const classes = useStyles();
  const { handleModalClose } = props;
  return (
    <Box className={classes.titleContainer}>
      <Typography className={classes.titleHeading}>
        New workspace or folder
      </Typography>
      <CloseIcon className={classes.closeIcon} onClick={handleModalClose} />
    </Box>
  );
};
