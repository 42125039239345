import React from "react";
import { addOpacityToHexColor, classNames, measureTextInPixel } from "../../helpers/helpers";

type ComponentProps = {
  label: any,
  canRemove?: boolean, 
  className?: string, 
  onRemoveSelectedTag?(e:any): void,
  truncate?: boolean
};

/** Component to display one custom label selected as badge
 * @param `props.label` - The component attribute that contains the label to display
 * @param `props.canRemove` - The component attribute to check if the tag can be removed
 * @param `props.className` - The component attribute that contains the root class name
 * @param `props.onRemoveSelectedTag` - The component function to call when an item has been removed
 * @param `props.truncate` - The component attribute to check if the label text should be truncated
 * @return {React.JSXElementConstructor} The output component.
 */
export default function MessageLabelTag(props: ComponentProps) {
  return (
    <div style={{backgroundColor: addOpacityToHexColor(props.label.color, 0.3), color: props.label.color}} className={classNames("flex rounded-sm p-1.5 mx-1 text-xs font-bold", props.className)}>
      <span className={classNames("cursor-default", props.truncate ? "max-w-[70px] truncate": "whitespace-nowrap")} title={props.truncate ? (measureTextInPixel(props.label.name, 12) > 60 ? props.label.name : "") : ""}>
        {props.label.name}
      </span>
      { props.canRemove &&
        <button
          type="button"
          style={{color: props.label.color}}
          onClick={() => props.onRemoveSelectedTag && props.onRemoveSelectedTag(props.label)}
          className="inline-flex -mr-[4px] h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:outline-none"
        >
          <span className="sr-only">Remove tag option</span>
          <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
          </svg>
        </button>
      }
    </div>
  );
}