import { gql } from '../graphqlHelpers';
import { RECURSIVE_MESSAGES_FRAGMENT, MESSAGE_FIELDS_FRAGMENT } from './RecursiveMessages';

export const DETAILED_TASK_INFO = gql`
  ${RECURSIVE_MESSAGES_FRAGMENT}
  ${MESSAGE_FIELDS_FRAGMENT}
  fragment DetailedTaskInfo on Task {
    id
    currency
    primaryLabel
    primarySourceType
    primaryValue
    secondaryValue
    isReconciled
    beginningBalanceChanged
    primarySource {
      id
      fileVersion {
        id
        fileName
        fileLocation
        taskId
        alterable
      }
      value
      integrationInfo {
        id
        companyId
        locationId
        ledgerId
        transactionType 
        period 
        slStartDate
        slEndDate

        ###########################
        #intacct stuff
        ###########################
        inEntities {
          label
          value 
        }
        inDepartments {
          label
          value 
        }
        inUnderliers {
          label
          value 
        }
        inAccounts {
          label
          value 
        }
        inServices {
          label
          value 
        }
        inCustomers {
          label
          value 
        }
        inVendors {
          label
          value 
        }
        inAccountingMethod {
          label
          value 
        }
        inStartDate
        inEndDate
        invert
        includeReportingBook
        startDateLocked
        endDateLocked
        
        ###########################
        #qbo stuff
        ###########################
        qboLocations {
          label
          value 
        }
        qboAccounts {
          label
          value 
        }
        qboNames {
          label
          value 
        }
        qboClasses {
          label
          value 
        }
        qboTypes {
          label
          value 
        }
        qboAccountingMethod {
          label
          value 
        }
        qboStartDate
        qboEndDate
        qboIntegration {
          label
          value
        }

        ######################
        #ns stuff
        ######################
        nsAccounts {
          label
          value
        }
        nsSubsidiaries{
          label
          value
        }
        nsLocations {
          label
          value
        }
        nsDepartments {
          label
          value
        }
        nsClasses {
          label
          value
        }
        nsItems {
          label
          value
        }
        nsEntities {
          label
          value
        }
        nsBooks {
          label
          value
        }
        nsStartDate
        nsEndDate
        currency
      }
    }
    secondaryLabel
    secondarySourceType
    secondaryValue
    secondarySource {
      id
      fileVersion {
        taskId
        id
        fileName
        fileLocation
        alterable
      }
      integrationInfo {
        id
        companyId
        locationId
        ledgerId
        transactionType 
        period 
        slStartDate
        slEndDate

        ###########################
        #intacct stuff
        ###########################
        inEntities {
          label
          value 
        }
        inDepartments {
          label
          value 
        }
        inUnderliers {
          label
          value 
        }
        inAccounts {
          label
          value 
        }
        inServices {
          label
          value 
        }
        inCustomers {
          label
          value 
        }
        inVendors {
          label
          value 
        }
        inAccountingMethod {
          label
          value 
        }
        inStartDate
        inEndDate
        invert
        includeReportingBook
        startDateLocked
        endDateLocked

        ###########################
        #qbo stuff
        ###########################
        qboLocations {
          label
          value 
        }
        qboAccounts {
          label
          value 
        }
        qboNames {
          label
          value 
        }
        qboClasses {
          label
          value 
        }
        qboTypes {
          label
          value 
        }
        qboAccountingMethod {
          label
          value 
        }
        qboStartDate
        qboEndDate
        qboIntegration {
          label
          value
        }

        ######################
        #ns stuff
        ######################
        nsAccounts {
          label
          value
        }
        nsSubsidiaries{
          label
          value
        }
        nsLocations {
          label
          value
        }
        nsDepartments {
          label
          value
        }
        nsClasses {
          label
          value
        }
        nsItems {
          label
          value
        }
        nsEntities {
          label
          value
        }
        nsBooks {
          label
          value
        }
        nsStartDate
        nsEndDate
        currency
      }
      value
    }
    schedule {
      id
      repeatType
      repeatNumber
      repeatInterval
      calendarType
      ends
      endsOn
      endsAfter
      selectedWeekday
      selectedMonthday
      selectedMonth
      selectedYearday
      selectedSecond
      nextRunningDate
    }
    relativeDueDate
    relativeStartDate
    modifiedAtText
    modifiedAtTime
    name
    description
    section {
        id
        name
     }
    processSteps {
      id
      taskId
      description
      position
      completed
      files {
        id
        fileName
        fileLocation
        viewed
        alterable
        updatedAt
      }
    }
    companyPosition
    priority
    status
    completedStatusKey
    hasMessages
    hasUnreadMessages
    hasFiles
    hasUnseenFiles
    startDate
    dueDate
    updatedAt
    reconciliations
    listNumber
    currentUserIsReviewer
    responderDelivered
    list {
      id
      multipleReviewers
      reconciliations
      rank
      currentUserIsOwner
      name
      deliverFileOnUpload
      schedule {
        id
        repeatType
        calendarType  
        repeatInterval
      }
      requesterCompany {
        id
        name
      }
      responderCompany {
        id
        name
      }
      estimatedStartDate
      estimatedEndDate
    }
    files {
      id
      fileName
      fileLocation
      viewed
      alterable
      updatedAt
      taskId
      createdAt
      isCheckedOut
      checkedOutBy{
        fullName
        displayName
      }
      checkedOutAt
      companyId
    }
    messages {
      ...RecursiveMessages
    }
    userOwners {
      id
      email
      fullName
      displayName
      profileUrl
    }
    teamOwners {
      id
      name
    }
    firstUserReviewers {
      id
      email
      fullName
      displayName
      profileUrl
    }
    firstTeamReviewers {
      id
      name
    }
    secondUserReviewers {
      id
      email
      fullName
      displayName
      profileUrl
    }
    secondTeamReviewers {
      id
      name
    }
    externalUserOwners {
      id
      email
      fullName
      displayName
      profileUrl
    }
    externalTeamOwners {
      id
      name
    }
    notifications {
      id
      name
      notifiable {
        ...on Task {
          listId
        }
      }
      file {
        id
        fileLocation
        fileName
        alterable
      }
      notifiableId
      actionType
      notifiableType
      pane
      readAt
      updatedAt
      url
      description
      createdAt
      isAutomation
      isEdited
      isDeleted
      user {
        id
        fullName
        displayName
        profileUrl
      }
      company {
        id
        name
      }
      messageParent
    }
    tags { 
      id 
      tag
      name
    }
    classifications {
      id
      name
    }
    isBlocked
    dependencies {
      id
      relatedId
      relatedName
      relatedDueDate
      taskId
      taskName
      taskDueDate
      dueDays
      dueHours
      hasStartDate
      status
      isDependent
      relatedListNumber
      taskListNumber
    }
    taskDataSummary {
      id
      primaryReconId
      primaryReconName
      primaryReconValue
      primarySourceType
      secondaryReconId
      secondaryReconName
      secondaryReconValue
      secondarySourceType
      primarySecondaryDifferenceValue
      primarySecondaryDifferencePercentage
      reconcilingItemId
      reconcilingItemName
      reconcilingItemValue
      reconcilingDifferenceValue
      reconcilingDifferencePercentage
      priorMonthId
      priorMonthValue
      priorMonthDifference
      priorMonthPercentage
      priorQuarterId
      priorQuarterValue
      priorQuarterDifference
      priorQuarterPercentage
      priorYearId
      priorYearValue
      priorYearDifference
      priorYearPercentage
      customAnalysisId
      customAnalysisValue
      customAnalysisDifference
      customAnalysisPercentage
      finalRecon
      isCurrent
      companyId
      listId
      taskId
      sectionId
      createdAt
      updatedAt
    }
    primaryFileReconSources {
      id
      sourceType
      fileVersion {
        id
        fileName
        fileLocation
        alterable
      }
      name
      isCurrent
      description
      prettyDate
      value
      associatedTaskIds
    }
    primaryFileReconSourcesTotal
    secondaryFileReconSources {
      id
      sourceType
      fileVersion {
        id
        fileName
        fileLocation
        alterable
      }
      name
      isCurrent
      description
      prettyDate
      value
      associatedTaskIds
    }
    secondaryFileReconSourcesTotal
    primaryTotal
    secondaryTotal
    reconcilingItemTotal
    reconDifference
    reconcilingItems {
      id
      sourceType
      fileVersion {
        id
        fileName
        fileLocation
        alterable
      }
      name
      isCurrent
      description
      prettyDate
      value
      associatedTaskIds
    }
    primaryExcelBatchIsProcessing
    secondaryExcelBatchIsProcessing
  }
`;
