export const COLORS = {
  background: '#FFFFFF',
  primary: '#3A84FF',
  primaryHover: '#EBF2FF',
  notification: '#6EB81D'
};

export const FONT = {
  color: '#2C2C2C',
	fontColor: '#606060',
  family: 'Montserrat',
  weight: {
    bold: 800,
    regular: 600,
    light: 500,
  },
  size: {
    xs: '12px',
    sm: '15px',
    md: '18px',
    lg: '24px',
    xl: '30px',
  },
};

export const TABLE_HEADER = {
  color: 'dimgray',
  family: 'Montserrat',
  weight: 600,
}

export const shared = {
  input: {
      border: 'none',
      background: 'none',
      fontFamily: FONT.family,
      fontSize: FONT.size.xs,
      fontWeight: 500,
      '&:hover': {
        border: 'none',
      },
      '&:focus': {
        border: 'none',
        outline: 'none',
      }
  }

};

/**
 * Colors palette for message labels
 * @returns {string[]} string[] - the range of colors to choose
 */
export const randomColors = ['#0E3B5C', '#184566', '#235b84', '#2572aa', '#0c67a8', '#1d4fa1', '#143a76', '#27477b', '#3166bb', '#0f3674'];