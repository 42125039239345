import { useLazyQuery, gql } from '@apollo/client';

const listLabelsQuery = gql`
  query ListLabels(
    $companyId: ID!, 
  ) {
    listLabels(
      companyId: $companyId
    ) {
      id
      name
      color
    }
  }
`

export const useLazyListLabels = (variables: any) => {
  return useLazyQuery(listLabelsQuery, {variables});
}