import React from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import idx from 'idx';

const useLocalStyles = makeStyles(() =>
  createStyles({
    headerContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 8,
      marginTop: 8,
      paddingLeft: 16,
      paddingRight: 16,
    },
    alertMessage: {
      backgroundColor: '#fff8e5',
      padding: '5px 0px 5px 18px',

    },
  })
);

type AlertMessageProps = {
  task: any;
};

/**
 * `AlertMessage` component reusable anywhere accross the app.
 * 
 * @return {React.JSXElementConstructor} The `AlertMessage` component.
 */
export default function AlertMessage(props: AlertMessageProps) {
  const { task } = props;

  const beginningBalanceChanged = idx(task, (task: any) => (task.beginningBalanceChanged));

  const localStyles = useLocalStyles();

  return (
    <>
      {beginningBalanceChanged ?
        (<div className={localStyles.alertMessage}>
          <Typography variant="body1">
            <b>Alert:</b> Your beginning balance has changed since the prior period
          </Typography>
        </div>)
        : (<div></div>)
      }
    </>
  )
}
