/* The purpose of this component is:
 */

import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle, Link, Typography } from '@material-ui/core';

export function SlEnable(props: any) {
  return(
    <div>
      { /* Instuctions on how to enable functionality*/ }
      <Dialog open={props.open}>
      
      <div style={{padding: 20}}>
          <DialogTitle>
            <Typography variant='h2'>
            {`Connect Your ${props.integrationType} Account`}
            </Typography>
          </DialogTitle>
      <div style={{padding: '16px 24px'}}>
{ props.integrationType !== 'QuickBooks Online' &&      <Typography>
      To enable the SoftLedger integration, please ensure that you have requested 
      and received your tenant UUID from SoftLedger before proceeding.
      </Typography> }
{props.integrationType === 'QuickBooks Online' && <Typography>Press 'Continue' to link your QuickBooks Online account</Typography>}
      <br />

    <Typography>
      Please contact our customer success team at <Link href='mailto:support@prepdd.com'>support@prepdd.com</Link> with any questions about configuring your integration
    </Typography>
    </div>
    </div>
        <DialogActions>
          <Button onClick={props.denyAction} variant='outlined'>
            Cancel
          </Button>
          <Button variant='contained' onClick={props.confirmAction} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
