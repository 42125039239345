import { createQueryHook, gql } from '../graphqlHelpers';

const retentionPolicies = createQueryHook(gql`
query RetentionPolicies($companyId: ID!) {
  retentionPolicies(companyId: $companyId) {
    id
    isDefault
    name
    company {
      id
      name
    }
    duration
    durationType
    policyStart
  }
}
`);

export default retentionPolicies;
