import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  IconButton,
  Typography,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import {CalendarToday, Close} from '@material-ui/icons';
import {useSetFilters} from '../../../../../../../hooks/SetFilters';

interface AlertModalProps {
  onCancel: any;
  onComplete: (_: any) => void;
  open: boolean;
  relatedTName?: any;
  relatedDDays?: any;
  relatedDHours?: any;
  relatedStartDue?: any;
  relatedListNo?: any;
  relatedTaskId?: any;
  relatedDueDate?: any;
  relatedId?: any;
  skinnyLists?: any;
  setSkinnyLists?: any;
}

export default function AlertModal(props: AlertModalProps) {
  const classes = useStyles();
  const setFilters = useSetFilters();
  // console.log('Open, props);
  // let finalArr = [...props.relatedTName, ...props.relatedListNo];
  // const finalArr = props.relatedTName.map((el: any, i: number) => [
  //   el,
  //   props.relatedListNo[i],
  // ]);

  let dependencyList = props.relatedTName.map(
    (name: any, index: React.ReactText) => ({
      name,
      listNo: props.relatedListNo[index],
      hours: props.relatedDHours[index],
      days: props.relatedDDays[index],
      relatedStartDue: props.relatedStartDue[index],
      relatedTaskId: props.relatedTaskId[index],
      relatedDueDate: props.relatedDueDate[index],
      relatedId: props.relatedId[index],
    })
  );

  function openTask(taskToOpen: any, taskNo: any) {
    let listToOpen = -1;

    // 1. determine list to which this task belongs
    for (let i = 0; i < props.skinnyLists.length; ++i) {
      for (let j = 0; j < props.skinnyLists[i].tasks.length; ++j) {
        if (props.skinnyLists[i].tasks[j].id == taskToOpen) {
          listToOpen = props.skinnyLists[i].id;
          break;
        }
      }
    }
    setFilters(
      {
        taskPageSelectedTasks: [taskToOpen.toString()],
        taskPageLists: [listToOpen.toString()],
        taskPagePane: 0,
      },
      {defaultTaskFilters: true}
    );
  }

  const isoString = new Date().toISOString();

  function ISOtoLongDate(isoString: Date, locale = 'en-US') {
    const options = {month: 'short', day: 'numeric'};
    const date = new Date(isoString);
    const longDate = new Intl.DateTimeFormat(locale, options).format(isoString);
    // console.log('Long Date', longDate);
    return longDate;
  }

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={props.open}
        onClose={props.onCancel}
        aria-labelledby="alert-modal-title"
        aria-describedby="alert-modal-description"
      >
        <DialogTitle id="form-dialog-title">
          {' '}
          <Grid container justifyContent="space-between" alignItems="baseline">
            <Grid item xs>
              <Typography
                style={{color: '#707070', fontSize: '18px', fontWeight: 'bold'}}
              >
                Dependency Warning
              </Typography>
            </Grid>
            <Grid item xs style={{textAlign: 'right'}} onClick={props.onCancel}>
              <IconButton style={{marginTop: '-12px'}}>
                <Close style={{color: '#606060'}} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent style={{marginTop: '-24px'}}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <img
              color="#C3DAFF"
              alt="a"
              src={require('../../../../../../../../../assets/images/dummy/logos/report_gmailerrorred.svg')}
              width="118px"
              style={{paddingBottom: '12px'}}
            />
          </Grid>
          <DialogContentText>
            <Typography
              style={{color: '#707070', fontSize: '18px', fontWeight: 'bold'}}
            >
              {' '}
              This task is waiting on another task to be completed{' '}
            </Typography>
          </DialogContentText>
          <DialogContentText>
            <Typography style={{color: '#7F7F7F', fontSize: '14px'}}>
              Are you sure you want to mark this task as complete?
            </Typography>
          </DialogContentText>
          <DialogContentText>
            <Typography
              style={{color: '#7F7F7F', fontSize: '14px', paddingTop: '12px'}}
            >
              Waiting On
            </Typography>
          </DialogContentText>
          <DialogContentText style={{display: 'flex', flexDirection: 'column'}}>
            {dependencyList.map((id: any) => (
              <>
                {/* {!id.listNo ? ( */}
                <List disablePadding={true}>
                  <ListItem
                    button
                    disableGutters={true}
                    className={classes.listItem}
                  >
                    <CalendarToday
                      fontSize="small"
                      style={{color: '#60606099', marginRight: '8px'}}
                    />
                    {id.relatedStartDue ? (
                      <Typography style={{marginRight: '4px'}}>
                        Start
                      </Typography>
                    ) : (
                      <Typography style={{marginRight: '4px'}}>Due</Typography>
                    )}

                    {id.days.length > 0 &&
                    id.days &&
                    id.days != '0' &&
                    id.days != '0.0' ? (
                      <Typography style={{marginRight: '4px'}}>
                        {id.days.split('.')[0]} days and
                      </Typography>
                    ) : (
                      <div></div>
                    )}

                    {id.hours.length > 0 &&
                    id.hours &&
                    id.hours != '0' &&
                    id.hours != '0.0' ? (
                      <Typography
                        className={classes.prefixStyling}
                        style={{marginRight: '4px', marginLeft: '4px'}}
                      >
                        {parseFloat(id.hours)} hours
                      </Typography>
                    ) : (
                      <div></div>
                    )}

                    <Typography style={{marginRight: '4px'}}>
                      {' '}
                      after task {id.listNo}
                    </Typography>

                    <Typography
                      className={classes.taskDisplayName}
                      onClick={e => openTask(id.relatedId, id.listNo)}
                    >
                      {id.name.length < 42
                        ? id.name
                        : 
                        <Tooltip
                            title={
                              <div style={{fontSize: '1em'}}>
                                {id.name}
                              </div>
                            }
                          >
                            <Typography className={classes.taskDisplayName}>
                            {id.name.substring(0, 42) + '...'}
                            </Typography>
                          </Tooltip>
                        }
                    </Typography>
                    {id.relatedDueDate.length > 0 ? (
                      <Typography
                        className={classes.prefixStyling}
                        style={{marginRight: '4px', marginLeft: '4px'}}
                      >
                        due on {id.relatedDueDate}
                      </Typography>
                    ) : (
                      <div> </div>
                    )}
                  </ListItem>
                </List>
              </>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            paddingBottom: '23px',
          }}
        >
          <Button
            onClick={props.onCancel}
            variant="outlined"
            style={{
              width: '200px',
              fontWeight: 'normal',
              borderRadius: '2px',
              paddingTop: '12px',
              paddingBottom: '12px',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={props.onComplete}
            variant="contained"
            style={{
              width: '200px',
              fontWeight: 'bold',
              borderRadius: '2px',
              paddingTop: '12px',
              paddingBottom: '12px',
            }}
          >
            Complete Task
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  '@global': {
    '.MuiDialog-paperWidthSm': {
      borderRadius: '2px',
    },
  },

  '.MuiButton-label': {
    marginRight: '-1px',
    whiteSpace: 'nowrap',
  },
  active: {
    background: '#EFF5FF',
    fontWeight: 'bolder',
    fontFamily: 'Montserrat',
    borderWidth: 'thin',
    zIndex: 1,
    '&:hover': {
      borderWidth: 'thin',
    },
  },

  listItem: {
    paddingTop: '0px',
    paddingBottom: '12px',
    '&:hover': {
      borderWidth: 'thin',
      backgroundColor: 'transparent',
    },
  },

  taskDisplayName: {
    fontSize: '12px',
    color: '#1159D0',
    fontFamily: 'Montserrat',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  suffixStyling: {
    fontSize: '12px',
    color: '#606060',
    fontFamily: 'Montserrat',
    margin: '0px 0px 0px 4px',
    whiteSpace: 'nowrap',
  },
  prefixStyling: {
    fontSize: '12px',
    color: '#606060',
    fontFamily: 'Montserrat',
    margin: '0px 4px 0px -12px',
    whiteSpace: 'nowrap',
  },
  hideSuffixStyling: {
    display: 'none',
  },
}));
