import React from 'react';
import {useStyles} from './Style';
import { StatusIcon } from './OnlyStatusIcon';
import { classNames } from '../../helpers/helpers';

interface NotificationTextProps {
  message: string | undefined | null;
  secondary?: boolean;
  listId?: string;
}

const secondaryStyle: any = {
  opacity: 0.8,
  color: '#2C2C2C',
  fontSize: 13,
};

export function NotificationText(props: NotificationTextProps) {
  if (!props.message) return null;

  const classes = useStyles();
  const tagSplitter = /(<b>|<\/b>)(.*?)(<\/b>|<b>)/g;
  const splitMessage = props.message.replace(/\n/g, '').split(tagSplitter);
  const filteredMessage = splitMessage.filter(
    (m: string) => m && !['<b>', '</b>'].includes(m)
  );

  var style = {width: '100%'};
  var modulo: number;
  if (props.secondary) {
    style = {...style, ...secondaryStyle};
  }
  props.secondary ? (modulo = 1) : (modulo = 0);

  const isStatusNotif = filteredMessage && filteredMessage[0].includes("Status changed")
  
  return (
    <div className={classNames(isStatusNotif ? 'flex items-center' : '', classes.ellipsis)} style={style}>
      {filteredMessage.map((message: string, i: number) =>
        i % 2 == modulo ? (
          <span key={i}>{message}</span>
        ) : (
          <>
            { isStatusNotif ? 
              <StatusIcon noCursor={true} status={message.replace(/\s/g, '')} listId={props.listId} style={{padding: '0px 6px 0px 4px', minWidth: 'auto', border: '1px solid #ececec', borderRadius: '3px', margin: '0 6px'}} /> :
              <span key={i} style={{fontWeight: 'bold'}}>
                {message}
              </span>
            }
          </>
        )
      )}
    </div>
  );
}
