
import React, { useState, useEffect, useMemo } from 'react';
import idx from 'idx';
import Dropdown from './components/Dropdown';
import { useUserListsLight } from '../../../../../graphql/queries/UserLists';
import CreateProjectPage from '../../../../list/CreateProjectPage/CreateProjectPage';
import { useStateContext } from '../../../../../store';
import { useLazyDetailedListLightInfo } from '../../../../../graphql/queries/DetailedListInfo';

export default function ImportTasks() {
  const state = useStateContext();
  /* State Variables */
  // lists from which user selects in top-left of the page
  const [lists, setLists] = useState<any[]>([]);
  
  // the specific list to which the user is adding tasks
  const [listId, setListId] = useState<string>('');

  // the specific list to which the user is adding tasks
  const [currentList, setCurrentList] = useState<any>({});

  // Open the dropwdown automatically if there is no list selected
  const [autoOpenDropdown, setAutoOpenDropdown] = useState<boolean>(false);

  /* Query hooks */
  // loads lists for user to select from
  const {loading, data, error} = useUserListsLight({}) 
  const [detailedListLightInfo] = useLazyDetailedListLightInfo({});

  const listFilter: string[] = useMemo(() => idx(state, s => s.userSelectedLists) || [], [idx(state, s => s.userSelectedLists)]);

  /**
   * useEffect hook to update the component's state with non-archived and non-deleted lists.
   *
   * This effect is triggered when loading status changes.
   * It retrieves the lists from the provided 'data' object and filters them to include
   * only those that are not archived and not deleted. The filtered lists are then set in
   * the 'lists' state of the component.
   *
   * @param {boolean} loading - The loading status indicating data fetching progress.
   * @param {object} data - The data containing user lists.
   * @param {function} setLists - A state update function to set the filtered lists.
   */
  useEffect(() => {
    // Retrieve lists from the data object using idx utility
    const listsResponse = idx(data, data => data.userLists.lists);

    // If still loading or no listsResponse, exit the effect
    if (loading || !listsResponse) return;

    // Filter out archived and deleted lists from the response
    const filteredLists = listsResponse.filter((list: any) => !list.isArchived).filter((list: any) => !list.isDeleted);

    // Set the filtered lists in the component's state
    setLists(filteredLists);
  }, [loading]);

  /**
   * useEffect hook to update the current list based on the selected listId.
   * This effect is triggered when the 'listId' changes.
   * using the 'setCurrentList' state update function.
   * @param {string | null} listId - The ID of the selected list.
   * @param {function} setCurrentList - A state update function to set the current list.
   */
  useEffect(() => {
    if (listId) {
      detailedListLightInfo({variables: { id: listId } })
        .then((res: any) => {
          setCurrentList(res?.data?.list);
        })
    }
  }, [listId]);

  // Select the first list in the selected lists
  useEffect(() => {
    if (listFilter.length) {
      if (!['-1', '-2'].includes(listFilter[0])) {
        setListId(listFilter[0]);
        setAutoOpenDropdown(false);
      } else {
        setAutoOpenDropdown(true);
      }
    } else {
      setAutoOpenDropdown(true);
    }
  }, [listFilter]);

  return (
    <div>
      <div style={{marginLeft: '50px', width: '300px'}}>
        <Dropdown data={lists} listId={listId} setListId={setListId} openDefault={autoOpenDropdown} autoSize />
      </div>
      <div style={{ display:  listId ? 'block' : 'none'}}>
        <CreateProjectPage isOnlyImportTasks currentList={currentList} />
      </div>
    </div>
  );
}