import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ReconSettings } from '../../../../../recons/ReconSettings';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: '100%',
			width: '100%',
			overflow: 'auto'
		},
	})
);

interface IProjectDetailRecon {
	listData: any;
	updateList: (field: string, value: string) => void;
	updateTask: (task: any) => void;
	updateAllTasks: (key: any, value: any) => void;
}

export default function ProjectDetailRecon(props: IProjectDetailRecon) {

	const classes = useStyles();
	const { listData, updateList, updateTask, updateAllTasks } = props;

	return (
		<div className={classes.root}>
			<ReconSettings isNewProjectCreate isCreate listData={listData} updateList={updateList} updateTask={updateTask} updateAllTasks={updateAllTasks} />
		</div>
	)
}