import {createQueryHook, createLazyQueryHook, gql} from '../graphqlHelpers';

const query = gql`
  query ReconSupportTasks($listId: ID!) {
    list(id: $listId) {
      id
      tasks {
        id
        name
        listNumber
        files {
          id
          fileName 
          fileLocation
          alterable
          taskId
        }
      }
    }
  }
`

export const useReconSupportTasks = createQueryHook(query);
export const useLazyReconSupportTasks = createLazyQueryHook(query);
