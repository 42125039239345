import { gql} from '../graphqlHelpers';

type ID = number | string;

export interface UpdateFileNameVariables {
  fileId: ID;
  name: string;
}

export interface Error {
  path: string;
  message: string;
}

export interface UpdateFileNameData {
  success: boolean;
  errors: Error[];
}

export const updateFileName = gql `
  mutation updateFileName($name: String!, $fileId:ID!) {
    updateFileName(name: $name, fileId: $fileId) {
      success
      errors {
        path
        message
      }
    }
  }
`;