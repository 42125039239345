import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { ISteps } from './common/interfaces';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			justifyContent: 'space-between',
			display: 'flex',
			padding: '12px 50px 12px 100px',
			borderTop: '1px solid #707070',
			background: 'white',
			zIndex: 1201,
		},
	})
);

interface FooterProps {
	step: ISteps,
	stepLabels: string[],
	nextDisabled: boolean,
	handleBack(): void,
	handleNext(): void,
}

export default function Footer(props: FooterProps) {

	const { step, stepLabels, nextDisabled, handleBack, handleNext } = props
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Button onClick={handleBack}>
				Back
      		</Button>
			{ !((step.activeStep === 0 && step.activeSubStep === 2) || (step.activeStep === 2 && step.activeSubStep === 1) || (step.activeStep === 3 && step.activeSubStep === 1)) &&
				<Button variant='contained' color='primary' disabled={nextDisabled} onClick={handleNext}>
					{step.activeStep === stepLabels.length - 1 ? 'Finish' : 'Continue'}
				</Button>
			}
		</div>

	)
}
