import React, {useState, useEffect} from 'react';;

import Header from './components/Header';
import { FileReviewTable } from './components/FileReviewTable/FileReviewTable';
import { useStateContext } from '../../../store';


import useListsFiles from '../../../graphql/queries/ListsFiles';
import useRetentionPolicies from '../../../graphql/queries/RetentionPolicies';
import { useArchiveLists } from '../../../graphql/mutations/ArchiveLists';
import { useSoftDeleteLists } from '../../../graphql/mutations/SoftDeleteLists';

import useUpdateList from '../../../graphql/mutations/UpdateList';

export default function ManageFilesPage(props:any) {
  const { list } = props;
	const state = useStateContext();

	const [ lists, setLists ] = useState([]);
	const [ listIds, setListIds ] = useState([]);
	const [ files, setFiles ] = useState([]);

  // when on the file management page, a user may change a list's FRP
  // passing a list object to ListToUpdate will trigger the updateList hook
  // For the purposes of this page, we should only be updating a lists FRP
  const [ listToUpdate, setListToUpdate ] = useState<any>(null);
	const [ updateList, updateListResponse ] = useUpdateList({list: listToUpdate});
	const [ listsToArchive, setListsToArchive ] = useState<any[]>([]);
	const [ listsToDelete, setListsToDelete ] = useState<any[]>([]);

	// making a call to get the policies for a given company 
	const [ policies, setPolicies ] = useState([]);
  const policyQueryResponse = useRetentionPolicies({companyId: state.currentUser.lastViewedCompanyId})

	const [ archiveLists, archiveListsResponse ] = useArchiveLists({listIds: listsToArchive})
	const [ deleteLists, deleteListsResponse ] = useSoftDeleteLists({listIds: listsToDelete})

	const {loading, data, error} = useListsFiles({listIds: listIds})

	useEffect(() => {
		if (listsToDelete.length) { deleteLists() }
	}, [listsToDelete])

	useEffect(() => {
		if (listsToArchive.length) { archiveLists() }
	}, [listsToArchive])

	useEffect(() => {
		if (!policyQueryResponse.data) return;
		setPolicies((policyQueryResponse as any).data.retentionPolicies)
	}, [ policyQueryResponse.called, policyQueryResponse.loading, policyQueryResponse.data] )

	const parse = (data: any) => {
		if (!data.lists) return;
		var ok = data.lists.map((a:any) => a.files);
		const isFileExist = ok.some((s: any) => s);

		if(!isFileExist) {
			return [];
		}

		setLists(data.lists)
		ok.forEach((z: any, i: any) => {
			z.forEach((x: any, j: any) => {
				ok[i][j].listName = data.lists[i].name
				if (data.lists[i].retentionPolicy) {
					ok[i][j].retentionPolicy = data.lists[i].retentionPolicy.name
					ok[i][j].retentionPolicyId = data.lists[i].retentionPolicy.id
				}
				ok[i][j].date = data.lists[i].retentionEnd;
				ok[i][j].listId = data.lists[i].id;

			})
		})
		var flat = ok.reduce((a: any, b:any) => a.concat(b), [] );
		var flat2 = flat.map((a: any) => {
			return ({
				id: a.id,
				url: a.fileLocation, 
				name: a.fileName, 
				taskName: a.task.name, 
				taskSection: a.task.section,
				listName: a.listName,
				date: a.date, 
				retentionPolicy: a.retentionPolicy,
				retentionPolicyId: a.retentionPolicyId,
				listId: a.listId
			});
		})
		return flat2;
	}

	// test this out.
	const handleArchive = () => {
		var immediateDeleteLists = lists.filter((l: any) => l.retentionPolicy.duration === 0);
		var archiveLists = lists.filter((l: any) => !(l.retentionPolicy.duration === 0));
		setListsToDelete(immediateDeleteLists.map((l: any) => l.id))
		setListsToArchive(archiveLists.map((l: any) => l.id))
	}

	useEffect(() => {
		if (data) {
			var parsedData = parse(data)
			setFiles(parsedData as any);
		}
	}, [data])

	useEffect(() => {
		if (props.location.listIds) {
			setListIds(props.location.listIds);
		}	
	}, [props.location]);

  useEffect(() => {
		if (!listToUpdate) return;
		updateListUI(listToUpdate)
		updateList();
	}, [listToUpdate])

	const updateListUI = (list: any) => {
		var listsCopy = JSON.parse(JSON.stringify(lists));
		if (listsCopy.length > 1) {
			var b = listsCopy.map( (l:any) => {
				var ppp;
				if (l.retentionPolicy && l.id === list.id) {
					ppp = l.retentionPolicy;
					ppp.id = list.retentionPolicyId
				}
				return l.id === list.id ? Object.assign({...l, retentionPolicy: ppp}) : l
			})	
			var c = {lists: b}
			var parsedData = parse(c)
			setFiles(parsedData as any);
			setLists(b);
		} else {
		listsCopy.find((l: any) => l.id == list.id)
			.retentionPolicy.id = list.retentionPolicyId;
		setLists(listsCopy)
		}
	}

  return (
      <div>
        <Header 
					lists = {lists}			
					updateList={setListToUpdate}
					policies={policies || []}
				/>
        <FileReviewTable
					lists = {lists}
					files={files}
					archiveLists={handleArchive}
					setFiles={setFiles}
          updateList={setListToUpdate}
					policies={policies || []}
        />
      </div>
  );
}
