import { createMutationHook, gql } from '../graphqlHelpers';

const mutation = gql`
  mutation AddTaskTags($taskIds: [ID!]!, $tag: String!) {
    addTaskTags(taskIds: $taskIds, tag: $tag) {
      success
    }
  }
`
export const useAddTaskTags = createMutationHook(mutation);
