import { createMutationHook, gql } from '../graphqlHelpers';

const mutation = gql`
  mutation UpdateTaskZeroBalance($taskId: ID) {
    updateTaskZeroBalance(taskId: $taskId) {
      success
    }
  }
`

export const useUpdateTaskZeroBalance = createMutationHook(mutation);
 