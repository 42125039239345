import React, { useState } from 'react';
import { Tab } from '@headlessui/react';
import moment from 'moment';
import { calculatePriorPeriodEndDate, calculatePriorDate } from '../../utils/dateUtils';

type RelativePanelProps = {
  primarySourceType: number,
  date: moment.Moment,
  startDate: moment.Moment,
  endDate: moment.Moment,
  isStartDateLocked: boolean,
  pickCustom?:() => void,
  pickRange(range: string, rangeStart?: any, rangeEnd?: any): void,
};

/**
 * `RelativePanel` component reusable anywhere accross the app. 
 * @return {React.JSXElementConstructor} The `RelativePanel` component.
 */

export default function RelativePanel(props: RelativePanelProps) {
  const [active, setActive] = useState<String>('today');
  /**
   * Generate appropriate class names for different types of buttons
   * @returns {String} The full class name
   */
  const classNames = (...classes: any) => classes.filter(Boolean).join(' ');

  let monthStartDate: any, quarterStartDate: any, yearStartDate: any, monthEndDate: any, quarterEndDate: any, yearEndDate: any;

  if (props.primarySourceType === 3 || props.primarySourceType === 5) {
    monthStartDate = props.isStartDateLocked ? 
      moment(props.startDate).format('ll') : 
      moment(props.startDate).clone().subtract(1, 'months').format('ll');
    quarterStartDate = props.isStartDateLocked ? 
      moment(props.startDate).format('ll') : 
      moment(props.startDate).clone().subtract(3, 'months').format('ll');
    yearStartDate = props.isStartDateLocked ? 
      moment(props.startDate).format('ll') : 
      moment(props.startDate).clone().subtract(1, 'years').format('ll');

    monthEndDate = calculatePriorPeriodEndDate(moment(props.endDate), 1).format('ll');
    quarterEndDate = calculatePriorPeriodEndDate(moment(props.endDate), 3).format('ll');
    yearEndDate = calculatePriorPeriodEndDate(moment(props.endDate), 12).format('ll');
  } else if (props.primarySourceType === 6) {
    monthStartDate = props.isStartDateLocked ? 
      moment(props.startDate).format('MMM YYYY') : 
      calculatePriorDate(props.startDate, 1).format('MMM YYYY');
      
    quarterStartDate = props.isStartDateLocked ? 
      moment(props.startDate).format('MMM YYYY') : 
      calculatePriorDate(props.startDate, 3).format('MMM YYYY');

    yearStartDate = props.isStartDateLocked ? 
      moment(props.startDate).format('MMM YYYY') : 
      calculatePriorDate(props.startDate, 12).format('MMM YYYY');

    monthEndDate = calculatePriorDate(props.endDate, 1).format('MMM YYYY');
    quarterEndDate = calculatePriorDate(props.endDate, 3).format('MMM YYYY');
    yearEndDate = calculatePriorDate(props.endDate, 12).format('MMM YYYY');
  }

  const handlePickMonth = () => {
    setActive('30_days')
    props.pickRange('30_days', monthStartDate, monthEndDate)
  }

  const handlePickQuarter = () => {
    setActive('90_days')
    props.pickRange('90_days', quarterStartDate, quarterEndDate)
  }

  const handlePickYear = () => {
    setActive('1_year')
    props.pickRange('1_year', yearStartDate, yearEndDate)
  }

  const handlePickCustom = () => {
    // props.pickCustom()
    props.pickRange('custom')
  }

  return (
    <Tab.Panel
      key='relative'
      className={classNames(
        'bg-white rounded-xl',
        'ring-opacity-60 focus:outline-none'
      )}
    >
      <ul className='divide-y divide-gray-50'>
        <li
          className={`flex py-2 px-4 cursor-pointer hover:bg-gray-100 ${active == '30_days' && 'bg-gray-100'}`}
          onClick={handlePickMonth}
        >
          <a className="flex items-center justify-between w-full text-xs">
            <span>Prior month</span>
            <span className='text-gray-500'> 
              {monthStartDate}
              {' '}-{' '}
              {monthEndDate}
            </span>
          </a>
        </li>
        <li
          className={`flex py-2 px-4 cursor-pointer hover:bg-gray-100 ${active == '90_days' && 'bg-gray-100'}`}
          onClick={handlePickQuarter}
        >
          <a className='flex items-center justify-between w-full text-xs'>
            <span>Prior quarter</span>
            <span className='text-gray-500'>
              {quarterStartDate}
              {' '}-{' '}
              {quarterEndDate}
            </span>
          </a>
        </li>
        <li
          className={`flex py-2 px-4 cursor-pointer hover:bg-gray-100 ${active == '1_year' && 'bg-gray-100'}`}
          onClick={handlePickYear}
        >
          <a className='flex items-center justify-between w-full text-xs'>
            <span>Prior year</span>
            <span className='text-gray-500'>
              {yearStartDate}
              {' '}-{' '}
              {yearEndDate}
            </span>
          </a>
        </li>
        {/* <li
          className={`flex py-2 px-4 cursor-pointer hover:bg-gray-100 ${active == 'custom' && 'bg-gray-100'}`}
          onClick={handlePickCustom}
        >
          <a className='flex items-center justify-between w-full text-xs'>
            <span>Custom</span>
          </a>
        </li> */}
      </ul>
    </Tab.Panel>
  );
}
