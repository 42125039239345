// linted - papf - 11/20
/* tslint:disable:object-literal-sort-keys */
/* we're going to eventually move a lot of the component-level repeated
   style info into this file, so skip key sorting for now */
import { createTheme } from '@material-ui/core';
import * as cs from '../constants/theme';

const defaultTheme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
    MuiTabs: {
      indicatorColor: 'primary'
    }
  },
  palette: {
    background: {
      default: cs.COLORS.background
    },
    primary: {
      main: '#3A84FF'
    }
  },
  typography: {
    body1: {
      color: cs.FONT.color,
      fontFamily: cs.FONT.family,
      fontSize: cs.FONT.size.xs,
      fontWeight: cs.FONT.weight.regular,
      lineHeight: '21px'
    },
    h1: {
      color: cs.FONT.color,
      fontFamily: cs.FONT.family,
      fontSize: cs.FONT.size.xl,
      fontWeight: cs.FONT.weight.bold,
      lineHeight: '40px'
    },
    h2: {
      color: '#606060',
      fontFamily: cs.FONT.family,
      fontSize: 18,
      fontWeight: 700
      //      lineHeight: '32px'
    },
    h3: {
      color: '#606060',
      fontFamily: cs.FONT.family,
      fontSize: 14,
      fontWeight: 700
      //      lineHeight: '24px'
    },
    h4: {
      color: cs.FONT.color,
      fontFamily: cs.FONT.family,
      fontSize: cs.FONT.size.sm,
      fontWeight: cs.FONT.weight.bold,
      lineHeight: '24px'
    },
    h5: {
      color: cs.FONT.color,
      fontFamily: cs.FONT.family,
      fontSize: cs.FONT.size.sm,
      fontWeight: cs.FONT.weight.regular,
      lineHeight: '24px'
    },
    h6: {
      color: '#606060',
      // color: cs.FONT.color,
      fontFamily: cs.FONT.family,
      fontSize: cs.FONT.size.xs,
      fontWeight: 700
      // fontWeight: cs.FONT.weight.regular,
      // lineHeight: '21px'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.6em',
          height: '0.6em',
          backgroundColor: '#EFEFEF'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#D8D8D8',
          outline: '1px solid D8D8D8'
        },
        '*::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#2C2C2C',
          outline: '1px solid #2C2C2C'
        }
      }
    },
    MuiContainer: {
      root: {
        maxWidth: '1380px'
      }
    },
    MuiListItem: {
      root: {
        display: 'flex',
        textTransform: 'none',
        width: '100%',
        cursor: 'pointer',
        '&$selected': {
          color: '#606060',
          backgroundColor: '#FFF'
        },
        '&:hover, &$selected:hover': {
          backgroundColor: cs.COLORS.primaryHover
        }
      }
    },
    MuiListItemIcon: {
      root: {
        color: '#000000',
        minWidth: '40px'
      }
    },
    MuiListItemText: {
      root: {
        color: cs.FONT.color
      }
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 1000 // this defaults to 600 for some reason
      }
    },
    MuiIconButton: {
      root: {
        border: '2px solid transparent',
        boxSizing: 'border-box',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: 'transparent',
          border: '2px solid #3A84FF',
          borderRadius: '3px'
        }
      }
    },
    MuiInput: {
      root: {
        width: '100%'
      }
    },
    MuiOutlinedInput: {
      root: {
        '&$focused': {
          borderColor: '#0F3B5C'
        }
      }
    },
    MuiSelect: {
      root: {
        fontFamily: 'Montserrat',
        color: '#606060',
        fontWeight: 500,
        fontSize: '12px',
        borderBottom: 'none'
      },
      select: {
        '&:focus': {
          backgroundColor: '#FFF'
        },
        border: '1px solid #CACACA',
        borderRadius: '3px'
      },
      selectMenu: {
        height: 13.25,
        // paddingBottom: 11,
        paddingTop: 6,
        paddingLeft: 6
      },
      icon: {
        width: '.8em'
      }
    },
    MuiPopover: {
      paper: {
        boxShadow: 'none',
        marginTop: -1,
        border: '1px solid #CACACA',
        borderRadius: 3
      }
    },
    MuiMenuItem: {
      root: {
        // height: '100%',
        display: 'flex',
        paddingTop: '12px',
        paddingBottom: '12px',
        alignItems: 'center',
        boxSizing: 'border-box',
        color: '#606060',
        minHeight: 17,
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '12px',
        textTransform: 'capitalize',
        '&:hover': {
          cursor: 'pointer',
          background: '#EBF2FF'
        }
      }
    },
    MuiButton: {
      root: {
        width: 'fit-content',
        minWidth: '36px',
        height: '36px',
        borderRadius: '3px',
        fontFamily: cs.FONT.family,
        fontWeight: cs.FONT.weight.regular,
        fontSize: cs.FONT.size.xs,
        color: cs.COLORS.primary,
        textAlign: 'center',
        textTransform: 'capitalize',
        border: '2px solid transparent',
        '&:hover': {
          border: `2px solid ${cs.COLORS.primary}`,
          backgroundColor: 'transparent'
        }
      },
      contained: {
        color: '#FFFFFF',
        backgroundColor: cs.COLORS.primary,
        border: '2px solid transparent',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: cs.COLORS.primary
        }
      },
      outlined: {
        backgroundColor: '#FFFFFF',
        color: '#3A84FF',
        border: `2px solid ${cs.COLORS.primary}`,
        fontSize: cs.FONT.size.xs
      }
    },
    MuiTableRow: {
      root: {
        height: '48px',
        color: '#606060',
        fontFamily: cs.FONT.family,
        fontWeight: cs.FONT.weight.regular,
        fontSize: cs.FONT.size.xs,
        '&:hover': {
          color: '#D8D8D8',
          backgroundColor: '#EBF2FF'
        }
      },
      head: {
        '&:hover': {
          backgroundColor: '#FFFFFF'
        }
      }
    },
    MuiTableCell: {
      root: {
        color: cs.FONT.fontColor,
        fontFamily: cs.FONT.family,
        fontWeight: cs.FONT.weight.regular,
        fontSize: cs.FONT.size.xs,
        paddingTop: '0px',
        paddingBottom: '0px'
      },
      head: {
        cursor: 'default'
      }
    },
    MuiRadio: {
      root: {
        padding: '12px',
        '& .MuiSvgIcon-root': {
          fontSize: '18px'
        },
        '&:hover': {
          borderColor: 'transparent',
          background: 'transparent'
        }
      },
      colorPrimary: {
        '&.Mui-checked': {
          color: cs.COLORS.primary
        }
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        '&.Mui-checked': {
          color: cs.COLORS.primary
        }
      }
    },
    MuiTab: {
      root: {
        // default MUI has media query, need to use another query to override
        '@media (min-width: 0px)': {
          minWidth: 0,
          marginBottom: 1,
          marginRight: 24
        },
        alignItems: 'flex-end',
        color: '#AFAFAF',
        fontFamily: 'Montserrat',
        fontSize: 12,
        fontWeight: 700,
        padding: 0, // '6px 12px',
        textTransform: 'none',
        '&$selected': {
          color: '#3A84FF',
          fontWeight: 700
        }
      }
    },
    MuiTypography: {
      root: {
        cursor: 'default'
      },
      body1: {
        color: '#606060',
        fontWeight: 500
      }
    }
  }
});

export default defaultTheme;
