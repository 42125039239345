import { gql} from '../graphqlHelpers';

type ID = number | string;

export interface MoveProjectVariables {
  workspaceId: ID;
  listId: ID;
  destinationWorkspaceId: ID;
}

export interface Error {
  path: string;
  message: string;
}

export interface MoveProjectData {
  success: boolean;
  errors: Error[];
}

export const moveProject = gql `
  mutation moveProject($workspaceId: ID!, $listId:ID!, $destinationWorkspaceId: ID!) {
    moveProject(workspaceId: $workspaceId, listId: $listId, destinationWorkspaceId: $destinationWorkspaceId) {
      success
      errors {
        path
        message
      }
    }
  }
`;