import { TagIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import { randomColors } from "../../constants/theme";
import { useCreateMessageLabelType } from "../../graphql/mutations/CreateMessageLabelType";
import { useClickOutsideCallback } from "../../hooks/useClickOutsideCallback";
import MessageLabelsBox from "../messageLabelsBox/MessageLabelsBox";
import { SimpleDialog } from "../modals/SimpleDialog";
import CustomizeMessageLabels from "./CustomizeMessageLabels";
import { useAddUpdateEntityLabelType } from "../../graphql/mutations/AddUpdateEntityLabelType";
import { classNames } from "../../helpers/helpers";

type ComponentProps = {
  labels?: any[];
  dropdownClassName?: string,
  selectedLabels: any[],
  onSelectTag(e:any): void,
  onLabelTagsChanged(newId?:string): void,
  entityType?: string,
  onShowCustomizeModal?(s:boolean): void,
};

/** Component for the custom label selection with search option
 * @param `props.labels` - The component attribute that contains all labels to select
 * @param `props.dropdownClassName` - The component attribute that contains the root class name
 * @param `props.onSelectTag` - The component function to call when an item has been selected
 * @param `props.onLabelTagsChanged` - The component function to call when the labels tags changed like added/edited/removed
 * @param `props.entityType` - The component attribute that contains the entity type
 * @param `props.onShowCustomizeModal` - The component function to call when the customize labels has been clicked
 * @return {React.JSXElementConstructor} The output component.
 */
export default function AddMessageLabelTag(props: ComponentProps) {
  const [showLabelsBox, setShowLabelsBox] = useState<boolean>(false);
  const [labels, setLabels] = useState<any[]>(props.labels || []);
  const [showCustomizeLabelModal, setShowCustomizeLabelModal] = useState<boolean>(false);
  const initialLabels = props.labels || [];
  const customLabelsRef: any = useRef<any>(null);
  const inputMessageLabelsRef: any = useRef<any>(null);
  const [createMessageLabelType] = useCreateMessageLabelType({});
  const [addUpdateEntityLabelType] = useAddUpdateEntityLabelType({});

  useEffect(() => {
    if (props.labels) {
      setLabels(filterLabelsByName(props.labels, inputMessageLabelsRef?.current?.value));
    }
  },[props.labels]);

  useEffect(() => {
    if (props.onShowCustomizeModal) {
      props.onShowCustomizeModal(showCustomizeLabelModal);
    }
  }, [showCustomizeLabelModal]);

  const onClickOutside = () => {
    setShowLabelsBox(false);
    setLabels(initialLabels);
  }

  useClickOutsideCallback(customLabelsRef, onClickOutside);

  const filterLabelsByName = (list:any[], name: string) => list.filter((it:any) => it.name.toLowerCase().includes((name || "").toLowerCase()));

  const onChangeInputLabels = (e:any) => {
    if (e.target.value) {
      setLabels(filterLabelsByName(initialLabels, e.target.value));
    } else {
      setLabels(initialLabels);
    }
  }

  const onFocusInputLabels = (e:any) => {
    if (!labels.length && !inputMessageLabelsRef.current.value) {
      setLabels(initialLabels);
    }
    if (inputMessageLabelsRef.current.value) {
      setLabels(filterLabelsByName(initialLabels, inputMessageLabelsRef.current.value));
    }
    setShowLabelsBox(true);
  }

  const onEscapeInputLabels = (e:any) => {
    resetLabelSelection();
  }

  const onSelectLabelItem = (tag: any) => {
    if (props.onSelectTag) {
      props.onSelectTag(tag);
    }
    resetLabelSelection();
  }

  const onClickAddNew = (text: string) => {
    const random = Math.floor(Math.random() * randomColors.length);
    const color = randomColors[random];
    if (props.entityType === "Message") {
      // Create the message label
      createMessageLabelType({
        variables: {
          name: text,
          color
        }
      }).then((response: any) => {
        if (response?.data?.createMessageLabelType?.success) {
          onResponseCreateLabel(response?.data?.createMessageLabelType, text, color);
        }
      });
    } else if (props.entityType === "List") {
      // Create the list label
      addUpdateEntityLabelType({
        variables: {
          etype: "List",
          name: text,
          color: color
        }
      }).then((response: any) => {
        if (response?.data?.addUpdateEntityLabelType?.success) {
          onResponseCreateLabel(response?.data?.addUpdateEntityLabelType, text, color);
        }
      });
    }
  }
  
  // Handle response of label creation request
  const onResponseCreateLabel = (data: any, text: string, color: string) => {
    onSelectLabelItem({id: data?.id, name: text, color, __typename: props.entityType === "Message" ? "MessageLabel" : "EntityLabel"});
    props.onLabelTagsChanged(data?.id);
  }

  const onClickCustomize = () => {
    setShowCustomizeLabelModal(true);
  } 

  const resetLabelSelection = () => {
    setShowLabelsBox(false);
    inputMessageLabelsRef.current.value = '';
    inputMessageLabelsRef.current.blur();
    setLabels(initialLabels);
  }

  const remainingLabelsToSelect = labels.filter((it:any) => {
    return !(props.selectedLabels || []).some((ls:any) => ls.name === it.name)
  });

  const handleKeyUp = (event: any) => {
    // enter key + shift 
    if (event.keyCode === 13 && event.shiftKey) {
      return;
    }

    // enter key
    if (event.keyCode === 13) {
      if (remainingLabelsToSelect.length === 0 && inputMessageLabelsRef.current.value) {
        event.stopPropagation();
        onClickAddNew(inputMessageLabelsRef.current.value);
      }
      if (remainingLabelsToSelect.length === 1) {
        event.stopPropagation();
        onSelectLabelItem(remainingLabelsToSelect[0]);
      }
    }
  }

  return (
    <div ref={customLabelsRef}>
      {showLabelsBox && (
        <MessageLabelsBox
          items={remainingLabelsToSelect}
          searchText={inputMessageLabelsRef.current.value}
          onSelectItem={onSelectLabelItem}
          onClickAddNew={onClickAddNew}
          onClickCustomize={onClickCustomize}
          onEscape={onEscapeInputLabels}
          className={props.dropdownClassName}
        />
      )}
      <div className={classNames(props.entityType === "List" ? "w-[110px]" : "w-[160px]", "group inline-flex items-center  h-[28px] rounded-sm text-left text-gray-400 hover:bg-gray-100")}>
        <TagIcon
          style={{ width: "0.9rem", height: "0.9rem" }}
          className="mr-1 ml-1 font-bold group-hover:text-gray-500"
          aria-hidden="true"
        />
        <input
          data-cy='msg-add-tag-input'
          type="text"
          ref={inputMessageLabelsRef}
          className="border-0 text-sm pl-0 py-0 w-full pr-1 text-gray-500 group-hover:bg-gray-100 focus:border-b focus:border-b-blue-100"
          placeholder="Add label..."
          onFocus={onFocusInputLabels}
          onChange={onChangeInputLabels}
          onKeyUp={handleKeyUp}
        />
      </div>
      { showCustomizeLabelModal &&
        <SimpleDialog onClose={() => setShowCustomizeLabelModal(false)} title="Customize labels" style={{maxWidth: '30rem'}}>
          <CustomizeMessageLabels labels={initialLabels} onLabelTagsChanged={props.onLabelTagsChanged} entityType={props.entityType} />
        </SimpleDialog>}
    </div>
  );
}