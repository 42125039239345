import React from "react";
import { Typography, Tooltip, makeStyles } from "@material-ui/core";

import idx from "idx";

const useStyles = makeStyles((theme) => ({
  badge: {
    border: "1px solid #B0B0B0",
    paddingLeft: "5px",
    paddingRight: "5px",
    height: "20px",
    width: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "2px",
    marginRight: "5px",
    flexWrap: "nowrap",
    whiteSpace: "nowrap",
  },
  badgeTitle: {
    color: "#B0B0B0",
    fontSize: "10px",
  },
}));

/**
 * @param trimmed - gives you the option to just display the first entity tag and display the number
 * of rest of tags (like on task list view) or you can display all the tags
 * by default - it trims
 *
 * @param containerStyles - additional styling to be applied to each entity/tag container
 */
function EntityBadges({ entities, trimmed = true, containerStyles = {} }: any) {
  const classes = useStyles();
  const additionalEntities = idx(entities, (e: any) => e.length > 1);

  return (
    <>
      {entities.slice(0, 1).map((e: any) => (
        <div className={classes.badge} style={containerStyles} key={e?.tag || e?.name}>
          <Typography
            data-cy={"tasklist-tag"}
            classes={{ root: classes.badgeTitle }}
          >
            {e?.tag || e?.name}
          </Typography>
        </div>
      ))}
      {additionalEntities && (
        <>
          {trimmed ? (
            <Tooltip
              placement="top"
              arrow
              title={
                <React.Fragment>
                  {entities.slice(1).map((e: any) => {
                    return <Typography color="inherit">{e?.tag || e?.name}</Typography>;
                  })}
                </React.Fragment>
              }
            >
              <div className={classes.badge}>
                <Typography classes={{ root: classes.badgeTitle }}>
                  {`+ ${entities.length - 1}`}
                </Typography>
              </div>
            </Tooltip>
          ) : (
            entities.slice(1).map((entity: any) => (
              <div
                className={classes.badge}
                style={containerStyles}
                key={entity?.tag || entity?.name}
              >
                <Typography
                  data-cy={"tasklist-tag"}
                  classes={{ root: classes.badgeTitle }}
                >
                  {entity?.tag || entity?.name}
                </Typography>
              </div>
            ))
          )}
        </>
      )}
    </>
  );
}

export default EntityBadges;
