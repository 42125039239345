import React, { useState } from 'react';
import clsx from 'clsx';
import * as cs from '../../constants/theme';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      height: 44,
      width: 34,
      border: '1px solid #CACACA',
      borderRadius: 3,
      marginRight: 3,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 700,
      color: '#CACACA',
      cursor: 'pointer'
    },
    container: {
      display: 'flex',
      flexDirection: 'column'
    },
    selected: {
      borderColor: cs.COLORS.primary,
      backgroundColor: cs.COLORS.primary,
      color: '#FFF'
    },
    disabled: {
      backgroundColor: '#CACACA',
      color: '#0000001F'
    }
  })
);

interface MonthDaysProps {
  selected: number | string | null;
  onChange: any;
  days: any;
}

function MonthDays(props: MonthDaysProps) {
  const classes = useStyles();

  const handleClick = (selected: number | string) => {
    props.onChange(selected);
  }

  return(
    <div className={classes.container}>
      { props.days.map((week: any) => {
        return(<div style={{display: 'flex', marginBottom: 3}}>
        {week.map((day: any) => {
          const isSelected = day == props.selected;
         return( <div data-cy={`rel-d-${day}`} onClick={() => handleClick(day)} className={clsx(classes.box, isSelected && classes.selected)}>
            <span>{day}</span>
          </div>);
          }
        )}
        </div>);
      })
      }
    </div>
  )
}

export { MonthDays };
