import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Input,
  Button,
  TextField,
  Box,
  Modal,
} from '@material-ui/core';
import idx from 'idx';
import CloseIcon from '@material-ui/icons/Close';

// GraphQL stuff
import { useQuery, useMutation } from '@apollo/client';
import { getWorkspace } from '../../../../../graphql/queries/getWorkspace';
import {
  updateFileName,
  UpdateFileNameVariables,
  UpdateFileNameData,
} from '../../../../../graphql/mutations/updateFileName';
import {
  copyFiles,
  CopyFileVariables,
  CopyFilesData,
} from '../../../../../graphql/mutations/copyFiles';
import {
  moveFiles,
  MoveFilesVariables,
  MoveFilesData,
} from '../../../../../graphql/mutations/moveFiles';
import {
  removeFile,
  RemoveFileVariables,
  RemoveFileData,
} from '../../../../../graphql/mutations/removeFile';
import {
  updateWorkspace,
  UpdateWorkspaceData,
  UpdateWorkspaceVariables,
} from '../../../../../graphql/mutations/updateWorkspace';
import {
  copyWorkspace,
  CopyWorkspaceVariables,
  CopyWorkspaceData,
} from '../../../../../graphql/mutations/copyWorkspace';
import {
  moveWorkspace,
  MoveWorkspaceVariables,
  MoveWorkspaceData,
} from '../../../../../graphql/mutations/moveWorkspace';
import {
  removeWorkspace,
  RemoveWorkspaceVariables,
  RemoveWorkspaceData,
} from '../../../../../graphql/mutations/removeWorkspace';
import {
  copyReports,
  CopyReportsData,
  CopyReportsVariables,
} from '../../../../../graphql/mutations/copyReports';
import {
  moveReports,
  MoveReportsData,
  MoveReportsVariables,
} from '../../../../../graphql/mutations/moveReports';
import {
  removeReport,
  RemoveReportData,
  RemoveReportsVariables,
} from '../../../../../graphql/mutations/removeReport';
import {
  moveProject,
  MoveProjectData,
  MoveProjectVariables,
} from '../../../../../graphql/mutations/moveProject';
import {
  copyProject,
  CopyProjectData,
  CopyProjectVariables,
} from '../../../../../graphql/mutations/copyProject';
import useUpdateList from '../../../../../graphql/mutations/UpdateList';

// Types
import { ModifySpaceObjectModalState } from '../../../../../constants/types';
import { getInternalFolders } from '../../../../../graphql/queries/getInternalFolders';
import { getExternalFolders } from '../../../../../graphql/queries/getExternalFolders';
import { getNestedFolders } from '../../../../../graphql/queries/getNestedFolders';

// Hooks
import { useDispatchContext, useStateContext } from '../../../../../store';
import { usePopperRef } from './CreateSpaceModal/hooks/usePopperRef';
import { useSetFilters } from '../../../../../hooks/SetFilters';

// Components
import { FoldersPopper, SpacesPopper } from './CreateSpaceModal/components';

export const MODIFY_SPACE_TYPE_VALUES = {
  rename: 'rename',
  moveTo: 'moveTo',
  makeCopy: 'makeCopy',
  delete: 'delete',
  unassignedProject: 'unassignedProject',
};

export const ModifySpaceObjectModal = () => {
  const classes = useStyles();
  const dispatch = useDispatchContext();
  const state = useStateContext();
  const setFilters = useSetFilters();

  // The state of the modify space object modal
  const {
    isModalOpen,
    typeOfModification,
    objectToModify,
    objectType,
  }: ModifySpaceObjectModalState = state.modifySpaceObjectModalState;

  const rootSpace = idx(state, (data: any) => data.currentUser.workspace) || {};
  const nexddFilter =
    idx(state, (s) => s.currentUser.filters.nexddFilter) || {};
  const selectedId =
    nexddFilter.selectedFolderId ||
    nexddFilter.selectedSpaceId ||
    rootSpace.id ||
    null;

  // The selected folder inside of the space the object is being moved to (can be empty)
  const [folder, setFolder] = useState<any>(false);

  const [folderFilter, setFolderFilter] = useState('');

  // The selected space the space object is being moved/copied
  const [currentSpace, setCurrentSpace] = useState({
    id: nexddFilter.selectedSpaceId,
    name: '',
  });

  // The new name of the space object used for copying and renaming
  const [name, setName] = useState('');

  // Hooks for handling poppers ref and toggling them to be open or not
  const [
    changeSpaceAnchorRef,
    changeSpaceAnchorEl,
    handleSpaceToggle,
  ] = usePopperRef();
  const [
    changeFolderAnchorRef,
    changeFolderAnchorEl,
    handleFolderToggle,
  ] = usePopperRef();

  // Fetching the space data
  const currentWorkspaceData = useQuery(getWorkspace, {
    variables: {
      spaceId: selectedId,
    },
  });
  const internalSpacesQuery: any = useQuery(getInternalFolders, {
    variables: {
      spaceId: rootSpace.id
    }
  });
  const externalSpacesQuery: any = useQuery(getExternalFolders, {
    variables: {
      spaceId: rootSpace.id
    }
  });

  const internalFoldersQuery: any = useQuery(getNestedFolders, {
    variables: { spaceId: currentSpace?.id || selectedId },
  });

  // Consolidating folders/spaces for easier use in displaying
  const internalSpaces = internalSpacesQuery.data?.getInternalFolders || [];
  const externalSpaces = externalSpacesQuery.data?.getExternalFolders || [];
  const spaces = [...internalSpaces, ...externalSpaces];

  const folders =
    internalFoldersQuery?.data?.getNestedFolders?.filter(
      (dataItem: any) => dataItem.isDashboard === false
    ) || [];

  // Files mutations
  const [renameFileObject] = useMutation<
    UpdateFileNameData,
    UpdateFileNameVariables
  >(updateFileName);
  const [copyFilesObject] = useMutation<CopyFilesData, CopyFileVariables>(
    copyFiles
  );
  const [moveFilesObject] = useMutation<MoveFilesData, MoveFilesVariables>(
    moveFiles
  );
  const [removeFileObject] = useMutation<RemoveFileData, RemoveFileVariables>(
    removeFile
  );

  // File handler
  const handleFileSubmit = () => {
    if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.rename) {
      return renameFileObject({
        variables: {
          fileId: objectToModify.id,
          name: name,
        },
      });
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.moveTo) {
      return moveFilesObject({
        variables: {
          fileVersionIds: [objectToModify.id],
          workspaceId: selectedId,
          destinationWorkspaceId: folder.id || currentSpace.id,
        },
      });
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.makeCopy) {
      return copyFilesObject({
        variables: {
          names: [name],
          fileVersionIds: [objectToModify.id],
          workspaceId: selectedId,
          destinationWorkspaceId: folder.id || currentSpace.id,
        },
      });
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.delete) {
      return removeFileObject({
        variables: {
          fileVersionId: objectToModify.id,
          deleteForAll: true,
        },
      });
    }
  };

  // Workspace mutations
  const [renameWorkspaceObject] = useMutation<
    UpdateWorkspaceData,
    UpdateWorkspaceVariables
  >(updateWorkspace);
  const [copyWorkspaceObject] = useMutation<
    CopyWorkspaceData,
    CopyWorkspaceVariables
  >(copyWorkspace);
  const [moveWorkspaceObject] = useMutation<
    MoveWorkspaceData,
    MoveWorkspaceVariables
  >(moveWorkspace);
  const [removeWorkspaceObject] = useMutation<
    RemoveWorkspaceData,
    RemoveWorkspaceVariables
  >(removeWorkspace);

  // Workspace handler
  const handleWorkspaceSubmit = () => {
    if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.rename) {
      return renameWorkspaceObject({
        variables: {
          id: objectToModify.id,
          name: name,
        },
      });
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.makeCopy) {
      return copyWorkspaceObject({
        variables: {
          workspaceId: objectToModify.id,
          destinationWorkspaceId: folder.id || currentSpace.id,
        },
      });
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.moveTo) {
      return moveWorkspaceObject({
        variables: {
          workspaceId: objectToModify.id,
          destinationWorkspaceId: folder.id || currentSpace.id,
        },
      });
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.delete) {
      return removeWorkspaceObject({
        variables: {
          workspaceId: objectToModify.id,
        },
      });
    }
  };

  // Reports mutations
  const [copyReportsObject] = useMutation<
    CopyReportsData,
    CopyReportsVariables
  >(copyReports);
  const [moveReportsObject] = useMutation<
    MoveReportsData,
    MoveReportsVariables
  >(moveReports);
  const [removeReportsObject] = useMutation<
    RemoveReportData,
    RemoveReportsVariables
  >(removeReport);

  // Reports handler
  const handleReportSubmit = () => {
    if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.moveTo) {
      return moveReportsObject({
        variables: {
          dataReportIds: [objectToModify.id],
          workspaceId: selectedId,
          destinationWorkspaceId: folder.id || currentSpace.id,
        },
      });
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.makeCopy) {
      return copyReportsObject({
        variables: {
          names: [name],
          dataReportIds: [objectToModify.id],
          workspaceId: selectedId,
          destinationWorkspaceId: folder.id || currentSpace.id,
        },
      });
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.delete) {
      return removeReportsObject({
        variables: {
          dataReportId: objectToModify.id,
        },
      });
    }
  };

  // Projects mutations
  const [renameProjectObject] = useUpdateList({
    list: {
      id: objectToModify?.id,
      name: name,
    },
  });
  const [moveProjectObject] = useMutation<
    MoveProjectData,
    MoveProjectVariables
  >(moveProject);
  const [copyProjectObject] = useMutation<
    CopyProjectData,
    CopyProjectVariables
  >(copyProject);

  // Projects handler
  const handleProjectSubmit = () => {
    if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.rename) {
      return renameProjectObject();
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.moveTo) {
      return moveProjectObject({
        variables: {
          workspaceId: selectedId,
          listId: objectToModify.id,
          destinationWorkspaceId: folder.id || currentSpace.id,
        },
      });
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.makeCopy) {
      return copyProjectObject({
        variables: {
          workspaceId: selectedId,
          listId: objectToModify.id,
          destinationWorkspaceId: folder.id || currentSpace.id,
        },
      });
    } else if (
      typeOfModification === MODIFY_SPACE_TYPE_VALUES.unassignedProject
    ) {
      return moveProjectObject({
        variables: {
          workspaceId: state.currentUser.workspace.id,
          listId: objectToModify.id,
          destinationWorkspaceId: folder.id || currentSpace.id,
        },
      });
    }
  };

  // The on submit for the modal
  const handleModifySpaceObjectSubmit = async () => {
    if (objectType === 'file') {
      await handleFileSubmit();
    } else if (objectType === 'workspace') {
      await handleWorkspaceSubmit();
    } else if (objectType === 'report') {
      await handleReportSubmit();
    } else if (objectType === 'project') {
      await handleProjectSubmit();
    }

    if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.unassignedProject) {
      setFilters({
        nexddFilter: {
          selectedSpaceId: folder.id || currentSpace.id,
          selectedFolderId: null,
          selectedReportId: null,
          selectedDashboardId: null,
          selectedProjectId: objectToModify.id,
          selectedItemParentId: null,
        },
      });
    }

    // Flag to left nav that something has changed and refetch the data
    dispatch({
      type: 'SET_IS_SPACE_UPDATED',
      isUpdated: true,
    });

    // Clean up the modal state
    dispatch({
      type: 'SET_MODIFY_SPACE_OBJECT_MODAL',
      modifySpaceObjectModalState: {
        isModalOpen: false,
        typeOfModification: null,
        objectToModify: null,
        objectType: null,
      },
    });
  };

  useEffect(() => {
    if (spaces.length > 0) {
      setCurrentSpace(spaces[0]);
    }
  }, [spaces]);

  // Reset the folder if the space changes so that only folders in
  // the selected space are showing
  useEffect(() => {
    setFolder(false);
    internalFoldersQuery.refetch();
  }, [currentSpace]);

  // Initially set name to the objects name
  useEffect(() => {
    setName(
      state.modifySpaceObjectModalState.objectToModify?.fileName ||
      state.modifySpaceObjectModalState.objectToModify?.name ||
      ''
    );
  }, [state.modifySpaceObjectModalState.objectToModify]);

  // Clean up the modal state onClose
  const handleModalClose = () => {
    // This is to handle if the user doesn't click the assign button and stops
    // them from creating projects in the Home workspace or not in any workspace
    // at all.
    if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.unassignedProject) {
      moveProjectObject({
        variables: {
          workspaceId: state.currentUser.workspace.id,
          listId: objectToModify.id,
          destinationWorkspaceId: folder.id || currentSpace.id,
        },
      }).then(() => {
        dispatch({
          type: 'SET_IS_SPACE_UPDATED',
          isUpdated: true,
        });
        setFilters({
          nexddFilter: {
            selectedSpaceId: folder.id || currentSpace.id,
            selectedFolderId: null,
            selectedReportId: null,
            selectedDashboardId: null,
            selectedProjectId: objectToModify.id,
            selectedItemParentId: null,
          },
        });
      });
    }

    dispatch({
      type: 'SET_MODIFY_SPACE_OBJECT_MODAL',
      modifySpaceObjectModalState: {
        isModalOpen: false,
        typeOfModification: null,
        objectToModify: null,
        objectType: null,
      },
    });
  };

  // Handler for clicking the select a folder input to show the popper
  const handleFolderInputClick = () => {
    setFolder(false);
    handleFolderToggle();
  };

  // Dynamic title text depending on action
  const displayModalTitle = () => {
    if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.moveTo) {
      return 'Move to another space or folder';
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.makeCopy) {
      return 'Make a copy';
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.rename) {
      return 'Rename';
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.delete) {
      return 'Delete an object';
    } else if (
      typeOfModification === MODIFY_SPACE_TYPE_VALUES.unassignedProject
    ) {
      return 'Assign project to a workspace';
    }
  };

  // Dynamic button text depending on action
  const displayButtonText = () => {
    if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.moveTo) {
      return 'Move';
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.makeCopy) {
      return 'Make a copy';
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.rename) {
      return 'Rename';
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.delete) {
      return 'Delete';
    } else if (
      typeOfModification === MODIFY_SPACE_TYPE_VALUES.unassignedProject
    ) {
      return 'Assign';
    }
  };

  // Dynamic description depending on action
  const displayDescriptionText = () => {
    if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.moveTo) {
      return 'Select a new location';
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.makeCopy) {
      return 'Enter a name and select its location';
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.rename) {
      return 'Enter a new name for the object';
    } else if (typeOfModification === MODIFY_SPACE_TYPE_VALUES.delete) {
      return 'Click delete to remove this object from the current space';
    } else if (
      typeOfModification === MODIFY_SPACE_TYPE_VALUES.unassignedProject
    ) {
      return 'Select a location to assign this project to';
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={isModalOpen}
      onClose={handleModalClose}
    >
      <Box className={classes.createContainer}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.titleHeading}>
            {displayModalTitle()}
          </Typography>
          <CloseIcon className={classes.closeIcon} onClick={handleModalClose} />
        </Box>
        <Box className={classes.inputContainer}>
          <Typography className={classes.t2}>
            {displayDescriptionText()}
          </Typography>

          {(typeOfModification === MODIFY_SPACE_TYPE_VALUES.rename ||
            typeOfModification === MODIFY_SPACE_TYPE_VALUES.makeCopy) && (
              <TextField
                value={name}
                onChange={(event: any) => setName(event.target.value)}
                className={classes.formInput}
                variant='outlined'
                placeholder='Enter name here'
              />
            )}
        </Box>
        {typeOfModification !== MODIFY_SPACE_TYPE_VALUES.rename &&
          typeOfModification !== MODIFY_SPACE_TYPE_VALUES.delete && (
            <Box className={classes.formContainer}>
              <Typography className={classes.marginTop}>
                <strong>Location</strong>
              </Typography>
              <Box className={classes.workspaceContainer}>
                <Typography
                  ref={changeSpaceAnchorRef}
                  onClick={handleSpaceToggle}
                  className={classes.spaceInput}
                >
                  <span className={clsx(classes.editable)}>
                    {currentSpace?.name}
                  </span>
                </Typography>

                <Typography
                  style={{
                    flexBasis: '5%',
                    color: '#60606080',
                  }}
                >
                  |
                </Typography>
                <Input
                  style={{
                    color: '#60606080',
                    flexBasis: '40%',
                  }}
                  className={classes.removePadding}
                  value={folder ? folder.name : folderFilter}
                  onChange={(event: any) => setFolderFilter(event.target.value)}
                  ref={changeFolderAnchorRef}
                  onClick={handleFolderInputClick}
                  placeholder={'Search subfolders here...'}
                  disableUnderline
                />
              </Box>
              <SpacesPopper
                selectCreateOption={setCurrentSpace}
                anchorEl={changeSpaceAnchorEl}
                setAnchorEl={handleSpaceToggle}
                spaces={spaces}
              />
              <FoldersPopper
                selectCreateOption={setFolder}
                anchorEl={changeFolderAnchorEl}
                setAnchorEl={handleFolderToggle}
                folders={folders}
              />
            </Box>
          )}
        <Box className={classes.actionContainer}>
          <Button
            className={classes.button}
            variant='contained'
            onClick={handleModifySpaceObjectSubmit}
          >
            {displayButtonText()}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

type TypeOfModification =
  | 'rename'
  | 'moveTo'
  | 'makeCopy'
  | 'delete'
  | 'unassignedProject'
  | null;

type ObjectType = 'file' | 'workspace' | 'report' | 'project' | null;

export const useModifySpaceObjectModalState = () => {
  const dispatch = useDispatchContext();
  return (
    isModalOpen: boolean,
    typeOfModification: TypeOfModification,
    objectToModify: any,
    objectType: ObjectType
  ) =>
    dispatch({
      type: 'SET_MODIFY_SPACE_OBJECT_MODAL',
      modifySpaceObjectModalState: {
        isModalOpen: isModalOpen,
        typeOfModification: typeOfModification,
        objectToModify: objectToModify,
        objectType: objectType,
      },
    });
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    createContainer: {
      background: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      width: '480px',
      borderRadius: '2px',
      '&:focus': {
        outline: 'none',
      },
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem',
      borderBottom: '.5px solid #CDCDCD',
    },
    titleHeading: {
      font: 'normal normal bold 18px/22px Montserrat',
      fontSize: '18px',
      color: '#2C2C2C',
      fontWeigh: '800',
    },
    closeIcon: {
      color: '#60606080',
      cursor: 'pointer',
    },
    formContainer: {
      padding: '0 1rem 1rem 1rem',
    },
    actionContainer: {
      borderTop: '.5px solid #CDCDCD',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    inputContainer: {
      padding: '1rem 1rem 1rem 1rem',
    },
    formInput: {
      width: '100%',
      marginTop: '1rem',
      '& .MuiOutlinedInput-input': {
        padding: '12px',
        fontWeight: 500,
        color: '#606060',
      },
    },
    marginTop: {
      marginTop: '.5rem',
    },
    workspaceContainer: {
      display: 'flex',
      marginTop: '.5rem',
      alignItems: 'center',
    },
    editable: {
      borderBottom: '1px dashed #3A84FF80',
      cursor: 'pointer',
    },
    t2: {
      fontSize: '12px',
      lineHeight: '15px',
      color: '#707070',
      fontWeight: 'normal',
      whiteSpace: 'nowrap',
    },
    button: {
      width: '130px',
    },
    spaceInput: {
      flexBasis: '50%',
      fontSize: '12px',
      fontFamily: 'Montserrat',
      color: '#606060',
      '& .MuiInput-underline:before': {
        border: 'none',
      },
    },
    removePadding: {
      '& .MuiInputBase-input': {
        padding: '0px',
      },
    },
  })
);
