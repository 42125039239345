import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import LoadingFallback from '../common/LoadingFallback';
import NotFoundPage from '../common/NotFoundPage';
import CreateTaskPage from '../task/CreateTaskPage';
import FilesPage from '../file/FilesPage';
import CopyListPage from '../list/CopyListPage';
import ManageFilesPage from '../file/ManageFilesPage';
import SignInPage from '../auth/SignInPage';
import { ReconSummary } from '../recons/ReconSummary';
import { ReconSettings } from '../recons/ReconSettings';

import {  useStateContext} from '../../store';
import CreateProjectPage from '../list/CreateProjectPage';

const AuthRoutes = lazy(() => import('./auth'));
const AppRoutes = lazy(() => import('./app'));
const ModuleRoutes = lazy(() => import('./migration'));

const PrivateRoute = (props: any) => {
  const {component: Component, ...rest} = props;
  const state = useStateContext();
  const isAuthenticated = state.currentUser.email ? true : false;

  return (
    <Route
      {...rest}
      render={(props: any) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : <SignInPage />
      }
    />
  );
};

export default function Router() {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <BrowserRouter>
        <Switch>
          <PrivateRoute exact path='/app/recon_settings' component={ReconSettings} />
          <PrivateRoute exact path='/app/recon_summary' component={ReconSummary} />
          <PrivateRoute path="/app" component={AppRoutes} />
          <PrivateRoute path="/create/list" component={CreateProjectPage} />
          <PrivateRoute path="/copy/list" component={CopyListPage} />
          <PrivateRoute path="/create/task" component={CreateTaskPage} />
          <PrivateRoute path="/files" component={FilesPage} />
          <PrivateRoute exact path="/copy/managefiles/" component={ManageFilesPage} />
          <Route path="/dasdev" component={ModuleRoutes} />
          <Route exact path="/notfound" component={NotFoundPage} />
          <Route path="/" component={AuthRoutes} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}
