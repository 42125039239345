// TODO -- type this:
interface FileProperties {
  comment?: string;
  labelName: string;
  listId: string;
  taskId: string;
  version?: any;
};

import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router';
import clsx from 'clsx';
import idx from 'idx';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

import Panel from '../../../../common/Panel';
import SingleTask from './components/SingleTask';
import MultipleTasks from './components/MultipleTasks';

import ListsQuery from '../../../../../graphql/queries/UploadLists';
import { useLazyUploadPageTasks } from '../../../../../graphql/queries/UploadTasks';
import TaskFiles from '../../../../../graphql/queries/TaskFiles';

import { useDispatchContext, useStateContext } from '../../../../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0px calc((100% - 1300px) / 2) 0px calc((100% - 1300px) / 2)',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

const labels = [
  { label: 'Single Task' },
  { label: 'Multiple Tasks' },
];

function Body(props: any) {
  const classes = useStyles();
	const {history, location} = props;

	// Load in all lists for a user && all tasks for a user.
  const [lists, setLists] = useState<any[]>([]);
  const [tasks, setTasks] = useState<any[]>([]);
  const [selectedListId, setSelectedListId] = useState<string>('');
  const [selectedTaskId, setSelectedTaskId] = useState<string>('');
  const {loading, data, error} = ListsQuery({});
  const [getTasks, {loading: tasksLoading, data: tasksData, error: tasksError}] = useLazyUploadPageTasks({listId: selectedListId});

  const [status, setStatus] = useState<any>('');
	// load in files, from the drag an drop
  // metadata for files kept in a separate array, rather than adding extra properties to File JS object
  const [files, setFiles] = useState<any[]>([]);
	const [fileProperties, setFileProperties] = useState<any[]>([]);

	const state = useStateContext(); // side panel uploads
	const {loading: filesLoading, data: filesData, error: filesError} = TaskFiles({id: selectedTaskId})

	useEffect(() => {
		var taskFiles = idx(filesData, filesData => filesData.task.files)
		if (!filesLoading && taskFiles) {
			let fileProps = fileProperties.slice();

			fileProps = fileProps.map((o:any)=> ({...o, files: taskFiles}));
			setFileProperties(fileProps);
		}
	} , [filesLoading])

	// Loads in lists
  useEffect(() => {
    var allLists = idx(data, data => data.userLists.lists);

    if (loading) return;
    if (allLists && allLists.length) {
      setLists(allLists
			.filter(list => !(list as any).isArchived)
			.filter(list => !(list as any).isDeleted)
			.map(list => ({label: list.name, value: list.id, companyPosition: (list as any).companyPosition, deliverFileOnUpload: (list as any).deliverFileOnUpload})));
    }
  }, [loading]);

	// loads in tasks
  useEffect(() => {
    var tasks = idx(tasksData, tasksData => tasksData.list.tasks);

    if (!tasks) { return };
    if (tasks) {
			// todo: use destructuring, make this prettier -- good opp to build a test
      setTasks(tasks.map((task: any) => ({primaryLabel: task.primaryLabel, secondaryLabel: task.secondaryLabel, list: task.list, priority: task.priority, companyPosition: props.isCreateProject ? props.companyPosition : task.companyPosition, listId: task.listId, listNumber: task.listNumber, label: task.name, value: task.id, status: task.status})));
    }
  }, [tasksData]);

	// importing the files -- dropped on a task row
  useEffect(() => {
    var files = idx(location, location => location.files.files);
    var listId = idx(location, location => location.files.listId);
    var taskId = idx(location, location => location.files.taskId);
		var status = idx(location, location => location.files.status);
    getTasks();

    if (files) {
			setFileProperties(files.map( (file:any) => {
				return {
					labelName: file.name,
					comment: '',
					version: state.versionUpload,
          primarySource: state.taskUpload.reconTag === 'primary',
          secondarySource: state.taskUpload.reconTag === 'secondary'
				}
			}));
			setFiles(files);
		}

		if (status) setStatus(status);
    if (listId) setSelectedListId(listId);
    if (taskId) setSelectedTaskId(taskId);
  }, [location]);

	// importing the files -- dropped on a task side panel
  useEffect(() => {
		var sidePanel = idx(location, location => location.files.panelDrop);
		if (sidePanel) {

		var {files} = location.files
    if (files.files) {
			setFileProperties(files.map( (file:any) => {
				return {
					labelName: file.name,
					comment: '',
					version: state.versionUpload
				}
			}));
			setFiles(files);
		}
			setStatus(state.taskUpload.taskStatus);
      setSelectedListId(state.taskUpload.listId);
			setSelectedTaskId(state.taskUpload.taskId);
		}
	}, [location])

  return (
    <div className={classes.root}>
      <Typography variant="h2">Add Files</Typography>
      <Panel title="Add Files" labels={labels}>
        <SingleTask
          files={files}
					setFiles={setFiles}
					lists={lists}
					tasks={tasks}
          status={status}
					setStatus={setStatus}
					selectedListId={selectedListId}
					setSelectedListId={setSelectedListId}
					selectedTaskId={selectedTaskId}
          setSelectedTaskId={setSelectedTaskId}
					fileProperties={fileProperties}
          setFileProperties={setFileProperties}
          isCreateProject = {props.isCreateProject}
          filesUploaded = {props.filesUploaded}
				/>
        <MultipleTasks
          files={files}
					setFiles={setFiles}
					lists={lists}
					tasks={tasks}
					setTasks={setTasks}
          status={status}
					setStatus={setStatus}
					selectedListId={selectedListId}
					setSelectedListId={setSelectedListId}
					selectedTaskId={selectedTaskId}
          setSelectedTaskId={setSelectedTaskId}
					fileProperties={fileProperties}
          setFileProperties={setFileProperties}
          isCreateProject = {props.isCreateProject}
          filesUploaded = {props.filesUploaded}
				/>
      </Panel>
    </div>
  );
}

export default withRouter(Body);
