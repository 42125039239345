import { gql } from '../graphqlHelpers';

export const shareWorkspaceViaUrl = gql`
  mutation ShareWorkspaceViaUrl ($accessUrl: String!) {
    shareWorkspaceViaUrl (  accessUrl: $accessUrl){
      errors {
        path
        message
      }
      success
      currentWorkspaceId
    }
  }
`;