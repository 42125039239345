import React, { useState, useEffect, useRef } from "react";
import { Typography, Link, Button } from "@material-ui/core";
import idx from "idx";
import axios from "axios";

import { IntacctPopper } from "./IntacctPopper";
import { useUpdateTaskRecon } from "../../../../../../../../../graphql/mutations/UpdateTaskRecon";
import { useRefreshIntacctReconSource } from "../../../../../../../../../graphql/mutations/RefreshIntacctReconSource";
import { useRefreshQboReconSource } from "../../../../../../../../../graphql/mutations/RefreshQBOReconSource";
import { QBOPopper } from "./QBOPopper";
import { NetsuitePopper } from "./NetsuitePopper";

import { netsuiteValueQuery } from '../../../../../../../../../graphql/queries/NetsuiteValue';
import { useApolloClient } from '@apollo/client';
import { buildAdjBookParams, buildParams, formatDateForDatePicker } from "../../../../../../../../../constants/dimensions";
import { useLazyAdjBookQuery } from "../../../../../../../../../graphql/queries/AdjBook";
import { useDispatchContext } from "../../../../../../../../../store";
import { useUpdateTaskZeroBalance } from "../../../../../../../../../graphql/mutations/UpdateTaskZeroBalance";
import { INTEGRATION_SOURCE_TYPE } from "../../../../../../../../../constants/integration_source_type";


const confirmIntacctSave = (
  task: any,
  sourceType: any,
  { state }: any,
  updateTaskRecon: any,
  updateTaskReconCallback: any
) => {
  const updatedValue = {
    taskId: task.id,
    sourceType: sourceType,
    valueSource: INTEGRATION_SOURCE_TYPE.INTACCT,
    integrationInfo: {
      inEntities: idx(state, (s: any) => s.inEntities),
      inDepartments: idx(state, (s: any) => s.inDepartments),
      inUnderliers: idx(state, (s: any) => s.inUnderliers),
      inAccounts: idx(state, (s: any) => s.inAccounts),
      inServices: idx(state, (s: any) => s.inServices),
      inCustomers: idx(state, (s: any) => s.inCustomers),
      inVendors: idx(state, (s: any) => s.inVendors),
      inAccountingMethod: idx(state, (s: any) => s.inAccountingMethod),
      inStartDate: formatDateForDatePicker(state.inStartDate),
      inEndDate: formatDateForDatePicker(state.inEndDate),
      startDateLocked: state.startDateLocked,
      endDateLocked: state.endDateLocked,
      invert: state.invert || false,
    },
    refreshSource: state.refreshSource,
    responseCallback: updateTaskReconCallback
  };
  updateTaskRecon(updatedValue);
};

const confirmQBOSave = (
  task: any,
  sourceType: any,
  { state }: any,
  updateTaskRecon: any,
  updateTaskReconCallback: any
) => {
  const updatedValue = {
    taskId: task.id,
    sourceType: sourceType,
    valueSource: INTEGRATION_SOURCE_TYPE.QBO,
    integrationInfo: {
      qboIntegration: idx(state, (s: any) => s.qboIntegration),
      qboAccounts: idx(state, (s: any) => s.accounts),
      qboNames: idx(state, (s: any) => s.names),
      qboClasses: idx(state, (s: any) => s.classes),
      qboLocations: idx(state, (s: any) => s.locations),
      qboTypes: idx(state, (s: any) => s.qboTypes),
      qboAccountingMethod: idx(state, (s: any) => s.accountingMethod),
      qboStartDate: formatDateForDatePicker(state.qboStartDate),
      qboEndDate: formatDateForDatePicker(state.qboEndDate),
      startDateLocked: state.startDateLocked,
      endDateLocked: state.endDateLocked,
    },
    refreshSource: state.refreshSource,
    responseCallback: updateTaskReconCallback
  };
  updateTaskRecon(updatedValue);
};

const confirmNetsuiteSave = (
  task: any,
  sourceType: any,
  { state }: any,
  updateTaskRecon: any,
  updateTaskReconCallback: any
) => {

  const updatedValue = {
    taskId: task.id,
    sourceType: sourceType,
    valueSource: INTEGRATION_SOURCE_TYPE.NETSUITE,
    integrationInfo: {
      nsSubsidiaries: idx(state, (s: any) => s.nsSubsidiaries),
      nsDepartments: idx(state, (s: any) => s.nsDepartments),
      nsClasses: idx(state, (s: any) => s.nsClasses),
      nsAccounts: idx(state, (s: any) => s.nsAccounts),
      nsItems: idx(state, (s: any) => s.nsItems),
      nsEntities: idx(state, (s: any) => s.nsEntities),
      nsBooks: idx(state, (s: any) => s.nsBooks),
      nsStartDate: state?.nsStartDate?.value,
      nsEndDate: state?.nsEndDate?.value,
      startDateLocked: state.startDateLocked,
      endDateLocked: state.endDateLocked,
      invert: state.invert,
      currency: state.currency,
    },
    responseCallback: updateTaskReconCallback
  };
  
  updateTaskRecon(updatedValue);
};

interface IntegrationInfoReconPopperProps {
  erpSourceType: number;
  task: any;
  updateTask: any;
  updateTaskInCache: any;
  sourceType: any;
  project: any;
  updatePrimaryDisplaySkeletons: any;
  updateSecondaryDisplaySkeletons: any;
  onPopoverLeave?: any;
  updateTaskStatus: (task: any, status: string, isAutomation: boolean) => void;
}

export function IntegrationInfoReconPopper({
  erpSourceType,
  task,
  updateTask,
  updateTaskStatus,
  updateTaskInCache,
  sourceType,
  project,
  updatePrimaryDisplaySkeletons,
  updateSecondaryDisplaySkeletons,
  onPopoverLeave,
}: IntegrationInfoReconPopperProps) {
  // set to true when there are unsaved values in the popper
  const [books, setBooks] = useState<any>({});
  const [hasUnsavedValues, setHasUnsavedValues] = useState(false);
  const [infoChanged, setInfoChanged] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const updateTaskRecon = useUpdateTaskRecon();
  const dispatch = useDispatchContext();

  const apolloClient = useApolloClient();
  const netsuiteValueAbortControllerRef = useRef<any>(null);

  // Fetch the current task account AdjBooks
  const { loading, data, error } = useLazyAdjBookQuery({ taskId: task.id });

  useEffect(() => { onPopoverLeave() }, []);

  useEffect(() => {
    if (data) {
      setBooks(data)
    }
  }, [data]);

  const optimistic = books.adjBook && books.adjBook.map((adj: any) => ({ 'adjbook' : adj.name }));

  const companyAdjBooks = (optimistic && optimistic.length > 0) ? optimistic : [{adjbook : 'GAAP'}, {adjbook : 'TAX'}];
  
  const [refreshIntacctMutation, refreshIntacctMutationResponse] = useRefreshIntacctReconSource({
    reconSourceId: idx(task, (t: any) => t[`${sourceType}Source`].id),
    taskId: task.id,
  });

  const [refreshQboMutation, refreshQboMutationResponse] = useRefreshQboReconSource({
    reconSourceId: idx(task, (t: any) => t[`${sourceType}Source`].id),
    taskId: task.id,
  });

  let integrationName = "";
  let integrationLoginUrl = "";
  if (erpSourceType === INTEGRATION_SOURCE_TYPE.INTACCT) {
    integrationName = "Intacct";
    integrationLoginUrl = "https://www.intacct.com/ia/acct/login.phtml";
  }

  if (erpSourceType === INTEGRATION_SOURCE_TYPE.QBO) {
    integrationName = "QuickBooks Online"
    integrationLoginUrl = "https://quickbooks.intuit.com/sign-in-offer/";
  }

  //TODO ADD THE INTEGRATION LOGIN URL
  if (erpSourceType === INTEGRATION_SOURCE_TYPE.NETSUITE) {
    integrationName = "NetSuite";
    integrationLoginUrl = ""
  }
  /** ====================================INTACCT=============================== */


  // const source = task[`${sourceType}Source`];
  // const info = idx(source, (s: any) => s.integrationInfo);

  const [source, setSource] = useState(task[`${sourceType}Source`]);
  const [info, setInfo] = useState(idx(source, (s: any) => s.integrationInfo));

  const projectReconEnd = idx(
    project,
    (p: any) => p.reconEndDate && new Date(p.reconEndDate)
  );

  const [currency, setCurrency] = useState(
    idx(source, (source: any) => source.integrationInfo.currency)
  );

  const [tokenSource, setTokenSource] = useState({} as any);
  // used to disable the useEffect from running during the initial render
  const initialRender = useRef(true);

  useEffect(() => {
    return () => {
      if (tokenSource && tokenSource.cancel) {
        tokenSource.cancel(
          "Component unmounted, cancelling request"
        )
      }
    }
  }, [])

  useEffect(() => {
    if (refreshIntacctMutationResponse.called && !refreshIntacctMutationResponse.loading) {

      //This is really bad here, we're missing alot of Typing around our mutations, so I'm having to work around TypeScript until we have time to refactor
      const refreshMutationResponseAsAny = refreshIntacctMutationResponse as any;

      let updatedTask;
      if (refreshMutationResponseAsAny && refreshMutationResponseAsAny.data) {
        updatedTask = refreshMutationResponseAsAny.data.refreshIntacctReconSource.task
        onPopoverLeave();
      }

      // This block is very fuzzy and not seems logical. Need to refactor!
      if (updatedTask) {
        updateTaskInCache({...task, ...updatedTask});
        updateTask({...task, ...updatedTask});
      }
      
      setDisableButton(false);
      if (sourceType === 'primary') {
        updatePrimaryDisplaySkeletons(false);
      } else {
        updateSecondaryDisplaySkeletons(false);
      }
    }
  }, [refreshIntacctMutationResponse.called, refreshIntacctMutationResponse.loading, refreshIntacctMutationResponse.data])

  useEffect(() => {
    if (refreshQboMutationResponse.called && !refreshQboMutationResponse.loading) {
      //This is really bad here, we're missing alot of Typing around our mutations, so I'm having to work around TypeScript until we have time to refactor
      const refreshMutationResponseAsAny = refreshQboMutationResponse as any;

      let updatedTask
      if (refreshMutationResponseAsAny && refreshMutationResponseAsAny.data) {
        updatedTask = refreshMutationResponseAsAny.data.refreshQboReconSource.task
      }

      if (updatedTask) {
        updateTaskInCache({ ...task, ...updatedTask });
        updateTask({ ...task, ...updatedTask });
      }

      setDisableButton(false);
      if (sourceType === 'primary') {
        updatePrimaryDisplaySkeletons(false);
      } else {
        updateSecondaryDisplaySkeletons(false);
      }
    }
  }, [refreshQboMutationResponse.called, refreshQboMutationResponse.loading, refreshQboMutationResponse.data])

  //Change the integration info if the task changes
  useEffect(() => {
    setInfoChanged(true);
    setHasUnsavedValues(false);
    setSource(task[`${sourceType}Source`]);
    onPopoverLeave();
  }, [task.id, task])

  //Update the source if a user clicks the source button while the popper is currently open
  useEffect(() => {
    if (initialRender.current) {
      return;
    }
    setInfoChanged(true);
    setHasUnsavedValues(false);
    setSource(task[`${sourceType}Source`]);
    onPopoverLeave();
  }, [sourceType])

  useEffect(() => {
    setInfo(idx(source, (s: any) => s.integrationInfo))
  }, [source])

  useEffect(() => {
    if (erpSourceType === INTEGRATION_SOURCE_TYPE.INTACCT) {
      setIntacctState({
        inEntities: idx(info, (i: any) => i.inEntities),
        inDepartments: idx(info, (i: any) => i.inDepartments),
        inUnderliers: idx(info, (i: any) => i.inUnderliers),
        inAccounts: idx(info, (i: any) => i.inAccounts),
        inServices: idx(info, (i: any) => i.inServices),
        inCustomers: idx(info, (i: any) => i.inCustomers),
        inVendors: idx(info, (i: any) => i.inVendors),
        inAccountingMethod: idx(info, (i: any) => i.inAccountingMethod?.map((it:any) => ({...it, label: it.label.charAt(0).toUpperCase() + it.label.slice(1)?.toLowerCase()}))) || [],
        inStartDate: idx(info, (i: any) => formatDateForDatePicker(i.inStartDate)) || formatDateForDatePicker(new Date(2000, 0, 1)),
        inEndDate: idx(info, (i: any) => formatDateForDatePicker(i.inEndDate)) || projectReconEnd || formatDateForDatePicker(new Date()),
        startDateLocked: idx(info, (i: any) => i.startDateLocked) || false,
        endDateLocked: idx(info, (i: any) => i.endDateLocked) || false,
        invert: idx(info, (i: any) => i.invert) || false,
      })
    }

    if (erpSourceType === INTEGRATION_SOURCE_TYPE.QBO) {
      setQBOState({
        qboIntegration: idx(info, (i: any) => i.qboIntegration),
        accounts: idx(info, (i: any) => i.qboAccounts),
        names: idx(info, (i: any) => i.qboNames),
        classes: idx(info, (i: any) => i.qboClasses),
        locations: idx(info, (i: any) => i.qboLocations),
        types: idx(info, (i: any) => i.qboTypes),
        accountingMethod: idx(info, (i: any) => i.qboAccountingMethod) || { label: 'Accrual', value: 'Accrual' },
        qboStartDate: idx(info, (i: any) => formatDateForDatePicker(i.qboStartDate)) || formatDateForDatePicker(new Date(2000, 0, 1)),
        qboEndDate: idx(info, (i: any) => formatDateForDatePicker(i.qboEndDate)) || projectReconEnd || formatDateForDatePicker(new Date()),
        startDateLocked: idx(info, (i: any) => i.startDateLocked) || false,
        endDateLocked: idx(info, (i: any) => i.endDateLocked) || false,
        showQBOEnable: false,
      })
    }

    if (erpSourceType === INTEGRATION_SOURCE_TYPE.NETSUITE) {
      setNsState({
        // nsLocations: idx(info, (i: any) => i.nsLocations),
        nsSubsidiaries: idx(info, (i: any) => i.nsSubsidiaries),
        nsDepartments: idx(info, (i: any) => i.nsDepartments),
        nsClasses: idx(info, (i: any) => i.nsClasses),
        nsAccounts: idx(info, (i: any) => i.nsAccounts),
        nsItems: idx(info, (i: any) => i.nsItems),
        nsEntities: idx(info, (i: any) => i.nsEntities),
        nsBooks: idx(info, (i: any) => i.nsBooks),
        nsStartDate: info?.nsStartDate ? { label: info.nsStartDate, value: info.nsStartDate } : null,
        nsEndDate: info?.nsEndDate ? { label: info.nsEndDate, value: info.nsEndDate } : null,
        startDateLocked: idx(info, (i: any) => i.startDateLocked) || false,
        endDateLocked: idx(info, (i: any) => i.endDateLocked) || false,
        invert: idx(info, (i: any) => i.invert),
        currency: currency,
      })
    }
  }, [info])

  /** intacct state */
  const [intacctState, setIntacctState] = useState({
    inEntities: idx(info, (i: any) => i.inEntities),
    inDepartments: idx(info, (i: any) => i.inDepartments),
    inUnderliers: idx(info, (i: any) => i.inUnderliers),
    inAccounts: idx(info, (i: any) => i.inAccounts),
    inServices: idx(info, (i: any) => i.inServices),
    inCustomers: idx(info, (i: any) => i.inCustomers),
    inVendors: idx(info, (i: any) => i.inVendors),
    inAccountingMethod: idx(info, (i: any) => i.inAccountingMethod?.map((it:any) => ({...it, label: it.label.charAt(0).toUpperCase() + it.label.slice(1)?.toLowerCase()}))) || [],
    inStartDate: idx(info, (i: any) => formatDateForDatePicker(i.inStartDate)) || formatDateForDatePicker(new Date(2000, 0, 1)),
    inEndDate:
      idx(info, (i: any) => formatDateForDatePicker(i.inEndDate)) || projectReconEnd || formatDateForDatePicker(new Date()),
    startDateLocked: idx(info, (i: any) => i.startDateLocked) || false,
    endDateLocked: idx(info, (i: any) => i.endDateLocked) || false,
    invert: idx(info, (i: any) => i.invert) || false,
  });


  /** calculate balance whenver the state gets updated */
  useEffect(() => {
    if (erpSourceType !== INTEGRATION_SOURCE_TYPE.INTACCT) {
      return;
    }

    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (tokenSource && tokenSource.cancel) {
      tokenSource.cancel("Operation canceled due to new request.");
    }

    const source = axios.CancelToken.source();
    setTokenSource(source);

    if (infoChanged) {
      setInfoChanged(false);
    } else {
      setHasUnsavedValues(true);
    }
  }, [
    intacctState.inEntities,
    intacctState.inDepartments,
    intacctState.inUnderliers,
    intacctState.inAccounts,
    intacctState.inServices,
    intacctState.inCustomers,
    intacctState.inVendors,
    intacctState.inAccountingMethod,
    intacctState.inStartDate,
    intacctState.inEndDate,
    intacctState.invert,
  ]);

  // Begin Netsuite code

  /** Netsuite state */
  const [nsState, setNsState] = useState({
    // nsLocations: idx(info, (i: any) => i.nsLocations),
    nsSubsidiaries: idx(info, (i: any) => i.nsSubsidiaries),
    nsDepartments: idx(info, (i: any) => i.nsDepartments),
    nsClasses: idx(info, (i: any) => i.nsClasses),
    nsAccounts: idx(info, (i: any) => i.nsAccounts),
    nsItems: idx(info, (i: any) => i.nsItems),
    nsEntities: idx(info, (i: any) => i.nsEntities),
    nsBooks: idx(info, (i: any) => i.nsBooks),
    nsStartDate: info?.nsStartDate ? { label: info.nsStartDate, value: info.nsStartDate } : null,
    nsEndDate: info?.nsEndDate ? { label: info.nsEndDate, value: info.nsEndDate } : null,
    startDateLocked: idx(info, (i: any) => i.startDateLocked) || false,
    endDateLocked: idx(info, (i: any) => i.endDateLocked) || false,
    invert: idx(info, (i: any) => i.invert),
    currency: currency,
  });

  /** calculate balance whenver the state gets updated */
  useEffect(() => {
    if (erpSourceType !== INTEGRATION_SOURCE_TYPE.NETSUITE) {
      return;
    }

    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (infoChanged) {
      setInfoChanged(false);
    } else {
      setHasUnsavedValues(true);
    }

    if (netsuiteValueAbortControllerRef.current) netsuiteValueAbortControllerRef.current.abort();

    const mappedParamters = {
      subsidiaryNames: nsState?.nsSubsidiaries ? nsState.nsSubsidiaries.map((option: any) => option.value) : [],
      departmentNames: nsState?.nsDepartments ? nsState.nsDepartments.map((option: any) => option.value) : [],
      classNames: nsState?.nsClasses ? nsState.nsClasses.map((option: any) => option.value) : [],
      accountIds: nsState?.nsAccounts ? nsState.nsAccounts.map((option: any) => option.value) : [],
      itemNames: nsState?.nsItems ? nsState.nsItems.map((option: any) => option.value) : [],
      entityNames: nsState?.nsEntities ? nsState.nsEntities.map((option: any) => option.value) : [],
      bookNames: nsState?.nsBooks ? nsState.nsBooks.map((option: any) => option.value) : [],
      startDate: nsState?.nsStartDate ? nsState.nsStartDate?.value : null,
      endDate: nsState?.nsEndDate ? nsState.nsEndDate?.value : null,
    }

    const hasRequiredParameters = mappedParamters.accountIds?.length > 0 && mappedParamters.startDate && mappedParamters.endDate;
    
    if (!hasRequiredParameters) {
      return;
    }
  }, [
    nsState.nsSubsidiaries,
    nsState.nsDepartments,
    nsState.nsClasses,
    nsState.nsAccounts,
    nsState.nsItems,
    nsState.nsEntities,
    nsState.nsBooks,
    nsState.nsStartDate,
    nsState.nsEndDate,
    nsState.startDateLocked,
    nsState.endDateLocked,
    nsState.invert,
    nsState.currency,
  ]);

  const handleNsInvert = (event: { target: { checked: Boolean } }) => {
    setNsState({ ...nsState, ...{ invert: !nsState.invert } });
  };

  //End Netsuite code


  //updating the intacctState will trigger a balance call
  const handleInvert = (event: { target: { checked: Boolean } }) => {
    setIntacctState({ ...intacctState, ...{ invert: !intacctState.invert } });
  };

  /* QBO State */
  const [qboState, setQBOState] = useState({
    qboIntegration: idx(info, (i: any) => i.qboIntegration),
    accounts: idx(info, (i: any) => i.qboAccounts),
    names: idx(info, (i: any) => i.qboNames),
    classes: idx(info, (i: any) => i.qboClasses),
    locations: idx(info, (i: any) => i.qboLocations),
    types: idx(info, (i: any) => i.qboTypes),
    accountingMethod: idx(info, (i: any) => i.qboAccountingMethod) || { label: 'Accrual', value: 'Accrual' },
    qboStartDate: idx(info, (i: any) => formatDateForDatePicker(i.qboStartDate)) || formatDateForDatePicker(new Date(2000, 0, 1)),
    qboEndDate: idx(info, (i: any) => formatDateForDatePicker(i.qboEndDate)) || projectReconEnd || formatDateForDatePicker(new Date()),
    startDateLocked: idx(info, (i: any) => i.startDateLocked) || false,
    endDateLocked: idx(info, (i: any) => i.endDateLocked) || false,
    showQBOEnable: false,
  });

  /** calculate balance whenver the state gets updated */
  useEffect(() => {
    if (erpSourceType !== INTEGRATION_SOURCE_TYPE.QBO) {
      return;
    }

    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (infoChanged) {
      setInfoChanged(false);
    } else {
      setHasUnsavedValues(true);
    }

  }, [
    qboState.qboIntegration,
    qboState.accounts,
    qboState.names,
    qboState.types,
    qboState.classes,
    qboState.locations,
    qboState.accountingMethod,
    qboState.qboStartDate,
    qboState.qboEndDate,
    qboState.startDateLocked,
    qboState.endDateLocked,
  ]);

  const [updateTaskZeroBalance, { data: updateTaskZeroBalanceData, loading: updateTaskZeroBalanceLoading, error: updateTaskZeroBalanceError }] = useUpdateTaskZeroBalance({});

  // Call the GraphQL helper to automatically triger the task zero balance automation handler.
  const handleTaskZeroBalanceAutomation = (updatedTask: any) => {
    updateTaskZeroBalance({
      variables: {
        taskId: task.id
      }
    }).then((response: any) => {
      const updateTaskZeroBalanceData = response?.data?.updateTaskZeroBalance;
      // Errors are currently on the backend side.
      // TODO: Find a way to better handle the errors on the frontend side.
      if (updateTaskZeroBalanceData?.success) {
        updateTaskStatus(updatedTask, task.completedStatusKey, true);
      }
    })
  };

  /**
   * Handles the save button click for the integration popper.
   * @param integrationName - Can be 'Intacct', 'Netsuite', or 'QBO'
   * @param state - The state of the integration popper
   * @param currency - The currency of 'Netsuite' integration (optional)
   */
  const handleIntegrationClick = (integrationName: 'Intacct' | 'Netsuite' | 'QBO', state: any, currency: any, refreshSource: boolean) => {
    let updateTaskReconCallback;
    const isPrimary = sourceType === 'primary';
    const displaySkeleton = isPrimary ? updatePrimaryDisplaySkeletons : updateSecondaryDisplaySkeletons;
    const valueKey = isPrimary ? 'primaryValue' : 'secondaryValue';
    const totalKey = isPrimary ? 'primaryTotal' : 'secondaryTotal';

    // Reset the primary or secondary total state to an empty object
    if (sourceType === 'primary') {
      dispatch({
        type: 'SET_PRIMARY_TOTAL',
        primaryTotal: {}
      });
    } else if (sourceType === 'secondary') {
      dispatch({
        type: 'SET_SECONDARY_TOTAL',
        secondaryTotal: {}
      });
    }

    setDisableButton(true);
    displaySkeleton(true);
  
    updateTaskReconCallback = (data: any, sourceType: any) => {
      if (data?.updateTaskReconSources?.task) {
        const updatedTask = data.updateTaskReconSources.task;
        const newTask = { ...task, ...updatedTask, [valueKey]: updatedTask[totalKey] };
  
        updateTaskInCache(newTask);
        updateTask(newTask);
  
        if (isPrimary && updatedTask.status !== updatedTask.completedStatusKey && (integrationName === 'Intacct' || integrationName === 'Netsuite')) {
          handleTaskZeroBalanceAutomation(newTask);
        }
  
        setDisableButton(false);
        displaySkeleton(false);
      }
    };
  
    switch (integrationName) {
      case 'Intacct':
        confirmIntacctSave(
          task,
          sourceType,
          { state: { ...state, refreshSource: refreshSource } },
          updateTaskRecon,
          updateTaskReconCallback
        );
        break;
      case 'Netsuite':
        confirmNetsuiteSave(
          task,
          sourceType,
          { state: { ...state, currency: currency } },
          updateTaskRecon,
          updateTaskReconCallback
        );
        break;
      case 'QBO':
        confirmQBOSave(
          task,
          sourceType,
          { state: { ...state, refreshSource: refreshSource } },
          updateTaskRecon,
          updateTaskReconCallback
        );
        break;
      default:
        console.error('Unsupported integration:', integrationName);
    }
  
    setHasUnsavedValues(false);
  };  

  const handleSaveClick = () => {
    handleErpSourceTypeClick(false);
  }

  const refreshButtonClick = () => {
    if (
      sourceType === 'primary' || 
      erpSourceType === INTEGRATION_SOURCE_TYPE.INTACCT || 
      erpSourceType === INTEGRATION_SOURCE_TYPE.NETSUITE ||
      erpSourceType === INTEGRATION_SOURCE_TYPE.QBO
      ) {
        handleErpSourceTypeClick(true);
    }
  }

  function handleErpSourceTypeClick(refreshSource: boolean) {
    switch (erpSourceType) {
      case INTEGRATION_SOURCE_TYPE.INTACCT:
        handleIntegrationClick('Intacct', intacctState, currency , refreshSource);
        break;
      case INTEGRATION_SOURCE_TYPE.QBO:
        handleIntegrationClick('QBO', qboState, currency, refreshSource);
        break;
      case INTEGRATION_SOURCE_TYPE.NETSUITE:
        handleIntegrationClick('Netsuite', nsState, currency, refreshSource);
        break;
      default:
        console.error('Invalid integration source type'); // Or throw an error
    }
  }
  
  /**======================================================================================== */
  return (
    <>
      <div className="flex items-center justify-around py-4 border-t border-gray-200">
        <Typography >
          Source
        </Typography>
        <Link color="primary" href={integrationLoginUrl} target="_blank" data-cy='source-type-support-name'>
          {integrationName}
        </Link>

        {hasUnsavedValues ? (
          <Button
            color="primary"
            style={{
              background: "#3A84FF",
              width: 90,
              color: "white",
              padding: 0,
              height: 32,
              opacity: disableButton ? 0.7 : 1
            }}
            onClick={() => handleSaveClick()}
            disabled={disableButton}
          >
            Save
          </Button>
        ) : (
          <Button
            color="primary"
            style={{
              background: "#3A84FF",
              width: 90,
              color: "white",
              padding: 0,
              height: 32,
              opacity: disableButton ? 0.7 : 1
            }}
            onClick={() => refreshButtonClick()}
            disabled={disableButton}
          >
            Refresh
          </Button>
        )}
      </div>

      <hr />

      {erpSourceType === INTEGRATION_SOURCE_TYPE.INTACCT && (
        <IntacctPopperHandler
          task={task}
          sourceType={sourceType}
          state={intacctState}
          setState={setIntacctState}
          handleInvert={handleInvert}
          setDisableButton={setDisableButton}
        />
      )}

      {erpSourceType === INTEGRATION_SOURCE_TYPE.QBO && (
        <QBOPopperHandler
          task={task}
          sourceType={sourceType}
          state={qboState}
          setState={setQBOState}
          setDisableButton={setDisableButton}
        />
      )}

      {erpSourceType === INTEGRATION_SOURCE_TYPE.NETSUITE && (
        <NetsuitePopperHandler
          task={task}
          sourceType={sourceType}
          state={nsState}
          setState={setNsState}
          handleInvert={handleNsInvert}
          setDisableButton={setDisableButton}
        />
      )}
    </>
  );
}

function IntacctPopperHandler({
  task,
  sourceType,
  state,
  setState,
  handleInvert,
  setDisableButton
}: any) {
  return (
    <IntacctPopper
      task={task}
      sourceType={sourceType}
      state={state}
      setState={setState}
      handleInvert={handleInvert}
      setDisableButton={setDisableButton}
    />
  );
}

function QBOPopperHandler({
  task,
  sourceType,
  state,
  setState,
  setDisableButton
}: any) {
  return (
    <QBOPopper
      task={task}
      sourceType={sourceType}
      state={state}
      setState={setState}
      setDisableButton={setDisableButton}
    />
  );
}

function NetsuitePopperHandler({
  task,
  sourceType,
  state,
  setState,
  handleInvert,
  setDisableButton
}: any) {
  return (
    <NetsuitePopper
      task={task}
      sourceType={sourceType}
      state={state}
      setState={setState}
      handleInvert={handleInvert}
      setDisableButton={setDisableButton}
    />
  );
}
