// linted - mpf - 11/20

export enum CalendarType {
  CALENDAR = 'calendar',
  BUSINESS = 'business',
}

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const MONTHLY_CALENDAR_DAYS = [
  ['1', '2', '3', '4', '5', '6', '7'],
  ['8', '9', '10', '11', '12', '13', '14'],
  ['15', '16', '17', '18', '19', '20', '21'],
  ['22', '23', '24', '25', '26', '27', '28'],
  ['29', '30', '31' ]
];

const MONTHLY_BUSINESS_DAYS = [
  ['1', '2', '3', '4', '5'],
  ['6', '7', '8', '9', '10'],
  ['11', '12', '13', '14', '15'],
  ['16', '17', '18', '19', '20'],
  ['21', '22', '23', '24', '25']
];

const MONTHLY_BUSINESS_DAYS_NEGATIVE = [...MONTHLY_BUSINESS_DAYS].map(
  (week: string[]) => [...week].reverse().map((day: string) => '-' + day)
).reverse();

const MONTHLY_CALENDAR_DAYS_NEGATIVE = [...MONTHLY_CALENDAR_DAYS].map(
  (week: string[]) => [...week].reverse().map((day: string) => '-' + day)
).reverse();

const QUARTERLY_BUSINESS_DAYS = [
  [
    ...MONTHLY_BUSINESS_DAYS
  ],
  [
    ['26', '27', '28', '29', '30'],
    ['31', '32', '33', '34', '35'],
    ['36', '37', '38', '39', '40'],
    ['41', '42', '43', '44', '45'],
    ['46', '47', '48', '49', '50']
  ],
  [
    ['51', '52', '53', '54', '55'],
    ['56', '57', '58', '59', '60'],
    ['61', '62', '63', '64', '65'],
    ['66', '67', '68', '69', '70'],
    ['71', '72', '73', '74', '75']
  ]
];

const QUARTERLY_CALENDAR_DAYS = [
  [
    [...MONTHLY_CALENDAR_DAYS[0]],
    [...MONTHLY_CALENDAR_DAYS[1]],
    [...MONTHLY_CALENDAR_DAYS[2]],
    [...MONTHLY_CALENDAR_DAYS[3]],
    [...MONTHLY_CALENDAR_DAYS[4], '32', '33', '34', '35']
  ],
  [
    ['36', '37', '38', '39', '40', '41', '42'],
    ['43', '44', '45', '46', '47', '48', '49'],
    ['50', '51', '52', '53', '54', '55', '56'],
    ['57', '58', '59', '60', '61', '62', '63'],
    ['64', '65', '66', '67', '68', '69', '70']
  ],
  [
    ['71', '72', '73', '74', '75', '76', '77'],
    ['78', '79', '80', '81', '82', '83', '84'],
    ['85', '86', '87', '88', '89', '90', '91'],
    ['92', '93']
  ],
];

const QUARTERLY_BUSINESS_DAYS_NEGATIVE = [...QUARTERLY_BUSINESS_DAYS].map(
  period => [...period].reverse().map(week => [...week].reverse().map((day => '-' + day)))
);

const QUARTERLY_CALENDAR_DAYS_NEGATIVE = [...QUARTERLY_CALENDAR_DAYS].map(
  period => [...period].reverse().map(week => [...week].reverse().map((day => '-' + day)))
);

export const CALENDAR_VIEW_DAYS: any = {
  monthly: {
    business: {
      negative: MONTHLY_BUSINESS_DAYS_NEGATIVE,
      positive: MONTHLY_BUSINESS_DAYS
    },
    calendar: {
      negative: MONTHLY_CALENDAR_DAYS_NEGATIVE,
      positive: MONTHLY_CALENDAR_DAYS
    },
  },
  quarterly: {
    business: {
      negative: QUARTERLY_BUSINESS_DAYS_NEGATIVE,
      positive: QUARTERLY_BUSINESS_DAYS
    },
    calendar: {
      negative: QUARTERLY_CALENDAR_DAYS_NEGATIVE,
      positive: QUARTERLY_CALENDAR_DAYS
    }
  }
};

export const getAllDays = (type:string) => {
  var businessDays = [];
  let least = 0;
  let max = 0;
  let columns = 5;
  let rows = 5;
  switch (type) {
    case CalendarType.BUSINESS: {
      least = -100;
      max = 275;
      rows = 5;
      break;
    }
    case CalendarType.CALENDAR : {
      least = -140;
      max = 365;
      rows = 7;
      break;
    }
    default: null;
  }
  var value = least;
  var period = [];
  var week = [];

  while(value >=least && value <= max ){
    if(value === 0) {
      value++;
      continue;
    }
    period = [];
    for(let i = 0; i < columns; i++){
      week = [];
      if(value < least || value > max){
        break;
      }
      for(let j=0; j < rows; j++ ) {
        if(value < least || value > max){
          break;
        }
        week.push(value.toString());
        value++;
      }
      period.push(week);
    }
    businessDays.push(period);
  }
  return [...businessDays];
}

export const ALL_CALENDAR_VIEW_DAYS: any = {
    business: getAllDays(CalendarType.BUSINESS),
    calendar: getAllDays(CalendarType.CALENDAR)
};
