
import { gql} from '../graphqlHelpers';

type ID = number | string;

export interface RemoveWorkspaceVariables {
  workspaceId: ID;
  deleteForAll?: boolean;
}

export interface Error {
  path: string;
  message: string;
}

export interface RemoveWorkspaceData {
  success: boolean;
  errors: Error[];
}

export const removeWorkspace = gql `
  mutation removeWorkspace($workspaceId: ID!, $deleteForAll: Boolean){
    removeWorkspace(workspaceId: $workspaceId, deleteForAll: $deleteForAll){
      success
      errors{
        path
        message
      }
    }
  }`;