import React from 'react';
import clsx from 'clsx'
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    default: {
      color: '#B0B0B0',
      height: 21,
      width: 21
    },
    blue: {
      color: '#3A84FF'
    },
    invert: {
      transform: 'rotate(180deg)'
    }
  })
)

interface ITaskSortIcon {
  sortDirection: string | null;
  classes?: any;
  onClick?: any;
  styles?: any;
}

export default function TaskSortIcon(props: ITaskSortIcon) {

  const { sortDirection, onClick, styles, classes } = props;
  const defaultStyles = useStyles();
  const parsedStyles = styles && { ...styles }

  return (
    <ArrowDownIcon
      className={clsx(
        defaultStyles.default,
        sortDirection && classes?.blue,
        sortDirection === 'ASC' && classes?.invert,
        classes && [...classes]
      )}
      onClick={onClick}
      style={{ visibility: sortDirection ? 'visible' : {}, parsedStyles }}
    />
  )
}
