import React from 'react';
import clsx from 'clsx';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '30px',
      height: '30px',
      position: 'relative',
      borderRadius: '50%',
      background: '#AFAFAF',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: 'bold',
    },
    label: {
      color: '#FFFFFF',
    },
  })
);

interface DefaultUserImageProps {
  className?: string;
  style?: React.CSSProperties;
  userName: string;
  IconInAvatar?: React.FC<any>
}

export default function DefaultUserImage(props: DefaultUserImageProps) {
  const {className, style, userName, IconInAvatar} = props;
  const classes = useStyles();

  const { ...propsIconInAvatar } = IconInAvatar || {};

  return (
    <div className={clsx(classes.root, className)} style={style}>
      <p className={classes.label}>
        {userName && userName
          .split(' ')
          .slice(0, 2)
          .map(name => name[0])
          .join('')}
      </p>
      {IconInAvatar ?
          <IconInAvatar propsIconInAvatar /> 
          : null}
    </div>
  );
}
