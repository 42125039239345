import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import {
  Typography,
  Tooltip,
  Button,
  Divider,
  Collapse,
  ListItem,
  ListItemText,
  List
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Skeleton } from '@material-ui/lab';

import { FileIcon } from '../FileIcon';

import useUnarchiveFile from '../../../graphql/mutations/UnarchiveFile';

const newStyles = makeStyles((theme: Theme) => {
  return createStyles({
    flexRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    fileRow: {
      height: '50px',
      cursor: 'default'
    },
    fileRowText: {
      color: "#606060",
      fontSize: '12px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      lineHeight: 1.6
    },
    fileRowName: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    moreIcon: {
      float: 'right',
      color: '#606060'
    },
    expandIcons: {
      color: "#606060",
      fontSize: '18px'
    },
    fileIcon: {
      height: '25px'
    },
    downloadAllFiles: {
      flex: '1',
      minWidth: '120px',
      '&:hover': {
        cursor: 'pointer',
        margin: '0 2px',
        backgroundColor: '#FFF',
        border: '1px solid #3A84FF',
        borderRadius: '3px',
      },
    }
  })
});

interface ArchivedFilesProps {
  task: any,
  tasks: any,
  updateTask: any,
  setTasks: any,
  archivedFiles: any[],
  archivedFilesLoading: boolean,
  setArchivedFiles: any,
  isExternalUser: boolean
}

/**
 * Reusable `ArchivedFiles` component.
 * This component is using the `TailwindCSS` design pattern.
 *
 * @return {React.JSXElementConstructor} The `ArchivedFiles` component.
 */
export default function  ArchivedFiles(props: ArchivedFilesProps) {
  const { 
    task, 
    tasks, 
    setTasks, 
    updateTask, 
    archivedFiles, 
    archivedFilesLoading, 
    setArchivedFiles, 
    isExternalUser 
  } = props;

  const classes = newStyles();

  const [open, setOpen] = useState(true);
  const [fileVersionIdToUnarchive, setFileVersionIdToUnarchive] = useState();

  const [unarchiveFile, { data: unarchiveFileData, error: unarchiveFileError, loading: unarchiveFileLoading }] = useUnarchiveFile({ fileVersionId: fileVersionIdToUnarchive })

  const handleUnarchiveFile = (fileId: any) => {
    if (isExternalUser) { return; }
    if (fileId) {
      setFileVersionIdToUnarchive(fileId);
    }
  }

  useEffect(() => {
    if (fileVersionIdToUnarchive) {
      unarchiveFile();
    }
  }, [fileVersionIdToUnarchive])

  useEffect(() => {
    if (unarchiveFileLoading) {
      return;
    }

    if (unarchiveFileData && !unarchiveFileError) {
      const returnedData = unarchiveFileData as any;

      const returnedTaskData = returnedData.unarchiveFile.task;

      updateTask({
        ...task,
        files: returnedTaskData.files
      })

      const allArchivedFiles = archivedFiles.slice()

      const newArchivedFiles = allArchivedFiles.filter((f: any) => {
        if (f.id !== fileVersionIdToUnarchive) {
          return f
        }
      })

      setArchivedFiles(newArchivedFiles)
    }
  }, [unarchiveFileData, unarchiveFileError, unarchiveFileLoading])


  return (
    <>
      <ListItem onClick={() => setOpen(!open)} className={classes.fileRow}>
        <ListItemText primary={"Archived Files"} primaryTypographyProps={{ className: classes.fileRowText }} />
        {open ? <ExpandMoreIcon className={classes.expandIcons} /> : <ChevronRightIcon className={classes.expandIcons} />}
      </ListItem>
      <Divider />
      <Collapse in={open}>
        {archivedFilesLoading ? (
          <>
            <ListItem>
              <Skeleton variant="rect" width="7%" height="34px" style={{ marginRight: '10px' }} />
              <Skeleton variant="text" width="38%" height="20px" style={{ marginRight: '40px' }} />
              <Skeleton variant="text" width="24%" height="20px" style={{ marginRight: '30px' }} />
              <Skeleton variant="rect" width="5%" height="20px" style={{ marginRight: '10px' }} />
              <Skeleton variant="rect" width="5%" height="20px" />
            </ListItem>
            <Divider />
          </>
        ) : (
          <List component='div' disablePadding>
            {archivedFiles && archivedFiles.length ? (
              <>
                {archivedFiles.map((file: any, index: number) => {
                  return (
                    <>
                      <ListItem className={clsx(classes.fileRow, classes.fileRowText, classes.flexRow)} key={index} >
                        <FileIcon fileName={file.fileName} className={classes.fileIcon} style={{ flex: 1, marginRight: '10px' }} />
                        <Tooltip title={file.fileName}>
                          <Typography className={classes.fileRowText} style={{ flex: 8, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', textAlign: 'left', marginRight: '40px' }}>
                            {file.fileName}
                          </Typography>
                        </Tooltip>
                        <div style={{ flex: 7, paddingLeft: '40px' }}>
                          <Button style={{ float: 'right', height: 'auto', pointerEvents: 'auto', cursor: isExternalUser ? 'not-allowed' : 'pointer' }} title={"Unarchive File"} variant={"outlined"} onClick={() => { handleUnarchiveFile(file.id) }} disabled={isExternalUser}>Unarchive</Button>
                        </div>
                      </ListItem>
                      <Divider />
                    </>
                  )
                })}
              </>
            ) : (
              <>
                <ListItem className={clsx(classes.fileRow, classes.fileRowText)} style={{ justifyContent: 'center' }}>There are currently no archived files associated with this task.</ListItem>
                <Divider />
              </>
            )}
          </List>
        )}
      </Collapse>
    </>
  )

}