import React, {useState, useEffect, useRef} from 'react';
import clsx from 'clsx';
import idx from 'idx';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import {
  Paper,
  TextField,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core';
import {useDispatchContext, useStateContext} from '../../../../../../store';
import Message from '../../Message';
import {MessageTaggingBox} from './TaskMessenger/MessageTaggingBox';
import {Alert} from './Alert';
import * as cs from '../../../../../../constants/theme';
import WarningIcon from '@material-ui/icons/WarningRounded';

import useCreatePublicMessage from '../../../../../../graphql/mutations/CreatePublicTaskMessage';
import CreatePrivateTaskMessage from '../../../../../../graphql/mutations/CreatePrivateTaskMessage';
import EditTaskMessage from '../../../../../../graphql/mutations/EditTaskMessage';
import deleteTaskMessage from '../../../../../../graphql/mutations/DeleteTaskMessages';
import {useLazyUserTagging} from '../../../../../../graphql/queries/UserTagging';

import {hashMessage} from '../../../../../../helpers/helpers';
import TaskMessenger from './TaskMessenger/TaskMessenger';
import {uniqBy} from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      height: 'calc(100vh - 254px)',
      padding: '12px 24px',
      overflow: 'auto',
      paddingBottom: '100px',
    },
    alertBody: {
      // borderBottom: '1px solid #D8D8D8',
      /* This height can be better calc-ed, I bet */
      height: 'calc(100vh - 316px)',
      padding: '12px',
      overflow: 'auto',
    },
    invisible: {
      display: 'none',
    },
    message: {
      backgroundColor: 'rgba(39, 146, 162, 0.1)',
    },
    footer: {
      padding: '6px 24px',
      position: 'fixed',
      bottom: 0,
      width: 500,
      borderTop: '1px solid #D8D8D8',
      backgroundColor: 'white',
    },
    messageBox: {
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      display: 'block',
      width: '100%',
      minHeight: '5vh',
      color: '#606060',
      maxHeight: 200,
      overflowY: 'auto',
      fontFamily: cs.FONT.family,
      fontWeight: cs.FONT.weight.regular,
      fontSize: cs.FONT.size.xs,
      textTransform: 'none',
      border: 'none',
      '& label': {
        color: '#606060',
        fontFamily: cs.FONT.family,
        fontWeight: cs.FONT.weight.regular,
        fontSize: cs.FONT.size.xs,
      },
      '&:selected': {
        color: '#3A84FF',
      },
      '& input::placeholder': {
        fontSize: '12px',
      },
      '& div': {
        width: '100%',
      },
      '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: 'none',
      },
    },
    card: {
      width: 275,
      height: 110,
      position: 'absolute',
      boxShadow: 'none',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -30%)',
    },
    content: {
      position: 'absolute',
      top: '50%',
      transform: 'translate(0, -50%)',
      padding: 0,
    },
    text: {
      fontFamily: cs.FONT.family,
      fontSize: '12px',
      fontWeight: 500,
    },
  })
);

interface MessagePaneProps {
  index?: number; // used by Panel parent component
  internal?: boolean; // user internal or external functions
  setTasks?: any; // function to update tasks
  tasks?: any; // source of truth for local state
  taskId?: any; // means of accessing current task within above
  value?: number; // used by Panel parent component
  isCreateProject?: boolean; //used by create project component
}

export function MessagePane(props: MessagePaneProps) {
  const {value, index, tasks, setTasks, taskId, internal} = props;
  const classes = useStyles();
  const dispatch = useDispatchContext()
  const state = useStateContext();;

  const role = idx(state, state => state.currentUser.companyRole);
  const userId = idx(state, state => state.currentUser.id);
  const userName = idx(state, state => state.currentUser.displayName);

  // local state vars
  // modals
  const [showAlert, setShowAlert] = useState<boolean>(false);
  // creating new messages
  const [newMessage, setNewMessage] = useState<string>('');
  const [encryptedMessage, setEncryptedMessage] = useState<string>('');
  // editing existing messages
  const [editedMessageText, setEditedMessageText] = useState<string>('');
  const [editedMessageId, setEditedMessageId] = useState<any>();
  // deleting messages
  const [deleteMessageId, setDeleteMessageId] = useState<any>();
  const [messageToDelete, setMessageToDelete] = useState<any>('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  // user tagging
  const [showAddBox, setShowAddBox] = useState<boolean>(false); //whether or not to show the tagging popup
  const [selectedItem, setSelectedItem] = useState<number>(0); //current user selection in tagging popup
  const [allUsers, setAllUsers] = useState<any[]>([]); //list of all users (default for display in tagging popup if no filterList)
  const [displayUsers, setDisplayUsers] = useState<any[]>([]);
  const [messages, setMessages] = useState<any>([]);

  // refs
  const listElement: any = useRef(null);
  const coolRef: any = useRef(null);

  // gql query
  const [getUserTags, {loading, data, error, refetch}] = useLazyUserTagging();

  useEffect(() => {
    if (taskId) {
      getUserTags({
        variables:{
          id: taskId
        }
      })
    }
  }, [taskId]);

  // gql mutations
  const [
    createPrivateMessage,
    createPrivateMessageResponse,
  ] = CreatePrivateTaskMessage({
    message: encryptedMessage,
    taskId: taskId as string,
    selectedOwners: parseSelectedUsers(),
  });

  const [
    createPublicMessage,
    createPublicMessageResonse,
  ] = useCreatePublicMessage({
    message: encryptedMessage,
    taskId: taskId as string,
    selectedOwners: parseSelectedUsers(),
  });

  const [createMessage, createMessageResponse] = internal
    ? [createPrivateMessage, createPrivateMessageResponse]
    : [createPublicMessage, createPublicMessageResonse];

  const [editMessage, editMessageResponse] = EditTaskMessage({
    id: editedMessageId,
    message: editedMessageText,
  });
  const [deleteMessage, deleteMessageResponse] = deleteTaskMessage({
    ids: [deleteMessageId],
  });

  // called Each time the value of the input changes
  const updateNewMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
  };

  useEffect(() => {
    if (state.rejectedTask) {
      if (
        (state.rejectedTask === 'internal' && !internal) ||
        (state.rejectedTask === 'public' && internal)
      ) {
        return;
      }
      setTimeout(() => {
        coolRef.current.focus();
        coolRef.current.selectionStart = coolRef.current.value.length;
      }, 500);
      setNewMessage('Reason For Rejection: ');
      dispatch({
        type: 'SET_REJECTED_TASK',
        rejectedTask: null,
      });
    }
  }, [state.rejectedTask]);

  // if tasks changes, re-fetch the list, to account for changes
  useEffect(() => {
    if (!refetch || !taskId) {
      return;
    }
    setTimeout(() => refetch(), 430);
  }, [tasks, createMessageResponse.called, createMessageResponse.loading, createMessageResponse.data]);

  // fetches the taskOwners query
  useEffect(() => {
    let result = idx(data, data => (data as any).task.allOwners);
    if (!result) {
      return;
    }

    if (internal) {
      var noExternal = result.filter(
        (o: any) => o.taskRelationship !== 'External'
      );
      role <= 5
        ? (result = noExternal.filter(
            (o: any) =>
              o.roleInCurrentUserCompany <= 5 || o.__typename === 'Team'
          ))
        : (result = noExternal.filter(
            (o: any) => o.roleInCurrentUserCompany > 5
          ));
    }

    // Add team members into all user's array -- TODO: refactor
    result
      .filter((val: any) => val.__typename === 'Team')
      .map((team: any) => {
        team.users.map((user: any, index: number) => {
          user.taskRelationship = team.taskRelationship;
          let userIndex = result.findIndex(
            (obj: any) => obj.email === user.email
          );
          if (userIndex === -1) {
            result.push(user);
          } else {
            result[userIndex] = user;
          }
        });
      });

    setAllUsers(result);
  }, [data]);

  function parseSelectedUsers() {
    // ID which users/teams were @-ed in the final message
    var usersTagged = allUsers.filter((o: any) => {
      var name = o.displayName || o.name || o.email;
      return newMessage.includes('@' + name);
    });

    // return object formatted the way the mutation is expecting
    return usersTagged.map((o: any) => {
      return {
        id: o.id,
        taskRelationship: o.taskRelationship,
        userType: o.__typename,
      };
    });
  }

  const tempOwnerParser = () => {
    return allUsers.filter((o: any) => {
      var name = o.displayName || o.name || o.email;
      return (
        newMessage.includes('@' + name) &&
        o.taskRelationship === 'Not in Project'
      );
    });
  };

  useEffect(() => {
    let msgs = idx(
      tasks,
      tasks => (tasks as any).find((t: any) => t.id == taskId).messages
    );

    msgs = uniqBy(msgs, (m: any) => m.id);

    setMessages(msgs);
  }, [tasks, taskId]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!internal && !showAlert) {
      setShowAlert(true);
    } else {
      var hashedMessage = await hashMessage(newMessage);
      setEncryptedMessage(hashedMessage.key);
      setShowAlert(false);
    }
  };

  useEffect(() => {
    if (encryptedMessage.length) {
      var clone = JSON.parse(JSON.stringify(tasks));
      var task = clone.find((t: any) => t.id == taskId);
      var taggedCompanyOwners = tempOwnerParser();
      task.userOwners = [
        ...task.userOwners,
        ...taggedCompanyOwners.filter(
          (o: any) =>
            o.__typename == 'User' &&
            !task.userOwners.map((a: any) => a.id).includes(o.id)
        ),
      ];
      task.teamOwners = [
        ...task.teamOwners,
        ...taggedCompanyOwners.filter(
          (o: any) =>
            o.__typename == 'Team' &&
            !task.teamOwners.map((a: any) => a.id).includes(o.id)
        ),
      ];
      setTasks(clone);
      createMessage();
      setNewMessage('');
    }
  }, [encryptedMessage]);

  /** update the DOM on creation of new messsages */
  useEffect(() => {
    const response = idx(
      createMessageResponse,
      (res: any) => res.data.createTaskMessages.message
    );

    if (response) {
      const newMessage = {
        ...response,
        isPublic: internal ? false : true,
        viewed: true,
      };
      var clone = tasks.map((t: any) => ({...t}));
      clone
        .find((t: any) => t.id == response.task.id)
        .messages.unshift(newMessage);

      setTasks(clone);
    }
  }, [createMessageResponse.called, createMessageResponse.loading, createMessageResponse.data]);

  // EDIT / UPDATE MESSAGES ///////////////////////////////////////////////////
  const handleUpdateMessage = async (event: any, messageText: any, id: any) => {
    // raw messagetext can just be put into the tasks
    var cool = await hashMessage(messageText);
    setEditedMessageId(id);
    setEditedMessageText(cool.key);

    var clone = JSON.parse(JSON.stringify(tasks));
    var task = clone.find((a: any) => a.id === taskId);
    var removeNulls = task.messages.filter((a: any) => a);
    removeNulls.find((a: any) => a.id === id).message = cool.key;
    task.messages = removeNulls;
    setTasks(clone);
  };

  // edit message effect
  useEffect(() => {
    if (editedMessageText.length) {
      editMessage();
      setEditedMessageText('');
      setEditedMessageId('');
    }
  }, [editedMessageText]);

  // DELETE MESSAGES
  // delete message click effect
  const handleDeleteMessage = () => {
    var clone = JSON.parse(JSON.stringify(tasks));
    // DOM
    if (taskId && clone && messageToDelete.length) {
      var task = clone.find((t: any) => t.id === taskId);
      var index = task.messages.findIndex(
        (m: any) => m && m.id === messageToDelete
      );
      task.messages.splice(index, 1);
      setDeleteMessageId(messageToDelete);
      setTasks(clone);
      setMessageToDelete('');
      setShowDeleteConfirm(false);
    }
  };

  useEffect(() => {
    if (deleteMessageId) deleteMessage();
  }, [deleteMessageId]);

  const addTag = () => {
    var taggingSelected = taggableSection(coolRef.current.selectionStart);
    var selected = displayUsers[selectedItem];
    var taggedName = selected.name || selected.displayName;

    var front = newMessage.substring(
      0,
      coolRef.current.selectionStart - taggingSelected.length
    );
    var middle = `@${taggedName} `;
    var back = newMessage.substring(
      coolRef.current.selectionStart,
      newMessage.length
    );

    setNewMessage(front + middle + back);
    coolRef.current.focus();
  };

  function taggableSection(cursorPosition: number) {
    var messageToCursor = newMessage.substring(0, cursorPosition - 1);
    var lastWhiteSpace = messageToCursor.lastIndexOf(' ');

    return newMessage.substring(lastWhiteSpace + 1, cursorPosition);
  }

  /* const availableTaggables = allUsers.filter((o: any) => {
    var name = o.displayName || o.name;
    return !newMessage.includes('@' + name);
  }) */

  const handleKeyDown = (event: any) => {
    // newlines are only added when shift key is pressed
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
    }

    // pressing up and down keys with active tagging box doesn't move cursor
    if ((event.keyCode === 40 || event.keyCode === 38) && showAddBox) {
      event.preventDefault();
    }
  };

  // determine if we should show the user tagging box
  const showTaggingBox = (event: any) => {
    var coolBeans = taggableSection(event.target.selectionStart);

    if (coolBeans === '@') {
      setShowAddBox(true);
      //setDisplayUsers(availableTaggables);
      setDisplayUsers(allUsers);
    }
    if (coolBeans[0] !== '@') {
      setShowAddBox(false);
    } else {
      var theRest = coolBeans.substring(1, coolBeans.length);
      var r = new RegExp('^' + theRest, 'gi');
      var toShow = /*availableTaggables*/ allUsers.filter((u: any) => {
        var nombre = u.name || u.displayName || u.email;
        return nombre.match(r);
      });
      if (toShow.length) {
        setDisplayUsers(toShow);
        setShowAddBox(true);
      } else {
        setShowAddBox(false);
      }
    }
  };

  const handleKeyUp = (event: any) => {
    showTaggingBox(event);

    if (event.keyCode === 13 && event.shiftKey) {
      return;
    }

    // enter key
    if (event.keyCode === 13) {
      event.stopPropagation();
      if (showAddBox && allUsers) {
        addTag();
        setShowAddBox(false);
      } else {
        handleSubmit(event);
      }
    }

    // If the tagging box is not active, we don't want to continue
    if (!showAddBox) {
      return;
    }
    // helpers for up && down keys
    // [confluence] -- up and down code
    var list = listElement.current;
    var {scrollTop} = list;
    var scrollBottom = list.scrollTop + 175;

    // down key
    if (event.keyCode === 40) {
      var newSelection = (selectedItem + 1) % displayUsers.length;
      setSelectedItem(newSelection);
      if (0 == newSelection) {
        list.scrollTo(0, 0);
      } else if (newSelection * 35 >= scrollBottom) {
        list.scrollTo(0, list.scrollTop + 35);
      }
    }

    // up key
    if (event.keyCode === 38) {
      var newSelection =
        (selectedItem + displayUsers.length - 1) % displayUsers.length;
      setSelectedItem(newSelection);
      if (displayUsers.length - 1 == newSelection) {
        list.scrollTo(0, 35 * displayUsers.length);
      } else if (newSelection * 35 < scrollTop) {
        list.scrollTo(0, scrollTop - 35);
      }
    }
  };

  // Whenever we go from no add box to add box reset selected item to 0
  useEffect(() => {
    if (!showAddBox) {
      return;
    }
    setSelectedItem(0);
  }, [showAddBox]);

  return (
    <Paper
      className={clsx(value !== index && classes.invisible)}
      aria-labelledby="Internal"
      elevation={0}
      style ={Boolean(props.isCreateProject) ? {height: 'calc(100% - 64px)'} : undefined}
    >
      <div
        id={internal ? 'internal-pane' : 'public-pane'}
        className={clsx(showAlert ? classes.alertBody : classes.body)}
        style ={Boolean(props.isCreateProject) ? {height: '100%', paddingBottom: 0} : undefined}
      >
        {messages && messages.length ? (
          messages
            .sort((m1: any, m2: any) => {
              if (Number(m2.id) < Number(m1.id)) return -1;
              if (Number(m2.id) > Number(m1.id)) return 1;
            })
            .map((message: any, index: number) => {
              return (
                <Message
                  key={message.id}
                  data={message}
                  className={clsx(internal && classes.message)}
                  //internal={internal} // message.isPublic?
                  internal={!message.isPublic}
                  users={allUsers}
                  role={role}
                  userId={userId}
                  userName={userName}
                  updateMessage={handleUpdateMessage}
                  setShowDeleteConfirm={setShowDeleteConfirm}
                  showDeleteConfirm={showDeleteConfirm}
                  setMessageToDelete={setMessageToDelete}
                />
              );
            })
        ) : (
          <Card className={classes.card}>
            <CardContent className={classes.content}>
              <Typography variant="h5" component="h2" className={classes.text}>
                There are currently no messages for this task, you can begin the
                conversation by writing a note below.
              </Typography>
            </CardContent>
          </Card>
        )}
        {showDeleteConfirm && (
          <Dialog
            open={showDeleteConfirm}
            onClose={() => setShowDeleteConfirm(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div
              style={{
                display: 'flex',
                paddingTop: '15px',
                paddingBottom: '15px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <WarningIcon style={{marginLeft: '8px'}} />
                <DialogTitle id="alert-dialog-title">
                  {'Are you sure you want to delete this message?'}
                </DialogTitle>
              </div>
              <DialogActions>
                <Button
                  onClick={() => setShowDeleteConfirm(false)}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleDeleteMessage}
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        )}
      </div>
    </Paper>
  );
}
