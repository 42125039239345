import { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useStateContext, useDispatchContext } from '../../store';

const mutation = gql`
  mutation UpdateListReconSettings(
    $list: ListAttributes!
  ) {
    updateList(
      list: $list
    ) {
      success
      list {
        id
        reconciliations
      }
    }
  }
`;

export function useUpdateListReconSettings() {
  const [list, setList] = useState<any>({});
  const [updateListReconSettings, { data }] = useMutation(mutation);

  const state = useStateContext();
  const dispatch = useDispatchContext();

  useEffect(() => {
    if (data) {
      const success = data?.updateList?.success;

      if (success) {
        const updatedList = data?.updateList?.list;

        if (updatedList?.id) {
          const userListsCopy = state.userLists.slice();

          const listIndex = userListsCopy.findIndex((userList: any) => {
            return userList.id === updatedList.id
          })

          if (listIndex !== -1) {

            const newList = {
              ...userListsCopy[listIndex],
              reconciliations: updatedList.reconciliations
            }

            userListsCopy[listIndex] = newList;

            dispatch({
              type: 'SET_USER_LISTS',
              userLists: userListsCopy
            })
          }
        }

      } else {
        //Log error in logrocket
      }
    }
  }, [data])

  useEffect(() => {
    if (list.id) {
      updateListReconSettings({ variables: { list } });
    }
  }, [list])

  return function (list: any) {
    setList(list);
  }
}
