import { useDispatchContext } from '../store';

export function useSuccess() {
  const dispatch = useDispatchContext();
  return function(errorMessage: string) {
    dispatch({
      type: 'SET_NOTIFICATION',
      notification: {
        variant: 'success', 
        message: errorMessage,
        time: 6000
      }
    });
  }
}
