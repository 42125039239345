import {createMutationHook, gql} from '../graphqlHelpers';

export const useAddUpdateEntityLabelType = createMutationHook(gql`
  mutation AddUpdateEntityLabelType (
    $id: ID,
    $name: String!,
    $color: String!,
    $etype: String!
  ) {
    addUpdateEntityLabelType(id: $id, name: $name, color: $color, etype: $etype){
      success
      id
    }
  }
`);

