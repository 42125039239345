import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import * as cs from '../../constants/theme';
import { ClickAwayListener, Paper , Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      border: '1px solid #CACACA',
      borderRadius: '3px',
      minWidth: '150px'
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    tooltip: {
      maxWidth: '500px',
      color: '#FFFFFF',
      fontFamily: cs.FONT.family,
      fontSize: cs.FONT.size.xs,
      fontWeight: cs.FONT.weight.regular,
    },
    tooltipFont: {
      fontSize: '1em'
    },
    smallHeight: {
      height: '29.25px',
    },
    grow: {
      flexGrow: 1,
    },
    paper: {
      width: '100%',
      position: 'absolute',
      top: 42,
      left: 0,
      zIndex: 2,
      maxHeight: 168,
      'overflow-y': 'scroll',
    },
    smallPaper: {
      top: 30,
    },
    invisible: {
      display: 'none',
    },
    item: {
			height: '100%',
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      color: '#606060',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: '12px',
      textTransform: 'capitalize',
      '&:hover': {
        cursor: 'pointer',
        background: '#EBF2FF',
      },
    },
    smallItem: {
      padding: '6px',
    },
    inputStyles: {
      border: 'none',
      minWidth: 0
    },
    hideGrow: {
      flexGrow: 0,
    },
    itemInput: {
      width: 'fit-content',
      padding: '6px 0'
    },
    modalInput: {
        width: 'fit-content',
    },
    modal: {
      overflow: 'overlay'
    },
    manageStyle: {
      marginBottom: 6,
      marginLeft: 10,
			border: 'none'
    },
		frp: {
			width: 150
		},
		roles: {
			minWidth: 160
		},
    refactorMe: {
      width: 146, 
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    goneSoon: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    disabled: {
      backgroundColor: 'lightgray',
      pointerEvents: 'none'
    }
  })
);

interface Option {
  id: string;
  name: string;
  duration?: number;
  durationType?: string;
}

interface DropdownProps {
  options: Option[];
  selected: string;
  small?: boolean;
  input?: boolean;
  index?: number;
  name?: string;
  placeholder?: string;
  handleUpdate?: any;
  selectMenu?: boolean;
  modalMenu?: boolean
  manage?: boolean;
	frp?:boolean;
	overrideValue?: any;
  roles?: boolean;
  classes?: any;
  dHeight?: string;
  ellipsis?: boolean;
  refactorMe?: boolean;
  noFlow?: boolean;
  zindex?: boolean;
  textLength?: number;
  goneSoon?: boolean;
  paddingSize?: string;
  disabled?: boolean
  preventMenuToggle?: boolean
}

export default function Dropdown(props: DropdownProps) {
  const {frp, options, selected, small, input, index, manage, selectMenu, modalMenu, name, placeholder, handleUpdate, dHeight, textLength, paddingSize, disabled, preventMenuToggle} = props;
  
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [label, setLabel] = useState<string>('');
  const [longLabel, setLongLabel] = useState<string>('');
  const passedClasses = props.classes || {};

  const textTruncate = (str: string, length: number) => {
    let ending = '...'
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  const renderLabel = () => {
		if (!options) return;

    const res = options.find(option => option.id === selected);
    if (res) {
      // setLabel(res.name);
      return < Typography className={clsx(props.refactorMe && classes.refactorMe, props.goneSoon && classes.goneSoon)} style={{width: frp?150:''} }variant='body1'> { textTruncate(res.name, textLength || 25) } </Typography>
    } else if (props.overrideValue) {
      return < Typography className={clsx(props.refactorMe && classes.refactorMe, props.goneSoon && classes.goneSoon)} style={{width: frp?150:''} }variant='body1'> { props.overrideValue } </Typography>
		} else {
      return < Typography className={clsx(props.refactorMe && classes.refactorMe, props.goneSoon && classes.goneSoon)} style={{ color: '#AFAFAF', fontWeight: 500, opacity: 1, fontFamily: 'Montserrat'}}>{placeholder}</Typography>;
    }
  };

  const toggleMenu = (e: any) => { 
    if(preventMenuToggle) { return; }
    frp && e.nativeEvent.stopPropagation();
    setOpen(prev => !prev); 
  }

  const handleClick = (value: string) => {
    setOpen(prev => !prev);
    if (handleUpdate){
      if (input) {
        handleUpdate(value, index, name);
      } else {
        handleUpdate(value);
      }
    }
  };

  const handleClickAway = () => setOpen(false);

  return (
    <div style={{minWidth: props.noFlow ? 110 : undefined}} className={clsx(classes.root, small && classes.smallHeight, (selectMenu || modalMenu) && classes.inputStyles, manage && classes.manageStyle, frp && classes.frp, props.roles && classes.roles, disabled && classes.disabled, passedClasses)}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div style={{width: '100%', height: dHeight ? dHeight : '100%' }}>
          <div
            className={clsx(classes.item, small && classes.smallItem, selectMenu && classes.itemInput, modalMenu && classes.modalInput)}
            onClick={toggleMenu}
            style={{padding: paddingSize ? paddingSize : '12px'}}
            data-cy='dropdown-selected-value'
          >
            {renderLabel()}
            <div className={clsx(classes.grow, selectMenu && classes.hideGrow)} />
            <i className="fa fa-caret-down" style={{marginLeft: '12px'}}></i>
          </div>
          <Paper
            style={{zIndex: props.zindex ? 3 : undefined, overflowY: props.noFlow ? 'auto' : undefined}}
            className={clsx(
              classes.paper,
              !open && classes.invisible,
              small && classes.smallPaper,
              modalMenu && classes.modal
            )}
            data-cy='dropdown-options-list'
          >
            {options &&
              options.map(option => (
                <div
                  key={option.id}
                  className={clsx(classes.item, props.ellipsis && classes.ellipsis)}
                  onClick={() => handleClick(option.id)}
                  style={{padding: paddingSize ? paddingSize : '12px'}}
                >
                  {option.name}
                </div>
              ))}
          </Paper>
        </div>
      </ClickAwayListener>
    </div>
  );
}
