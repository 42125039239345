/* linted */

import idx from 'idx';
import { STATUSES_KEYS_EXTENDS } from '../modules/common/Constants';
/* link to confluence */
/* These are ordered by what is available to the user type */
const REQUESTER_STATUSES = [
  'Unstarted',
  'Complete',
  'RejectedExternal',
  'NotApplicable'
];

const ALL_RESPONDER_STATUSES = [
  'Unstarted',
  'Started',
  'Prepared',
  'Delivered',
  'RejectedInternal',
  'NotApplicable'
];

const LIMITED_RESPONDER_STATUSES = [
  'Unstarted',
  'Started',
  'Prepared',
  'NotApplicable'
];

const ALL_INTERNAL_STATUSES = [
  'Unstarted',
  'Started',
  'Prepared',
  'Delivered',
  'Complete',
  'RejectedInternal',
  'NotApplicable'
];

const FIRST_REVIEWER_STATUSES = [
  'Unstarted',
  'Started',
  'Prepared',
  'Delivered',
  'RejectedInternal', 
  'NotApplicable'
]

const RECONCILIATION_STATUSES_TO_REMOVE = [
  'Prepared',
  'Delivered',
  'Complete'
]

/* We need company position from task,
 * currentuserisowner from list */
export function getStatusesFor(task: any, currentUser: any) {
  const { companyPosition } = task;

  let possibleStatuses: string[] = [];
  if (companyPosition === 'requester') { possibleStatuses = REQUESTER_STATUSES; }

  const owner = idx(task, t => t.list.currentUserIsOwner);
  const reviewer = idx(task, t => t.currentUserIsReviewer);

  const multipleReviewersActive = task.list?.multipleReviewers;
  const userIsFirstReviewer = task.firstUserReviewers?.some((firstReviewer: any) => firstReviewer.id === currentUser.id);
  const taskHasSecondReviewers = task.secondUserReviewers?.length > 0 || task.secondTeamReviewers?.length > 0;

  const taskReconciliationActive = (typeof task?.reconciliations === 'string' && Number(task.reconciliations) !== 0)

  const getTaskReviewers = (task: any) => {
    return task?.firstUserReviewers?.length === 0 && task?.firstTeamReviewers?.length === 0 && task?.secondUserReviewers?.length === 0 && task?.secondTeamReviewers?.length === 0
  }

  if (companyPosition === 'responder' && !owner && !reviewer) {
    if (getTaskReviewers(task)) {
      possibleStatuses = ALL_INTERNAL_STATUSES;
    } else {
      possibleStatuses = LIMITED_RESPONDER_STATUSES;
    }
  } else if (companyPosition === 'responder') {
    possibleStatuses = ALL_RESPONDER_STATUSES;
  }

  if (companyPosition === 'internal' && !owner && !reviewer) {
    if (getTaskReviewers(task)) {
      possibleStatuses = ALL_INTERNAL_STATUSES;
    } else {
      possibleStatuses = LIMITED_RESPONDER_STATUSES;
    }
  } else if (companyPosition === 'internal') {
    if (multipleReviewersActive) {
      if (userIsFirstReviewer && taskHasSecondReviewers){
        possibleStatuses = FIRST_REVIEWER_STATUSES
      }
    }
    possibleStatuses = ALL_INTERNAL_STATUSES;
  }

  if(taskReconciliationActive){
    if(!task.isReconciled){
      possibleStatuses = possibleStatuses.filter((status: any) => {
        return !RECONCILIATION_STATUSES_TO_REMOVE.includes(status);
      })
    }
  }

  // Apply the default ordering from STATUSES_KEYS_EXTENDS
  const possibleStatusesSorted = possibleStatuses.sort((a, b) => {
    return STATUSES_KEYS_EXTENDS.indexOf(a) - STATUSES_KEYS_EXTENDS.indexOf(b);
  });

  return possibleStatusesSorted; // in case we've fouled up somewhere...
}
