import React from 'react';
import { Skeleton as MuiSkeleton } from '@material-ui/lab';

export interface Skeleton {
  /** If `true`, it'll render its children */
  isLoaded?: boolean;
  /** The animation. If 'false' the animation effect is disabled. */
  animation?: 'pulse' | 'wave' | false;
  /** The type of content that will be rendered. */
  variant?: 'text' | 'rect' | 'circle';
  /** Width of the skeleton. Useful when the skeleton is inside an inline element with no width of its own. */
  width?: number | string;
  /** Height of the skeleton. Useful when you don't want to adapt the skeleton to a text element but for instance a card. */
  height?: number | string;
  /** Optional children to infer width and height from. */
  children?: React.ReactNode;
  style?: React.CSSProperties
}

/** Custom component which gives loading queues to the user when waiting on data
 * @param children Optional children to infer width and height from
 * @param isLoaded If `true`, it'll render its children
 */
export const Skeleton: React.FC<Skeleton> = ({
  isLoaded = false,
  children,
  height,
  width,
  variant,
  animation,
  style
}) =>
  isLoaded ? (
    <MuiSkeleton
      height={height}
      width={width}
      variant={variant}
      animation={animation}
      style={{...style}}
    >
      {children}
    </MuiSkeleton>
  ) : (
    <>{children}</>
  );
