import React, { useState, useEffect } from 'react';
import {
  ClickAwayListener,
  TextField,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { MultiFileReconMoneyDisplay } from './MultiFileReconMoneyDisplay';
import { useUpdateFileReconSourceValue } from '../../../../../../../../graphql/mutations/UpdateFileReconSourceValue';
import { useDispatchContext } from '../../../../../../../../store';

const useLocalStyles = makeStyles(() =>
  createStyles({
    sourceFieldInput: {
      '& input': {
        padding: '6px 0px',
        textAlign: 'center',
        color: 'rgb(96, 96, 96)',
      },
    },
    inputContainer: {
      display: 'flex',
      height: 25,
      justifyContent: 'center',
      width: 120,
    },
  })
);

interface MultiFileReconSourceProps {
  task: any;
  disabled?: boolean;
  sourceType: any;
  updateTask: any;
  updateTaskInCache: any;
  fileValue: any;
  fileExtenstion: any;
  fileReconSourceId: any;
}

export function MultiFileReconSourceValue({
  sourceType,
  disabled,
  task,
  updateTask,
  updateTaskInCache,
  fileValue,
  fileExtenstion,
  fileReconSourceId
}: MultiFileReconSourceProps) {

  const [fileReconParams, setFileReconParams] = useState<any>();
  const [updateFileReconSourceValue, {data: mutationData, loading: mutationLoading, error: mutationError}] = useUpdateFileReconSourceValue(fileReconParams);

  const [displayedValue, setDisplayedValue] = useState<any>(fileValue);

  const [inputActive, setInputActive] = useState<boolean>(false);

  const dispatch = useDispatchContext();

  function handleSourceValueClick(e: React.MouseEvent) {
    //Don't allow click if disabled
    if(disabled) {
      return;
    }

    //This might need updated
    if (fileExtenstion !== "pdf") {
      return;
    }

    setInputActive(true);
  }

  function handleSourceInputKeyUp(e: React.KeyboardEvent) {
    if (!(e.key === 'Enter')) {
      return;
    }
    update();
  }

  function handleChange(e: any) {
    setDisplayedValue(e.target.value);
  }

  function handleClickAway() {
    if (inputActive) {
      setInputActive(false);
    }
  }

  function handleBlur() {
    update();
  }

  function update() {

    //Prevent update if the user clicks input but doesn't enter a number
    if (!displayedValue) {
      setInputActive(false);
      return;
    }

    setFileReconParams({
      fileReconSourceId: fileReconSourceId,
      taskId: task.id,
      value: Number(displayedValue)
    })
  }

  useEffect(() => {
    if(fileReconParams){
      updateFileReconSourceValue()
    }
  }, [fileReconParams])

  useEffect(() => {
    if(mutationData){

      //Need to type these better
      const mutationDataAsAny = mutationData as any;
      const updatedTask = mutationDataAsAny.updateFileReconSourceValue.task;

      updateTaskInCache({
        ...task,
        ...updatedTask
      })

      updateTask({
        ...task,
        ...updatedTask
      })

      dispatch({
        type: 'SET_PRIMARY_TOTAL',
        primaryTotal: { balance: updatedTask.primaryTotal, task_id: task.id },
      });
      dispatch({
        type: 'SET_SECONDARY_TOTAL',
        secondaryTotal: { balance: updatedTask.secondaryTotal, task_id: task.id },
      });

      setInputActive(false);
    }
  }, [mutationData])


  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ display: 'contents' }}>
        {inputActive ? (
          <CoolInput
            handleBlur={handleBlur}
            onChange={handleChange}
            onKeyUp={handleSourceInputKeyUp}
            value={displayedValue}
          />
        ) : (
          <MultiFileReconMoneyDisplay dollarSign value={fileValue} onClick={handleSourceValueClick} />
        )
        }
      </div>
    </ClickAwayListener>
  );
}

function CoolInput({ onKeyUp, value, onChange, handleBlur }: any) {
  const localStyles = useLocalStyles();
  return (
    <TextField
      InputProps={{
        disableUnderline: true,
        className: localStyles.sourceFieldInput,
      }}
      autoFocus
      onBlur={handleBlur}
      onChange={onChange}
      onKeyUp={onKeyUp}
      style={{
        padding: '0px',
        width: '100%',
        whiteSpace: 'nowrap',
        color: '#606060',
      }}
      type="number"
      value={value}
    />
  );
}
