import gql from 'graphql-tag';
import idx from 'idx';
import { useMutation } from '@apollo/client'
import { useDispatchContext } from '../../store';
import { convertTaskPageViewTabStringToInt } from '../../helpers/helpers';

const mutation = gql`
  mutation UpdateUserFilters(
    $filters: UserFiltersAttributes!
  ) {
    updateUserFilters(
      filters: $filters
    ) {
      success
    }
  }
`;

export function useUpdateUserFilters(filters: any) {
  //No idea why we're setting filters when they're null?
  //Come back to this if filters mess up again
  const dispatch = useDispatchContext();
  if (filters) {
    dispatch({
      type: 'SET_USER_FILTERS',
      filters
    });
  }

  if (filters && filters.taskPageView) {
    filters.taskPageView = convertTaskPageViewTabStringToInt(filters.taskPageView);
  }

  return useMutation(mutation, {variables: {filters}});
}

export const useLazyUpdateUserFilters = () =>
  useMutation(gql`
    mutation UpdateUserFilters(
      $filters: UserFiltersAttributes!
    ) {
      updateUserFilters(
        filters: $filters
      ) {
        success
      }
    }
  `);
