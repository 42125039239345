import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
			width: 300,
			alignItems: 'center',
			margin: '20px 50px',
			border: '2px solid #D8D8D8',
			borderRadius: 2,
			padding: '8px 19px'
		},
		iconRoot: {
			width: 32,
			height: 32,
			alignSelf: 'flex-end',
		},
		defaultCheck: {
			color: '#B0B0B0',
		},
		selectedCheck: {
			color: '#6EB81D',
		},
		t2: {
			fontSize: "18px",
			lineHeight: "22px",
			color: "#707070",
			opacity: 1,
			fontWeight: "bold",
		},
		t3: {
			fontSize: "12px",
			lineHeight: "15px",
			color: "#B0B0B0",
			opacity: 1,
			fontWeight: "normal",
		},
	})
);

interface IProjectOption {
	selected: boolean;
	projectOption: any;
	onSelect(value: string): void;
}

export default function ProjectOption(props: IProjectOption) {
	const classes = useStyles();
	const { selected, projectOption, onSelect } = props;

	function onClickHandler(_: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		onSelect(projectOption.value);
	}

	return (
		<Paper elevation={0} className={classes.root} style={{ height: projectOption.description1 ? 350 : 300 }} onClick={(e) => onClickHandler(e)}>
			<CheckCircleOutlinedIcon classes={{ root: classes.iconRoot }} className={selected ? classes.selectedCheck : classes.defaultCheck} />
			{projectOption.icon}
			{projectOption.header}
			{ projectOption.description1 &&
				<Typography className={classes.t3} style={{ margin: '22px 0px' }}>{projectOption.description1}</Typography>
			}
			{projectOption.description2 &&
				<Typography className={classes.t3}>{projectOption.description2}</Typography>
			}
		</Paper>
	)
}