import { createMutationHook, gql } from '../graphqlHelpers';

const deleteTaskMessages = createMutationHook(gql`
	mutation deleteTaskMessage($ids: [ID!]!) {
		deleteTaskMessage(ids: $ids) {
			success
			errors {
				message
			}
		}
	}
`)

export default deleteTaskMessages;
