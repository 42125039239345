import { createMutationHook, gql } from '../graphqlHelpers';

export const useSoftDeleteLists = createMutationHook(gql`
  mutation SoftDeleteLists(
    $listIds: [ID!]!
  ) {
    softDeleteLists(
      listIds: $listIds
    ) {
      success
    }
  }
`);
