import React from 'react';
import clsx from 'clsx';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import DefaultUserImage from './DefaultUserImage';
import Clear from '@material-ui/icons/Clear';
import {IconButton, Popover, Typography} from '@material-ui/core';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import {truncate as truncateFn} from '../../helpers/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: 'fit-content',
      alignItems: 'center',
      marginRight: '6px',
      marginBottom: '12px',
      padding: '1px 6px 1px 6px',
      background: '#FFFFFF',
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
    },
    label: {
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: 600,
    },
    userImage: {
      width: '18px',
      height: '18px',
      backgroundColor: '#2792A2',
      fontSize: '9px',
      marginRight: '6px',
    },
    teamImage: {
      width: '18px',
      height: '18px',
      backgroundColor: '#FFFFFF',
      border: '1px solid #2792A2',
      fontSize: '9px',
      marginRight: '6px',
      '& p': {
        color: '#2792A2',
      },
    },
    close: {
      fontSize: '12px',
      marginLeft: '12px',
    },
    clearButton: (props:any) => ({
      background: 'transparent',
      '&:hover': {
        background: 'transparent',
        border: props.border
      },
    }),
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
      maxWidth: 320,
      wordWrap: 'break-word',
      backgroundColor: '#606060',
      '&>p': {
        color: 'white',
      },
    },
  })
);

interface NameLabelProps {
  type?: 'user' | 'company' | 'team';
  logo?: string;
  label: string;
  className?: string;
  style?: any;
  selected?: boolean;
  labelStyle?: any;
  truncate?: number;
  onClose?: () => void;
  onCloseDataCy?: string;
  isExternalUser?: boolean;
}

const 
NameLabel = React.forwardRef(
  (props: NameLabelProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      logo,
      label,
      selected,
      type,
      className,
      onClose,
      style,
      labelStyle,
      truncate,
      isExternalUser,
      ...other
    } = props;

    const styleProps = {
      border: isExternalUser ? '2px solid #FFFFFF;' : '2px solid #3A84FF;'
    }

    const classes = useStyles(styleProps);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    const canOpen = !!truncate && label.length > truncate;
    const open = canOpen && Boolean(anchorEl);

    return (
      <div
        {...other}
        className={clsx(classes.root, className)}
        ref={ref}
        style={style}
        onMouseEnter={event => {
          setAnchorEl((event as any).currentTarget);
        }}
        onMouseLeave={handlePopoverClose}
      >
        {logo ? (
          logo === 'File' ? (
            <InsertDriveFileOutlinedIcon
              style={{width: '18px', height: '18px', marginRight: '6px'}}
            />
          ) : (
            <img
              src={logo}
              width="18"
              height="18"
              style={{marginRight: '6px', borderRadius: 9}}
              alt={label}
            />
          )
        ) : (
          (type === 'user' || type === 'team') && (
            <DefaultUserImage
              userName={label}
              className={clsx(
                type === 'user' ? classes.userImage : classes.teamImage
              )}
            />
          )
        )}
        <div
          data-cy="name-label-label"
          className={classes.label}
          style={labelStyle}
        >
          {truncate ? truncateFn(label, truncate) : label}
        </div>
        {onClose && (
          <IconButton
            className={classes.clearButton}
            disableRipple
            disableFocusRipple={true}
            onClick={onClose}
            style={{padding: '3px', marginLeft: '8px', cursor: props.isExternalUser ? 'not-allowed' : 'pointer'}}
            data-cy={props.onCloseDataCy}
          >
            <Clear style={{fontSize: '12px'}} />
          </IconButton>
        )}
        {canOpen && (
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography>{label}</Typography>
          </Popover>
        )}
      </div>
    );
  }
);

export default NameLabel;
