import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import UploadIcon from '@material-ui/icons/CloudUpload';

import axios from 'axios';
import ReactDropzone from 'react-dropzone';
import idx from 'idx';
import clsx from 'clsx';
import { IProjectOptionsSelected, ITableHeader, ITask } from '../../../../common/interfaces';
import { useDispatchContext, useStateContext } from '../../../../../../../../store';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			position: 'sticky',
			minWidth: 300,
			padding: '0px 50px',
			right: 0,
			top: 0,
			background: 'white',
			zIndex: 20,
			height: '100%'
		},
		uploadButton: {
			width: '100%',
			height: '42px',
			padding: '0px'
		},
		uploadArea: {
			width: '100%',
			height: '350px',
			border: '2px dashed #D8D8D8',
			borderRadius: '3px',
			marginTop: '30px',
			paddingTop: '50px',
			color: '#D8D8D8',
			textAlign: 'center',
			'&:hover': {
				cursor: 'pointer'
			}
		},
		uploadLabelColor: {
			color: '#D8D8D8'
		},
		uploadActive: {
			background: '#EBF2FF',
			borderColor: '#3A84FF'
		},
	})
);

function getCSRFToken(): string {
	const el = document.querySelector('meta[name="csrf-token"]');
	return (el && el.getAttribute('content')) || '';
}
interface IImportTemplatePane {
	selectedTemplate: any;
	setSelectedTemplate: React.Dispatch<React.SetStateAction<any>>;
	setAddTasks: React.Dispatch<React.SetStateAction<any>>;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	allTableHeaderOptions: ITableHeader[];
	displayedHeaders: ITableHeader[];
	setDisplayedHeaders: React.Dispatch<React.SetStateAction<ITableHeader[]>>;
	projectOptionsSelected: IProjectOptionsSelected;
	selectedTasksIds: string[];
	setSelectedTasksIds: React.Dispatch<React.SetStateAction<string[]>>;
	additionalInternalOwners: any[];
	additionalExternalOwners: any[];
	setAdditionalInternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
	setAdditionalExternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function ImportTemplatePane(props: IImportTemplatePane) {
	const classes = useStyles();
	const dispatch = useDispatchContext();

	const { selectedTemplate, setSelectedTemplate, setAddTasks, setLoading, allTableHeaderOptions, displayedHeaders, setDisplayedHeaders,
		projectOptionsSelected, selectedTasksIds, setSelectedTasksIds,
		additionalInternalOwners, additionalExternalOwners, setAdditionalInternalOwners, setAdditionalExternalOwners } = props;

	function handleClickDownload() {
		window.open('/templates/PrepDD_Excel_Task_Import_New_Template.xlsx')
	};

	function isBlankProject() {
		if (selectedTemplate.tasks.length <= 1) {
			if (selectedTemplate.tasks.length === 0) {
				return true;
			}
			if (selectedTemplate.tasks.length === 1 && !Boolean(selectedTemplate.tasks[0].name)) {
				return true;
			}
		}
		return false;
	}

	function getHeaderstoDisplay(tasks: any) {
		const displayedHeadersValues = displayedHeaders.map(header => header.value);
		const remainingHeadersValues = allTableHeaderOptions.filter(header => !displayedHeadersValues.includes(header.value)).map(header => header.value);
		let headersValuesToAdd: string[] = [];
		remainingHeadersValues.forEach(header => {
			tasks.forEach((task: any) => {
				if (!headersValuesToAdd.includes(header) && task[header] && task[header].toString().length > 0) {
					headersValuesToAdd.push(header);
				}
			});
		});
		if (projectOptionsSelected.projectType === 'internal') {
			headersValuesToAdd = headersValuesToAdd.filter(header => !(header === 'externalUserOwners' || header === 'externalUserReviewers'));
		}
		const finalHeaderValues = [...(new Set([...displayedHeadersValues, ...headersValuesToAdd]))];

		return allTableHeaderOptions.filter(header => finalHeaderValues.includes(header.value));
	}

	function setAdditionalOwners(newTasks: any[], additionalOwners: any[], newOwnersType: string) {

		return idx(newTasks, (ts: any) => {
			var uniqueOwners:any = (additionalOwners && additionalOwners.length) ? additionalOwners : [];

			function checkAndAdd(owner: any) {
				for ( let i = 0; i < uniqueOwners.length; i++) {
					let existingOwner = uniqueOwners[i]
					if (owner.__typename === existingOwner.__typename && (
						(owner.email && (owner.email === existingOwner.email)) ||
						(owner.name && (owner.name === existingOwner.name))
					)) { return; }
				}
				uniqueOwners.push({...owner, id: Boolean(idx(owner, o => o.id.toString().length)) ? owner.id : Math.random().toString()});
			}

			const assignees = newOwnersType === 'internal' ? ['userOwners', 'teamOwners', 'userReviewers', 'teamReviewers']
							: newOwnersType === 'external' ? ['externalUserOwners', 'externalTeamOwners', 'externalUserReviewers', 'externalTeamReviewers'] : [];

			ts.forEach((task: any) => {
				assignees.forEach(assignee => {
					task[assignee].forEach(checkAndAdd);
				})
			});
			return uniqueOwners;
			}) || [];

	}

	function handleDrop(acceptedFiles: File[]) {
		setLoading(true);
		// Handle importing templates
		const form_data = new FormData();
		acceptedFiles.map((file: File, index: number) => {
			form_data.append('files[]', file);
			form_data.append('list_ids[]', selectedTemplate.id);
		});

		axios
			.post('/api/import_volatile_task', form_data, {
				headers: {
					'X-CSRF-Token': getCSRFToken()
				}
			})
			.then(async res => {
				const existingTasks = isBlankProject() ? [] : selectedTemplate.tasks;
				const selectedTasksIndices = isBlankProject() ? [] : selectedTasksIds;
				const taskGroups = idx(res, res => res.data.tasks);

				if (taskGroups) {
					const groups = Object.values(taskGroups);
					let newTasks: ITask[] = [];
					let unformattedTasks: any[] = [];

					groups.map((group: any) => {
						const tasks = Object.values(group);
						const tempTasks = tasks.map((task: any) => {
							return {
								__typename: 'Task',
								id: task.id.toString(),
								name: String(task.name),
								section: {
									__typename: 'TaskSection',
									id: '',
									name: task.section ? String(task.section) : ''
								},
								description: task.description ? String(task.description) : '',
								priority: task.priority,
								status: 'Unstarted',
								tags: task.tags,
								dependencies: task.dependencies,
								dueDate: task.dueDate ? new Date(task.dueDate): '',
								startDate: task.startDate ? new Date(task.startDate): '',
								relativeDueDate: task.relativeDueDate,
								relativeStartDate: task.relativeStartDate,
								isVolatile: true,
								list: { id: task.list.id.toString(), name: task.list.name, __typename: "List" },
								listNumber: parseInt(task.listNumber),
								userOwners: task.userOwners ? task.userOwners : [],
								teamOwners: task.teamOwners ? task.teamOwners : [],
								userReviewers: task.userReviewers ? task.userReviewers : [],
								teamReviewers: task.teamReviewers ? task.teamReviewers : [],
								externalUserOwners: task.externalUserOwners ? task.externalUserOwners : [],
								externalTeamOwners: task.externalTeamOwners ? task.externalTeamOwners : [],
								externalUserReviewers: task.externalUserReviewers ? task.externalUserReviewers : [],
								externalTeamReviewers: task.externalTeamReviewers ? task.externalTeamReviewers : []
							} as any;
						});
						newTasks = newTasks.concat(tempTasks);
						unformattedTasks = unformattedTasks.concat(tasks);
					});
					setSelectedTasksIds([...selectedTasksIndices, ...newTasks.map(t => t.id)]);
					setAddTasks([...newTasks]);
					setAdditionalInternalOwners(setAdditionalOwners(newTasks, additionalInternalOwners, 'internal'));
					setAdditionalExternalOwners(setAdditionalOwners(newTasks, additionalExternalOwners, 'external'));
					setSelectedTemplate({ ...selectedTemplate, tasks: [...existingTasks, ...newTasks] });
					setDisplayedHeaders(getHeaderstoDisplay(unformattedTasks));
					dispatch({
						type: 'SET_NOTIFICATION',
						notification: {
							variant: 'success',
							message: 'Tasks imported successfully',
						},

					});
				}
				setLoading(false);
			})
			.catch(e => {
				dispatch({
					type: 'SET_NOTIFICATION',
					notification: {
						variant: 'error',
						message: 'Importing tasks failed'
					}
				});
				setLoading(false);
			});
	};

	return (
		<div className={classes.root}>
			<Button variant="outlined" className={classes.uploadButton} onClick={handleClickDownload}>
				Download Import Template
      		</Button>
			<ReactDropzone multiple onDrop={handleDrop}>
				{({ getRootProps, getInputProps, isDragActive }) => (
					<div data-cy="dragndrop" {...getRootProps()} className={clsx(classes.uploadArea, isDragActive && classes.uploadActive)}>
						<input {...getInputProps()} />
						<div>
							<UploadIcon style={{ fontSize: '120px' }} />
							<br />
							<Typography variant="h3" className={classes.uploadLabelColor}>
								Drag and Drop/
                				<br />
					      		Import Tasks
            				</Typography>
						</div>
					</div>
				)}
			</ReactDropzone>
		</div>
	)
}