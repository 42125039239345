import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import UploadIcon from '@material-ui/icons/CloudUpload';
import { Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { FileUploadProperties, FileUploadWorkerProperties, useFileUploadWorker } from '../../../../../../../../hooks/useFileUploadWorker';


function FileDropSectionComponent({ history, source, sourceType, task, updateTask, updateTaskInCache, updateTaskStatus }: any) {

  const fileUploadWorker = useFileUploadWorker();
  /** Dropzone lib requires props spreading */
  /* eslint-disable react/jsx-props-no-spreading */
  function handleDrop(acceptedFiles: any) {

    const formattedFiles: FileUploadProperties[] = acceptedFiles.map((file: File, index: number) => {
      return {
        file,
        taskId: task.id,
        sourceType: sourceType
      }
    });

    const fileUploadWorkerProperties: FileUploadWorkerProperties = {
      files: formattedFiles,
      uploadType: 'task',
      selectedTask: task,
      updateTask: updateTaskInCache,
      updateTaskStatus: updateTaskStatus,
    }

    fileUploadWorker(fileUploadWorkerProperties);
  }

  return (
    <>
      <Dropzone
        noDragEventsBubbling
        onDrop={handleDrop}
      >
        {({ getRootProps, getInputProps }) =>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '2px #D8D8D8 dashed', borderRadius: 3, flexDirection: 'column', padding: '5px' }}>
              <UploadIcon style={{ fontSize: '48px', color: '#D8D8D8' }} />
              <Typography>Upload additional support files</Typography>
            </div>
          </div>}
      </Dropzone>
    </>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}

export const FileDropSection = withRouter(FileDropSectionComponent)
