import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    createContainer: {
      background: "#FFFFFF",
      display: "flex",
      flexDirection: "column",
      width: "480px",
      borderRadius: "2px",
      "&:focus": {
        outline: "none",
      },
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1rem",
      borderBottom: ".5px solid #CDCDCD",
    },
    titleHeading: {
      font: "normal normal bold 18px/22px Montserrat",
      fontSize: "18px",
      color: "#2C2C2C",
      fontWeigh: "800",
    },
    closeIcon: {
      color: "#60606080",
      cursor: "pointer",
    },
    formContainer: {
      padding: "1rem",
    },
    actionContainer: {
      padding: ".9rem 1.1rem",
      borderTop: ".5px solid #CDCDCD",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    formInput: {
      width: "100%",
      marginTop: "1rem",
      "& .MuiOutlinedInput-input": {
        padding: "12px",
        fontWeight: 500,
      },
    },
    marginTop: {
      marginTop: "1rem",
    },
    workspaceContainer: {
      display: "flex",
      marginTop: ".5rem",
      alignItems: "center",
    },
    editable: {
      borderBottom: "1px dashed #3A84FF80",
      cursor: "pointer",
    },
    root: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
    },
    list: {
      backgroundColor: "#FFFFFF",
      border: "1px solid #60606066",
      borderRadius: "2px",
    },
    listItem: {
      padding: "4px 8px",
    },
    listIcon: {
      color: "#707070",
      minWidth: "24px",
    },
    icon: {
      fontSize: "15px",
    },
    t2: {
      fontSize: "12px",
      lineHeight: "15px",
      color: "#707070",
      fontWeight: "normal",
      whiteSpace: "nowrap",
      cursor: "pointer",
    },
    button: {
      padding: ".5rem 2rem",
    },
    spaceInput: {
      flexBasis: "50%",
      fontSize: "12px",
      fontFamily: "Montserrat",
      color: "#606060",
      "& .MuiInput-underline:before": {
        border: "none",
      },
    },
    removePadding: {
      '& .MuiInputBase-input': {
        padding: '0px'
      }
    }
  })
);
