import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, StepConnector, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			padding: '40px 50px 30px',
		},
		stepperRoot: {
			padding: 0,
			width: '100%',
		},
		stepLabel: {
			fontSize: '12px',
			lineHeight: '18px',
			color: '#2C2C2C !important',
			fontWeight: 'bold',
		},
		createProject: {
			fontSize: '18px',
			lineHeight: '22px',
			color: '#2C2C2C !important',
			fontWeight: 'bold',
		},
		close: {
			fontSize: 30,
			color: '#D8D8D8',
			cursor: 'pointer',
		},
		iconRoot: {
			color: '#eaeaf0',
			display: 'flex',
			height: 22,
			width: 22,
			alignItems: 'center',
		},
		labelMargin: {
			marginTop: '6px !important',
		},
		active: {
			color: '#3A84FF !important',
		},
		default: {
			color: '#707070'
		}
	})
);

const LineConnector = withStyles({
	alternativeLabel: {
		top: 10,
		left: 'calc(-50% + 10px)',
		right: 'calc(50% + 10px)',
	},
	active: {
		'& $line': {
			borderColor: '#3A84FF',
		},
	},
	completed: {
		'& $line': {
			borderColor: '#3A84FF',
		},
	},
	line: {
		borderColor: '#707070',
		borderTopWidth: 1,
		color: '#707070'
	},
})(StepConnector);

interface HeaderProps {
	submitTitle: string,
	activeStep: number,
	steps: string[],
	handleClose(): void,
}

export default function Header(props: HeaderProps) {
	const classes = useStyles();
	const { submitTitle, activeStep, steps, handleClose } = props;

	return (
		<div className={classes.root}>
			<div style={{ display: 'flex', whiteSpace: 'nowrap', minWidth: '150px' }}>
				<Typography data-cy={submitTitle} className={classes.createProject}>{submitTitle}</Typography>
			</div>
			<Stepper classes={{ root: classes.stepperRoot }} alternativeLabel activeStep={activeStep} connector={<LineConnector />}>
				{steps.map((label: any) => (
					<Step key={label}>
						<StepLabel classes={{ label: clsx(classes.stepLabel, classes.labelMargin), active: classes.stepLabel, completed: classes.active }}
							StepIconComponent={StepIconComponent}>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', minWidth: '150px' }}>
				<CloseIcon data-cy="close-icon" className={classes.close} onClick={handleClose} />
			</div>
		</div>
	);
}

function StepIconComponent(props: any) {
	const classes = useStyles();
	const { active, completed } = props;

	return (
		completed ? <CheckCircleIcon classes={{ root: clsx(classes.iconRoot, classes.active) }} />
			: <RadioButtonUncheckedOutlinedIcon classes={{ root: clsx(classes.iconRoot, active ? classes.active : classes.default) }} />
	);
}
