import { debounce } from "lodash";
import { useEffect, useMemo, useRef } from "react";
/** Custom hook to skip function execution if that function is called too many times over a certain time period.
 * Inside the callback we have access to the component props/state
* @returns a callback function to call
*/

export function useDebounce(callback:any, delay:number = 1000) {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, delay, {
      leading: false // If it is true the function will get executed immediately rather than waiting for the ‘wait’ time.
    });
  }, []);

  return debouncedCallback;
};