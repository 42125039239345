import { useRef, useState } from "react";

export const usePopperRef = () => {
  const elementRef = useRef(null);
  const [anchorElement, setAnchorElement] = useState<any>(null);
  const handleClick = (a: any) => {
    setAnchorElement(
      anchorElement ? null : elementRef.current
    );
  };

  return [ elementRef, anchorElement, handleClick ]
};
