import { useEffect, useRef } from "react";
/** Custom hook for handling previous value in useEffect
* @returns ref.current
*/

export function usePrevious<T extends unknown>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};