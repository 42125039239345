import { gql } from '../graphqlHelpers';

export const getNestedFolders = gql`
  query GetNestedFolders($spaceId: ID!){
      getNestedFolders(spaceId: $spaceId){
        id
        name
        isDashboard
        isInternal
      }
  }
`;