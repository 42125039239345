import React, { useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { MoneyDisplay } from '../../common/MoneyDisplay';
import { useLocalStorage, useSessionStorage } from '../../../utils/useStorage';
import { useLazyTaskDataSummaryQuery } from '../../../graphql/queries/TaskDataSummary';
import { PRIOR_PERIODS } from '../../../tailwind/Constants';
import FluxContent from './FluxContent';
import FluxGrowth from './FluxGrowth';
import moment from 'moment';
import { Skeleton } from '../Skeleton';
import { calculatePriorPeriodEndDate, calculatePriorDate } from '../../../utils/dateUtils';

interface FluxAnalysisProps {
  task: any
  displaySkeleton: boolean;
}

/**
 * `FluxAnalysis` tab component used in the `FilesPane` component data tab section.
 * This component is using the `TailwindCSS` design pattern.
 * @param `props.task` - The current displaying task.
 *
 * @return {React.JSXElementConstructor} The `FluxAnalysis` component.
 */
export default function  FluxAnalysis(props:  FluxAnalysisProps) {
  const [open, setOpen] = useState(false);

  const [showMonthFlux, setShowMonthFlux] = useState(false);
  const [showQuarterFlux, setShowQuarterFlux] = useState(false);
  const [showYearFlux, setShowYearFlux] = useState(false);

  // States
  const [summary, setSummary] = useState<any>({});
  const { task, displaySkeleton } = props;

  // Initial states to save the TaskDataSummary values on page loads.
  const [primaryValue, setPrimaryValue] = useState<number>(0);

  // States for the prior month values
  const [priorMonthValue, setPriorMonthValue] = useState<number>(0);
  const [priorMonthStartDate, setPriorMonthStartDate] = useState<moment.Moment>(moment());
  const [priorMonthEndDate, setPriorMonthEndDate] = useState<moment.Moment>(moment());
  const [priorMonthDollarVarianceValue, setPriorMonthDollarVarianceValue] = useState<number>(0);
  const [priorMonthPercentageVarianceValue, setPriorMonthPercentageVarianceValue] = useState<number>(0);

  // States for the prior quarter values
  const [priorQuarterValue, setPriorQuarterValue] = useState<number>(0);
  const [priorQuarterStartDate, setPriorQuarterStartDate] = useState<moment.Moment>(moment());
  const [priorQuarterEndDate, setPriorQuarterEndDate] = useState<moment.Moment>(moment());
  const [priorQuarterDollarVarianceValue, setPriorQuarterDollarVarianceValue] = useState<number>(0);
  const [priorQuarterPercentageVarianceValue, setPriorQuarterPercentageVarianceValue] = useState<number>(0);

  // States for the prior year values
  const [priorYearValue, setPriorYearValue] = useState<number>(0);
  const [priorYearStartDate, setPriorYearStartDate] = useState<moment.Moment>(moment());
  const [priorYearEndDate, setPriorYearEndDate] = useState<moment.Moment>(moment());
  const [priorYearDollarVarianceValue, setPriorYearDollarVarianceValue] = useState<number>(0);
  const [priorYearPercentageVarianceValue, setPriorYearPercentageVarianceValue] = useState<number>(0);

  // States for the prior month/quarter/year growth values
  const [priorMonthGrowth, setPriorMonthGrowth] = useState<number>(0);
  const [priorQuarterGrowth, setPriorQuarterGrowth] = useState<number>(0);
  const [priorYearGrowth, setPriorYearGrowth] = useState<number>(0);

  // States to handle the case where we need to display the skeleton for 5 seconds to make sure the data are pulled
  const [shouldDisplaySkeleton, setShouldDisplaySkeleton] = useState(false);
  
  // The returned grow values may be decimal with many numbers after the coma.
  // So, we need to limit to 2 numbers after the coma.
  const handleRoundValue = (value: number) => Math.round(value * 100) / 100;

  /**
   * This function updates various summary data based on the current summary input.
   *
   * @param {any} currentSummary - The current summary data. The following properties are used if present:
   *     primaryReconValue, priorMonthValue, priorQuarterValue, priorYearValue,
   *     priorMonthDifference, priorMonthPercentage, priorQuarterDifference,
   *     priorQuarterPercentage, priorYearDifference, priorYearPercentage.
   * 
   * If currentSummary equals to 0, then all the state variables will be set to 0 and dates are set to the current moment.
   * If not, each state variable will be set to its corresponding value in the currentSummary object.
   * 
   * The function also calculates the start and end dates for the prior month, quarter and year based on the primary source type.
   * If the primary source type is 3, it uses the integration inStartDate and inEndDate, possibly adjusting for locked start dates and period lengths.
   * If the primary source type is 6, it calculates the prior dates based on the nsStartDate and nsEndDate values in the integration info.
   * 
   * @returns {void}
   */
  const handleUpdateSummaryData = (currentSummary: any) => {
    const priodBalance = currentSummary === 0 ? 0 : currentSummary.primaryReconValue;
    setPrimaryValue(priodBalance);

    setPriorMonthValue(currentSummary === 0 ? 0 :currentSummary.priorMonthValue)
    setPriorQuarterValue(currentSummary === 0 ? 0 :currentSummary.priorQuarterValue);
    setPriorYearValue(currentSummary === 0 ? 0 :currentSummary.priorYearValue);

    setPriorMonthDollarVarianceValue(currentSummary === 0 ? 0 : currentSummary.priorMonthDifference);
    setPriorMonthPercentageVarianceValue(currentSummary === 0 ? 0 : currentSummary.priorMonthPercentage);
  
    setPriorQuarterDollarVarianceValue(currentSummary === 0 ? 0 : currentSummary.priorQuarterDifference);
    setPriorQuarterPercentageVarianceValue(currentSummary === 0 ? 0 : currentSummary.priorQuarterPercentage);

    setPriorYearDollarVarianceValue(currentSummary === 0 ? 0 : currentSummary.priorYearDifference);
    setPriorYearPercentageVarianceValue(currentSummary === 0 ? 0 : currentSummary.priorYearPercentage);

    setPriorMonthGrowth(currentSummary === 0 ? 0 : handleRoundValue(currentSummary.priorMonthPercentage));
    setPriorQuarterGrowth(currentSummary === 0 ? 0 : handleRoundValue(currentSummary.priorQuarterPercentage));
    setPriorYearGrowth(currentSummary === 0 ? 0 : handleRoundValue(currentSummary.priorYearPercentage));

    if (props.task?.primarySourceType === 3) {
      setPriorMonthStartDate(
        currentSummary === 0 ? moment() 
          : props.task?.primarySource?.integrationInfo?.startDateLocked ? moment(props.task?.primarySource?.integrationInfo?.inStartDate)
            : moment(props.task?.primarySource?.integrationInfo?.inStartDate).clone().subtract(1, 'months')
      )
      
      setPriorMonthEndDate(
        currentSummary === 0 ? moment() 
          : calculatePriorPeriodEndDate(moment(props.task?.primarySource?.integrationInfo?.inEndDate), 1)
      );

      setPriorQuarterStartDate(
        currentSummary === 0 ? moment() 
          : props.task?.primarySource?.integrationInfo?.startDateLocked ? moment(props.task?.primarySource?.integrationInfo?.inStartDate)
            : moment(props.task?.primarySource?.integrationInfo?.inStartDate).clone().subtract(3, 'months')
      );
    
      setPriorQuarterEndDate(
        currentSummary === 0 ? moment() 
          : calculatePriorPeriodEndDate(moment(props.task?.primarySource?.integrationInfo?.inEndDate), 3)
      );

      setPriorYearStartDate(
        currentSummary === 0 ? moment() 
          : props.task?.primarySource?.integrationInfo?.startDateLocked ? moment(props.task?.primarySource?.integrationInfo?.inStartDate)
            : moment(props.task?.primarySource?.integrationInfo?.inStartDate).clone().subtract(1, 'years')
      );
      
      setPriorYearEndDate(
        currentSummary === 0 ? moment() 
          : calculatePriorPeriodEndDate(moment(props.task?.primarySource?.integrationInfo?.inEndDate), 12)
      );
    } else if (props.task?.primarySourceType === 5) {
      setPriorMonthStartDate(
        currentSummary === 0 ? moment() 
          : props.task?.primarySource?.integrationInfo?.startDateLocked ? moment(props.task?.primarySource?.integrationInfo?.qboStartDate)
            : moment(props.task?.primarySource?.integrationInfo?.qboStartDate).clone().subtract(1, 'months')
      )
      
      setPriorMonthEndDate(
        currentSummary === 0 ? moment() 
          : calculatePriorPeriodEndDate(moment(props.task?.primarySource?.integrationInfo?.qboEndDate), 1)
      );

      setPriorQuarterStartDate(
        currentSummary === 0 ? moment() 
          : props.task?.primarySource?.integrationInfo?.startDateLocked ? moment(props.task?.primarySource?.integrationInfo?.qboStartDate)
            : moment(props.task?.primarySource?.integrationInfo?.qboStartDate).clone().subtract(3, 'months')
      );
    
      setPriorQuarterEndDate(
        currentSummary === 0 ? moment() 
          : calculatePriorPeriodEndDate(moment(props.task?.primarySource?.integrationInfo?.qboEndDate), 3)
      );

      setPriorYearStartDate(
        currentSummary === 0 ? moment() 
          : props.task?.primarySource?.integrationInfo?.startDateLocked ? moment(props.task?.primarySource?.integrationInfo?.qboStartDate)
            : moment(props.task?.primarySource?.integrationInfo?.qboStartDate).clone().subtract(1, 'years')
      );
      
      setPriorYearEndDate(
        currentSummary === 0 ? moment() 
          : calculatePriorPeriodEndDate(moment(props.task?.primarySource?.integrationInfo?.qboEndDate), 12)
      );
    } else if (props.task?.primarySourceType === 6) {
      setPriorMonthStartDate(
        currentSummary === 0 ? moment()
          : props.task?.primarySource?.integrationInfo?.nsStartDate ? moment(props.task?.primarySource?.integrationInfo?.nsStartDate)
            : moment(calculatePriorDate(props.task?.primarySource?.integrationInfo?.nsStartDate, 1))
      );
  
      setPriorMonthEndDate(
        currentSummary === 0 ? moment()
          : calculatePriorDate(props.task?.primarySource?.integrationInfo?.nsEndDate, 1)
      );

      setPriorQuarterStartDate(
        currentSummary === 0 ? moment()
          : props.task?.primarySource?.integrationInfo?.nsStartDate ? moment(props.task?.primarySource?.integrationInfo?.nsStartDate)
            : calculatePriorDate(props.task?.primarySource?.integrationInfo?.nsStartDate, 3)
      );
    
      setPriorQuarterEndDate(
        currentSummary === 0 ? moment()
          : calculatePriorDate(props.task?.primarySource?.integrationInfo?.nsEndDate, 3)
      );

      setPriorYearStartDate(
        currentSummary === 0 ? moment()
          : props.task?.primarySource?.integrationInfo?.nsStartDate ? moment(props.task?.primarySource?.integrationInfo?.nsStartDate)
            : calculatePriorDate(props.task?.primarySource?.integrationInfo?.nsStartDate, 12)
      );
      
      setPriorYearEndDate(
        currentSummary === 0 ? moment()
          : calculatePriorDate(props.task?.primarySource?.integrationInfo?.nsEndDate, 12)
      );
    }
  };

  // Fetch the current task TaskDataSummary
  const { loading, data } = useLazyTaskDataSummaryQuery(task.id);

  // With this useEffect, we:
  // a-) memorize the data when page refreshes
  // b-) filter data and test the results
  useEffect(() => {
    if (loading || !data) return;

    setSummary(data);
  }, [loading, data]);

  useEffect(() => {
    const taskDataSummary = summary && summary.taskDataSummary

    if(taskDataSummary == null || taskDataSummary.length === 0) {
      handleUpdateSummaryData(0);
    } else {
      handleUpdateSummaryData(taskDataSummary[0]);
    }
  }, [summary, props.task]);

  useEffect(() => {
    if (priorMonthValue === null || priorMonthValue === undefined ||
        priorQuarterValue === null || priorQuarterValue === undefined ||
        priorYearValue === null || priorYearValue === undefined) {
      setShouldDisplaySkeleton(true);
    } else {
      setShouldDisplaySkeleton(displaySkeleton);
    }
  }, [displaySkeleton, priorMonthValue, priorQuarterValue, priorYearValue]);

  const buildTitle = (range: string) => {}

  const prevLocalStatus = useLocalStorage("isFluxTabOpen", [open, setOpen]);
  const prevSessionStatus = useSessionStorage("isFluxTabOpen", [open, setOpen]);

  const handleToggle = () => {
    setOpen(!open);
  }

  const handleShowPeriodFlux = (range: string) => {
    switch (range) {
      case '30_days':
        handleShowMonthFlux();
        break;
      case '90_days':
        handleShowQuarterFlux();
        break;
      case '1_year':
        handleShowYearFlux();
        break;
      default:
        handleShowMonthFlux();
        break;
    }
  }

  const handleShowMonthFlux = () => {
    setShowMonthFlux(!showMonthFlux);
    setShowQuarterFlux(false);
    setShowYearFlux(false);
  }

  const handleShowQuarterFlux = () => {
    setShowQuarterFlux(!showQuarterFlux);
    setShowMonthFlux(false);
    setShowYearFlux(false);
  }

  const handleShowYearFlux = () => {
    setShowYearFlux(!showYearFlux);
    setShowMonthFlux(false);
    setShowQuarterFlux(false);
  }

  const varianceValueIsLong = (priorMonthDollarVarianceValue && priorMonthDollarVarianceValue.toString().length > 7) || 
                              (priorQuarterDollarVarianceValue && priorQuarterDollarVarianceValue.toString().length > 7) || 
                              (priorYearDollarVarianceValue && priorYearDollarVarianceValue.toString().length > 7)

  const varianceGrowthIsLong = (priorMonthGrowth.toString().length > 6) || 
                               (priorQuarterGrowth.toString().length > 6) || 
                               (priorYearGrowth.toString().length > 6)   

  const buildFontSize = () => varianceValueIsLong ? (varianceGrowthIsLong ? 10 : 11 ) : 12;

  return (
    <Disclosure defaultOpen={open}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={
              `${open ? 'border-b-0' : 'border-b-200'
                } focus-visible:ring-opacity-75 flex justify-between items-center p-4 w-full text-sm font-medium text-left text-gray-400 border border-x-0 border-gray-200 hover:bg-blue-50 focus:outline-none focus-visible:ring focus-visible:ring-blue-100`
              }
            onClick={() => handleToggle()}
          >
            <div className='flex items-center justify-between w-full text-xs text-gray-400'>
              <span className='font-bold'>Flux Analysis</span>
              {priorMonthDollarVarianceValue ? (
                <div className='flex items-center mr-3 font-bold' data-cy='flux-analysis-summary-data'>
                  <span> MoM: </span>
                  <Skeleton
                    isLoaded={shouldDisplaySkeleton}
                    style={{
                      width: '120px',
                      height: '25px',
                      display: 'flex',
                      justifyContent: 'start'
                    }}
                  >
                    <span className='px-1'>
                      <MoneyDisplay 
                        value={priorMonthDollarVarianceValue} 
                        style={{ fontWeight: 700 }}
                        customSymbol={task.currency}
                        displaySymbol
                        canConvertToMBT
                      />
                    </span>
                    { priorMonthGrowth ? (
                      <span className='flex items-center'>(<FluxGrowth growthValue={priorMonthGrowth} />)</span>
                    ) : null }
                  </Skeleton>
                </div>
              ) : null }
            </div>
            <ChevronRightIcon
              className={`${open ? 'transform rotate-90' : ''} !text-lg`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="p-4 pt-0 text-sm text-gray-400 border border-t-0 border-gray-200 border-x-0">
            <div className="inline-block min-w-full align-middle" data-cy='flux-analysis-body'>
              <div className={`${(showMonthFlux || showQuarterFlux || showYearFlux) ? 'mb-4' : 'mb-0'} grid grid-cols-3 gap-4 pb-3`}>
                <div className=''>
                  <div className='text-[10px] font-medium'><span>Prior month flux</span></div>
                  <Skeleton
                    isLoaded={shouldDisplaySkeleton}
                    style={{
                      width: '120px',
                      height: '25px',
                      display: 'flex',
                      justifyContent: 'start'
                    }}
                  >
                    <div className='flex items-end pb-2'>
                      <span className="font-bold" style={{ fontSize: buildFontSize() }}>
                        { priorMonthDollarVarianceValue ? 
                          <MoneyDisplay 
                            value={priorMonthDollarVarianceValue} 
                            style={{ fontWeight: 700, fontSize: buildFontSize(), color: '#2C2C2C'}}
                            customSymbol={task.currency}
                            displaySymbol
                            canConvertToMBT
                          /> 
                          : <span className='px-5'>-</span>
                        }
                      </span>
                      <FluxGrowth growthValue={priorMonthGrowth} varianceGrowthIsLong={varianceGrowthIsLong} />
                    </div>
                  </Skeleton>
                  <button
                    onClick={() => handleShowMonthFlux()}
                    className={`${showMonthFlux ? 'bg-gray-200' : 'bg-gray-160'} border-gray-200 border-t-[1.5px] flex items-center py-2 px-4 w-full text-xs font-medium text-center rounded-sm pointer-events-auto hover:bg-gray-200`}
                    data-cy='show-month-flux-button'
                  >
                    <span className='mr-auto'>View</span>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.23216 1.81789H8.1819M8.1819 1.81789V6.76764M8.1819 1.81789L1.81794 8.18185" stroke="#606060" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </div>

                <div className=''>
                  <div className='text-[10px] font-medium'><span>Prior quarter flux</span></div>
                  <Skeleton
                    isLoaded={shouldDisplaySkeleton}
                    style={{
                      width: '120px',
                      height: '25px',
                      display: 'flex',
                      justifyContent: 'start'
                    }}
                  >
                    <div className='flex items-end pb-2'>
                      <span className="font-bold" style={{ fontSize: buildFontSize() }}>
                        { priorQuarterDollarVarianceValue ? 
                          <MoneyDisplay 
                            value={priorQuarterDollarVarianceValue} 
                            style={{ fontWeight: 700, fontSize: buildFontSize(), color: '#2C2C2C' }}
                            customSymbol={task.currency}
                            displaySymbol
                            canConvertToMBT
                          /> 
                          : <span className='px-5'>-</span>
                        }
                      </span>
                      <FluxGrowth growthValue={priorQuarterGrowth} varianceGrowthIsLong={varianceGrowthIsLong} />
                    </div>
                  </Skeleton>
                  <button
                    onClick={() => handleShowQuarterFlux()}
                    className={`${showQuarterFlux ? 'bg-gray-200' : 'bg-gray-160'} border-gray-200 border-t-[1.5px] flex items-center py-2 px-4 w-full text-xs font-medium text-center rounded-sm pointer-events-auto hover:bg-gray-200`}
                    data-cy='show-quarter-flux-button'
                  >
                    <span className='mr-auto'>View</span>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.23216 1.81789H8.1819M8.1819 1.81789V6.76764M8.1819 1.81789L1.81794 8.18185" stroke="#606060" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </div>

                <div className=''>
                  <div className='text-[10px] font-medium'><span>Prior year flux</span></div>
                  <Skeleton
                    isLoaded={shouldDisplaySkeleton}
                    style={{
                      width: '120px',
                      height: '25px',
                      display: 'flex',
                      justifyContent: 'start'
                    }}
                  >
                    <div className='flex items-end pb-2'>
                      <span className="font-bold" style={{ fontSize: buildFontSize() }}>
                        {priorYearDollarVarianceValue ? 
                          <MoneyDisplay 
                            value={priorYearDollarVarianceValue} 
                            style={{ fontWeight: 700, fontSize: buildFontSize(), color: '#2C2C2C' }}
                            customSymbol={task.currency}
                            displaySymbol
                            canConvertToMBT
                          /> 
                          : <span className='px-5'>-</span>
                        }
                      </span>
                      <FluxGrowth growthValue={priorYearGrowth} varianceGrowthIsLong={varianceGrowthIsLong} />
                    </div>
                  </Skeleton>
                  <button
                    onClick={() => handleShowYearFlux()}
                    className={`${showYearFlux ? 'bg-gray-200' : 'bg-gray-160'} border-gray-200 border-t-[1.5px] flex items-center py-2 px-4 w-full text-xs font-medium text-center rounded-sm pointer-events-auto hover:bg-gray-200`}
                    data-cy='show-year-flux-button'
                  >
                    <span className='mr-auto'>View</span>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.23216 1.81789H8.1819M8.1819 1.81789V6.76764M8.1819 1.81789L1.81794 8.18185" stroke="#606060" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </div>
              </div>
              {
                showMonthFlux ? (
                  <FluxContent
                    task={task}
                    priorPeriodTitle={PRIOR_PERIODS.month}
                    currentPeriodValue={primaryValue}
                    priorPeriodValue={priorMonthValue}
                    priorPeriodEndDate={priorMonthEndDate}
                    dollarVarianceValue={priorMonthDollarVarianceValue}
                    percentageVarianceValue={priorMonthPercentageVarianceValue}
                    handleRoundValue={handleRoundValue}
                    buildTitle={buildTitle}
                    displaySkeleton={shouldDisplaySkeleton}
                    handleShowPeriodFlux={handleShowPeriodFlux}
                  />
                ) : null
              }

              {
                showQuarterFlux ? (
                  <FluxContent
                    task={task}
                    priorPeriodTitle={PRIOR_PERIODS.quarter}
                    currentPeriodValue={primaryValue}
                    priorPeriodValue={priorQuarterValue}
                    priorPeriodEndDate={priorQuarterEndDate}
                    dollarVarianceValue={priorQuarterDollarVarianceValue}
                    percentageVarianceValue={priorQuarterPercentageVarianceValue}
                    handleRoundValue={handleRoundValue}
                    buildTitle={buildTitle}
                    displaySkeleton={shouldDisplaySkeleton}
                    handleShowPeriodFlux={handleShowPeriodFlux}
                  />
                ) : null
              }

              {
                showYearFlux ? (
                  <FluxContent
                    task={task}
                    priorPeriodTitle={PRIOR_PERIODS.year}
                    currentPeriodValue={primaryValue}
                    priorPeriodValue={priorYearValue}
                    priorPeriodEndDate={priorYearEndDate}
                    dollarVarianceValue={priorYearDollarVarianceValue}
                    percentageVarianceValue={priorYearPercentageVarianceValue}
                    handleRoundValue={handleRoundValue}
                    buildTitle={buildTitle}
                    displaySkeleton={shouldDisplaySkeleton}
                    handleShowPeriodFlux={handleShowPeriodFlux}
                  />
                ) : null
              }
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}