import React from 'react';
import clsx from 'clsx';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

import * as cs from '../../../../../constants/theme';

import CreatableSelect from 'react-select/creatable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: 'fit-content',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    grow: {
      flexGrow: 1,
    },
    selector: {
      display: 'block',
      width: 248,
      marginTop: '0px',
      color: '#606060',
      fontFamily: cs.FONT.family,
      fontWeight: cs.FONT.weight.regular,
      fontSize: cs.FONT.size.xs,
    },
  })
);



interface TagDropdownProps {
  setOpenAddTagsModal?: React.Dispatch<React.SetStateAction<boolean>>;
  options: any;
  task: any;
  id: any;
  changeHandler: any;
  placeholder?: string;
  spaceAvailable?: boolean;
}

export default function TagDropdown(props: TagDropdownProps) {
  const { setOpenAddTagsModal, options, task, id, changeHandler, placeholder } = props;

  const classes = useStyles({});
  const customSelectStyle = {
    control: () => ({
      alignItems: 'center',
      border: 'none',
      cursor: 'default',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      // minHeight: 38,
      position: 'relative',
      transition: 'all 100ms',
      boxSizing: 'border-box',
    }),
    indicatorsContainer: () => ({ display: 'none' }),
    dropdownIndicator: () => ({ display: 'none', color: 'transparent' }),
    valueContainer: () => ({
      padding: 0,
      width: props.spaceAvailable ? '80px' : '100%',
      overflow: 'hidden'
    }),
    placeholder: () => ({
      color: '#B0B0B0',
      opacity: 1,
      fontSize: '12px',
      fontWeight: 'normal',
      lineHeight: '15px',
      position: 'absolute',
      top: '50%',
      paddingLeft: 3,
      transform: 'translateY(-50%)',
    }),
    singleValue: () => ({ display: 'none' })
  };

  return (
    <div className={clsx(classes.root)} >
      <CreatableSelect
        isClearable
        styles={customSelectStyle}
        className={clsx(classes.selector)}
        options={options}
        onChange={(e: any) => changeHandler(e, task, id)}
        onCreateOptions={(e: any) => changeHandler(e, task, id)}
        placeholder={placeholder ? placeholder : 'Search entity'}
      />
    </div>
  );
}

