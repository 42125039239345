/* The purpose of this component is: 
 * to allow the user to enter their credentials for one of the integration 
 * softwares, and if they are not already 'connected'
 */

import React, { useState } from 'react';
import { TextField, Typography, Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import {Theme, makeStyles, createStyles, withStyles} from '@material-ui/core/styles';
import FlashMessage from '../common/FlashMessage';

interface SlAuthenticationProps {
  selectedIntegration: string;
  open: boolean;
  denyAction: any;
  confirmAction: any;
}

const CssTextField = withStyles({
  root: {
    width: 300,
    '& label.Mui-focused': {
      color: "#0F3B5C",
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: "#0F3B5C",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: "#0F3B5C",
      },
      '&:hover fieldset': {
        borderColor: "#0F3B5C",
      },
      '&.Mui-focused fieldset': {
        borderColor: "#0F3B5C",
      },
    },
  },
})(TextField);


export function SlAuthentication(props: any) {

  const [login, setLogin] = useState<any>({
    tenantUUID: null,
  });
  const errors = useState<any>([])

  const updateLogin = (e: React.ChangeEvent<HTMLInputElement> ) => {
    const { name, value } = e.target;
    setLogin({...login, [name]: value});
  };

  return(
    <div>
      <Dialog
        open={props.open}
        onClose={props.denyAction}
      >
        <div style={{ height: 448, width: 600, display: 'flex', flexDirection: 'column', padding: 20 }}>
          <DialogTitle>
            <Typography variant='h2'>
            {`Connect Your ${props.integrationType} Account`}
            </Typography>
          </DialogTitle>
            
            <div style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>
            <form noValidate onSubmit={props.confirmAction}
              style={{display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center'}}>
              <CssTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Tenant UUID"
                name="tenantUUID"
                //autoComplete="email"
                autoFocus
                onChange={updateLogin}
              />
            </form>
            </div>
        </div>
          
          <DialogActions>
            <Button onClick={props.denyAction} variant='outlined'>
              Cancel
            </Button>
            <Button variant='contained' onClick={() => props.confirmAction(login)}>
              Connect
            </Button>
          </DialogActions>
      </Dialog>
    </div>
 ) 
}
