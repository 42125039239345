import React, {useState, useEffect} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {
  Checkbox,
  Grid,
  Input,
  MenuItem,
  Popper,
  PopperProps,
  Select,
  TextField,
} from '@material-ui/core';
import {
  CheckCircleOutline,
  CheckCircle,
  ListSharp,
  CloseOutlined,
} from '@material-ui/icons';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import {Autocomplete} from '@material-ui/lab';

const COMPANY_LIST = gql`
  query companyList($id: ID!) {
    listsForCompany(id: $id) {
      id
      name
    }
  }
`;

/* deprecated 
export const PROJECT_LIST = gql`
  query($id: ID!) {
    tasksForList(id: $id) {
      id
      name
      waitingForDependency {
        id
        name
      }
      dependencyIsBlocking {
        id
        name
      }
    }
  }
`;
*/

interface SearchDependencyProps {
  lists: any;
  task: any;
  skinnyLists: any;
  setRelatedTaskId: any;
}

export default function SearchDependency(props: any) {
  const {lists, task, skinnyLists, setRelatedTaskId} = props;

  const classes = useStyles();

  const listOptions = [];
  for (let i = 0; i < lists.length; ++i) {
//    if (!lists[i].isArchived) {
     
      let lname = lists[i].name;
      if (lname.length > 35) { lname = lname.substring(0, 32) + '...'; }
      //var option = {id: lists[i].id, name: lists[i].name};
      var option = {id: lists[i].id, name: lname};
      listOptions.push(option);

//    }
  }


  var currentList = {id: '', name: ''};
  var displayTasks = [];

  //guard against clickaway which makes task.list undefined 
  if (task && task.list) {
    currentList = {id: task.list.id, name: task.list.name};

    displayTasks = skinnyLists.filter(
        (o: any) => o.id == task.list.id
    );
  } 

  function filterTasks(_tasks: any[]){
    return _tasks.filter((_task, idx) => _task.id !== task.id);
  }

  var defaultTaskOptions = [];
  if (displayTasks[0]) {
    defaultTaskOptions = filterTasks(displayTasks[0].tasks);
    //truncate task display names 
    for (var i = 0; i < defaultTaskOptions.length; ++i) {
      let tname = defaultTaskOptions[i].name;
      if (tname.length > 35) { tname = tname.substring(0, 32) + '...'; }
      defaultTaskOptions[i].displayName = tname;
    }
  }

/* deprecated 
  const {
    loading: projectLoading,
    data: projectData,
    error: projectError,
  } = useQuery(PROJECT_LIST, {
    variables: {id: 1},
  });
*/
  const projectLoading = false;

  const [taskText, setTaskText] = useState('');
  const [checked, setChecked] = useState(false);
  const [project, setProject] = useState(
    'Editable but autopopulated project name'
  );

  const [taskTextId, setTaskTextId] = React.useState(0);
  const [taskTextName, setTaskTextName] = React.useState('');

  const [projectId, setProjectId] = React.useState(0);
  const [projectName, setProjectName] = React.useState('');
  const [taskOptions, setTaskOptions] = useState<any[]>(defaultTaskOptions);

  const isTaskTextNotEmpty = taskText.length > 0;
  const isProjectNotEmpty = project.length > 0;

  const handleTaskTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTaskText(event.target.value);
  };

  useEffect(() => {
    let displayTasks = skinnyLists.filter(
        (o: any) => o.id == project
    );

    if (displayTasks[0]) {
      let _taskOptions = filterTasks(displayTasks[0].tasks);
      for (var i = 0; i < _taskOptions.length; ++i) {
        let tname = _taskOptions[i].name;
        if (tname.length > 35) { tname = tname.substring(0, 32) + '...'; }
        _taskOptions[i].displayName = tname;
      }
      setTaskOptions(_taskOptions);
    }

  }, [project]);

  const handleProjectChange = (event: React.ChangeEvent<HTMLInputElement>, value: any) => {
    setProject(value.id);
    setProjectName(value.name);
  };

  const handleReset = () => {
    setProject('');
  };

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <Grid container direction="column">
      <Grid item container xs direction="row" style={{margin: 'inherit'}}>
        <Grid item xs={1} style={{marginRight: '20px'}}>
          <Checkbox
            disabled
            style={{color: '#3A84FF'}}
            icon={<CheckCircleOutline />}
            checkedIcon={<CheckCircle />}
            checked={!!taskTextName}
            onChange={handleCheckChange}
          />{' '}
        </Grid>
        <Grid item xs={10}>
          {projectLoading ? (
            <p>Loading ...</p>
          ) : (
            <>
              <div>
                <Autocomplete
                  disableClearable
                  ListboxProps={{
                    style: {
                      padding: '0px',
                      fontWeight: 'normal',
                    },
                  }}
                  id="task-name"
                  forcePopupIcon={false}

                  options={taskOptions}
                  getOptionLabel={(option: {name: string; id: any, listNumber:any, displayName:any}) =>
                    `${option.listNumber} - ${option.displayName}`
                  }
                  onChange={(event: any, newValue: any | null) => {
                    setTaskTextId(newValue.id);
                    setTaskTextName(newValue.name);
                    setRelatedTaskId(newValue.id);
                  }}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === 'clear') {
                      setTaskTextName('');
                      return;
                    } else {
                      setTaskTextName(newInputValue);
                    }
                  }}
                  style={{width: 300, paddingTop: '10px'}}
                  renderInput={params => (
                    <TextField
                      {...params}
                      onChange={handleTaskTextChange}
                      placeholder="Search Task Name"
                      variant="standard"
                      InputLabelProps={{
                        style: {color: '#B0B0B0', fontWeight: 'normal'},
                      }}
                    />
                  )}
                />
              </div>
            </>
          )}
        </Grid>
      </Grid>

      <Grid item container xs direction="row" style={{margin: 'inherit'}}>
        <Grid item xs={1} style={{marginRight: '20px'}}>
          {' '}
          <Checkbox
            disabled
            style={{color: '#3A84FF'}}
            icon={<CheckCircleOutline />}
            checkedIcon={<CheckCircle />}
            checked={!!projectName}
            onChange={handleCheckChange}
          />
        </Grid>
        <Grid item xs={10} style={{paddingTop: '3%'}}>
          <Autocomplete
            disableClearable
            ListboxProps={{
              style: {
                padding: '0px',
              },
            }}
            clearText="clear"
            id="project-name"
            onChange={(event: any, value: any) => handleProjectChange(event, value)}
            className={classes.input}
            forcePopupIcon={false}
            options={listOptions}
            defaultValue={currentList}
            onClick={handleReset}
            getOptionLabel={(option: {name: string; id: any}) =>
              `${option.name}`
            }
            onInputChange={(event, newInputValue, reason) => {
              if (reason === 'clear') {
                setProjectName('');
                return;
              } else {
                setProjectName(newInputValue);
              }
            }}
            // style={{width: 300, color: '#2C2C2C', fontWeight: 'normal'}}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                value={project}
                InputLabelProps={{
                  style: {color: '#2C2C2C', fontWeight: 'normal'},
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '.MuiTabPanel-root': {
      padding: '10px 20px 20px 4px',
    },

    '.MuiAutocomplete-popup': {
      zIndex: 999999,
    },
    '.MuiAutocomplete-inputRoot': {
      fontWeight: 'normal',
    },
    '.MuiAutocomplete-option': {
      fontWeight: 'normal',
    },
  },
  input: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
  '.MuiList-padding': {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  closeIc: {
    fontSize: '12px',
  },
}));
