import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {
  Button,
  Grid,
} from '@material-ui/core';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import SearchDependency from './SearchDependency';
import { useDispatchContext } from '../../../../../../../../store';
import { DETAILED_TASK_INFO } from '../../../../../../../../graphql/fragments/detailedTaskInfo';
import { useDebounce } from '../../../../../../../../hooks/useDebounce';

export const INSERT_TASK_DEPENDENCY = gql`
  ${DETAILED_TASK_INFO}
  mutation CreateTaskDependency($taskDependency: TaskDependencyAttributes!) {
    createTaskDependency(taskDependency: $taskDependency) {
      success
      task {
        ...DetailedTaskInfo
      }
      relatedTask {
        ...DetailedTaskInfo
      }
    }
  }
`;

export default function RelatedOnly(props: any) {
  const {lists, task, updateMultipleTasksInCache, skinnyLists, setSkinnyLists, refreshTaskNotifications} = props;
  const dispatch = useDispatchContext()

  const classes = useStyles();


  const [relatedTaskId, setRelatedTaskId] = React.useState<string | null>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [
    insertTaskDependency,
    {loading: mutationLoading, data: mutationData, error: mutationError},
  ] = useMutation(INSERT_TASK_DEPENDENCY);

  const handleSubmit = (e:any) => {
    e.preventDefault();
    processSubmit();
  }

  const processSubmit = useDebounce(() => {
    insertTaskDependency({
      variables: {
        taskDependency: {
          taskId: task.id,
          isDependent: false,
          relatedId: relatedTaskId,
          hasStartDate: false,
        },
      },
    })
    .then((response: any) => {

      if (refreshTaskNotifications && response?.data?.createTaskDependency?.success) {
        refreshTaskNotifications();
      }

      //NEED TO UPDATE TASK IN CACHE HERE

      const updatedTasks = [];

      const updatedTask = response?.data?.createTaskDependency?.task;
      const updatedRelatedTask = response?.data?.createTaskDependency?.relatedTask;

      if(updatedTask){
        updatedTasks.push(updatedTask);
      }

      if(updatedRelatedTask){
        updatedTasks.push(updatedRelatedTask);
      }

      if(updatedTasks.length){
        updateMultipleTasksInCache(updatedTasks);
      }
      //handle response
      // show message
      // to do check for success, otherwise show error
      props.snackbarStat(true);
      props.getMsg(`${task.id}`, 'added');

      setAnchorEl(props.anchorEl);
      // add dependency to skinnyList object, mostly for re-render of dependency section on overviewpane
      // find correct task indices
      // webpack compile error if initialized as null or uninitialized so init as -1
      let relatedName, relatedDueDate, relatedListNumber;
      for(let list_iter=0; list_iter < skinnyLists.length; ++list_iter ){
        if(skinnyLists[list_iter].tasks &&
          skinnyLists[list_iter].tasks.length > 0){
            let _tasks = skinnyLists[list_iter].tasks;
            for (let i = 0; i < _tasks.length; ++i) {
              if (_tasks[i].id == relatedTaskId) {
                relatedName = _tasks[i].name;
                relatedDueDate = _tasks[i].dueDate;
                relatedListNumber = _tasks[i].listNumber;
              }
            }
          }
      }

      // add new dependency to the Optimistic UI
      let newly_created_dependency = {
        __typename: 'TaskDependency',
        dueDays: '',
        dueHours: '',
        hasStartDate: false,
        isDependent: false,
        id: '18',
        status: 'Unresolved',
        taskId: task.id,
        taskName: task.name,
        taskDueDate: task.dueDate,
        taskListNumber: task.listNumber,
        relatedId: relatedTaskId,
        relatedName: relatedName,
        relatedDueDate: relatedDueDate,
        relatedListNumber: relatedListNumber
      };
      dispatch({type: 'DEPEDENCIES_UPDATED'})

      // add it to the live display object
      // make sure to deep copy skinnyLists so we're not operating directly on the object
      let _skinnyLists = skinnyLists.map((a: any) => {
        return ({...a});
      });


      let dependency_count = 2;
      for(let list_iter=0; list_iter < _skinnyLists.length; ++list_iter ){
        if(dependency_count == 0){
          break;
        }
        if(_skinnyLists[list_iter].tasks &&
          _skinnyLists[list_iter].tasks.length > 0){
            let _tasks = _skinnyLists[list_iter].tasks;
            for (let i = 0; i < _tasks.length; ++i) {
              if(dependency_count == 0){
                break;
              }
              if (_tasks[i].id == task.id || _tasks[i].id == relatedTaskId) {
                _tasks[i].dependencies.push(newly_created_dependency);
                dependency_count--;
              }
            }
          }
      }
      setSkinnyLists(_skinnyLists);
    })
    .catch((err: any) => {
    });
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <SearchDependency
          lists={skinnyLists}
          task={task}
          skinnyLists={skinnyLists}
          setRelatedTaskId={setRelatedTaskId}
        />

        <div>
          <Grid item xs justifyContent="flex-end" className={classes.button}>
            <Button
              variant="contained"
              disabled={!task.id || !relatedTaskId}
              color="primary"
              type="submit"
              data-cy='related-save'
            >
              Save
            </Button>
          </Grid>
        </div>
      </form>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '.MuiTabPanel-root': {
      padding: '10px 20px 20px 4px',
    },

    '.MuiAutocomplete-popup': {
      zIndex: 999999,
    },
  },
  input: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
  '.MuiList-padding': {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  closeIc: {
    fontSize: '12px',
  },
  button: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
}));
