/* The purpose of this component is:
 * to allow the user to enter their credentials for one of the integration
 * softwares, and if they are not already 'connected'
 */

import React, { useState } from 'react';
import { TextField, Typography, Button, Dialog, DialogActions, DialogTitle, CircularProgress } from '@material-ui/core';
import { Theme, makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import FlashMessage from '../common/FlashMessage';

interface IntacctAuthenticationProps {
  selectedIntegration: string;
  open: boolean;
  denyAction: any;
  confirmAction: any;
}

const CssTextField = withStyles({
  root: {
    width: 300,
    '& label.Mui-focused': {
      color: '#0F3B5C'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0F3B5C'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#0F3B5C'
      },
      '&:hover fieldset': {
        borderColor: '#0F3B5C'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0F3B5C'
      }
    }
  }
})(TextField);

export function IntacctAuthentication(props: any) {
  const [login, setLogin] = useState<any>({
    companyId: null,
    userId: null,
    password: null
  });
  const errors = useState<any>([])

  const updateLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  return (
    <div>
      <Dialog
        onClose={props.denyAction}
        open={props.open}
      >
        <div style={{ height: 448, width: 600, display: 'flex', flexDirection: 'column', padding: 20 }}>
          <DialogTitle>
            <Typography variant='h2'>
              {`Connect Your ${props.integrationType} Account`}
            </Typography>
          </DialogTitle>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <form
              noValidate
              onSubmit={props.confirmAction}
              style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            >
              <CssTextField
                autoFocus
                fullWidth
                label="Company ID"
                margin="normal"
                name="companyId"
                onChange={updateLogin}
                // autoComplete="email"
                required
                variant="outlined"
              />
              <CssTextField
                fullWidth
                label="User ID"
                margin="normal"
                name="userId"
                onChange={updateLogin}
                required
                variant="outlined"
              />
              <CssTextField
                autoComplete="current-password"
                fullWidth
                id="password"
                label="User Password"
                margin="normal"
                name="password"
                onChange={updateLogin}
                required

                type="password"
                variant="outlined"
              />
            </form>
          </div>
        </div>

        <DialogActions>
          <Button
            onClick={props.denyAction}
            variant='outlined'
          >
              Cancel
          </Button>
          <Button
            onClick={() => props.confirmAction(login)}
            variant='contained'
            disabled={props.loading}
          >
            {props.loading ? (
              <span>
                <CircularProgress size={12} />
                <span style={{ marginLeft: '8px' }}>Processing</span>
              </span>
            ) : (
              'Accept'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
