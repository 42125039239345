  /**
   * By default, the tasks are sorted by the task id
   */

export function sortingFunctionHelper(activeSort: string | boolean, nameSort: string | boolean, dueDateSort: string | boolean, 
    differenceSort: string | boolean, primarySort: string | boolean, secondarySort: string | boolean) {
      return function applySorting(one: any, two: any,) {
        if (!activeSort) { 
          let parsedOneId = parseInt(one.id, 10);
          let parsedTwoId = parseInt(two.id, 10);
    
          if (parsedOneId < parsedTwoId) return -1;
          if (parsedOneId > parsedTwoId) return 1;
          return 0;
        }
        var a;
        switch (activeSort) {
          case 'name': {
            a = nameSort;
            one = one.name.toLowerCase();
            two = two.name.toLowerCase();
          }
            break;
          case 'dueDate': {
            a = dueDateSort;
            one = new Date(one.dueDate);
            two = new Date(two.dueDate);
          }
            break;
          case 'difference': {
            a = differenceSort;
            one = Number(one.primaryValue) - Number(one.secondaryValue);
            two = Number(two.primaryValue) - Number(two.secondaryValue);
          }
          break;
          case 'primary': {
            a = primarySort;
            one = Number(one.primaryValue);
            two = Number(two.primaryValue);
          }
          break;
          case 'secondary': {
            a = secondarySort;
            one = Number(one.secondaryValue);
            two = Number(two.secondaryValue);
          }
          break;
        }
        if (a === 'DESC') {
          if (two < one) { return -1; }
          if (two > one) { return 1; }
          return 0;
        } else if (a === 'ASC') {
          if (one < two) { return -1; }
          if (one > two) { return 1; }
          return 0;
        }
      }
  }
