import React, { useState, useEffect, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router';
import idx from 'idx';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Paper,
  List,
  ListItem,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';
const Excel = require('images/dummy/logos/excel.svg');

import { useDispatchContext, useStateContext } from '../../../../../../store';

import { useDropzone } from 'react-dropzone';
import * as cs from '../../../../../../constants/theme';
import NameLabel from '../../../../../common/NameLabel';
import { useGlobalStyles } from '../../../../../common/Style';
import ListDropdown from './ListDropdown';
import VersionDropdown from './VersionDropdown';

import { StatusDropdown } from '../../../../../common/StatusDropdown';
import { useUpdateTask } from '../../../../../../graphql/mutations/UpdateTaskHook';
import { sortTasks } from '../../../FilesPage';

import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { useLazyUserTagging } from '../../../../../../graphql/queries/UserTagging';
import { MessageTaggingBox } from '../../../../../task/TaskPage/components/SidePanel/components/TaskMessenger/MessageTaggingBox';
import { getCaretCoordinates } from './getCaretCoordinates';
import { FileIcon } from '../../../../../common/FileIcon';
import { RDS_MESSAGE_BASE_URL, RDS_MESSAGE_KEY } from '../../../../../common/Constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    table: {
      tableLayout: 'fixed',
    },
    body: {
      height: 'calc(100vh - 220px)',
      padding: '0px calc((100% - 1380px) / 2) 0px calc((100% - 1380px) / 2)',
      borderBottom: '1px solid #D8D8D8',
      overflow: 'auto',
    },
    invisible: {
      display: 'none',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    grow: {
      flexGrow: 1,
    },
    footer: {
      height: '60px',
      padding: '0px calc((100% - 1380px) / 2) 0px calc((100% - 1380px) / 2)',
    },
    statusButton: {
      width: '110px',
      border: '1px solid #ECECEC',
      cursor: 'pointer',
      background: '#FFFFFF',
      borderRadius: '3px',
    },
    primary: {
      color: cs.COLORS.primary,
    },
    filesPane: {
      marginTop: '24px',
      padding: '6px',
      background: '#F2F2F2',
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
      overflowX: 'scroll',
      overflowY: 'hidden',
    },
    fileLabel: {
      height: '60px',
      margin: '0px 6px 0px 0px',
      // width: '150px',
    },
    textarea: {
      width: '100%',
      height: '100px',
      marginTop: '24px',
      padding: '12px',
      resize: 'none',
      fontFamily: cs.FONT.family,
      fontSize: cs.FONT.size.xs,
      fontWeight: cs.FONT.weight.light,
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
    },
    input: {
      width: '100%',
      border: 'none',
      background: 'none',
      fontFamily: cs.FONT.family,
      fontSize: cs.FONT.size.xs,
      fontWeight: cs.FONT.weight.light,
      '&:hover': {
        border: 'none',
      },
      '&:focus': {
        border: 'none',
        outline: 'none',
      },
    },
    selection: {
      marginTop: '24px',
      height: '42px',
      borderTop: '1px solid #D8D8D8',
      borderBottom: '1px solid #D8D8D8',
    },
    delete: {
      fontSize: '28px',
      cursor: 'pointer',
      '&:hover': {
        border: `1px solid ${cs.COLORS.primary}`,
        borderRadius: '3px',
      },
    },
    mr12: {
      marginRight: '12px',
      height: '25px'
    },
  })
);

interface SingleTaskProps {
  index?: any;
  value?: any;
  location?: any;
  history?: any;
  files?: any[];
  lists?: any[];
  tasks?: any[];
  setFiles?: any;
}

function SingleTask(props: any) {
  const {
    status,
    setStatus,
    fileProperties,
    setFileProperties,
    selectedTaskId,
    setSelectedTaskId,
    selectedListId,
    setSelectedListId,
    index,
    value,
    location,
    history,
    lists,
    tasks,
    files,
    setFiles,
  } = props;
  const classes = useStyles();

  const dispatch = useDispatchContext();
  const state = useStateContext();

  //  keeping track of togglestate
  const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
  // const [retries, setRetries] = useState<number>(0);
  const [allToggle, setToggle] = useState<boolean>(false);
  const [uploadClicked, setUploadClicked] = useState<boolean>(false);
  const [generalComment, setGeneralComment] = useState<string>('');
  const [primary, setPrimary] = useState<string>('Primary');
  const [secondary, setSecondary] = useState<string>('Secondary');
  const [statusDropdownOpen, setStatusDropdownOpen] = useState<boolean>(false);

  // mutation hook to update status of a task
  const [
    updateStatus,
    { loading: updateLoading, data: updateData, error: updateError },
  ] = useUpdateTask({
    id: selectedTaskId,
    status: status,
  });

  function getCSRFToken(): string {
    const el = document.querySelector('meta[name="csrf-token"]');
    return (el && el.getAttribute('content')) || '';
  }

  const onDrop = (addedFiles: File[]) => {
    //setFiles([...files, ...addedFiles]);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const toggleSelectedFile = (id: number) => {
    if (selectedFiles.includes(id)) {
      setSelectedFiles(selectedFiles.filter((fid) => fid != id));
    } else {
      setSelectedFiles([...selectedFiles, id]);
    }
  };

  const toggleAll = () => {
    selectedFiles.length
      ? setSelectedFiles([])
      : setSelectedFiles(files.map((file: any, index: number) => index));
    setToggle((toggleAll) => !toggleAll);
  };

  const handleDeleteClick = () => {
    setFiles(files.filter(toDelete));
    setFileProperties(fileProperties.filter(toDelete));
    setSelectedFiles([]);
  };

  const hashMessage = async (comment: any) => {
    var a = await fetch(
      `${RDS_MESSAGE_BASE_URL}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': `${RDS_MESSAGE_KEY}`,
        },
        body: JSON.stringify({ message: comment }),
      }
    );
    var b = await a.json();

    return b.key;
  };

  const toDelete = (a: any, i: number) => !selectedFiles.includes(i);

  const hashAllMessages = async () => {
    return Promise.all(
      fileProperties.map(async (f: any) => {
        if (f.comment) {
          return await hashMessage(
            `File: ${f.labelName}, comment: ${f.comment}`
          );
        }
      })
    );
  };

  // ajax call to backend
  const handleUpload = async (e: any) => {
    setUploadClicked(true);
    var formData = new FormData();

    var bok = await hashAllMessages();

    files.forEach((file: File, index: number) => {
      var { comment } = fileProperties[index];

      formData.append(`files[]`, file);
      formData.append('label_names[]', fileProperties[index].labelName);
      formData.append('public_comments[]', comment ? bok[index] : comment);
      formData.append('versions[]', fileProperties[index].version);
      formData.append('task_ids[]', selectedTaskId);
      formData.append('primary_sources[]', fileProperties[index].primarySource);
      formData.append(
        'secondary_sources[]',
        fileProperties[index].secondarySource
      );
    });

    var encryptedComment = await hashMessage(generalComment);
    generalComment
      ? formData.append('general_comment', encryptedComment)
      : null;

    try {
      var ok = await fetch('/api/upload', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': getCSRFToken(),
        },
        body: formData,
      });

      var json = await ok.json();

      const selectedListIndex = idx(lists, (ls) =>
        ls.findIndex((l: any) => l.value === selectedListId)
      );
      const selectedTaskIndex = idx(tasks, (ts) =>
        ts.findIndex((t: any) => t.value === selectedTaskId)
      );
      if (
        (selectedListId == 1377 &&
          state.currentUser.lastViewedCompanyId == 699) ||
        (selectedListIndex > -1 &&
          selectedTaskIndex > -1 &&
          lists[selectedListIndex].deliverFileOnUpload === 'yes' &&
          lists[selectedListIndex].companyPosition === 'responder' &&
          tasks[selectedTaskIndex].status === status)
      ) {
        // special case see: https://prepdd.atlassian.net/browse/LAUN-1344
        // update -- not just for one company; but for all responding companies
      } else if (
        status &&
        tasks.length &&
        selectedTaskIndex > -1 &&
        tasks[selectedTaskIndex].status !== status
      ) {
        updateStatus();
      }

      if (json.failed_files && json.failed_files.length > 0) {
        dispatch({
          type: 'SET_NOTIFICATION',
          notification: {
            variant: 'warning',
            message: 'There has been an error processing your file. For now, you can manually enter the value for your support file.',
          },
        });
      } else {

        dispatch({
          type: 'SET_NOTIFICATION',
          notification: {
            variant: 'success',
            message: 'File uploaded successfully',
          },
        });
      }

      if (props.isCreateProject) {
        setTimeout(() => props.filesUploaded(status), 1000);
      } else {
        //ADD DISPATCH HERE FOR STATS
        dispatch({
          type: 'UPDATE_PROJECT_SELECTION_OPTONS',
          selectedListIds: ['-1'],
          selectedSectionIds: null
        })
        setTimeout(() => window.location.assign('/app/newtasks'), 1000);
      }
    } catch (e) {
      //Activate the error snackbar

      // setRetries(retries + 1);
      // if (retries < 7) setTimeout(async () => await handleUpload(null), 412);
    }
  };

  const handleLabelChange = (e: any, index: number) => {
    var quickClone = JSON.parse(JSON.stringify(fileProperties));
    quickClone[index].labelName = e.target.value;
    setFileProperties(quickClone);
  };

  const handleCommentChange = (e: any, index: number) => {
    var quickClone = JSON.parse(JSON.stringify(fileProperties));
    quickClone[index].comment = e.target.value;
    setFileProperties(quickClone);
  };

  const handleGeneralCommentChange = (e: any) => {
    setGeneralComment(e.target.value);
  };

  const taskCompanyPosition = idx(tasks, (tasks) =>
    tasks.find((t: any) => t.value == selectedTaskId)
  );

  const handleStatusClick = (e: any, newStatus: any) => {
    setStatus(newStatus);
    setStatusDropdownOpen(false);
  };

  useEffect(() => {
    var newStatus = idx(
      tasks,
      (tasks) => tasks.filter((t: any) => t.value == selectedTaskId)[0].status
    );
    if (newStatus) setStatus(newStatus);
  }, [selectedTaskId]);

  useEffect(() => {
    if (!tasks.length || !selectedTaskId) return;
    var pp = idx(
      tasks,
      (tasks) => tasks.find((t: any) => t.value == selectedTaskId).primaryLabel
    );
    var ss = idx(
      tasks,
      (tasks) =>
        tasks.find((t: any) => t.value == selectedTaskId).secondaryLabel
    );
    if (pp) setPrimary(pp);
    if (ss) setSecondary(ss);
  }, [tasks, selectedTaskId]);

  const [showReconTags, setShowReconTags] = useState<boolean>(false);
  const setReconciliationTag = (tagType: string, index: number) => {
    const newFileProperties = fileProperties.map((fp: any) => ({
      ...fp,
      [`${tagType}Source`]: false,
    }));
    newFileProperties[index].primarySource = false;
    newFileProperties[index].secondarySource = false;
    newFileProperties[index][`${tagType}Source`] = true;
    setFileProperties(newFileProperties);
    setShowReconTags(false);
  };

  const global = useGlobalStyles();

  //////
  // user tagging
  const [showAddBox, setShowAddBox] = useState<boolean>(false); //whether or not to show the tagging popup
  const [selectedItem, setSelectedItem] = useState<number>(0); //current user selection in tagging popup
  const [allUsers, setAllUsers] = useState<any[]>([]); //list of all users (default for display in tagging popup if no filterList)
  const [displayUsers, setDisplayUsers] = useState<any[]>([]);
  const role = idx(state, (state) => state.currentUser.companyRole);

  // refs
  const listElement: any = useRef(null);
  const coolRef: any = useRef(null);
  const internal = false;

  // gql query
  const [getUserTags, { loading, data, error, refetch }] = useLazyUserTagging();

  useEffect(() => {
    if (selectedTaskId) {
      getUserTags({
        variables: {
          id: selectedTaskId,
        }
      });
    }
  }, [selectedTaskId]);

  useEffect(() => {
    let result = idx(data, (data) => (data as any).task.allOwners);

    if (!result) {
      return;
    }

    if (internal) {
      var noExternal = result.filter(
        (o: any) => o.taskRelationship !== 'External'
      );
      role <= 5
        ? (result = noExternal.filter(
          (o: any) =>
            o.roleInCurrentUserCompany <= 5 || o.__typename === 'Team'
        ))
        : (result = noExternal.filter(
          (o: any) => o.roleInCurrentUserCompany > 5
        ));
    }

    // Add team members into all user's array -- TODO: refactor
    result
      .filter((val: any) => val.__typename === 'Team')
      .map((team: any) => {
        team.users.map((user: any, index: number) => {
          user.taskRelationship = team.taskRelationship;
          let userIndex = result.findIndex(
            (obj: any) => obj.email === user.email
          );
          if (userIndex === -1) {
            result.push(user);
          } else {
            result[userIndex] = user;
          }
        });
      });

    setAllUsers(result);
  }, [data, internal]);

  const addTag = () => {
    var taggingSelected = taggableSection(
      coolRef.current.selectionStart || generalComment.length
    );
    var selected = displayUsers[selectedItem];
    var taggedName = selected.name || selected.displayName;

    var front = generalComment.substring(
      0,
      coolRef.current.selectionStart - taggingSelected.length
    );
    var middle = `@${taggedName} `;
    var back = generalComment.substring(
      coolRef.current.selectionStart,
      generalComment.length
    );

    setGeneralComment(front + middle + back);
    coolRef.current.focus();
  };

  function taggableSection(cursorPosition: number) {
    var messageToCursor = coolRef.current.value.substring(
      0,
      cursorPosition - 1
    );
    var lastWhiteSpace = messageToCursor.lastIndexOf(' ');

    return generalComment.substring(lastWhiteSpace + 1, cursorPosition);
  }

  // determine if we should show the user tagging box
  const showTaggingBox = (event: any) => {
    var coolBeans = taggableSection(event.target.selectionStart);

    if (coolBeans === '@' || coolBeans === '\n@') {
      setShowAddBox(true);
      //setDisplayUsers(availableTaggables);
      setDisplayUsers(allUsers);
    }
    if (coolBeans[0] !== '@') {
      setShowAddBox(false);
    } else {
      var theRest = coolBeans.substring(1, coolBeans.length);
      var r = new RegExp('^' + theRest, 'gi');
      var toShow = /*availableTaggables*/ allUsers.filter((u: any) => {
        var nombre = u.name || u.displayName;
        return nombre.match(r);
      });
      if (toShow.length) {
        setDisplayUsers(toShow);
        setShowAddBox(true);
      } else {
        setShowAddBox(false);
      }
    }
  };

  const handleKeyDown = (event: any) => {
    // newlines are only added when shift key is pressed
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
    }

    // pressing up and down keys with active tagging box doesn't move cursor
    if ((event.keyCode === 40 || event.keyCode === 38) && showAddBox) {
      event.preventDefault();
    }
  };

  const handleKeyUp = (event: any) => {
    showTaggingBox(event);

    if (event.keyCode === 13 && event.shiftKey) {
      return;
    }

    // enter key
    if (event.keyCode === 13) {
      event.stopPropagation();
      if (showAddBox && allUsers) {
        addTag();
        setShowAddBox(false);
      }
    }

    // If the tagging box is not active, we don't want to continue
    if (!showAddBox) {
      return;
    }
    // helpers for up && down keys
    // [confluence] -- up and down code
    var list = listElement.current;
    var { scrollTop } = list;
    var scrollBottom = list.scrollTop + 175;

    // down key
    if (event.keyCode === 40) {
      var newSelection = (selectedItem + 1) % displayUsers.length;
      setSelectedItem(newSelection);
      if (0 == newSelection) {
        list.scrollTo(0, 0);
      } else if (newSelection * 35 >= scrollBottom) {
        list.scrollTo(0, list.scrollTop + 35);
      }
    }

    // up key
    if (event.keyCode === 38) {
      var newSelection =
        (selectedItem + displayUsers.length - 1) % displayUsers.length;
      setSelectedItem(newSelection);
      if (displayUsers.length - 1 == newSelection) {
        list.scrollTo(0, 35 * displayUsers.length);
      } else if (newSelection * 35 < scrollTop) {
        list.scrollTo(0, scrollTop - 35);
      }
    }
  };

  // Whenever we go from no add box to add box reset selected item to 0
  useEffect(() => {
    if (!showAddBox) {
      return;
    }
    setSelectedItem(0);
  }, [showAddBox]);

  let coordinates = { top: 0, left: 0 };

  if (coolRef.current) {
    coordinates = getCaretCoordinates(
      coolRef.current,
      coolRef.current.value.lastIndexOf('@')
    );
  }

  return (
    <Paper
      className={clsx(classes.root, value !== index && classes.invisible)}
      aria-labelledby='Single Task'
      elevation={0}
    >
      <div
        className={clsx(classes.filesPane, classes.flex)}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {!files.length ? (
          <p>Drop Your Files</p>
        ) : (
          files.map((file: any, index: number) => (
            <NameLabel
              label={file.name.substring(0, 18) + '...'}
              key={index}
              logo={'File'}
              className={classes.fileLabel}
            />
          ))
        )}
      </div>
      <div style={{ width: '100%', position: 'relative' }}>
        {showAddBox && (
          <MessageTaggingBox
            onClick={addTag}
            peopleList={displayUsers}
            setShowAddBox={setShowAddBox}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            listRef={listElement}
            listStyles={{
              bottom: 'revert',
              marginLeft: coordinates.left,
              top: coordinates.top + 40,
              width: 500,
            }}
          />
        )}

        <textarea
          className={classes.textarea}
          placeholder='Add public comment or description...'
          onChange={handleGeneralCommentChange}
          value={generalComment}
          ref={coolRef}
          onClick={showTaggingBox}
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
          data-cy='general-comment-textarea'
        />
      </div>

      <div className={classes.flex} style={{ marginTop: '24px' }}>
        <ListDropdown
          options={lists}
          handleUpdate={setSelectedListId}
          placeholder='Select a Project'
          value={selectedListId}
          dropType={'list'}
        />
        <ListDropdown
          options={sortTasks(tasks)}
          handleUpdate={setSelectedTaskId}
          placeholder='Select a Task'
          value={selectedTaskId}
          dropType={'task'}
        />
        <div style={{ position: 'relative', marginLeft: 110 }}>
          {taskCompanyPosition && (
            <StatusDropdown
              containerStyles={{ width: '150px' }}
              task={{ ...taskCompanyPosition, status: status }}
              open={statusDropdownOpen}
              changeOpen={() => setStatusDropdownOpen(!statusDropdownOpen)}
              onChange={handleStatusClick}
            />
          )}
        </div>
      </div>

      {selectedFiles.length ? (
        <div className={clsx(classes.flex, classes.selection)}>
          <Typography variant='h6' className={classes.primary}>
            {`${selectedFiles.length} file${selectedFiles.length === 1 ? '' : 's'
              } selected`}
          </Typography>
          <div className={classes.grow} />
          <DeleteIcon onClick={handleDeleteClick} className={classes.delete} />
        </div>
      ) : (
        <div className={clsx(classes.flex, classes.selection)}></div>
      )}

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell padding='checkbox'>
              <Checkbox
                color='primary'
                checked={allToggle}
                onChange={toggleAll}
              />
            </TableCell>
            <TableCell>File</TableCell>
            <TableCell>Public Comment</TableCell>
            <TableCell>Version</TableCell>
            <TableCell>Reconciliation Tag</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file: any, index: number) => (
            <TableRow key={index}>
              <TableCell padding='checkbox'>
                <Checkbox
                  color='primary'
                  checked={selectedFiles.includes(index)}
                  onChange={() => toggleSelectedFile(index)}
                />
              </TableCell>
              <TableCell>
                <div className={classes.flex}>
                  <FileIcon fileName={file.name} className={classes.mr12} />
                  <input
                    type='text'
                    name='test'
                    data-cy={'upload-row-name-' + index}
                    className={classes.input}
                    value={fileProperties[index].labelName}
                    onChange={(e) => handleLabelChange(e, index)}
                    autoFocus
                  />
                </div>
              </TableCell>
              <TableCell>
                <input
                  type='text'
                  name='test'
                  placeholder='Add...'
                  data-cy={'upload-row-comment-' + index}
                  className={classes.input}
                  value={fileProperties[index].comment}
                  onChange={(e) => handleCommentChange(e, index)}
                  autoFocus
                />
              </TableCell>
              <TableCell>
                <VersionDropdown
                  files={fileProperties[index].files}
                  index={index}
                  setFileProperties={setFileProperties}
                  fileProperties={fileProperties}
                />
              </TableCell>
              <TableCell>
                <div
                  onMouseEnter={() => setShowReconTags(true)}
                  onMouseLeave={() => setShowReconTags(false)}
                  style={{
                    position: 'relative',
                    display: 'flex',
                    cursor: 'pointer',
                    width: 'fit-content',
                  }}
                >
                  {fileProperties[index].primarySource && (
                    <Typography
                      style={{ fontWeight: 800, color: cs.COLORS.primary }}
                    >
                      {primary}
                    </Typography>
                  )}
                  {fileProperties[index].secondarySource && (
                    <Typography
                      style={{ fontWeight: 800, color: cs.COLORS.primary }}
                    >
                      {secondary}
                    </Typography>
                  )}
                  {!fileProperties[index].secondarySource &&
                    !fileProperties[index].primarySource && (
                      <Typography
                        style={{ fontWeight: 800, color: cs.COLORS.primary }}
                      >
                        Apply
                      </Typography>
                    )}
                  {showReconTags && (
                    <Paper
                      style={{
                        //width: 'fit-content',
                        maxWidth: 200,
                        position: 'absolute',
                        border: '1px solid #D8D8D8',
                        top: '17px',
                        left: '-6px',
                        zIndex: 2,
                      }}
                      elevation={0}
                      square
                    >
                      <List>
                        <ListItem
                          className={global.ellipsis}
                          onClick={() => setReconciliationTag('primary', index)}
                        >
                          {primary}
                        </ListItem>
                        <ListItem
                          className={global.ellipsis}
                          onClick={() =>
                            setReconciliationTag('secondary', index)
                          }
                        >
                          {secondary}
                        </ListItem>
                      </List>
                    </Paper>
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.footer}>
        <div
          className={classes.flex}
          style={{ paddingTop: '12px', paddingRight: '10px' }}
        >
          <div className={clsx(classes.grow)} />
          <Button
            variant='contained'
            onClick={handleUpload}
            disabled={uploadClicked}
            data-cy='up-button'
          >
            Upload
          </Button>
        </div>
      </div>
    </Paper>
  );
}

export default withRouter(SingleTask);
