import {createMutationHook, gql} from '../graphqlHelpers';

export const useDeleteEntityLabelType = createMutationHook(gql`
  mutation DeleteEntityLabelType (
    $id: ID!
  ) {
    deleteEntityLabelType(id: $id){
      success
      id
    }
  }
`);

