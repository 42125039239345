import {List, ListItem, makeStyles, createStyles} from '@material-ui/core';
import {get} from 'lodash';
import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import TimelineNotification from './TimeLineNotification';
import TimeLineFile from './TimeLineFile';
import {
  decryptMessage,
  hashMessage,
} from '../../../../../../../helpers/helpers';
import Message from '../../../Message';
import idx from 'idx';
import {useDispatchContext, useStateContext} from '../../../../../../../store';
import {useLazyUserTagging} from '../../../../../../../graphql/queries/UserTagging';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    message: {
      backgroundColor: 'rgba(39, 146, 162, 0.1)',
    },
  })
);

const OverviewNotifications = (props: any) => { 
  const {value, index, tasks, taskId} = props;

  const [notifications, setNotifications] = useState([]);

  // when taskId changes, load up the notification for that task
  useEffect(() => {
    let isMounted = true;
    if (taskId) {
      var task = tasks.find((task: any) => task.id == taskId);
      if (task && task.notifications && task.notifications.length) {
        var notifications = JSON.parse(JSON.stringify(task.notifications));
        if (isMounted) setNotifications(notifications);
      }
      return () => { isMounted = false };
      // @ts-ignore
      // else {
      //   setNotifications([]);
      // }
    }
  }, [taskId, tasks]);

  const decryptNotifications = (notifications: any) => {
    Promise.all(
      notifications.map(async (n: any) => {
        return n.pane === 2 || n.pane === 3
          ? {...n, description: await decryptMessage(n.description)}
          : n;
      })
    )
      // @ts-ignore
      .then(a => setNotifications(a));
  };
  const dispatch = useDispatchContext()
  const state = useStateContext();;

  const role = idx(state, state => state.currentUser.companyRole);
  const userId = idx(state, state => state.currentUser.id);
  const userName = idx(state, state => state.currentUser.displayName);
  const [allUsers, setAllUsers] = useState<any[]>([]); //list of all users (default for display in tagging popup if no filterList)
  // deleting messages
  const [deleteMessageId, setDeleteMessageId] = useState<any>();
  const [messageToDelete, setMessageToDelete] = useState<any>('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const classes = useStyles();
  const [getUserTags, {loading, data, error, refetch}] = useLazyUserTagging();

  useEffect(() => {
    if (taskId) {
      getUserTags({
        variables: {
          id: taskId
        }
      })
    }
  }, [taskId]);

  // fetches the taskOwners query
  useEffect(() => {
    let result = idx(data, data => (data as any).task.allOwners);
    if (!result) {
      return;
    }

    // Add team members into all user's array -- TODO: refactor
    result
      .filter((val: any) => val.__typename === 'Team')
      .map((team: any) => {
        team.users.map((user: any, index: number) => {
          user.taskRelationship = team.taskRelationship;
          let userIndex = result.findIndex(
            (obj: any) => obj.email === user.email
          );
          if (userIndex === -1) {
            result.push(user);
          } else {
            result[userIndex] = user;
          }
        });
      });

    setAllUsers(result);
  }, [data]);

  useEffect(() => {
    if (!refetch || !taskId) {
      return;
    }
    setTimeout(() => refetch(), 430);
  }, [tasks]);

  return (
    <List
      component="div"
      aria-labelledby="task timeline"
      data-cy="overview-notifications"
    >
      {notifications.map((notification: any) => {
        if (notification.actionType === 'Files Uploaded') {
          // console.log(notification);
          // Requirements
          // 1. The Url to download the file
          // 2. the profileUrl for the user - this is also for just regular notification component
          // 3. isCheckedOut, so fileId is required for this.

          return (
            <TimeLineFile key={notification.id} notification={notification} />
          );
        }

        if (
          (notification.actionType === 'New Internal Messages' ||
            notification.actionType === 'New Public Messages') &&
          notification.description
        ) {
          const hashedMessage = notification.description;
          const isPublic = notification.actionType === 'New Public Messages';

          const message = {};
          // Missing from the New Interval/New Pubic Message notification
          // 1. The actual message object
          // data: {
          //   message: string;
          //   status: boolean;
          //   viewed?: boolean; // the conditional we send along to trigger the status
          //   isPublic?: boolean;
          //   user: any;
          //   createdAt: string;
          //   id: any;
          // };
          // 2.

          //console.log('allusers', allUsers);
          return (
            <Message
              key={index}
              data={{
                message: hashedMessage,
                status: true,
                viewed: true,
                isPublic,
                user: notification.user,
                createdAt: notification.createdAt,
                id: 1,
              }}
              className={clsx(!isPublic && classes.message)}
              internal={!isPublic}
              users={allUsers}
              role={role}
              userId={userId}
              userName={userName}
              noEdits
            />
          );
        }
        return (
          <TimelineNotification
            key={notification.id}
            notification={notification}
          />
        );
      })}{' '}
    </List>
  );
};

export default OverviewNotifications;
