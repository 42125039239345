import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, createStyles, DialogContentText, IconButton, ListItem, makeStyles, TableBody, TableCell, TableRow, Theme, Tooltip, Typography } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import Clear from '@material-ui/icons/Clear';

import PriorityForm from '../../../PriorityForm';
import TagLabel from '../../../../../../../common/TagLabel';
import OwnerForm from '../../../../../../../common/InviteForm';
import { DatePicker } from '../../../../../../../common/DatePicker';
import { ISteps, ITableHeader, ITask } from '../../../interfaces';
import { useGlobalStyles } from '../../../../../../../common/Style';
import { useUserTags } from '../../../../../../../../graphql/queries/UserTags';
import TagDropdown from '../../../TagDropdown';
import RenderOwnersRow from '../../../RenderOwnersRow';
import idx from 'idx';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useMutation } from '@apollo/client'
import { useCreateTask } from '../../../../../../../../graphql/mutations/CreateTask';
import { useUpdateTask } from '../../../../../../../../graphql/mutations/UpdateTaskHook';
import AlertModal from '../../../AlertModal';
import { useSetFilters } from '../../../../../../../../hooks/SetFilters';
import { useHistory } from 'react-router';
import { SearchCompanyUsers_searchCompanyUsers_teams, SearchCompanyUsers_searchCompanyUsers_users } from '../../../../../../../../helpers/__generated__/SearchCompanyUsers';
import EditableInput from '../../../EditableInput';
import { dependenciesPresentFunc, dependencyDataGeneratorFunc, handleDeleteTaskDependencyFunc } from '../../../../../../../task/NewTaskPage/components/SidePanel/components/TaskDependency/DependencyFunctions';
import TaskDependency, { DELETE_TASK_DEPENDENCY } from '../../../../../../../task/NewTaskPage/components/SidePanel/components/TaskDependency/TaskDependency';
import { useAddTaskOwners } from '../../../../../../../../graphql/mutations/AddTaskOwners';
import RemoveTaskOwner from '../../../../../../../../graphql/mutations/RemoveTaskOwner';
import { useAddTaskTags } from '../../../../../../../../graphql/mutations/AddTaskTags';
import { useRemoveTaskTags } from '../../../../../../../../graphql/mutations/RemoveTaskTags';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		flex: {
			display: 'flex',
		},
		tableRow: {
			height: 'auto',
		},
		tableCell: {
			padding: 10,
			verticalAlign: 'top',
		},
		checkbox: {
			color: '#606060',
			// color: "#B0B0B0",
			padding: 0,
		},
		t4: {
			fontSize: "12px",
			lineHeight: "15px",
			color: "#606060",
			// color: "#B0B0B0",
			opacity: 1,
			fontWeight: "normal",
		},
		innerTextFlow: {
			'& .MuiInput-input, & input': {
				fontSize: "12px",
				lineHeight: "15px",
				color: "#606060",
				// color: "#B0B0B0",
				opacity: 1,
				fontWeight: "normal",
				padding: '3px',
				border: '1px solid transparent',
			},
			'& input': {
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			}
		},
		inputBorder: {
			'& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
				border: 'none',
			},
			'& .MuiInputBase-root': {
				alignItems: 'flex-start',
			},
			'& .MuiInputBase-multiline': {
				padding: '0px',
				height: 'calc(100% - 14px)',
			},
			'& .MuiInput-input, & input': {
				'&:hover': {
					border: '1px solid #AFAFAF',
					borderRadius: '5px',
				},
				'&:focus-within': {
					border: '1px solid #AFAFAF',
					borderRadius: '5px',
				},
			}
		},
		createTaskRow: {
			'&:hover': {
				color: 'white',
				backgroundColor: 'white'
			}
		},
		panelClass: {
			top: 0
		},
		truncateIcon: {
			width: '32px',
			height: '32px',
		},
		tags: {
			justifyContent: 'center',
			marginBottom: '2px'
		},
		datePicker: {
			display: 'none',
		},
		taskDisplayName: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		dependencyText: {
			fontSize: '12px',
			lineHeight: '15px',
			color: '#606060',
			opacity: 1,
			fontWeight: 'normal',
		},
		hideSuffixStyling: {
			display: 'none',
		},
		listItem: {
			padding: '0px 0px 4px',
			flexDirection: 'row',
			whiteSpace: 'nowrap',
			width: 'auto',
			maxWidth: 'calc(100% - 38px)',
			marginRight: '6px',
			'&:hover': {
				borderWidth: 'thin',
				backgroundColor: 'transparent',
				'& svg': {
					visibility: 'visible'
				}
			},
			'& svg': {
				visibility: 'hidden'
			},
		},
		dependencyDeleteButton: {
			padding: 0,
			border: 'none',
			marginLeft: 10,
			'&:hover': {
				border: 'none',
			}
		},
		selectedTask: {
			backgroundColor: 'rgb(235, 242, 255)',
		},
		dialogButton: {
			width: '200px',
			fontWeight: 'bold',
			borderRadius: '2px',
			paddingTop: '12px',
			paddingBottom: '12px',
		},
		showMoreButton: {
			color: '#606060',
			border: '1px solid #D8D8D8',
			backgroundColor: '#FFFFFF',
			borderRadius: '3px',
			fontSize: '12px',
			padding: '0px',
			marginBottom: '0px',
			marginRight: '6px',
			zIndex: 1,
			height: '26px',
			minWidth: '26px',
			alignSelf: 'flex-end',
			'&:hover': {
				border: '1px solid #3A84FF;',
				backgroundColor: '#FFFFFF',
			},
		},
		dependencyContainer: {
			'& .MuiButton-root': {
				height: '28px !important'
			}
		}
	})
);

interface ITaskTableBody {
	isAdmin: boolean;
	externalCompanyId: any;
	onAddButtonHover?: boolean;
	displayedHeaders: ITableHeader[];
	selectedTemplate: any;
	setSelectedTemplate: React.Dispatch<React.SetStateAction<any>>;
	selectedTasksIds: string[];
	setSelectedTasksIds: React.Dispatch<React.SetStateAction<string[]>>;
	selectedTask?: any;
	updateSelectedTask?(task: any): void;
	step: ISteps;
	skinnyLists?: any[];
	setSkinnyLists?: React.Dispatch<React.SetStateAction<any[]>>;
	dependencies?: any;
	setAddTasks?: React.Dispatch<React.SetStateAction<any>>;
	sortByName: string | null;
	sortBySection: string | null;
	nameFilter: string;
	sectionFilter: string;
	additionalInternalOwners: any[];
	additionalExternalOwners: any[];
	setAdditionalInternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
	setAdditionalExternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
	isOnlyImportTasks?: boolean;
}

export default function TaskTableBody(props: ITaskTableBody) {

	const { isAdmin, externalCompanyId, onAddButtonHover, displayedHeaders, selectedTemplate, setSelectedTemplate, selectedTasksIds, setSelectedTasksIds,
		selectedTask, updateSelectedTask, step, skinnyLists, setSkinnyLists, dependencies, setAddTasks, sortByName, sortBySection, nameFilter, sectionFilter,
		additionalInternalOwners, additionalExternalOwners, setAdditionalInternalOwners, setAdditionalExternalOwners, isOnlyImportTasks } = props;

	const availableHeaderOptionValues = displayedHeaders.map(header => header.value);
	const [addedTags, setAddedTags] = useState<any[]>([]);
	const { data: tagData } = useUserTags({});
	const tagsFromBE = idx(tagData, (d: any) => d.tags.map((x: any) => ({ label: x.tag, value: x.id }))) || [];

	const classes = useStyles();
	const globalStyles = useGlobalStyles();

	// const inputRef = useRef(null);
	const newTaskRef = useRef(null);

	const newAddedTask = {
		id: '',
		name: '',
		section: null,
		priority: "medium",
		status: "Unstarted",
		tags: [],
		description: null,
		dueDate: null,
		isVolatile: true,
		list: { id: selectedTemplate.id as String, name: selectedTemplate.name as String, __typename: "List" },
		dependencies: [],
		__typename: "Task"
	};

	const [taskToCreate, setTaskToCreate] = useState<any>(); // pass to create task hook
	const [taskToUpdate, setTaskToUpdate] = useState<any>(); // pass to update Task Hook
	const [openAddPanelUserOwner, setOpenAddPanelUserOwner] = useState(false);
	const [openAddPanelUserReviewer, setOpenAddPanelUserReviewer] = useState(false);
	const [openAddPanelExtUserOwner, setOpenAddPanelExtUserOwner] = useState(false);
	const [openAddPanelExtUserReviewer, setOpenAddPanelExtUserReviewer] = useState(false);

	function buildTaskToUpdatePayload(data: any) {
		return {...data, startDate: data?.startDate || null, dueDate: data?.dueDate || null}
	}


	/* Mutation Hooks */
	// Create Task
	const useCreateTaskVariables = {
		listId: selectedTemplate.id,
		tasks: [taskToCreate] // mutation takes array of tasks, even for a single task
	}
	const [createTask, createTaskResponse] = useCreateTask(useCreateTaskVariables);

	// Update Task
	const [updateTask, updateTaskResponse] = useUpdateTask(buildTaskToUpdatePayload(taskToUpdate));

	// Assign task owners
	const [addTaskOwners] = useAddTaskOwners({});

	// Remove task owner
	const [removeTaskOwner] = RemoveTaskOwner({});

	// Assign entity
	const [addTags] = useAddTaskTags({});

	// Remove entity
	const [removeTags] = useRemoveTaskTags({});

	useEffect(() => {
		if (taskToUpdate) updateTask();
	}, [taskToUpdate]);

	useEffect(() => {
		if (taskToCreate) createTask();
	}, [taskToCreate]);

	useEffect(() => {
		if (Boolean(idx(createTaskResponse, response => response.data.createTask.success))) {
			const newTasks: ITask[] | null = selectedTemplate.tasks;
			const addedTask = idx(createTaskResponse, response => response.data.createTask.tasks[0]);
			if (newTasks && addedTask) {
				const newTask = { ...newAddedTask, ...addedTask, section: { __typename: 'TaskSection', id: '', name: '' } };
				setAddTasks && setAddTasks([newTask]);
				setSelectedTemplate({ ...selectedTemplate, tasks: [...newTasks, newTask] });
				if (newTaskRef && newTaskRef.current) {
					(newTaskRef as any).current.scrollIntoView({ behavior: "smooth" });
					setSelectedTasksIds([...selectedTasksIds, newTask.id]);
				}
			}
		}
	}, [createTaskResponse.loading, createTaskResponse.called, createTaskResponse.data]);

	const checkBoxClick = (_: React.MouseEvent<unknown>, id: string) => {
		const selectedIndex = selectedTasksIds.indexOf(id);
		let newSelected: string[] = [];
		if (selectedIndex === -1) {
			newSelected = [...selectedTasksIds, id];
		} else {
			newSelected = selectedTasksIds.filter(taskId => taskId !== id);
		}
		setSelectedTasksIds(newSelected);
	};

	function onEnterClicked(event: any, index: number) {
		if (selectedTemplate.tasks[index + 1]) {
			const elem = document.getElementById(`${index + 1}${event.target.name}`);
			elem?.focus();
		}
		if (index === selectedTemplate.tasks.length - 1) {
			const elem = document.getElementById(`${index}${event.target.name}`);
			elem?.blur();
		}
	}

	function handleBlur(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | React.ChangeEvent<HTMLInputElement>, id: string) {
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		if (newTasks) {
			const index = newTasks.findIndex(t => t.id === id);
			if (index > -1) {
				const { name, value } = event.target;
				newTasks[index] = Object.assign({}, newTasks[index], { [name]: value });
				setSelectedTemplate({ ...selectedTemplate, tasks: newTasks });
				setTaskToUpdate({ ...newTasks[index], section: Boolean(idx(newTasks[index], task => task.section.name)) ? newTasks[index].section.name : ' ' });
			}
		}
	};

	function handleSectionBlur(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) {
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		if (newTasks) {
			const index = newTasks.findIndex(t => t.id === id);
			if (index > -1) {
				const newSection = newTasks[index].section
				const { value } = event.target;
				if (newSection && newSection.name !== null) {
					newSection.name = value;
					newTasks[index].section = newSection;
				} else {
					newTasks[index].section = {
						name: value,
						id: ' ',
						__typename: 'TaskSection'
					};
				}
				setSelectedTemplate({ ...selectedTemplate, tasks: newTasks });
				setTaskToUpdate({ ...newTasks[index], section: value });
			}
		}
	};

	function handleChangePriority(newValue: string, id: string) {
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		if (newTasks) {
			const index = newTasks.findIndex(t => t.id === id);
			if (index > -1) {
				newTasks[index].priority = newValue;
				setSelectedTemplate({ ...selectedTemplate, tasks: newTasks });
				setTaskToUpdate({ ...newTasks[index], section: Boolean(idx(newTasks[index], task => task.section.name)) ? newTasks[index].section.name : ' ' });
			}
		}
	};

	function handleChangeEntity(tag: any, task: any, id: string) {
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		const index = newTasks.findIndex(t => t.id === id);
		if (index > -1) {
			if (newTasks[index].tags && newTasks[index].tags.length) {
				if (newTasks[index].tags.includes(tag.label)) {
					return;
				}
			}

			if (!addedTags.map((t: any) => t.label).includes(tag.label) && !tagsFromBE.map((t: any) => t.label).includes(tag.label)) {
				setAddedTags([...addedTags, { label: tag.label }])
			}

			if (newTasks) {
				const newTask = newTasks[index]
				if (newTask.tags) {
					newTask.tags.push(tag.label);
				} else {
					newTask.tags = [];
					newTask.tags.push(tag.label);
				}
				newTasks[index] = newTask;
				setSelectedTemplate({ ...selectedTemplate, tasks: newTasks });
				addTags({variables: { taskIds: [newTasks[index].id], tag: tag.label }})
			}
		}
	}

	function handleRemoveTag(item: any, tag: string, id: string) {
		if (!item.tags) { return }
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		const index = newTasks.findIndex(t => t.id === id);
		if (index > -1) {
			for (let i = 0; i < item.tags.length; i++) {
				if (item.tags[i] === tag) {
					item.tags.splice(i, 1);
				}
			}
			newTasks[index] = item;
			setSelectedTemplate({ ...selectedTemplate, tasks: newTasks });
			removeTags({variables: { taskIds: [newTasks[index].id], tag }})
		}
	}

	function handleChangeDueDate(newDate: any, id: string) {
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		if (isNaN(newDate) || newDate === null) {
			return;
		}
		if (newTasks) {
			const index = newTasks.findIndex(t => t.id === id);
			if (index > -1) {
				newTasks[index].dueDate = newDate;
				setSelectedTemplate({ ...selectedTemplate, tasks: newTasks });
				setTaskToUpdate({ ...newTasks[index], section: Boolean(idx(newTasks[index], task => task.section.name)) ? newTasks[index].section.name : ' ' });
			}
		}
	}

	function handleChangeStartDate(newDate: any, id: string) {
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		if (isNaN(newDate) || newDate === null) {
			return;
		}
		if (newTasks) {
			const index = newTasks.findIndex(t => t.id === id);
			if (index > -1) {
				newTasks[index].startDate = newDate;
				setSelectedTemplate({ ...selectedTemplate, tasks: newTasks });
				setTaskToUpdate({ ...newTasks[index], section: Boolean(idx(newTasks[index], task => task.section.name)) ? newTasks[index].section.name : ' ' });
			}
		}
	}

	function handleBlurRelDate(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) {
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		const { name, value } = event.target;
		if (newTasks && (parseInt(value) || value === '')) {
			const index = newTasks.findIndex(t => t.id === id);
			if (index > -1) {
				newTasks[index] = Object.assign({}, newTasks[index], { [name]: parseInt(value) });
				setTaskToUpdate({ ...newTasks[index], section: Boolean(idx(newTasks[index], task => task.section.name)) ? newTasks[index].section.name : ' ' });
				setSelectedTemplate({ ...selectedTemplate, tasks: newTasks });
			}
		}
	}

	const getInternalOwners = (item: any) => {
		let owners: any = [];
		if (Boolean(idx(item, t => t.userOwners))) {
			owners = item.userOwners;
		}
		if (Boolean(idx(item, t => t.teamOwners))) {
			owners = [...owners, ...item.teamOwners];
		}
		return owners;
	}

	const getInternalReviewers = (item: any) => {
		let reviewers: any = [];
		if (Boolean(idx(item, t => t.userReviewers))) {
			reviewers = item.userReviewers;
		}
		if (Boolean(idx(item, t => t.teamReviewers))) {
			reviewers = [...reviewers, ...item.teamReviewers];
		}
		return reviewers;
	}

	const getExternalOwners = (item: any) => {
		let owners: any = [];
		if (Boolean(idx(item, t => t.externalUserOwners))) {
			owners = item.externalUserOwners;
		}
		if (Boolean(idx(item, t => t.externalTeamOwners))) {
			owners = [...owners, ...item.externalTeamOwners];
		}
		return owners;
	}

	const getExternalReviewers = (item: any) => {
		let reviewers: any = [];
		if (Boolean(idx(item, t => t.externalUserReviewers))) {
			reviewers = item.externalUserReviewers;
		}
		if (Boolean(idx(item, t => t.externalTeamReviewers))) {
			reviewers = [...reviewers, ...item.externalTeamReviewers];
		}
		return reviewers;
	}

	function setInternalOwners(owners: any, id: string) {
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		if (newTasks) {
			const index = newTasks.findIndex(t => t.id === id);
			if (index > -1) {
				let userOwners: SearchCompanyUsers_searchCompanyUsers_users[] = [];
				let teamOwners: SearchCompanyUsers_searchCompanyUsers_teams[] = [];
				owners.forEach((owner: SearchCompanyUsers_searchCompanyUsers_users | SearchCompanyUsers_searchCompanyUsers_teams) => {
					if (owner.__typename === 'User') {
						userOwners.push(owner);
					} else {
						teamOwners.push(owner);
					}
				})
				newTasks[index].userOwners = userOwners;
				newTasks[index].teamOwners = teamOwners;
				setSelectedTemplate({ ...selectedTemplate, tasks: newTasks });
				if (isOnlyImportTasks) {
					addTaskOwners({variables: { taskId: newTasks[index].id, owners: userOwners}});
				}
			}
		}
	}

	function handleRemoveInternalOwner(removedOwner: (SearchCompanyUsers_searchCompanyUsers_users | SearchCompanyUsers_searchCompanyUsers_teams), id: string) {
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		if (newTasks) {
			const index = newTasks.findIndex(t => t.id === id);
			if (index > -1) {
				let userOwners = newTasks[index].userOwners || [];
				let teamOwners = newTasks[index].teamOwners || [];
				if (removedOwner.__typename === 'User') {
					userOwners = userOwners.filter((owner: SearchCompanyUsers_searchCompanyUsers_users) => owner.email !== removedOwner.email)
				} else {
					teamOwners = teamOwners.filter((owner: SearchCompanyUsers_searchCompanyUsers_teams) => owner.id !== removedOwner.id);
				}
				setInternalOwners([...userOwners, ...teamOwners], id);
				if (isOnlyImportTasks) {
					removeTaskOwner({variables: { taskId: newTasks[index].id, owner: removedOwner}});
				}
			}
		}
	}

	function setInternalReviewers(reviewers: any, id: string) {
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		if (newTasks) {
			const index = newTasks.findIndex(t => t.id === id);
			if (index > -1) {
				let userReviewers: SearchCompanyUsers_searchCompanyUsers_users[] = [];
				let teamReviewers: SearchCompanyUsers_searchCompanyUsers_teams[] = [];
				reviewers.forEach((reviewer: SearchCompanyUsers_searchCompanyUsers_users | SearchCompanyUsers_searchCompanyUsers_teams) => {
					if (reviewer.__typename === 'User') {
						userReviewers.push(reviewer);
					} else {
						teamReviewers.push(reviewer);
					}
				})
				newTasks[index].userReviewers = userReviewers;
				newTasks[index].teamReviewers = teamReviewers;
				setSelectedTemplate({ ...selectedTemplate, tasks: newTasks });
				if (isOnlyImportTasks) {
					addTaskOwners({variables: { taskId: newTasks[index].id, owners: userReviewers.map(it => ({...it, reviewer: true, taskReviewerOrder: 'first'})) }});
				}
			}
		}
	}

	function handleRemoveInternalReviewer(removedReviewer: (SearchCompanyUsers_searchCompanyUsers_users | SearchCompanyUsers_searchCompanyUsers_teams), id: string) {
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		if (newTasks) {
			const index = newTasks.findIndex(t => t.id === id);
			if (index > -1) {
				let userReviewers = newTasks[index].userReviewers || [];
				let teamReviewers = newTasks[index].teamReviewers || [];
				if (removedReviewer.__typename === 'User') {
					userReviewers = userReviewers.filter((reviewer: SearchCompanyUsers_searchCompanyUsers_users) => reviewer.email !== removedReviewer.email)
				} else {
					teamReviewers = teamReviewers.filter((reviewer: SearchCompanyUsers_searchCompanyUsers_teams) => reviewer.id !== removedReviewer.id);
				}
				setInternalReviewers([...userReviewers, ...teamReviewers], id);
				if (isOnlyImportTasks) {
					removeTaskOwner({variables: { taskId: newTasks[index].id, owner: removedReviewer}});
				}
			}
		}
	}

	function setExternalOwners(owners: any, id: string) {
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		if (newTasks) {
			const index = newTasks.findIndex(t => t.id === id);
			if (index > -1) {
				let externalUserOwners: SearchCompanyUsers_searchCompanyUsers_users[] = [];
				let externalTeamOwners: SearchCompanyUsers_searchCompanyUsers_teams[] = [];
				owners.forEach((owner: SearchCompanyUsers_searchCompanyUsers_users | SearchCompanyUsers_searchCompanyUsers_teams) => {
					if (owner.__typename === 'User') {
						externalUserOwners.push(owner);
					} else {
						externalTeamOwners.push(owner);
					}
				})
				newTasks[index].externalUserOwners = externalUserOwners;
				newTasks[index].externalTeamOwners = externalTeamOwners;
				setSelectedTemplate({ ...selectedTemplate, tasks: newTasks });
				if (isOnlyImportTasks) {
					addTaskOwners({variables: { taskId: newTasks[index].id, owners: externalUserOwners }});
				}
			}
		}
	}

	function handleRemoveExternalOwner(removedOwner: (SearchCompanyUsers_searchCompanyUsers_users | SearchCompanyUsers_searchCompanyUsers_teams), id: string) {
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		if (newTasks) {
			const index = newTasks.findIndex(t => t.id === id);
			if (index > -1) {
				let externalUserOwners = newTasks[index].externalUserOwners || [];
				let externalTeamOwners = newTasks[index].externalTeamOwners || [];
				if (removedOwner.__typename === 'User') {
					externalUserOwners = externalUserOwners.filter((owner: SearchCompanyUsers_searchCompanyUsers_users) => owner.email !== removedOwner.email)
				} else {
					externalTeamOwners = externalTeamOwners.filter((owner: SearchCompanyUsers_searchCompanyUsers_teams) => owner.id !== removedOwner.id);
				}
				setExternalOwners([...externalUserOwners, ...externalTeamOwners], id);
				if (isOnlyImportTasks) {
					removeTaskOwner({variables: { taskId: newTasks[index].id, owner: removedOwner}});
				}
			}
		}
	}

	function setExternalReviewers(reviewers: any, id: string) {
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		if (newTasks) {
			const index = newTasks.findIndex(t => t.id === id);
			if (index > -1) {
				let externalUserReviewers: SearchCompanyUsers_searchCompanyUsers_users[] = [];
				let externalTeamReviewers: SearchCompanyUsers_searchCompanyUsers_teams[] = [];
				reviewers.forEach((reviewer: SearchCompanyUsers_searchCompanyUsers_users | SearchCompanyUsers_searchCompanyUsers_teams) => {
					if (reviewer.__typename === 'User') {
						externalUserReviewers.push(reviewer);
					} else {
						externalTeamReviewers.push(reviewer);
					}
				})
				newTasks[index].externalUserReviewers = externalUserReviewers;
				newTasks[index].externalTeamReviewers = externalTeamReviewers;
				setSelectedTemplate({ ...selectedTemplate, tasks: newTasks });
				if (isOnlyImportTasks) {
					addTaskOwners({variables: { taskId: newTasks[index].id, owners: externalUserReviewers.map(it => ({...it, reviewer: true, taskReviewerOrder: 'first'})) }});
				}
			}
		}
	}

	function handleRemoveExternalReviewer(removedReviewer: (SearchCompanyUsers_searchCompanyUsers_users | SearchCompanyUsers_searchCompanyUsers_teams), id: string) {
		const newTasks: ITask[] = JSON.parse(JSON.stringify(selectedTemplate.tasks));
		if (newTasks) {
			const index = newTasks.findIndex(t => t.id === id);
			if (index > -1) {
				let externalUserReviewers = newTasks[index].externalUserReviewers || [];
				let externalTeamReviewers = newTasks[index].externalTeamReviewers || [];
				if (removedReviewer.__typename === 'User') {
					externalUserReviewers = externalUserReviewers.filter((reviewer: SearchCompanyUsers_searchCompanyUsers_users) => reviewer.email !== removedReviewer.email)
				} else {
					externalTeamReviewers = externalTeamReviewers.filter((reviewer: SearchCompanyUsers_searchCompanyUsers_teams) => reviewer.id !== removedReviewer.id);
				}
				setExternalReviewers([...externalUserReviewers, ...externalTeamReviewers], id);
				if (isOnlyImportTasks) {
					removeTaskOwner({variables: { taskId: newTasks[index].id, owner: removedReviewer}});
				}
			}
		}
	}

	function createNewTask(name: string) {
		const newTask = { ...newAddedTask, name: name };
		setTaskToCreate(newTask);
	};

	function sortingOptions(t1: any, t2: any) {
		const nameA = t1.name.toUpperCase(); // ignore upper and lowercase
		const nameB = t2.name.toUpperCase(); // ignore upper and lowercase
		const sectionA = t1.section.name.toUpperCase(); // ignore upper and lowercase
		const sectionB = t2.section.name.toUpperCase(); // ignore upper and lowercase
		// by Name
		if (sortByName === 'ASC') {
			if (nameA < nameB) return -1;
			if (nameA > nameB) return 1;

			// names must be equal
			return 0;
		}
		if (sortByName === 'DESC') {
			if (nameA < nameB) return 1;
			if (nameA > nameB) return -1;

			// names must be equal
			return 0;
		}

		// by Section
		if (sortBySection === 'ASC') {
			if (sectionA < sectionB) return -1;
			if (sectionA > sectionB) return 1;

			// section names must be equal
			return 0;
		}
		if (sortBySection === 'DESC') {
			if (sectionA < sectionB) return 1;
			if (sectionA > sectionB) return -1;

			// section names must be equal
			return 0;
		}
	}

	function filterByName(task: any) {
		if (!nameFilter) {
			return true;
		}
		return task.name.toLowerCase().includes(nameFilter.toLowerCase());
	}

	function filterBySection(task: any) {
		if (!sectionFilter) {
			return true;
		}
		return task.section.name.toLowerCase().includes(sectionFilter.toLowerCase());
	}

	function filterByStep(task: any) {
		if (step.activeStep === 1) {
			return true;
		} else {
			return selectedTasksIds.includes(task.id);
		}	
	}

	const filteredTasks = selectedTemplate.tasks.filter(filterByStep).filter(filterByName).filter(filterBySection).sort(sortingOptions);

	return (
		<TableBody data-cy='task-table-body'>
			{selectedTemplate.tasks &&
				filteredTasks
					.map((item: ITask, index: number) => {
						const isSelected = selectedTasksIds.indexOf(item.id) !== -1;
						const isBlank = selectedTemplate.tasks && selectedTemplate.tasks.length === 1 && !selectedTemplate.tasks[0].name;
						const allTagOptions = [...tagsFromBE, ...addedTags];
						return (
							<TableRow className={clsx(classes.tableRow, selectedTask?.id === item.id ? classes.selectedTask : undefined)}
								key={index} onClick={() => updateSelectedTask && updateSelectedTask(item)}>
								{ step.activeStep === 1 &&
									<TableCell className={classes.tableCell} style={{ paddingLeft: 0 }}>
										<Checkbox className={classes.checkbox} checked={isSelected} color="primary"
											onClick={(e: React.MouseEvent<unknown>) => checkBoxClick(e, item.id)} />
									</TableCell>
								}
								{ availableHeaderOptionValues.map(header => {
									return (
										<React.Fragment key={index + header}>
											{ header === 'name' &&
												<TableCell data-cy="add-task" className={clsx(classes.tableCell, globalStyles.showIconOnHover)} >
													<EditableInput inputId={`${index}name`} dataCy='name-input' type='text' name="name" className={clsx(classes.innerTextFlow, step.activeStep === 1 && classes.inputBorder)}
														initialValue={item.name ? item.name : ''} placeholder="Add task..." autoFocus={isBlank} InputProps={{ disableUnderline: true }}
														onBlur={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleBlur(event, item.id)}
														onEnterClicked={(event: any) => onEnterClicked(event, index)} hideIcon multiline disableEdit={step.activeStep === 2} />
												</TableCell>
											}
											{ header === 'section' &&
												<TableCell className={classes.tableCell} data-cy="add-section">
													<EditableInput inputId={`${index}section`} name="section" className={classes.innerTextFlow} type='text' placeholder="Add section..."
														initialValue={item.section ? item.section.name ? item.section.name : '' : ''} InputProps={{ disableUnderline: true }}
														onBlur={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleSectionBlur(event, item.id)}
														onEnterClicked={(event: any) => onEnterClicked(event, index)} hideIcon={step.activeStep === 2} disableEdit={step.activeStep === 2} />
												</TableCell>
											}
											{ header === 'priority' &&
												<PriorityForm value={item.priority as string} onChange={(newValue: string) => handleChangePriority(newValue, item.id)} />
											}
											{ header === 'description' &&
												<TableCell className={classes.tableCell} data-cy="add-desc">
													<EditableInput inputId={`${index}description`} name="description" className={clsx(classes.innerTextFlow, classes.inputBorder)} type='text'
														placeholder="Add description..." initialValue={item.description ? item.description : ''} InputProps={{ disableUnderline: true }}
														onBlur={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleBlur(event, item.id)}
														onEnterClicked={(event: any) => onEnterClicked(event, index)} hideIcon multiline />
												</TableCell>
											}
											{ header === 'tags' &&
												<TableCell className={classes.tableCell} data-cy="add-tags">
													<TagsDropdown item={item} id={item.id} allTagOptions={allTagOptions}
														handleChangeEntity={handleChangeEntity} handleRemoveTag={handleRemoveTag} />
												</TableCell>
											}
											{ header === 'dueDate' &&
												<TableCell className={classes.tableCell} data-cy="add-calDueDate">
													<DateHandler type='dueDate' date={idx(item, t => t.dueDate)} handleChangeDate={(dueDate: any) => handleChangeDueDate(dueDate, item.id)} />
												</TableCell>
											}
											{ header === 'relativeDueDate' &&
												<TableCell className={classes.tableCell} data-cy="add-relDueDate">
													<EditableInput inputId={`${index}relativeDueDate`} name='relativeDueDate' className={classes.innerTextFlow}
														initialValue={item.relativeDueDate ? item.relativeDueDate : ''} type='number' placeholder="Relative Due Date"
														onBlur={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleBlurRelDate(event, item.id)}
														InputProps={{ disableUnderline: true, inputProps: { inputMode: 'numeric', pattern: '[0-9]*' } }} onEnterClicked={(event: any) => onEnterClicked(event, index)} />
												</TableCell>
											}
											{ header === 'startDate' &&
												<TableCell className={classes.tableCell} data-cy="add-calStartDate">
													<DateHandler type='startDate' date={idx(item, t => t.startDate)} handleChangeDate={(startDate: any) => handleChangeStartDate(startDate, item.id)} />
												</TableCell>
											}
											{ header === 'relativeStartDate' &&
												<TableCell className={classes.tableCell} data-cy="add-relStartDate">
													<EditableInput inputId={`${index}relativeStartDate`} name='relativeStartDate' className={classes.innerTextFlow}
														initialValue={item.relativeStartDate ? item.relativeStartDate : ''} type='number' placeholder="Relative Start Date"
														onBlur={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleBlurRelDate(event, item.id)}
														InputProps={{ disableUnderline: true, inputProps: { inputMode: 'numeric', pattern: '[0-9]*' } }} onEnterClicked={(event: any) => onEnterClicked(event, index)} />
												</TableCell>
											}
											{ header === 'dependencies' &&
												<TableCell className={classes.tableCell} data-cy="add-dependencies">
													<Dependencies task={item} skinnyLists={skinnyLists} setSkinnyLists={setSkinnyLists} dependencies={dependencies ? dependencies : []}
														selectedTemplate={selectedTemplate} />
												</TableCell>
											}
											{ header === 'userOwners' &&
												<TableCell className={classes.tableCell} data-cy="add-userOwners">
													<div className={classes.flex} style={{ flexWrap: 'wrap' }}>
														<RenderOwnersRow truncate={1} owners={getInternalOwners(item)} style={{ height: '26px', marginBottom: '2px', maxWidth: '224px' }}
															handleRemoveOwnerClick={(owner: any) => handleRemoveInternalOwner(owner, item.id)} />
														<OwnerForm panelClass={classes.panelClass} adminPlus={isAdmin} formType="owners" owners={getInternalOwners(item)}
															additionalNewOwners={additionalInternalOwners} setAdditionalNewOwners={setAdditionalInternalOwners}
															openAddPanel={openAddPanelUserOwner} setOpenAddPanel={setOpenAddPanelUserOwner}
															owners2={getInternalOwners(item)} setOwners={owner => setInternalOwners(owner, item.id)} size="small" whatTheHell />
													</div>
												</TableCell>
											}
											{ header === 'userReviewers' &&
												<TableCell className={classes.tableCell} data-cy="add-userReviewers">
													<div className={classes.flex} style={{ flexWrap: 'wrap' }}>
														<RenderOwnersRow truncate={1} owners={getInternalReviewers(item)} style={{ height: '26px', marginBottom: '2px', maxWidth: '224px' }}
															handleRemoveOwnerClick={(reviewer: any) => handleRemoveInternalReviewer(reviewer, item.id)} />
														<OwnerForm panelClass={classes.panelClass} adminPlus={isAdmin} formType="reviewers" owners={getInternalReviewers(item)}
															additionalNewOwners={additionalInternalOwners} setAdditionalNewOwners={setAdditionalInternalOwners}
															openAddPanel={openAddPanelUserReviewer} setOpenAddPanel={setOpenAddPanelUserReviewer}
															owners2={getInternalReviewers(item)} setOwners={reviewer => setInternalReviewers(reviewer, item.id)} size="small" whatTheHell />
													</div>
												</TableCell>
											}
											{ header === 'externalUserOwners' &&
												<TableCell className={classes.tableCell} data-cy="add-externalUserOwners">
													<div className={classes.flex} style={{ flexWrap: 'wrap' }}>
														<RenderOwnersRow truncate={1} owners={getExternalOwners(item)} style={{ height: '26px', marginBottom: '2px', maxWidth: '224px' }}
															handleRemoveOwnerClick={(owner: any) => handleRemoveExternalOwner(owner, item.id)} />
														<OwnerForm panelClass={classes.panelClass} adminPlus={true} formType="owners" externalCompanyId={externalCompanyId}
															owners={getExternalOwners(item)} owners2={getExternalOwners(item)}
															additionalNewOwners={additionalExternalOwners} setAdditionalNewOwners={setAdditionalExternalOwners}
															openAddPanel={openAddPanelExtUserOwner} setOpenAddPanel={setOpenAddPanelExtUserOwner}
															setOwners={owner => setExternalOwners(owner, item.id)} size="small" whatTheHell />
													</div>
												</TableCell>
											}
											{ header === 'externalUserReviewers' &&
												<TableCell className={classes.tableCell} data-cy="add-externalUserReviewers">
													<div className={classes.flex} style={{ flexWrap: 'wrap' }}>
														<RenderOwnersRow truncate={1} owners={getExternalReviewers(item)} style={{ height: '26px', marginBottom: '2px', maxWidth: '224px' }}
															handleRemoveOwnerClick={(reviewer: any) => handleRemoveExternalReviewer(reviewer, item.id)} />
														<OwnerForm panelClass={classes.panelClass} adminPlus={true} formType="reviewers" externalCompanyId={externalCompanyId}
															owners={getExternalReviewers(item)} owners2={getExternalReviewers(item)}
															additionalNewOwners={additionalExternalOwners} setAdditionalNewOwners={setAdditionalExternalOwners}
															openAddPanel={openAddPanelExtUserReviewer} setOpenAddPanel={setOpenAddPanelExtUserReviewer}
															setOwners={reviewer => setExternalReviewers(reviewer, item.id)} size="small" whatTheHell />
													</div>
												</TableCell>
											}
										</React.Fragment>
									)
								})}
								{ step.activeStep === 1 &&
									// for autoFill Field
									<TableCell className={classes.tableCell} style={onAddButtonHover ? ({ borderLeft: '2px solid #3A84FF', zIndex: 1 }) : undefined} />
								}
								{/* extra tab */}
								<TableCell className={classes.tableCell} />
							</TableRow>
						)
					})
			}
			<TableRow ref={newTaskRef} className={clsx(classes.tableRow, classes.createTaskRow)}>
				{step.activeStep === 1 &&
					<TableCell className={classes.tableCell} style={{ border: 'none' }} />
				}
				<TableCell className={classes.tableCell} style={{ border: 'none' }}>
					<AddTaskBtn createNewTask={createNewTask} />
				</TableCell>
			</TableRow>
		</TableBody>
	)
}

function DateHandler({ date, handleChangeDate, type }: any) {

	const [open, setOpen] = useState(false);
	const classes = useStyles();
	const text = Boolean(date) ? format(Date.parse(date), 'MMM dd yyyy') : `Add ${(type === 'dueDate' ? 'Due' : 'Start')} Date`;

	function onChange(date: any) {
		setOpen(false);
		handleChangeDate(date)
	}

	return (
		<>
			<EditableInput initialValue={Boolean(date) ? text : ''} placeholder={text} disableEdit={false} isOnlyParentUpdate={true} hideIcon className={classes.innerTextFlow} onClick={() => setOpen(!open)} />
			{open &&
				<DatePicker
					classes={classes.datePicker}
					open={open}
					onClose={() => setOpen(false)}
					value={Boolean(date) ? date : null}
					onChange={(date: any) => onChange(date)}
				/>
			}
		</>
	)
}

function TagsDropdown({ item, id, allTagOptions, handleChangeEntity, handleRemoveTag }: any) {

	const [showMore, setShowMore] = useState(false);
	const classes = useStyles();

	return (
		<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
			{ item.tags &&
				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
					{item.tags.map((tag: string, tagIndex: number) => (
						<React.Fragment key={tagIndex}>
							{(showMore || (!showMore && tagIndex === 0)) &&
								<TagLabel additionalClasses={classes.tags} key={tagIndex} label={tag} overrideWidth={item.tags.length > 1} onClose={() => handleRemoveTag(item, tag, id)} />
							}
						</React.Fragment>
					))}

					{item.tags.length > 1 &&
						<Button className={classes.showMoreButton} onClick={() => setShowMore(!showMore)} style={{ height: '31px', alignSelf: 'baseline' }}>
							{showMore ? `-` : `+${item.tags.length - 1}`}
						</Button>
					}
				</div>
			}
			<div>
				<TagDropdown options={allTagOptions} task={item} id={id} changeHandler={handleChangeEntity} placeholder='Search Entity'
					spaceAvailable={idx(item, (t: any) => t.tags.length)} />
			</div>
		</div>
	)
}

function AddTaskBtn({ createNewTask }: any) {
	const classes = useStyles();
	const [newTaskActive, setNewTaskActive] = useState(true);

	function handleBlur(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
		setNewTaskActive(false);
		if (!idx(event, e => e.target.value)) { return }
		const { value } = event.target;
		createNewTask(value);
	}

	function onEnterClicked(event: any) {
		handleBlur(event);
	}

	return (
		<div data-cy="create-new-task-container">
			{newTaskActive ? (
				<EditableInput name='addNewTask'
					className={clsx(classes.innerTextFlow, classes.inputBorder)}
					type='text'
					placeholder="New Task"
					dataCy="create-new-task-text-field"
					initialValue=''
					InputProps={{ disableUnderline: true }}
					hideIcon
					autoFocus
					multiline
					onEscClicked={() => setNewTaskActive(false)}
					onEnterClicked={(event: any) => onEnterClicked(event)}
					onBlur={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleBlur(event)} />
			) : (
				<div style={{ display: 'flex', alignItems: 'center', height: 23 }} onClick={() => setNewTaskActive(true)}>
					<AddIcon style={{ fontSize: '10px', marginRight: '2px', color: '#606060' }} />
					<Typography className={classes.t4} data-cy="create-new-task-text">
						Create new task
          </Typography>
				</div>
			)}
		</div>
	)
}

function Dependencies(props: any) {

	const { task, skinnyLists, setSkinnyLists, dependencies, selectedTemplate } = props;
	const classes = useStyles();

	const dependenciesPresent = dependenciesPresentFunc;

	const [showMore, setShowMore] = useState(false);

	return (
		<div className={classes.dependencyContainer}>
			{ dependenciesPresent(dependencies, task) && (
				<div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
					{
						showMore ? (
							<>
								{dependencies[task.id].map((dependency: any, index: number) => (
									<React.Fragment key={index}>
										<Dependency skinnyLists={skinnyLists} setSkinnyLists={setSkinnyLists} task={task} dependency={dependency} selectedTemplate={selectedTemplate} />
									</React.Fragment>
								))
								}
							</>
						) : (
							<Dependency skinnyLists={skinnyLists} setSkinnyLists={setSkinnyLists} task={task} dependency={dependencies[task.id][0]} selectedTemplate={selectedTemplate} />
						)
					}
					{
						idx(dependencies, d => d[task.id].length > 1) && (
							<Button className={classes.showMoreButton} onClick={() => setShowMore(!showMore)} style={{ height: '31px !important' }}>
								{showMore ? `-` : `+${dependencies[task.id].length - 1}`}
							</Button>
						)
					}
				</div>

			)}
			<TaskDependency task={task} skinnyLists={skinnyLists} setSkinnyLists={setSkinnyLists} dependencies={dependencies} fromCreateProject={true} />
		</div>
	)
}

function Dependency(props: any) {

	const { skinnyLists, selectedTemplate, setSkinnyLists, dependency, task } = props;
	const classes = useStyles();
	const setFilters = useSetFilters();
	const history = useHistory();

	const [openModal, setOpenModal] = useState(false);

	const dependencyDataGenerator = dependencyDataGeneratorFunc;
	const handleDeleteTaskDependency = handleDeleteTaskDependencyFunc;

	var checkedId: any;
	if (dependency.taskId == task.id) {
		checkedId = dependency.relatedId;
	} else {
		checkedId = dependency.taskId;
	}

	const [
		text_prefix,
		text_suffix,
		taskDisplayName,
		fullTaskDisplayName,
		bufferTruncate,
		taskDisplayId,
		relatedId,
		isBlocking,
	] = dependencyDataGenerator(dependency, task, skinnyLists);

	const [
		deleteTaskDependency,
		{ loading: mutationLoading, data: mutationData, error: mutationError },
	] = useMutation(DELETE_TASK_DEPENDENCY);

	function openTask(taskToOpen: any, taskNo: any) {
		let listToOpen = -1;

		// 1. determine list to which this task belongs
		for (let i = 0; i < props.skinnyLists.length; ++i) {
			for (let j = 0; j < props.skinnyLists[i].tasks.length; ++j) {
				if (props.skinnyLists[i].tasks[j].id == taskToOpen) {
					listToOpen = props.skinnyLists[i].id;
					break;
				}
			}
		}
		setFilters(
			{
				taskPageSelectedTasks: [taskToOpen.toString()],
				taskPageLists: [listToOpen.toString()],
				taskPagePane: 0,
			},
			{ defaultTaskFilters: true }
		);
		// history.push('/app/tasks');
		setTimeout(() => history.push('/app/tasks'), 250);
	}

	function dialogHeader() {
		return (
			<div>
				<Typography style={{ color: '#707070', fontSize: '18px', fontWeight: 'bold' }}>
					Dependency Warning
      			</Typography>
			</div>
		)
	}

	function dialogContent() {

		const text = (selectedTemplate.tasks.some((t: any) => t.id === checkedId)) ?
			'This task has not yet been created' : 'You are about to navigate away from this page and any changes you have made will be lost.';
		return (
			<div className={classes.flex} style={{ flexDirection: 'row' }}>
				<DialogContentText className={classes.dependencyText} style={{ marginBottom: '0px' }}>
					{text}
				</DialogContentText>
			</div>
		)
	}

	function dialogActions() {
		return (
			<React.Fragment>
				<Button onClick={onCancel} variant="outlined" className={classes.dialogButton}>
					Cancel
				</Button>
				{ !(selectedTemplate.tasks.some((t: any) => t.id === checkedId)) &&
					<Button onClick={onOk} variant="contained" className={classes.dialogButton} style={{ marginLeft: '24px' }}>
						Okay
					</Button>
				}

			</React.Fragment>
		)
	}

	function onOk() {
		openTask(dependency.relatedId, dependency.relatedListNumber);
		setOpenModal(false);
	}

	function onCancel() {
		setOpenModal(false);
	}

	return (

		<React.Fragment>
			<ListItem data-cy="list-task-dependency" button disableGutters={true} className={classes.listItem} >
				<Typography className={classes.dependencyText} >
					{text_prefix}&nbsp;
					</Typography>
				<Tooltip title={fullTaskDisplayName} >
					<Typography className={clsx(classes.dependencyText, classes.taskDisplayName)} style={{ color: '#1159D0' }} onClick={() => setOpenModal(true)}>
						{fullTaskDisplayName}
					</Typography>
				</Tooltip>
				<Typography className={text_suffix == '' ? classes.hideSuffixStyling : classes.dependencyText} >
					{text_suffix}
				</Typography>
				<IconButton onClick={e => handleDeleteTaskDependency(e, Boolean(isBlocking), relatedId, task, skinnyLists, setSkinnyLists, deleteTaskDependency, mutationError)}
					className={classes.dependencyDeleteButton} data-cy="delete-dependency" >
					<Clear style={{ fontSize: '12px' }} />
				</IconButton>
			</ListItem>
			{ openModal &&
				<AlertModal open={openModal} dialogContent={dialogContent()} dialogHeader={dialogHeader()} dialogActions={dialogActions()} onCancel={onCancel} onOk={onOk} />
			}
		</React.Fragment>
	)
}