import { createMutationHook, gql } from '../graphqlHelpers';
import {
  AddTaskOwners,
  AddTaskOwnersVariables,
} from './__generated__/AddTaskOwners';

export const useAddTaskOwners = createMutationHook(gql`
  mutation AddTaskOwners(
    $taskId: ID!
    $owners: [OwnerAttributes!]
  ) {
    addTaskOwners(
      taskId: $taskId
      owners: $owners
    ) {
      errors {
        path
        message
      }
      success
      task {
        id
        name
        priority
        status
        dueDate
        updatedAt
        userOwners {
          id
          email
          fullName
          profileUrl
        }
        teamOwners {
          id
          name
        }
        firstUserReviewers {
          id
          email
          fullName
          profileUrl
        }
        firstTeamReviewers {
          id
          name
        }
        secondUserReviewers {
          id
          email
          fullName
          profileUrl
        }
        secondTeamReviewers {
          id
          name
        }
      }
    }
  }
`);
