import React, { useEffect, useRef, useState } from 'react';
import { createStyles, Checkbox, IconButton, makeStyles, TableCell, TableRow, TextField, Theme, Typography, TableHead } from '@material-ui/core'
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import clsx from 'clsx';
import * as cs from '../../../../../../../../constants/theme';
import { IProjectOptionsSelected, ISteps, ITableHeader, ITask } from '../../../interfaces';
import idx from 'idx';
import TaskSortIcon from '../../../../../../../common/TaskSortIcon';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		flexContainerRow: {
			display: 'flex',
			width: '100%',
			height: '100%',
			flexDirection: 'row',
		},
		tableRow: {
			height: 'auto',
		},
		tableCell: {
			padding: 10,
		},
		stickyHeader: {
			position: 'sticky',
			top: '0px',
			backgroundColor: cs.COLORS.background,
			zIndex: 10,
		},
		t3: {
			fontSize: "14px",
			lineHeight: "18px",
			color: cs.FONT.fontColor,
			opacity: 1,
			fontWeight: "bold",
		},
		headerCellDiv: {
			'& svg': { visibility: 'hidden' },
			'&:hover': {
				'& svg': { visibility: 'visible', cursor: 'pointer' }
			}
		},
		noPadding: {
			padding: '0px !important',
			margin: '0px !important',
		},
		inputRootPadding: {
			paddingTop: '7px !important',
			paddingBottom: '7px !important'
		},
		autoCompleteText: {
			color: cs.FONT.fontColor,
			fontSize: '12px',
			fontWeight: 500,
			lineHeight: '21px',
			letterSpacing: '0.00938em',
		},
		autoCompletePaper: {
			boxShadow: 'none',
			border: '1px solid #D8D8D8',
			borderRadius: '3px',
			backgroundColor: cs.COLORS.background,
			margin: '0px',
		},
		autoCompleteListBox: {
			padding: '0px',
		},
		autoCompletePopper: {
			zIndex: 12,
		},
		autoCompleteOption: {
			padding: '8px',
			'&:hover': {
				backgroundColor: cs.COLORS.primaryHover,
			},
		},
		inputHeight: {
			height: '14px',
		},
		addIcon: {
			color: cs.COLORS.primary,
			backgroundColor: cs.COLORS.background,
			width: 20,
			height: 20,
			padding: 0,
			borderRadius: '2px',
			border: `2px solid ${cs.COLORS.primary}`,
			position: 'absolute',
			bottom: -10,
			left: -10,
			zIndex: 2,
			'&:hover': {
				zIndex: 1,
				borderRadius: '2px',
				backgroundColor: cs.COLORS.background,
			}
		},
	})
);

interface ITaskTableHeader {
	allTableHeaderOptions: ITableHeader[];
	displayedHeaders: ITableHeader[];
	setDisplayedHeaders?: React.Dispatch<React.SetStateAction<ITableHeader[]>>;
	selectedTemplate: any;
	selectedTasksIds: string[];
	setSelectedTasksIds: React.Dispatch<React.SetStateAction<string[]>>;
	onAddButtonHover?: boolean;
	setOnAddButtonHover?: React.Dispatch<React.SetStateAction<boolean>>;
	step: ISteps;
	projectOptionsSelected: IProjectOptionsSelected;
	sortByName: string | null;
	sortBySection: string | null;
	nameFilter: string;
	sectionFilter: string;
	setNameFilterPopper: any;
}

export default function TaskTableHeader(props: ITaskTableHeader) {

	const { displayedHeaders, setDisplayedHeaders, allTableHeaderOptions, selectedTemplate, selectedTasksIds, setSelectedTasksIds,
		onAddButtonHover, setOnAddButtonHover, step, projectOptionsSelected, sortByName, sortBySection, nameFilter, sectionFilter, setNameFilterPopper } = props;

	const classes = useStyles();

	const availableHeaderOptionValues = displayedHeaders.map(header => header.value);
	const [onAddButtonClicked, setOnAddButtonClicked] = useState(false);
	const autoCompleteOptions = headerOptions();
	const listHasDependencies = idx(selectedTemplate, template => template.tasks.some((t: any) => t.dependencies.length > 0)) || false;

	const emptyHeaderRef = useRef(null);
	const nameHeaderRef = useRef(null);
	const sectionHeaderRef = useRef(null);


	function headerOptions() {
		var headerOptionValues = allTableHeaderOptions.filter(option => !availableHeaderOptionValues.includes(option.value));
		if (projectOptionsSelected.projectType === 'internal') {
			headerOptionValues = headerOptionValues.filter(header => !(header.value === 'externalUserOwners' || header.value === 'externalUserReviewers'));
		}
		return headerOptionValues;
	}

	// useEffect(() => {
	// 	if (emptyHeaderRef && emptyHeaderRef.current) { (emptyHeaderRef as any).current.scrollIntoView({ behavior: "smooth" }) };
	// }, [displayedHeaders]);

	function onHeaderSelected(_: React.ChangeEvent<{}>, selectedOption: ITableHeader | null, reason: AutocompleteChangeReason) {
		if (selectedOption) {
			if (reason === 'select-option' && !availableHeaderOptionValues.includes(selectedOption.value)) {
				setOnAddButtonClicked(false);
				setDisplayedHeaders && setDisplayedHeaders([...displayedHeaders, selectedOption]);
			}
		}
	}

	function removeHeader(_: React.MouseEvent<unknown>, selectedValue: ITableHeader) {
		if (availableHeaderOptionValues.includes(selectedValue.value)) {
			setDisplayedHeaders && setDisplayedHeaders(displayedHeaders.filter(header => header.value !== selectedValue.value));
		}
	}

	function renderCheckbox() {
		const rowCount = selectedTemplate && selectedTemplate.tasks ? selectedTemplate.tasks.length : 0;
		return (
			<Checkbox
				indeterminate={selectedTasksIds.length > 0 && selectedTasksIds.length < rowCount}
				checked={selectedTasksIds.length > 0 && selectedTasksIds.length === rowCount}
				onChange={handleSelectAllClick} color="primary" style={{ color: cs.FONT.fontColor, padding: 0 }}
			/>
		);
	};

	function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
		if (event.target.checked && selectedTemplate && selectedTemplate.tasks) {
			const newSelectedTasks = selectedTemplate.tasks.map((task: ITask) => task.id);
			setSelectedTasksIds(newSelectedTasks);
			return;
		}
		setSelectedTasksIds([]);
	};

	function handleNameClick(header: string) {
		if (header === 'name') setNameFilterPopper(header, nameHeaderRef);
		else if (header === 'section') setNameFilterPopper(header, sectionHeaderRef);
	}

	function getSortDirection(header: string) {
		if (header === 'name') return sortByName;
		else if (header === 'section') return sortBySection;
		else return null;
	}

	function getFilteredStyle(header: string) {
		if (header === 'name') return nameFilter;
		else if (header === 'section') return sectionFilter;
	}

	return (
		<TableHead data-cy='task-table-header'>
			<TableRow className={classes.tableRow}>
				{step.activeStep === 1 &&
					<TableCell className={clsx(classes.tableCell, classes.stickyHeader)} width={'30px'} style={{ paddingLeft: 0 }}>
						{renderCheckbox()}
					</TableCell>
				}
				{displayedHeaders &&
					displayedHeaders.map((header) => {
						const headerWidth = ((header.value === 'dependencies' && !listHasDependencies) ? '200px' : header.width);
						return (
							<TableCell key={header.value} className={clsx(classes.tableCell, classes.stickyHeader)} width={headerWidth}>
								<div className={clsx(classes.flexContainerRow, classes.headerCellDiv)} style={{ justifyContent: 'space-between' }} onClick={() => handleNameClick(header.value)}>
									<div className={clsx(classes.flexContainerRow, classes.headerCellDiv)}>
										<Typography ref={header.value === 'name' ? nameHeaderRef : header.value === 'section' ? sectionHeaderRef : null}
											className={classes.t3} style={(header.value === 'name' || header.value === 'section') ? { marginRight: '5px' } : undefined}>
											{header.label}</Typography>
										{(header.value === 'name' || header.value === 'section') &&
											<TaskSortIcon sortDirection={getSortDirection(header.value)} styles={getFilteredStyle(header.value) ? { color: '#3A84FF', visibility: 'visible' } : {}} />
										}
									</div>
									{header.optional &&
										<CloseIcon onClick={(e: React.MouseEvent<unknown>) => removeHeader(e, header)} style={{ fontSize: 18, color: cs.FONT.fontColor, marginRight: '12px' }} />
									}
								</div>
							</TableCell>
						)
					})
				}
				{step.activeStep === 1 &&
					<TableCell className={clsx(classes.tableCell, classes.stickyHeader)} width={'250px'}>
						<div className={classes.flexContainerRow}>
							<IconButton onMouseOver={() => (!onAddButtonHover && setOnAddButtonHover) && setOnAddButtonHover(true)}
								onMouseLeave={() => (onAddButtonHover && setOnAddButtonHover) && setOnAddButtonHover(false)}
								onClick={() => setOnAddButtonClicked(!onAddButtonClicked)} className={classes.addIcon} >
								<AddOutlinedIcon data-cy="add-new-dimension-icon" style={{ fontSize: '1rem' }} />
							</IconButton>
							{onAddButtonClicked &&
								<Autocomplete
									classes={{
										input: clsx(classes.noPadding, classes.autoCompleteText, classes.inputHeight), inputRoot: classes.inputRootPadding,
										popupIndicator: classes.noPadding, option: clsx(classes.autoCompleteText, classes.autoCompleteOption),
										paper: classes.autoCompletePaper, listbox: classes.autoCompleteListBox, popper: classes.autoCompletePopper
									}}
									style={{ width: '250px' }}
									id="add-new-dimention"
									disableClearable
									options={autoCompleteOptions}
									getOptionLabel={(option) => option.label}
									renderInput={(params) => <TextField {...params} placeholder="Add new dimension" variant="outlined" />}
									onChange={(e: React.ChangeEvent<{}>, value, reason) => onHeaderSelected(e, value, reason)}
								/>
							}
						</div>
					</TableCell>
				}
				<TableCell ref={emptyHeaderRef} className={clsx(classes.tableCell, classes.stickyHeader)} width={'dependencies'} />
			</TableRow>
		</TableHead>
	)
}

