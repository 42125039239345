import React, { useState, useEffect } from 'react';
import {
  ClickAwayListener,
  TextField
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { MoneyDisplay } from '../../../../../../../common/MoneyDisplay';
import { useUpdateTaskRecon } from '../../../../../../../../graphql/mutations/UpdateTaskRecon';
import { Skeleton } from '../../../../../../../common/Skeleton';
import { useElementOnScreen } from '../../../utils/useElementOnScreen';
import { useDispatchContext } from '../../../../../../../../store';

const useLocalStyles = makeStyles(() =>
  createStyles({
    sourceFieldInput: {
      '& input': {
        padding: '6px 0px',
        textAlign: 'center',
        color: 'rgb(96, 96, 96)'
      }
    },
    inputContainer: {
      display: 'flex',
      height: 25,
      justifyContent: 'center',
      width: 120
    }
  })
);

interface SourceValueProps {
  changeActiveReconSource: any;
  task: any;
  value?: number;
  sourceType: any;
  label?: any;
  updateTask: any;
  updateTaskInCache: any;
  style?: any;
  displaySkeleton: any;
  toggleReconcilingItems?: any;
  updateToggleReconcilingItems?: any;
  handleShowSourcePopper?: any;
  hasSourceTypePopper?: any;
}

export function SourceValue({
  sourceType,
  label,
  task,
  value = 0,
  updateTask,
  updateTaskInCache,
  changeActiveReconSource,
  displaySkeleton,
  style,
  toggleReconcilingItems,
  updateToggleReconcilingItems,
  handleShowSourcePopper,
  hasSourceTypePopper,
}: SourceValueProps) {
  const updateTaskRecon = useUpdateTaskRecon();
  const isIntegration = task[`${sourceType}SourceType`] !== 1 && task[`${sourceType}SourceType`] !== 2
  const hasFileValues = task[`${sourceType}FileReconSourcesTotal`] && task[`${sourceType}FileReconSources`] && task[`${sourceType}FileReconSources`].length > 0;
  const showMoreButtonText = sourceType === 'reconciling-items' ? 'Detail' : 'Source';
  const showMoreButtonDataCy = `show-more-button-${sourceType}`
  const sourceValueDataCy = `${sourceType}-source-value`
  // Not the best way to determine the skeleton
  // Should update this in the future
  // Currently checking to see if the primaryLabel has been queried yet or not
  const skeletonActive = (!task.hasOwnProperty('primaryLabel') || displaySkeleton)
  const [displayedValue, setDisplayedValue] = useState<any>('');
  const [inputActive, setInputActive] = useState<boolean>(false);
  const [activeSource, setActiveSource] = useState<string>('');
  const [ sourcePopperRef, isVisible, setIsVisible ] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 1.0
  })
  const dispatch = useDispatchContext();

  if (sourceType === 'reconciling-items') value = task.reconcilingItemTotal

  // when taskId changes, load up the notification for that task
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
    }
    return () => { isMounted = false };
  });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setDisplayedValue(value);
    }
    return () => { isMounted = false };
  }, [value]);

  if (hasSourceTypePopper) {
    useEffect(() => {
      let isMounted = true;
      if (isMounted && !isVisible) {
        onHideSourcePopper();
      }
      return () => { isMounted = false };
    }, [isVisible, handleShowSourcePopper]);
  }

  const onHideSourcePopper = async () => {
    handleShowSourcePopper();
  }

  function handleSourceValueClick(e: React.MouseEvent) {
    // Prevent manual input for Integrations
    // Only allow for manual or file validation currently when there are no files added
    if (!isIntegration && !hasFileValues) {
      setInputActive(true);
    }
  }

  function handleSourceInputKeyUp(e: React.KeyboardEvent) {
    if (!(e.key === 'Enter')) {
      return;
    }
    update();
  }

  function handleChange(e: any) {
    setDisplayedValue(e.target.value);
  }

  function handleClickAway() {
    if (!inputActive) {
      return;
    }
    update();
  }

  function handleBlur() {
    update();
  }

  function update() {
    // Prevent update if the user clicks input but doesn't enter a number
    if (!displayedValue) {
      setInputActive(false);
      return;
    }

    const updateTaskReconCallback = (data: any, sourceType: any) => {
      // Might be a bit excessive here
      if (data && data.updateTaskReconSources && data.updateTaskReconSources.task) {
        const updatedTask = data.updateTaskReconSources.task;

        updateTaskInCache({
          ...task,
          ...updatedTask
        })
        updateTask({
          ...task,
          ...updatedTask
        });
            
        if (sourceType === 'primary') {
          dispatch({
            type: 'SET_PRIMARY_TOTAL',
            primaryTotal: { balance: updatedTask.primaryTotal, task_id: task.id },
          });
        } else if (sourceType === 'secondary') {
          dispatch({
            type: 'SET_SECONDARY_TOTAL',
            secondaryTotal: { balance: updatedTask.secondaryTotal, task_id: task.id },
          });
        }
        
        setInputActive(false);
      }
    }

    updateTaskRecon({
      taskId: task.id,
      sourceType: sourceType,
      value: Number(displayedValue),
      responseCallback: updateTaskReconCallback
    })
  }

  function handleSourceButtonClick(e: React.MouseEvent) {
    setIsVisible(true);
    handleShowSourcePopper(e, sourceType);
    changeActiveReconSource(sourceType);
  }

  const handleToggleReconcilingItems = () => {
    updateToggleReconcilingItems(!toggleReconcilingItems);
  }
  
  const localStyles = useLocalStyles();
  return (
    <div
      className="flex-1 pr-1"
      style={style}
    >
      <div className="text-[10px] font-medium">
        <span>{label || sourceType}</span>
      </div>

      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          className={localStyles.inputContainer}
          onClick={handleSourceValueClick}
          style={{ justifyContent: 'start' }}
        >
          {inputActive ? (
            <CoolInput
              handleBlur={handleBlur}
              onChange={handleChange}
              onKeyUp={handleSourceInputKeyUp}
              value={displayedValue}
            />
          ) : (
            <Skeleton isLoaded={skeletonActive}>
              <span className="font-bold" style={{ fontSize: 12 }} data-cy={sourceValueDataCy}>
                {value && value !== 0 ? 
                  <MoneyDisplay
                    style={{ fontWeight: 700, fontSize: 12, color: '#2C2C2C' }}
                    value={value}
                    customSymbol={task.currency}
                    displaySymbol
                  />
                : <span className='px-5'>-</span>}
              </span>
            </Skeleton>
          )}
        </div>
      </ClickAwayListener>

      <button
        onClick={sourceType === 'reconciling-items' ? handleToggleReconcilingItems : handleSourceButtonClick}
        ref={sourcePopperRef}
        aria-describedby={sourceType}
        className="bg-gray-160 border-gray-200 border-t-[1.5px] flex items-center py-2 px-4 w-full text-xs font-medium text-center rounded-sm pointer-events-auto hover:bg-gray-200"
        data-cy={showMoreButtonDataCy}
      >
        <span className='mr-auto'>{showMoreButtonText}</span>
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.23216 1.81789H8.1819M8.1819 1.81789V6.76764M8.1819 1.81789L1.81794 8.18185" stroke="#606060" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
  );
}

function CoolInput({ onKeyUp, value, onChange, handleBlur }: any) {
  const localStyles = useLocalStyles();
  return (
    <TextField
      InputProps={{
        disableUnderline: true,
        className: localStyles.sourceFieldInput
      }}
      autoFocus
      onBlur={handleBlur}
      onChange={onChange}
      onKeyUp={onKeyUp}
      style={{
        padding: '0px',
        width: '100%',
        whiteSpace: 'nowrap',
        color: '#606060'
      }}
      type="number"
      value={value}
    />
  );
}
