import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import clsx from 'clsx';
import idx from 'idx';
import _, { indexOf } from 'lodash';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Paper,
  Typography,
  List,
  ListItem,
  ClickAwayListener,
  TextField,
  Tab,
  Tabs,
  InputAdornment,
  MenuItem,
  ListItemText,
} from '@material-ui/core';
import RightIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUpSharp';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import NumberFormat from 'react-number-format';

import * as cs from '../../../../../../constants/theme';
import { useGlobalStyles } from '../../../../../common/Style';
import InputForm from '../../../../../common/EditableInputForm';
import InviteForm from '../../../../../common/InviteForm';
import { MoneyInput } from '../../../../../common/MoneyInput';
import { DatePicker } from '../../../../../common/DatePicker';
import { MoneyDisplay } from '../../../../../common/MoneyDisplay';
import Dropdown from '../../../../../common/Dropdown';
import CreatableAutoSelect from '../../../../../common/CreatableAutoSelect';
import Dropzone from 'react-dropzone';

import NameLabel from '../../../../../common/NameLabel';
import { DueDateSelect } from '../../dateSelectors/DueDateSelect';
import UploadIcon from '@material-ui/icons/CloudUpload';
import ListIcon from '@material-ui/icons/PlaylistAdd';

import { useReconSupportTasks } from '../../../../../../graphql/queries/ReconSupportTasks';
import { UserTasks_userTasks } from '../../../../../../graphql/queries/__generated__/UserTasks';
import {
  SearchCompanyUsers_searchCompanyUsers_teams,
  SearchCompanyUsers_searchCompanyUsers_users,
} from '../../../../../../helpers/__generated__/SearchCompanyUsers';
import { useLazyListSections } from '../../../../../../graphql/queries/ListSections';
import { useUpdateTask } from '../../../../../../graphql/mutations/UpdateTaskHook';
import { useAddTaskOwners } from '../../../../../../graphql/mutations/AddTaskOwners';
import RemoveTaskOwner from '../../../../../../graphql/mutations/RemoveTaskOwner';
import { useUpdateTaskRecon } from '../../../../../../graphql/mutations/UpdateTaskRecon';
import { useError } from '../../../../../../hooks/UseError';
import { useSetFilters } from '../../../../../../hooks/SetFilters';

import { useDispatchContext, useStateContext } from '../../../../../../store';
import { ReconciliationSection } from './Reconciliation/ReconciliationSection';

import TimeLineNotification from './TimeLine/TimeLineNotification';
import { AccountCircle, LocalDrinkSharp, AttachFile } from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PersonIcon from '@material-ui/icons/Person';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import ViewCompactOutlinedIcon from '@material-ui/icons/ViewCompactOutlined';
import TimeLineFile from './TimeLine/TimeLineFile';
import OverviewNotifications from './TimeLine/OverviewNotifications';
import TaskDependency from './TaskDependency/TaskDependency';
import MultiTaskDependency from './TaskDependency/components/MultiDependency/MultiTaskPopper';
import { TaskEntityInput } from './TaskEntityInput/TaskEntityInput';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...cs.shared,
    difference: {
      textAlign: 'center',
      width: 120,
      fontWeight: 'bold',
    },
    root: {
      height: '100%',
      overflowY: 'auto',
      paddingBottom: '400px',
      '& .react-select__menu': {
        marginTop: '0px !important',
        zIndex: 100000000000,
        width: 300
      }
    },
    invisible: {
      display: 'none',
    },
    coool: {
      '& input': {
        padding: '6px 0px',
        textAlign: 'center',
        fontWeight: 800,
        color: 'rgb(96, 96, 96)',
      },
    },
    fullWidth: { width: 365 },
    metaBox: {
      marginTop: '24px',
    },
    metaForm: {
      display: 'flex',
      marginBottom: '24px',
      paddingLeft: '16px',
    },
    flex: {
      display: 'flex',
    },
    grow: {
      flexGrow: 1,
    },
    addButton: {
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
      fontSize: '15px',
    },
    secondary: {
      color: '#606060',
    },
    label: {
      minWidth: '80px',
    },
    priority: {
      display: 'flex',
      width: 'fit-content',
      height: '28px',
      cursor: 'pointer',
      color: '#509E6D',
      background: '#FFFFFF',
      alignItems: 'center',
      padding: '1px 6px 1px 0px',
      marginRight: '60px',
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
    },
    transform: {
      textTransform: 'capitalize',
      paddingLeft: '6px',
    },
    priorityDropdown: {
      position: 'absolute',
      top: '27px',
      left: '0px',
      width: 'max-content',
      color: '#509E6D',
      background: '#FFFFFF',
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
    },
    morePaper: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '200px',
      position: 'absolute',
      top: '28px',
      padding: '9px',
      boxSizing: 'border-box',
      border: '2px solid #D8D8D8',
      borderRadius: '3px',
      zIndex: 1000000,
    },
    owners: {
      width: '375px',
    },
    taskTitle: {
      marginTop: '-30px',
    },
    taskSection: {},
    taskDescription: {
      maxHeight: '200px',
      overflowY: 'auto',
      marginTop: '32px',
    },
    shortText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '75%',
      marginLeft: '100px',
      marginTop: '-22px',
      color: 'dimgrey',
      fontSize: '15px',
      fontFamily: 'Montserrat',
      fontWeight: 500,
      cursor: 'text',
    },
    headerPointer: {
      cursor: 'pointer',
    },
    datePicker: {
      border: 'none',
      paddingLeft: '0 !important',
      '& .MuiInputBase-root': {
        flexDirection: 'row-reverse',
      },
      '& input': {
        width: '100px',
      },
      '& svg': {
        marginRight: '5px',
        fontSize: '20px',
      },
    },
    descriptionPlaceholderInput: {
      border: '1px solid transparent',
      borderRadius: '5px',
      width: '100%',
      paddingRight: '3px',
      '& ::-webkit-input-placeholder': {
        color: '#AFAFAF',
        fontWeight: 500,
        opacity: 1,
        fontFamily: 'Montserrat',
      },
      '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: 'none',
      },
      '& .MuiInputBase-root': {
        alignItems: 'flex-start',
      },
      '& .MuiInputBase-multiline': {
        padding: '3px 0 7px',
      },
      '&:hover': {
        border: '1px solid #AFAFAF',
        borderRadius: '5px',
      },
      '&:focus-within': {
        border: '1px solid #AFAFAF',
        borderRadius: '5px',
      },
    },
    descriptionInputContainer: {
      flex: 3,
      border: '1px solid transparent',
      borderRadius: '5px',
    },
    selectorClass: {
      width: '100%',
    },
    containerClass: {
      flex: 1,
    },
    /** needed for firefox
     * in Firefox padding-bottom is ignored with overflow:auto or overflow:scroll, see the documentation
    */
    lastPadding: {
      '&::after': {
        content: "''",
        height: '400px',
        display: 'block',
      },
    },
  })
);

interface OverviewPaneProps {
  search?: any;
  value?: number;
  index?: number;
  task: any;
  tasks: UserTasks_userTasks[];
  multiTasks: string[];
  skinnyLists: any[];
  setSkinnyLists: React.Dispatch<any>;
  setTasks: React.Dispatch<React.SetStateAction<UserTasks_userTasks[]>>;
  task2?: any;
  lists: any;
  dependencies: any[];
  setDependencies: React.Dispatch<{}>;
  setFetchNewSkinnyList: React.Dispatch<{}>;
  isExternalCreateProject?: Boolean;
  isInternalCreateProject?: Boolean;
  additionalInternalOwners?: any[];
  additionalExternalOwners?: any[];
  setAdditionalInternalOwners?: React.Dispatch<React.SetStateAction<any[]>>;
  setAdditionalExternalOwners?: React.Dispatch<React.SetStateAction<any[]>>;
}

function OverviewPane(props: any) {
  const {
    value,
    index,
    task,
    tasks,
    multiTasks,
    skinnyLists,
    setSkinnyLists,
    setTasks,
    task2,
    lists,
    dependencies,
    setDependencies,
    setFetchNewSkinnyList,
    isExternalCreateProject,
    isInternalCreateProject
  } = props;



  const classes = useStyles();
  const setFilters = useSetFilters();

  const [openTitle, setOpenTitle] = useState<boolean>(true);
  const [openDescription, setOpenDescription] = useState<boolean>(false);
  const [openTaskDependency, setOpenTaskDependency] = useState<boolean>(false);

  const [openTitleHover, setOpenTitleHover] = useState<boolean>(false);
  const [openDescriptionHover, setOpenDescriptionHover] = useState<boolean>(
    false
  );

  const [openPriority, setOpenPriority] = useState<boolean>(false);
  const [moreHover, setMoreHover] = useState<boolean>(false);
  const [moreHover2, setMoreHover2] = useState(false);

  const [addOwner, setAddOwner] = useState<any>(null);
  const [removeOwner, setRemoveOwner] = useState<any>(null);

  const [owners, setOwners] = useState<any[]>([]);

  const [reviewers, setReviewers] = useState<any[]>([]);
  // const [changedDueDate, setChangedDueDate] = useState<any>('');
  //
  const [taskToUpdate, setTaskToUpdate] = useState<any>({});
  const [dueDateModal, setDueDateModal] = useState<boolean>(false);
  const [doNotUpdateTasks, setDoNotUpdateTasks] = useState<boolean>(false);
  const [sections, setSections] = useState<any>([]);

  const [
    updateTask,
    { loading: updateTaskLoading, data: updateTaskRes, error: updateTaskError },
  ] = useUpdateTask({
    id: props.task2 && props.task2.id,
    name: task.name,
    description: task.description,
    priority: task.priority,
    dueDate: task.dueDate,
    relativeDueDate: task.relativeDueDate,
    section: task && task.section && task.section.name,
  });
  const dispatch = useDispatchContext();
  const state = useStateContext();
  const adminPlus = idx(
    state,
    state =>
      state.currentUser.companyRole <= 3 || state.currentUser.companyRole == 8
  );
  const isDaily = idx(
    task,
    t =>
      t.list.schedule.repeatType === '1' || t.list.schedule.repeatInterval == 1
  );
  const hasSchedule = idx(task, t => t.list.schedule.repeatType);

  const [
    addTaskOwners,
    { loading: addTaskLoading, data: addTaskRes, error: addTaskError },
  ] = useAddTaskOwners({
    taskId: task.id,
    owners: addOwner,
  });

  const [getListSections, { loading: sectionLoading, data: sectionData }] = useLazyListSections();

  const [removeTaskOwner, { data: removeOwnerData }] = RemoveTaskOwner({
    taskId: task.id,
    owner: removeOwner,
  });

  const handleChangeSection = (e: any, task: any) => {
    const newSection = e.label;
    const newTask = props.task2;
    const taskSection = { name: '' };
    taskSection.name = newSection;
    newTask.section = taskSection;
    updateTasks(newTask);
    setTaskToUpdate(newTask);

    // Add New Section into options
    if (!sections.map((t: any) => t.label).includes(e.label)) {
      setSections([...sections, { label: e.label }]);
    }
  };

  function updateTasks(newTask: any) {
    const newTasks = tasks.map((a: any) => ({ ...a }));
    const index = newTasks.findIndex((task: any) => task.id == newTask.id);
    newTasks[index] = newTask;
    setTasks(newTasks);
  }

  // this helper function, 1. sends the mutation to update the task
  // updates the 'tasks' array -- the source of truth
  function updateTask2(newTask: any) {
    var newTasks = tasks.map((t: any) => ({ ...t }));
    var taskIndex: any = newTasks.find((t: any) => t.id === newTask.id);
    taskIndex = newTasks.indexOf(taskIndex);
    newTasks.splice(taskIndex, 1, newTask);
    setTasks(newTasks);

    setTaskToUpdate(newTask);
  }

  function parseOwner(owner: any) {
    if (owner.__typename === 'User') {
      return { email: owner.email, fullName: owner.fullName } as any;
    } else {
      return { id: owner.id, name: owner.name } as any;
    }
  }

  function toggleDescriptionOpen() {
    setOpenDescription(!openDescription);
  }

  function toggleTaskDependency() {
    setOpenTaskDependency(!openTaskDependency);
  }

  function updateDescription(newDescription: string) {
    const newTask = { ...task };
    newTask.description = newDescription;
    updateTasks(newTask);
    setTaskToUpdate(newTask);
  }

  const handleKeyDownSection = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      const sectionInput = document.getElementById('text-area-task-section');
      // @ts-ignore
      sectionInput.blur();
    }
    if (e.keyCode === 27) {
      const sectionInput = document.getElementById('text-area-task-section');
      // @ts-ignore
      sectionInput.innerHTML = task2
        ? task2.section && (task2.section.name as string)
        : 'None';
      // @ts-ignore
      sectionInput.blur();
    }
  };

  const handleRemoveOwner = (removeId: number, owner: any) => {
    if (state.currentUser.isExternalUser) { return; }
    var newOwners = [
      ...owners.slice(0, removeId),
      ...owners.slice(removeId + 1),
    ];
    setOwners(newOwners);

    var newTask = Object.assign({}, task);

    var addNewOwner;
    // if (owner.__typename === 'User') {
    //   addNewOwner = {email: owner.email, fullName: owner.fullName};
    //   newTask.userOwners = newTask.userOwners.filter(
    //     (o: any) => o.id !== owner.id
    //   );
    // } else {
    //   addNewOwner = {id: owner.id, name: owner.name};
    //   newTask.teamOwners = newTask.teamOwners.filter(
    //     (o: any) => o.id !== owner.id
    //   );
    // }

    // if (owner.__typename === 'User') {
    //   addNewOwner = {email: owner.email, fullName: owner.fullName};
    //   if(isExternalCreateProject){
    //     newTask.externalUserOwners = newTask.externalUserOwners.filter(
    //       (o: any) => o.id !== owner.id
    //     );
    //   } else {
    //     newTask.userOwners = newTask.userOwners.filter(
    //       (o: any) => o.id !== owner.id
    //     );
    //   }
    // } else {
    //   addNewOwner = {id: owner.id, name: owner.name};
    //   if(isExternalCreateProject){
    //     newTask.externalTeamOwners = newTask.externalTeamOwners.filter(
    //       (o: any) => o.id !== owner.id
    //     );
    //   } else {
    //     newTask.teamOwners = newTask.teamOwners.filter(
    //       (o: any) => o.id !== owner.id
    //     );
    //   }
    // }

    var newTask = Object.assign({}, task);
    var ownerType =
      // reviewer.__typename === 'User' ? 'userOwners' : 'teamOwners';
      owner.__typename === 'User' ? (isExternalCreateProject ? 'externalUserOwners' : 'userOwners') : (isExternalCreateProject ? 'externalTeamOwners' : 'teamOwners');
    newTask[ownerType].splice(removeId, 1);

    setRemoveOwner(owner);
    updateTasks(newTask);
  };

  const handleRemoveReviewer = (removeId: number, reviewer: any) => {
    if (state.currentUser.isExternalUser) { return; }
    setReviewers([
      ...reviewers.slice(0, removeId),
      ...reviewers.slice(removeId + 1),
    ]);

    var newTask = Object.assign({}, task);
    var reviewerType =
      // reviewer.__typename === 'User' ? 'userReviewers' : 'teamReviewers';
      reviewer.__typename === 'User' ? (isExternalCreateProject ? 'externalUserReviewers' : 'userReviewers') : (isExternalCreateProject ? 'externalTeamReviewers' : 'teamReviewers');
    newTask[reviewerType].splice(removeId, 1);

    reviewer = parseOwner(reviewer);
    reviewer.reviewer = true;
    setRemoveOwner(reviewer);
    updateTasks(newTask);
  };

  const handleAddOwner = (owner: any) => {
    if (state.currentUser.isExternalUser) { return; }
    for (let i = 0; i < owners2.length; i++) {
      if (
        owner.id != '' &&
        owner.id == owners2[i].id &&
        owner.__typename === owners2[i].__typename
      ) {
        return null;
      }
    }
    var addNewOwner = parseOwner(owner);
    var newTask = Object.assign({}, task);
    owner.__typename === 'User'
      // ? (newTask.userOwners = [...newTask.userOwners, owner])
      // : (newTask.teamOwners = [...newTask.teamOwners, owner]);
      ? (isExternalCreateProject ? (newTask.externalUserOwners = [...newTask.externalUserOwners, owner]) : (newTask.userOwners = [...newTask.userOwners, owner]))
      : (isExternalCreateProject ? (newTask.externalTeamOwners = [...newTask.externalTeamOwners, owner]) : (newTask.teamOwners = [...newTask.teamOwners, owner]));

    setAddOwner([addNewOwner]);
    updateTasks(newTask);
  };

  const handleAddReviewer = (reviewer: any) => {
    if (state.currentUser.isExternalUser) { return; }
    /* TODO:  <25-10-19, mpf: make this prettier> */
    for (let i = 0; i < reviewers2.length; i++) {
      if (
        reviewer.id != '' &&
        reviewer.id == reviewers2[i].id &&
        reviewer.__typename === reviewers2[i].__typename
      ) {
        return null;
      }
    }
    var newReviewer = parseOwner(reviewer);
    newReviewer.reviewer = true;
    newReviewer.__typename = reviewer.__typename;
    var newTask = Object.assign({}, task);
    reviewer.__typename === 'User'
      // ? (newTask.userReviewers = [...newTask.userReviewers, reviewer])
      // : (newTask.teamReviewers = [...newTask.teamReviewers, reviewer]);
      ? (isExternalCreateProject ? (newTask.externalUserReviewers = [...newTask.externalUserReviewers, reviewer]) : (newTask.userReviewers = [...newTask.userReviewers, reviewer]))
      : (isExternalCreateProject ? (newTask.externalTeamReviewers = [...newTask.externalTeamReviewers, reviewer]) : (newTask.teamReviewers = [...newTask.teamReviewers, reviewer]));

    setAddOwner([newReviewer]);
    updateTasks(newTask);
  };

  useEffect(() => {
    if(task?.list?.id){
      getListSections({
        variables: {
          id: task.list.id
        }
      })
    }
  }, [task])

  useEffect(() => {
    // if (addOwner && addOwner.length) addTaskOwners();
    if (addOwner && addOwner.length && !(isExternalCreateProject || isInternalCreateProject)) addTaskOwners();
  }, [addOwner]);
  useEffect(() => {
    // if (removeOwner) removeTaskOwner();
    if (removeOwner && !(isExternalCreateProject || isInternalCreateProject)) removeTaskOwner();
  }, [removeOwner]);

  useEffect(() => {
    if (!addOwner?.length) { return; }
    setAddOwner([]);
  }, [
    task.userOwners,
    task.teamOwners,
    task.userReviewers,
    task.teamReviewers,
  ]);

  //Leaving a comment here to come back and review this useEffect
  //I think it's causing unnecessary GQL calls
  useEffect(() => {
    if (taskToUpdate.id) {
      updateTask().then((res: any) => {
        if (res.data && res.data.updateTask && res.data.updateTask.task) {
          let dependencies = res.data.updateTask.task.dependencies;
          if (dependencies && dependencies.length > 0) {
            const newTasks = tasks.map((a: any) => ({ ...a }));
            let _skinnyLists = skinnyLists.map((a: any) => {
              return { ...a };
            });
            for (
              let dependency_iter = 0;
              dependency_iter < dependencies.length;
              dependency_iter++
            ) {
              let curr_dependency = dependencies[dependency_iter];
              for (
                let list_iter = 0;
                list_iter < _skinnyLists.length;
                list_iter++
              ) {
                let _list = _skinnyLists[list_iter];
                if (_list.tasks && _list.tasks.length > 0) {
                  for (
                    let task_iter = 0;
                    task_iter < _list.tasks.length;
                    task_iter++
                  ) {
                    let _task = _list.tasks[task_iter];
                    if (_task.id == curr_dependency.taskId) {
                      _task.dueDate = curr_dependency.taskDueDate;
                    }
                    if (_task.id == curr_dependency.relatedId) {
                      _task.dueDate = curr_dependency.relatedDueDate;
                    }
                  }
                }
              }
              newTasks.forEach((_task: any) => {
                if (_task.id == curr_dependency.taskId) {
                  _task.dueDate = curr_dependency.taskDueDate;
                }
                if (_task.id == curr_dependency.relatedId) {
                  _task.dueDate = curr_dependency.relatedDueDate;
                }
              });
            }
            setTasks(newTasks);
            setDoNotUpdateTasks(true);
            setFetchNewSkinnyList(
              Math.floor(Math.random() * (100 - 2 + 1) + 2)
            );
            setSkinnyLists(_skinnyLists);
          }
        }
      });
    }
  }, [taskToUpdate]);

  // sets Sections
  useEffect(() => {
    const listSections = idx(sectionData, data => data);
    if (sectionLoading) {
      return;
    }

    const availableSections = idx(listSections, (data: any) =>
      data.list.sections.map((section: any) => ({
        label: section.name,
        value: section.id,
      }))
    ) || [
        {
          value: idx(task2, (t: any) => task.section.id),
          label: idx(task2, (t: any) => task.section.name),
        },
      ];

    setSections(availableSections);
  }, [sectionLoading]);

  const handleChangePriority = (newPriority: string) => {
    var newTask = Object.assign({}, task);
    newTask.priority = newPriority;
    newTask.updatedAt = 'just now';

    if (props.search) {
      setTimeout(() => setTaskToUpdate(newTask), 400);
    } else {
      setTaskToUpdate(newTask);
      updateTasks(newTask);
    }
    setOpenPriority(false);
  };

  // const owners2 = idx(task2, task2 =>
  //   task2.userOwners.concat(task2.teamOwners)
  // );
  const owners2 = isExternalCreateProject ?
    (idx(task2, task2 => task2.externalUserOwners.concat(task2.externalTeamOwners))) : (idx(task2, task2 => task2.userOwners.concat(task2.teamOwners)));
  // const reviewers2 = idx(task2, task2 =>
  //   task2.userReviewers.concat(task2.teamReviewers)
  // );
  const reviewers2 = isExternalCreateProject ?
    (idx(task2, task2 => task2.externalUserReviewers.concat(task2.externalTeamReviewers))) : (idx(task2, task2 => task2.userReviewers.concat(task2.teamReviewers)));

  /* Either the responder or requester is allowed to change the due date,
    so we aren't concnerd with company Role here */
  const handleChangeDate = (newDate: any) => {
    if (state.currentUser.isExternalUser) { return; }
    var newTask = Object.assign({}, task);
    if (isNaN(newDate) || newDate === null) {
      return;
    }
    newTask.dueDate = newDate;
    newTask.updatedAt = 'just now';
    if (props.search) {
      setTimeout(() => setTaskToUpdate(newTask), 400);
    } else {
      setTaskToUpdate(newTask);
      updateTasks(newTask);
    }
    setFetchNewSkinnyList(
      Math.floor(Math.random() * (100 - 2 + 1) + 2)
    );
    dispatch({ type: 'DEPEDENCIES_UPDATED' })
  };

  useEffect(() => {
    if (!doNotUpdateTasks) {
      const newTasks = tasks.map((a: any) => ({ ...a }));
      skinnyLists.forEach((_list: any) => {
        if (
          task &&
          task.list &&
          task.list.id == _list.id &&
          _list.tasks &&
          _list.tasks.length > 0
        ) {
          _list.tasks.forEach((_task: any) => {
            if (_task.id == task.id) {
              newTasks.forEach((t: any) => {
                if (t.id == _task.id) {
                  t.dueDate = _task.dueDate;
                }
              });
            }
          });
        }
      });
      setTasks(newTasks);
    } else {
      setDoNotUpdateTasks(false);
    }
  }, [skinnyLists.length]);

  // useEffect(() => {
  //   if(changedDueDate != ''){
  //     handleChangeDate(changedDueDate);
  //   }
  // }, [changedDueDate]);
  /* We want to only allow requesters to set the priority */
  /* 12/17/19 -- We now want responders to be able to set priority as well */
  const handlePriorityClick = () => {
    setOpenPriority(!openPriority);
  };

  const handleDueDateChange = (e: any, dueDate: any) => {
    if (state.currentUser.isExternalUser) { return; }
    updateTask2({ ...props.task2, relativeDueDate: Number(e), dueDate: dueDate });
    setDueDateModal(false);
  };

  const handleDueDateClick = (e: any) => {
    if (state.currentUser.isExternalUser) { return; }

    if (!Number(hasSchedule)) {
      return;
    }
    setDueDateModal(true);
  };

  // conditions under which a user can see the recociliation box
  const reconciliationVisible = idx(state, s => {
    const { reconciliations } = task.list;
    const { companyPosition } = task;

    if (
      reconciliations &&
      (companyPosition == 'responder' || companyPosition == 'internal') &&
      Number(task.reconciliations)
    ) {
      return true;
    }

    if (
      reconciliations === '1' &&
      companyPosition == 'requester' &&
      task.responderDelivered
    ) {
      return true;
    }
  });

  const setTask = (prop: any) => {
    const newTask = { ...task, ...prop };
    updateTasks(newTask);
  };

  function updateTask3(task: any) {
    const cool = tasks.map((t: any) => ({ ...t }));
    const index = cool.findIndex((t: any) => t.id === task.id);
    cool[index] = task;

    setTasks(cool);
  }

  const [moreOwnersToRight, setMoreOwnersToRight] = useState(false)
  const [moreReviewersToRight, setMoreReviewersToRight] = useState(false)
  const moreOwnersRef = useRef<any>()
  const moreReviewersRef = useRef<any>()
  useEffect(() => {
    const sidePanel = document.getElementById('overview-pane');

    if (sidePanel) {
      const sidePanelPositions = sidePanel.getBoundingClientRect();
      if (moreOwnersRef.current) {
        const ownersPositions = moreOwnersRef.current.getBoundingClientRect();

        if (ownersPositions.left - 300 < sidePanelPositions.left && ownersPositions.right + 250 < sidePanelPositions.right) {
          setMoreOwnersToRight(true)
        } else {
          setMoreOwnersToRight(false)
        }
      }
      if (moreReviewersRef.current) {
        const reviewersPositions = moreReviewersRef.current.getBoundingClientRect();

        if (reviewersPositions.left - 300 < sidePanelPositions.left && reviewersPositions.right + 250 < sidePanelPositions.right) {
          setMoreReviewersToRight(true)
        } else {
          setMoreReviewersToRight(false)
        }
      }
    }
  }, [owners2, reviewers2])




  function getReferencePoint() {
    const sidePanel = document.getElementById('overview-pane');
    let rightPosition = window.innerWidth;
    if (sidePanel) {
      const sidePanelPositions = sidePanel.getBoundingClientRect();
      rightPosition = sidePanelPositions.right;
    }
    return rightPosition;
  }

  return (
    <Paper
      aria-labelledby="Overview"
      className={clsx(classes.root, value !== index && classes.invisible, classes.lastPadding)}
      elevation={0}
      style={(isExternalCreateProject || isInternalCreateProject) ? { height: 'calc(100% - 64px)', padding: 0, overflowX: 'hidden' } : { padding: 0 }}
      id="overview-pane"
    >
      {dueDateModal && (
        <DueDateSelect
          onAccept={handleDueDateChange}
          onClose={() => setDueDateModal(false)}
          task={task}
        />
      )}
      <div
        data-cy="description-container"
        style={{
          marginTop: '24px',
          paddingLeft: '16px',
          marginBottom: '24px',
          display: 'flex',
          paddingRight: 8,
        }}
      >
        <Typography
          className={clsx(classes.secondary, classes.label)}
          style={{ minWidth: '0px', paddingTop: '3px', flex: 1 }}
          variant="h6"
        >
          Description
        </Typography>
        <DescriptionInput
          description={task.description}
          updateDescription={updateDescription}
          id={task.id}
        />
      </div>

      <div className={classes.metaForm} style={{ alignItems: 'center' }}>
        {(
          <>
            <Typography
              className={clsx(classes.secondary, classes.label)}
              style={{ flex: 1 }}
              variant="h6"
            >
              Due date
            </Typography>

            <div data-cy="date-picker" style={{ flex: 3, marginLeft: '-12px' }}>
              <DatePicker
                classes={classes.datePicker}
                disabled={Boolean(Number(hasSchedule)) || state.currentUser.isExternalUser}
                onChange={handleChangeDate}
                onClick={handleDueDateClick}
                placeholder="Add due date"
                value={task.dueDate}
                dataCyInput="due-date-input"
                isExternalUser={state.currentUser.isExternalUser}
              />
            </div>
          </>
        )}
      </div>
      {/* <div className={classes.metaForm}>
        <Typography variant="h6" className={clsx(classes.secondary, classes.label)} style={{flex: 1}}>
          Actual Due Date
        </Typography>
        <div className={clsx(classes.flex, classes.owners)} style={{marginLeft: '9%'}}>{task && task.dueDate ? new Date(task.dueDate).toString() : ''}</div>
      </div> */}
      <div className={classes.metaBox}>
        <div className={classes.metaForm}>
          <Typography
            className={clsx(classes.secondary, classes.label)}
            style={{
              minWidth: '0px',
              // marginRight: '5px',
              paddingTop: '3px',
              flex: 1,
            }}
            variant="h6"
          >
            Owner
          </Typography>
          <div
            className={clsx(classes.flex, classes.owners)}
            data-cy="owners-container"
            style={{ flexWrap: 'wrap', flex: 3 }}
          >
            {owners2 &&
              owners2.slice(0, 5).map((owner: any, index: number) => {
                return owner.__typename === 'User' ? (
                  <NameLabel
                    key={index}
                    label={
                      owner.fullName ||
                      owner.displayName ||
                      owner.name ||
                      owner.email
                    }
                    logo={owner.profileUrl as string}
                    onClose={() => handleRemoveOwner(index, owner)}
                    type="user"
                    onCloseDataCy="remove-owner"
                    truncate={40}
                    isExternalUser={state.currentUser.isExternalUser}
                    style={{ cursor: state.currentUser.isExternalUser ? 'not-allowed' : 'pointer' }}
                  />
                ) : (
                  <NameLabel
                    key={index}
                    isExternalUser={state.currentUser.isExternalUser}
                    style={{ cursor: state.currentUser.isExternalUser ? 'not-allowed' : 'pointer' }}
                    label={owner.name}
                    onClose={() => handleRemoveOwner(index, owner)}
                    onCloseDataCy="remove-owner"
                    truncate={40}
                  />
                );
              })}

            {owners2 && owners2.length > 5 && (
              <div
                onMouseLeave={() => setMoreHover(false)}
                onMouseOver={() => setMoreHover(true)}
                style={{ position: 'relative', }}
              >
                <NameLabel
                  isExternalUser={state.currentUser.isExternalUser}
                  label={`+${owners2.length - 5}`}
                  style={{ height: 26, cursor: state.currentUser.isExternalUser ? 'not-allowed' : 'pointer' }}
                  ref={moreOwnersRef}
                />
                {moreHover && (
                  <Paper
                    className={classes.morePaper}
                    elevation={0}
                    onMouseLeave={() => setMoreHover(false)}
                    onMouseOver={() => setMoreHover(true)}
                    style={!moreOwnersToRight ? { right: 3 } : {}}
                  >
                    {owners2.slice(5).map((owner: any, index: number) => {
                      return owner.__typename === 'User' ? (
                        <NameLabel
                          key={index}
                          label={owner.fullName}
                          logo={owner.profileUrl as string}
                          onClose={() => handleRemoveOwner(index, owner)}
                          type="user"
                          truncate={15}
                          labelStyle={{ whiteSpace: 'nowrap' }}
                        />
                      ) : (
                        <NameLabel
                          key={index}
                          label={owner.name}
                          onClose={() => handleRemoveOwner(index, owner)}
                          truncate={15}
                          labelStyle={{ whiteSpace: 'nowrap' }}
                        />
                      );
                    })}
                  </Paper>
                )}
              </div>
            )}

            <InviteForm
              adminPlus={adminPlus}
              formType="owners"
              handleAdd={handleAddOwner}
              id={task.id}
              owners={owners}
              owners2={owners2}
              externalCompanyId={props.externalCompanyId}
              getReferencePoint={getReferencePoint()}
              setOwners={setOwners}
              setTasks={setTasks}
              additionalNewOwners={props.additionalNewOwners ? props.additionalNewOwners : undefined}
              setAdditionalNewOwners={props.setAdditionalNewOwners ? props.setAdditionalNewOwners : undefined}
              size="small"
              tasks={tasks}
              whatTheHell
              data-cy="add-owner"
            />
          </div>
        </div>

        <div className={classes.metaForm}>
          <Typography
            className={clsx(classes.secondary, classes.label)}
            style={{
              minWidth: '0',
              // marginRight: '6px',
              paddingTop: '3px',
              flex: 1,
            }}
            variant="h6"
          >
            Reviewer
          </Typography>
          <div
            className={clsx(classes.flex, classes.owners)}
            data-cy="reviewers-container"
            style={{ flexWrap: 'wrap', flex: 3 }}
          >
            {reviewers2 &&
              reviewers2.slice(0, 5).map((reviewer: any, index: number) => {
                return reviewer.__typename === 'User' || reviewer.fullName ? (
                  <NameLabel
                    key={index}
                    label={
                      reviewer.fullName ||
                      reviewer.displayName ||
                      reviewer.email
                    }
                    logo={reviewer.profileUrl as string}
                    onClose={() => handleRemoveReviewer(index, reviewer)}
                    type="user"
                    onCloseDataCy="remove-reviewer"
                    isExternalUser={state.currentUser.isExternalUser}
                    style={{ cursor: state.currentUser.isExternalUser ? 'not-allowed' : 'pointer' }}
                  />
                ) : (
                  <NameLabel
                    key={index}
                    label={reviewer.name}
                    onClose={() => handleRemoveReviewer(index, reviewer)}
                    onCloseDataCy="remove-reviewer"
                    isExternalUser={state.currentUser.isExternalUser}
                    style={{ cursor: state.currentUser.isExternalUser ? 'not-allowed' : 'pointer' }}
                  />
                );
              })}

            {reviewers2 && reviewers2.length > 5 && (
              <div
                onMouseLeave={() => setMoreHover2(false)}
                onMouseOver={() => setMoreHover2(true)}
                style={{ position: 'relative' }}
              >
                <NameLabel
                  label={`+${reviewers2.length - 5}`}
                  style={{ height: 26 }}
                  ref={moreReviewersRef}
                />
                {moreHover2 && (
                  <Paper
                    className={classes.morePaper}
                    elevation={0}
                    onMouseLeave={() => setMoreHover2(false)}
                    onMouseOver={() => setMoreHover2(true)}
                    style={!moreReviewersToRight ? { right: 3 } : {}}
                  >
                    {reviewers2.slice(5).map((reviewer: any, index: number) => {
                      return reviewer.__typename === 'User' ? (
                        <NameLabel
                          key={index}
                          label={reviewer.fullName}
                          logo={reviewer.profileUrl as string}
                          onClose={() => handleRemoveReviewer(index, reviewer)}
                          type="user"
                          truncate={15}
                          labelStyle={{ whiteSpace: 'nowrap' }}
                        />
                      ) : (
                        <NameLabel
                          key={index}
                          label={reviewer.name}
                          onClose={() => handleRemoveReviewer(index, reviewer)}
                          truncate={15}
                          labelStyle={{ whiteSpace: 'nowrap' }}
                        />
                      );
                    })}
                  </Paper>
                )}
              </div>
            )}
            <InviteForm
              adminPlus={adminPlus}
              formType="reviewers"
              handleAdd={handleAddReviewer}
              id={task.id}
              owners={reviewers}
              owners2={reviewers2}
              externalCompanyId={props.externalCompanyId}
              getReferencePoint={getReferencePoint()}
              setOwners={setReviewers}
              setTasks={setTasks}
              additionalNewOwners={props.additionalNewOwners ? props.additionalNewOwners : undefined}
              setAdditionalNewOwners={props.setAdditionalNewOwners ? props.setAdditionalNewOwners : undefined}
              size="small"
              tasks={tasks}
              whatTheHell
              data-cy="add-reviewer"
            />
          </div>
        </div>
      </div>
      {!(isInternalCreateProject || isExternalCreateProject) &&
        <>
          <TaskEntityInput task={task} tasks={tasks} setTasks={setTasks} multiTasks={[task.id]} />
          <div
            data-cy="section-container"
            style={{
              marginTop: '24px',
              paddingLeft: '16px',
              display: 'flex',
            }}
          >
            <Typography
              className={clsx(classes.secondary, classes.label)}
              style={{
                minWidth: '0px',
                // marginRight: '6px',
                paddingTop: '3px',
                flex: 1,
                display: 'flex',
                alignItems: 'center',
              }}
              variant="h6"
            >
              Section
            </Typography>

            <div style={{ flex: 3 }}>
              <div className={clsx(classes.taskSection)} style={{ display: 'flex' }}>
                <CreatableAutoSelect
                  LeftIcon={() => (
                    <FolderOutlinedIcon
                      style={{
                        color: idx(task2, (t: any) => task.section)
                          ? ' #606060'
                          : '#AFAFAF',
                        fontSize: '20px',
                        marginTop: '9px',
                        marginRight: '10px',
                        cursor: 'pointer',
                      }}
                    />
                  )}
                  containerClass={classes.containerClass}
                  onChange={handleChangeSection}
                  options={sections}
                  placeholder="Add Section..."
                  selectorClass={classes.selectorClass}
                  value={{
                    value: idx(task2, (t: any) => task.section.id),
                    label: idx(task2, (t: any) => task.section.name),
                  }}
                />
              </div>
            </div>
          </div>

          {/* {reconciliationVisible && (
            <ReconciliationSection task={task} updateTask={updateTask3} />
          )} */}

          <div
            data-cy="project-container"
            style={{
              marginTop: '24px',
              paddingLeft: '16px',
              marginBottom: '5%',
              display: 'flex',
            }}
          >
            <Typography
              className={clsx(classes.secondary, classes.label)}
              style={{
                minWidth: '0px',
                // marginRight: '6px',
                paddingTop: '3px',
                flex: 1,
                display: 'flex',
                alignItems: 'center',
              }}
              variant="h6"
            >
              Project
            </Typography>

            <div style={{ flex: 3 }}>
              <div
                className={clsx(classes.taskSection)}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <ViewCompactOutlinedIcon
                  style={{
                    // color: idx(task2, (t: any) => task.section.id)
                    //   ? ' #606060'
                    //   : '#AFAFAF',
                    color: ' #606060',
                    fontSize: '21px',
                    // marginTop: '9px',
                    marginRight: '10px',
                    cursor: 'pointer',
                  }}
                />
                <Typography style={{ color: ' #606060', cursor: 'pointer' }}>
                  {idx(task, (t: any) => t.list.name)}
                </Typography>
              </div>
            </div>
          </div>
        </>
      }
      {!isExternalCreateProject &&
        <div
          data-cy="project-container"
        // style={{
        //   marginTop: '24px',
        //   paddingLeft: '16px',
        //   marginBottom: '5%',
        //   display: 'flex',
        // }}
        >
          {/* {multiTasks.length > 1 ? (
            <MultiTaskDependency
              multiTasks={multiTasks}
              lists={lists}
              task={task}
              skinnyLists={skinnyLists}
              setSkinnyLists={setSkinnyLists}
            />
          ) : ( */}
          <TaskDependency
            lists={lists}
            task={task}
            skinnyLists={skinnyLists}
            setSkinnyLists={setSkinnyLists}
            dependencies={dependencies}
            setDependencies={setDependencies}
            onDependencyClick={props.onDependencyClick}
            isCreateProject={isInternalCreateProject || isExternalCreateProject}
          />
          {/* )} */}

          {/* <Typography
            className={clsx(classes.secondary, classes.label)}
            style={{
              minWidth: '0px',
              // marginRight: '6px',
              paddingTop: '3px',
              flex: 1,
              display: 'flex',
              alignItems: 'center',
            }}
            variant="h6"
          >
            Dependencies
          </Typography> */}
          {/* <Typography
            className={clsx(classes.secondary, classes.label)}
            style={{
              minWidth: '0px',
              // marginRight: '6px',
              paddingTop: '3px',
              flex: 1,
              display: 'flex',
              alignItems: 'center',
            }}
            variant="h6"
          >
            Dependencies
          </Typography> */}

          {/* <div style={{flex: 3}}>
            <div
              className={clsx(classes.taskSection)}
              style={{display: 'flex', alignItems: 'center'}}
              onClick={toggleTaskDependency}
            >
              <TaskDependency />
            </div>
            <div
              className={clsx(classes.taskSection)}
              style={{display: 'flex', alignItems: 'center'}}
              // onClick={toggleTaskDependency}
            >
              <TaskDependency />
            <AttachFile
                style={{
                  color: idx(task2, (t: any) => task.section.id)
                    ? '#757575'
                    : '#AFAFAF',
                  fontSize: '21px',
                  // marginTop: '9px',
                  marginRight: '10px',
                  cursor: 'pointer',
                }}
              />
              <Typography style={{color: '#AFAFAF', cursor: 'pointer'}}>
                Link Tasks
              </Typography>
              {!openTaskDependency && <TaskDependency />}
            </div>
          </div> */}
        </div>
      }
      {!(isInternalCreateProject || isExternalCreateProject) && <OverviewNotifications tasks={tasks} taskId={task.id} />}
    </Paper>
  );
}

export default withRouter(OverviewPane);

function DescriptionInput({ description, updateDescription, id }: any) {
  const [displayedDescription, setDisplayedDescription] = useState<string>('');

  useEffect(() => {
    if (description) {
      setDisplayedDescription(description)
    } else {
      setDisplayedDescription('')
    }
  }, [id, description])

  function handleChange(e: any) {
    setDisplayedDescription(e.target.value);
  }

  function determineUpdate() {
    const descriptionChanged = description !== displayedDescription;
    if (displayedDescription && descriptionChanged) {
      updateDescription(displayedDescription);
    }
  }

  const descriptionInputRef = useRef(null);
  function handleKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      determineUpdate();
      idx(descriptionInputRef, (d: any) => d.current.blur());
    }
  }

  function handleBlur() {
    determineUpdate();
  }

  function handleClickAway() {
    determineUpdate();
  }

  const classes = useStyles();
  return (
    <div className={classes.descriptionInputContainer}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ marginTop: 8 }} position="start">
                <BallotOutlinedIcon
                  style={{
                    color: Boolean(description) ? '#606060' : '#AFAFAF',
                    fontSize: '20px',
                  }}
                />
              </InputAdornment>
            ),
          }}
          multiline
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className={classes.descriptionPlaceholderInput}
          inputRef={descriptionInputRef}
          inputProps={{
            style: {
              color: Boolean(description) ? '#606060' : '#AFAFAF',
              fontSize: '12px',
              fontFamily: 'Montserrat',
              paddingRight: '18px',
              fontWeight: 500,
              alignItems: 'flex-start',
            },
            'data-cy': 'side-panel-task-description-input',
          }}
          value={displayedDescription}
          placeholder="Add Description"
          onChange={handleChange}
        />
      </ClickAwayListener>
    </div>
  );
}
