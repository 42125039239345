import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import idx from 'idx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';

import InputForm from '../../../../common/ListInputForm';
import PriorityForm from '../../../../common/PriorityForm';
import CheckBox from './components/CheckBox';
import Dropdown from './components/Dropdown';

import { useLazyDetailedListCopyPage } from '../../../../../graphql/queries/UserListsCopyPage';

import * as cs from '../../../../../constants/theme';
import {
  TemplateLists_templateLists,
  TemplateLists_templateLists_tasks,
} from '../../../../../graphql/queries/__generated__/TemplateLists';
import {useStateContext } from '../../../../../store';
import { useUserListsLight } from '../../../../../graphql/queries/UserLists';
import CircularLoader from '../../../../common/CircularLoader';

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      height: 'calc(100vh - 144px)',
      padding: '0px 50px 0px 50px',
      borderBottom: '1px solid #D8D8D8',
      overflow: 'auto',
    },
    footer: {
      height: '60px',
      padding: '0px calc((100% - 1380px) / 2) 0px calc((100% - 1380px) / 2)',
    },
    title: {
      height: '42px',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    grow: {
      flexGrow: 1,
    },
    selection: {
      padding: '6px 12px',
      color: cs.COLORS.primary,
      border: `2px solid ${cs.COLORS.primary}`,
      borderRadius: '3px',
      marginRight: '12px',
    },
    content: {
      display: 'flex',
      marginTop: '10px',
      height: 'calc(100% - 100px)',
    },
    textFlow: {
      display: 'inline-block',
      width: 'fit-content',
      maxWidth: 'calc(80%)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    stickyHeader: {
      position: 'sticky',
      top: '0px',
      backgroundColor: '#FFFFFF',
      zIndex: 1,
    },
    doubleStickyHeader: {
      position: 'sticky',
      top: '0px',
      backgroundColor: '#FFFFFF',
      zIndex: 1,
      width: 60,
      padding: 0,
    },
    doubleFirstStickyHeader: {
      position: 'sticky',
      top: '0px',
      backgroundColor: '#FFFFFF',
      zIndex: 1,
      width: 60,
      borderLeft: '1px solid rgb(224, 224, 224)',
      padding: 0,
      borderRadius: 3
    },
    doubleLastStickyHeader: {
      position: 'sticky',
      top: '0px',
      backgroundColor: '#FFFFFF',
      zIndex: 1,
      width: 60,
      borderRight: '1px solid rgb(224, 224, 224)',
      paddingRight: '0 !important',
      borderRadius: 3
    },
    topHeader: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    topCheckHeader: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      borderTop: '1px solid rgb(224, 224, 224)',
      paddingTop: 10
    },
    bottomHeader: {
      height: '50px',
      display: 'flex',
      alignItems: 'center'
    },
    bottomCheckHeader: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    input: {
      display: 'block',
      width: '100%',
      marginTop: '6px',
      color: '#606060',
      fontFamily: cs.FONT.family,
      fontWeight: cs.FONT.weight.regular,
      fontSize: cs.FONT.size.xs,
      textTransform: 'none',
      border: 'none',
      '& label': {
        color: '#606060',
        fontFamily: cs.FONT.family,
        fontWeight: cs.FONT.weight.regular,
        fontSize: cs.FONT.size.xs,
      },
      '&:selected': {
        color: '#3A84FF',
      },
      '& input::placeholder': {
        fontSize: '12px',
      },
      '& div': {
        width: '100%',
      },
      '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: 'none',
      },
    },
    checkCell: {
      width: 60,
      padding: '0 0 0 0px',
    },
    flexCenter: {
      display: 'flex',
      justifyContent: 'center'
    },
    firstCellBody: {
      width: 60,
      padding: '0 0 0 0px',
      borderLeft: '1px solid rgb(224, 224, 224)'
    },
    lastCellBody: {
      width: 60,
      padding: '0 0 0 0px',
      borderRight: '1px solid rgb(224, 224, 224)'
    },
    deleteIcon: {
      cursor: 'pointer',
    },
    addTaskButton: {
      marginLeft: '75px',
    }
  })
);

interface CreateTemplateStepProps {
  setSelectedListTasks: any;
  selectedTemplate: TemplateLists_templateLists;
  setSelectedTemplate: React.Dispatch<
    React.SetStateAction<TemplateLists_templateLists>
  >;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  updateSelectedList: any;
  selectedList: any;
  handleDelete: Function;
}

export default function CreateTemplateStep(props: CreateTemplateStepProps) {
  const {
    selectedTemplate,
    setSelectedTemplate,
    setStep,
    updateSelectedList,
    selectedList,
    handleDelete,
  } = props;
  const classes = useStyles();

  /* Used to scroll table down on new task addition */
  const scrollEl = useRef(null);
  const [selected, setSelected] = useState<number[]>([]);
  const [listsLite, setListsLite] = useState<any[]>([]);
  const [listId, setListId] = useState<string>('');
  const [loadingDetailedList, setLoadingDetailedList] = useState<boolean>(false);

  const state = useStateContext()
  
  const preSelectedListId = idx(state, s => s.currentUser.filters.listPageSelectedLists[0])
  const [detailedListCopyPage] = useLazyDetailedListCopyPage();
  const {loading: loadingListsLite, data: dataListsLite, error: errorListsLite} = useUserListsLight({}) 

  // Get the lists lite for the dropdown
  useEffect(() => {
    const lists = idx(dataListsLite, data => (data as any).userLists.lists);
    if (loadingListsLite || !lists) return;
    
    const activeLists = lists.filter((l: any) => !l.isArchived).filter((l: any) => !l.isDeleted)
    setListsLite(activeLists);
  }, [loadingListsLite, idx(dataListsLite, data => (data as any).userLists.lists)]);

  // When the lists lite exist, set the listId to the selected list by the user from filters
  useEffect(() => {
    if (listsLite.length) {
      const theList = listsLite.find((l: any) => l.id == preSelectedListId);
      if (theList) {
        setListId(preSelectedListId)
      }
    }
  },[listsLite, preSelectedListId])

  // Fetch the detailed list info for the selected list Id
  useEffect(() => {
    if (listId) {
      setLoadingDetailedList(true);
      detailedListCopyPage({
        variables: { id: listId }
      }).then((res: any) => {
        setLoadingDetailedList(false);
        if (res?.data?.list) {
          updateSelectedList(res.data.list);
        }
      })
    }
  }, [listId]);

  useEffect(() => {
    setSelected([]);
  }, [selectedList.tasks]);

  const updateListTasks = (listId: any) => {}

  const handleGlobalItemClick = (event: any, type: string) => {
    const newTasks = selectedList.tasks.map((task: any, index: number) => {
      /* If a task does not have an id, it was added on the screen, and should
       * not be a part of any mass-boolean updating */
      if (task.id) {
        task[type] = !task[type]
      }
    });
    setSelectedTemplate({
      ...selectedTemplate,
      tasks: newTasks,
    });
  };

  const handleOneItemClick = (event: React.MouseEvent<unknown>, id: any, type: string) => {
    let newTasks: any | null =
      selectedList.tasks;

    switch (type) {
      case 'owner':
        newTasks[id] = Object.assign({}, newTasks[id], {
          ['owner']: !newTasks[id].owner,
        });
        break;
      case 'dueDateCopy':
        newTasks[id] = Object.assign({}, newTasks[id], {
          ['dateDateCopy']: !newTasks[id].dueDateCopy,
        });
        break;
      case 'files':
        newTasks[id] = Object.assign({}, newTasks[id], {
          ['files']: !newTasks[id].files,
        });
        break;
      case 'internalMessages':
        newTasks[id] = Object.assign({}, newTasks[id], {
          ['internalMessages']: !newTasks[id].internalMessages,
        });
        break;
      case 'externalMessages':
        newTasks[id] = Object.assign({}, newTasks[id], {
          ['externalMessages']: !newTasks[id].externalMessages,
        });
        break;
      case 'statusCopy':
        newTasks[id] = Object.assign({}, newTasks[id], {
          ['statusCopy']: !newTasks[id].statusCopy,
        });
        break;
      default:
        break;
    }

    setSelectedTemplate({
      ...selectedTemplate,
      tasks: newTasks,
    });
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let newTasks: TemplateLists_templateLists_tasks[] | null =
      selectedList.tasks;
    const { name, value } = event.target;

    if (newTasks) {
      newTasks[index] = Object.assign({}, newTasks[index], {
        [name]: value,
      });

      props.setSelectedListTasks(newTasks);
    }
  };

  const handleChangeSection = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let newTasks: TemplateLists_templateLists_tasks[] | null =
      selectedList.tasks;
    const { value } = event.target;

    if (newTasks) {
      const newSection = newTasks[index].section

      if (newSection && newSection.name !== null) {
        newSection.name = value;
        newTasks[index].section = newSection;
      } else {
        newTasks[index].section = { name: value, id: ' ', __typename: 'TaskSection' };
      }

      props.setSelectedListTasks(newTasks);

    }
  };

  const handleChangePriority = (newValue: string, index: number) => {
    let newTasks: TemplateLists_templateLists_tasks[] | null =
      selectedList.tasks;

    if (newTasks) {
      newTasks[index].priority = newValue;
    }
    props.setSelectedListTasks(newTasks);
  };

  /* When the user hits add task, we add a blank task to the SOT array */
  const handleAddTask = () => {
    // take the existing selectedList.tasks array, and add a new task.
    // const newTasks = JSON.parse(JSON.stringify(selectedList.tasks));
    const newTask = {
      name: '',
      description: '',
      priority: 'medium',
      section: '',
      owner: null,
      dueDateCopy: null,
      files: null,
      internalMessages: null,
      externalMessages: null,
      statusCopy: null
    };
    const newTasks = [...selectedList.tasks, newTask];
    props.setSelectedListTasks(newTasks);
    // move scroll of the task table to the lowest point to view the new task
    setTimeout(() => {
      const table = idx(scrollEl, s => s.current);
      if (table) {
        (table as any).scrollTop = (table as any).scrollHeight
      }
    }, 300);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (event.target.checked && selectedList && selectedList.tasks) {
      const newSelecteds = selectedList.tasks.map(
        (tasks: TemplateLists_templateLists_tasks, index: number) => index
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const renderGlobalCheckbox = (type: string) => {
    const rowCount = selectedList && selectedList.tasks ? selectedList.tasks.length : 0;
    const globalValue = selectedList && selectedList.tasks && selectedList.tasks.filter((task: any) => task[type]) || []

    return (
      <CheckBox
        data-cy={'global-' + type}
        indeterminate={globalValue.length > 0 && globalValue.length < rowCount}
        checkedValue={globalValue.length === rowCount && rowCount > 0}
        onChange={(event: React.MouseEvent<unknown>) =>
          handleGlobalItemClick(event, type)
        }
      />
    );
  };

  const renderCheckbox = () => {
    const rowCount =
      selectedList && selectedList.tasks
        ? selectedList.tasks.length
        : 0;

    return (
      <CheckBox
        indeterminate={selected.length > 0 && selected.length < rowCount}
        checkedValue={selected.length > 0 && selected.length === rowCount}
        onChange={handleSelectAllClick}
      />
    );
  };

  return (
    <div>
      <div className={classes.body}>
        <div className={clsx(classes.flex, classes.title)}>
          <Typography variant="h2">Copy Project:&nbsp;</Typography>
          <Dropdown updateListTasks={updateListTasks} data={listsLite} listId={listId} setListId={setListId} />
          <div className={classes.grow} />
          {selected.length > 0 && (
            <Typography variant="h3" className={classes.selection} style={{ textTransform: 'none' }}>
              {selected.length} Task(s) Selected
            </Typography>
          )}
          {selected.length > 0 && (
            <DeleteIcon className={classes.deleteIcon} onClick={() => handleDelete(selected)} />
          )}
        </div>
        <div className={classes.content}>
          <div ref={scrollEl} style={{ overflow: 'auto' }}>
            { loadingDetailedList ?
                <div className='w-[90vw] flex items-center justify-center'>
                  <CircularLoader height='35vh' />
                </div> 
              : 
              <Table style={{ tableLayout: 'fixed', borderCollapse: 'separate' }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      padding="checkbox"
                      className={classes.stickyHeader}
                    >
                      <div className={classes.topHeader} />
                      <div className={classes.bottomHeader}>
                        {renderCheckbox()}
                      </div>
                    </TableCell>
                    <TableCell
                      className={classes.stickyHeader}
                      style={{ width: '100px' }}
                    >
                      <div className={classes.topHeader} />
                      <div className={classes.bottomHeader}>Task</div>
                    </TableCell>
                    <TableCell
                      className={classes.stickyHeader}
                      style={{ width: '150px' }}
                    >
                      <div className={classes.topHeader} />
                      <div className={classes.bottomHeader}>Section</div>
                    </TableCell>
                    <TableCell
                      className={classes.stickyHeader}
                      style={{ width: '150px' }}
                    >
                      <div className={classes.topHeader} />
                      <div className={classes.bottomHeader}>Priority</div>
                    </TableCell>
                    <TableCell
                      className={classes.stickyHeader}
                      style={{ width: '150px' }}
                    >
                      <div className={classes.topHeader} />
                      <div className={classes.bottomHeader}>Description</div>
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                      className={classes.doubleFirstStickyHeader}
                    >
                      <div className={classes.topCheckHeader}>Owner / Reviewer</div>
                      <div data-cy='global-owner-container' className={classes.bottomCheckHeader}>
                        {renderGlobalCheckbox('owner')}
                      </div>
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                      className={classes.doubleStickyHeader}
                    >
                      <div className={classes.topCheckHeader}>Files</div>
                      <div className={classes.bottomCheckHeader}>
                        {renderGlobalCheckbox('files')}
                      </div>
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                      className={classes.doubleStickyHeader}
                    >
                      <div className={classes.topCheckHeader}>Internal Messages</div>
                      <div className={classes.bottomCheckHeader}>
                        {renderGlobalCheckbox('internalMessages')}
                      </div>
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                      className={classes.doubleStickyHeader}
                    >
                      <div className={classes.topCheckHeader}>Public Messages</div>
                      <div className={classes.bottomCheckHeader}>
                        {renderGlobalCheckbox('externalMessages')}
                      </div>
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                      className={classes.doubleStickyHeader}
                    >
                      <div className={classes.topCheckHeader}>Task Status</div>
                      <div className={classes.bottomCheckHeader}>
                        {renderGlobalCheckbox('statusCopy')}
                      </div>
                    </TableCell>
                    <TableCell
                      padding="checkbox"
                      className={classes.doubleLastStickyHeader}
                    >
                      <div className={classes.topCheckHeader}>Due Date</div>
                      <div className={classes.bottomCheckHeader}>
                        {renderGlobalCheckbox('dueDateCopy')}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {selectedList.tasks &&
                    selectedList.tasks.sort((t: any, t1: any) => Number(t.id) - Number(t1.id))
                      .map(
                        (item: any, index: number) => {
                          const isSelected = selected.indexOf(index) !== -1;
                          return (
                            <TableRow key={index}>
                              <TableCell padding="checkbox">
                                <CheckBox
                                  checkedValue={isSelected}
                                  onClick={(e: React.MouseEvent<unknown>) =>
                                    handleClick(e, index)
                                  }
                                />
                              </TableCell>
                              <TableCell data-cy='name-input'>
                                <InputForm
                                  name="name"
                                  className={classes.textFlow}
                                  value={item.name ? item.name : ''}
                                  placeholder="Add task..."
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => handleChange(event, index)}
                                />
                              </TableCell>
                              <TableCell data-cy='section-input'>
                                <InputForm
                                  name="section"
                                  className={classes.textFlow}
                                  value={
                                    item.section
                                      ? item.section.name
                                        ? item.section.name
                                        : ''
                                      : ''
                                  }
                                  placeholder="Add section..."
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => handleChangeSection(event, index)}
                                />
                              </TableCell>
                              <PriorityForm
                                value={item.priority as string}
                                onChange={(newValue: string) =>
                                  handleChangePriority(newValue, index)
                                }
                              />
                              <TableCell data-cy='description-input'>
                                <InputForm
                                  name="description"
                                  className={classes.textFlow}
                                  value={item.description ? item.description : ''}
                                  placeholder="Add description..."
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => handleChange(event, index)}
                                />
                              </TableCell>

                              <TableCell
                                className={classes.firstCellBody}
                              >
                                <div className={classes.flexCenter}>
                                  <CheckBox
                                    disabled={item.owner === null}
                                    checkedValue={item.owner}
                                    onClick={(e: React.MouseEvent<unknown>) =>
                                      handleOneItemClick(e, index, 'owner')
                                    }
                                  />
                                </div>
                              </TableCell>

                              <TableCell
                                className={classes.checkCell}
                              >
                                <div className={classes.flexCenter}>
                                  <CheckBox
                                    disabled={item.files === null}
                                    checkedValue={item.files}
                                    onClick={(e: React.MouseEvent<unknown>) =>
                                      handleOneItemClick(e, index, 'files')
                                    }
                                  />
                                </div>
                              </TableCell>
                              <TableCell
                                className={classes.checkCell}
                              >
                                <div className={classes.flexCenter}>
                                  <CheckBox
                                    disabled={item.internalMessages === null}
                                    checkedValue={item.internalMessages}
                                    onClick={(e: React.MouseEvent<unknown>) =>
                                      handleOneItemClick(e, index, 'internalMessages')
                                    }
                                  />
                                </div>
                              </TableCell>
                              <TableCell
                                className={classes.checkCell}
                              >
                                <div className={classes.flexCenter}>
                                  <CheckBox
                                    disabled={item.externalMessages === null}
                                    checkedValue={item.externalMessages}
                                    onClick={(e: React.MouseEvent<unknown>) =>
                                      handleOneItemClick(e, index, 'externalMessages')
                                    }
                                  />
                                </div>
                              </TableCell>
                              <TableCell
                                className={classes.checkCell}
                              >
                                <div className={classes.flexCenter}>
                                  <CheckBox
                                    disabled={item.statusCopy === null}
                                    checkedValue={item.statusCopy}
                                    onClick={(e: React.MouseEvent<unknown>) =>
                                      handleOneItemClick(e, index, 'statusCopy')
                                    }
                                  />
                                </div>
                              </TableCell>
                              <TableCell
                                className={classes.lastCellBody}
                              >
                                <div className={classes.flexCenter}>
                                  <CheckBox
                                    disabled={item.dueDateCopy === null}
                                    checkedValue={item.dueDateCopy}
                                    onClick={(e: React.MouseEvent<unknown>) =>
                                      handleOneItemClick(e, index, 'dueDateCopy')
                                    }
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                </TableBody>
              </Table>
            }
            {/* Add space at the bottom of the table to select priority */}
            <div style={{ height: 130, width: 50 }} />
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div
          className={classes.flex}
          style={{ paddingTop: '12px' }}
        >
          <Button className={classes.addTaskButton} onClick={handleAddTask}>+ Add task</Button>
          <div className={classes.grow} />
          <Button variant="contained" onClick={() => setStep(1)}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}
