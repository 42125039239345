import React, { useEffect, useRef } from 'react';
import idx from 'idx';
import clsx from 'clsx';

import { FileUploadProperties, FileUploadWorkerProperties, useFileUploadWorker } from '../../../hooks/useFileUploadWorker';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import {
  Paper,
  Typography,
  Popper,
  ClickAwayListener
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

import useArchiveFile from '../../../graphql/mutations/ArchiveFile';
import useUpdateFileCheckout from '../../../graphql/mutations/UpdateFileCheckout';
import { useDispatchContext } from '../../../store';

const popperStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeFileOptionsRoot: {
      width: '180px',
      border: '1px solid #D8D8D8',
    },
    taskFilesToggleRoot: {
      width: '200px',
      border: '1px solid #D8D8D8',
    },
    items: {
      fontSize: '12px',
      color: '#606060',
      font: 'normal normal normal 12px/24px Montserrat',
      width: '100%',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#EBF2FF',
      }
    },
    typography: (props: any) => ({
      cursor: props.cursor
    }),
    itemDiv: (props: any) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '29px',
      cursor: props.cursor
    }),
    iconTypography: (props: any) => ({
      display: 'flex',
      width: '16px',
      marginRight: '10px',
      paddingLeft: '5px',
      cursor: props.cursor
    }),
    icon: (props: any) => ({
      fontSize: '18px',
      color: '#606060',
      cursor: props.cursor
    })
  })
);

/**
 * Reusable `ActiveFileOptionsPopper` component.
 * This component is using the `TailwindCSS` design pattern.
 *
 * @return {React.JSXElementConstructor} The `ActiveFileOptionsPopper` component.
 */

export default function ActiveFileOptionsPopper(props: any) {
  const { 
    file, 
    task, 
    tasks, 
    setTasks, 
    updateTaskStatus, 
    updateStatusAfterUpload, 
    anchorEl, 
    onClose, 
    updateTask, 
    updateTaskInCache,
    archivedFiles, 
    setArchivedFiles, 
    currentPage, 
    lists, 
    setLists, 
    isExternalUser,
    onUpdateFile
  } = props;

  const styleProps = { cursor: isExternalUser ? 'not-allowed' : 'pointer' };
  const classes = popperStyles(styleProps);

  const dispatch = useDispatchContext();

  const [deleteFile, { data: deleteFileData, error: deleteFileError, loading: deleteFileLoading }] = useArchiveFile({ fileId: file?.id });
  const [checkOutFile, { data: checkOutFileData, error: checkOutFileError, loading: checkOutFileLoading }] = useUpdateFileCheckout({
    fileVersionId: file?.id,
    isCheckedOut: true,
  });
  const [checkInFile, { data: checkInFileData, error: checkInFileError, loading: checkInFileLoading }] = useUpdateFileCheckout({
    fileVersionId: file?.id,
    isCheckedOut: false,
  });
  const fileUploadWorker = useFileUploadWorker();

  const inputFile = useRef(null);
  const open = Boolean(anchorEl);

  const handleDeleteFileClick = () => {
    if (isExternalUser) { return; }
    deleteFile()
  }

  const handleUpdateFileVersionClick = () => {
    if (isExternalUser) { return; }
    const fileInput: any = idx(inputFile, inputFile => inputFile.current);
    if (fileInput) fileInput.click();
  }

  const handleFileCheckOut = () => {
    if (isExternalUser) { return; }
    checkOutFile();
  }

  const handleFileCancelCheckout = () => {
    if (isExternalUser) { return; }
    checkInFile();
  }

  const updateFile = async (e: any) => {
    const formattedFiles: FileUploadProperties[] = [
      {
        file: e.target.files[0],
        taskId: task.id,
        fileVersionId: file.id
      }
    ]

    const fileUploadWorkerProperties: FileUploadWorkerProperties = {
      files: formattedFiles,
      uploadType: 'task',
      selectedTask: task,
      updateTask: updateTask,
      updateTaskStatus: updateTaskStatus,
      afterUpload: (task: any, fileUploadStatusState: any) => updateStatusAfterUpload && updateStatusAfterUpload(task, fileUploadStatusState),
    }

    fileUploadWorker(fileUploadWorkerProperties);

    onClose()
  }

  useEffect(() => {
    if (deleteFileLoading) {
      return;
    }

    if (deleteFileData && !deleteFileError) {

      if (currentPage === "Task") {

        const returnedData = deleteFileData as any;
        if (returnedData?.archiveFile?.success && returnedData?.archiveFile?.tasks && returnedData?.archiveFile?.tasks?.length) {
          const updatedTaskFiles = returnedData.archiveFile.tasks[0].files;
          const returnedTaskData = returnedData.archiveFile.tasks[0];

          updateTaskInCache({
            ...task,
            primaryTotal: returnedTaskData.primaryTotal,
            secondaryTotal: returnedTaskData.secondaryTotal,
            primaryFileReconSources: returnedTaskData.primaryFileReconSources,
            primaryFileReconSourcesTotal: returnedTaskData.primaryFileReconSourcesTotal,
            secondaryFileReconSources: returnedTaskData.secondaryFileReconSources,
            secondaryFileReconSourcesTotal: returnedTaskData.secondaryFileReconSourcesTotal,
            reconcilingItems: returnedTaskData.reconcilingItems,
            reconcilingItemTotal: returnedTaskData.reconcilingItemTotal,
            isReconciled: returnedTaskData.isReconciled,
            reconDifference: returnedTaskData.reconDifference
          });

          updateTask({
            ...task,
            files: updatedTaskFiles,
            primaryTotal: returnedTaskData.primaryTotal,
            secondaryTotal: returnedTaskData.secondaryTotal,
            primaryFileReconSources: returnedTaskData.primaryFileReconSources,
            primaryFileReconSourcesTotal: returnedTaskData.primaryFileReconSourcesTotal,
            secondaryFileReconSources: returnedTaskData.secondaryFileReconSources,
            secondaryFileReconSourcesTotal: returnedTaskData.secondaryFileReconSourcesTotal,
            reconcilingItems: returnedTaskData.reconcilingItems,
            reconcilingItemTotal: returnedTaskData.reconcilingItemTotal,
            isReconciled: returnedTaskData.isReconciled,
            reconDifference: returnedTaskData.reconDifference
          });

          dispatch({
            type: 'SET_PRIMARY_TOTAL',
            primaryTotal: { balance: returnedData.archiveFile.tasks[0].primaryTotal, task_id: task.id },
          });

          dispatch({
            type: 'SET_SECONDARY_TOTAL',
            secondaryTotal: { balance: returnedData.archiveFile.tasks[0].secondaryTotal, task_id: task.id },
          });
  
          const allArchivedFiles = archivedFiles.slice()
          allArchivedFiles.push(file)
          setArchivedFiles(allArchivedFiles)
        }
      } else {
        //copy pasta
        // var newLists = JSON.parse(JSON.stringify(lists));
        // var list = newLists.find((l: any) => l.id == task.id);
        // list.files = list.files.filter((f: any) => f.id != file.id);
        // setLists(newLists);
        if (onUpdateFile) {
          onUpdateFile('deleted', file.id);
        }
      }

      onClose();
    }
  }, [deleteFileData, deleteFileError, deleteFileLoading])

  useEffect(() => {
    if (checkOutFileLoading) {
      return;
    }

    if (checkOutFileData && !checkOutFileError) {
      if (currentPage === "Task") {
        updateTask({
          ...task,
          files: task.files.map((f: any) => {
            if (f.id === file.id) {
              const fileCopy = Object.assign({}, file);
              fileCopy.isCheckedOut = true;
              return fileCopy;
            } else {
              return f;
            }
          })
        })
      } else {
        //copy pasta
        // var newLists = JSON.parse(JSON.stringify(lists));
        // var list = newLists.find((l: any) => l.id == task.id);
        // list.files.find((f: any) => f.id === file.id).isCheckedOut = true;
        // setLists(newLists);
        if (onUpdateFile) {
          onUpdateFile('checkedOut', file.id);
        }
      }

      onClose();
    }
  }, [checkOutFileData, checkOutFileError, checkOutFileLoading])

  useEffect(() => {
    if (checkInFileLoading) {
      return;
    }

    if (checkInFileData && !checkInFileError) {
      if (currentPage === "Task") {
        updateTask({
          ...task,
          files: task.files.map((f: any) => {
            if (f.id === file.id) {
              const fileCopy = Object.assign({}, file);
              fileCopy.isCheckedOut = false;
              return fileCopy;
            } else {
              return f;
            }
          })
        })
      } else {
        //copy pasta
        // var newLists = JSON.parse(JSON.stringify(lists));
        // var list = newLists.find((l: any) => l.id == task.id);
        // list.files.find((f: any) => f.id === file.id).isCheckedOut = false;
        // setLists(newLists);
        if (onUpdateFile) {
          onUpdateFile('checkedIn', file.id);
        }
      }
      onClose();
    }
  }, [checkInFileData, checkInFileError, checkInFileLoading])


  //If the file is still processing, only allow the user to delete the file
  if (!file?.alterable) {
    return (
      <Popper style={{ zIndex: 1203 }} anchorEl={anchorEl} open={open} placement='left-start'>
        <ClickAwayListener onClickAway={onClose}>
          <Paper className={classes.activeFileOptionsRoot} elevation={0} square>
            <div onClick={handleDeleteFileClick} className={clsx(classes.items, classes.itemDiv)}>
              <Typography variant="h6" className={clsx(classes.items, classes.iconTypography)}>
                <DeleteIcon className={classes.icon} />
              </Typography>
              <Typography variant="h6" className={clsx(classes.items, classes.typography)}>
                Delete file
              </Typography>
            </div>
            <input
              onChange={updateFile}
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    )
  }

  return (
    <Popper style={{ zIndex: 1203 }} anchorEl={anchorEl} open={open} placement='left-start'>
      <ClickAwayListener onClickAway={onClose}>
        <Paper className={classes.activeFileOptionsRoot} elevation={0} square>
          <div onClick={handleDeleteFileClick} className={clsx(classes.items, classes.itemDiv)}>
            <Typography variant="h6" className={clsx(classes.items, classes.iconTypography)}>
              <DeleteIcon className={classes.icon} />
            </Typography>
            <Typography variant="h6" className={clsx(classes.items, classes.typography)}>
              Delete file
            </Typography>
          </div>
          <div onClick={handleUpdateFileVersionClick} className={clsx(classes.items, classes.itemDiv)}>
            <Typography variant="h6" className={clsx(classes.items, classes.iconTypography)}>
              <AutorenewIcon className={classes.icon} />
            </Typography>
            <Typography variant="h6" className={clsx(classes.items, classes.typography)}>
              Update file version
            </Typography>
          </div>
          {!file?.isCheckedOut && (
            <div onClick={handleFileCheckOut} className={clsx(classes.items, classes.itemDiv)}>
              <Typography variant="h6" className={clsx(classes.items, classes.iconTypography)}>
                <CheckBoxOutlineBlankIcon className={classes.icon} />
              </Typography>
              <Typography variant="h6" className={clsx(classes.items, classes.typography)}>
                File checkout
              </Typography>
            </div>
          )}
          {file?.isCheckedOut && (
            <>
              <div onClick={handleUpdateFileVersionClick} className={clsx(classes.items, classes.itemDiv)}>
                <Typography variant="h6" className={clsx(classes.items, classes.iconTypography)}>
                  <CheckBoxIcon className={classes.icon} />
                </Typography>
                <Typography variant="h6" className={clsx(classes.items, classes.typography)}>
                  File check in
                </Typography>
              </div>
              <div onClick={handleFileCancelCheckout} className={clsx(classes.items, classes.itemDiv)}>
                <Typography variant="h6" className={clsx(classes.items, classes.iconTypography)}>
                  <IndeterminateCheckBoxIcon className={classes.icon} />
                </Typography>
                <Typography variant="h6" className={clsx(classes.items, classes.typography)}>
                  Cancel file check out
                </Typography>
              </div>
            </>
          )}
          <input
            onChange={updateFile}
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
          />
        </Paper>
      </ClickAwayListener>
    </Popper>
  )
}