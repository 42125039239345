import React, { useEffect, useRef, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useLocalStorage, useSessionStorage } from '../../../utils/useStorage';
import ActiveFiles from './ActiveFiles';
import { useDispatchContext, useStateContext } from '../../../store';
import ArchivedFiles from './ArchivedFiles';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

interface TaskFilesProps {
  currentPage?: any;
  reconciliationVisible?: any;
  downloadAllFiles?: any;
  downloadAll?: boolean;
  fileDisplay?: any;
  setFileDisplay?: any;
  isRequester?: boolean;
  files: any[],
  filesLoading: boolean,
  task: any,
  tasks: any,
  updateTaskStatus: any,
  updateStatusAfterUpload: any,
  updateTask: any,
  updateTaskInCache: any,
  setTasks: any,
  archivedFiles: any[],
  setArchivedFiles: any,
  lists: any,
  setLists: any,
  isExternalUser: boolean,
  archivedFilesLoading: boolean,
  onUpdateFile?(context: string, id: string): void,
  paneIndex?: number,
  sidePanelIsOpened?: boolean
}

const newStyles = makeStyles((theme: Theme) => {
  return createStyles({
    flexRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    fileRow: {
      height: '50px',
      cursor: 'default'
    },
    fileRowText: {
      color: "#606060",
      fontSize: '12px',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      lineHeight: 1.6
    },
    fileRowName: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    moreIcon: {
      float: 'right',
      color: '#606060'
    },
    expandIcons: {
      color: "#606060",
      fontSize: '18px'
    },
    fileIcon: {
      height: '25px'
    },
    downloadAllFiles: {
      flex: '1',
      minWidth: '120px',
      '&:hover': {
        cursor: 'pointer',
        margin: '0 2px',
        backgroundColor: '#FFF',
        border: '1px solid #3A84FF',
        borderRadius: '3px',
      },
    }
  })
});

/**
 * `TaskFiles` tab component used in the `FilesPane` component data tab section.
 * This component is using the `TailwindCSS` design pattern.
 * @param `props.task` - The current displaying task.
 *
 * @return {React.JSXElementConstructor} The `TaskFiles` component.
 */
export default function  TaskFiles(props:  TaskFilesProps) {
  const [open, setOpen] = useState(true);
  const state = useStateContext();
  
  const { 
    currentPage, 
    reconciliationVisible, 
    downloadAllFiles, 
    downloadAll, 
    fileDisplay, 
    setFileDisplay, 
    isRequester,
    files, 
    filesLoading, 
    task, 
    tasks, 
    setTasks, 
    updateTaskStatus, 
    updateStatusAfterUpload, 
    updateTask, 
    updateTaskInCache,
    archivedFiles, 
    setArchivedFiles, 
    lists, 
    setLists, 
    isExternalUser,
    archivedFilesLoading,
    onUpdateFile,
    paneIndex,
    sidePanelIsOpened
  } = props;

  const classes = newStyles();

  const [onClose, setOnClose] = useState(() => { return () => { } });
  const [anchorEl, setAnchorEl] = useState();
  const discloseBtnRef: any = useRef(null);

  useEffect(() => {
    if (sidePanelIsOpened && paneIndex === 1) {
      if (!open) {
        discloseBtnRef.current.click();
      }
    }
  }, [paneIndex, sidePanelIsOpened]);

  const handleTaskFilesToggleClick = (event: any) => {
    const newOnClose = () => {
      setAnchorEl(undefined);
    }

    setOnClose(() => { return newOnClose })
    setAnchorEl(event.currentTarget);
  };

  if (task && Object.keys(task).length !== 0) {
    var rows = task.files;
  }  

  const handleToggle = () => {
    setOpen(!open);
  }

  return (
    <Disclosure defaultOpen={open}>
      {({ open }) => (
        <>
          <Disclosure.Button
            ref={discloseBtnRef}
            className={
              `${open ? 'border-b-0 border-t-0' : 'border-t-0 border-b-200'
                } focus-visible:ring-opacity-75 flex justify-between items-center p-4 w-full text-sm font-medium text-left text-gray-400 border border-x-0 border-gray-200 hover:bg-blue-50 focus:outline-none focus-visible:ring focus-visible:ring-blue-100`
              }
            onClick={() => handleToggle()}
          >
            <div className='flex items-center justify-between w-full text-xs text-gray-400'>
              <span className='font-bold'>{ currentPage === "List" ? "Files" : "Task Files"}</span>
              {task.files && task.files.length >= 0 ? (
                <div className='mr-3 font-bold' data-cy='task-files-summary-data'>
                  (
                    {task.files && task.files.length}
                  )
                </div>
              ) : null }
            </div>
            <ChevronRightIcon
              className={`${open ? 'transform rotate-90' : ''} !text-lg`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="pt-0 text-sm text-gray-400 border border-t-0 border-b-0 border-gray-200 border-x-0" data-cy='task-files-body'>
            {(task && Object.keys(task).length && fileDisplay !== "Archived") ? (
              <ActiveFiles 
                files={files} 
                filesLoading={filesLoading} 
                task={task} 
                tasks={tasks} 
                updateTaskStatus={updateTaskStatus} 
                updateStatusAfterUpload={updateStatusAfterUpload} 
                updateTask={updateTask}
                updateTaskInCache={updateTaskInCache}
                setTasks={setTasks} 
                archivedFiles={archivedFiles} 
                setArchivedFiles={setArchivedFiles} 
                currentPage={currentPage} 
                lists={lists} 
                setLists={setLists} 
                isExternalUser={isExternalUser} 
                downloadAllFiles={downloadAllFiles} 
                downloadAll={downloadAll} 
                fileDisplay={fileDisplay} 
                setFileDisplay={setFileDisplay} 
                isRequester={isRequester}
                onUpdateFile={onUpdateFile}
              />
            ) : null } 
            {/* Check to prevent users associated with the requester company from seeing archived files */}
            {currentPage !== "List" && fileDisplay !== "Active" && !isRequester && (
              <ArchivedFiles 
                task={task} 
                tasks={tasks} 
                updateTask={updateTask} 
                setTasks={setTasks} 
                archivedFiles={archivedFiles} 
                archivedFilesLoading={archivedFilesLoading} 
                setArchivedFiles={setArchivedFiles} 
                isExternalUser={isExternalUser} 
              />
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}