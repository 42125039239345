/** The purpose of this component is:
 */
import { SlEnable } from './SlEnable';
import { IntacctEnable } from './IntacctEnable';
import {NetSuiteEnable} from './NetSuiteEnable'

import React from 'react';

export function IntegrationEnable(props: any) {

  switch(props.integrationType){
    case '3': {
      return(
        <IntacctEnable
          open={props.open}
          denyAction={props.denyAction}
          integrationType={'Intacct'}
          confirmAction={props.confirmAction}
        />
      )
    }
    case '4': {
      return(
        <SlEnable
          open={props.open}
          denyAction={props.denyAction}
          integrationType={'SoftLedger'}
          confirmAction={props.confirmAction}
        />
      )
    }
    case '5': {
      return(
        <SlEnable
          open={props.open}
          denyAction={props.denyAction}
          integrationType={'QuickBooks Online'}
          confirmAction={props.confirmAction}
        />
      )
    }
    case '6': {
      return(
        <NetSuiteEnable
          open={props.open}
          denyAction={props.denyAction}
          integrationType={'NetSuite'}
          confirmAction={props.confirmAction}
        />
      )
    }
    default: {
      return(
        <div/>
      )
    }
  }
}
