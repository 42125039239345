
import {  Typography,  Switch, Grid, withStyles } from '@material-ui/core';
import { Theme, createStyles } from '@material-ui/core/styles';
import React from "react";


interface ILockSwitchProps {
  locked: boolean,
  name: string,
  disabled?: boolean,
  handleChange: () => void
}


export default function LockSwitchToggle(lockSwitchProps: ILockSwitchProps) {

  const { locked, name, disabled, handleChange } = lockSwitchProps;

  return (
    <Typography component='div' style={{ paddingTop: '6px', paddingBottom: '6px' }}>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <LockSwitch checked={locked} onChange={handleChange} name={name} disabled={disabled}/>
        </Grid>
        <Grid item>{locked ? "Locked" : "Unlocked"}</Grid>
      </Grid>
    </Typography>
  )
}

const LockSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 0,
      color: theme.palette.grey[500],
      '&:hover': {
        border: '2px solid transparent !important'
      },
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }),
)(Switch);