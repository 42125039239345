import { useLazyQuery } from '@apollo/client';
import {createQueryHook, gql} from '../graphqlHelpers';

const queryRelativeDueDate = gql`
  query GetRelativeDueDate( 
    $taskId: ID!
    $relativeDateNumber: Int!
  ) {
    getRelativeDueDate(
      taskId: $taskId
      relativeDateNumber: $relativeDateNumber
    )
  }
`

const queryRelativeNumber = gql`
  query GetRelativeNumberFromDate( 
    $taskId: ID!
    $date: String!
  ) {
    getRelativeNumberFromDate(
      taskId: $taskId
      date: $date
    )
  }
`

export const useGetRelativeDueDate = createQueryHook(queryRelativeDueDate);
export const useLazyGetRelativeDateNumber = (variables: any) => useLazyQuery(queryRelativeNumber, {variables});
