import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import ProjectOption from './ProjectOption';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			// height: '100%',
			width: '100%',
			justifyContent: 'center',
			alignItems: 'baseline',
		},
	})
);

interface IProjectOptionGroup {
	projectOptions: any;
	onOptionChange(selectedOption: string): void;
	selectedValue: string;
}

export default function ProjectOptionGroup(props: IProjectOptionGroup) {
	const classes = useStyles();
	const { projectOptions, onOptionChange, selectedValue } = props;

	return (
		<div className={classes.root}>
			{
				Object.keys(projectOptions).map((option: string) =>
					<ProjectOption key={projectOptions[option].value} projectOption={projectOptions[option]}
						selected={projectOptions[option].value === selectedValue} onSelect={onOptionChange} />
				)
			}
		</div>
	)
}