// linted
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { COLORS } from '../../constants/theme';

interface ReconPercentProps {
  percent: number;
};

export function ReconPercent(props: ReconPercentProps) {
  const container = useRef(null);

  useEffect(() => {
    if (container.current) {
      const text = props.percent.toFixed(2);
      const init = {
        draw: function(config: any) {
          d3.selectAll('.donut').remove();
          var svg = d3.select('#recon-percent')
            .attr('width', 200)
            .attr('height', 200)
            .append('g')
            .attr('transform', 'translate(100, 100)');

          var pie = d3.pie()
            .value(function(d: any) { return d; })
          // @ts-ignore
          var dataReady = pie([100 - props.percent, props.percent]);

          svg
            .selectAll('whatever')
            .data(dataReady)
            .enter()
            .append('path')
          // @ts-ignore
            .attr('d', d3.arc()
              .innerRadius(60)
              .outerRadius(90)
            )
            .attr('class', 'donut')
            .attr('fill', function(a: any, b: any) {
              if (b === 0) { return '#f2f2f2 '; }
              else { return COLORS.notification; }
            })
            .style('display', 'block')
          // always want the completed percent to run clockwise
          // @ts-ignore
            .attr('style', function(d: any) {
              if (props.percent <= 50) { return 'transform: scaleX(-1)'; }
            });

          svg.append('text')
            .attr('class', 'donut')
            .attr('width', 20).attr('height', 20)
            .attr('x', -30)
            .attr('y', 0)
            .attr('fill', 'black')
            .style('font-family', '"Montserrat", sans-serif')
            .style('font-size', '20px')
            .text(text + ' %');
        }
      }
      init.draw({});
    }
  }, [container, props.percent]);

  return (
    <svg
      id='recon-percent'
      width={200}
      height={200}
      ref={container}
      style={{cursor: 'default'}}
    />
  );
}
