import { useState, useRef, useEffect, Dispatch, SetStateAction} from 'react';

/** Custom hook to trigger a callback when click outside a ref
* @returns A callback call when the click outside occured
*/

export function useClickOutsideCallback (ref:any, callback:any): any {
  const handleClick = (e:any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};