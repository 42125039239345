// Type: enum `INTEGRATION_SOURCE_TYPE` for the different integration source types
// Can be reused everywhere in the app.
// Usage: import { INTEGRATION_SOURCE_TYPE } from 'constants/integration_source_type';
export enum INTEGRATION_SOURCE_TYPE {
    MANUAL = 1,
    FILE_VALIDATION = 2,
    INTACCT = 3,
    SOFTLEDGER = 4,
    QBO = 5,
    NETSUITE = 6,
  };