import React, { useState, useEffect } from 'react';

import { TaskReconSupport } from './components/TaskReconSupport';
import ReconcilingItems from './components/ReconcilingItems';
import { useLocalStorage, useSessionStorage } from '../../utils/useStorage';
import ReconSummary from './components/ReconSummary/ReconSummary';

interface ReconciliationSectionProps {
  task: any;
  updateTask: (task: any) => void;
  updateTaskInCache: any;
  updateTaskStatus: (task: any) => void;
  tasks: any;
  allTasks: any;
  setTasks: any;
  lists: any;
  taskListPrimaryDisplaySkeleton?: any;
  taskListSecondaryDisplaySkeleton?: any;
  taskListReconcilingItemsDisplaySkeleton?: any;
  setTaskListPrimaryDisplaySkeleton: any;
  setTaskListSecondaryDisplaySkeleton: any;
  setTaskListReconcilingItemsDisplaySkeleton: any;
  onPopoverLeave?: any;
}

export type ReconSourceType = 'primary' | 'secondary' | 'reconciling-items';

/** ReconciliationSection organizes the top recon section 'summary' and
 *  the bottom section 'sources'. It maintains shared state between the two,
 *  which recon source type (primary, secondary, none) is active in 'sources' */
export function ReconciliationSection({
  task,
  updateTask,
  updateTaskInCache,
  updateTaskStatus,
  tasks,
  allTasks,
  setTasks,
  lists,
  taskListPrimaryDisplaySkeleton,
  taskListSecondaryDisplaySkeleton,
  taskListReconcilingItemsDisplaySkeleton,
  setTaskListPrimaryDisplaySkeleton,
  setTaskListSecondaryDisplaySkeleton,
  setTaskListReconcilingItemsDisplaySkeleton,
  onPopoverLeave,
}: ReconciliationSectionProps) {
  const [
    sourceSupportActive,
    setSourceSupportActive
  ] = useState<ReconSourceType | null>(null);

  const [reconcilingItemsDisplaySkeleton, setReconcilingItemsDisplaySkeleton] = useState(false);
  const [toggleReconcilingItems, setToggleReconcilingItems] = useState<boolean>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const prevLocalStatus = useLocalStorage('isReconcilingItemsTabOpen', [toggleReconcilingItems, setToggleReconcilingItems]);
  const prevSessionStatus = useSessionStorage('isReconcilingItemsTabOpen', [toggleReconcilingItems, setToggleReconcilingItems]);
  
  const anchorRef = React.useRef<any>();

  useEffect(() => { 
    setTimeout(() => setAnchorEl(anchorRef?.current), 1) 
  }, [anchorRef])

  const updatePrimaryDisplaySkeletons = (bool: boolean) => {
    setTaskListPrimaryDisplaySkeleton(bool);
    if(sourceSupportActive && task[`${sourceSupportActive}SourceType`] === 2) {
      updateReconcilingItemsDisplaySkeletons(bool);
    }
  }

  const updateSecondaryDisplaySkeletons = (bool: boolean) => {
    setTaskListSecondaryDisplaySkeleton(bool);
    
    if(sourceSupportActive && task[`${sourceSupportActive}SourceType`] === 2) {
      updateReconcilingItemsDisplaySkeletons(bool);
    }
  }

  const updateReconcilingItemsDisplaySkeletons = (bool: boolean) => {
    setReconcilingItemsDisplaySkeleton(bool);
    setTaskListReconcilingItemsDisplaySkeleton(bool);
  }

  const updateToggleReconcilingItems = (bool: boolean) => {
    onPopoverLeave();
    setSourceSupportActive(null);
    setToggleReconcilingItems(bool);
  }
  
  function changeActiveReconSource(reconSourceType: ReconSourceType) {   
    onPopoverLeave(); 
    if (reconSourceType === sourceSupportActive) {
      setSourceSupportActive(null);
    } else {
      setSourceSupportActive(reconSourceType);
    }
  }

  const handleShowSourcePopper = (event: React.MouseEvent<HTMLElement>, sourceType?: any) => {
    if (event) {
      if (!sourceSupportActive) {
        setAnchorEl(anchorEl ? null : event.currentTarget);
      } else {
        setAnchorEl(null);
        setSourceSupportActive(null);
        onPopoverLeave();
      }
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
      onPopoverLeave();
    }
  }
  
  return (
    <>
      <ReconSummary
        changeActiveReconSource={changeActiveReconSource}
        primaryDisplaySkeleton={taskListPrimaryDisplaySkeleton}
        reconcilingItemsDisplaySkeleton={reconcilingItemsDisplaySkeleton}
        secondaryDisplaySkeleton={taskListSecondaryDisplaySkeleton}
        task={task}
        updateTask={updateTask}
        updateTaskInCache={updateTaskInCache}
        toggleReconcilingItems={toggleReconcilingItems}
        updateToggleReconcilingItems={updateToggleReconcilingItems}
        handleShowSourcePopper={handleShowSourcePopper}
      />
      <div ref={anchorRef}>
        {sourceSupportActive && (
          <TaskReconSupport
            allTasks={allTasks}
            lists={lists}
            setTasks={setTasks}
            sourceType={sourceSupportActive}
            task={task}
            tasks={tasks}
            updateTask={updateTask}
            updateTaskInCache={updateTaskInCache}
            updateTaskStatus={updateTaskStatus}
            updatePrimaryDisplaySkeletons={updatePrimaryDisplaySkeletons}
            updateSecondaryDisplaySkeletons={updateSecondaryDisplaySkeletons}
            updateReconcilingItemsDisplaySkeletons={updateReconcilingItemsDisplaySkeletons}
            toggleReconcilingItems={toggleReconcilingItems}
            updateToggleReconcilingItems={updateToggleReconcilingItems}
            displaySkeleton={taskListSecondaryDisplaySkeleton}
            onPopoverLeave={onPopoverLeave}
          />
        )}
      </div>
      <ReconcilingItems
        allTasks={allTasks}
        setTasks={setTasks}
        sourceType={sourceSupportActive}
        task={task}
        tasks={tasks}
        updatePrimaryDisplaySkeletons={updatePrimaryDisplaySkeletons}
        updateSecondaryDisplaySkeletons={updateSecondaryDisplaySkeletons}
        displaySkeleton={taskListSecondaryDisplaySkeleton}
        updateTask={updateTask}
        updateTaskInCache={updateTaskInCache}
        updateTaskStatus={updateTaskStatus}
        toggleReconcilingItems={toggleReconcilingItems}
        updateToggleReconcilingItems={updateToggleReconcilingItems}
      />
    </>
  );
}
