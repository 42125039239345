import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { IProjectOptionsSelected, ISteps, ITableHeader } from '../../../common/interfaces';

import ProjectTaskTable from '../../../common/TasksDetailsReview/ProjectTaskTable';
import TasksHeader from '../../../common/TasksDetailsReview/TasksHeader';
import FilesPage from '../../../../../../file/FilesPage';
import SidePanelContainer from './SidePanelContainer';
import ForwardIcon from '@material-ui/icons/Forward';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			position: 'sticky',
			right: 0,
			top: 0,
			background: 'white',
			zIndex: 20,
		},
		flex: {
			display: 'flex',
		},
		container: {
			width: '100%',
			height: '100%',
		},
		column: {
			flexDirection: 'column',
		},
		row: {
			flexDirection: 'row',
		},
		scrollBar: {
			'&::-webkit-scrollbar': {
				width: '0.6em',
				height: '0.6em',
			}
		},
		leftDiv: {
			position: 'sticky',
			padding: '0px 25px',
			top: 0,
			left: 0,
			background: 'white',
			zIndex: 10,
			height: '100%'
		},
		icon: {
			color: '#D8D8D8',
			fontSize: '36px'
		},
		verticalLine: {
			color: '#D8D8D8',
			height: '100%',
			borderLeft: '1px solid #D8D8D8'
		},
	})
);

interface IProjectReviewStep {
	isAdmin: boolean;
	externalCompanyId: string;
	selectedTemplate: any;
	setSelectedTemplate: React.Dispatch<React.SetStateAction<any>>;
	allTableHeaderOptions: ITableHeader[];
	selectedTasksIds: string[];
	setSelectedTasksIds: React.Dispatch<React.SetStateAction<string[]>>;
	step: ISteps;
	projectOptionsSelected: IProjectOptionsSelected;
	skinnyLists: any[];
	setSkinnyLists: React.Dispatch<React.SetStateAction<any[]>>;
	dependencies: any;
	setAddTasks: React.Dispatch<React.SetStateAction<any[]>>;
	deleteTasks: any[];
	setDeleteTasks: React.Dispatch<React.SetStateAction<any[]>>;
	onFilesDropped(): void;
	filesUploaded(): void;
	sortByName: string | null;
	sortBySection: string | null;
	nameFilter: string;
	sectionFilter: string;
	setNameFilterPopper: any;
	additionalInternalOwners: any[];
	additionalExternalOwners: any[];
	setAdditionalInternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
	setAdditionalExternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function ProjectReviewStep(props: IProjectReviewStep) {

	const classes = useStyles();

	const { isAdmin, externalCompanyId, selectedTemplate, setSelectedTemplate, allTableHeaderOptions, selectedTasksIds, setSelectedTasksIds, step,
		projectOptionsSelected, onFilesDropped, filesUploaded, skinnyLists, setSkinnyLists, dependencies, setAddTasks, deleteTasks, setDeleteTasks,
		sortByName, sortBySection, nameFilter, sectionFilter, setNameFilterPopper,
		additionalInternalOwners, additionalExternalOwners, setAdditionalInternalOwners, setAdditionalExternalOwners } = props;
	const [paneIndex, setPaneIndex] = useState<number>(0);
	const [panelPaneIndex, setPanelPaneIndex] = useState<number>(0);
	const [open, setOpen] = useState<boolean>(false);
	const [selectedTask, setSelectedTask] = useState<any>({});
	const [updatedStatusFromFiles, setUpdatedStatusFromFiles] = useState<String>('');
	const [tasks, setTasks] = useState<any[]>(selectedTemplate.tasks);

	const displayedHeaders = allTableHeaderOptions.slice(0, 2);

	function updateSelectedTask(task: any) {
		if ((selectedTask && selectedTask.id) && (task.id === selectedTask.id)) {
			setSelectedTask({});
			setOpen(false);
		} else {
			setSelectedTask(task);
			setOpen(true);
		}
	}

	useEffect(() => {
        setSelectedTemplate({...selectedTemplate, tasks: tasks});
    }, [tasks]);

	useEffect(() => {
		setSelectedTask({});
		setOpen(false);
	}, [deleteTasks])

	function onFilesUploaded(status: String) {
		setUpdatedStatusFromFiles(status);
		filesUploaded();
	}

	return (
		<>
			{
				step.activeSubStep === 0 &&
				<div className={clsx(classes.flex, classes.container, classes.row, classes.scrollBar)} style={{ overflow: 'auto' }}>
					<div className={clsx(classes.flex, classes.container, classes.column)} style={{ maxWidth: 750 }}>
						<TasksHeader step={step} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate}
							selectedTasksIds={selectedTasksIds} setSelectedTasksIds={setSelectedTasksIds} setDeleteTasks={setDeleteTasks} />
						<div className={clsx(classes.flex, classes.container, classes.row)} >
							<div className={classes.leftDiv} />
							<div className={clsx(classes.flex, classes.container, classes.column)} >
								<ProjectTaskTable isAdmin={isAdmin} externalCompanyId={externalCompanyId} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate}
									selectedTasksIds={selectedTasksIds} setSelectedTasksIds={setSelectedTasksIds} allTableHeaderOptions={allTableHeaderOptions}
									displayedHeaders={displayedHeaders} selectedTask={selectedTask} updateSelectedTask={updateSelectedTask} projectOptionsSelected={projectOptionsSelected}
									step={step} setAddTasks={setAddTasks} dependencies={dependencies} skinnyLists={skinnyLists} setSkinnyLists={setSkinnyLists}
									sortByName={sortByName} sortBySection={sortBySection} nameFilter={nameFilter} sectionFilter={sectionFilter} setNameFilterPopper={setNameFilterPopper}
									additionalInternalOwners={additionalInternalOwners} setAdditionalInternalOwners={setAdditionalInternalOwners}
									additionalExternalOwners={additionalExternalOwners} setAdditionalExternalOwners={setAdditionalExternalOwners} />
								<div className={classes.leftDiv} style={{ padding: '15px', bottom: 0 }} />
							</div>
						</div>
					</div>
					<div className={clsx(classes.root, classes.flex, classes.container, classes.row)} style={{ padding: '0px 50px 36px' }}>
						<div className={clsx(classes.flex, classes.container, classes.column)} style={{ alignItems: 'center' }}>
							<div className={classes.verticalLine} style={{ height: '32px' }}></div>
							<ForwardIcon className={classes.icon} />
							<div className={classes.verticalLine}></div>
						</div>
						<SidePanelContainer selectedTask={selectedTask} tasks = {tasks} setTasks = {setTasks} open={open} selectedTasksIds = {selectedTasksIds}
							projectOptionsSelected={projectOptionsSelected} dependencies={dependencies} skinnyLists={skinnyLists} onFilesDropped={onFilesDropped}
							paneIndex={paneIndex} setPaneIndex={setPaneIndex} panelPaneIndex={panelPaneIndex} setPanelPaneIndex={setPanelPaneIndex}
							externalCompanyId={externalCompanyId} setSkinnyLists={setSkinnyLists} updatedStatusFromFiles={updatedStatusFromFiles} setUpdatedStatusFromFiles = {setUpdatedStatusFromFiles} setSelectedTask={setSelectedTask}
							additionalInternalOwners={additionalInternalOwners} setAdditionalInternalOwners={setAdditionalInternalOwners}
							additionalExternalOwners={additionalExternalOwners} setAdditionalExternalOwners={setAdditionalExternalOwners} />
					</div>
				</div>
			}
			{
				step.activeSubStep === 1 &&
				<FilesPage isCreateProject={true} filesUploaded={onFilesUploaded}
					companyPosition={projectOptionsSelected.projectType === 'internal' ? 'internal' : projectOptionsSelected.projectType === 'share' ? 'responder' : 'requester'} />
			}
		</>
	)
}
