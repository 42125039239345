
/**
 * Build the money currency display helper.
 * @param {String} currency - the currency got from the NS integration parameters
 * @returns {String} params - the new currency to display.
 *
*/
export const displayCurrency = (currency?: string) => {
  if (!currency) { 
    return '$';
  } else {  
    switch (currency?.toLowerCase()) {
      case 'us dollar':
        return '$';
      case 'euro':
        return '€';
      case 'singapore dollar':
        return 'S$';
      case 'indian rupee':
        return '₹';
      case 'british pound':
        return '£';
      default:
        return '$';
    }
  }
};

