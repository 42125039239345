import React, { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TaskReconSourceOverview from './TaskReconSourceOverview';
import AlertMessage from './AlertMessage';

import { useLocalStorage, useSessionStorage } from '../../../../utils/useStorage';
import { MoneyDisplay } from '../../../../../../../../common/MoneyDisplay';
import { Skeleton } from '../../../../../../../../common/Skeleton';

interface ReconSummaryProps {
  changeActiveReconSource: any;
  task: any;
  updateTask: any;
  updateTaskInCache: any;
  primaryDisplaySkeleton: any;
  secondaryDisplaySkeleton: any;
  toggleReconcilingItems: any;
  updateToggleReconcilingItems: any;
  handleShowSourcePopper: any;
  reconcilingItemsDisplaySkeleton: any;
}

/**
 * `ReconSummary` tab component used in the `FilesPane` component data tab section.
 * This component is using the `TailwindCSS` design pattern.
 * @param `props.task` - The current displaying task.
 *
 * @return {React.JSXElementConstructor} The `ReconSummary` component.
 */
export default function  ReconSummary(props:  ReconSummaryProps) {
  const [open, setOpen] = useState(false);
  const {
    changeActiveReconSource,
    task,
    updateTask,
    updateTaskInCache,
    primaryDisplaySkeleton,
    secondaryDisplaySkeleton,
    toggleReconcilingItems,
    updateToggleReconcilingItems,
    reconcilingItemsDisplaySkeleton,
    handleShowSourcePopper,
  } = props;

  const prevLocalStatus = useLocalStorage("isReconSummaryTabOpen", [open, setOpen]);
  const prevSessionStatus = useSessionStorage("isReconSummaryTabOpen", [open, setOpen]);

  const handleToggle = () => {
    setOpen(!open);
  }

  return (
    <Disclosure defaultOpen={open}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className='focus-visible:ring-opacity-75 flex justify-between items-center p-4 w-full text-sm font-medium text-left text-gray-400 border-0 hover:bg-blue-50 focus:outline-none focus-visible:ring focus-visible:ring-blue-100'
            onClick={() => handleToggle()}
            id={JSON.stringify(open)}
          >
            <div className='flex justify-between items-center w-full text-xs font-bold text-gray-400'>
              <span>Reconciliation Summary</span>
              <Skeleton
                isLoaded={primaryDisplaySkeleton}
                style={{
                  width: '120px',
                  height: '15px',
                  display: 'flex',
                  justifyContent: 'start'
                }}
              >
                {task.reconDifference !== undefined && task.reconDifference !== null ? (
                  <span className='mr-3' data-cy='reconciliation-summary-data'>
                    { !(task.isReconciled) ? (
                      <MoneyDisplay
                        value={task.reconDifference} 
                        style={{ fontWeight: 700, color: 'orange' }}
                        customSymbol={task.currency}
                        displaySymbol
                      />
                    ) : (
                      <span style={{ fontWeight: 700, color: 'green' }}>Reconciled</span>
                    )}
                  </span>
                ) : null}
              </Skeleton>
            </div>
            <ChevronRightIcon
              className={`${open ? 'transform rotate-90' : ''} !text-lg`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="pt-0 p-4 text-sm text-gray-400">
            <>
              <TaskReconSourceOverview
                changeActiveReconSource={changeActiveReconSource}
                primaryDisplaySkeleton={primaryDisplaySkeleton}
                secondaryDisplaySkeleton={secondaryDisplaySkeleton}
                task={task}
                updateTask={updateTask}
                updateTaskInCache={updateTaskInCache}
                toggleReconcilingItems={toggleReconcilingItems}
                updateToggleReconcilingItems={updateToggleReconcilingItems}
                handleShowSourcePopper={handleShowSourcePopper}
                reconcilingItemsDisplaySkeleton={reconcilingItemsDisplaySkeleton}
              />
              <AlertMessage task={task} />
            </>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}