import {createMutationHook, gql} from '../graphqlHelpers';
//import {DeleteLists, DeleteListsVariables} from './__generated__/DeleteLists';

const archiveFile = createMutationHook<any, any>(gql`
  mutation ArchiveFile($fileId: ID!) {
    archiveFile(fileId: $fileId) {
      tasks {
        id
        currency
        primaryFileReconSources {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
            alterable
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        secondaryFileReconSources {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
            alterable
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        reconcilingItems {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
            alterable
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        primaryFileReconSourcesTotal
        secondaryFileReconSourcesTotal
        isReconciled
        primaryTotal
        secondaryTotal
        reconcilingItemTotal
        reconDifference
        files {
          id
          fileName
          fileLocation
          viewed
          alterable
          updatedAt
          taskId
          createdAt
          isCheckedOut
          checkedOutBy{
            fullName
          }
          checkedOutAt
          companyId
        }
      }
      success
    }
  }
`);

export default archiveFile;
