import React from 'react';

interface FileIcon {
  fileName: string;
  className?: string;
  style?: any;
  onClick?: any;
}

/**
 * File icon component for rendering the correct icond depending on the file type
 * @param {string} fileName - The files name
 * @param {string} className - CSS class for styling
 * @param {style} style - Inline styling for the component
 * @param {any} onClick - On click handler for clicking the icon
 */

export const FileIcon = ({ fileName, className, style, onClick }: FileIcon) => {
  const chooseFileIcon = (fileExtension: string): string => {
    if (fileName.includes('pdf')) {
      return require('../../../../../public/images/pdf_file_icon.svg');
    } else if (fileName.includes('doc')) {
      return require('../../../../../public/images/microsoft_word_icon.svg');
    } else if (fileExtension.includes('ppt')) {
      return require('../../../../../public/images/microsoft_powerpoint_icon.svg');
    } else if (fileName.includes('xlsx') || fileName.includes('xls')) {
      return require('../../../../../public/images/microsoft_excel_icon.svg');
    } else {
      return require('../../../../../public/images/default_file_icon.svg');
    }
  };

  return (
    <img
      style={style}
      className={className}
      onClick={onClick}
      src={chooseFileIcon(fileName)}
    />
  );
};
