import {createQueryHook, gql} from '../graphqlHelpers';
import {UploadLists} from './__generated__/UploadLists';

const useUploadLists = createQueryHook<UploadLists, {}>(gql`
  query UploadLists {
    userLists {
		id
      lists {
        id
        name
				isDeleted
				isArchived
        deliverFileOnUpload
        companyPosition
      }
    }
  }
`);

export default useUploadLists
