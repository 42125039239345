import React, { useState, useEffect } from 'react';
import idx from 'idx';
import clsx from 'clsx';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Paper,
  Button,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { withRouter } from 'react-router';

import * as cs from '../../../../../../constants/theme';
import VersionDropdown from './VersionDropdown';
import TitleDropdown from './TitleDropdown';
import StatusLabel from '../../../../../common/StatusLabel';
import { StatusIcon } from '../../../../../common/OnlyStatusIcon';

import TaskFiles from '../../../../../../graphql/queries/TaskFiles';
import { sortTasks } from '../../../FilesPage';

const Excel = require('images/dummy/logos/excel.svg');
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { FileIcon } from '../../../../../common/FileIcon';
import { RDS_MESSAGE_BASE_URL, RDS_MESSAGE_KEY } from '../../../../../common/Constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    footer: {
      height: '60px',
      padding: '0px calc((100% - 1380px) / 2) 0px calc((100% - 1380px) / 2)',
    },
    input: {
      width: '100%',
      border: 'none',
      background: 'none',
      fontFamily: cs.FONT.family,
      fontSize: cs.FONT.size.xs,
      fontWeight: cs.FONT.weight.light,
      '&:hover': {
        border: 'none',
      },
      '&:focus': {
        border: 'none',
        outline: 'none',
      },
    },
    invisible: {
      display: 'none',
    },
    table: {
      tableLayout: 'fixed',
    },
    primary: {
      color: cs.COLORS.primary,
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    grow: {
      flexGrow: 1,
    },
    selection: {
      width: '100%',
      height: '56px',
      borderBottom: '1px solid #D8D8D8',
    },
    delete: {
      color: cs.COLORS.primary,
      fontSize: '28px',
      '&:hover': {
        border: `1px solid ${cs.COLORS.primary}`,
        borderRadius: '3px',
      },
    },
    mr12: {
      marginRight: '12px',
      height: '25px'
    },
  })
);

interface MultipleTasksProps {
  value?: number;
  index?: number;
}

function MultipleTasks(props: any) {
  const {
    setTasks,
    status,
    setStatus,
    fileProperties,
    setFileProperties,
    selectedTaskId,
    setSelectedTaskId,
    selectedListId,
    setSelectedListId,
    index,
    value,
    location,
    history,
    lists,
    tasks,
    files,
    setFiles,
  } = props;
  const classes = useStyles();


  //FILE TOGGLE
  const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
  const [allToggle, setToggle] = useState<boolean>(false);

  // array to keep all the different possible tasks
  const [allTasks, setAllTasks] = useState<any>({});
  // const [retries, setRetries] = useState<number>(0);

  const [uploadClicked, setUploadClicked] = useState<boolean>(false);

  const toggleSelectedFile = (id: number) => {
    if (selectedFiles.includes(id)) {
      setSelectedFiles(selectedFiles.filter((fid) => fid != id));
    } else {
      setSelectedFiles([...selectedFiles, id]);
    }
  };

  const toggleAll = () => {
    selectedFiles.length
      ? setSelectedFiles([])
      : setSelectedFiles(files.map((file: any, index: number) => index));
    setToggle((toggleAll) => !toggleAll);
  };

  const handleDeleteClick = () => {
    setFiles(files.filter(toDelete));
    setFileProperties(fileProperties.filter(toDelete));
    setSelectedFiles([]);
  };

  const toDelete = (a: any, i: number) => !selectedFiles.includes(i);

  const handleLabelChange = (e: any, index: number) => {
    var fileProps = fileProperties.slice();
    fileProps[index].labelName = e.target.value;
    setFileProperties(fileProps);
  };

  const handleCommentChange = (e: any, index: number) => {
    var fileProps = fileProperties.slice();
    fileProps[index].comment = e.target.value;
    setFileProperties(fileProps);
  };

  function getCSRFToken(): string {
    const el = document.querySelector('meta[name="csrf-token"]');
    return (el && el.getAttribute('content')) || '';
  }

  const hashMessage = async (comment: any) => {
    var a = await fetch(
      `${RDS_MESSAGE_BASE_URL}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': `${RDS_MESSAGE_KEY}`,
        },
        body: JSON.stringify({ message: comment }),
      }
    );
    var b = await a.json();

    return b.key;
  };

  const handleUpload = async (e: any) => {
    setUploadClicked(true);
    var formData = new FormData();

    var cool = await Promise.all(
      fileProperties.map((item: any) => hashMessage(item.comment))
    );

    files.forEach(async (file: File, index: number) => {
      var { comment } = fileProperties[index];
      //var ok = await hashMessage(`File: ${fileProperties[index].labelName}, comment: ${comment}`);

      formData.append(`files[]`, file);
      formData.append('label_names[]', fileProperties[index].labelName);
      //formData.append('public_comments[]', fileProperties[index].comment)
      formData.append('public_comments[]', comment ? cool[index] : comment);
      formData.append('versions[]', fileProperties[index].version);
      formData.append('task_ids[]', fileProperties[index].taskId);
      formData.append('statuses[]', fileProperties[index].status);
    });

    try {
      var ok = await fetch('/api/upload', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': getCSRFToken(),
        },
        body: formData,
      });

      var json = await ok.json();
      if (props.isCreateProject) {
        props.filesUploaded();
      } else {
        history.goBack();
      }
    } catch (e) {
      //Activate the error snackbar

      // setRetries(retries + 1);
      //if (retries < 7) setTimeout( async () => await handleUpload(null), 412);
      /*
      else {
      }
*/
    }

    //		history.goBack();
  };

  const handleTaskUpdate = (a: any, i: number) => {
    var fileProps = fileProperties.slice();
    fileProps[i].taskId = a;

    setSelectedTaskId(a);

    setFileProperties(fileProps);
  };

  useEffect(() => {
    if (selectedTaskId) {
      let fileProps = fileProperties.slice();
      fileProps = fileProps.map((f: any) => ({ ...f, taskId: selectedTaskId }));
      setFileProperties(fileProps);
    }
  }, [selectedTaskId]);

  useEffect(() => {
    if (tasks.length) {
      var newAllTasks = Object.assign({}, allTasks);
      var doINeedThis = { ...newAllTasks, [tasks[0].listId]: tasks };
      setAllTasks(doINeedThis);
    }
  }, [tasks]);

  return (
    <Paper
      className={clsx(classes.root, value !== index && classes.invisible)}
      aria-labelledby='Multiple Tasks'
      elevation={0}
    >
      {!!selectedFiles.length ? (
        <div className={clsx(classes.selection, classes.flex)}>
          <Typography variant='h6' className={classes.primary}>
            {`${selectedFiles.length} file${selectedFiles.length === 1 ? '' : 's'
              } selected`}
          </Typography>
          <div className={classes.grow} />
          <DeleteIcon className={classes.delete} onClick={handleDeleteClick} />
        </div>
      ) : (
        <div className={clsx(classes.selection, classes.flex)}></div>
      )}

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell padding='checkbox'>
              <Checkbox
                color='primary'
                checked={allToggle}
                onChange={toggleAll}
              />
            </TableCell>
            <TableCell>File</TableCell>
            <TableCell>List</TableCell>
            <TableCell>Task</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Comment</TableCell>
            <TableCell>Version</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files &&
            files.map((file: any, i: number) => (
              <MultipleTaskTableRow
                setTasks={setTasks}
                handleTaskUpdate={handleTaskUpdate}
                setFileProperties={setFileProperties}
                fileProperties={fileProperties}
                handleCommentChange={handleCommentChange}
                handleLabelChange={handleLabelChange}
                i={i}
                selectedFiles={selectedFiles}
                toggleSelectedFile={toggleSelectedFile}
                key={i}
                file={file}
                index={index}
                lists={lists}
                tasks={tasks}
                incomingList={selectedListId}
                incomingTask={selectedTaskId}
                setSelectedListId={setSelectedListId}
                allTasks={allTasks}
              />
            ))}
        </TableBody>
      </Table>
      <div className={classes.footer}>
        <div
          className={classes.flex}
          style={{ paddingTop: '12px', paddingRight: '10px' }}
        >
          <div className={clsx(classes.grow)} />
          <Button
            variant='contained'
            onClick={handleUpload}
            disabled={uploadClicked}
          >
            Upload
          </Button>
        </div>
      </div>
    </Paper>
  );
}

function MultipleTaskTableRow(props: any) {
  const {
    i,
    file,
    lists,
    tasks,
    index,
    selectedFiles,
    toggleSelectedFile,
    fileProperties,
    setFileProperties,
  } = props;
  const classes = useStyles();

  const [files, setFiles] = useState<any[]>([]);

  const selectedTask = idx(tasks, function getSelectedTask(tasks) {
    return tasks.find(
      (task: any) => task.value === props.fileProperties[i].taskId
    );
  });

  const handleStatusUpdate = (i: number) => {
    const newStatus: any = {
      Unstarted: 'Started',
      Started: 'Prepared',
      Prepared: 'Delivered',
      RejectedExternal: 'Rejected',
      RejectedInternal: 'Rejected',
    };
    var fileProps = fileProperties.slice();
    var ok = tasks.slice();
    var status2 = ok.find((o: any) => o.value === fileProperties[i].taskId)
      .status;
    ok.find((t: any) => t.value == fileProperties[i].taskId).status =
      newStatus[status2];
    fileProps[i].status = newStatus[status2];
    setFileProperties(fileProps);
    props.setTasks(ok);
  };

  const setListId = (listId: any, index: any) => {
    var newFileProperties = fileProperties.slice();
    newFileProperties[i].listId = listId;
    setFileProperties(newFileProperties);
    props.setSelectedListId(listId);
  };

  const setTaskId = (taskId: any, index: any) => {
    var newFileProperties = fileProperties.slice();
    newFileProperties[i].taskId = taskId;
    setFileProperties(newFileProperties);
  };

  const selectedTaskName = idx(tasks, function getSelectedTaskName(tasks) {
    var findSelectedTaskByValue = props.allTasks[fileProperties[i].listId].find(
      (t: any) => t.value === fileProperties[i].taskId
    );
    return `${findSelectedTaskByValue.listNumber}: ${findSelectedTaskByValue.label}`;
  });

  const {
    loading: filesLoading,
    data: filesData,
    error: filesError,
    refetch: filesRefetch,
  } = TaskFiles({ id: selectedTask && selectedTask.value });

  useEffect(() => {
    var taskFiles = idx(filesData, (filesData) => filesData.task.files);
    if (!filesLoading && taskFiles) {
      setFiles(taskFiles);
    }
  }, [filesLoading]);

  const selectedTaskStatus = idx(tasks, (tasks) => {
    return props.allTasks[props.fileProperties[i].listId].find(
      (task: any) => task.value === props.fileProperties[i].taskId
    ).status;
  });

  //for each row... whenever they get a 'incomingList, that should set the listId for each file'
  useEffect(() => {
    var hasListId = fileProperties[i].listId;

    if (props.incomingList && !hasListId) {
      var newFileProperties = fileProperties.slice();
      newFileProperties[i].listId = props.incomingList;
      setFileProperties(newFileProperties);
    }
  }, [props.incomingList]);

  useEffect(() => {
    var hasTaskId = fileProperties[i].taskId;

    if (props.incomingTask && !hasTaskId) {
      var newFileProperties = fileProperties.slice();
      newFileProperties[i].taskId = props.incomingTask;
      setFileProperties(newFileProperties);
    }
  }, [props.incomingTask]);

  const selectedListName = idx(lists, function getSelectedListName(lists) {
    var findSelectedListByValue = lists.find(
      (list: any) => list.value === fileProperties[i].listId
    );
    return findSelectedListByValue.label;
  });

  return (
    <TableRow>
      <TableCell padding='checkbox'>
        <Checkbox
          color='primary'
          checked={selectedFiles.includes(i)}
          onChange={() => toggleSelectedFile(i)}
        />
      </TableCell>
      <TableCell>
        <div className={classes.flex}>
          <FileIcon fileName={file.name} className={classes.mr12} />
          <input
            type='text'
            name='test'
            className={classes.input}
            value={props.fileProperties[i].labelName}
            onChange={(e) => props.handleLabelChange(e, i)}
            autoFocus
          />
        </div>
      </TableCell>
      <TableCell>
        <TitleDropdown
          key={i}
          options={lists}
          value={selectedListName || 'Select Project'}
          index={i}
          //handleUpdate={setListId}
          multi={true}
          handleMulti={setListId}
        />
      </TableCell>
      <TableCell>
        {selectedListName && (
          <TitleDropdown
            //									handleUpdate={(a) =>props.handleTaskUpdate(a, i)}
            key={i}
            index={i}
            handleMulti={setTaskId}
            multi={true}
            options={sortTasks(props.allTasks[fileProperties[i].listId])} // todo: make this less ugly
            value={truncate(selectedTaskName, 20) || 'Select Task'}
          />
        )}
      </TableCell>
      <TableCell size={'small'}>
        {selectedTaskName && (
          <StatusLabel
            onClick={() => handleStatusUpdate(i)}
            currentStatus={selectedTaskStatus}
            selected
          />
        )}
      </TableCell>
      <TableCell>
        <input
          type='text'
          name='test'
          placeholder='Add...'
          className={classes.input}
          value={props.fileProperties[i].comment}
          onChange={(e) => props.handleCommentChange(e, i)}
          autoFocus
        />
      </TableCell>
      <TableCell>
        <VersionDropdown
          short
          files={files}
          index={i}
          setFileProperties={props.setFileProperties}
          fileProperties={fileProperties}
        />
      </TableCell>
    </TableRow>
  );
}

function truncate(s: any, n: any) {
  if (s && s.length > n) return s.substring(0, n) + '...';
  else return s;
}

export default withRouter(MultipleTasks);
