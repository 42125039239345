import React from 'react';

import {
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core';

interface RepeatingProjectDropdownProps {
  onClose?: any;
  onChange: any;
  value: any;
  disabled?: boolean,
  className?: any;
}

const RepeatingProjectDropdown = (props: RepeatingProjectDropdownProps) => {
  const annual = new Date().toLocaleDateString('en-US', {
    month: 'long', 
    day: 'numeric'
  });
  const DATE_OPTIONS = [
      {id: '0', name: 'Do Not Repeat'},
      {id: '1', name: 'Daily'},
      {id: '2', name: 'Weekly on Monday'},
      {id: '3', name: 'Monthly on first business day'},
      {id: '4', name: 'Quarterly on first business day'},
      {id: '5', name: 'Annually on ' + annual},
      {id: '6', name: 'Custom...'}
    ];

  var selected = '0';
  if (props.value==null) {
    selected = '0';
  } else {
    selected = props.value;
  }

  return( 
    <div className={props.className} >
      <FormControl disabled={props.disabled}>
        <Select style={{minWidth: 178, maxWidth: 178}} 
                MenuProps={{
                  transitionDuration: 0,
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  }
                }}
                data-cy='rpt-select'
                disableUnderline 
                value={selected} 
                onClose={props.onClose}
                onChange={props.onChange}>
          { DATE_OPTIONS.map( (option: any, index: number) => {
              return(
                <MenuItem 
                  data-cy={`rpt-` + option.id} 
                  value={option.id}
                  key={index}
                >
                  {option.name}
                </MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
    </div>
  );
}

export { RepeatingProjectDropdown };
