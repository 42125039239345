export interface ILocalState {
    selectedSpace: string | null;
    homePane: boolean;
    reportsPane: boolean;
    filesPane: boolean;
    commentsPane: boolean;
}

export type ICreateModalSelected = 'internalSpace' | 'sharedSpace' | 'folder' | 'dashboard' | 'project' | 'report' | null;

export const createModalOptions = {
    internalSpace: 'Internal Space',
    sharedSpace: 'Shared Space',
    folder: 'Folder',
    dashboard: 'Dashboard',
    project: 'Project',
    report: 'Report'
}

