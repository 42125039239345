// linted -- mpf 11/20
import React from 'react';
import { render } from 'react-dom';

import { InMemoryCache, HttpLink, from, ApolloProvider, ApolloClient, makeVar, gql, NormalizedCacheObject } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from "@apollo/client/link/error";

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';

import theme from './config/theme';
import App from './containers/app';
import { Provider } from './store';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { DETAILED_TASK_INFO } from './graphql/fragments/detailedTaskInfo';
import Auth0ProviderWithHistory from './modules/auth/Auth0ProviderWithHistory';

const getCSRFToken = () => {
  const el = document.querySelector('meta[name="csrf-token"]');
  return (el && el.getAttribute('content')) || '';
}

export enum DueDateGroupingEnum {
  RECENTLY_UPDATED = 'recently updated',
  LATE = 'late',
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  THIS_WEEK = 'this week',
  LATER = 'later',
}

document.addEventListener('DOMContentLoaded', () => {
  const abortController = new AbortController();

  const link = from([
    new RetryLink({
      attempts: {
        max: 5,
        retryIf: (error, _operation) => {
          if (_operation.operationName === 'CurrentUser' && error.statusCode === 401) {
            return false;
          }
          return true;
        }
      }
    }),
    new HttpLink({
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': getCSRFToken()
      },
      fetchOptions: {
        signal: abortController.signal
      }
    })
  ]);

  const invalidTokenLink = onError(({networkError }) => {
    if (networkError) {
      if (`${networkError}`.includes('Received status code 422')) {
        alert('Your session has expired. Please reauthenticate to continue using the application.');
        window.location.href = '/signin';
      }
    }
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          userTasks: offsetLimitPagination(['listIds', 'sectionIds', 'priorities', 'statuses', 'recons', 'entities']),
          task: {
            merge(_existing, incoming) {
              const reactiveTasksData = reactiveTasksDataVar();
              const reactiveTasksQueryInfo = reactiveTasksQueryInfoVar();

              // it's possible these slice methods cause an undefined error if the task is somehow loaded
              // before the arrays are initialized
              const tasksCopy = reactiveTasksData.tasks.slice();
              const tasksBySectionCopy = reactiveTasksData.tasksBySection.slice();
              let tasksByDueDateCopy = reactiveTasksData.tasksByDueDate.slice();

              const taskFragment = client.readFragment({
                id: incoming.__ref,
                fragment: DETAILED_TASK_INFO,
                fragmentName: 'DetailedTaskInfo'
              });

              if (taskFragment && tasksCopy.length) {
                // TODO: Clean up this mess
                const taskIndex = tasksCopy.findIndex((task: any) => task.id === taskFragment.id)
                let sectionIndex;

                if (taskFragment?.section) {
                  sectionIndex = tasksBySectionCopy.findIndex((section: any) => (section.id === taskFragment.section.id) && (section.listId === taskFragment.list.id));
                } else {
                  sectionIndex = tasksBySectionCopy.findIndex((section: any) => (section.id === undefined) && (section.listId === taskFragment.list.id));
                }

                // Update all task group by section to includes new tasks
                if (sectionIndex !== -1) {
                  if (taskIndex !== -1) {
                    tasksCopy[taskIndex] = { ...tasksCopy[taskIndex], ...taskFragment }
                    const taskInSectionIndex = tasksBySectionCopy[sectionIndex].tasks.findIndex((task: any) => task.id === taskFragment.id);
                    tasksBySectionCopy[sectionIndex].tasks[taskInSectionIndex] = { ...tasksBySectionCopy[sectionIndex].tasks[taskIndex], ...taskFragment };
                  } else {
                    tasksCopy.push(taskFragment);
                    tasksBySectionCopy[sectionIndex]?.tasks.push(taskFragment);
                  }
                }

                // Update the tasks in place
                // Cache should update locally before this merge function is called
                tasksByDueDateCopy = tasksByDueDateCopy.map((dueDateGroup: any) => {
                  return {
                    ...dueDateGroup,
                    tasks: dueDateGroup.tasks.map((task: any) => {
                      if (task.id === taskFragment.id) {
                        return {
                          ...task,
                          ...taskFragment
                        }
                      }

                      return task;
                    })
                  }
                })

                const taskModifiedDate = new Date((taskFragment.modifiedAtTime * 1000))
                if (isRecentlyUpdated(taskModifiedDate)) {
                  if (!tasksByDueDateCopy[0].tasks.some((task: any) => task.id === taskFragment.id)) {
                    tasksByDueDateCopy[0].tasks.push(taskFragment);
                  }
                }

                // selectedTask check here if user closes sidepanel before the call finishes
                reactiveTasksDataVar({
                  ...reactiveTasksData,
                  tasks: tasksCopy,
                  tasksBySection: tasksBySectionCopy,
                  tasksByDueDate: tasksByDueDateCopy,
                  selectedTask: reactiveTasksData.selectedTask ? { ...reactiveTasksData.selectedTask, ...tasksCopy.find((task: any) => task.id === taskFragment.id) } : null
                });

                reactiveTasksQueryInfoVar({
                  ...reactiveTasksQueryInfo,
                  selectedTaskLoading: false
                })
              }
            }
          },
          /**
           * This needs massively refactored
           */
          taskPageUserTasks: {
            merge(existing, incoming, options) {
              const tasks = existing ? existing.slice() : [];

              const reactiveTasksData = reactiveTasksDataVar();
              const reactiveTasksQueryInfo = reactiveTasksQueryInfoVar();
              // Decided to add the new fields to the old fields here
              // Adding the new version of tasks each time was causing data to be reset
              const tasksCopy = reactiveTasksData?.tasks?.slice() || [];
              const tasksBySectionCopy = reactiveTasksData?.tasksBySection?.slice() || [];
              const tasksByDueDateCopy = reactiveTasksData?.tasksByDueDate?.slice() || [];

              const newTasks: any = []

              // Gather all of the tasks for the incoming refs
              // I wish there was a better way to do this, but not yet unfortunately
              incoming.edges.forEach((edge: any) => {
                const taskFragment = client.readFragment({
                  id: edge.node.__ref,
                  fragment: gql`
                  fragment TaskFragment on Task {
                    id
                    name @include(if: ${options.variables?.include_name})
                    description @include(if: ${options.variables?.include_description})
                    currency @include(if: ${options.variables?.include_currency})
                    hasFiles @include(if: ${options.variables?.include_hasFiles})
                    hasMessages @include(if: ${options.variables?.include_hasMessages})
                    companyPosition @include(if: ${options.variables?.include_companyPosition})
                    currentUserIsReviewer @include(if: ${options.variables?.include_currentUserIsReviewer})
                    responderDelivered @include(if: ${options.variables?.include_responderDelivered})
                    hasUnreadMessages @include(if: ${options.variables?.include_hasUnreadMessages})
                    modifiedAtText @include(if: ${options.variables?.include_modifiedAtText})
                    modifiedAtTime @include(if: ${options.variables?.include_modifiedAtTime})
                    startDate @include(if: ${options.variables?.include_startDate})
                    dueDate @include(if: ${options.variables?.include_dueDate})
                    status @include(if: ${options.variables?.include_status})
                    completedStatusKey @include(if: ${options.variables?.include_completed_status_key})
                    priority @include(if: ${options.variables?.include_priority})
                    primaryTotal @include(if: ${options.variables?.include_primaryTotal})
                    secondaryTotal @include(if: ${options.variables?.include_secondaryTotal})
                    primarySourceType @include(if: ${options.variables?.include_primarySourceType})
                    secondarySourceType @include(if: ${options.variables?.include_secondarySourceType})
                    primaryFileReconSources @include(if: ${options.variables?.include_primaryFileReconSources}) {
                      id
                      sourceType
                      fileVersion {
                        id
                        fileName
                        fileLocation
                        alterable
                      }
                      name
                      isCurrent
                      description
                      date
                      value
                      associatedTaskIds
                    }
                    secondaryFileReconSources @include(if: ${options.variables?.include_secondaryFileReconSources}) {
                      id
                      sourceType
                      fileVersion {
                        id
                        fileName
                        fileLocation
                        alterable
                      }
                      name
                      isCurrent
                      description
                      date
                      value
                      associatedTaskIds
                    }
                    reconcilingItems @include(if: ${options.variables?.include_reconcilingItems}) {
                      id
                      sourceType
                      fileVersion {
                        id
                        fileName
                        fileLocation
                        alterable
                      }
                      name
                      isCurrent
                      description
                      date
                      value
                      associatedTaskIds
                    }
                    reconcilingItemTotal @include(if: ${options.variables?.include_reconcilingItemTotal})
                    reconDifference @include(if: ${options.variables?.include_reconDifference})
                    reconciliations @include(if: ${options.variables?.include_reconciliations})
                    isReconciled @include(if: ${options.variables?.include_isReconciled})
                    hasUnseenFiles @include(if: ${options.variables?.include_hasUnseenFiles})
                    listNumber @include(if: ${options.variables?.include_listNumber})
                    isBlocked @include(if: ${options.variables?.include_isBlocked})
                    dependencies @include(if: ${options.variables?.include_dependencies}) {
                      id
                      relatedId
                      relatedName
                      relatedDueDate
                      taskId
                      taskName
                      taskDueDate
                      dueDays
                      dueHours
                      hasStartDate
                      status
                      isDependent
                      relatedListNumber
                      taskListNumber
                    }
                    section @include(if: ${options.variables?.include_section}) {
                      id
                      name
                    }
                    processSteps @include(if: ${options.variables?.include_processSteps}) {
                      id
                      taskId
                      description
                      position
                      completed
                      files {
                        id
                        fileName
                        fileLocation
                        viewed
                        alterable
                        updatedAt
                      }
                    }
                    list @include(if: ${options.variables?.include_list}) {
                      id
                      multipleReviewers
                      name
                      rank
                      reconciliations
                      currentUserIsOwner
                      deliverFileOnUpload
                      requesterCompany {
                        id
                        name
                      }
                      responderCompany {
                        id
                        name
                      }
                      schedule {
                        id
                        repeatType
                        calendarType  
                        repeatInterval
                      }
                      estimatedStartDate
                      estimatedEndDate
                    }
                    files @include(if: ${options.variables?.include_files}) {
                      id
                      fileName
                      fileLocation
                      viewed
                      alterable
                      updatedAt
                      taskId
                      createdAt
                      isCheckedOut
                      checkedOutBy{
                        fullName
                        displayName
                      }
                      checkedOutAt
                      companyId
                    }
                    messages @include(if: ${options.variables?.include_messages}) {
                      id
                      isPublic
                      message
                      createdAt
                      task {
                        id
                      }
                      company {
                        id
                      }
                      viewed
                      user {
                        id
                        fullName
                        displayName
                        email
                        profileUrl
                      }
                      resolveDate
                      resolveUser {
                        fullName
                        profileUrl
                      }
                      resolveStatusOption {
                        id
                        description
                        resolveObject {
                          id
                          description
                        }
                      }
                      resolveStatusDescription
                      isEdited
                    }
                    tags @include(if: ${options.variables?.include_tags}) {
                      id
                      name
                    }
                    classifications @include(if: ${options.variables?.include_classifications}) {
                      id
                      name
                    }
                    userOwners @include(if: ${options.variables?.include_userOwners}) {
                      id
                      displayName
                      profileUrl
                    }
                    teamOwners @include(if: ${options.variables?.include_teamOwners}) {
                      id
                      name
                    }
                    firstUserReviewers @include(if: ${options.variables?.include_firstUserReviewers}) {
                      id
                      displayName
                      profileUrl
                    }
                    secondUserReviewers @include(if: ${options.variables?.include_secondUserReviewers}) {
                      id
                      displayName
                      profileUrl
                    }
                    firstTeamReviewers @include(if: ${options.variables?.include_firstTeamReviewers}) {
                      id
                      name
                    }
                    secondTeamReviewers @include(if: ${options.variables?.include_secondTeamReviewers}) {
                      id
                      name
                    }
                    schedule @include(if: ${options.variables?.include_schedule}) {
                      id
                      repeatType
                      repeatNumber
                      repeatInterval
                      calendarType
                      ends
                      endsOn
                      endsAfter
                      selectedWeekday
                      selectedMonthday
                      selectedMonth
                      selectedYearday
                      selectedSecond
                      nextRunningDate
                    }
                  }
                `,
                fragmentName: 'TaskFragment'
                });

                if (taskFragment) {
                  const existingTaskIndex = tasks.findIndex((task: any) => task.id === taskFragment.id);

                  if (existingTaskIndex !== -1) {
                    tasks[existingTaskIndex] = taskFragment;
                  } else {
                    tasks.push(taskFragment);
                    tasksCopy.push(taskFragment);
                    newTasks.push(taskFragment)
                  }
                }
              });

              // const tasksGroupedBySection = groupBy(Object.values(tasks), (task: any) => [
              //   idx(task, t => t.list.name),
              // ]);

              // Need to move this to a helper function in the future
              // const tasksGroupedBySection: any = [];

              // Might need to sort by list rank or id
              newTasks.forEach((newTask: any) => {
                let sectionIndex;

                if (newTask?.section) {
                  sectionIndex = tasksBySectionCopy.findIndex((section: any) => (section.id === newTask.section.id) && (section.listId === newTask.list.id));
                } else {
                  sectionIndex = tasksBySectionCopy.findIndex((section: any) => (section.id === undefined) && (section.listId === newTask.list.id));
                }

                if (sectionIndex !== -1) {
                  tasksBySectionCopy[sectionIndex].tasks.push(newTask)
                } else {
                  const newSection = {
                    id: newTask?.section?.id,
                    name: newTask?.section?.name || 'No Section',
                    listId: newTask?.list?.id,
                    listName: newTask?.list?.name,
                    listRank: newTask?.list?.rank,
                    currentUserIsOwner: newTask?.list?.currentUserIsOwner || false,
                    tasks: [newTask],
                    newTaskActive: false
                  };

                  tasksBySectionCopy.push(newSection);
                }

                const taskModifiedDate = new Date((newTask.modifiedAtTime * 1000))
                if (isRecentlyUpdated(taskModifiedDate)) {
                  tasksByDueDateCopy[0].tasks.push(newTask);
                }

                const matchingDueDateGroups = getDueDateGroups(newTask.dueDate);

                matchingDueDateGroups.forEach((matchingDueDateGroupType: any) => {
                  const matchingDueDateGroup = tasksByDueDateCopy.find((dueDateGroup: any) => dueDateGroup.type === matchingDueDateGroupType);

                  matchingDueDateGroup.tasks.push(newTask);
                })
              });

              tasksBySectionCopy.sort((sectionA: any, sectionB: any) => {
                if (sectionA.listRank === sectionB.listRank) {
                  // sorts the section by the lowest task list number
                  if (sectionA.tasks?.length === 0 && sectionB.tasks?.length === 0) {
                    return 0;
                  };

                  if (sectionA.tasks?.length === 0) {
                    return 1;
                  }

                  if (sectionB.tasks?.length === 0) {
                    return -1;
                  }
                  const sectionALowestListNumber = sectionA.tasks.map((task: any) => task.listNumber).reduce((prevListNumber: number, nextListNumber: number) => Math.min(prevListNumber, nextListNumber));
                  const sectionBLowestListNumber = sectionB.tasks.map((task: any) => task.listNumber).reduce((prevListNumber: number, nextListNumber: number) => Math.min(prevListNumber, nextListNumber));

                  return sectionALowestListNumber - sectionBLowestListNumber;
                } else {
                  return sectionA.listRank - sectionB.listRank
                }
              })

              reactiveTasksDataVar({
                ...reactiveTasksData,
                tasks: tasksCopy,
                tasksBySection: tasksBySectionCopy,
                tasksByDueDate: tasksByDueDateCopy
              });

              reactiveTasksQueryInfoVar({
                ...reactiveTasksQueryInfo,
                tasksCursor: incoming.pageInfo.hasNextPage ? incoming.edges[incoming.edges.length - 1].cursor : null,
                tasksLoading: incoming.pageInfo.hasNextPage
              })

              return tasks;
            },

            read(existing) {
              return existing;
            }
          },
          taskPageUserTasksRemainingFields: {
            merge(existing, incoming, options) {
              const tasks = existing ? existing.slice() : [];

              const reactiveTasksData = reactiveTasksDataVar();
              let tasksCopy = reactiveTasksData?.tasks?.slice() || [];
              let tasksBySectionCopy = reactiveTasksData?.tasksBySection?.slice() || [];
              let tasksByDueDateCopy = reactiveTasksData?.tasksByDueDate?.slice() || [];

              const newTasks: any = []

              incoming.edges.forEach((edge: any) => {
                const taskFragment = client.readFragment({
                  id: edge.node.__ref,
                  fragment: gql`
                  fragment TaskFragment on Task {
                    id
                    name @include(if: ${options.variables?.include_name})
                    description @include(if: ${options.variables?.include_description})
                    currency @include(if: ${options.variables?.include_currency})
                    hasFiles @include(if: ${options.variables?.include_hasFiles})
                    hasMessages @include(if: ${options.variables?.include_hasMessages})
                    companyPosition @include(if: ${options.variables?.include_companyPosition})
                    currentUserIsReviewer @include(if: ${options.variables?.include_currentUserIsReviewer})
                    responderDelivered @include(if: ${options.variables?.include_responderDelivered})
                    hasUnreadMessages @include(if: ${options.variables?.include_hasUnreadMessages})
                    modifiedAtText @include(if: ${options.variables?.include_modifiedAtText})
                    modifiedAtTime @include(if: ${options.variables?.include_modifiedAtTime})
                    startDate @include(if: ${options.variables?.include_startDate})
                    dueDate @include(if: ${options.variables?.include_dueDate})
                    status @include(if: ${options.variables?.include_status})
                    completedStatusKey @include(if: ${options.variables?.include_completed_status_key})
                    priority @include(if: ${options.variables?.include_priority})
                    primaryTotal @include(if: ${options.variables?.include_primaryTotal})
                    secondaryTotal @include(if: ${options.variables?.include_secondaryTotal})
                    primarySourceType @include(if: ${options.variables?.include_primarySourceType})
                    secondarySourceType @include(if: ${options.variables?.include_secondarySourceType})
                    primaryFileReconSources @include(if: ${options.variables?.include_primaryFileReconSources}) {
                      id
                      sourceType
                      fileVersion {
                        id
                        fileName
                        fileLocation
                        alterable
                      }
                      name
                      isCurrent
                      description
                      date
                      value
                      associatedTaskIds
                    }
                    secondaryFileReconSources @include(if: ${options.variables?.include_secondaryFileReconSources}) {
                      id
                      sourceType
                      fileVersion {
                        id
                        fileName
                        fileLocation
                        alterable
                      }
                      name
                      isCurrent
                      description
                      date
                      value
                      associatedTaskIds
                    }
                    reconcilingItems @include(if: ${options.variables?.include_reconcilingItems}) {
                      id
                      sourceType
                      fileVersion {
                        id
                        fileName
                        fileLocation
                        alterable
                      }
                      name
                      isCurrent
                      description
                      date
                      value
                      associatedTaskIds
                    }
                    reconcilingItemTotal @include(if: ${options.variables?.include_reconcilingItemTotal})
                    reconDifference @include(if: ${options.variables?.include_reconDifference})
                    reconciliations @include(if: ${options.variables?.include_reconciliations})
                    isReconciled @include(if: ${options.variables?.include_isReconciled})
                    hasUnseenFiles @include(if: ${options.variables?.include_hasUnseenFiles})
                    listNumber @include(if: ${options.variables?.include_listNumber})
                    isBlocked @include(if: ${options.variables?.include_isBlocked})
                    dependencies @include(if: ${options.variables?.include_dependencies}) {
                      id
                      relatedId
                      relatedName
                      relatedDueDate
                      taskId
                      taskName
                      taskDueDate
                      dueDays
                      dueHours
                      hasStartDate
                      status
                      isDependent
                      relatedListNumber
                      taskListNumber
                    }
                    section @include(if: ${options.variables?.include_section}) {
                      id
                      name
                    }
                    processSteps @include(if: ${options.variables?.include_processSteps}) {
                      id
                      taskId
                      description
                      position
                      completed
                      files {
                        id
                        fileName
                        fileLocation
                        viewed
                        alterable
                        updatedAt
                      }
                    }
                    list @include(if: ${options.variables?.include_list}) {
                      id
                      multipleReviewers
                      name
                      rank
                      reconciliations
                      currentUserIsOwner
                      deliverFileOnUpload
                      requesterCompany {
                        id
                        name
                      }
                      responderCompany {
                        id
                        name
                      }
                      schedule {
                        id
                        repeatType
                        calendarType  
                        repeatInterval
                      }
                      estimatedStartDate
                      estimatedEndDate
                    }
                    files @include(if: ${options.variables?.include_files}) {
                      id
                      fileName
                      fileLocation
                      viewed
                      alterable
                      updatedAt
                      taskId
                      createdAt
                      isCheckedOut
                      checkedOutBy{
                        fullName
                        displayName
                      }
                      checkedOutAt
                      companyId
                    }
                    messages @include(if: ${options.variables?.include_messages}) {
                      id
                      isPublic
                      message
                      createdAt
                      task {
                        id
                      }
                      company {
                        id
                      }
                      viewed
                      user {
                        id
                        fullName
                        displayName
                        email
                        profileUrl
                      }
                      resolveDate
                      resolveUser {
                        fullName
                        profileUrl
                      }
                      resolveStatusOption {
                        id
                        description
                        resolveObject {
                          id
                          description
                        }
                      }
                      resolveStatusDescription
                      isEdited
                    }
                    tags @include(if: ${options.variables?.include_tags}) {
                      id
                      name
                    }
                    classifications @include(if: ${options.variables?.include_classifications}) {
                      id
                      name
                    }
                    userOwners @include(if: ${options.variables?.include_userOwners}) {
                      id
                      displayName
                      profileUrl
                    }
                    teamOwners @include(if: ${options.variables?.include_teamOwners}) {
                      id
                      name
                    }
                    firstUserReviewers @include(if: ${options.variables?.include_firstUserReviewers}) {
                      id
                      displayName
                      profileUrl
                    }
                    secondUserReviewers @include(if: ${options.variables?.include_secondUserReviewers}) {
                      id
                      displayName
                      profileUrl
                    }
                    firstTeamReviewers @include(if: ${options.variables?.include_firstTeamReviewers}) {
                      id
                      name
                    }
                    secondTeamReviewers @include(if: ${options.variables?.include_secondTeamReviewers}) {
                      id
                      name
                    }
                    schedule @include(if: ${options.variables?.include_schedule}) {
                      id
                      repeatType
                      repeatNumber
                      repeatInterval
                      calendarType
                      ends
                      endsOn
                      endsAfter
                      selectedWeekday
                      selectedMonthday
                      selectedMonth
                      selectedYearday
                      selectedSecond
                      nextRunningDate
                    }
                  }
                `,
                fragmentName: 'TaskFragment'
                });

                if (taskFragment) {
                  const existingTaskIndex = tasks.findIndex((task: any) => task.id === taskFragment?.id);
                  if (existingTaskIndex !== -1) {
                    tasks[existingTaskIndex] = taskFragment;
                  } else {
                    tasks.push(taskFragment);
                  }
                  const taskIndex = tasksCopy.findIndex((task: any) => task.id === taskFragment?.id);
                  tasksCopy[taskIndex] = { ...tasksCopy[taskIndex], ...taskFragment };
                  tasksBySectionCopy = tasksBySectionCopy.map((section: any) => {
                    return {
                      ...section,
                      tasks: section.tasks.map((task: any) => {
                        if (task.id === taskFragment?.id) {
                          return { ...task, ...taskFragment}
                        }
                        return task;
                      })
                    }
                  });
                  tasksByDueDateCopy = tasksByDueDateCopy.map((dueGroup: any) => {
                    return {
                      ...dueGroup,
                      tasks: dueGroup.tasks.map((task: any) => {
                        if (task.id === taskFragment?.id) {
                          return { ...task, ...taskFragment}
                        }
                        return task;
                      })
                    }
                  });
                }
              });
              reactiveTasksDataVar({
                ...reactiveTasksData,
                tasks: tasksCopy,
                tasksBySection: tasksBySectionCopy,
                tasksByDueDate: tasksByDueDateCopy
              });
              return tasks;
            },
            read(existing) {
              return existing;
            }
          }
        }
      }
    }
  });
  const client = new ApolloClient<NormalizedCacheObject>({
    cache,
    connectToDevTools: true,
    link: from([link]),
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      }
    }
  });

  render(
    <Provider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/*
            The Auth0ProviderWithHistory component has been introduced to wrap
            the App component by the `react-auth0` library used for the Okta authentication SSO.
          */}
          <Auth0ProviderWithHistory>
            <App />
          </Auth0ProviderWithHistory>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>,
    document.body.appendChild(document.createElement('div'))
  );
});

export const reactiveTasksDataVar: any = makeVar({
  tasks: [],
  tasksBySection: [],
  tasksByDueDate: [
    {
      type: DueDateGroupingEnum.RECENTLY_UPDATED,
      name: 'Recently updated',
      tableRank: 0,
      tasks: []
    },
    {
      type: DueDateGroupingEnum.LATE,
      name: 'Past due',
      tableRank: 1,
      tasks: []
    },
    {
      type: DueDateGroupingEnum.TODAY,
      name: 'Due today',
      tableRank: 2,
      tasks: []
    },
    {
      type: DueDateGroupingEnum.TOMORROW,
      name: 'Due tomorrow',
      tableRank: 3,
      tasks: []
    },
    {
      type: DueDateGroupingEnum.THIS_WEEK,
      name: 'Due this week',
      tableRank: 4,
      tasks: []
    },
    {
      type: DueDateGroupingEnum.LATER,
      name: 'Due later',
      tableRank: 5,
      tasks: []
    }
  ],
  selectedTask: null
})

export const reactiveTasksQueryInfoVar: any = makeVar({
  tasksCursor: null,
  tasksLoading: false,
  selectedTaskLoading: false
})

const isRecentlyUpdated = (taskModifiedDate: Date) => {
  const currentDate = new Date();
  const hoursSinceModified = Math.abs((currentDate.getTime() - taskModifiedDate.getTime()) / (1000 * 60 * 60));
  return hoursSinceModified <= 72;
}

export const getDueDateGroups = (taskDueDateString: string | null) => {
  if (!taskDueDateString) return [DueDateGroupingEnum.LATER];
  const matchingDueDateGroups: string[] = [];
  const currentDate = new Date();
  const currentDateWithoutTime = new Date(currentDate.toDateString());

  const taskDueDate = new Date(taskDueDateString);

  const taskDueDateWithoutTime = new Date(taskDueDate.toDateString());
  const daysFromDueDate = Math.floor((taskDueDateWithoutTime.getTime() - currentDateWithoutTime.getTime()) / (1000 * 60 * 60 * 24));

  if (daysFromDueDate < 0) matchingDueDateGroups.push(DueDateGroupingEnum.LATE);
  if (daysFromDueDate >= 0 && daysFromDueDate < 1) matchingDueDateGroups.push(DueDateGroupingEnum.TODAY);
  if (daysFromDueDate >= 1 && daysFromDueDate < 2) matchingDueDateGroups.push(DueDateGroupingEnum.TOMORROW);
  if (daysFromDueDate >= 2 && daysFromDueDate < 7) matchingDueDateGroups.push(DueDateGroupingEnum.THIS_WEEK);
  if (daysFromDueDate >= 7) matchingDueDateGroups.push(DueDateGroupingEnum.LATER);

  if (matchingDueDateGroups.length === 0) {
    matchingDueDateGroups.push(DueDateGroupingEnum.LATER);
  }

  return matchingDueDateGroups;
}
