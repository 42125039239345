import {useDispatchContext} from '../store';
import {useSetFilters} from './SetFilters';

export function useRejectMessageNavigation() {
  const setFilters = useSetFilters();
  const dispatch = useDispatchContext();

  return function(taskId: string, paneType: string) {
    if (!taskId || !paneType) {
      return;
    }

    //Breaking changes for old task page here
    const filters = {
      customTaskPageSelectedTaskIds: [taskId],
      taskPagePane: 2
    };
    setFilters(filters);

    dispatch({
      type: 'SET_REJECTED_TASK',
      rejectedTask: paneType,
    });
  };
}
