import {
  ClickAwayListener,
  List,
  makeStyles,
  Popper,
  TextField,
  Typography,
  IconButton
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { Check, Clear } from "@material-ui/icons";

const useStyles = makeStyles({
  listItem: {
    marginBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "rgb(235, 242, 255)",
    },
  },
  input: {
    width: "100%",
    paddingLeft: 15,
    paddingRight: 15,
  },
  list: {
    position: "absolute",
    backgroundColor: "#FFFFFF",
    border: "1px solid #D8D8D8",
    borderRadius: "3px",
    zIndex: 32,
    paddingBottom: 12,
    paddingTop: 5,
    width: 375,
  },
  check: {
    color: "blue",
    marginLeft: "10px",
  },
  hidden: {
    visibility: "hidden",
  },
  taskNameFilter: {
    display: "flex",
    flexDirection: "row",
  },
  clearButton: {
    border: 0,
    borderRadius: 0,
    margin: "0px",
  },
  root: {
    "&:hover": {
      border: 0,
    }
  },
});

interface ITaskNameFilterPopper {
  closePopper: () => void;
  debouncedNameFilter?: any;
  handleNameSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  anchorEl: any;
  handleSortAsc: () => void;
  handleSortDesc: () => void;
  nameSearchValue: string;
  containerStyle?: React.CSSProperties;
  sortByName: string | null;
  clearInput?: React.MouseEventHandler<HTMLButtonElement>;
  onCloseDataCy?: string;
}

export const TaskNameFilterPopper = (props: ITaskNameFilterPopper) => {
  const {
    anchorEl,
    closePopper,
    debouncedNameFilter,
    handleNameSearch,
    handleSortAsc,
    handleSortDesc,
    clearInput,
    nameSearchValue,
    containerStyle,
    sortByName,
    onCloseDataCy
  } = props;
  const classes = useStyles();
  
  const [localNameFilter, setLocalNameFilter] = useState<string>(nameSearchValue); 

  const handleNameFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const nameFilter = event.target.value as string;
    const lowerCaseNameFilter = nameFilter.toLowerCase();

    setLocalNameFilter(lowerCaseNameFilter);
    debouncedNameFilter(lowerCaseNameFilter);
  };

  const clearNameFilter = () => {
    setLocalNameFilter('');
    debouncedNameFilter('');
  }

  return (
    <Popper
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      style={{ zIndex: 1203 }}
      placement={"bottom-start"}
    >
      <ClickAwayListener onClickAway={closePopper}>
        <List style={containerStyle} className={classes.list}>
          <div onClick={handleSortAsc} className={classes.listItem}>
            <Typography>Sort A to Z</Typography>
            <Check
              className={clsx(
                classes.check,
                sortByName !== "ASC" && classes.hidden
              )}
            />
          </div>
          <div onClick={handleSortDesc} className={classes.listItem}>
            <Typography>Sort Z to A</Typography>
            <Check
              className={clsx(
                classes.check,
                sortByName !== "DESC" && classes.hidden
              )}
            />
          </div>
          {/** Displays the clear button for the input field only if clearInput prop exists and nameSearchValue exists */}
          <TextField
            id="standard-basic"
            placeholder="Filter by task name"
            className={classes.input}
            onChange={debouncedNameFilter ? handleNameFilterChange : handleNameSearch}
            value={debouncedNameFilter ? localNameFilter : nameSearchValue}
            InputProps={clearInput && {
              endAdornment: (<IconButton
              className={classes.clearButton} 
              classes={{
                root: classes.root
              }}              
              disableRipple
              disableFocusRipple={true}
              onClick={debouncedNameFilter ?  clearNameFilter : clearInput}
              style={{padding: '3px', display: localNameFilter ? 'block' : 'none'}}
              data-cy={onCloseDataCy}
            >
              <Clear style={{fontSize: '15px'}} />
            </IconButton>)
            }}
          />
        </List>
      </ClickAwayListener>
    </Popper>
  );
};
