import { useEffect, useState } from 'react';
import {gql, useMutation} from '@apollo/client';

const mutation = gql`
  mutation BulkAliasAssign(
    $listId: ID!
    $sourceType: String!
    $sourceAlias: String!
  ) {
    bulkAliasAssign(
      listId: $listId
      sourceType: $sourceType
      sourceAlias: $sourceAlias
    ) {
      success
    }
  }
`;

export function useBulkAliasAssign() {
  const [sweet, setSweet] = useState<any>({});
  const [ update, response ] = useMutation(mutation);

  useEffect(() => {
    if (sweet.listId) {
      const variables: any = { 
        listId: sweet.listId,
        sourceType: sweet.sourceType,
        sourceAlias: sweet.sourceAlias
      }
      update({ variables });
    }
  }, [sweet])

  return function(cool: any) {
    setSweet(cool)
    return response;
  }
}
