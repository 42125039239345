export const PRODUCTION = 'production';
export const DEVELOPMENT = 'development';
export const STAGING = 'staging';
export const TAILWIND_CSS = 'Tailwind CSS';

// Used for the PrepDD automation in the Task Zero Balance acceptance case.
// Used in all the `TimeLineNotification.tsx` components for now.
export const AUTOMATION_TITLE = "PrepDD Workflow Automation";
export const PREPDD_LOGO = require('images/logos/prepdd-logo.svg');
export const RDS_MESSAGE_BASE_URL = 'https://mk04owoex8.execute-api.us-east-2.amazonaws.com/default/PrepddMsgSvc';
export const RDS_MESSAGE_KEY = '9GCHYOQqA23WhlQ2RWJz09rAsPaeXmlx7l3Zsoz8';
export const DECRYPT_MESSAGE_FAILED_FALLBACK = 'unable to load message, please try refreshing your page';

export const chartColors:any =  {
  'behind': '#F6AF45',
  'ontime': '#80B63E',
  'ahead': '#80B63E'
};

export interface RepeatTaskTpl {
    id: string;
    name: string;
}

// Repeating tasks list template
export const REPEAT_TASKS_TPL: RepeatTaskTpl[] = [
    {id: '0', name: 'Does not repeat'},
    {id: '1', name: 'Repeats daily'},
    {id: '1_1.5', name: 'Repeats every weekday'},
    {id: '2_', name: 'Repeats weekly on _'},
    {id: '7', name: 'Repeats semi-monthly'},
    {id: '3_', name: 'Repeats monthly on _'},
    {id: '4', name: 'Repeats quarterly'},
    {id: '5_', name: 'Repeats annually on _'},
    {id: '5', name: 'Repeats annually'}
]

export enum SCHEDULE_REPEATING_TYPES {
    NO_REPEAT = "0",
    DAILY = "1",
    WEEKLY = "2",
    MONTHLY = "3",
    QUARTERLY = "4",
    ANNUALLY = "5",
    CUSTOM = "6"
};
/**
 * The callback URL for Okta authentication. It determines the URL where Okta will
 * redirect the user after a successful login. The value is determined based on
 * the current environment (development or production).
 *
 * @type {string}
 */
export const OKTA_AUTH0_CALLBACK_URL = window.location.origin + "/okta-status";

/**
 * The key name used to store the Okta OAuth configuration data in the application.
 * This key is used when saving and retrieving the configuration from local storage
 * or other data storage mechanisms.
 *
 * @type {string}
 */
export const OKTA_CONFIG_KEY_NAME = "prepdd_okta_oauth_config";

export const AUTH_ROUTES = [
    "/user/invitation/", "/signup/", "/signin", "/forgot",
    "/reset_password/", "/linkedin", "/newco", "/okta-sign-in", "/okta-status"
];

export const AUTH_TIMEOUT = 1000 * 60 * 240; // 4 hours
export const AUTH_TIMEOUT_ALERT_DURATION = 1000 * 60 * 5; // 5 minutes
export const STATUSES_OPTIONS: any = {
    Unstarted: {
      label: 'Not Started',
      hover: 'Start',
      color: '#0E3B5C',
    },
    Rejected: {
      label: 'Rejected',
      hover: 'Re-start',
      color: '#FF507C',
    },
    RejectedExternal: {
      label: 'Rejected',
      hover: 'Re-start',
      color: '#FF507C',
    },
    RejectedInternal: {
      label: 'Rejected',
      hover: 'Re-start',
      color: '#FF507C',
    },
    NotApplicable: {
      label: 'N/A',
      hover: 'N/A',
      color: '#FF507C',
    },
    Started: {
      label: 'In Progress',
      hover: 'Complete',
      color: '#3A84FF',
    },
    Prepared: {
      label: 'Prepared',
      hover: 'Delivered',
      color: 'rgba(80,158,109,0.50)',
    },
    Delivered: {
      label: 'Delivered',
      hover: 'Accept',
      color: '#509E6D',
    },
    Complete: {
      label: 'Complete',
      hover: 'Complete',
      color: '#6EB81D',
    }
};
export const NOT_FOUND_STATUS: any = {
  label: 'Not found',
  hover: 'Not found',
  color: '#b4b4b4',
};
export const STATUSES_KEYS_EXTENDS = Object.keys(STATUSES_OPTIONS);
export const STATUSES_KEYS = STATUSES_KEYS_EXTENDS.filter((key) => !["RejectedInternal", "RejectedExternal"].includes(key));
export const NOT_APPLIC_STATUS_KEYS = ["NotApplicable", "n_a", "notapplicable", "not_applicable"];