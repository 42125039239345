import { createMutationHook, gql } from '../graphqlHelpers';

export const useArchiveLists = createMutationHook(gql`
  mutation ArchiveLists(
    $listIds: [ID!]!
  ) {
    archiveLists(
      listIds: $listIds
    ) {
      success
      }
    }
`);
