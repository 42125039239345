import React, { useState, useEffect } from 'react';
import DefaultUserImage from './DefaultUserImage';
import clsx from 'clsx';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '30px',
      height: '30px',
      borderRadius: '50%'
    },
  })
);

// if a user doesn't have a profileUrl, or if the ProfileUrl is a 404. use the default image
interface UserImageProps {
  user: any;
  size?: any;
  style?: any;
  className?: string;
  IconInAvatar?: React.FC<any>
}

export default function UserImage(props: UserImageProps) {
  const { user, size, IconInAvatar } = props;
  const classes = useStyles();

  const { ...propsIconInAvatar } = IconInAvatar || {};

  return(
    user && user.profileUrl ?
      <>
        <img
          className={clsx(classes.root, props.className)}
          style={props.style}
          src={user.profileUrl} 
        /> 
        {IconInAvatar ?
          <IconInAvatar propsIconInAvatar /> 
          : null}
      </>
    :
      user &&
      <DefaultUserImage
        className={clsx(classes.root, props.className)}
        style={props.style}
        userName={user.fullName || user.displayName || user.email || user.name || '    '}
        IconInAvatar={IconInAvatar}
      />
  );
}
