import {useQuery, useLazyQuery} from '@apollo/client';
import {TaskStatus} from '../../constants/types';
import {createLazyQueryHook, createQueryHook, gql} from '../graphqlHelpers';

export interface Owner {
  displayName: string;
  email: string;
  id: string;
  profileUrl: string | null;
  __typename: string | 'Team' | 'User';
  fullName: string;
  roles: {name: string}[];
  currentCompany: {id: string};
}

export interface Task {
  description: string;
  dueDate: string;
  id: string;
  name: string;
  priority: string;
  companyPosition: CompanyPosition;
  status: TaskStatus;
  list: {
    name: string
  }
  tags: [{
    id: string,
    tag: string
  }],
  section: {
    id: string;
    name: string;
  } | null;
}

export enum CompanyPosition {
  responder = 'responder',
  requester = 'requester',
  internal = 'internal',
}

export interface UserListProject {
  id: string;
  reconciliations: string;
  name: string;
  modifiedAt: string;
  currentUserIsOwner: boolean;
  estimatedEndDate: string;
  reconEndDate: string | null;
  isDeleted: boolean;
  isRetained: boolean;
  isArchived: boolean;
  retentionEnd: string | null;
  isPastRetention: boolean;
  isPastEndDate: boolean;
  totalTasks: number;
  schedule:{
    repeatType: string,
    id: string,
    calendarType: string,
    ends: string,
    endsOn: string,
    endsAfter: number,
    repeatNumber: string,
    repeatInterval: string,
    copyRoles: string,
    copyFiles: string,
    copyMessages: string,
    copyDueDates: string,
    copyStatuses: string,
    selectedWeekday: number,
    selectedMonthday: number,
    selectedMonth: number
  };

  completeTasks: number;
  companyPosition: CompanyPosition;
  rankrank: number;
  notifications: any[]; //TODO type this from notification;
  retentionPolicy: any; //TODO type this from Policy
  tasks: Task[];
  owners: Owner[];
  externalOwners: Owner[];
  sharedCompany: {id: string; logoUrl: string; name: string};
  requesterCompany: {id: string; name: string};
  responderCompany: {id: string; name: string} | null;
}
export interface UserLists {
  userLists: {
    id: string;
    lists: UserListProject[];
  };
}

const query = gql`
  query UserListsListPage(
    $userId: ID
    $companyId: ID
  ) {
    userLists(
      userId: $userId
      companyId: $companyId
     ){
      id
      lists {
        id
        reconciliations
        name
        modifiedAt
        currentUserIsOwner
        estimatedEndDate
        reconEndDate
        isDeleted
        isRetained
        isArchived
        retentionEnd
        isPastRetention
        isPastEndDate
        name
        totalTasks
        completeTasks
        companyPosition
        sections {
          id
          name
        }
        taggingUsers {
          ... on User {
            id
            email
            fullName
            profileUrl
          }
          ... on Team {
            id
            name
          }
        }
        schedule{
          repeatType
          id
          calendarType
          ends
          endsOn
          endsAfter
          repeatNumber
          repeatInterval
          copyRoles
          copyFiles
          copyMessages
          copyDueDates
          copyStatuses
          selectedWeekday
          selectedMonthday
          selectedMonth
        }
        rank
        retentionPolicy {
          name
          duration
          id
        }
        requesterCompany {
          id
          name
          logoUrl
        }
        responderCompany {
          id
          name
          logoUrl
        }
        sharedCompany {
          id
          name
          logoUrl
        }
        tasks {
          id
          name
          description
          priority
          status
          dueDate
          companyPosition
          list {
            name
          }
          tags {
            id
            tag
          }
          classifications{
            id
            name
          }
          section {
            id
            name
          }
        }
        owners {
          ... on User {
            id
            displayName
            profileUrl
            fullName
            email
            roles {
              name
            }
          }
          ... on Team {
            id
            name
            companyId
            users {
              id
            }
          }
        }
        externalOwners {
          ... on User {
            id
            displayName
            profileUrl
            fullName
            email
            roles {
              name
            }
          }
          ... on Team {
            id
            name
            companyId
            users {
              id
            }
          }
        }
        notifications {
          id
          name
          notifiable {
            ... on Task {
              listId
            }
          }
          notifiableId
          notifiableType
          pane
          readAt
          updatedAt
          url
          actionType
          description
          createdAt
          isAutomation
          user {
            id
            fullName
          }
          company {
            id
            name
          }
        }
      }
    }
  }
`;

const queryList = gql`
  query DetailedListCopyPage($id: ID!) {
    list(id: $id) {
      id
      reconciliations
      name
      modifiedAt
      currentUserIsOwner
      estimatedEndDate
      reconEndDate
      isDeleted
      isRetained
      isArchived
      retentionEnd
      isPastRetention
      isPastEndDate
      name
      totalTasks
      completeTasks
      companyPosition
      sections {
        id
        name
      }
      taggingUsers {
        ... on User {
          id
          email
          fullName
          profileUrl
        }
        ... on Team {
          id
          name
        }
      }
      schedule{
        repeatType
        id
        calendarType
        ends
        endsOn
        endsAfter
        repeatNumber
        repeatInterval
        copyRoles
        copyFiles
        copyMessages
        copyDueDates
        copyStatuses
        selectedWeekday
        selectedMonthday
        selectedMonth
      }
      rank
      retentionPolicy {
        name
        duration
        id
      }
      requesterCompany {
        id
        name
        logoUrl
      }
      responderCompany {
        id
        name
        logoUrl
      }
      sharedCompany {
        id
        name
        logoUrl
      }
      tasks {
        id
        name
        description
        priority
        status
        dueDate
        companyPosition
        list {
          name
        }
        tags {
          id
          tag
        }
        classifications{
          id
          name
        }
        section {
          id
          name
        }
      }
      owners {
        ... on User {
          id
          displayName
          profileUrl
          fullName
          email
          roles {
            name
          }
        }
        ... on Team {
          id
          name
          companyId
          users {
            id
          }
        }
      }
      externalOwners {
        ... on User {
          id
          displayName
          profileUrl
          fullName
          email
          roles {
            name
          }
        }
        ... on Team {
          id
          name
          companyId
          users {
            id
          }
        }
      }
      notifications {
        id
        name
        notifiable {
          ... on Task {
            listId
          }
        }
        notifiableId
        notifiableType
        pane
        readAt
        updatedAt
        url
        actionType
        description
        createdAt
        isAutomation
        user {
          id
          fullName
        }
        company {
          id
          name
        }
      }
    }
  }
`;

export function useLazyUserListsCopyPage(userId: any, companyId: any) {
  return useLazyQuery(query, { variables: { userId: userId, companyId: companyId }, fetchPolicy: 'no-cache'});
}

export function useUserListsCopyPage(userId: any, companyId: any) {
  return useQuery(query, { variables: { userId: userId, companyId: companyId } });
}

export function useLazyDetailedListCopyPage() {
  return useLazyQuery(queryList, { fetchPolicy: 'network-only'});
}