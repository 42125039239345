/* The 'blank slate' of task filters 
 * show all lists, priorities, sections, statuses
 * no selected task, on overview pane
 * */
export const defaultTaskFilters = {
  taskPageLists: [],
  taskPageDueDateSort: null,
  taskPageDueDateSelected: null,
  taskPageNameSort: null,
  taskPageName: null,
  taskPageId: null,
  taskPageDateSort: null,
  taskPageIdSort: null,
  taskPageModifiedSort: null,
  taskPagePriority: ['high', 'medium', 'low'],
  taskPageSection: [],
  taskPageStatus: [
    'Unstarted',
    'Started',
    'Prepared',
    'Delivered',
    'Rejected',
    'Complete'
  ],
  taskPageSelectedTasks: [],
  taskPagePane: 0,
  taskPageOwners: [],
  taskPageRecons: 0,
  taskPageReconSort: null,
  taskPageTags: [],
  taskPageView: 0
};

export const defaultCustomTaskPageFilters = {
  customTaskPagePriorityFilter: null,
  customTaskPageNameAndEntityFilter: null,
  customTaskPageNameFilter: null,
  customTaskPageEntityFilter: null,
  customTaskPageClassificationFilter: null,
  customTaskPageStatusFilter: null,
  customTaskPageAssigneesFilter: null,
  customTaskPageOwnersFilter: null,
  customTaskPageReviewersFilter: null,
  customTaskPageDueDateFilter: null,
  customTaskPageStartDateFilter: null,
  customTaskPageMessagesFilter: null,
  customTaskPageFilesFilter: null,
  customTaskPageIsReconciledFilter: null,
  customTaskPagePrimaryValueFilter: null,
  customTaskPageSecondaryValueFilter: null,
  customTaskPageReconcilingItemValueFilter: null,
  customTaskPageDifferenceFilter: null,
  customTaskPageIdFilter: null,
  customTaskPageCompanyNameFilter: null,
  customTaskPageProjectNameFilter: null,
  customTaskPageSectionNameFilter: null,
  customTaskPageExternalPartyFilter: null,
  customTaskPageShowCompletedTasks: null,
  customTaskPageMyTasks: null,
  customTaskPageSelectedTaskIds: null
};

export const defaultProjectFilters = {

};

export const allDefaultFilters = {
  ...defaultTaskFilters,
  ...defaultProjectFilters
};
