import {
  ClickAwayListener,
  createStyles,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Theme,
} from '@material-ui/core';
import useCreatePublicMessage from '../../../../../../../graphql/mutations/CreatePublicTaskMessage';
import React, {useEffect, useRef, useState} from 'react';
import * as cs from '../../../../../../../constants/theme';
import AttachFileSharpIcon from '@material-ui/icons/AttachFileSharp';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import {hashMessage} from '../../../../../../../helpers/helpers';
import {MessageTaggingBox} from './MessageTaggingBox';
import {Alert} from '../Alert';
import {useLazyUserTagging} from '../../../../../../../graphql/queries/UserTagging';
import idx from 'idx';
import clsx from 'clsx';
import {useDispatchContext, useStateContext} from '../../../../../../../store';
import { FileUploadProperties, FileUploadWorkerProperties, useFileUploadWorker } from '../../../../../../../hooks/useFileUploadWorker';
import CreatePrivateTaskMessage from '../../../../../../../graphql/mutations/CreatePrivateTaskMessage';
import EditTaskMessage from '../../../../../../../graphql/mutations/EditTaskMessage';
import {useDropzone} from 'react-dropzone';
import {useError} from '../../../../../../../hooks/UseError';
import UserImage from '../../../../../../common/UserImage';
import { getCaretCoordinates } from '../../../../../../file/FilesPage/components/Body/components/getCaretCoordinates';

const tabHeight = 30;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginBottom: '5px',
      minHeight: tabHeight,
      height: tabHeight,
    },
    tab: {
      paddingBottom: 3,
      minHeight: tabHeight,
      height: tabHeight,
    },
    footer: {
      padding: 0,
      position: 'fixed',
      paddingLeft: '16px',
      bottom: 0,
      width: 500,
      borderTop: '1px solid #D8D8D8',
      backgroundColor: 'white',
      transition: 'height 3s ease-in 5s',
      paddingBottom: '3px',
      zIndex: 10000000000,
    },
    createProjectBottom: {
      position: 'relative',
      bottom: 14,
      // width: 498,
    },
    messageBox: {
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      width: '100%',
      minHeight: '5vh',
      color: '#606060',
      fontFamily: cs.FONT.family,
      fontWeight: cs.FONT.weight.regular,
      fontSize: cs.FONT.size.xs,
      textTransform: 'none',
      border: 'none',
      height: '100%',
      resize: 'none',
      '& label': {
        color: '#606060',
        fontFamily: cs.FONT.family,
        fontWeight: cs.FONT.weight.regular,
        fontSize: cs.FONT.size.xs,
      },
      '&:selected': {
        color: '#3A84FF',
      },
      '& input::placeholder': {
        fontSize: '12px',
      },
      '& div': {
        width: '100%',
      },
      '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: 'none',
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    inputActionsContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      position: 'absolute',
      left: 0,
      paddingLeft: '28px',
      paddingTop: '5px',
      width: '100%',
    },
    mainGrid: {
      width: '99%',
      paddingBottom: '2%',
    },
    inputContainer: {
      paddingLeft: '8px',
      paddingRight: '2px',
      border: '1px solid transparent',
      '&:hover': {
        border: '1px solid #AFAFAF',
        borderRadius: '5px',
      },
    },
    alertContainer: {
      position: 'absolute',
      bottom: '115px',
      right: '5px',
      background: 'white',
    },
    inputStyles: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '12px',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      paddingLeft: '6px',
    },
  })
);
function TaskMessenger(props: any) {
  const classes = useStyles();
  const {value, index, tasks, setTasks, taskId, task, isCreateProject, updateTask, updateTaskStatus, updateStatusAfterUpload} = props;
  const [currentTab, setCurrentTab] = useState(0);
  const internal = currentTab === 1;
  const [inputFocused, setInputFocused] = useState(false);
  const expandedStyles = {height: '120px'};

  const dispatch = useDispatchContext()
  const state = useStateContext();;
  const fileUploadWorker = useFileUploadWorker()

  const role = idx(state, state => state.currentUser.companyRole);

  useEffect(() => {
    if (taskId) {
      setCurrentTab(0);
      setInputFocused(false);
      setNewMessage('');
      setShowAlert(false);
      setShowAddBox(false);
    }
  }, [taskId]);
  const errorMessage = useError();

  // local state vars
  // modals
  const [showAlert, setShowAlert] = useState<boolean>(false);
  // creating new messages
  const [newMessage, setNewMessage] = useState<string>('');
  const [encryptedPublicMessage, setPublicEncryptedMessage] = useState<string>(
    ''
  );
  const [encryptedPrivateMessage, setPrivateEncryptedMessage] = useState<
    string
  >('');
  // editing existing messages
  const [editedMessageText, setEditedMessageText] = useState<string>('');
  const [editedMessageId, setEditedMessageId] = useState<any>();

  // user tagging
  const [showAddBox, setShowAddBox] = useState<boolean>(false); //whether or not to show the tagging popup
  const [selectedItem, setSelectedItem] = useState<number>(0); //current user selection in tagging popup
  const [allUsers, setAllUsers] = useState<any[]>([]); //list of all users (default for display in tagging popup if no filterList)
  const [displayUsers, setDisplayUsers] = useState<any[]>([]);

  // refs
  const listElement: any = useRef(null);
  const coolRef: any = useRef(null);

  // gql query
  const [getUserTags, {loading, data, error, refetch}] = useLazyUserTagging();

  useEffect(() => {
    if (taskId) {
      getUserTags({
        variables: {
          id: taskId
        }
      })
    }
  }, [taskId]);

  // gql mutations
  const [
    createPrivateMessage,
    createPrivateMessageResponse,
  ] = CreatePrivateTaskMessage({
    message: encryptedPrivateMessage,
    taskId: taskId as string,
    selectedOwners: parseSelectedUsers(),
  });

  const [
    createPublicMessage,
    createPublicMessageResponse,
  ] = useCreatePublicMessage({
    message: encryptedPublicMessage,
    taskId: taskId as string,
    selectedOwners: parseSelectedUsers(),
  });

  // const [createMessage, createMessageResponse] = internal
  //   ? [createPrivateMessage, createPrivateMessageResponse]
  //   : [createPublicMessage, createPublicMessageResonse];

  const [editMessage, editMessageResponse] = EditTaskMessage({
    id: editedMessageId,
    message: editedMessageText,
  });

  // called Each time the value of the input changes
  const updateNewMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
  };

  useEffect(() => {
    if (state.rejectedTask) {
      
      setShowAlert(false);

      setTimeout(() => {
        if ((state.rejectedTask === 'internal' && task.companyPosition !== 'requester') || task.companyPosition !== 'requester') {
          setCurrentTab(1);
        } else {
          setCurrentTab(0);
        }
        setInputFocused(true);
        setNewMessage('Reason For Rejection: ');
        coolRef.current.focus();
        coolRef.current.selectionStart = coolRef.current.value.length;
      }, 500);

      dispatch({
        type: 'SET_REJECTED_TASK',
        rejectedTask: null,
      });
    }
  }, [state.rejectedTask]);

  // if tasks changes, re-fetch the list, to account for changes
  // useEffect(() => {
  //   if (!refetch) {
  //     return;
  //   }
  //   setTimeout(() => refetch(), 430);
  // }, [tasks, createMessageResponse]);

  // fetches the taskOwners query
  useEffect(() => {
    let result = idx(data, data => (data as any).task.allOwners);

    if (!result) {
      return;
    }

    if (internal) {
      var noExternal = result.filter(
        (o: any) => o.taskRelationship !== 'External'
      );
      role <= 5
        ? (result = noExternal.filter(
            (o: any) =>
              o.roleInCurrentUserCompany <= 5 || o.__typename === 'Team'
          ))
        : (result = noExternal.filter(
            (o: any) => o.roleInCurrentUserCompany > 5
          ));
    }

    // Add team members into all user's array -- TODO: refactor
    result
      .filter((val: any) => val.__typename === 'Team')
      .map((team: any) => {
        team.users.map((user: any, index: number) => {
          user.taskRelationship = team.taskRelationship;
          let userIndex = result.findIndex(
            (obj: any) => obj.email === user.email
          );
          if (userIndex === -1) {
            result.push(user);
          } else {
            result[userIndex] = user;
          }
        });
      });

    setAllUsers(result);
  }, [data, internal]);

  function parseSelectedUsers() {
    // ID which users/teams were @-ed in the final message
    var usersTagged = allUsers.filter((o: any) => {
      var name = o.displayName || o.name;
      return newMessage.includes('@' + name);
    });

    // return object formatted the way the mutation is expecting
    return usersTagged.map((o: any) => {
      return {
        id: o.id,
        taskRelationship: o.taskRelationship,
        userType: o.__typename,
      };
    });
  }

  const tempOwnerParser = () => {
    return allUsers.filter((o: any) => {
      var name = o.displayName || o.name;
      return (
        newMessage.includes('@' + name) &&
        o.taskRelationship === 'Not in Project'
      );
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!internal && !showAlert) {
      setShowAlert(true);
    } else {
      var hashedMessage = await hashMessage(newMessage);
      if (!internal) {
        setPublicEncryptedMessage(hashedMessage.key);
      } else {
        setPrivateEncryptedMessage(hashedMessage.key);
      }
      // setEncryptedMessage(hashedMessage.key);
      setShowAlert(false);
    }
  };

  useEffect(() => {
    if (encryptedPublicMessage.length) {
      var clone = JSON.parse(JSON.stringify(tasks));
      var task = clone.find((t: any) => t.id == taskId);
      var taggedCompanyOwners = tempOwnerParser();
      task.userOwners = task.userOwners ? task.userOwners : [];
      task.teamOwners = task.teamOwners ? task.teamOwners : [];
      task.userOwners = [
        ...task.userOwners,
        ...taggedCompanyOwners.filter(
          (o: any) =>
            o.__typename == 'User' &&
            !task.userOwners.map((a: any) => a.id).includes(o.id)
        ),
      ];
      task.teamOwners = [
        ...task.teamOwners,
        ...taggedCompanyOwners.filter(
          (o: any) =>
            o.__typename == 'Team' &&
            !task.teamOwners.map((a: any) => a.id).includes(o.id)
        ),
      ];
      setTasks(clone);
      createPublicMessage();

      setNewMessage('');
    }
  }, [encryptedPublicMessage]);

  useEffect(() => {
    if (encryptedPrivateMessage.length) {
      var clone = JSON.parse(JSON.stringify(tasks));
      var task = clone.find((t: any) => t.id == taskId);
      var taggedCompanyOwners = tempOwnerParser();
      task.userOwners = task.userOwners ? task.userOwners : [];
      task.teamOwners = task.teamOwners ? task.teamOwners : [];
      task.userOwners = [
        ...task.userOwners,
        ...taggedCompanyOwners.filter(
          (o: any) =>
            o.__typename == 'User' &&
            !task.userOwners.map((a: any) => a.id).includes(o.id)
        ),
      ];
      task.teamOwners = [
        ...task.teamOwners,
        ...taggedCompanyOwners.filter(
          (o: any) =>
            o.__typename == 'Team' &&
            !task.teamOwners.map((a: any) => a.id).includes(o.id)
        ),
      ];
      setTasks(clone);
      createPrivateMessage();
      setNewMessage('');
    }
  }, [encryptedPrivateMessage]);

  useEffect(() => {
    const response = idx(
      createPrivateMessageResponse,
      (res: any) => res.data.createTaskMessages.message
    );

    if (response) {
      const newMessage = {
        ...response,
        isPublic: false,
        viewed: true,
      };
      var clone = tasks.map((t: any) => ({...t}));
      const currentTask = clone.find((t: any) => t.id == response.task.id);

      if (currentTask.messages) {
        currentTask.messages.unshift(newMessage);
      } else {
        currentTask.messages = [newMessage];
      }

      const notification = createNotificationFromMessage(newMessage);
      currentTask.notifications = currentTask.notifications ? currentTask.notifications : [];
      currentTask.notifications = [
        notification,
        ...currentTask.notifications
      ];

      setTasks(clone);
    }
  }, [createPrivateMessageResponse.called, createPrivateMessageResponse.loading, createPrivateMessageResponse.data]);

  function createNotificationFromMessage(message: any) {
    const actionType = message.isPublic
      ? 'New Public Messages'
      : 'New Internal Messages'

    return ({
      actionType: actionType,
      createdAt: message.createdAt,
      description: message.message,
      user: message.user,
      isPublic: message.isPublic,
      name: 'x ',
    });
  }

  /** update the DOM on creation of new messsages */
  useEffect(() => {
    const response = idx(
      createPublicMessageResponse,
      (res: any) => res.data.createTaskMessages.message
    );

    if (response) {
      const newMessage = {
        ...response,
        isPublic: true,
        viewed: true,
      };
      var clone = tasks.map((t: any) => ({...t}));
      var currentTask = clone.find((t: any) => t.id == response.task.id);

      if (currentTask) {
        if (currentTask.messages) {
          currentTask.messages.unshift(newMessage);
        } else {
          currentTask.messages = [newMessage];
        }
        const notification = createNotificationFromMessage(newMessage);
        currentTask.notifications = currentTask.notifications ? currentTask.notifications : [];
        currentTask.notifications = [
          notification,
          ...currentTask.notifications
        ];
      }
      setTasks(clone);
    }
  }, [createPublicMessageResponse.called, createPublicMessageResponse.loading, createPublicMessageResponse.data]);

  // edit message effect
  useEffect(() => {
    if (editedMessageText.length) {
      editMessage();
      setEditedMessageText('');
      setEditedMessageId('');
    }
  }, [editedMessageText]);

  const addTag = () => {
    var taggingSelected = taggableSection(
      coolRef.current.selectionStart || newMessage.length
    );
    var selected = displayUsers[selectedItem];
    var taggedName = selected.name || selected.displayName;

    var front = newMessage.substring(
      0,
      coolRef.current.selectionStart - taggingSelected.length
    );
    var middle = `@${taggedName} `;
    var back = newMessage.substring(
      coolRef.current.selectionStart,
      newMessage.length
    );

    setNewMessage(front + middle + back);
    coolRef.current.focus();
  };

  function taggableSection(cursorPosition: number) {
    var messageToCursor = coolRef.current.value.substring(
      0,
      cursorPosition - 1
    );
    var lastWhiteSpace = messageToCursor.lastIndexOf(' ');

    return newMessage.substring(lastWhiteSpace + 1, cursorPosition);
  }

  /* const availableTaggables = allUsers.filter((o: any) => {
    var name = o.displayName || o.name;
    return !newMessage.includes('@' + name);
  }) */

  const handleKeyDown = (event: any) => {
    // newlines are only added when shift key is pressed
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
    }

    // pressing up and down keys with active tagging box doesn't move cursor
    if ((event.keyCode === 40 || event.keyCode === 38) && showAddBox) {
      event.preventDefault();
    }
  };

  function handleClick() {
    setInputFocused(true);
    coolRef.current.focus();
    const messageToCreate = newMessage ? `${newMessage} @` : '@';

    setNewMessage(messageToCreate);

    setShowAddBox(true);
    //setDisplayUsers(availableTaggables);
    setDisplayUsers(allUsers);
  }

  function handleClick2() {
    setInputFocused(true);
    coolRef.current.focus();
    setTimeout(() => {
      const messageToCreate = newMessage ? `${newMessage} @` : '@';

      setNewMessage(messageToCreate);
      coolRef.current.selectionStart = messageToCreate.length;
      setShowAddBox(true);
      setDisplayUsers(allUsers);
    }, 0);
  }

  // determine if we should show the user tagging box
  const showTaggingBox = (event: any) => {
    var coolBeans = taggableSection(event.target.selectionStart);

    if (coolBeans === '@' || coolBeans === '\n@') {
      setShowAddBox(true);
      //setDisplayUsers(availableTaggables);
      setDisplayUsers(allUsers);
    }
    if (coolBeans[0] !== '@') {
      setShowAddBox(false);
    } else {
      var theRest = coolBeans.substring(1, coolBeans.length);
      var r = new RegExp('^' + theRest, 'gi');
      var toShow = /*availableTaggables*/ allUsers.filter((u: any) => {
        var nombre = u.name || u.displayName;
        return nombre.match(r);
      });
      if (toShow.length) {
        setDisplayUsers(toShow);
        setShowAddBox(true);
      } else {
        setShowAddBox(false);
      }
    }
  };

  const handleKeyUp = (event: any) => {
    showTaggingBox(event);

    if (event.keyCode === 13 && event.shiftKey) {
      return;
    }

    // enter key
    if (event.keyCode === 13) {
      event.stopPropagation();
      if (showAddBox && allUsers) {
        addTag();
        setShowAddBox(false);
      } else {
        handleSubmit(event);
      }
    }

    // If the tagging box is not active, we don't want to continue
    if (!showAddBox) {
      return;
    }
    // helpers for up && down keys
    // [confluence] -- up and down code
    var list = listElement.current;
    var {scrollTop} = list;
    var scrollBottom = list.scrollTop + 175;

    // down key
    if (event.keyCode === 40) {
      var newSelection = (selectedItem + 1) % displayUsers.length;
      setSelectedItem(newSelection);
      if (0 == newSelection) {
        list.scrollTo(0, 0);
      } else if (newSelection * 35 >= scrollBottom) {
        list.scrollTo(0, list.scrollTop + 35);
      }
    }

    // up key
    if (event.keyCode === 38) {
      var newSelection =
        (selectedItem + displayUsers.length - 1) % displayUsers.length;
      setSelectedItem(newSelection);
      if (displayUsers.length - 1 == newSelection) {
        list.scrollTo(0, 35 * displayUsers.length);
      } else if (newSelection * 35 < scrollTop) {
        list.scrollTo(0, scrollTop - 35);
      }
    }
  };

  // Whenever we go from no add box to add box reset selected item to 0
  useEffect(() => {
    if (!showAddBox) {
      return;
    }
    setSelectedItem(0);
  }, [showAddBox]);

  const onDrop = (acceptedFiles: any) => {
    const formattedFiles: FileUploadProperties[] = acceptedFiles.map((file: File, index: number) => {
      return {
        file,
        taskId: task.id
      }
    });

    const fileUploadWorkerProperties: FileUploadWorkerProperties = {
      files: formattedFiles,
      uploadType: 'task',
      selectedTask: task,
      updateTask: updateTask,
      updateTaskStatus: updateTaskStatus,
      afterUpload: (file: any) => updateStatusAfterUpload(task),
    }

    fileUploadWorker(fileUploadWorkerProperties);
  };

  const {getRootProps, getInputProps, open, isDragActive} = useDropzone({
    onDrop,
    noClick: true,
  });
  

  let coordinates = {top: 0, left: 0, height: 0}

  if(coolRef.current) {
    coordinates = getCaretCoordinates(coolRef.current,  coolRef.current.selectionEnd)
  }

  

  let bottomOffSet = 92 - (Math.abs(coordinates.top) + coordinates.height);

  return (
    <ClickAwayListener onClickAway={() => setInputFocused(false)}>
      <div
        className={clsx(classes.footer, isCreateProject ? classes.createProjectBottom : undefined)}
        style={inputFocused ? expandedStyles : {}}
        data-cy="task-messenger"
      >
        <Tabs
          classes={{root: classes.root}}
          onChange={(_, newTab) => {
            if (!showAlert) {
              setCurrentTab(newTab);
            }
          }}
          value={currentTab}
        >
          <Tab
            classes={{root: classes.tab}}
            label="Public"
            data-cy="task-messenger_public-tab"
          />
          <Tab
            classes={{root: classes.tab}}
            label="Internal"
            data-cy="task-messenger_internal-tab"
          />
        </Tabs>

        <div className={classes.messageBox}>
          <Grid classes={{root: classes.mainGrid}} container>
            <Grid item style={{flex: 0.75, paddingTop: '2px'}}>
              <UserImage
                user={state.currentUser}
                style={{
                  height: '25px',
                  width: '25px',
                  backgroundColor: 'rgba(42,137,75,0.80)',
                }}
              />
            </Grid>
            <Grid
              classes={{root: classes.inputContainer}}
              container
              item
              style={{
                flex: 9,
                backgroundColor: currentTab === 1 ? '#EEF8F9' : 'white',
                height: inputFocused ? 'auto' : '30px',
                overflowY: 'hidden',
              }}
            >
              <Grid
                item
                style={{
                  flex: 10,
                }}
              >
                {showAddBox && (
                  <MessageTaggingBox
                    onClick={addTag}
                    peopleList={displayUsers}
                    setShowAddBox={setShowAddBox}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    listRef={listElement}
                    listStyles={{
                      bottom:  bottomOffSet < 50 ? 50 : bottomOffSet
                    }}
                  />
                )}
                {showAlert && (
                  <div className={classes.alertContainer} style={isCreateProject && {bottom: 105, right: 0}}>
                    <Alert
                      onClick={handleSubmit}
                      onCancel={() => setShowAlert(false)}
                    />
                  </div>
                )}

                <div {...getRootProps({})}>
                  <input {...getInputProps()} style={{display: 'none'}} />
                </div>
                <form style={{width: '100%'}}>
                  <TextField
                    autoComplete="off"
                    className={classes.input}
                    data-cy="message-input"
                    inputProps={{
                      className: classes.inputStyles,
                    }}
                    multiline={inputFocused}
                    name="messageText"
                    onFocus={() => setInputFocused(true)}
                    placeholder="Add a message"
                    rows={inputFocused ? 3 : 1}
                    inputRef={coolRef}
                    onClick={showTaggingBox}
                    onChange={updateNewMessage}
                    value={newMessage}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                    autoFocus={inputFocused}
                  />
                  {inputFocused && (
                    <div className={classes.inputActionsContainer}>
                      <AlternateEmailIcon
                        onClick={event => {
                          event.stopPropagation();

                          handleClick();
                        }}
                        style={{
                          fontSize: '20px',
                          marginRight: '9px',
                          color: '#B0B0B0',
                        }}
                      />
                      <AttachFileSharpIcon
                        onClick={event => {
                          event.stopPropagation();
                          open();
                        }}
                        style={{fontSize: '20px', color: '#B0B0B0'}}
                      />
                    </div>
                  )}
                </form>
              </Grid>
              {!inputFocused && (
                <Grid
                  container
                  item
                  style={{
                    flex: 1.5,
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '5px',
                    backgroundColor:
                      currentTab === 1 ? '#EEF8F9' : 'transparent',
                  }}
                >
                  <AlternateEmailIcon
                    onClick={event => {
                      event.stopPropagation();
                      handleClick2();
                    }}
                    style={{
                      fontSize: '18px',
                      marginRight: '6px',
                      color: '#B0B0B0',
                    }}
                  />
                  <AttachFileSharpIcon
                    onClick={event => {
                      event.stopPropagation();
                      open();
                    }}
                    style={{fontSize: '18px', color: '#B0B0B0'}}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default TaskMessenger;
