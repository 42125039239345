import React, { useState, useEffect } from 'react';
import idx from 'idx';
import { useStateContext } from '../../../../../../../../../store';
import { SourceValue } from '../SourceValue';
import Difference from './Difference';


type TaskReconSourceOverviewProps = {
  changeActiveReconSource: any;
  task: any;
  updateTask: any;
  updateTaskInCache: any;
  primaryDisplaySkeleton: any;
  secondaryDisplaySkeleton: any;
  reconcilingItemsDisplaySkeleton: any;
  toggleReconcilingItems: any;
  updateToggleReconcilingItems: any;
  handleShowSourcePopper: any;
};

/**
 * `TaskReconSourceOverview` component reusable anywhere accross the app.
 * 
 * @return {React.JSXElementConstructor} The `TaskReconSourceOverview` component.
 */
export default function TaskReconSourceOverview(props: TaskReconSourceOverviewProps) {
  const { 
    changeActiveReconSource,
    task,
    updateTask,
    updateTaskInCache,
    primaryDisplaySkeleton,
    secondaryDisplaySkeleton,
    reconcilingItemsDisplaySkeleton,
    toggleReconcilingItems,
    updateToggleReconcilingItems,
    handleShowSourcePopper,
  } = props

    const state = useStateContext();
    
    const primaryTotal = idx(state, state => state.primaryTotal);
    const secondaryTotal = idx(state, state => state.secondaryTotal);
    const [primaryValue, setPrimaryValue] = useState<any>(0);
    const [secondaryValue, setSecondaryValue] = useState<any>(0);
  
    // For the primary source
    useEffect(() => {
      if (primaryTotal.task_id === task.id) {
        setPrimaryValue(primaryTotal.balance);
      } else {
        setPrimaryValue(task.primaryTotal);
      }
    }, [task.id, task.primaryTotal, primaryTotal.task_id, primaryTotal.balance])
  
    // For the secondary source
    useEffect(() => {
      if (secondaryTotal.task_id === task.id) {
        setSecondaryValue(secondaryTotal.balance);
      } else {
        setSecondaryValue(task.secondaryTotal);
      }
    }, [task.id, task.secondaryTotal, secondaryTotal.task_id, secondaryTotal.balance])
  
    return (
      <div className="inline-block min-w-full align-middle" data-cy='task-recon-source-overview'>
        <div className="grid grid-cols-4 gap-1 pb-3">
          <SourceValue
            changeActiveReconSource={changeActiveReconSource}
            displaySkeleton={primaryDisplaySkeleton}
            label="GL Balance"
            sourceType="primary"
            style={{ flex: '0 1 auto' }}
            task={task}
            value={primaryValue}
            updateTask={updateTask}
            updateTaskInCache={updateTaskInCache}
            handleShowSourcePopper={handleShowSourcePopper}
            hasSourceTypePopper
          />
          <SourceValue
            changeActiveReconSource={changeActiveReconSource}
            displaySkeleton={secondaryDisplaySkeleton}
            label="Reconciled"
            sourceType="secondary"
            style={{ flex: '0 1 auto' }}
            task={task}
            value={secondaryValue}
            updateTask={updateTask}
            updateTaskInCache={updateTaskInCache}
            handleShowSourcePopper={handleShowSourcePopper}
            hasSourceTypePopper
          />
          <SourceValue
            changeActiveReconSource={changeActiveReconSource}
            displaySkeleton={reconcilingItemsDisplaySkeleton}
            label="Reconciling Items"
            sourceType="reconciling-items"
            task={task}
            updateTask={updateTask}
            updateTaskInCache={updateTaskInCache}
            toggleReconcilingItems={toggleReconcilingItems}
            updateToggleReconcilingItems={updateToggleReconcilingItems}
          />
          <Difference
            displaySkeleton={primaryDisplaySkeleton || secondaryDisplaySkeleton}
            task={task}
          />
        </div>
      </div>
    );
}
