import moment from 'moment';

/**
 * Calculate the end date for a given input date and prior period, with end of month adjustment if necessary.
 * 
 * @param inputEndDate - The input date as a moment object.
 * @param priorPeriod - The number of months to subtract from the input date.
 * @returns A moment object representing the calculated end date, with end of month adjustment if necessary.
 * 
 * The function will check if the inputEndDate given is the end of a month 
 * If it's the case it will return the endOf month of the endDate after the substraction
 * 
 * @example
 * // Calculate the end date for one month ago
 * const oneMonthAgo = calculatePriorPeriodEndDate(moment('2022-03-10'), 1);
 * 
 * @example
 * // Calculate the end date for three months ago
 * const threeMonthsAgo = calculatePriorPeriodEndDate(moment('2022-03-10'), 3);
 */
export function calculatePriorPeriodEndDate(inputEndDate: moment.Moment, priorPeriod: number) {
	const endDate = inputEndDate.clone().subtract(priorPeriod, 'months');
	const diffInDaysEndDate = inputEndDate.diff(inputEndDate.clone().endOf('month'), 'days');

	if (diffInDaysEndDate === 0) {
		return endDate.endOf('month');
	} else {
		return endDate;
	}
}

/**
 * Calculates a prior date based on the given date string and prior period.
 * This function is intended for Netsuite integration.
 *
 * @param {any} dateString - The date string representing the starting date. It should be in the format "MMM YYYY".
 * @param {number} priorPeriod - The number of months to subtract from the given date.
 * @returns {string} The calculated prior date in the format "MMM YYYY".
 */
export function calculatePriorDate(dateString: any, priorPeriod: number) {
	// Parse the dateString into a moment object
	const date = moment(dateString, "MMM YYYY");

	// Subtract the given number of months
	date.subtract(priorPeriod, 'months');

	// Format the result back into a string and return it
	return date;
}