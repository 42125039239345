import { createMutationHook, gql } from '../graphqlHelpers';

export const useRefreshIntacctReconSource = createMutationHook(gql`
  mutation RefreshIntacctReconSource(
    $reconSourceId: ID!
    $taskId: ID!
  ) {
    refreshIntacctReconSource(
      reconSourceId: $reconSourceId
      taskId: $taskId
    ) {
      success
      task {
        currency
        primaryFileReconSources {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
            alterable
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        primaryFileReconSourcesTotal
        secondaryFileReconSources {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
            alterable
          }
          name
          isCurrent
          description
          prettyDate
          value
        }
        secondaryFileReconSourcesTotal
        isReconciled
        primaryTotal
        secondaryTotal
        reconcilingItemTotal
        reconDifference
        files {
          id
          fileName
          fileLocation
          alterable
          viewed
          updatedAt
          taskId
          createdAt
          isCheckedOut
          checkedOutBy{
            fullName
          }
          checkedOutAt
          companyId
        }
      }
    }
  }
`);
