import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';

import clsx from 'clsx';

import { ISteps, ITask } from '../interfaces';
import * as cs from '../../../../../../constants/theme';
import EditableInput from '../EditableInput';
import { useArchiveTasks } from '../../../../../../graphql/mutations/ArchiveTasks';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flex: {
            display: 'flex',
        },
        container: {
            width: '100%',
            height: '100%',
        },
        column: {
            flexDirection: 'column',
        },
        row: {
            flexDirection: 'row',
        },
        templateName: {
            width: '100%',
            '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
                border: 'none'
            },
            '& input': {
                fontSize: "18px",
                lineHeight: "22px",
                color: "#2C2C2C",
                opacity: 1,
                fontWeight: "bold",
            },
        },
        selectedContainer: {
            alignItems: 'center',
            justifyContent: 'space-between',
            minWidth: '250px',
            paddingLeft: '50px',
            marginRight: '0.6em',
        },
        selection: {
            width: '100%',
            padding: '6px 12px',
            color: cs.COLORS.primary,
            border: `2px solid ${cs.COLORS.primary}`,
            borderRadius: '3px',
            marginRight: '12px',
            pointerEvents: 'none'
        },
        pointer: {
            cursor: 'pointer'
        },
    })
);

interface ITasksHeader {
    step: ISteps
    selectedTemplate: any;
    setSelectedTemplate: React.Dispatch<React.SetStateAction<any>>;
    selectedTasksIds: string[];
    setSelectedTasksIds: React.Dispatch<React.SetStateAction<string[]>>;
    setDeleteTasks?: React.Dispatch<React.SetStateAction<any>>;
    isOnlyImportTasks?: boolean;
}

export default function TasksHeader(props: ITasksHeader) {

    const classes = useStyles();
    const { step, selectedTemplate, setSelectedTemplate, selectedTasksIds, setSelectedTasksIds, setDeleteTasks, isOnlyImportTasks } = props;
    function handleUpdateName(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setSelectedTemplate({
            ...selectedTemplate,
            name: e.target.value,
        });
    }

    function getDeletedTaskIds() {
        return selectedTemplate.tasks.filter((task: ITask) => selectedTasksIds.indexOf(task.id) !== -1).map((t: any) => t.id);
    }

    const [archiveTasks] = useArchiveTasks({
        taskIds: getDeletedTaskIds()
    });

    function handleDelete() {
        if (selectedTemplate && selectedTemplate.tasks) {
            const newTasks = selectedTemplate.tasks.filter((task: ITask) => selectedTasksIds.indexOf(task.id) === -1);

            const deletedTasks = selectedTemplate.tasks.filter((task: ITask) => selectedTasksIds.indexOf(task.id) !== -1);
            archiveTasks()
            setSelectedTasksIds([]);
            setDeleteTasks && setDeleteTasks(deletedTasks);
            setSelectedTemplate({ ...selectedTemplate, tasks: newTasks });
        }
    };

    return (
        <div className={clsx(classes.flex, classes.container, classes.row)} style={{ height: 'auto', padding: '10px 50px' }}>
            {!isOnlyImportTasks && <EditableInput type='text' name="selectedTemplateName" className={classes.templateName} hideIcon
                initialValue={selectedTemplate.name ? selectedTemplate.name : ''} placeholder="Template Name" InputProps={{ disableUnderline: true }}
                onBlur={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleUpdateName(event)} />
            }
            {step.activeStep === 1 &&
                <div className={clsx(classes.flex, classes.row, classes.selectedContainer)} style={{ visibility: selectedTasksIds.length > 0 ? 'visible' : 'hidden' }}>
                    <Button variant="outlined" className={classes.selection} style={{ textTransform: 'none' }}>
                        {selectedTasksIds.length} Task(s) Selected
                    </Button>
                    <DeleteIcon className={classes.pointer} onClick={handleDelete} />
                </div>
            }

        </div>
    )
}