export const chooseFileIcon = (fileExtension: string): string => {
  if (fileExtension.includes('pdf')) {
    return require('../../../../../../../public/images/pdf_file_icon.svg');
  } else if (fileExtension.includes('doc')) {
    return require('../../../../../../../public/images/microsoft_word_icon.svg');
  } else if (fileExtension.includes('ppt')) {
    return require('../../../../../../../public/images/microsoft_powerpoint_icon.svg');
  } else if (fileExtension.includes('xlsx') || fileExtension.includes('xls')) {
    return require('../../../../../../../public/images/microsoft_excel_icon.svg');
  } else {
    return require('../../../../../../../public/images/default_file_icon.svg');
  }
};