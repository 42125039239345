import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {
  Button,
  Grid,
} from '@material-ui/core';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

import SearchDependency from './SearchDependency';
import { useDispatchContext, useStateContext } from '../../../../../../../../store';

export const INSERT_TASK_DEPENDENCY = gql`
  mutation CreateTaskDependency($taskDependency: TaskDependencyAttributes!) {
    createTaskDependency(taskDependency: $taskDependency) {
      success
      taskNewDate
      relatedNewDate
    }
  }
`;

interface BlockingOnProps {
  anchorEl: any;
  task: any;
  skinnyLists: any;
  setSkinnyLists: React.Dispatch<any>;
}

export default function BlockingOn(props: any) {
  const {task, skinnyLists, setSkinnyLists} = props;
  const dispatch = useDispatchContext()

  const classes = useStyles();

  const [insertTaskDependency] = useMutation(INSERT_TASK_DEPENDENCY);
  const [relatedTaskId, setRelatedTaskId] = React.useState<string | null>();
  const [project, setProject] = React.useState(
    'Editable but autopopulated project name'
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    insertTaskDependency({
      variables: {
        taskDependency: {
          taskId: relatedTaskId,
          isDependent: true,
          relatedId: task.id,
          hasStartDate: false,
        },
      },
    }).then((response: any) => {
      //handle response
      // show message
      // to do check for success, otherwise show error
      // add dependency to skinnyList object, mostly for re-render of dependency section on overviewpane
      // find correct task indices
      // webpack compile error if initialized as null or uninitialized so init as -1
      let _skinnyLists = skinnyLists.map((a: any) => {
        return ({...a});
      });
      _skinnyLists.forEach((_list: any) =>{
        if(task.list.id == _list.id && _list.tasks && _list.tasks.length > 0){
          _list.tasks.forEach((_task: any) => {
            if(_task.id == relatedTaskId){
              _task.dueDate = response.data.createTaskDependency.relatedNewDate;
            }
            if(_task.id == task.id){
              _task.dueDate = response.data.createTaskDependency.taskNewDate;
            }
          });
        }
      });

      let relatedName, relatedDueDate, relatedListNumber;
      for(let list_iter=0; list_iter < _skinnyLists.length; list_iter++ )
      {
        if(_skinnyLists[list_iter].tasks &&
          _skinnyLists[list_iter].tasks.length > 0){
            let _tasks = _skinnyLists[list_iter].tasks;
            for (let i = 0; i < _tasks.length; i++) {
              if (_tasks[i].id == relatedTaskId) {
                relatedName = _tasks[i].name;
                relatedDueDate = _tasks[i].dueDate;
                relatedListNumber = _tasks[i].listNumber;
              }
            }
          }
      }
  
      // add new dependency to the Optimistic UI
      let newly_created_dependency = {
        __typename: 'TaskDependency',
        dueDays: '',
        dueHours: '',
        hasStartDate: false,
        isDependent: true,
        id: '18',
        status: 'Unresolved',
        relatedId: task.id,
        relatedName: task.name,
        relatedDueDate: task.dueDate,
        relatedListNumber: task.listNumber,
        taskId: relatedTaskId,
        taskName: relatedName,
        taskDueDate: relatedDueDate,
        taskListNumber: relatedListNumber,
      };
      // add it to the live display object
      // make sure to deep copy skinnyLists so we're not operating directly on the object

      let dependency_count = 2;
      for(let list_iter=0; list_iter < _skinnyLists.length; list_iter++ ){
        if(dependency_count == 0){
          break;
        }
        if(_skinnyLists[list_iter].tasks &&
          _skinnyLists[list_iter].tasks.length > 0){
            let _tasks = _skinnyLists[list_iter].tasks;
            for (let i = 0; i < _tasks.length; i++) {
              if(dependency_count == 0){
                break;
              }
              if (_tasks[i].id == task.id || _tasks[i].id == relatedTaskId) {
                _tasks[i].dependencies.push(newly_created_dependency);
                dependency_count--;
              }
            }
          }
      }

      props.snackbarStat(true);
      props.getMsg(`${task.id}` + 'added');
      dispatch({type: 'DEPEDENCIES_UPDATED'})
      setAnchorEl(props.anchorEl);
      setSkinnyLists(_skinnyLists);
    })
    .catch((err: any) => {
      console.log('mutation error', err);
    });
  };


  return (
    <>
      <form onSubmit={handleSubmit}>
        <SearchDependency
          lists={skinnyLists}
          task={task}
          skinnyLists={skinnyLists}
          setRelatedTaskId={setRelatedTaskId}
        />

        <div>
          <Grid item xs justifyContent="flex-end" className={classes.button}>
            <Button variant="contained" data-cy="blocking-save" disabled={!task.id || !relatedTaskId} color="primary" type="submit">
              Save
            </Button>
          </Grid>
        </div>
      </form>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '.MuiTabPanel-root': {
      padding: '10px 20px 20px 4px',
    },

    '.MuiAutocomplete-popup': {
      zIndex: 999999,
    },
  },
  input: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
  '.MuiList-padding': {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  closeIc: {
    fontSize: '12px',
  },
  button: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
}));
