import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Grid,
  // Input,
  // MenuItem,
  // Popper,
  // PopperProps,
  // Snackbar,
  TextField,
} from '@material-ui/core';
import {
  CheckCircleOutline,
  CheckCircle,
  // ListSharp,
  // CloseOutlined,
  ArrowDropDownSharp,
} from '@material-ui/icons';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import {Alert, Autocomplete} from '@material-ui/lab';

import Select, {components} from 'react-select';
import SearchDependency from './SearchDependency';
import { useDispatchContext, useStateContext } from '../../../../../../../../store';
// import {useCreateTaskDependency} from '../../../../../../../../graphql/mutations/CreateTaskDependency';

// const COMPANY_LIST = gql`
//   query companyList($id: ID!) {
//     listsForCompany(id: $id) {
//       id
//       name
//     }
//   }
// `;

export const INSERT_TASK_DEPENDENCY = gql`
  mutation CreateTaskDependency($taskDependency: TaskDependencyAttributes!) {
    createTaskDependency(taskDependency: $taskDependency) {
      success
      taskNewDate
      relatedNewDate
    }
  }
`;

interface WaitingOnProps {
  anchorEl: any;
  task: any;
  skinnyLists: any;
  setSkinnyLists: React.Dispatch<any>;
}

export default function WaitingOn(props: any) {
  const { task, skinnyLists, setSkinnyLists} = props;
  const dispatch = useDispatchContext();
  const classes = useStyles();

  // const {
  //   loading: companyLoading,
  //   data: companyData,
  //   error: companyError,
  // } = useQuery(COMPANY_LIST, {
  //   variables: {id: 1},
  // });

  const [
    insertTaskDependency,
    {loading: mutationLoading, data: mutationData, error: mutationError},
  ] = useMutation(INSERT_TASK_DEPENDENCY);

  const [relatedTaskId, setRelatedTaskId] = React.useState<string | null>();
  // const [taskText, setTaskText] = React.useState('');

  const [checked, setChecked] = React.useState(false);
  // const [project, setProject] = React.useState('');

  // const [option1Value, setOption1Value] = React.useState({
  //   name: companyData?.listsForCompany?.name,
  //   id: companyData?.listsForCompany?.id,
  // });

  // const isTaskTextNotEmpty = taskText.length > 0;
  // const isProjectNotEmpty = project.length > 0;

  // const [taskTextId, setTaskTextId] = React.useState(null);
  // const [projectTextId, setProjectTextId] = React.useState(null);

  // const handleTaskTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setTaskText(event.target.value);
  // };

  // const handleProjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setProject(event.target.value);
  //   props(project);
  // };
  // const handleReset = () => {
  //   setProject('');
  // };

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const [hours, setHours] = React.useState('');
  const [days, setDays] = React.useState('');
  const isDayHoursNotEmpty = days.length > 0 || hours.length > 0;
  const [dueSelect, setDueSelect] = React.useState('Due');

  const handleDueChange = (event : any) => {
    if(event && event.value){
      setDueSelect(event.value as string);
    }
  };

  const handleDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event && event.target){
      // console.log(event.target.value);
      setDays(event.target.value);
    }
  };
  const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHours(event.target.value);
  };

  /*
  const handleTaskClear = () => {
    setTaskTextId(0);
  };
  const handleProjectClear = () => {
    setProjectTextId(0);
  };
  */

  const DropdownIndicator = (props: JSX.IntrinsicAttributes) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownSharp style={{marginLeft: '-35px'}} />
      </components.DropdownIndicator>
    );
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // mutation to create dependency
    insertTaskDependency({
      variables: {
        taskDependency: {
          taskId: task.id,
          isDependent: true,
          relatedId: relatedTaskId,
          hasStartDate: dueSelect == 'Start',
          dueDays: days,
          dueHours: hours,
        },
      },
    }).then((response: any) => {
      //handle response
      // console.log('mutation data', response);
      let _skinnyLists = skinnyLists.map((a: any) => {
        return ({...a});
      });

      _skinnyLists.forEach((_list: any) =>{
        if(task.list.id == _list.id && _list.tasks && _list.tasks.length > 0){
          _list.tasks.forEach((_task: any) => {
            if(_task.id == task.id){
              _task.dueDate = response.data.createTaskDependency.taskNewDate;
            }
          });
        }
      });
      // add dependency to skinnyList object, mostly for re-render of dependency section on overviewpane
      // find correct task indices
      // webpack compile error if initialized as null or uninitialized so init as -1
      let relatedName, relatedDueDate, relatedListNumber;
      for(let list_iter=0; list_iter < _skinnyLists.length; ++list_iter ){
        if(_skinnyLists[list_iter].tasks &&
          _skinnyLists[list_iter].tasks.length > 0){
            let tasks = _skinnyLists[list_iter].tasks;
            for (let i = 0; i < tasks.length; ++i) {
              if (tasks[i].id == relatedTaskId) {
                relatedName = tasks[i].name;
                relatedDueDate = tasks[i].dueDate;
                relatedListNumber = tasks[i].listNumber;
              }
            }
          }
      }
      // add new dependency to the Optimistic UI
      let newly_created_dependency = {
        __typename: 'TaskDependency',
        dueDays: days,
        dueHours: hours,
        hasStartDate: dueSelect == 'Start',
        isDependent: true,
        id: '18',
        status: 'Unresolved',
        taskId: task.id,
        taskName: task.name,
        taskDueDate: task.dueDate,
        taskListNumber: task.listNumber,
        relatedId: relatedTaskId,
        relatedName: relatedName,
        relatedDueDate: relatedDueDate,
        relatedListNumber: relatedListNumber,
      };
  
      // add it to the live display object
      // tasks[taskIdx].dependencies.push(dep);
      // tasks[relatedIdx].dependencies.push(dep);
      // make sure to deep copy skinnyLists so we're not operating directly on the object
      let dependency_count = 2;
      for(let list_iter=0; list_iter < _skinnyLists.length; ++list_iter ){
        if(dependency_count == 0){
          break;
        }
        if(_skinnyLists[list_iter].tasks &&
          _skinnyLists[list_iter].tasks.length > 0){
            let _tasks = _skinnyLists[list_iter].tasks;
            for (let i = 0; i < _tasks.length; ++i) {
              if(dependency_count == 0){
                break;
              }
              if (_tasks[i].id == task.id || _tasks[i].id == relatedTaskId) {
                _tasks[i].dependencies.push(newly_created_dependency);
                dependency_count--;
              }
            }
          }
      }

      props.snackbarStat(true);
      props.getMsg(`${task.id}`, 'added');
      dispatch({type: 'DEPEDENCIES_UPDATED'})
      setAnchorEl(props.anchorEl);
      setSkinnyLists(_skinnyLists);
      // setChangedDueDate(response.data.createTaskDependency.taskNewDate);
      // console.log(response.data.createTaskDependency.taskNewDate);
      // show message
      // to do check for success, otherwise show error

    })
    .catch((err: any) => {
      console.log('mutation error', err);
    });
  };

  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      borderStyle: 'none',
      width: '100%',
      fontFamily: 'Montserrat',
      color: '#2C2C2C',
      fontSize: '12px',
      fontWeight: 'bold',

      border: 0,
      // This line disable the blue border
      boxShadow: 'none',
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex', // To keep icon and label aligned
      alignItems: 'center',
      marginRight: '0px',
      marginLeft: '0px',
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      marginRight: '50px',
      marginLeft: '0px',
      display: 'none',
    }), // removes the "stick"
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      textOverflow: 'ellipsis',
      maxWidth: '90%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'initial',
      paddingLeft: '0px',
    }),

    menuList: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: '0px',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      display: 'absolute',
      width: '45px',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      alignItems: 'left',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      justifyContent: 'center',
    }),
  };

  const selectOptions = [
    {value: 'Due', label: 'Due'},
    {value: 'Start', label: 'Start'},
  ];

  return (
    <>
      <form onSubmit={handleSubmit}>
        <SearchDependency
          lists={skinnyLists}
          task={task}
          skinnyLists={skinnyLists}
          setRelatedTaskId={setRelatedTaskId}
        />

        <div>
          <Grid
            item
            container
            xs
            direction="row"
            justifyContent="space-evenly"
            alignItems="baseline"
            style={{paddingTop: '0px', margin: 'inherit'}}
          >
            <Grid item xs={1} style={{marginRight: '20px', marginTop: '2px'}}>
              {' '}
              <Checkbox
                disabled
                color="primary"
                icon={<CheckCircleOutline />}
                checkedIcon={<CheckCircle />}
                checked={isDayHoursNotEmpty}
                onChange={handleCheckChange}
              />
            </Grid>

            <Grid
              item
              xs
              alignItems="center"
              style={{
                display: 'flex',
                alignSelf: 'normal',
                paddingTop: '2%',
              }}
            >
              <div
                style={{
                  width: '75px',
                }}
              >
                <Select
                  defaultValue={{label: 'Due', value: 'Due'}}
                  autosize={true}
                  components={{DropdownIndicator}}
                  styles={customStyles}
                  options={selectOptions}
                  data-cy='Date-select'
                  onChange={handleDueChange}
                />
              </div>
            </Grid>
            <Grid
              alignItems="center"
              item
              xs
              style={{
                display: 'inherit',
                // marginLeft: '12px',
                marginRight: '12px',
              }}
            >
              <TextField
                type="number"
                fullWidth
                InputLabelProps={{
                  style: {
                    color: '#B0B0B0',
                    fontWeight: 'normal',
                  },
                }}
                inputProps={{min: '0', step: '1'}}
                size="small"
                color="primary"
                placeholder="Days"
                data-cy='Select-days'
                value={days}
                onChange={handleDaysChange}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                fontSize: '12px',
                fontFamily: 'Montserrat',
                marginRight: '2%',
              }}
            >
              and
            </Grid>
            <Grid item xs style={{display: 'inherit'}}>
              <TextField
                fullWidth
                type="number"
                InputLabelProps={{
                  style: {
                    color: '#B0B0B0',
                    fontWeight: 'normal',
                  },
                }}
                inputProps={{min: '0', step: '0.25'}}
                size="small"
                color="primary"
                placeholder="Hours"
                data-cy='Select-hours'
                value={hours}
                onChange={handleHoursChange}
              />
            </Grid>
            <Grid
              item
              xs
              style={{
                marginLeft: '12px',
                fontSize: '12px',
                fontFamily: 'Montserrat',
              }}
            >
              later
            </Grid>
          </Grid>
          <Grid item xs justifyContent="flex-end" className={classes.button}>
            <Button
              variant="contained"
              disabled={!task.id || !relatedTaskId}
              color="primary"
              type="submit"
              data-cy='waiting-save'
            >
              Save
            </Button>
          </Grid>
        </div>
      </form>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '.MuiTabPanel-root': {
      padding: '10px 20px 20px 4px',
    },

    '.MuiAutocomplete-popup': {
      zIndex: 999999,
    },
  },
  input: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
  '.MuiList-padding': {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  closeIc: {
    fontSize: '12px',
  },
  button: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
}));
