import React from 'react';
import clsx from 'clsx'
import { ClickAwayListener, List, ListItem, ListItemText, Popper } from '@material-ui/core';
import { useStyles } from "../Styles";

export const SpacesPopper = (props: any) => {
  const { anchorEl, setAnchorEl, selectCreateOption, spaces } = props;
  const classes = useStyles();

  function close() {
    setAnchorEl(null);
  }

  const handleTypeSelection = (space: any) => {
    selectCreateOption(space);
    close();
  };

  return (
    <Popper
      anchorEl={anchorEl}
      data-cy="test"
      open={Boolean(anchorEl)}
      placement={"bottom-start"}
      style={{ zIndex: 1301, maxHeight: "300px", overflowY: "auto", width: '225px' }}
    >
      <ClickAwayListener onClickAway={close}>
        <List
          classes={{ root: clsx(classes.root, classes.list) }}
          disablePadding
        >
          {spaces.map((space: any, index: number) => {
            return (
              <ListItem
                key={index + space.name + space.id}
                classes={{ root: classes.listItem }}
                onClick={() => handleTypeSelection(space)}
              >
                <ListItemText
                  primary={space.name}
                  classes={{ primary: classes.t2 }}
                />
              </ListItem>
            );
          })}
        </List>
      </ClickAwayListener>
    </Popper>
  );
};
