import { IntacctValueSelection  } from './IntacctValueSelection';
import { SlValueSelection } from './SlValueSelection';
import { QBOValueSelection } from './QBOValueSelection';
import { NetsuiteValueSelection } from './NetsuiteValueSelection';

import React from 'react';

export function IntegrationValueSelection(props: any) {

  if (props.integrationType == '3') {  
    return(
      <IntacctValueSelection
        open={props.open}
        denyAction={props.denyAction}
        sourceType={props.sourceType}
        task={props.task}
        project={props.project}
        confirmAction={props.confirmAction}
      />
    )
  }
  else if (props.integrationType == '4') {  
    return(
      <SlValueSelection
        open={props.open}
        denyAction={props.denyAction}
        sourceType={props.sourceType}
        integrationType={props.integrationType}
        task={props.task}
        project={props.project}
        confirmAction={props.confirmAction}
      />
    )
  }
  else if (props.integrationType == '5') {
    return(
      <QBOValueSelection 
        open={props.open}
        denyAction={props.denyAction}
        sourceType={props.sourceType}
        task={props.task}
        project={props.project}
        confirmAction={props.confirmAction}
      />
    );
  }
  else if (props.integrationType == '6'){
    return (
      <NetsuiteValueSelection
      open={props.open}
      denyAction={props.denyAction}
      sourceType={props.sourceType}
      task={props.task}
      project={props.project}
      confirmAction={props.confirmAction}
      />
    )
  }

    return(
      <div/>
    )
}

