import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, Theme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
	flex: {
		display: 'flex',
		width: '100%',
		height: '100%',
	},
	row: {
		flexDirection: 'row',
	},
	column: {
		flexDirection: 'column',
	},
	'@global': {
		'.MuiDialog-paperWidthSm': {
			borderRadius: '2px',
		},
	},
	dependencyText: {
		fontSize: '12px',
		color: '#606060',
		opacity: 1,
		fontWeight: 'normal',
	},
	taskDisplayName: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	button: {
		width: '200px',
		fontWeight: 'bold',
		borderRadius: '2px',
		paddingTop: '12px',
		paddingBottom: '12px',
	}
}));

interface IAlertModal {
	open: boolean;
	dialogContent: JSX.Element;
	dialogHeader: JSX.Element;
	dialogActions: JSX.Element;
	onCancel(): void;
	onOk(): void;
}

export default function AlertModal(props: IAlertModal) {

	const { open, dialogHeader, dialogContent, dialogActions, onOk, onCancel } = props;
	const classes = useStyles();

	return (
		<Dialog open={open} onClose={onCancel} className={clsx(classes.flex, classes.column)} aria-labelledby="alert-modal-title" aria-describedby="alert-modal-description" >
			<DialogTitle disableTypography={true} id="form-dialog-title" className={clsx(classes.flex, classes.row)}
				style={{ justifyContent: "space-between", alignItems: "baseline", display: 'none' }}>
				{dialogHeader}
				<div onClick={onCancel}>
					<IconButton>
						<Close style={{ color: '#606060' }} />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent className={clsx(classes.flex, classes.column)} style={{ justifyContent: "center", alignItems: "center", flexDirection: 'column', paddingBottom: '0px' }}>
				<div>
					<img color="#C3DAFF" alt="a" src={require('../../../../../../../assets/images/dummy/logos/report_gmailerrorred.svg')} width="118px" />
				</div>
				{dialogContent}
			</DialogContent>
			<DialogActions className={clsx(classes.flex, classes.row)} style={{ justifyContent: 'space-around', padding: '24px' }}>
				{dialogActions}
			</DialogActions>
		</Dialog>
	);
}
