import { gql} from '../graphqlHelpers';
export const newVolatileTemplate = gql`
mutation NewVolatileList($workspaceId: ID){
    newVolatileList(workspaceId: $workspaceId ) {
        id,
        labelName,
        name,
        isVolatile,
        tasks {
          id
          name
          section {
            id
            name
          }
          description
          priority
          status
          tags {
            id
            tag
          }
          classifications{
            id
            name
          }
          isVolatile
        }
        errors {
          message,
          path
        }
      }
  }
`;