import { useQuery } from '@apollo/client'
import idx from 'idx';
import React, { useEffect, useState } from 'react'
import { skinnyListWithTasks } from '../../../../../graphql/queries/searchSkinnyListWithTasks';
import { IProjectOptionsSelected, ISteps, ITableHeader } from '../common/interfaces';
import ProjectReviewStep from './components/ProjectReviewStep';
import ProjectTasksStep from './components/ProjectTasksStep';


interface IProjectTaskDeatilsReview {
    isAdmin: boolean;
    externalCompanyId: string
    selectedTemplate: any;
    setSelectedTemplate: React.Dispatch<React.SetStateAction<any>>;
    allTableHeaderOptions: ITableHeader[];
    displayedHeaders: ITableHeader[];
    setDisplayedHeaders: React.Dispatch<React.SetStateAction<ITableHeader[]>>;
    selectedTasksIds: string[];
    setSelectedTasksIds: React.Dispatch<React.SetStateAction<string[]>>;
    steps: ISteps;
    projectOptionsSelected: IProjectOptionsSelected;
    onFilesDropped(): void;
    filesUploaded(): void;
    sortByName: string | null;
    sortBySection: string | null;
    nameFilter: string;
    sectionFilter: string;
    setNameFilterPopper: any;
    additionalInternalOwners: any[];
    additionalExternalOwners: any[];
    setAdditionalInternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
    setAdditionalExternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
    isOnlyImportTasks?: boolean;
}

export default function ProjectTaskDeatilsReview(props: IProjectTaskDeatilsReview) {
    const { isAdmin, externalCompanyId, selectedTemplate, setSelectedTemplate, allTableHeaderOptions, displayedHeaders, setDisplayedHeaders,
        selectedTasksIds, setSelectedTasksIds, steps, projectOptionsSelected, onFilesDropped, filesUploaded,
        sortByName, sortBySection, nameFilter, sectionFilter, setNameFilterPopper,
        additionalInternalOwners, additionalExternalOwners, setAdditionalInternalOwners, setAdditionalExternalOwners, isOnlyImportTasks } = props;

    const [dependencies, setDependencies] = useState<any>({});
    const [skinnyLists, setSkinnyLists] = useState<any>([]);

    const [addTasks, setAddTasks] = useState<any>({});
    const [deleteTasks, setDeleteTasks] = useState<any>({});

    const skinny_list_query = skinnyListWithTasks;

    const tempTemplate = {
        id: selectedTemplate.id,
        name: selectedTemplate.name,
        tasks: selectedTemplate.tasks.map((task: any) => {
            return {
                dependencies: task.dependencies || [],
                dueDate: task.dueDate,
                id: task.id,
                listNumber: task.listNumber,
                name: task.name,
                __typename: "SkinnyTask"
            }
        }),
        __typename: "SkinnyList"
    }

    const {
        loading: projectLoading,
        data: skinnyListData,
        error: projectError,
    } = useQuery(skinny_list_query, {
        variables: { id: 1 },
    });

    useEffect(() => {
        if (
            skinnyListData &&
            skinnyListData.skinnyListsWithTasks &&
            skinnyListData.skinnyListsWithTasks.length > 0
        ) {
            setSkinnyLists([...skinnyListData.skinnyListsWithTasks, tempTemplate]);
        }
    }, [skinnyListData, selectedTemplate, selectedTemplate.tasks]);

    useEffect(() => {
        let temp = {} as any;
        if (skinnyLists && skinnyLists.length > 0) {
            skinnyLists.forEach((_list: any) => {
                let _tasks = _list.tasks;
                _tasks.forEach((t: any) => {
                    temp[t.id] = t.dependencies;
                    selectedTemplate.tasks.forEach((t1: any) => { if (t1.id === t.id) t1.dependencies = t.dependencies });
                });
            });
        }
        setDependencies(temp);
    }, [skinnyLists]);

    useEffect(() => {
        if (deleteTasks && deleteTasks.length) {
            const taskIds: any = deleteTasks.map((t: any) => t.id);
            setSkinnyLists([...deleteDependency(taskIds)])
        }
    }, [deleteTasks])

    useEffect(() => {
        if (addTasks && addTasks.length) {
            setSkinnyLists([...addDependencies(addTasks)]);
        }
    }, [addTasks])

    function deleteDependency(taskIds: any[]) {
        let _skinnyLists = skinnyLists.map((a: any) => {
            return { ...a };
        });

        taskIds.forEach((taskId: any) => {

            for (let list_iter = 0; list_iter < _skinnyLists.length; list_iter++) {
                let _current_list = _skinnyLists[list_iter];
                let _list_of_tasks = _current_list.tasks;
                for (let i = 0; i < _list_of_tasks.length; i++) {
                    if (
                        _list_of_tasks[i].dependencies &&
                        _list_of_tasks[i].dependencies.length > 0
                    ) {
                        for (let j = 0; j < _list_of_tasks[i].dependencies.length; j++) {
                            let curdep = _list_of_tasks[i].dependencies[j];
                            if (
                                (curdep.taskId == taskId || curdep.relatedId == taskId)
                            ) {
                                _list_of_tasks[i].dependencies.splice(j, 1);
                            }
                        }
                    }
                }
            }
        }
        )

        for (let list_iter = 0; list_iter < _skinnyLists.length; ++list_iter) {
            if (_skinnyLists[list_iter].tasks &&
                _skinnyLists[list_iter].tasks.length > 0) {
                let tasks = _skinnyLists[list_iter].tasks.filter((t: any) => !taskIds.includes(t.id));
                _skinnyLists[list_iter].tasks = tasks;
            }
        }

        return _skinnyLists;

    }

    function addDependencies(newTasks: any[]) {
        let _skinnyLists = skinnyLists.map((a: any) => {
            return ({ ...a });
        });

        newTasks.forEach((task: any) => {

            let dependencies = idx(task, t => t.dependencies) || [];

            let dependencies1: any = [];

            dependencies.forEach((dependency: any) => {

                let tempDependency = {
                    dueDays: idx(dependency, d => d.due_days.toString()) || dependency.dueDays,
                    dueHours: idx(dependency, d => d.due_hours.toString()) || dependency.dueHours,
                    hasStartDate: idx(dependency, d => d.has_start_date) || dependency.hasStartDate,
                    id: dependency.id.toString(),
                    isDependent: idx(dependency, d => d.is_dependent) || dependency.isDependent,
                    // relatedDueDate: null,
                    relatedId: idx(dependency, d => d.related_id.toString()) || dependency.relatedId,
                    // relatedListNumber: 22,
                    // relatedName: "abc",
                    status: dependency.status || "Unresolved",
                    taskDueDate: task.dueDate || "",
                    taskId: idx(dependency, d => d.task_id.toString()) || dependency.taskId,
                    taskListNumber: task.listNumber,
                    taskName: task.name,
                    __typename: "TaskDependency",
                }

                let relatedName: any, relatedDueDate: any, relatedListNumber: any;
                if (selectedTemplate.tasks.map((t: any) => t.id).includes(tempDependency.relatedId)) {
                    for (let i = 0; i < selectedTemplate.tasks.length; i++) {
                        if (selectedTemplate.tasks[i].id === tempDependency.relatedId) {
                            relatedName = selectedTemplate.tasks[i].name;
                            relatedDueDate = selectedTemplate.tasks[i].dueDate;
                            relatedListNumber = selectedTemplate.tasks[i].listNumber;
                        }
                    }
                } else {
                    for (let list_iter = 0; list_iter < _skinnyLists.length; ++list_iter) {
                        if (_skinnyLists[list_iter].tasks &&
                            _skinnyLists[list_iter].tasks.length > 0) {
                            let tasks = _skinnyLists[list_iter].tasks;
                            for (let i = 0; i < tasks.length; ++i) {
                                if (tasks[i].id == tempDependency.relatedId) {
                                    relatedName = tasks[i].name;
                                    relatedDueDate = tasks[i].dueDate;
                                    relatedListNumber = tasks[i].listNumber;
                                }
                            }
                        }
                    }

                }

                dependencies1.push({ ...tempDependency, 'relatedName': relatedName, 'relatedDueDate': relatedDueDate, 'relatedListNumber': relatedListNumber });
            })
            task.dependencies = dependencies1;
        }
        )

        for (let list_iter = 0; list_iter < _skinnyLists.length; ++list_iter) {
            // if (_skinnyLists[list_iter].id === newTasks[0].list.id){
            if (newTasks.some((t: any) => t.list.id === _skinnyLists[list_iter].id)) {
                let tasks = newTasks.map((task: any) => {
                    return {
                        dependencies: task.dependencies || [],
                        dueDate: task.dueDate,
                        id: task.id,
                        listNumber: task.listNumber,
                        name: task.name,
                        __typename: "SkinnyTask"
                    }
                })
                _skinnyLists[list_iter].tasks = [..._skinnyLists[list_iter].tasks, ...tasks];
            }

        }

        return _skinnyLists;
    }

    return (
        <>
            {(isOnlyImportTasks || steps.activeStep === 1) &&
                <ProjectTasksStep isAdmin={isAdmin} externalCompanyId={externalCompanyId} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate}
                    allTableHeaderOptions={allTableHeaderOptions} displayedHeaders={displayedHeaders} setDisplayedHeaders={setDisplayedHeaders}
                    selectedTasksIds={selectedTasksIds} setSelectedTasksIds={setSelectedTasksIds} step={steps} projectOptionsSelected={projectOptionsSelected}
                    setAddTasks={setAddTasks} setDeleteTasks={setDeleteTasks} dependencies={dependencies} skinnyLists={skinnyLists} setSkinnyLists={setSkinnyLists}
                    sortByName={sortByName} sortBySection={sortBySection} nameFilter={nameFilter} sectionFilter={sectionFilter} setNameFilterPopper={setNameFilterPopper}
                    additionalInternalOwners={additionalInternalOwners} setAdditionalInternalOwners={setAdditionalInternalOwners}
                    additionalExternalOwners={additionalExternalOwners} setAdditionalExternalOwners={setAdditionalExternalOwners} isOnlyImportTasks={isOnlyImportTasks} />
            }
            {steps.activeStep === 2 &&
                <ProjectReviewStep isAdmin={isAdmin} externalCompanyId={externalCompanyId} allTableHeaderOptions={allTableHeaderOptions} selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate} selectedTasksIds={selectedTasksIds} setSelectedTasksIds={setSelectedTasksIds}
                    step={steps} projectOptionsSelected={projectOptionsSelected} onFilesDropped={onFilesDropped} filesUploaded={filesUploaded} setAddTasks={setAddTasks}
                    setDeleteTasks={setDeleteTasks} dependencies={dependencies} skinnyLists={skinnyLists} setSkinnyLists={setSkinnyLists} deleteTasks={deleteTasks}
                    sortByName={sortByName} sortBySection={sortBySection} nameFilter={nameFilter} sectionFilter={sectionFilter} setNameFilterPopper={setNameFilterPopper}
                    additionalInternalOwners={additionalInternalOwners} setAdditionalInternalOwners={setAdditionalInternalOwners}
                    additionalExternalOwners={additionalExternalOwners} setAdditionalExternalOwners={setAdditionalExternalOwners} />
            }
        </>
    )
}
