import React, { useState, useEffect, useRef } from 'react';
import { Typography, Checkbox } from '@material-ui/core';
import { useError } from '../../../../../../../../../hooks/UseError'
import Select, { components } from 'react-select';
import { useApolloClient } from '@apollo/client';
import { netsuiteMenuItemsQuery } from '../../../../../../../../../graphql/queries/NetsuiteMenuItems';
import { LockSwitchToggle } from './commonComponents';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useGlobalStyles } from '../../../../../../../../common/Style';
import VirtualizedMenuList from '../../../../../../../../recons/VirtualizedMenuList';


interface NetsuitePopperProps {
  task: any;
  sourceType: any;
  state: any;
  setState: any;
  handleInvert: any;
  setDisableButton: any;
}

interface Option {
  label: string;
  value: string;
}

interface IntegrationField {
  label: string;
  value?: string;
  isRequired?: boolean;
  isMulti?: boolean;
  options?: Option[];
  component?: any;
}

const selectStyles: any = {
  control: () => ({
    alignItems: 'center',
    border: '1px solid hsl(0,0%,80%)',
    borderRadius: '4px',
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: 38,
    position: 'relative',
    transition: 'all 100ms',
    boxSizing: 'border-box'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (provided: any) => ({
    ...provided,
    border: '1px solid hsl(0,0%,80%)',
    boxShadow: 'none',
    marginTop: -1

  }),
  multiValue: () => ({
    background: '#FFF',
    border: '1px solid hsl(0,0%,80%)',
    borderRadius: '2px',
    display: 'flex',
    margin: '2px',
    padding: '2px',
    minWidth: 0,
    boxSizing: 'border-box'
  }),
  multiValueRemove: (provided: any, state: any) => ({
    //    ...provided,
    background: '#FFF',
    color: 'lightgrey',
    paddingLeft: 4,
    paddingRight: 4,
    alignItems: 'center',
    display: 'flex'
  })
};

const periodAdjustmentSelectStyles: any = {
  control: () => ({
    width: '135px',
    alignItems: 'center',
    border: '1px solid hsl(0,0%,80%)',
    borderRadius: '4px',
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: 38,
    position: 'relative',
    transition: 'all 100ms',
    boxSizing: 'border-box',
    marginRight: '15px'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (provided: any) => ({
    ...provided,
    border: '1px solid hsl(0,0%,80%)',
    boxShadow: 'none',
    marginTop: -1
  }),
  multiValue: () => ({
    background: '#FFF',
    border: '1px solid hsl(0,0%,80%)',
    borderRadius: '2px',
    display: 'flex',
    margin: '2px',
    padding: '2px',
    minWidth: 0,
    boxSizing: 'border-box'
  }),
  multiValueRemove: (provided: any, state: any) => ({
    //    ...provided,
    background: '#FFF',
    color: 'lightgrey',
    paddingLeft: 4,
    paddingRight: 4,
    alignItems: 'center',
    display: 'flex'
  })
};

//TODO: NEED TO VIRTUALIZE THE ITEM DROPDOWN, TOO MANY ITEMS
const LEVELS: IntegrationField[] = [
  // { label: 'Location', value: 'nsLocations', isRequired: false, isMulti: true},
  { label: 'Subsidiary', value: 'nsSubsidiaries', isRequired: false, isMulti: true },
  { label: 'Department', value: 'nsDepartments', isRequired: false, isMulti: true },
  { label: 'Class', value: 'nsClasses', isRequired: false, isMulti: true },
  { label: 'Account', value: 'nsAccounts', isRequired: false, isMulti: true },
  { label: 'Item', value: 'nsItems', isRequired: false, isMulti: true },
  { label: 'Entity', value: 'nsEntities', isRequired: false, isMulti: true },
  { label: 'Book', value: 'nsBooks', isRequired: false, isMulti: true },
  { label: 'Period', component: NetsuitePeriodAdjustmentField, isMulti: false }
]

export function NetsuitePopper({
  sourceType, task, state, setState, handleInvert, setDisableButton
}: NetsuitePopperProps
) {
  const showError = useError();
  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  const [OPTIONS, SETOPTIONS] = useState<any>({});
  //determine if the user can edit the recon settings or not
  const currentUserIsOwner = task.list.currentUserIsOwner;


  const apolloClient = useApolloClient();
  const netsuiteMenuItemsAbortControllerRef = useRef<any>(null);
  const netsuiteValueAbortControllerRef = useRef<any>(null);

  useEffect(() => {

    const abortController = new AbortController();
    netsuiteMenuItemsAbortControllerRef.current = abortController;

    setDisableButton(true);

    const queryParameters: any = {
      query: netsuiteMenuItemsQuery,
      context: {
        fetchOptions: {
          signal: abortController.signal
        }
      },
    }


    apolloClient.query(queryParameters).then((response: any) => {
      setShowSpinner(false);
      const menuItemData = response?.data?.netsuiteMenuItems;

      if (!menuItemData) {
        //error check
      }

      const mappedOptions = {
        // nsLocations: 
        nsSubsidiaries: menuItemData?.subsidiaryNames.slice(),
        nsDepartments: menuItemData?.departmentNames.slice(),
        nsClasses: menuItemData?.classNames.slice(),
        nsAccounts:  menuItemData?.accountNames.map(mapAccountValues).filter(filterAccountValues).sort(accountSort),
        nsItems: menuItemData?.itemNames.slice(),
        nsEntities: menuItemData?.entityNames.slice(),
        nsBooks: menuItemData?.bookNames.slice(),
        nsStartDates: menuItemData?.startDates.slice().sort(dateSort),
        nsEndDates: menuItemData?.endDates.slice().sort(dateSort),
      }

      SETOPTIONS(mappedOptions);
      // if `mappedOptions.nsAccounts` is empty, then we should disable the button
      if (mappedOptions.nsAccounts.length === 0) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    }).catch((e: any) => {
      setDisableButton(false);
      setShowSpinner(false);
    })

    return () => {
      if (netsuiteMenuItemsAbortControllerRef.current) netsuiteMenuItemsAbortControllerRef.current.abort()
      if (netsuiteValueAbortControllerRef.current) netsuiteValueAbortControllerRef.current.abort()

      // apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'netsuiteMenuItems' });
      // apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'netsuiteValue' });
      // apolloClient.cache.gc();
    }
  }, [])

  const mapAccountValues = (account: any) => {
    const accountId = account.accountId;
    const accountName = account.accountName;
    const accountNumber = account.accountNumber;

    let formattedLabel;

    if(accountName && accountNumber){
      formattedLabel = `${accountNumber} - ${accountName}`;
    } else if (accountName) {
      formattedLabel = accountName;
    } else if (accountNumber){
      formattedLabel = accountNumber;
    } else {
      formattedLabel = null; 
    }

    return {
      id: accountId,
      name: formattedLabel
    }
  }

  const filterAccountValues = (account: any) => {
    return Boolean(account?.name);
  }

  const accountSort = (firstAccount: any, secondAccount: any) => {
    const firstAccountName = firstAccount?.name;
    const secondAccountName = secondAccount?.name;
    return firstAccountName.localeCompare(secondAccountName)
  }

  const sortedMonthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const dateSort = (firstDateString: string, secondDateString: string) => {
    const firstDateStringSplit: string[] = firstDateString.split(' ');
    const secondDateStringSplit: string[] = secondDateString.split(' ');

    if (firstDateStringSplit[1] !== secondDateStringSplit[1]) return firstDateStringSplit[1].localeCompare(secondDateStringSplit[1])

    const firstDateStringMonth: string = firstDateStringSplit[0];
    const secondDateStringMonth: string = secondDateStringSplit[0];

    const firstDateStringMonthIndex = sortedMonthArray.indexOf(firstDateStringMonth);
    const secondDateStringMonthIndex = sortedMonthArray.indexOf(secondDateStringMonth);

    if (firstDateStringMonthIndex < secondDateStringMonthIndex) return -1;
    if (secondDateStringMonthIndex < firstDateStringMonthIndex) return 1;

    return 0;
  }

  return (
    <div>
      <div style={{ minWidth: 440, height: 200, overflow: 'auto', display: 'flex', flexDirection: 'column', paddingTop: '15px', paddingBottom: '15px' }}>
        {LEVELS.map((level: any, i: number) =>
          <LevelDropdown
            i={i}
            key={i}
            level={level}
            allOptions={OPTIONS}
            options={OPTIONS[level.value] && OPTIONS[level.value].map((o: any) => o.id
              ? { value: o.id, label: o.name }
              : { value: o, label: o }) || level.options}
            set={(x: any) => {
              setState({ ...state, [level.value]: x })
            }}
            setState={setState}
            showSpinner={showSpinner}
            state={state}
            v={state[level.value] || level.label === 'Period'}
          />
        )}

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography style={{ marginLeft: '20px', width: 140, paddingTop: '12px' }}>
            Other Options:
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={state.invert}
              color='primary'
              onChange={handleInvert}
              value={state.invert}
              disabled={!currentUserIsOwner}
            />
            <Typography>
              Invert total(s)
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

//TODO: REPLACE THIS WITH NORMAL DROPDOWN SINGLE SELECTS
function NetsuitePeriodAdjustmentField(props: any) {

  const [startDateLocked, setStartDateLocked] = useState(props.state.startDateLocked || false);
  const [endDateLocked, setEndDateLocked] = useState(props.state.endDateLocked || false);

  const startDateOptions = props.allOptions?.nsStartDates ? props.allOptions?.nsStartDates.map((option: any) => { return { label: option, value: option } }) : [];
  const endDateOptions = props.allOptions?.nsEndDates ? props.allOptions?.nsEndDates.map((option: any) => { return { label: option, value: option } }) : [];

  const handleStartDateOnChange = (newStartDate: any) => {
    props.setState({ ...props.state, nsStartDate: newStartDate })
  }

  const handleEndDateOnChange = (newEndDate: any) => {
    props.setState({ ...props.state, nsEndDate: newEndDate })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'space-between', alignContent: 'center' }}>
        <Select
          isDisabled={props.showSpinner || props.balanceQueryLoading}
          components={{ DropdownIndicator }}
          isClearable={false}
          isMulti={props.level.isMulti}
          onChange={handleStartDateOnChange}
          options={startDateOptions}
          style={{ width: '150px' }}
          styles={periodAdjustmentSelectStyles}
          value={props.state.nsStartDate}
        />
        <LockSwitchToggle name="startDateLockSwitch" locked={startDateLocked} handleChange={() => { props.setState({ ...props.state, startDateLocked: !startDateLocked }); setStartDateLocked(!startDateLocked) }} />
      </div>
      <Typography style={{ width: 130, textAlign: "center" }} component="span">to</Typography>
      <div style={{ display: 'flex', alignItems: 'space-between', alignContent: 'center' }}>
        <Select
          isDisabled={props.showSpinner || props.balanceQueryLoading}
          components={{ DropdownIndicator }}
          isClearable={false}
          isMulti={props.level.isMulti}
          onChange={handleEndDateOnChange}
          options={endDateOptions}
          styles={periodAdjustmentSelectStyles}
          value={props.state.nsEndDate}
        />
        <LockSwitchToggle name="endDateLockSwitch" locked={endDateLocked} handleChange={() => { props.setState({ ...props.state, endDateLocked: !endDateLocked }); setEndDateLocked(!endDateLocked) }} />
      </div>
    </div>
  )
}

function DropdownIndicator(props: any) {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDownIcon />
    </components.DropdownIndicator>
  );
}

function LevelDropdown(props: any) {
  const classes = useGlobalStyles();
  const { state, setState } = props;

  // props.v can be a string || an array
  // const emptyProps = !props.v || (Array.isArray(props.v) && !props.v.length)

  return (
    <div
      className={classes.flex}
      style={{ marginBottom: 10 }}
    >
      <div
        className={classes.flexCenter}
        style={{ justifyContent: 'flex-start' }}
      >
        <div style={{ display: 'flex', alignSelf: 'flex-start' }}>
          <div style={{ width: 20, marginRight: 20 }}>
            {(props.showSpinner)
              ? <CircularProgress size="0.75rem" />
              : <CircleCheckedFilled style={{ height: "0.70em", width: "0.70em", color: 'green', opacity: (Array.isArray(props.v) && props.v.length) ? 1 : 0.1 }} />}
          </div>
          <Typography style={{ width: 140 }}>
            {props.level.label}
          </Typography>
        </div>
        <div
          className={classes.dropdownContainer}
          style={{ width: 250 }}
        >
          {props.level.component
            ? props.level.component({ ...props, state, setState })
            : <Select
              isDisabled={props.showSpinner || props.balanceQueryLoading}
              components={{ 
                DropdownIndicator,
                MenuList: VirtualizedMenuList 
              }}
              isClearable={false}
              isMulti={props.level.isMulti}
              onChange={props.set}
              options={props.options}
              styles={selectStyles}
              value={props.v}
            />}
        </div>
      </div>
    </div>
  )
}

export function LevelDropdown2(props: any) {
  const classes = useGlobalStyles();
  const { state, setState } = props;

  // props.v can be a string || an array
  const emptyProps = !props.v || (Array.isArray(props.v) && !props.v.length)

  return (
    <div className={classes.flex} style={{ marginBottom: 10 }}>
      <div
        className={classes.flexCenter}
        style={{ justifyContent: "flex-start" }}
      >
        <div style={{ display: 'flex', alignSelf: 'flex-start' }}>
          <div style={{ width: 20, marginRight: 10 }}>
            {props.showSpinner && !emptyProps ? (
              <CircularProgress size={"0.75rem"} />
            ) : (
              <CircleCheckedFilled
                style={{
                  height: "0.70em",
                  width: "0.70em",
                  color: "green",
                  opacity: props.v ? 1 : 0.1,
                }}
              />
            )}
          </div>
          <Typography style={{ width: 140 }}>{props.level.label}</Typography>
        </div>
        <div style={{ width: 250 }} className={classes.dropdownContainer}>
          {props.level.component ? (
            props.level.component({
              state,
              setState,
              startDateParamName: props.startDateParamName,
              endDateParamName: props.endDateParamName,
              integrationType: props.integrationType,
              currentUserIsOwner: props.currentUserIsOwner
            })
          ) : (
            <Select
              components={{ DropdownIndicator }}
              options={props.options}
              isClearable={false}
              isMulti={props.level.isMulti}
              styles={selectStyles}
              onChange={props.set}
              value={props.v}
              isDisabled={!props.currentUserIsOwner}
            />
          )}
        </div>
      </div>
    </div>
  );
}