import {Tooltip, Typography} from '@material-ui/core';
import idx from 'idx';
import React from 'react';
import {useNotificationNavigation} from '../../../../../../../hooks/NotificationNavigation';
import { AUTOMATION_TITLE, PREPDD_LOGO } from '../../../../../../common/Constants';
import {NotificationText} from '../../../../../../common/NotificationText';
import UserImage from '../../../../../../common/UserImage';

function TimeLineNotification({privateMessage = false, notification}: any) {
  const {name, description, createdAt} = notification;
  const notificationNavigation = useNotificationNavigation();
  const handleNotification = (notification: any) => {
    notificationNavigation(notification);
  };

  // For the automated resolving task, we want the logo to be the PrepDD logo.
  // So we have had to make the verifications for that.
  const canShowAutomationNotification = notification.isAutomation !== null ? notification.isAutomation : false;

  return (
    <div
      style={{
        display: 'flex',
        height: '45px',
        paddingLeft: '16px',
        marginBottom: '24px',
      }}
      onClick={() => handleNotification(notification)}
    >
      <div
        style={{
          flex: 0.75,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        { canShowAutomationNotification
          ? (<img src={PREPDD_LOGO} alt={AUTOMATION_TITLE} className='h-[25px] w-[25px]' />)
          : (<UserImage user={idx(notification, n => n.user)} className='h-[25px] w-[25px] bg-[rgba(42,137,75,0.80)]' />)
        }
      </div>
      <Tooltip
        title={!canShowAutomationNotification ? name.replace(/<\/?[^>]+(>|$)/g, '').length > 50 ? name.replace(/<\/?[^>]+(>|$)/g, '') : '' : ''}
        aria-label="task-name"
        style={{fontSize: '1em'}}
      >
        <div
          style={{
            backgroundColor: privateMessage ? '#E9F4F6' : 'white',
            flex: 8,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            overflowX: 'hidden',
            paddingLeft: '10px',
          }}
        >
          <div style={{lineHeight: '16px'}}>
            <Typography
              style={{lineHeight: '16px', fontSize: '12px', color: '#606060'}}
            >
              { canShowAutomationNotification
                ? <NotificationText message={AUTOMATION_TITLE} />
                : <NotificationText message={name} />
              }
            </Typography>

            <Typography
              style={{
                lineHeight: '16px',
                fontSize: '12px',
                color: '#606060',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              <NotificationText message={description} />
            </Typography>
          </div>
        </div>
      </Tooltip>
      <div
        style={{
          flex: 1.6,
          backgroundColor: privateMessage ? '#E9F4F6' : 'white',
          paddingRight: '10px',
          paddingTop: description ? '7px' : '15px',
          paddingLeft: '10px',
        }}
      >
        <Typography
          style={{
            fontSize: '12px',
            color: '#606060',
            lineHeight: '16px',
            whiteSpace: 'nowrap',
            textAlign: 'right',
          }}
        >
          {createdAt}
        </Typography>
      </div>
    </div>
  );
}

export default TimeLineNotification;
