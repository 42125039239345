import { createMutationHook, gql } from '../graphqlHelpers';

const archiveFiles = createMutationHook(gql`
  mutation ArchiveFiles($fileIds: [ID!]!) {
    archiveFiles(fileIds: $fileIds) {
      success
    }
  }
`);

export default archiveFiles;
