import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {
  Button,
  Grid,
} from '@material-ui/core';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';

import SearchDependency from './SearchDependency';
import { useDispatchContext, useStateContext } from '../../../../../../../../store';
export const INSERT_TASK_DEPENDENCY = gql`
mutation CreateTaskDependency($taskDependency: TaskDependencyAttributes!) {
  createTaskDependency(taskDependency: $taskDependency) {
    success
  }
}
`;
interface RelatedOnlyProps {
  anchorEl: any;
  task: any;
  skinnyLists: any;
  setSkinnyLists: React.Dispatch<any>;
}

export default function RelatedOnly(props: any) {
  const {lists, task, skinnyLists, setSkinnyLists} = props;
  const dispatch = useDispatchContext()

  const classes = useStyles();


  const [relatedTaskId, setRelatedTaskId] = React.useState<string | null>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [
    insertTaskDependency,
    {loading: mutationLoading, data: mutationData, error: mutationError},
  ] = useMutation(INSERT_TASK_DEPENDENCY);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // console.log("related only caled.");
    insertTaskDependency({
      variables: {
        taskDependency: {
          taskId: task.id,
          isDependent: false,
          relatedId: relatedTaskId,
          hasStartDate: false,
        },
      },
    })
    .then((response: any) => {
      //handle response
      // console.log('mutation data', response);
      // show message
      // to do check for success, otherwise show error
      props.snackbarStat(true);
      props.getMsg(`${task.id}`, 'added');

      // alert(response.data.createTaskDependency.success);
      setAnchorEl(props.anchorEl);
      // add dependency to skinnyList object, mostly for re-render of dependency section on overviewpane
      // find correct task indices
      // webpack compile error if initialized as null or uninitialized so init as -1
      let relatedName, relatedDueDate, relatedListNumber;
      for(let list_iter=0; list_iter < skinnyLists.length; ++list_iter ){
        if(skinnyLists[list_iter].tasks &&
          skinnyLists[list_iter].tasks.length > 0){
            let _tasks = skinnyLists[list_iter].tasks;
            for (let i = 0; i < _tasks.length; ++i) {
              if (_tasks[i].id == relatedTaskId) {
                relatedName = _tasks[i].name;
                relatedDueDate = _tasks[i].dueDate;
                relatedListNumber = _tasks[i].listNumber;
              }
            }
          }
      }
      // add deps array if none
      // do we need this?
      // if (!tasks[taskIdx].dependencies) {
      //   tasks[taskIdx].dependencies = [];
      // }


      // add new dependency to the Optimistic UI
      let newly_created_dependency = {
        __typename: 'TaskDependency',
        dueDays: '',
        dueHours: '',
        hasStartDate: false,
        isDependent: false,
        id: '18',
        status: 'Unresolved',
        taskId: task.id,
        taskName: task.name,
        taskDueDate: task.dueDate,
        taskListNumber: task.listNumber,
        relatedId: relatedTaskId,
        relatedName: relatedName,
        relatedDueDate: relatedDueDate,
        relatedListNumber: relatedListNumber
      };
      dispatch({type: 'DEPEDENCIES_UPDATED'})

      // add it to the live display object
      // make sure to deep copy skinnyLists so we're not operating directly on the object
      let _skinnyLists = skinnyLists.map((a: any) => {
        return ({...a});
      });


      let dependency_count = 2;
      for(let list_iter=0; list_iter < _skinnyLists.length; ++list_iter ){
        if(dependency_count == 0){
          break;
        }
        if(_skinnyLists[list_iter].tasks &&
          _skinnyLists[list_iter].tasks.length > 0){
            let _tasks = _skinnyLists[list_iter].tasks;
            for (let i = 0; i < _tasks.length; ++i) {
              if(dependency_count == 0){
                break;
              }
              if (_tasks[i].id == task.id || _tasks[i].id == relatedTaskId) {
                _tasks[i].dependencies.push(newly_created_dependency);
                dependency_count--;
              }
            }
          }
      }
      setSkinnyLists(_skinnyLists);
    })
    .catch((err: any) => {
      console.log('mutation error', err);
    });
  };

  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      borderStyle: 'none',
      width: '100%',
      fontFamily: 'Montserrat',
      color: '#2C2C2C',
      fontSize: '12px',
      fontWeight: 'bold',
    }),
    indicatorSeparator: () => {}, // removes the "stick"
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      textOverflow: 'ellipsis',
      maxWidth: '90%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'initial',
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <SearchDependency
          lists={skinnyLists}
          task={task}
          skinnyLists={skinnyLists}
          setRelatedTaskId={setRelatedTaskId}
        />

        <div>
          <Grid item xs justifyContent="flex-end" className={classes.button}>
            <Button
              variant="contained"
              disabled={!task.id || !relatedTaskId}
              color="primary"
              type="submit"
              data-cy='related-save'
            >
              Save
            </Button>
          </Grid>
        </div>
      </form>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '.MuiTabPanel-root': {
      padding: '10px 20px 20px 4px',
    },

    '.MuiAutocomplete-popup': {
      zIndex: 999999,
    },
  },
  input: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
  '.MuiList-padding': {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  closeIc: {
    fontSize: '12px',
  },
  button: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
}));
