import React from 'react';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    prepLink: {
      textDecoration: 'none',
      color: '#0F3B5C',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

/**
 * OktaSignInButton is a component that renders a link for logging in with Single Sign-On (SSO).
 * It utilizes Material-UI components and react-router-dom for routing.
 * 
 * @returns {JSX.Element} The OktaSignInButton component.
 */
const OktaSignInButton = () => {
  const classes = useStyles({});

  return (
    <div className='mb-5 text-center'>
      <Link component={RouterLink} variant="body2" to="/okta-sign-in" className={classes.prepLink}>
        <button className='w-full px-4 py-2 font-medium text-center rounded-sm shadow-sm pointer-events-auto bg-[linear-gradient(#007dc1,#0073b2)] border-[#004b75 #004b75 #00456a] shadow-[0 1px #00000026, inset 0 1px #ffffff1a] font-bold text-white hover:bg-[#0087d0]'> Sign in with Okta </button>
      </Link>
    </div>
  );
};

export default OktaSignInButton;
