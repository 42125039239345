/* linted */
/* eslint camelcase: 0 */ // --> ignore GQL autogen types

/*
  This component contains various click handlers, to updated the viewed
  status of different viewable types -- files, messages
*/
// 🚨 This component is duplicated in the code base and seems not being used.
// We have 3 occurences of this and it's creating a lot of confusion.
// TODO: Find the possible duplicated files/components too and remove all of them.
import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  ClickAwayListener,
  Drawer,
  List,
  ListItem,
  Typography,
  DialogContentText,
  Button,
} from '@material-ui/core';
import RightIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import { useDispatchContext, useStateContext } from '../../../../../store';
import { FileUploadProperties, FileUploadWorkerProperties, useFileUploadWorker } from '../../../../../hooks/useFileUploadWorker'

import Panel from '../../../../common/Panel';
import FilesPane from '../../../../common/panes/FilesPane';
import OverviewPane from './components/OverviewPane';
import { MessagePane } from './components/MessagePane';
import { StatusDropdown } from '../../../../common/StatusDropdown';

import { UserTasks_userTasks } from '../../../../../graphql/queries/__generated__/UserTasks';
import { useUpdateTask } from '../../../../../graphql/mutations/UpdateTaskHook';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import InputForm from '../../../../common/EditableInputForm';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUpSharp';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import { useDropzone } from 'react-dropzone';
import { useQuery } from '@apollo/client';
import { withRouter } from 'react-router';
import TaskMessenger from './components/TaskMessenger/TaskMessenger';
import { isFunction, truncate } from 'lodash';
import { Link } from '@material-ui/icons';
import { skinnyListWithTasks } from '../../../../../graphql/queries/searchSkinnyListWithTasks';
import { useSetFilters } from '../../../../../hooks/SetFilters';
import idx from 'idx';
import AlertModal from './components/TaskDependency/AlertModal';
import CreateProjectDependencyModal from '../../../../list/CreateProjectPage/components/common/AlertModal';

const panelWidth = 500;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: panelWidth,
      flexShrink: 0,
    },
    drawerRoot: {
      height: '100%',
      paddingBottom: 40,
    },
    commonClass: {
      width: panelWidth,
      overflow: 'hidden',
      zIndex: 7,
    },
    calendarPaper: {},
    drawerPaper: (props: any) => ({
      top: "inherit",
      bottom: 0,
      height: props.height,
      width: panelWidth
    }),
    searchPaper: {},
    createProjectPaper: {
      width: panelWidth,
      overflow: 'hidden',
      marginTop: 0,
      borderTopColor: '#D8D8D8',
      borderTopStyle: 'solid',
      borderTopWidth: 'thin',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      right: 0,
      bottom: 0,
      height: '100%',
      position: 'relative',
    },
    panelStyle: {
      height: 'calc(100% - 150px)',
      overflow: 'hidden',
    },
    drawerSpacer: {
      // marginTop: 138,
    },
    drawerHeader: {
      padding: '12px 16px 0px 16px',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    priority: {
      color: '#509E6D',
    },
    light: {
      marginLeft: '10px',
    },
    statusBlock: {
      position: 'relative',
      marginBottom: '10px',
      marginLeft: '-1px',
    },
    textFlow: {
      display: 'inline-block',
      width: 'fit-content',
      maxWidth: 'calc(80%)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    taskDisplayName: {
      fontSize: '12px',
      color: '#3A84FF',
      fontFamily: 'Montserrat',
      cursor: 'pointer',
    },
    priority2: {
      display: 'flex',
      width: '150px',
      height: '28px',
      cursor: 'pointer',
      color: '#509E6D',
      background: '#FFFFFF',
      alignItems: 'center',
      padding: '1px 6px 1px 0px',
      marginRight: '60px',
      border: '1px solid #ECECEC',
      borderRadius: '3px',
      flexWrap: 'nowrap',
    },
    transform: {
      textTransform: 'capitalize',
      paddingLeft: '6px',
    },
    priorityDropdown: {
      position: 'absolute',
      top: '27px',
      // left: '0px',
      width: '150px',
      color: '#509E6D',
      background: '#FFFFFF',
      border: '1px solid #ECECEC',
      borderRadius: '3px',
      zIndex: 10000,
      paddingTop: 0,
      paddingBottom: 0,
    },
    headerPointer: {
      cursor: 'pointer',
    },
    shortText: {
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '90%',
      marginLeft: '10px',
      color: 'dimgrey',
      fontSize: '15px',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      cursor: 'text',
    },
    taskNumber: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      // width: '75%',
      textOverflow: 'ellipsis',
      // marginLeft: '12px',
      color: 'dimgrey',
      fontSize: '15px',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      cursor: 'text',
    },
    taskTitle: {
      marginLeft: '10px',
      marginTop: '0px',
    },
    titleInputContainer: {
      marginTop: '-54px',
      // marginLeft: '15px',
    },
    ribbon: {
      '&:hover': {
        backgroundColor: '#FFF8E5',
        pointerEvents: 'none',
      },
    },
    ribbonText: {
      color: '#606060',
      opacity: '100%',
      fontFamily: 'Montserrat',
      fontSize: '12px',
    },
    blueRibbonText: {
      color: '#3b84ff',
      opacity: '100%',
      fontFamily: 'Montserrat',
      fontSize: '12px',
    },
    dependencyText: {
      fontSize: '12px',
      lineHeight: '15px',
      color: '#606060',
      opacity: 1,
      fontWeight: 'normal',
    },
    dialogButton: {
      width: '200px',
      fontWeight: 'bold',
      borderRadius: '2px',
      paddingTop: '12px',
      paddingBottom: '12px',
    },
    closeBtnContainer: {
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    closeBtn: {
      transform: 'rotate(270deg)',
      color: '#606060',
    },
    overviewPanelContainer: {
      overflow: 'hidden',
    },
  })
);

interface TaskDetailPageProps {
  open: boolean;
  selectedTask: UserTasks_userTasks;
  tasks: UserTasks_userTasks[];
  allTasks: UserTasks_userTasks[];
  paneIndex: number;
  lists: any;
  multiTasks: string[];
  setTasks: React.Dispatch<React.SetStateAction<UserTasks_userTasks[]>>;
  history: any;
  setPaneIndex: any;
  updateTaskStatus: any;
  isCalendar?: Boolean;
  isInternalCreateProject?: Boolean;
  isExternalCreateProject?: Boolean;
  skinnyLists: any;
}

function TaskDetailPage(props: any) {
  const {
    open,
    selectedTask,
    tasks,
    updateTaskLocal,
    allTasks,
    paneIndex,
    lists,
    multiTasks,
    setTasks,
    history,
    search,
    updateTaskStatus,
    updateStatusAfterUpload,
    setPaneIndex,
    isInternalCreateProject,
    isExternalCreateProject,
    handleClose,
    setTaskListPrimaryDisplaySkeleton,
    setTaskListSecondaryDisplaySkeleton,
    detailedTaskLoading,
    height
  } = props;
  const setFilters = useSetFilters();
  const dispatch = useDispatchContext()
  const state = useStateContext();

  const widgetHeight = idx(state, state => state.widgetHeight)

  // Make sure to dynamically calculate the task sidebar height
  // Based on the top navbar and the list table header content height.
  useEffect(() => {
    dispatch({
      type: 'SET_WIDGET_HEIGHT',
      widgetHeight: height + 66,
    });
  }, [height])

  const styleProps = {
    height: `calc(100% - ${widgetHeight}px)`
  } 

  const classes = useStyles(styleProps);


  // helper function which takes in the task and type ('files'/'messages') and returns the unseen/unread files/messages count
  const unReadHelper = (task: any, type: string) => {
    var unreadCount = 0;
    if (task[type].length) {
      task[type].forEach((elem: any) => {
        if (elem.viewed == false) {
          unreadCount += 1;
        }
      });
    }
    return unreadCount;
  };

  // number of unseen/unread files/messages
  const unSeenFiles =
    idx(selectedTask, (task: any) => unReadHelper(task, 'files')) || 0;
  const unReadMessages =
    idx(selectedTask, (task: any) => unReadHelper(task, 'messages')) || 0;

  // based on the count; show the appropriate header
  const panelLabels = isExternalCreateProject
    ? [{ label: 'Overview' }]
    : [{ label: 'Overview' }, { label: 'Data' }, { label: 'Messages' }];

  const [badges, setBadges] = useState<number[]>([]);
  const [editTask, setEditTask] = useState<any>({});

  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [oldStatus, setOldStatus] = useState('');

  const handleCancelAlertModal = (_: any) => {
    updateTaskStatus(selectedTask.id, oldStatus);
    setOpenAlertDialog(false);
  };

  const handleCompleteAlertModal = (_: any) => {
    updateTaskStatus(selectedTask.id, selectedTask.status);
    setOpenAlertDialog(false);
  };

  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [openTitle, setOpenTitle] = useState<boolean>(true);
  const [openTitleHover, setOpenTitleHover] = useState<boolean>(false);
  const fileUploadWorker = useFileUploadWorker()
  const [fetchNewSkinnyList, setFetchNewSkinnyList] = useState<any>(
    props.forceUpdateSkinnyList ? props.forceUpdateSkinnyList : 1
  );
  const tasksRef = useRef(tasks);
  tasksRef.current = tasks;

  useEffect(() => {
    setOpenTitle(true);
  }, [selectedTask.id]);

  useEffect(() => {
    if (selectedTask.id) {
      dispatch({
        type: 'SET_SELECTED_TASK',
        selectedTask: selectedTask,
      });
    }
  }, [selectedTask.id]);

  const handleChangeOpen = (bool: boolean) => {
    if(state.currentUser.isExternalUser) { return; }

    setOpenStatus(bool);
  }

  const onDrop = (acceptedFiles: File[]) => {

    const formattedFiles: FileUploadProperties[] = acceptedFiles.map((file: File, index: number) => {
      return {
        file,
        taskId: selectedTask.id
      }
    });

    const fileUploadWorkerProperties: FileUploadWorkerProperties = {
      files: formattedFiles,
      uploadType: 'task',
      selectedTask: selectedTask,
      updateTask: updateTaskLocal,
      updateTaskStatus: updateTaskStatus,
      afterUpload: (file: any) => updateStatusAfterUpload(selectedTask)
    }
    
    fileUploadWorker(fileUploadWorkerProperties);

  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });
  function toggleTittleOpen() {
    setOpenTitle(!openTitle);
  }

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      const titleInput = document.getElementById('text-area-input');
      // @ts-ignore
      titleInput.blur();
    }
    if (e.keyCode === 27) {
      const titleInput = document.getElementById('text-area-input');
      // @ts-ignore
      titleInput.innerHTML = task2 ? (task2.name as string) : 'None';
      // @ts-ignore
      titleInput.blur();
    }
  };

  useEffect(() => {
    var badgesFilled: any = [];
    if (unSeenFiles > 0 || unReadMessages > 0) {
      badgesFilled.push(0);
      if (unSeenFiles > 0) {
        badgesFilled.push(1);
      }
      if (unReadMessages > 0) {
        badgesFilled.push(2);
      }
    }
    setBadges(badgesFilled);
  }, [unSeenFiles, unReadMessages]);

  const handleChangeName = (newValue: string) => {
    var newTask = selectedTask;
    newTask.name = newValue;
    updateTasks(newTask);
    setEditTask(newTask);
    if (props.updateTaskName) props.updateTaskName(newTask.id, newValue);
  };

  function updateTasks(newTask: any) {
    var newTasks = tasks;
    var taskIndex = newTasks.find((t: any) => t.id === newTask.id);

    newTasks[taskIndex] = newTask;
    setTasks(newTasks);
  }

  const [
    createProjectDependencyClick,
    setCreateProjectDependencyClick,
  ] = useState(false);
  const [taskIdToNav, setTaskIdToNav] = useState('');

  function onDependencyClick(taskToOpen: any) {
    setTaskIdToNav(taskToOpen);
    if (isExternalCreateProject || isInternalCreateProject) {
      if (!tasks.some((t: any) => t.id === taskToOpen)) {
        setCreateProjectDependencyClick(true);
      } else {
        props.createProjectSelectedTask(
          tasks.find((t: any) => t.id === taskToOpen)
        );
      }
    } else {
      openTask(taskToOpen);
    }
  }

  function createProjectDependencyDialogHeader() {
    return (
      <div>
        <Typography
          style={{ color: '#707070', fontSize: '18px', fontWeight: 'bold' }}
        >
          Dependency Warning
        </Typography>
      </div>
    );
  }

  function createProjectDependencyDialogContent() {
    const text =
      'You are about to navigate away from this page and any changes you have made will be lost.';
    return (
      <div className={classes.flex} style={{ flexDirection: 'row' }}>
        <DialogContentText
          className={classes.dependencyText}
          style={{ marginBottom: '0px' }}
        >
          {text}
        </DialogContentText>
      </div>
    );
  }

  function createProjectDependencyDialogActions() {
    return (
      <React.Fragment>
        <Button
          onClick={createProjectDependencyCancel}
          variant='outlined'
          className={classes.dialogButton}
        >
          Cancel
        </Button>
        <Button
          onClick={createProjectDependencyOk}
          variant='contained'
          className={classes.dialogButton}
          style={{ marginLeft: '24px' }}
        >
          Okay
        </Button>
      </React.Fragment>
    );
  }

  function createProjectDependencyOk() {
    openTask(taskIdToNav);
    setCreateProjectDependencyClick(false);
  }

  function createProjectDependencyCancel() {
    setCreateProjectDependencyClick(false);
  }

  function openTask(taskToOpen: any) {
    let listToOpen = -1;
    // 1. determine list to which this task belongs
    for (let i = 0; i < skinnyLists.length; ++i) {
      for (let j = 0; j < skinnyLists[i].tasks.length; ++j) {
        if (skinnyLists[i].tasks[j].id == taskToOpen) {
          listToOpen = skinnyLists[i].id;
          break;
        }
      }
    }
    let isPresentInView: Boolean;
    isPresentInView = false;
    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].id == taskToOpen) {
        isPresentInView = true;
        break;
      }
    }
    if (listToOpen != selectedTask.list.id || !isPresentInView) {
      setFilters({
        taskPageStatus: [
          'Unstarted',
          'Started',
          'Prepared',
          'Delivered',
          'Rejected',
          'Complete',
        ],
        taskPageSelectedTasks: [taskToOpen.toString()],
        taskPageLists: [listToOpen.toString()],
      });
    } else {
      setFilters({
        taskPageSelectedTasks: [taskToOpen.toString()],
        taskPageLists: [listToOpen.toString()],
      });
    }

    if (isInternalCreateProject || isExternalCreateProject)
      setTimeout(() => history.push('/app/tasks'), 500);
  }

  /* side panel upload */
  useEffect(() => {
    if (selectedTask) {
      dispatch({
        type: 'SET_TASK_UPLOAD',
        taskUpload: {
          taskId: selectedTask.id,
          listId: selectedTask.list && selectedTask.list.id,
          taskStatus: selectedTask.status,
        },
      });
    }
  }, [isDragActive]);

  const [updateTask] = useUpdateTask({
    id: editTask.id,
    status: editTask.status,
    name: editTask.name,
    priority: editTask.priority,
  });

  useEffect(() => {
    if (editTask.id) updateTask();
  }, [editTask]);

  const updateTasksAfterFileUpload = (newFile: any, oldFileId: string) => {
    newFile.updatedAt = 'just now';
    newFile.viewed = true;

    // yucky
    var newTasks = tasks.slice();
    var task = newTasks.findIndex((t: any) => t.id === selectedTask.id);
    var taskFiles = newTasks[task].files;
    var index = taskFiles.map((t: any) => t.id).indexOf(oldFileId);
    taskFiles.splice(index, 1, newFile);
    newTasks[task].files = taskFiles;
    const listIndex = lists.findIndex(
      (l: any) => l.id === newTasks[task].list.id
    );
    if (
      lists[listIndex].deliverFileOnUpload === 'yes' &&
      lists[listIndex].companyPosition === 'responder' &&
      (newTasks[task].status !== 'Delivered' ||
        newTasks[task].status !== 'Complete')
    ) {
      newTasks[task].status = 'Delivered';
    }
    setTasks(newTasks);
  };
  const [openPriority, setOpenPriority] = useState<boolean>(false);

  const handlePriorityClick = () => {
    setOpenPriority(!openPriority);
  };

  const handleChangePriority = (newPriority: string) => {
    var newTask = Object.assign({}, selectedTask);
    newTask.priority = newPriority;
    newTask.updatedAt = 'just now';
    var newTasks = tasks.map((t: any) => ({ ...t }));
    var taskIndex = newTasks.findIndex((t: any) => t.id === newTask.id);
    newTasks[taskIndex] = newTask;
    setTasks(newTasks);
    setOpenPriority(false);

    setEditTask(newTask);
  };

  // all dependency stuff starts here
  const skinny_list_query = skinnyListWithTasks;
  // first fetch all the list, their tasks and dependencies for each task
  // const dependencies = [] as any;
  const [dependencies, setDependencies] = useState<any>({});
  const [skinnyLists, setSkinnyLists] = useState<any>([]);

  useEffect(() => {
    const currentSkinnyTasks =
      idx(skinnyLists, (s) => s[s.length - 1].tasks) || [];
    const actualSkinnyTasks =
      idx(props.skinnyLists, (s) => s[s.length - 1].tasks) || [];
    if (currentSkinnyTasks.length !== actualSkinnyTasks.length)
      setSkinnyLists(props.skinnyLists);
  }, [idx(props.skinnyLists, (s) => s[s.length - 1].tasks.length)]);

  const {
    loading: projectLoading,
    data: skinnyListData,
    error: projectError,
  } = useQuery(skinny_list_query, {
    variables: { id: fetchNewSkinnyList },
  });

  function filterSkinnyList(skinnyList: any) {
    skinnyList.forEach((_list: any) => {
      let __tasks = _list.tasks;
      if (_list.tasks.length > 0) {
        __tasks = _list.tasks.filter((_task: any) => _task.isVolatile == false);
      }
      _list.tasks = __tasks;
      return _list;
    });
    return skinnyList;
  }

  useEffect(() => {
    if (
      !(isExternalCreateProject || isInternalCreateProject) &&
      skinnyListData &&
      skinnyListData.skinnyListsWithTasks &&
      skinnyListData.skinnyListsWithTasks.length > 0
    ) {

      const skinnyListDataCopy = JSON.parse(JSON.stringify(skinnyListData))
      let filteredSkinnyListData = filterSkinnyList(
        skinnyListDataCopy.skinnyListsWithTasks
      );
      setSkinnyLists(filteredSkinnyListData);
    }
  }, [skinnyListData]);

  useEffect(() => {
    let temp = {} as any;
    if (skinnyLists && skinnyLists.length > 0) {
      skinnyLists.forEach((_list: any) => {
        let _tasks = _list.tasks;
        _tasks.forEach((t: any) => {
          temp[t.id] = t.dependencies;
        });
      });
    }
    setDependencies(temp);
  }, [skinnyLists]);

  function getTaskName(id: string) {
    let taskName = '';
    if (skinnyLists && skinnyLists.length > 0) {
      skinnyLists.forEach((_list: any) => {
        let _tasks = _list.tasks;
        _tasks.forEach((t: any) => {
          if (t.id == id) {
            taskName = t.name;
          }
        });
      });
    }
    return taskName;
  }

  let months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  function relatedTaskDueDate(taskDueDate: string) {
    if (taskDueDate && taskDueDate.length > 0) {
      let date_hours = taskDueDate.split(' ');
      let date = date_hours[0].split('-');
      let idx = date[1];
      let displayedDueDate = months[parseInt(idx) - 1]
        .concat(' ')
        .concat(date[2]);
      return displayedDueDate;
    } else {
      return '';
    }
  }

  function getTaskDueDate() {
    let relatedTaskids = [] as any;
    let relatedDate: string;
    relatedDate = '';
    dependencies[selectedTask.id].forEach((dependency: any) => {
      if (dependency.taskId == selectedTask.id) {
        relatedTaskids.push(dependency.relatedId.toString());
      } else {
        //relatedTaskids.push(dependency.taskId.toString());
      }
    });

    skinnyLists.forEach((_list: any) => {
      let _tasks = _list.tasks;
      _tasks.forEach((t: any) => {
        if (relatedTaskids.includes(t.id.toString())) {
          if (
            (relatedDate == '' && t.dueDate && t.dueDate.length > 0) ||
            (t.dueDate &&
              t.dueDate.length > 0 &&
              Date.parse(t.dueDate) > Date.parse(relatedDate))
          ) {
            relatedDate = t.dueDate;
          }
        }
      });
    });
    if (relatedDate.length > 0) {
      let date_hours = relatedDate.split(' ');
      let date = date_hours[0].split('-');
      let day = date[2].split('T')[0];
      let displayed_month_index = date[1];
      return months[parseInt(displayed_month_index) - 1]
        .concat(' ')
        .concat(day);
    } else {
      return '';
    }
  }

  // function relatedTaskDueDate(taskDueDate: string) {
  //   if (taskDueDate && taskDueDate.length > 0) {
  //     let date_hours = taskDueDate.split(' ');
  //     let date = date_hours[0].split('-');
  //     let idx = date[1];
  //     let displayedDueDate = months[parseInt(idx) - 1]
  //       .concat(' ')
  //       .concat(date[2]);
  //     return displayedDueDate;
  //   } else {
  //     return '';
  //   }
  // }

  function getListDetails() {
    let relatedListNumber: string[] = [];
    let relatedTName: string[] = [];
    let relatedDDays: string[] = [];
    let relatedDHours: string[] = [];
    let relatedStartDue: string[] = [];
    let relatedTaskId: string[] = [];
    let relatedId: string[] = [];
    let relatedDueDate: string[] = [];
    let relatedDate: string;
    relatedDate = '';

    if (
      dependencies[selectedTask.id] &&
      dependencies[selectedTask.id].length > 0
    ) {
      dependencies[selectedTask.id].forEach((dependency: any) => {
        if (dependency.isDependent && dependency.relatedId != selectedTask.id) {
          relatedTName.push(dependency.relatedName);
          relatedDDays.push(dependency.dueDays);
          relatedDHours.push(dependency.dueHours);
          relatedStartDue.push(dependency.hasStartDate);
          relatedId.push(dependency.relatedId);
          relatedTaskId.push(dependency.taskId);
          relatedListNumber.push(dependency.relatedListNumber);
          relatedDueDate.push(relatedTaskDueDate(dependency.relatedDueDate));
        }
      });
    }
    return [
      relatedTName,
      relatedDDays,
      relatedDHours,
      relatedStartDue,
      relatedListNumber,
      relatedTaskId,
      relatedId,
      relatedDueDate,
    ];
  }

  function getRibbonText() {
    let ribbonTaskName = ''; //only one task to show and link
    let ribbonTaskName2 = ''; //n tasks
    let prefix = '';
    let suffix = '';
    let dependencyCount = 0;
    let linkedTaskId = -1;

    if (
      dependencies[selectedTask.id] &&
      dependencies[selectedTask.id].length > 0
    ) {
      dependencies[selectedTask.id].forEach((dependency: any) => {
        if (dependency.isDependent && dependency.relatedId != selectedTask.id) {
          dependencyCount++;

          if (dependencyCount == 1) {
            prefix = 'Dependent on ';
            ribbonTaskName = getTaskName(dependency.relatedId);
            if (ribbonTaskName.length > 32) {
              ribbonTaskName = ribbonTaskName.substring(0, 32) + '...';
            }
            linkedTaskId = dependency.relatedId;
          }
        }
      });
      if (dependencyCount > 1) {
        ribbonTaskName = '';
        ribbonTaskName2 = dependencyCount.toString().concat(' tasks ');
      }
      if (dependencyCount > 0) {
        let date = getTaskDueDate();
        if (date != '') {
          if (dependencyCount == 1) {
            suffix = '  due on '.concat(date);
          } else {
            suffix = '  due on or before '.concat(date);
          }
        }
      }
    }
    return [
      prefix,
      ribbonTaskName,
      ribbonTaskName2,
      suffix,
      dependencyCount,
      linkedTaskId,
    ];
  }
  const [
    ribbonPrefix,
    ribbonTaskName,
    ribbonTaskName2,
    ribbonSuffix,
    ribbonDependencyCount,
    linkedTaskId,
  ] = getRibbonText();

  function handleChangeStatus(_: any, newStatus: string) {
    if(state.currentUser.isExternalUser) { return; }
    updateTaskStatus(selectedTask.id, newStatus);
    setOldStatus(selectedTask.status);
    if (newStatus == 'Complete' && ribbonPrefix.toString().length > 0) {
      setOpenAlertDialog(true);
    }
    setOpenStatus(false);
  }

  const [
    relatedTName,
    relatedDDays,
    relatedDHours,
    relatedStartDue,
    relatedListNumber,
    relatedTaskId,
    relatedId,
    relatedDueDate,
  ] = getListDetails();

  const drawerPaper = search
      ? classes.searchPaper
      : isInternalCreateProject || isExternalCreateProject
          ? classes.createProjectPaper
          : classes.drawerPaper;

  function getRelatedStatus() {
    let relatedStatus: string[] = [];
    let relatedTaskName: string[] = [];
    let updateStatusTasks: any;
    updateStatusTasks =
      allTasks ||
      [].map((t: any, i: number) => {
        return {
          allTaskId: t.id,
          status: t.status,
        };
      });

    relatedTaskId.forEach((id: any) => {
      if (dependencies[id] && dependencies[id].length > 0) {
        dependencies[id].forEach((relatedStat: any) => {
          if (
            relatedStat.relatedId != selectedTask.id &&
            relatedStat.isDependent
          ) {
            {
              updateStatusTasks.map((option: any) => {
                if (option.allTaskId == relatedStat.relatedId) {
                  relatedStatus.push(option.status);
                  relatedTaskName.push(relatedStat.relatedId);
                }
              });
            }
          }
        });
      }
    });
    return [relatedStatus, updateStatusTasks];
  }

  const [relatedStatus] = getRelatedStatus();

  function updateSkinnyLists(skinnyLists: any) {
    setSkinnyLists(skinnyLists);
    props.setSkinnyLists && props.setSkinnyLists(skinnyLists);
  }

  // function updateDependencies(dependencies: any) {
  //   setDependencies(dependencies);
  //   props.setDependencies(dependencies);
  // }

  function onClose() {
    if (handleClose && isFunction(handleClose)) {
      handleClose();
    }
  }

  return (
    <Drawer
      anchor='right'
      className={clsx(
        classes.drawer,
        isExternalCreateProject || isInternalCreateProject
          ? classes.drawerRoot
          : undefined
      )}
      classes={{paper: clsx(drawerPaper, classes.commonClass)}} 
      data-cy='side-panel-input'
      open={Boolean(open)}
      variant='persistent'
      {...getRootProps({})}
    >
      {createProjectDependencyClick && (
        <CreateProjectDependencyModal
          open={createProjectDependencyClick}
          dialogContent={createProjectDependencyDialogContent()}
          dialogHeader={createProjectDependencyDialogHeader()}
          dialogActions={createProjectDependencyDialogActions()}
          onCancel={createProjectDependencyCancel}
          onOk={createProjectDependencyOk}
        />
      )}
      <input {...getInputProps()} />
      {openAlertDialog &&
        !(isExternalCreateProject || isInternalCreateProject) &&
        ribbonPrefix.toString().length > 0 &&
        // !relatedStatus.includes('Complete')
        ((relatedStatus.filter((item: string) => item === 'Complete').length >
          0 &&
          relatedStatus.filter((item: string) => item != 'Complete').length !=
          0) ||
          relatedStatus.filter((item: string) => item != 'Complete').length ===
          relatedStatus.length) ? (
        <AlertModal
          open={openAlertDialog}
          onCancel={handleCancelAlertModal}
          onComplete={handleCompleteAlertModal}
          relatedTName={relatedTName}
          relatedDDays={relatedDDays}
          relatedDHours={relatedDHours}
          relatedStartDue={relatedStartDue}
          relatedListNo={relatedListNumber}
          relatedTaskId={relatedTaskId}
          relatedId={relatedId}
          relatedDueDate={relatedDueDate}
          skinnyLists={skinnyLists}
          setSkinnyLists={updateSkinnyLists}
        />
      ) : (
        <div></div>
      )}{' '}
      <div className={classes.drawerSpacer} />
      {ribbonPrefix != '' ? (
        <div
          style={{
            backgroundColor: '#FFF8E5',
            border: '#FFDF00',
            display: 'flex',
            alignItems: 'center',
            height: '36px',
          }}
        >
          <div
            data-cy='ribbon'
            style={{
              marginLeft: '14px',
              display: 'flex',
              alignItems: 'center',
              height: '36px',
            }}
          >
            <Link
              style={{ marginRight: '12px', color: '#9F9F9F', opacity: '100%' }}
            />
            <Typography
              data-cy='task-title-prefix'
              style={{
                color: '#606060',
                opacity: '100%',
                fontFamily: 'Montserrat',
                fontSize: '12px',
                float: 'left',
                margin: '0px 4px 0px 0px',
              }}
            >
              {ribbonPrefix}
            </Typography>
            <Typography
              data-cy='task-title-name'
              className={
                ribbonDependencyCount == 1
                  ? classes.blueRibbonText
                  : classes.ribbonText
              }
            >
              <Typography
                onClick={(e) => onDependencyClick(linkedTaskId)}
                variant='inherit'
                className={classes.taskDisplayName}
                data-cy='task-links'
              >
                {ribbonTaskName}
              </Typography>

              <Typography
                data-cy='task-title-prefix'
                style={{
                  color: '#606060',
                  opacity: '100%',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  float: 'left',
                  margin: '0px 4px 0px 0px',
                }}
              >
                {ribbonTaskName2}
              </Typography>
            </Typography>
            <Typography
              data-cy='task-title-suffix'
              style={{
                color: '#606060',
                opacity: '100%',
                fontFamily: 'Montserrat',
                fontSize: '12px',
                float: 'left',
                margin: '0px 0px 0px 4px',
              }}
            >
              {ribbonSuffix}
            </Typography>
            {/* </ListItem>
          </List> */}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={classes.drawerHeader}>
        <div
          className={clsx(classes.flex, classes.statusBlock)}
          data-cy='side-panel-status-container'
        >
          <StatusDropdown
            changeOpen={handleChangeOpen}
            onChange={handleChangeStatus}
            open={openStatus}
            task={selectedTask}
            isExternalUser={state.currentUser.isExternalUser}
          />

          <ClickAwayListener onClickAway={() => setOpenPriority(false)}>
            <div
              data-cy='priority-tag'
              style={{ paddingLeft: '10px', flex: 1 }}
            >
              <div
                className={classes.priority2}
                data-cy='overview-priority'
                onClick={handlePriorityClick}
              >
                {selectedTask && selectedTask.priority === 'high' && (
                  <RightIcon data-cy='high-priority-icon' />
                )}
                {/* <RightIcon data-cy="high-priority-icon" /> */}
                <Typography
                  className={clsx(classes.transform, classes.headerPointer)}
                  data-cy='priority-name'
                  style={{
                    paddingLeft:
                      selectedTask && selectedTask.priority === 'high'
                        ? '0px'
                        : '25px',
                    whiteSpace: 'nowrap',
                  }}
                // variant="h6"
                >
                  {/* High Priority */}
                  {selectedTask &&
                    selectedTask.priority === 'high' &&
                    'High Priority'}
                  {selectedTask &&
                    selectedTask.priority === 'medium' &&
                    'Medium Priority'}
                  {selectedTask &&
                    selectedTask.priority === 'low' &&
                    'Low Priority'}
                  {/* : selectedTask.priority} */}
                </Typography>
              </div>

              {openPriority && (
                <List className={classes.priorityDropdown}>
                  <ListItem
                    onClick={() => handleChangePriority('high')}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <RightIcon />
                    <Typography data-cy='priority-dropdown-high'>
                      High Priority
                    </Typography>
                  </ListItem>
                  <ListItem
                    onClick={() => handleChangePriority('medium')}
                    style={{ paddingLeft: '23px' }}
                  >
                    <Typography data-cy='priority-dropdown-medium'>
                      Medium Priority
                    </Typography>
                  </ListItem>
                  <ListItem
                    onClick={() => handleChangePriority('low')}
                    style={{ paddingLeft: '23px' }}
                  >
                    <Typography data-cy='priority-dropdown-low'>
                      Low Priority
                    </Typography>
                  </ListItem>
                </List>
              )}
            </div>
          </ClickAwayListener>
          {handleClose && (
            <div onClick={onClose} className={classes.closeBtnContainer}>
              <VerticalAlignBottomIcon className={classes.closeBtn} />
            </div>
          )}
        </div>
        <div className={classes.flex}>
          {/* {selectedTask && selectedTask.priority === 'high' && (
            <RightIcon className={classes.priority} />
          )} */}
          {/* <Typography
            className={classes.textFlow}
            style={{fontWeight: 700}}
            variant="h2"
          >
            {selectedTask && selectedTask.listNumber
              ? selectedTask.listNumber + '. '
              : ' '}
            <span className={classes.light} data-cy="side-panel-title">
              {selectedTask && selectedTask.name}
            </span>
          </Typography> */}

          <div
            data-cy='title-container'
            style={{ width: '100%', display: 'flex' }}
          >
            <div>
              <Typography
                className={classes.taskNumber}
                data-cy='task-title'
                variant='h6'
              >
                {selectedTask && selectedTask.listNumber}.
              </Typography>
            </div>

            {!openTitle && (
              <div
                onClick={toggleTittleOpen}
                onMouseLeave={() => setOpenTitleHover(false)}
                onMouseOver={() => setOpenTitleHover(true)}
                style={{ flex: 1 }}
                data-cy='side-panel-task-name'
              // style={{display: 'inline-block', width: '92%'}}
              >
                <Typography
                  className={classes.shortText}
                  data-cy='overview-title'
                  variant='h6'
                >
                  {selectedTask &&
                    selectedTask.name &&
                    truncate(selectedTask.name, { length: 45 })}
                </Typography>

                {/* {openTitleHover && ( */}
                <div
                  style={{
                    float: 'right',
                    marginTop: '-25px',
                    visibility: openTitleHover ? 'visible' : 'hidden',
                  }}
                >
                  <ArrowDownIcon onClick={toggleTittleOpen} />
                </div>
                {/* )} */}
              </div>
            )}

            {openTitle && (
              <div
                className={classes.titleInputContainer}
                style={{ marginTop: '-25px', flex: 1 }}
              >
                <div
                  className={clsx(classes.taskTitle)}
                  onMouseLeave={() => setOpenTitleHover(false)}
                  onMouseOver={() => setOpenTitleHover(true)}
                  style={{ display: 'inline-block', width: '95%' }}
                >
                  <div style={{ overflowY: 'auto', maxHeight: '200px' }}>
                    <InputForm
                      fontWeight={600}
                      onChange={handleChangeName}
                      onKeyDown={handleKeyDown}
                      onUpdate={updateTask}
                      onlyEnter
                      textArea
                      value={selectedTask && (selectedTask.name as string)}
                      dataCy='side-panel-task-name-input'
                    />
                  </div>
                  <div
                    style={{
                      float: 'right',
                      marginTop: '-25px',
                      minHeight: '30px',
                      marginRight: '-12px',
                    }}
                    data-cy='task-name-arrow-up-icon'
                  >
                    {openTitleHover && (
                      <ArrowUpIcon onClick={toggleTittleOpen} />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Panel
        badges={badges}
        labels={panelLabels}
        padding
        paneIndex={paneIndex}
        setPanelIndex={setPaneIndex}
        passedClasses={
          isInternalCreateProject || isExternalCreateProject
            ? classes.panelStyle
            : classes.overviewPanelContainer
        }
      >
        <OverviewPane
          multiTasks={multiTasks}
          search={search}
          setTasks={setTasks}
          lists={lists}
          task={selectedTask}
          task2={selectedTask}
          tasks={tasks}
          skinnyLists={skinnyLists}
          setSkinnyLists={updateSkinnyLists}
          dependencies={dependencies}
          setDependencies={setDependencies}
          setFetchNewSkinnyList={setFetchNewSkinnyList}
          isExternalCreateProject={isExternalCreateProject}
          isInternalCreateProject={isInternalCreateProject}
          additionalNewOwners={
            props.additionalNewOwners ? props.additionalNewOwners : undefined
          }
          setAdditionalNewOwners={
            props.setAdditionalNewOwners
              ? props.setAdditionalNewOwners
              : undefined
          }
          externalCompanyId={props.externalCompanyId}
          onDependencyClick={onDependencyClick}
        />
        <FilesPane
          currentPage='Task'
          selectedItem={selectedTask}
          setTasks={setTasks}
          updateTaskStatus={updateTaskStatus}
          updateStatusAfterUpload = {updateStatusAfterUpload}
          task={selectedTask}
          tasks={tasks}
          updateAfterComplete={updateTasksAfterFileUpload}
          isCreateProject={isInternalCreateProject || isExternalCreateProject}
          lists={lists}
          setTaskListPrimaryDisplaySkeleton={setTaskListPrimaryDisplaySkeleton}
          setTaskListSecondaryDisplaySkeleton={
            setTaskListSecondaryDisplaySkeleton
          }
          detailedTaskLoading={detailedTaskLoading}
        />
        <MessagePane
          internal={false}
          setTasks={setTasks}
          taskId={selectedTask.id}
          tasks={tasks}
          isCreateProject={isInternalCreateProject || isExternalCreateProject}
        />
      </Panel>
      {!isExternalCreateProject ? (
        <TaskMessenger
          history={history}
          internal
          setTasks={setTasks}
          task={selectedTask}
          updateTask={updateTaskLocal}
          taskId={selectedTask.id}
          tasks={tasks}
          updateTaskStatus={updateTaskStatus}
          updateStatusAfterUpload={updateStatusAfterUpload}
          isCreateProject={isInternalCreateProject || isExternalCreateProject}
        />
      ) : (
        <></>
      )}
    </Drawer>
  );
}

export default withRouter(TaskDetailPage);
