import {createMutationHook, gql} from '../graphqlHelpers';

const UpdateFileCheckout = createMutationHook<any, any>(gql`
  mutation UpdateFileCheckout(
    $fileVersionId: ID!
    $isCheckedOut: Boolean!
    ) {
      updateFileCheckout (
        fileVersionId: $fileVersionId
        isCheckedOut: $isCheckedOut
      ) {
      success
      errors {
        path
        message
      }
    }
  }
`);

export default UpdateFileCheckout;
