import { gql} from '../graphqlHelpers';

type ID = number | string;

export interface MoveWorkspaceVariables {
  workspaceId: ID;
  destinationWorkspaceId: ID;
}

export interface Error {
  path: string;
  message: string;
}

export interface MoveWorkspaceData {
  success: boolean;
  errors: Error[];
}

export const moveWorkspace = gql `
mutation moveWorkspace($workspaceId: ID!, $destinationWorkspaceId: ID!) {
    moveWorkspace(workspaceId: $workspaceId, destinationWorkspaceId: $destinationWorkspaceId) {
      success
      errors {
        path
        message
      }
    }
  }
`;