export interface NotificationType {
  variant: 'success' | 'warning' | 'error' | 'info';
  message: string;
	time?: any;
}

export interface ListType {
  name: string;
  description: string;
  requesterId: string;
  responderId: string;
	retentionPolicyId?: string;
	estimatedEndDate?: any;
	userEmail: string;
  reconciliationCheck? : string;
}

export interface TaskType {
  name: string;
  description: string;
  priority: string;
  status: string;
  due_date: string;
  section: string;
  isActive: boolean;
}

export enum DueDateEnum {
  LATE = 'Past due',
  TODAY = 'Due today',
  TOMORROW = 'Due tomorrow',
  THIS_WEEK = 'Due this week',
  LATER = 'Due later',
  UNSET = 'Unset'
}

export interface OptionType {
  value: string;
  label: string;
  listNumber?: number;
}

export const RECON_TYPES: any = [
  { name: 'Not Active', id: '0' },
  { name: 'Active Public', id: '1' },
  { name: 'Active Private', id: '2' }
];

export const VALUE_SOURCE_TYPES: any[] = [
  {name: 'Manual', id: '1'},
  {name: 'File Validation', id: '2'},
  {name: 'Intacct', id: '3'},
  {name: 'SoftLedger', id: '4'},
  {name: 'QuickBooks Online', id: '5'},
  {name: 'NetSuite', id: '6'}
];

export enum TaskStatus {
  Unstarted = 'Unstarted',
  Started = 'Started',
  Prepared = 'Prepared',
  RejectedInternal = 'RejectedInternal',
  Delivered = 'Delivered',
  RejectedExternal = 'RejectedExternal',
  Complete = 'Complete',
  Rejected = 'Rejected',
  NotApplicable = 'NotApplicable'
}

export type PopperPlacement = "bottom" | "left" | "right" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start" | undefined


export interface TaskTag {
  tag: string;
  id: string;
}

export interface MessageLabelType {
  id: string;
  name: string;
  color: string;
  isActive: boolean;
  companyId: string;
  userId: string;
}

export interface ListLabelType {
  id: string;
  name: string;
  color: string;
  isActive: boolean;
  companyId: string;
  userId: string;
}

export interface ResolveObject {
  id: string;
  description: string;
  resolveStatusOptions: ResolveStatusOption[];
}

export interface ResolveStatusOption {
  id: string;
  description: string;
}

export interface ClassificationsTasks {
  name: string;
  id: string;
}

export interface ModifySpaceObjectModalState {
  isModalOpen: boolean;
  typeOfModification: 'rename' | 'moveTo' | 'makeCopy' | 'delete' | 'unassignedProject' | null;
  objectToModify: any;
  objectType: 'file' | 'workspace' | 'report' | 'project' | null;
}

export interface UserSpacePermissions {
  create: boolean;
  edit: boolean;
  view: boolean;
  share: boolean;
  download: boolean;
  manage: boolean;
  collaborate: boolean;
  build: boolean;
}

export interface ShareModalState {
  isModalOpen: boolean;
  objectToShare: any;
  objectType: 'folder' | 'report' | 'dashboard' | 'file' | 'project' | 'workspace' | undefined
}

// Used for the Okta Sign In Widget user object
export interface OktaSignInUserType {
  email?: string | undefined;
  email_verified?: boolean | undefined;
  name?: string | undefined;
  nickname?: string | undefined;
  picture?: string | undefined;
  sub?: string | undefined;
  updated_at?: string | undefined;
}

// Used fof rthe Okta Sign In Widget response object
export interface OktaSignInResponseType {
  user: OktaSignInUserType;
  accessToken: string;
 }

 /**
 * The ID representing the Intacct source type.
 */
export const INTACCT_SOURCE_TYPE_ID: number = 3;

 /**
 * The ID representing the QBO source type.
 */
 export const QBO_SOURCE_TYPE_ID: number = 5;

  /**
 * The ID representing the NETSUITE source type.
 */
  export const NETSUITE_SOURCE_TYPE_ID: number = 6;

/**
 * An array containing company names that are allowed to lock the Intacct in the primary source,
 * if task is reconciled and has the Intacct as primary source.
 * 📍 The `PrepDD for CC, Inc` is a temp company name, which could be removed once the Elevate company is created and the tests is complete.
 */
export const LOCKED_INTACCT_SOURCE_COMPANIES: string[] = ['Clearcover', 'PrepDD for CC, Inc'];

// Used for time periods
export interface TimePeriod {
  id: string;
  name: string;
  slug: string;
}
/**
 * An array containing company names that are allowed to lock the QBO in the primary source,
 * if task is reconciled and has the QBO as primary source.
 */
export const LOCKED_QBO_SOURCE_COMPANIES: string[] = ['TruU, Inc.'];

/**
 * An array containing company names that are allowed to lock the Netsuite in the primary source,
 * if task is reconciled and has the Netsuite as primary source.
 */
export const LOCKED_NETSUITE_SOURCE_COMPANIES: string[] = ['Clearwater Analytics'];

