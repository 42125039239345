import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import UpdateIcon from '@material-ui/icons/Create';

import * as cs from '../../../../../constants/theme';
import { TextField } from '@material-ui/core';
import { useGlobalStyles } from '../../../../common/Style';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		flex: {
			display: 'flex',
			width: '100%',
		},
		update: {
			marginLeft: '12px',
			color: cs.COLORS.primary,
			fontSize: '15px',
			cursor: 'pointer',
			visibility: 'hidden',
		},
	})
);

interface IEditableInput {
	initialValue: string;
	placeholder?: string;
	name?: string;
	type?: string;
	InputProps?: any;
	className?: string;
	onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	inputRef?: any;
	disableEdit?: boolean;
	dataCy?: string;
	hideIcon?: boolean;
	onEscClicked?: any;
	onEnterClicked?: any;
	onClick?: any;
	inputId?: string;
	autoFocus?: boolean;
	multiline?: boolean;
	isOnlyParentUpdate?: boolean;
}

export default function EditableInput(props: IEditableInput) {
	const { inputId, initialValue, type, placeholder, name, className, onBlur, disableEdit, isOnlyParentUpdate, dataCy, InputProps, hideIcon, autoFocus, multiline } = props;
	const classes = useStyles();
	const globalStyles = useGlobalStyles();
	const [value, setValue] = useState<string>(initialValue || '');
	const inputRef = useRef(null)

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue])

	useEffect(() => {
		if (inputRef && inputRef.current && autoFocus) (inputRef as any).current.focus();
	}, [autoFocus])


	const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
		event.persist();
		if (event.keyCode === 13) {
			if (props.onEnterClicked) props.onEnterClicked(event as any);
		}
		if (event.keyCode === 27) {
			if (isOnlyParentUpdate) return;
			setValue(initialValue);
			// if (onBlur) onBlur(event as any);
			if (props.onEscClicked) props.onEscClicked(event as any);
		}
	};

	const onBlurred = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		event.persist();
		if (onBlur && initialValue !== value) onBlur(event as any);
	}

	const onFocus = (event: any) => {
		event.persist();
		if (inputRef && inputRef.current) {
			(inputRef as any).current.select();
		}
	}

	return (
		<div className={clsx(classes.flex, globalStyles.showIconOnHover,)}>

			<TextField
				id={inputId}
				inputRef={inputRef}
				data-cy={dataCy}
				type={type}
				name={name}
				fullWidth
				className={className}
				value={value}
				placeholder={(value === '' && placeholder) ? placeholder : ''}
				InputProps={InputProps ? InputProps : { disableUnderline: true }}
				onChange={(event) => isOnlyParentUpdate ? props.onClick(event) : setValue(event.target.value)}
				onClick={props.onClick}
				onBlur={onBlurred}
				onFocus={onFocus}
				onKeyUp={handleKeyUp}
				multiline={multiline}
				disabled={disableEdit}
			/>

			{ !hideIcon &&
				<UpdateIcon className={classes.update} onClick={onFocus} />
			}
		</div>
	);
}
