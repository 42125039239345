import React, { useState, useEffect } from 'react';
import { useGlobalStyles } from '../common/Style';
import { DatePicker } from '../common/DatePicker';
import { MoneyDisplay } from '../common/MoneyDisplay';
import { useError } from '../../hooks/UseError';
import axios from 'axios';
import idx from 'idx';
import { getCSRF } from '../../helpers/helpers';

import { Button, Dialog, DialogActions, DialogTitle, Tooltip, Typography } from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import CircularProgress from "@material-ui/core/CircularProgress";
import Select, { components } from 'react-select';
import LockSwitchToggle from './LockSwitch';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CancelIcon from '@material-ui/icons/Cancel';
import { set } from 'lodash';
import { IntegrationEnable } from './IntegrationEnable';
import { NEW_INTEGRATION_OPTION, formatDateForDatePicker } from '../../constants/dimensions';

interface QBOValueSelectionProps {
  open: boolean;
  denyAction: any;
  confirmAction: any;
  task: any;
  project: any;
  sourceType: any;
}

interface Option {
  label: string;
  value: string;
}

interface IntegrationField {
  label: string;
  value?: string;
  isRequired?: boolean;
  isMulti?: boolean;
  options?: Option[];
  component?: any;
}

const datePickerOnChange = (date: MaterialUiPickersDate, dateParamName: string, { setState, state }: any) => {
  //If date is invalid, we don't want to update the state
  if (date && date.toString() !== "Invalid Date") {
    //QBO specific format
    //YYYY-MM-DD
    const formattedDate = formatDateForQBO(date);
    setState({ ...state, [`${dateParamName}`]: formattedDate });
  }
}

const formatDateForQBO = (date: MaterialUiPickersDate) => {
  if(date){
    const formattedDate = [
      date.getFullYear(),
      ('0' + (date.getMonth() + 1)).slice(-2),
      ('0' + date.getDate()).slice(-2)
    ].join('-');

    return formattedDate;
  }
}

function PeriodAdjustmentField({ setState, state }: any) {

  const [startDateLocked, setStartDateLocked] = useState(state.startDateLocked || false);
  const [endDateLocked, setEndDateLocked] = useState(state.endDateLocked || false);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <DatePicker
          value={formatDateForDatePicker(state.startDate)}
          onChange={(d: any) => datePickerOnChange(d, 'startDate', { setState, state })}
        />
        <LockSwitchToggle name="startDateLockSwitch" locked={startDateLocked} handleChange={() => { setState({ ...state, startDateLocked: !startDateLocked }); setStartDateLocked(!startDateLocked) }} />
      </div>
      <Typography component='span'>
        to
      </Typography>
      <div>

        <DatePicker
          value={formatDateForDatePicker(state.endDate)}
          onChange={(d: any) => datePickerOnChange(d, 'endDate', { setState, state })}
        />
        <LockSwitchToggle name="endDateLockSwitch" locked={endDateLocked} handleChange={() => { setState({ ...state, endDateLocked: !endDateLocked }); setEndDateLocked(!endDateLocked) }}/>
      </div>
    </div>
  )
}

const LEVELS: IntegrationField[] = [
  { label: 'QBO Integration', value: 'qboIntegration', isRequired: true },
  { label: 'Account', value: 'accounts', isRequired: true, isMulti: true },
  { label: 'Name', value: 'names', isMulti: true },
  { label: 'Class', value: 'classes', isMulti: true },
  { label: 'Location', value: 'locations', isMulti: true },
  { label: 'Transaction type', value: 'types' },
  { label: 'Accounting method', value: 'accountingMethod', options: [{ label: 'Cash', value: 'Cash' }, { label: 'Accrual', value: 'Accrual' }] },
  { label: 'Period', component: PeriodAdjustmentField }
];

export function QBOValueSelection(
  { sourceType, confirmAction, denyAction, open, task, project }: QBOValueSelectionProps
) {
  const showError = useError();
  const classes = useGlobalStyles();

  const [balanceQueryLoading, setBalanceQueryLoading] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  const source = task[`${sourceType}Source`];
  const info = idx(source, (s: any) => s.integrationInfo);
  
  const projectReconEnd = idx(project, (p: any) => p.reconEndDate && new Date(p.reconEndDate));
  // using this to cancel earlier API call to /balance endpoint
  const [tokenSource, setTokenSource] = useState<any>({});
  const [integrationOptions, setIntegrationOptions] = useState<Option[]>([]);

  /** defaults */
  const [state, setState] = useState<any>({
    qboIntegration: idx(info, (i: any) => i.qboIntegration),
    accounts: idx(info, (i: any) => i.qboAccounts),
    names: idx(info, (i: any) => i.qboNames),
    classes: idx(info, (i: any) => i.qboClasses),
    locations: idx(info, (i: any) => i.qboLocations),
    types: idx(info, (i: any) => i.qboTypes),
    accountingMethod: idx(info, (i: any) => i.qboAccountingMethod) || { label: 'Accrual', value: 'Accrual' },
    startDate: idx(info, (i: any) => formatDateForDatePicker(i.qboStartDate)) || formatDateForDatePicker(new Date(2000, 0, 1)),
    endDate: idx(info, (i: any) => formatDateForDatePicker(i.qboEndDate)) || projectReconEnd || formatDateForDatePicker(new Date()),
    startDateLocked: idx(info, (i: any) => i.startDateLocked) || false,
    endDateLocked: idx(info, (i: any) => i.endDateLocked) || false,
    balance: idx(source, (source: any) => source.value),
    showQBOEnable: false
  });

  const [OPTIONS, SETOPTIONS] = useState<any>({});

  useEffect(() => {
    if (open) { 
      axios.get('/connector/qbo/integrations')
        .then(response => {
          const integrationList = response.data.integrations;
          if (Array.isArray(integrationList)) {
            const updatedOptions = integrationList.map((i: any) => ({ label: i.name, value: String(i.id) }));
            setIntegrationOptions(updatedOptions);

            // If state.qboIntegration is not set, set it to the first integration in the list
            if (!state.qboIntegration && updatedOptions.length > 0) {
              setState({ ...state, qboIntegration: updatedOptions[0] });
            }
          } else {
            console.error("Expected an array but received:", integrationList);
          }
        })
        .catch(error => {
            console.error("Error fetching integrations:", error);
        });
    }
  }, [open]);

  /** When menu opens, fetch menu items */
  useEffect(() => {
    if (open) { 
      axios.get('/connector/qbo/menuitems', {
        headers: { 'X-CSRF-TOKEN': getCSRF() },
        params: {
          task_id: task.id,
          integration_id: state.qboIntegration?.value
        }
      })
        .then((a: any) => {
          setShowSpinner(false);
          SETOPTIONS(a.data);
        }).catch((e: any) => {
          showError('There is a problem with your company\'s QuickBooks connection, please contact your company\'s administrator or PrepDD support for assistance.');
          setShowSpinner(false)
        });
    }
  }, [open, state.qboIntegration]);

  /** Under what conditions do we query the balance? */
  useEffect(() => {
    if (!open) { return; }

    if (tokenSource && tokenSource.cancel) {
      tokenSource.cancel('Operation canceled due to new request.');
    }

    const source = axios.CancelToken.source();
    setTokenSource(source);

    // What are the required items to check a balance?
    // in this case, accounts are necessary, if none select, assumes all
    setBalanceQueryLoading(true);
    
    axios.get('/connector/qbo/balance', {
      cancelToken: source.token,
      params: {
        accounts: idx(state, s => s.accounts.map((n: any) => n.value)),
        names: idx(state, s => s.names.map((n: any) => n.value)),
        types: idx(state, s => s.types.value),
        classes: idx(state, s => s.classes.map((n: any) => n.value)),
        locations: idx(state, s => s.locations.map((n: any) => n.value)),
        accountingMethod: state.accountingMethod.value,
        startDate: formatDateForDatePicker(state.startDate),
        endDate: formatDateForDatePicker(state.endDate),
        startDateLocked: state.startDateLocked,
        endDateLocked: state.endDateLocked,
        task_id: task.id,
        integration_id: state.qboIntegration?.value
      }
    }).then((a: any) => {
      setBalanceQueryLoading(false);
      setState({ ...state, balance: a.data });
    }).catch((e: any) => {
      if(!axios.isCancel(e)) {
        setBalanceQueryLoading(false);
      }
    });
  }, [state.classes, state.locations, state.accounts, 
  state.names, state.types, state.accountingMethod, 
  state.startDate, state.endDate, state.qboIntegration])

  function handleAccept() { confirmAction({ state }); }

  // Check if at least one account is selected while editing the dimensions for reconciliation.
  // Otherwise disable the "Accept" button and block form submission.
  const isFormValid = !!state?.accounts?.length;

  const launchQBOAuthenticate = () => {
    sessionStorage.setItem('connectList', task.list.id)
    axios.get('/connector/qbo/connect', {
      params: {
        list_id: task.list.id,
        add_new_integration: true
      }
    })
      .then((d: any) => window.location = d.data.url)

    setState((prevState: any) => ({ ...prevState, showQBOEnable: false }));
  }

  const closeQBOModals = () => {
    setState((prevState: any) => ({ ...prevState, showQBOEnable: false }));
  }

  return (
    <>
      <IntegrationEnable
        open={state.showQBOEnable}
        denyAction={closeQBOModals}
        integrationType={'5'}
        confirmAction={launchQBOAuthenticate}
      />
      
      <div>
        <Dialog
          open={open}
          onClose={denyAction}
        >
          <div style={{ minWidth: 750, paddingRight: 20, paddingLeft: 20, display: 'flex', flexDirection: 'column', paddingTop: '15px', paddingBottom: '15px' }}>
            <DialogTitle>
              <Typography variant='h2'>
                {`Edit Reconciliation Source QuickBooks Online`}
              </Typography>
            </DialogTitle>

            {
              LEVELS.map((level: any, i: number) => {
                const options = 
                  (level.value === 'qboIntegration' && integrationOptions.length > 0) ? integrationOptions :
                  (OPTIONS[level.value]?.map((o: any) => o.id ? { value: o.id, label: o.name } : { value: o, label: o }) || level.options);

                return (
                  <LevelDropdown
                    i={i}
                    key={i}
                    level={level}
                    options={options}
                    set={(x: any) => {
                      setState({ ...state, [level.value]: x })
                    }}
                    setState={setState}
                    showSpinner={showSpinner}
                    state={state}
                    v={state[level.value] || level.label === 'Period'}
                    dimension={OPTIONS[level.value]}
                  />
                )
              })
            }

            <div style={{ display: 'flex', marginLeft: 120, marginTop: 30 }}>
              <div style={{ width: '1.25rem', display: 'flex', alignItems: 'flex-end', marginRight: 10 }}>
                {balanceQueryLoading && <CircularProgress size={'1.25rem'} />}
              </div>
              <div style={{ marginRight: 60 }}>
                <Typography style={{ marginTop: 10 }}>
                  Project
                </Typography>
                <Typography style={{ marginTop: 10 }}>
                  Section
                </Typography>
                <Typography style={{ marginTop: 10 }}>
                  Entity
                </Typography>
                <Typography style={{ marginTop: 10 }}>
                  Task
                </Typography>
                <Typography style={{ marginTop: 10 }}>
                  Report Period
                </Typography>
                <Typography style={{ marginTop: 10 }}>
                  Value
                </Typography>
              </div>
              <div>
                <Tooltip title={project.name} classes={{ tooltip: classes.tooltipFont }}>
                  <Typography style={{ width: 268, marginTop: 10 }} className={classes.ellipsis}>
                    {idx(project, p => p.name)}
                  </Typography>
                </Tooltip>
                <Tooltip title={idx(task, t => t.section.name) || ''} classes={{ tooltip: classes.tooltipFont }}>
                  <Typography style={{ width: 268, marginTop: 10 }} className={classes.ellipsis}>
                    {idx(task, t => t.section?.name) || 'No Section'}
                  </Typography>
                </Tooltip>
                <Typography style={{ width: 268, marginTop: 10 }} className={classes.ellipsis}>
                  {idx(task, t => t.tags?.length)
                    ? task.tags.map((tag: any) => tag.tag)
                    : <Typography> None </Typography>
                  }
                </Typography>
                <Tooltip title={task.name} classes={{ tooltip: classes.tooltipFont }}>
                  <Typography style={{ width: 268, marginTop: 10 }} className={classes.ellipsis}>
                    {idx(task, t => t.name)}
                  </Typography>
                </Tooltip>
                <Typography style={{ width: 268, marginTop: 10 }} className={classes.ellipsis}>
                  {
                    idx(project, p => p.reconEndDate)
                      ? new Date(project.reconEndDate).toLocaleDateString(undefined,
                        { year: 'numeric', month: 'short', day: 'numeric' }
                      )
                      : new Date().toLocaleDateString(undefined,
                        { year: 'numeric', month: 'short', day: 'numeric' }
                      )
                  }
                </Typography>
                <Typography style={{ width: 268, marginTop: 10 }} className={classes.ellipsis}>
                  {state.balance ?
                    <>
                      <MoneyDisplay
                        style={{ fontWeight: 500 }}
                        value={state.balance}
                        displaySymbol
                      />
                    </> : '$ 0.00'
                  }
                </Typography>
              </div>
            </div>

            <DialogActions>
              <Button onClick={denyAction} variant='outlined'>
                Cancel
              </Button>
              <Button variant='contained'
                autoFocus
                onClick={handleAccept}
                disabled={!isFormValid}
              >
                Accept
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </>
  );
}

const DropdownIndicator = (
  props: any
) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDownIcon />
    </components.DropdownIndicator>
  );
};

// Disable the select box input to not allow value modification
// when the dimension is not available from the user `QBO` dimensions
// after the Api request.
const disableSelectStyles: any = {
  control: () => ({
    alignItems: 'center',
    border: '1px solid hsl(0,0%,80%)',
    borderRadius: '4px',
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: 38,
    position: 'relative',
    transition: 'all 100ms',
    boxSizing: 'border-box',
    backgroundColor: 'hsl(0deg 0% 80% / 44%)'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (provided: any) => ({
    ...provided,
    border: '1px solid hsl(0,0%,80%)',
    boxShadow: 'none',
    marginTop: -1
  }),
};

const selectStyles: any = {
  control: () => ({
    alignItems: 'center',
    border: '1px solid hsl(0,0%,80%)',
    borderRadius: '4px',
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: 38,
    position: 'relative',
    transition: 'all 100ms',
    boxSizing: 'border-box'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (provided: any) => ({
    ...provided,
    border: '1px solid hsl(0,0%,80%)',
    boxShadow: 'none',
    marginTop: -1

  }),
  multiValue: () => ({
    background: '#FFF',
    border: '1px solid hsl(0,0%,80%)',
    borderRadius: '2px',
    display: 'flex',
    margin: '2px',
    padding: '2px',
    minWidth: 0,
    boxSizing: 'border-box'
  }),
  multiValueRemove: (provided: any, state: any) => ({
    //    ...provided,
    background: '#FFF',
    color: 'lightgrey',
    paddingLeft: 4,
    paddingRight: 4,
    alignItems: 'center',
    display: 'flex'
  })
};

function LevelDropdown(props: any) {
  const classes = useGlobalStyles();
  const { state, setState, dimension, level } = props;
  const { label } = level;

  const emptyProps = !props.v || (Array.isArray(props.v) && !props.v.length);

  const levelDropdownOptions = (level.value === 'qboIntegration' && props.options) ?
    [...props.options, NEW_INTEGRATION_OPTION] :
    props.options;
  
  function handleOnChange(e: any) {
    if(e.value === 'ADD_NEW_INTEGRATION'){
      setState((prevState: any) => ({ ...prevState, showQBOEnable: true }));
    } else {
      props.set(e);
    }
  }
  // Check if at least one account is selected while editing the dimensions for reconciliation.
  // Otherwise, show a warning message alert.
  const isFormValid = label === 'Account' && (Array.isArray(props.v) && !props.v.length);

  return (
    <div className={classes.flexCenter} style={{ marginBottom: 10 }}>
      <div className={classes.flexCenter} style={{ justifyContent: 'flex-start' }}>
        <div style={{ width: 20, marginRight: 20 }}>
        {(props.showSpinner && !emptyProps)
            ? <CircularProgress size="1.5rem" />
              : (dimension && !dimension.length)
                ? <CancelIcon style={{ color: 'green', opacity: 0.1 }}/>
                  : <CircleCheckedFilled style={{ color: 'green', opacity: props.v ? 1 : 0.1 }} />}
        </div>
        <Typography style={{ width: 140 }}>{label}</Typography>
        <div style={{ width: 400 }} className={classes.dropdownContainer}>
          {level.component ?
            level.component({ state, setState }) :
            <Select
              components={{ DropdownIndicator }}
              isClearable={false}
              isDisabled={dimension && !dimension.length}
              isMulti={level.isMulti}
              onChange={(e: any) => handleOnChange(e)}
              options={levelDropdownOptions}
              styles={dimension && !dimension.length ? disableSelectStyles : selectStyles}
              value={props.v}
              isOptionDisabled={(option: any) => option.isDisabled}
              id={label?.split(' ').join('_').toLowerCase().concat('_select')}
            />
          }
          { isFormValid ? <span className='text-xs text-red-500'>Please select an account to proceed</span> : null }
        </div>
      </div>
    </div>
  )
}
