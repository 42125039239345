import { gql } from '../graphqlHelpers';

export const getInternalFolders = gql`
  query getInternalFolders($spaceId: ID){
    getInternalFolders(spaceId: $spaceId){
      id
      spaceId
      name
      description
      isDashboard
      url
      companyId
    }
  }`
;
