import { gql} from '../graphqlHelpers';

type ID = number | string;

export interface CopyFileVariables {
  names: string[];
  fileVersionIds: ID[];
  workspaceId: ID;
  destinationWorkspaceId: ID;
}

export interface Error {
  path: string;
  message: string;
}

export interface CopyFilesData {
  success: boolean;
  errors: Error[];
}

export const copyFiles = gql `
  mutation copyFiles($names: [String!]!, $fileVersionIds: [ID!]!, $workspaceId: ID!, $destinationWorkspaceId: ID!) {
    copyFiles(names: $names, fileVersionIds: $fileVersionIds, workspaceId: $workspaceId, destinationWorkspaceId: $destinationWorkspaceId) {
      success
      errors {
        path
        message
      }
    }
  }`;