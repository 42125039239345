import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { IProjectOptionsSelected, ISteps, ITableHeader } from '../../../common/interfaces';

import ProjectTaskTable from '../../../common/TasksDetailsReview/ProjectTaskTable';
import TasksHeader from '../../../common/TasksDetailsReview/TasksHeader';
import ImportTemplatePane from './components/ImportTemplatePane';

import CircularLoader from '../../../../../../common/CircularLoader';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		flex: {
			display: 'flex',
		},
		container: {
			width: '100%',
			height: '100%',
		},
		column: {
			flexDirection: 'column',
		},
		row: {
			flexDirection: 'row',
		},
		scrollBar: {
			'&::-webkit-scrollbar': {
				width: '0.6em',
				height: '0.6em',
			}
		},
		leftDiv: {
			position: 'sticky',
			padding: '0px 25px',
			left: 0,
			top: 0,
			background: 'white',
			zIndex: 20,
			height: '100%'
		},
		spinner: {
			position: 'absolute',
			zIndex: 1301,
			background: 'rgba(255, 255, 255, .8)',
			display: 'flex',
			top: '0',
			left: '0',
			width: '100%',
			height: '100%',
			alignContent: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
		}
	})
);

interface IProjectTasksStep {
	isAdmin: boolean;
	externalCompanyId: string
	selectedTemplate: any;
	setSelectedTemplate: React.Dispatch<React.SetStateAction<any>>;
	allTableHeaderOptions: ITableHeader[];
	displayedHeaders: ITableHeader[];
	setDisplayedHeaders: React.Dispatch<React.SetStateAction<ITableHeader[]>>;
	selectedTasksIds: string[];
	setSelectedTasksIds: React.Dispatch<React.SetStateAction<string[]>>;
	step: ISteps;
	projectOptionsSelected: IProjectOptionsSelected;
	skinnyLists: any[];
	setSkinnyLists: React.Dispatch<React.SetStateAction<any[]>>;
	dependencies: any;
	setAddTasks: React.Dispatch<React.SetStateAction<any[]>>;
	setDeleteTasks: React.Dispatch<React.SetStateAction<any[]>>;
	sortByName: string | null;
	sortBySection: string | null;
	nameFilter: string;
	sectionFilter: string;
	setNameFilterPopper: any;
	additionalInternalOwners: any[];
	additionalExternalOwners: any[];
	setAdditionalInternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
	setAdditionalExternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
	isOnlyImportTasks?: boolean;
}

export default function ProjectTasksStep(props: IProjectTasksStep) {

	const classes = useStyles();

	const { isAdmin, externalCompanyId, selectedTemplate, setSelectedTemplate, allTableHeaderOptions, displayedHeaders,
		setDisplayedHeaders, selectedTasksIds, setSelectedTasksIds, step, projectOptionsSelected, skinnyLists, setSkinnyLists,
		dependencies, setAddTasks, setDeleteTasks, sortByName, sortBySection, nameFilter, sectionFilter, setNameFilterPopper,
		additionalInternalOwners, additionalExternalOwners, setAdditionalInternalOwners, setAdditionalExternalOwners, isOnlyImportTasks } = props;
	const [onAddButtonHover, setOnAddButtonHover] = useState(false);

	// first fetch all the list, their tasks and dependencies for each task
	// const dependencies = [] as any;
	const [loading, setLoading] = useState(false);

	return (
		<div className={clsx(classes.flex, classes.container, classes.column)} style={{ position: 'relative' }}>
			<TasksHeader step={step} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate}
				selectedTasksIds={selectedTasksIds} setSelectedTasksIds={setSelectedTasksIds} setDeleteTasks={setDeleteTasks} isOnlyImportTasks={isOnlyImportTasks} />
			<div className={clsx(classes.flex, classes.container, classes.row, classes.scrollBar)} style={{ overflow: 'auto scroll' }}>
				<div className={classes.leftDiv} />
				<ProjectTaskTable isAdmin={isAdmin} externalCompanyId={externalCompanyId} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate}
					selectedTasksIds={selectedTasksIds} setSelectedTasksIds={setSelectedTasksIds} allTableHeaderOptions={allTableHeaderOptions} step={step}
					displayedHeaders={displayedHeaders} setDisplayedHeaders={setDisplayedHeaders} onAddButtonHover={onAddButtonHover} setOnAddButtonHover={setOnAddButtonHover}
					projectOptionsSelected={projectOptionsSelected} setAddTasks={setAddTasks} dependencies={dependencies} skinnyLists={skinnyLists} setSkinnyLists={setSkinnyLists}
					sortByName={sortByName} sortBySection={sortBySection} nameFilter={nameFilter} sectionFilter={sectionFilter} setNameFilterPopper={setNameFilterPopper}
					additionalInternalOwners={additionalInternalOwners} setAdditionalInternalOwners={setAdditionalInternalOwners}
					additionalExternalOwners={additionalExternalOwners} setAdditionalExternalOwners={setAdditionalExternalOwners} isOnlyImportTasks={isOnlyImportTasks} />
				<ImportTemplatePane selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} setAddTasks={setAddTasks} displayedHeaders={displayedHeaders}
					allTableHeaderOptions={allTableHeaderOptions} setDisplayedHeaders={setDisplayedHeaders} setLoading={setLoading} projectOptionsSelected={projectOptionsSelected}
					selectedTasksIds={selectedTasksIds} setSelectedTasksIds={setSelectedTasksIds}
					additionalInternalOwners={additionalInternalOwners} setAdditionalInternalOwners={setAdditionalInternalOwners}
					additionalExternalOwners={additionalExternalOwners} setAdditionalExternalOwners={setAdditionalExternalOwners} />
			</div>
			{loading &&
				<div className={classes.spinner}>
					<CircularLoader />
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '36px' }}>
						<Typography>Please give us a second while we process your import file</Typography>
					</div>
				</div>
			}
		</div>
	)
}
