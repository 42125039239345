import {createLazyQueryHook, createQueryHook, gql} from '../graphqlHelpers';
import {UploadTasks} from './__generated__/UploadTasks';

const query = gql`
  query UploadTasks($listId: ID!)
  {
		list(id: $listId) 
     {
      id
			tasks {
				id
				name
        currency
				status
				listNumber
        list {
          # easier to re-use FE logic by querying here
          id
          currentUserIsOwner
        }
				listId
				priority
				companyPosition
        primaryLabel
        secondaryLabel
			}
    }
  }`;

export const useLazyUploadPageTasks = createLazyQueryHook<UploadTasks, {}>(query);
