import { gql} from '../graphqlHelpers';

type ID = number | string;

export interface CopyReportsVariables {
  names: String[];
  dataReportIds: ID[];
  workspaceId: ID;
  destinationWorkspaceId: ID;
}

export interface Error {
  path: string;
  message: string;
}

export interface CopyReportsData {
  success: boolean;
  errors: Error[];
}

export const copyReports = gql `
  mutation copyReports($names: [String!]!, $dataReportIds: [ID!]!, $workspaceId: ID!, $destinationWorkspaceId: ID!) {
    copyReports(names: $names, dataReportIds: $dataReportIds, workspaceId: $workspaceId, destinationWorkspaceId: $destinationWorkspaceId) {
      success
      errors {
        path
        message
      }
    }
  }`;