import axios from 'axios';
import idx from 'idx';
import {getCSRF} from './helpers';

const ROUTES = {
  search: '/api/search/fast/',
  exportFiles: '/api/export_task_files',
  zipFiles: '/api/download_all_files',
  intacct: {
    connect: '/connector/intacct/connect',
    validate: '/connector/intacct/valid',
  },
  sl: {
    connect: '/connector/sl/connect',
    validate: '/connector/sl/valid',
  },
  keepAlive: '/api/keep_session_alive',
};

export const intacct: any = {
  connect: async (credentials: any) => {
    const response = await axios.post(ROUTES.intacct.connect, credentials, {
      headers: {
        'X-CSRF-Token': getCSRF(),
      },
    });
    return idx(response, (r: any) => r.data.success);
  },
  validate: async () => {
    const response = await axios.get(ROUTES.intacct.validate, {
      headers: {
        'X-CSRF-Token': getCSRF(),
      },
    });
    return idx(response, (r: any) => r.data.status);
  },
};

export const sl: any = {
  connect: async (credentials: any) => {
    const response = await axios.post(ROUTES.sl.connect, credentials, {
      headers: {
        'X-CSRF-Token': getCSRF(),
      },
    });
    return idx(response, (r: any) => r.data.success);
  },
  validate: async () => {
    const response = await axios.get(ROUTES.sl.validate, {
      headers: {
        'X-CSRF-Token': getCSRF(),
      },
    });
    return idx(response, (r: any) => r.data.status);
  },
};

export async function keepAliveSession() {
  const response = await axios.get(ROUTES.keepAlive, {
    headers: {
      'X-CSRF-Token': getCSRF(),
    },
  });
  return idx(response, (r: any) => r.data.success);
}

export async function exportProjectFilesAndSummary(listIds: string[]) {
  const response = await axios.post(
    ROUTES.exportFiles,
    {list_ids: listIds},
    {
      headers: {
        'X-CSRF-Token': getCSRF(),
      },
    }
  );
  return idx(response, (r: any) => r.data.url);
}

export async function fileZip(fileVersionIds: string[]) {
  const response = await axios.post(
    ROUTES.zipFiles,
    {file_ids: fileVersionIds},
    {
      headers: {
        'X-CSRF-Token': getCSRF(),
      },
    }
  );
  return idx(response, (r: any) => r.data.url);
}

async function search(term: string) {
  var result = await axios(ROUTES.search + term);
  var tasks = formatTasks(result.data.tasks);
  var lists = formatLists(result.data.lists);
  var files = formatFiles(result.data.files);
  return [...tasks, ...lists, ...files];
}

function formatFiles(files: any[]) {
  return files.map((file: any) => ({
    id: file.id,
    name: file.file_name,
    type: 'file',
    updatedAt: file.updated_at,
    listId: file.list_id,
    taskId: file.task_id,
    createdAt: file.created_at,
  }));
}

function formatTasks(tasks: any[]) {
  return tasks.map((task: any) => ({
    id: task.id,
    name: task.name,
    listId: task.list_id,
    // listName: task.list.name,
    type: 'task',
    list: task.list,
    status: task.status === 'Complete' ? 'Completed' : task.status,
    updatedAt: task.updated_at,
    notifications: [],
    userOwners: [],
    userReviewers: [],
    teamOwners: [],
    teamReviewers: [],
  }));
}

function formatLists(lists: any[]) {
  return lists.map((e: any) => {
    return {
      id: e.id,
      name: e.name,
      listId: e.id,
      type: 'list',
      updatedAt: e.updated_at,
      notifications: [],
      userOwners: [],
      teamOwners: [],
      owners: [],
    };
  });
}

export const api = {
  search,
  exportProjectFilesAndSummary,
};
