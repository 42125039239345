import { useLazyQuery, gql } from '@apollo/client';

const messageLabelsQuery = gql`
  query MessageLabels(
    $companyId: ID!, 
  ) {
    messageLabels(
      companyId: $companyId
    ) {
      id
      name
      color
    }
  }
`

export const useLazyMessageLabels = (variables: any) => {
  return useLazyQuery(messageLabelsQuery, {variables});
}