import React, { Component } from "react";
import { Typography } from '@material-ui/core';

import CreatableSelect from "react-select/creatable";
import { components } from 'react-select';

const customSelectStyle = {
  control: () => ({
    alignItems: "center",
    cursor: "default",
    display: "flex",
    justifyContent: "space-between",
    minHeight: 38,
    position: "relative",
    transition: "all 100ms",
    boxSizing: "border-box",
  }),
  indicatorsContainer: () => ({ display: "none" }),
  valueContainer: () => ({
    padding: 0,
    display: "flex",
    maxWidth: 250,
    flexWrap: "wrap",
  }),
  placeholder: (base: any, state: any) => ({
    fontFamily: "Montserrat",
    fontSize: 12,
    position: "absolute",
    top: "50%",
    color: "#AFAFAF",
    transform: "translateY(-50%)",
  }),
  multiValue: () => ({
    background: "rgb(255, 255, 255)",
    border: "1px solid rgb(204, 204, 204)",
    borderRadius: "2px",
    display: "flex",
    margin: "2px",
    padding: "2px",
    minWidth: "0px",
    boxSizing: "border-box",
  }),
  multiValueRemove: () => ({
    display: "flex",
    paddingLeft: "4px",
    paddingRight: "4px",
    boxSizing: "border-box",
    alignItems: "center", 
    color: "lightgrey",
    cursor: 'pointer'
  }),
  
};

const Input = (props: any) => {
  if (props.isHidden) {
    return <components.Input {...props} />;
  }
  return (
    <div data-cy='add-entity-filed'>
      <Typography
        style={{
          visibility: props.selectProps.menuIsOpen ? 'hidden' : 'initial',
          height: 0,
//          fontSize: 11,
            marginTop: props.selectProps.menuIsOpen ? 0 : 6,
          marginLeft: 5, 
          color: 'rgb(175, 175, 175)'
        }}
      >  
        Add entity...
      </Typography>
    <components.Input style={{paddingLeft: 3}} {...props} />
    </div>
  );
};

export class CreatableMulti extends Component<any> {
  handleChange = (newValue: any, actionMeta: any) => {
    if (actionMeta.action === "remove-value") {
      this.props.handleRemove(actionMeta.removedValue.label);
    }

    if (
      actionMeta.action === "select-option" ||
      actionMeta.action === "create-option"
    ) {
      const newVal = newValue[newValue.length - 1];
      this.props.handleAdd(newVal.label);
    }
  };

  render() {
    return (
      <CreatableSelect
        components={{ Input }}
        blurInputOnSelect
        isMulti
        onChange={this.handleChange}
        options={this.props.options}
        styles={{ ...customSelectStyle }}
        value={this.props.value}
        placeholder={""}
        classNamePrefix="react-select"
      />
    );
  }
}
