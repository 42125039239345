import { createQueryHook, gql } from '../graphqlHelpers';

export const useReconFilesForTask = createQueryHook(gql`
  query ReconFilesForTasks(
    $taskId: ID!
  ) {
    task(id: $taskId) {
      id
      files {
        id
        fileName
        fileLocation
        alterable
        taskId
      }
    }
  }
`)
