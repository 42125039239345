import React, { useState, useEffect, useRef } from 'react';
import idx from 'idx';
import { Typography, Button, Dialog, DialogActions, DialogTitle, Checkbox} from '@material-ui/core';
import { useGlobalStyles } from '../common/Style';
import { MoneyDisplay } from '../common/MoneyDisplay';
import { useError } from '../../hooks/UseError';
import TagLabel from '../common/TagLabel'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select, { components } from 'react-select';

import LockSwitchToggle from './LockSwitch';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { netsuiteMenuItemsQuery } from '../../graphql/queries/NetsuiteMenuItems';
import { netsuiteValueQuery } from '../../graphql/queries/NetsuiteValue';
import { useApolloClient } from '@apollo/client';
import VirtualizedMenuList from './VirtualizedMenuList';
import { displayCurrency } from '../../constants/currency';

interface NetsuiteValueSelectionProps {
  open: boolean;
  denyAction: any;
  confirmAction: any;
  task: any;
  project: any;
  sourceType: any;
}

interface Option {
  label: string;
  value: string;
}

interface IntegrationField {
  label: string;
  value?: string;
  isRequired?: boolean;
  isMulti?: boolean;
  options?: Option[];
  component?: any;
}


//TODO: REPLACE THIS WITH NORMAL DROPDOWN SINGLE SELECTS
function PeriodAdjustmentField(props: any) {
  const [startDateLocked, setStartDateLocked] = useState(props.state.startDateLocked || false);
  const [endDateLocked, setEndDateLocked] = useState(props.state.endDateLocked || false);

  const startDateOptions = props.allOptions?.nsStartDates ? props.allOptions?.nsStartDates.map((option: any) => { return { label: option, value: option } }) : [];
  const endDateOptions = props.allOptions?.nsEndDates ? props.allOptions?.nsEndDates.map((option: any) => { return { label: option, value: option } }) : [];

  const handleStartDateOnChange = (newStartDate: any) => {
    props.setState({ ...props.state, nsStartDate: newStartDate })
  }

  const handleEndDateOnChange = (newEndDate: any) => {
    props.setState({ ...props.state, nsEndDate: newEndDate })
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ width: '40%' }}>
        <Select
          isDisabled={props.showSpinner || props.balanceQueryLoading}
          components={{ DropdownIndicator }}
          isClearable={false}
          isMulti={props.level.isMulti}
          onChange={handleStartDateOnChange}
          options={startDateOptions}
          styles={selectStyles}
          value={props.state.nsStartDate}
        />
        <LockSwitchToggle name="startDateLockSwitch" locked={startDateLocked} handleChange={() => { props.setState({ ...props.state, startDateLocked: !startDateLocked }); setStartDateLocked(!startDateLocked) }} />
      </div>
      <Typography component='span'>
        to
      </Typography>
      <div style={{ width: '40%' }}>
        <Select
          isDisabled={props.showSpinner || props.balanceQueryLoading}
          components={{ DropdownIndicator }}
          isClearable={false}
          isMulti={props.level.isMulti}
          onChange={handleEndDateOnChange}
          options={endDateOptions}
          styles={selectStyles}
          value={props.state.nsEndDate}
        />
        <LockSwitchToggle name="endDateLockSwitch" locked={endDateLocked} handleChange={() => { props.setState({ ...props.state, endDateLocked: !endDateLocked }); setEndDateLocked(!endDateLocked) }} />
      </div>
    </div>
  )
}

//TODO: NEED TO VIRTUALIZE THE ITEM DROPDOWN, TOO MANY ITEMS
const LEVELS: IntegrationField[] = [
  // { label: 'Location', value: 'nsLocations', isRequired: false, isMulti: true},
  { label: 'Subsidiary', value: 'nsSubsidiaries', isRequired: false, isMulti: true },
  { label: 'Department', value: 'nsDepartments', isRequired: false, isMulti: true },
  { label: 'Class', value: 'nsClasses', isRequired: false, isMulti: true },
  { label: 'Account', value: 'nsAccounts', isRequired: false, isMulti: true },
  { label: 'Item', value: 'nsItems', isRequired: false, isMulti: true },
  { label: 'Entity', value: 'nsEntities', isRequired: false, isMulti: true },
  { label: 'Book', value: 'nsBooks', isRequired: false, isMulti: true },
  { label: 'Period', component: PeriodAdjustmentField, isMulti: false }
]

export function NetsuiteValueSelection({
  sourceType, confirmAction, denyAction, open, task, project
}: NetsuiteValueSelectionProps
) {
  
  const [balanceQueryLoading, setBalanceQueryLoading] = useState<boolean>(false);
  const classes = useGlobalStyles();
  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  const source = task[`${sourceType}Source`];
  const info = idx(source, (s: any) => s.integrationInfo);
  const [currency, setCurrency] = useState<string>(idx(source, (s: any) => s.integrationInfo.currency));
  const [balance, setBalance] = useState(idx(source, (source: any) => source.value));

  const apolloClient = useApolloClient();
  const netsuiteMenuItemsAbortControllerRef = useRef<any>(null);
  const netsuiteValueAbortControllerRef = useRef<any>(null);

  const handleInvert = (event: { target: { checked: Boolean }; }) => {
    setState({ ...state, ...{ invert: !state.invert } });
    setBalance(-1 * balance);
  };
  

  /** defaults */
  const [state, setState] = useState<any>({
    nsSubsidiaries: idx(info, (i: any) => i.nsSubsidiaries),
    nsDepartments: idx(info, (i: any) => i.nsDepartments),
    nsClasses: idx(info, (i: any) => i.nsClasses),
    nsAccounts: idx(info, (i: any) => i.nsAccounts),
    nsItems: idx(info, (i: any) => i.nsItems),
    nsEntities: idx(info, (i: any) => i.nsEntities),
    nsBooks: idx(info, (i: any) => i.nsBooks),
    nsStartDate: info?.nsStartDate ? { label: info.nsStartDate, value: info.nsStartDate } : null,
    nsEndDate: info?.nsEndDate ? { label: info.nsEndDate, value: info.nsEndDate } : null,
    startDateLocked: idx(info, (i: any) => i.startDateLocked) || false,
    endDateLocked: idx(info, (i: any) => i.endDateLocked) || false,
    invert: idx(info, (i: any) => i.invert),
    currency: currency,
  });

  const [OPTIONS, SETOPTIONS] = useState<any>({});

  useEffect(() => {
    return () => {
      if (netsuiteMenuItemsAbortControllerRef.current) netsuiteMenuItemsAbortControllerRef.current.abort()
      if (netsuiteValueAbortControllerRef.current) netsuiteValueAbortControllerRef.current.abort()
    }
  }, [])

  /** Get the menu options at the first load. */
  useEffect(() => {
    if (open) {

      const abortController = new AbortController();
      netsuiteMenuItemsAbortControllerRef.current = abortController;

      const queryParameters: any = {
        query: netsuiteMenuItemsQuery,
        context: {
          fetchOptions: {
            signal: abortController.signal
          }
        },
      }

      apolloClient.query(queryParameters).then((response: any) => {
        setShowSpinner(false);
        const menuItemData = response?.data?.netsuiteMenuItems;
        const mappedOptions = {
          // nsLocations: 
          nsSubsidiaries: menuItemData?.subsidiaryNames.slice(),
          nsDepartments: menuItemData?.departmentNames.slice(),
          nsClasses: menuItemData?.classNames.slice(),
          nsAccounts: menuItemData?.accountNames.map(mapAccountValues).filter(filterAccountValues).sort(accountSort),
          nsItems: menuItemData?.itemNames.slice(),
          nsEntities: menuItemData?.entityNames.slice(),
          nsBooks: menuItemData?.bookNames.slice(),
          nsStartDates: menuItemData?.startDates.slice().sort(dateSort),
          nsEndDates: menuItemData?.endDates.slice().sort(dateSort),
        }

        SETOPTIONS(mappedOptions);
      }).catch((e: any) => {
        setShowSpinner(false);
      })
    }
  }, [open]);

  const mapAccountValues = (account: any) => {
    const accountId = account.accountId;
    const accountName = account.accountName;
    const accountNumber = account.accountNumber;

    let formattedLabel;

    if(accountName && accountNumber){
      formattedLabel = `${accountNumber} - ${accountName}`;
    } else if (accountName) {
      formattedLabel = accountName;
    } else if (accountNumber){
      formattedLabel = accountNumber;
    } else {
      formattedLabel = null; 
    }

    return {
      id: accountId,
      name: formattedLabel
    }
  }

  const filterAccountValues = (account: any) => {
    return Boolean(account?.name);
  }

  const accountSort = (firstAccount: any, secondAccount: any) => {
    const firstAccountName = firstAccount?.name;
    const secondAccountName = secondAccount?.name;
    return firstAccountName.localeCompare(secondAccountName)
  }

  const sortedMonthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const dateSort = (firstDateString: string, secondDateString: string) => {
    const firstDateStringSplit: string[] = firstDateString.split(' ');
    const secondDateStringSplit: string[] = secondDateString.split(' ');

    if (firstDateStringSplit[1] !== secondDateStringSplit[1]) return firstDateStringSplit[1].localeCompare(secondDateStringSplit[1])

    const firstDateStringMonth: string = firstDateStringSplit[0];
    const secondDateStringMonth: string = secondDateStringSplit[0];

    const firstDateStringMonthIndex = sortedMonthArray.indexOf(firstDateStringMonth);
    const secondDateStringMonthIndex = sortedMonthArray.indexOf(secondDateStringMonth);

    if (firstDateStringMonthIndex < secondDateStringMonthIndex) return -1;
    if (secondDateStringMonthIndex < firstDateStringMonthIndex) return 1;

    return 0;
  }

  useEffect(() => {
    if (!open) return;

    if (netsuiteValueAbortControllerRef.current) netsuiteValueAbortControllerRef.current.abort();

    setBalanceQueryLoading(true);

    const mappedParamters = {
      subsidiaryNames: state?.nsSubsidiaries ? state.nsSubsidiaries.map((option: any) => option.value) : [],
      departmentNames: state?.nsDepartments ? state.nsDepartments.map((option: any) => option.value) : [],
      classNames: state?.nsClasses ? state.nsClasses.map((option: any) => option.value) : [],
      accountIds: state?.nsAccounts ? state.nsAccounts.map((option: any) => option.value) : [],
      itemNames: state?.nsItems ? state.nsItems.map((option: any) => option.value) : [],
      entityNames: state?.nsEntities ? state.nsEntities.map((option: any) => option.value) : [],
      bookNames: state?.nsBooks ? state.nsBooks.map((option: any) => option.value) : [],
      startDate: state?.nsStartDate ? state.nsStartDate?.value : null,
      endDate: state?.nsEndDate ? state.nsEndDate?.value : null,
    }

    const hasRequiredParameters = mappedParamters.accountIds?.length > 0 && mappedParamters.startDate && mappedParamters.endDate;

    if (!hasRequiredParameters) {
      setBalanceQueryLoading(false);
      setBalance(0);
      return;
    }

    const abortController = new AbortController();
    netsuiteValueAbortControllerRef.current = abortController;

    apolloClient.query({
      query: netsuiteValueQuery,
      variables: {
        ...mappedParamters
      },
      context: {
        fetchOptions: {
          signal: abortController.signal
        }
      },
      fetchPolicy: 'network-only'
    }).then((response: any) => {
      setBalanceQueryLoading(false);
      let netsuiteValueData = response?.data?.netsuiteValue?.value;

      if (state.invert) {
        netsuiteValueData = -1 * netsuiteValueData;
      }

      setBalance(netsuiteValueData);      
      setCurrency(response?.data?.netsuiteValue?.currency);
    }).catch((e: any) => {
      setBalanceQueryLoading(false);
    })

  }, [state.nsSubsidiaries, state.nsDepartments, state.nsClasses, state.nsAccounts,
  state.nsItems, state.nsEntities, state.nsBooks, state.nsStartDate, state.nsEndDate])
  
  function handleAccept() {
    const stateCopy = { ...state, ...{ currency: currency } };
    confirmAction({ state: { ...stateCopy, balance: balance } });
  }

  const denyActionWrapper = () => {
    if (netsuiteMenuItemsAbortControllerRef.current) netsuiteMenuItemsAbortControllerRef.current.abort();
    if (netsuiteValueAbortControllerRef.current) netsuiteValueAbortControllerRef.current.abort();
    setShowSpinner(false);
    setBalanceQueryLoading(false);
    denyAction();
  }

  return (
    <div>
      <Dialog
        onClose={denyActionWrapper}
        open={open}
      >
        <div style={{ minWidth: 750, paddingRight: 20, paddingLeft: 20, display: 'flex', flexDirection: 'column', paddingTop: '15px', paddingBottom: '15px' }}>
          <DialogTitle>
            <Typography variant='h2'>
              Edit Reconciliation Source NetSuite Connection
            </Typography>
          </DialogTitle>

          {LEVELS.map((level: any, i: number) =>
            <LevelDropdown
              i={i}
              key={i}
              level={level}
              allOptions={OPTIONS}
              options={OPTIONS[level.value] && OPTIONS[level.value].map((o: any) => o.id
                ? { value: o.id, label: o.name }
                : { value: o, label: o }) || level.options}
              set={(x: any) => {
                setState({ ...state, [level.value]: x })
              }}
              setState={setState}
              showSpinner={showSpinner}
              state={state}
              v={state[level.value] || level.label === 'Period'}
            />
          )}

          <div style={{ marginLeft: 105, marginBottom: 10, display: 'flex', alignItems: 'center' }}>
            <Typography>
              Other Options:
            </Typography>
            <div style={{ marginLeft: 34, display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={state.invert}
                color='primary'
                onChange={handleInvert}
                value={state.invert}
              />
              <Typography>
                Invert total(s)
              </Typography>
            </div>
          </div>

          <div style={{ display: 'flex', marginLeft: 120, marginTop: 30 }}>
            <div style={{ width: '1.25rem', display: 'flex', alignItems: 'flex-end', marginRight: 10 }}>
              {balanceQueryLoading && <CircularProgress size="1.25rem" />}
            </div>

            <div style={{ marginRight: 60 }}>
              <Typography style={{ marginTop: 10 }}>
                Project
              </Typography>
              <Typography style={{ marginTop: 10 }}>
                Section
              </Typography>
              <Typography style={{ marginTop: 10 }}>
                Entity
              </Typography>
              <Typography style={{ marginTop: 10 }}>
                Task
              </Typography>
              <Typography style={{ marginTop: 10 }}>
                Report Period
              </Typography>
              <Typography style={{ marginTop: 10 }}>
                Value
              </Typography>
            </div>
            <div>
              <Typography
                className={classes.ellipsis}
                style={{ width: 268, marginTop: 10 }}
              >
                {idx(project, p => p.name)}
              </Typography>
              <Typography
                className={classes.ellipsis}
                style={{ width: 268, marginTop: 10 }}
              >
                {idx(task, t => t.section.name) || 'No Section'}
              </Typography>
              <Typography
                className={classes.ellipsis}
                style={{ width: 268, marginTop: 10, marginRight: '0px' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'left' }}>

                  {idx(task, t => t.tags.length)
                    ? task.tags.map((tag: any) => <TagLabel label={tag.tag} />)
                    : <Typography>
                      {' '}
                      None
                      {' '}
                    </Typography>}

                </div>

              </Typography>
              <Typography
                className={classes.ellipsis}
                style={{ width: 268, marginTop: 10 }}
              >
                {idx(task, t => t.name)}
              </Typography>
              <Typography
                className={classes.ellipsis}
                style={{ width: 268, marginTop: 10 }}
              >
                {
                  idx(project, p => p.reconEndDate)
                    ? new Date(project.reconEndDate).toLocaleDateString(undefined,
                      { year: 'numeric', month: 'short', day: 'numeric' }
                    )
                    : new Date().toLocaleDateString(undefined,
                      { year: 'numeric', month: 'short', day: 'numeric' }
                    )
                }
              </Typography>
              <Typography
                className={classes.ellipsis}
                style={{ width: 268, marginTop: 10 }}
              >
                {balance ?
                  <>
                    <MoneyDisplay
                      style={{ fontWeight: 500 }}
                      value={balance}
                      customSymbol={currency}
                      displaySymbol
                    />
                  </> : displayCurrency(currency)?.concat(' 0.00')
                }
              </Typography>
            </div>
          </div>

          <DialogActions>
            <Button
              onClick={denyActionWrapper}
              variant='outlined'
            >
              Cancel
            </Button>
            <Button
              autoFocus
              disabled={showSpinner || balanceQueryLoading}
              onClick={handleAccept}
              variant='contained'
            >
              Accept
            </Button>
          </DialogActions>

        </div>
      </Dialog>
    </div>
  )
}

function DropdownIndicator(props: any) {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDownIcon />
    </components.DropdownIndicator>
  );
}

const selectStyles: any = {
  control: () => ({
    alignItems: 'center',
    border: '1px solid hsl(0,0%,80%)',
    borderRadius: '4px',
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: 38,
    position: 'relative',
    transition: 'all 100ms',
    boxSizing: 'border-box'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (provided: any) => ({
    ...provided,
    border: '1px solid hsl(0,0%,80%)',
    boxShadow: 'none',
    marginTop: -1

  }),
  multiValue: () => ({
    background: '#FFF',
    border: '1px solid hsl(0,0%,80%)',
    borderRadius: '2px',
    display: 'flex',
    margin: '2px',
    padding: '2px',
    minWidth: 0,
    boxSizing: 'border-box'
  }),
  multiValueRemove: (provided: any, state: any) => ({
    //    ...provided,
    background: '#FFF',
    color: 'lightgrey',
    paddingLeft: 4,
    paddingRight: 4,
    alignItems: 'center',
    display: 'flex'
  })
};

function LevelDropdown(props: any) {
  const classes = useGlobalStyles();
  const { state, setState } = props;

  // props.v can be a string || an array
  // const emptyProps = !props.v || (Array.isArray(props.v) && !props.v.length)

  return (
    <div
      className={classes.flexCenter}
      style={{ marginBottom: 10 }}
    >
      <div
        className={classes.flexCenter}
        style={{ justifyContent: 'flex-start' }}
      >
        <div style={{ width: 20, marginRight: 20 }}>
          {(props.showSpinner)
            ? <CircularProgress size="1.5rem" />
            : <CircleCheckedFilled style={{ color: 'green', opacity: (Array.isArray(props.v) && props.v.length) ? 1 : 0.1 }} />}
        </div>
        <Typography style={{ width: 140 }}>
          {props.level.label}
        </Typography>
        <div
          className={classes.dropdownContainer}
          style={{ width: 400 }}
        >
          {props.level.component
            ? props.level.component({ ...props, state, setState })
            : <Select
              isDisabled={props.showSpinner}
              components={{ 
                DropdownIndicator,
                MenuList: VirtualizedMenuList 
              }}
              isClearable={false}
              isMulti={props.level.isMulti}
              onChange={props.set}
              options={props.options}
              styles={selectStyles}
              value={props.v}
            />}
        </div>
      </div>
    </div>
  )
}
