import React from 'react';
import clsx from 'clsx';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import {
  Paper,
  Typography,
  Popper,
  ClickAwayListener
} from '@material-ui/core';
import { Check } from '@material-ui/icons';

const popperStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeFileOptionsRoot: {
      width: '180px',
      border: '1px solid #D8D8D8',
    },
    taskFilesToggleRoot: {
      width: '200px',
      border: '1px solid #D8D8D8',
    },
    items: {
      fontSize: '12px',
      color: '#606060',
      font: 'normal normal normal 12px/24px Montserrat',
      width: '100%',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#EBF2FF',
      }
    },
    typography: (props: any) => ({
      cursor: props.cursor
    }),
    itemDiv: (props: any) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '29px',
      cursor: props.cursor
    }),
    iconTypography: (props: any) => ({
      display: 'flex',
      width: '16px',
      marginRight: '10px',
      paddingLeft: '5px',
      cursor: props.cursor
    }),
    icon: (props: any) => ({
      fontSize: '18px',
      color: '#606060',
      cursor: props.cursor
    })
  })
);

/**
 * Reusable `TaskFileTogglePopper` component.
 * This component is using the `TailwindCSS` design pattern.
 *
 * @return {React.JSXElementConstructor} The `TaskFileTogglePopper` component.
 */

export default function TaskFileTogglePopper(props: any) {
  const { anchorEl, onClose, fileDisplay, setFileDisplay, isRequester } = props;

  const classes = popperStyles({});
  const open = Boolean(anchorEl);

  const handleActiveOnlyClick = () => {
    setFileDisplay('Active')
  };

  const handleActiveAndArchivedClicked = () => {
    setFileDisplay('Both')
  }

  return (
    <Popper style={{ zIndex: 1203 }} anchorEl={anchorEl} open={open} placement='bottom-start'>
      <ClickAwayListener onClickAway={onClose}>
        <Paper className={classes.taskFilesToggleRoot} elevation={0} square>
          <div onClick={handleActiveOnlyClick} className={clsx(classes.items, classes.itemDiv)} style={{ paddingLeft: '10px' }}>
            <Typography variant="h6" className={classes.items}>
              Active files only
            </Typography>
            <Typography variant="h6" className={clsx(classes.items, classes.iconTypography)}>
              {fileDisplay === "Active" && <Check className={classes.icon} />}
            </Typography>
          </div>
          {/* Check to prevent users associated with the requester company from seeing archived files */}
          {!isRequester && (
            <>
              <div onClick={handleActiveAndArchivedClicked} className={clsx(classes.items, classes.itemDiv)} style={{ paddingLeft: '10px' }}>
                <Typography variant="h6" className={classes.items}>
                  Active and archived files
                </Typography>
                <Typography variant="h6" className={clsx(classes.items, classes.iconTypography)}>
                  {fileDisplay === "Both" && <Check className={classes.icon} />}
                </Typography>
              </div>
            </>
          )}
        </Paper>
      </ClickAwayListener>
    </Popper>
  )
}