import {createMutationHook, gql} from '../graphqlHelpers';

const editTaskMessage = createMutationHook(gql`
  mutation EditTaskMessage(
    $id: ID!
    $message: String!
  ) {
    editTaskMessage(
      id: $id
      message: $message
    ) {
      success
      errors {
        path
        message
      }
    }
  }
`);
export default editTaskMessage;
