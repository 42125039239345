import { gql} from '../graphqlHelpers';

type ID = number | string;

export interface MoveFilesVariables {
  fileVersionIds: ID[];
  workspaceId: ID;
  destinationWorkspaceId: ID;
}

export interface Error {
  path: string;
  message: string;
}

export interface MoveFilesData {
  success: boolean;
  errors: Error[];
}

export const moveFiles = gql `
  mutation moveFiles( $fileVersionIds: [ID!]!, $workspaceId: ID!, $destinationWorkspaceId: ID!) {
    moveFiles( fileVersionIds: $fileVersionIds, workspaceId: $workspaceId, destinationWorkspaceId: $destinationWorkspaceId) {
      success
      errors {
        path
        message
      }
    }
  }`;