import { createMutationHook, gql } from '../graphqlHelpers';

const mutation = gql`
  mutation RemoveTaskTags($taskIds: [ID!]!, $tag: String!) {
    removeTaskTags(taskIds: $taskIds, tag: $tag) {
      success
    }
  }
`
export const useRemoveTaskTags = createMutationHook(mutation);
