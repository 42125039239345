import { createLazyQueryHook, createQueryHook, gql } from '../graphqlHelpers';
import { DETAILED_TASK_INFO } from '../fragments/detailedTaskInfo';

 

const query = gql`
  ${DETAILED_TASK_INFO}
	query DetailedTaskInfo(
		$taskIds: [ID!]!
	) {
		tasks(ids: $taskIds) {
      ...DetailedTaskInfo
		}
	}
`;

export const useGetTasksById = createQueryHook(query);
export const useLazyGetTasksById = createLazyQueryHook<any, {}>(query);
