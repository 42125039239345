import React from 'react';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { MoneyDisplay } from '../../../../../../../common/MoneyDisplay';
import { chooseFileIcon } from '../../../../../../../nexdd/DashboardHome/common/chooseFileIcon';
import { Skeleton } from '../../../../../../../common/Skeleton';

const checkGreen = require('../../../../../../../../../../../public/images/check_green.svg');
const checkBlue = require('../../../../../../../../../../../public/images/check_blue.svg');
const messaging = require('../../../../../../../../../../../public/images/messaging.svg');
const linking = require('../../../../../../../../../../../public/images/linking.svg');

interface ReconcilingItemsProps {
  sourceType?: any,
  updateTask?: any,
  updateTaskInCache?: any,
  tasks?: any,
  allTasks?: any,
  setTasks?: any,
  updateTaskStatus?: any,
  displaySkeleton?: any,
}

function groupByFileId(array: any) {
  const hash = Object.create(null)
  const result: any[] = [];

  (array || []).forEach(function (a: any) {
    if (!hash[a.fileVersion.id]) {
      hash[a.fileVersion.id] = [];

      result.push(hash[a.fileVersion.id]);
    }

    hash[a.fileVersion.id].push(a);
  });
  return result;
}

function sum(items: any, prop: any) {
  return items.reduce(function(a: any, b: any) {
    return a + b[prop];
  }, 0);
};

export default function ReconcilingItems(props: any) {
  const { task, displaySkeleton, updateTaskStatus, toggleReconcilingItems, updateToggleReconcilingItems } = props;
  const reconFiles = groupByFileId(task.reconcilingItems)
  const skeletonActive = (!task.hasOwnProperty('secondaryLabel') || displaySkeleton)

  const handleToggle = () => {
    updateToggleReconcilingItems(!toggleReconcilingItems)
  }

  return (
    <div>
      <div
        className="focus-visible:ring-opacity-75 flex justify-between items-center p-4 w-full text-sm font-medium text-left text-gray-400 border border-x-0 border-b-0 border-gray-200 cursor-pointer hover:bg-blue-50 focus:outline-none focus-visible:ring focus-visible:ring-blue-100"
        onClick={() => handleToggle()}
        aria-label="reconciling-items-panel"
      >
        <div className="flex justify-between items-center w-full text-xs">
          <div className="inline-block font-bold">
            Reconciling Items
          </div>
          <div className="inline-block pl-1 mr-3 font-bold" data-cy='reconciling-items-summary-data'>
            {skeletonActive ? (
              <Skeleton
                isLoaded={skeletonActive}
                width="22px"
              />
            ) : `(${task?.reconcilingItems?.length})`}
          </div>
        </div>
        <ChevronRightIcon
          className={`${toggleReconcilingItems ? 'transform rotate-90' : ''} !text-lg`}
        />
      </div>
      {toggleReconcilingItems && (
        <>
          {skeletonActive ? (
            <SkeletonReconFiles skeletonActive={skeletonActive} />
          ) : (
            <ReconclilingItemsBody
              reconFiles={reconFiles}
              task={task}
            />
          )}
          {reconFiles?.length === 0 ? <ReconFilesEmpty/> : ''}
        </>
      )}
    </div>
  );
}

const ReconFilesEmpty = (props: any) => {
  return (
    <div className="bg-[#fbfbfb] py-8 px-4 text-gray-400">
      <h5 className="font-bold pb-1.5 text-[#7F7F7F] text-sm flex justify-center">
        <img
          className="pr-2 w-6"
          src={checkBlue} alt="Empty reconciling items"
        />
        No Reconciling Items
      </h5>
      <p className="text-xs text-[#606060]">
        There currently are no reconciling items to review or address
      </p>
    </div>
  );
}

const ReconclilingItemsBody = (props: any) => {
  const { reconFiles, task } = props;

  const handleFileClick = (fileLocation: string) => {
    if (fileLocation) window.open(fileLocation, '_blank');
  }

  let amountColumnWidth = '113px';
  const maxAmountValue = task?.reconcilingItems?.length ? Math.max(...task.reconcilingItems.map((reconFile: any) => reconFile.value)) : 0;
  if (maxAmountValue >= 100000000000) amountColumnWidth = '138px';

  return (
    <div className={`${reconFiles?.length > 0 ? 'pb-2 mt-3.5' : ''} text-sm border border-x-0 border-t-0 border-b-0 border-gray-200`} data-cy='reconciling-items-body'>
      {reconFiles && reconFiles.map((reconFileArray: any, index: any) => {
        const splitFileName = reconFileArray[0].fileVersion.fileName.split('.');
        const fileName = reconFileArray[0].fileVersion.fileName; // Might need to update this in the future as well
        const fileType = splitFileName[splitFileName?.length - 1]; // In case the file has multiple '.' in the name.
        const fileLink = reconFileArray[0].fileVersion.fileLocation;

        return (
          <div
            className="px-4 pb-4"
            key={reconFileArray[0].id}
          >
            <div className="flex items-center pb-2 cursor-pointer">
              <img
                className="pr-2 h-5"
                src={chooseFileIcon(fileType)}
              />
              <p
                className=""
                style={{ color: '#3A84FF', font: 'normal normal normal 12px/15px Montserrat' }}
                onClick={() => { handleFileClick(fileLink) }}
              >
                {fileName}
              </p>
              <div className="pl-1 text-xs text-gray-400">
                ({reconFileArray?.length})
              </div>
            </div>
            <div className="inline-block min-w-full align-middle">
              <table className="min-w-full border-0">
                <thead className="border-b border-gray-200">
                  <tr>
                    <th
                      className="py-2 text-xs font-bold text-left text-gray-400"
                      scope="col"
                    >
                      Description
                    </th>
                    <th
                      className="py-2 text-xs font-bold text-left text-gray-400"
                      scope="col"
                      style={{ width: amountColumnWidth }}
                    >
                      Amount
                    </th>
                    <th
                      className="relative py-2 w-16"
                      scope="col"
                      style={{ width: '73px' }}
                    />
                  </tr>
                </thead>
                <tbody className="divide-y divide-white">
                  {reconFileArray && reconFileArray.map((reconFile: any, reconIndex: any) => {
                    return (
                      <tr
                        className={
                        `${reconFileArray[reconFileArray?.length - 1].id === reconFile.id
                            ? ''
                            : 'border-b border-white'
                          }`
                        }
                        key={reconFile.id}
                      >
                        <td
                          className="py-1 text-xs whitespace-nowrap"
                        >
                          <p className="w-64 truncate">
                            {reconFile.description}
                          </p>
                          <span
                            className="text-gray-400"
                            style={{ fontSize: '10px' }}
                          >
                            {reconFile.prettyDate}
                          </span>
                        </td>
                        <td
                          className="py-1 text-xs text-right whitespace-nowrap"
                        >
                          <div
                            className="flex justify-between truncate"
                            style={{ width: amountColumnWidth }}
                          >
                            <span className="pr-1">$</span>
                            <MoneyDisplay
                              style={{ padding: 'none' }}
                              value={reconFile.value}
                            />
                          </div>
                        </td>
                        <td
                          className="py-1 pl-3 text-xs whitespace-nowrap"
                        >
                          {/* {visibleActionIconList && ( */}
                            <div className="icons-table flex justify-end items-center w-full">
                              <img
                                className="hidden pr-2 w-7"
                                src={checkGreen}
                              />
                              <img
                                className="hidden pr-2 w-7"
                                src={linking}
                              />
                              <img
                                className="hidden w-7"
                                src={messaging}
                              />
                            </div>
                          {/* )} */}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
                <tfoot className="border-t border-gray-200">
                  <tr>
                    <th
                      className="py-2 text-xs font-bold text-left text-gray-400"
                      scope="col"
                    >
                      Subtotal
                    </th>
                    <th
                      className="py-2 text-xs font-bold text-left text-gray-400"
                      scope="col"
                    >
                      <div
                        className="flex justify-between truncate"
                        style={{ width: amountColumnWidth }}
                      >
                        <span className="pr-1">$</span>
                        <MoneyDisplay
                          style={{ padding: 'none', fontWeight: 'bold' }}
                          value={sum(reconFileArray, 'value').toFixed(2)}
                        />
                      </div>
                    </th>
                    <th
                      className="relative py-2"
                      scope="col"
                    />
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        )
      })}
    </div>
  );
}

const SkeletonReconFiles = (props: any) => {
  const { skeletonActive } = props;
  
  return (
    <div className="pb-2 mt-3.5 text-sm border border-x-0 border-t-0 border-b-0 border-gray-200">
        <div className="px-4 pb-4">
          <Skeleton
            isLoaded={skeletonActive}
            width="100px"
          />
          <div className="inline-block min-w-full align-middle">
            <table className="min-w-full border-0">
              <thead className="border-b border-white">
                <tr>
                  <th
                    scope="col"
                    className="pl-0 p-1"
                  >
                    <Skeleton
                      isLoaded={skeletonActive}
                      width="100%"
                    />
                  </th>
                  <th
                    scope="col"
                    className="p-1"
                    style={{ width: "113px" }}
                  >
                    <Skeleton
                      isLoaded={skeletonActive}
                      width="100%"
                    />
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-white">
                <tr>
                  <td className="pl-0 p-1 text-xs whitespace-nowrap">
                    <Skeleton
                      isLoaded={skeletonActive}
                      height="20px"
                    />
                    <Skeleton
                      isLoaded={skeletonActive}
                      width="113px"
                      height="20px"
                    />
                  </td>
                  <td className="p-1">
                    <Skeleton
                      isLoaded={skeletonActive}
                    />
                  </td>
                </tr>
              </tbody>
              <tfoot className="border-t border-white">
                <tr>
                  <th className="pl-0 p-1" scope="col">
                    <Skeleton
                      isLoaded={skeletonActive}
                    />
                  </th>
                  <th className="p-1" scope="col">
                    <Skeleton
                      isLoaded={skeletonActive}
                    />
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      '&:hover': {
        iconsTable: {
          display: 'block'
        }
      }
    },
  })
);
