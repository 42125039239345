import React from "react";
import clsx from "clsx";
import { useStyles } from "../Styles";
import {
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Popper,
} from "@material-ui/core";
import { ICreateModalSelected } from "../../../constants";

interface IFolderTypePopper {
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  selectCreateOption: (createOptionSelected: ICreateModalSelected) => void;
}

export const FolderTypePopper = (props: IFolderTypePopper) => {
  const { anchorEl, setAnchorEl, selectCreateOption } = props;
  const classes = useStyles();

  function close() {
    setAnchorEl(null);
  }

  const handleTypeSelection = (str: ICreateModalSelected) => {
    selectCreateOption(str);
    close();
  };

  return (
    <Popper
      anchorEl={anchorEl}
      data-cy="test"
      open={Boolean(anchorEl)}
      placement={"bottom-start"}
      style={{ zIndex: 1301 }}
    >
      <ClickAwayListener onClickAway={close}>
        <List
          classes={{ root: clsx(classes.root, classes.list) }}
          disablePadding
        >
          <ListItem
            classes={{ root: classes.listItem }}
            onClick={() => handleTypeSelection("internalSpace")}
          >
            <ListItemText
              primary="Internal Space"
              classes={{ primary: classes.t2 }}
            />
          </ListItem>
          <ListItem
            classes={{ root: classes.listItem }}
            onClick={() => handleTypeSelection("sharedSpace")}
          >
            <ListItemText
              primary="Shared Space"
              classes={{ primary: classes.t2 }}
            />
          </ListItem>
          <ListItem
            classes={{ root: classes.listItem }}
            onClick={() => handleTypeSelection("folder")}
          >
            <ListItemText primary="Folder" classes={{ primary: classes.t2 }} />
          </ListItem>
        </List>
      </ClickAwayListener>
    </Popper>
  );
};
