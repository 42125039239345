import React, { useEffect, useState } from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import idx from 'idx';
import clsx from 'clsx';

import Panel from '../../../../../common/Panel';
import TemplatesPane from '../../common/TemplatesPane';
import CircularLoader from '../../../../../common/CircularLoader';
import { TemplateLists_templateLists } from '../../../../../../graphql/queries/__generated__/TemplateLists';
import { useTemplateDetails } from '../../../../../../graphql/queries/TemplateDetails';
import { newVolatileTemplate } from '../../../../../../graphql/mutations/NewVolatileTemplate';
import { createVolatileList } from '../../../../../../graphql/mutations/CreateVolatileList';
import { useMutation } from '@apollo/client';
import { useSetFilters } from '../../../../../../hooks/SetFilters';
import { useDispatchContext, useStateContext } from '../../../../../../store';
import {
  ModifySpaceObjectModal,
  useModifySpaceObjectModalState,
} from '../../../../../nexdd/DashboardHome/common/Modals/ModifySpaceObjectModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0px calc((100% - 1080px) / 2) 0px calc((100% - 1080px) / 2)',
      overflow: 'auto',
      position: 'relative',
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    container: {
      height: '100%',
      width: '100%',
    },
    grow: {
      flexGrow: 1,
    },
    loadTemp: {
      marginTop: 30,
    },
    t2: {
      fontSize: '18px',
      lineHeight: '22px',
      color: '#707070',
      opacity: 1,
      fontWeight: 'bold',
    },
    spinner: {
      position: 'absolute',
      zIndex: 1301,
      background: 'rgba(255, 255, 255, .8)',
      display: 'flex',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      alignContent: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  })
);

const labels: any = {
  general: { label: 'General Templates', value: 'general' },
  finance: { label: 'Finance', value: 'finance' },
  accounting: { label: 'Accounting', value: 'accounting' },
  cybersecurity: { label: 'Cybersecurity', value: 'cybersecurity' },
};

interface IProjectTemplates {
  data: TemplateLists_templateLists[];
  setSelectedTemplate: React.Dispatch<React.SetStateAction<any>>;
  selectedTemplate: any;
  templateSelected(): void;
  setSelectedTasksIds: React.Dispatch<React.SetStateAction<string[]>>;
}

interface CompanyModule {
  name: string;
  url: string;
  __typename: string;
}

export default function ProjectTemplates(props: IProjectTemplates) {
  const {
    data,
    setSelectedTemplate,
    templateSelected,
    setSelectedTasksIds,
    selectedTemplate,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatchContext();
  const state = useStateContext();
  const setFilters = useSetFilters();
  const nexddFilter =
    idx(state, (s) => s.currentUser.filters.nexddFilter) || {};
  const workspaceId =
    nexddFilter.selectedFolderId || nexddFilter.selectedSpaceId || null;

  const [selectedTemplateId, setSelectedTemplateId] = useState<number>();

  const { loading: templateLoading, data: templateData } = useTemplateDetails({
    id: selectedTemplateId,
  });
  const [
    useNewVolatileTemplate,
    { loading: listBlankLoading, data: listBlankData, error: listBlankError },
  ] = useMutation(newVolatileTemplate);
  const [
    useCreateVolatileList,
    { loading: listLoading, data: listData, error: listError },
  ] = useMutation(createVolatileList);
  const setModifySpaceObjectModalState = useModifySpaceObjectModalState();

  const trackProjectIdForNexddRedirect = (id: string | number): void => {
    setFilters(
      {
        taskPageLists: [id],
        nexddFilter: {
          selectedSpaceId: null,
          selectedFolderId: null,
          selectedDashboardId: null,
          selectedReportId: null,
          selectedProjectId: id,
        },
      },
      { resetTaskFilters: true }
    );
  };

  // Setting this here so we can know in DashboardHome.tsx if a project has been created
  // without being assigned to a workspace.
  const flagWorkspaceForUnassignedProject = (projectId: (string | number)) => {
    if (!workspaceId || workspaceId === state.currentUser.workspace.id) {
      setModifySpaceObjectModalState(false, 'unassignedProject', {id: projectId}, 'project');
    }
  };

  const flagLeftNavToPullNewProject = () => {
    dispatch({
      type: 'SET_IS_SPACE_UPDATED',
      isUpdated: true,
    });
  };

  const handleClickBlank = () => {
    useNewVolatileTemplate({
      variables: { workspaceId },
    }).then((response: any) => {
      setSelectedTemplateId(response.data.newVolatileList.id);
      trackProjectIdForNexddRedirect(response.data.newVolatileList.id);
      flagWorkspaceForUnassignedProject(response.data.newVolatileList.id);
      flagLeftNavToPullNewProject();
    });
  };

  function handleTemplateClick(template: TemplateLists_templateLists) {
    useCreateVolatileList({
      variables: { listId: template.id, workspaceId },
    }).then((response: any) => {
      setSelectedTemplateId(response.data.createVolatileList.id);
      trackProjectIdForNexddRedirect(response.data.createVolatileList.id);
      flagWorkspaceForUnassignedProject(response.data.createVolatileList.id);
      flagLeftNavToPullNewProject();
    });
  }

  // set the selectedTemplate.tasks to empty when land on this page
  useEffect(() => {
    if (idx(selectedTemplate, (template) => template.tasks.length > 0))
      setSelectedTemplate({ ...selectedTemplate, tasks: [] });
  }, []);

  // Fetch selected template's data(tasks, sections, etc) from BackEnd
  useEffect(() => {
    if (selectedTemplateId) {
      const template = idx(templateData, (data: any) => data.list);
      if (templateLoading || selectedTemplateId.toString() === '-1' || !template) {
        return;
      }
      setSelectedTemplate(template);
      setSelectedTasksIds(template.tasks.map((t: any) => t.id));
      templateSelected();
    }
  }, [selectedTemplateId, templateLoading]);

  return (
    <div className={clsx(classes.root, classes.container)}>
      <div className={classes.flexCenter}>
        <Typography className={classes.t2}>
          Select your project template
        </Typography>
        <div className={classes.grow} />
        <Button
          variant='contained'
          onClick={handleClickBlank}
          data-cy='create-blank-project'
        >
          Create blank project
        </Button>
      </div>
      <Panel
        title='Create Project'
        labels={Object.keys(labels).map((label) => ({
          label: labels[label].label,
        }))}
        data-cy='template-panel'
      >
        {Object.keys(labels).map((label) =>
          label === labels.general.value ? (
            data ? (
              <TemplatesPane
                data={data}
                setSelectedTemplateId={setSelectedTemplateId}
                templateName={labels.general.label}
                handleTemplateClick={handleTemplateClick}
              />
            ) : (
              <Typography className={clsx(classes.t2, classes.loadTemp)}>
                Loading Templates
              </Typography>
            )
          ) : data &&
            data.filter((o: any) => o.labelName == labels[label].label)
              .length ? (
            <TemplatesPane
              data={data.filter((o: any) => o.labelName == labels[label].label)}
              setSelectedTemplateId={setSelectedTemplateId}
              templateName={labels[label].label}
              handleTemplateClick={handleTemplateClick}
            />
          ) : (
            <Typography className={clsx(classes.t2, classes.loadTemp)}>
              Loading Templates
            </Typography>
          )
        )}
      </Panel>
      {(templateLoading || listLoading || listBlankLoading) && (
        <div className={classes.spinner}>
          <CircularLoader />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '36px',
            }}
          >
            <Typography>
              Please give us a second while we generate your template
            </Typography>
          </div>
        </div>
      )}
      <ModifySpaceObjectModal />
    </div>
  );
}
