import React from 'react';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import { Typography } from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { FONT } from '../../../../../../../../constants/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      border: 'none',
      background: 'none',
      fontFamily: FONT.family,
      fontSize: FONT.size.xs,
      fontWeight: 500,
      '&:hover': {
        border: 'none',
      },
      '&:focus': {
        border: 'none',
        outline: 'none',
      }
    },
  })
);

interface MoneyDisplayProps {
  value: string | number;
  style?: any;
  dollarSign?: boolean;
  onClick?: any;
};

const getNumberFormat = (props: MoneyDisplayProps, isNegative: boolean, classes: any) => {

  return (
    <React.Fragment>
      {props.dollarSign && '$'}
      <NumberFormat
        onClick={props.onClick}
        className={clsx(classes.input)}
        style={{ cursor: 'default', whiteSpace: 'nowrap', color: '#606060', width: '100%', textAlign: 'end', ...props.style }}
        value={Math.abs(Number(props.value))}
        thousandSeparator
        decimalScale={2}
        displayType='text'
        fixedDecimalScale
        prefix={isNegative ? '( ' : ''}
        suffix={isNegative ? ' )' : '\u00A0\u00A0'}
      />
    </React.Fragment>
  )

}

export function MultiFileReconMoneyDisplay(props: MoneyDisplayProps) {
  const classes = useStyles();
  const isNegative = Number(props.value) < 0;

  return (
    <div onClick={props.onClick} style={{display: 'contents'}}>
       { Number(props.value)
          ? getNumberFormat(props, isNegative, classes)
          : <Dash />
       }
    </div>
  )

}

const Dash = ({ color }: any) => {
  const classes = useStyles();
  return (<Typography
    style={{ color }}
    className={clsx(classes.input)}
  >
    {`$\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0-\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0`}
  </Typography>);
}
