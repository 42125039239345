import {createQueryHook, gql} from '../graphqlHelpers';
import {UserTags} from './__generated__/UserTags';

export const useUserTags = createQueryHook<UserTags, {}>(gql`
  query UserTags {
    tags {
      id
      tag
    }
  }
`);


