import { gql } from '../graphqlHelpers';

export const getWorkspace = gql`
  query getWorkspace($spaceId: ID!) {
    getWorkspace(spaceId: $spaceId) {
      id
      name
      description
      isInternal
      breadcrumb {
        id
        name
        spaceId
        accessLevel
        isDashboard
      }
      accessUrl
      companyId
      urlAccessLevel
      users {
        id
        fullName
        email
        workspaceAccessLevel
        permissions{
          create
          edit
          view
          share
          download
          manage
          build
          collaborate
        }
      }
    }
  }
`;

export const getWorkspaceBreadcrumbs = gql`
  query getWorkspace($spaceId: ID!) {
    getWorkspace(spaceId: $spaceId) {
      id
      name
      isInternal
      isDashboard
      breadcrumb {
        id
        name
        spaceId
        accessLevel
      }
      
    }
  }
`;
