import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import idx from 'idx';
import {gql, useLazyQuery} from '@apollo/client';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import {
  ClickAwayListener,
  Typography,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';

import StyledItem from './StyledItem';
import NestedList from './NestedList';

import {useDispatchContext, useStateContext} from '../../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      marginBottom: 14,
      width: 198
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    clickalbleArea: {
      height: '36px',
      flexGrow: 1,
    },
    secondary: {
      color: '#606060',
      marginTop: '24px',
      marginBottom: '12px',
    },
    addButton: {
      border: '1px solid #D8D8D8',
      borderRadius: '3px',
      fontSize: '15px',
    },
    addPanel: {
      position: 'absolute',
      width: '280px',
      top: '33px',
      left: '0px',
      padding: '12px 24px 18px',
      backgroundColor: '#FFFFFF',
      border: '2px solid #D8D8D8',
      borderRadius: '3px',
      zIndex: 1,
    },
    input: {
      display: 'block',
      width: '100%',
      marginTop: '6px',
      color: '#606060',
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: '12px',
      textTransform: 'none',
      '& label': {
        color: '#606060',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '12px',
      },
      '&:selected': {
        color: '#3A84FF',
      },
      '& input::placeholder': {
        fontSize: '12px',
      },
      '& div': {
        width: '100%',
      },
    },
    addLink: {
      marginTop: '6px',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    companyLogo: {
      width: '24px',
      minWidth: '24px',
      height: '24px',
      marginRight: '12px',
    },
    nestedList: {
      width: '100%',
      background: '#FFFFFF',
    },
    warning: {
      marginTop: 0,
    }
  })
);

const SEARCH_COMPANIES = gql`
  query SearchCompanies($email: String!, $companyId: ID) {
    searchCompanies(email: $email, companyId: $companyId) {
      companies {
        id
        name
        logoUrl
      }
      users {
        id
        fullName
        profileUrl
        searchCompanies {
          id
          name
          logoUrl
        }
        companies {
          id
          name
          logoUrl
        }
      }
    }
  }
`;

interface NewCompanyType {
  newCompanyName: string;
  ownerEmail: string;
}

interface CompanyFormProps {
  sharing: string;
  newTemplate: any;
  setNewTemplate: React.Dispatch<React.SetStateAction<any>>;
  inviteCompany: NewCompanyType;
  setInviteCompany: React.Dispatch<React.SetStateAction<NewCompanyType>>;
  showWarning: any;
}

export default function CompanyForm(props: CompanyFormProps) {
  const {
    sharing,
    newTemplate,
    setNewTemplate,
    inviteCompany,
    setInviteCompany,
    showWarning,
  } = props;
  const classes = useStyles();
  const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [searchString, setSearchString] = useState<string>('');
  const [open, setOpen] = useState<boolean>(true);
  const [openInvitePanel, setOpenInvitePanel] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState({
    users: null,
    companies: null,
  });
  const [
    issueCompany,
    setIssueCompany,
  ] = useState<any>(null);
  const state = useStateContext();

  const [searchCompanies, {loading, data, error}] = useLazyQuery(
    SEARCH_COMPANIES
  );

  useEffect(() => {
    let result = idx(data, data => data.searchCompanies);

    if (loading || !result) return;
    result.companies = result.companies.filter(
      (company: any) =>
        company.id !== state.selectedCompany
    );
    setSearchResult(result);
  }, [loading, idx(data, data => data.searchCompanies)]);

  const toggleSetOpen = () => {
    setOpen(!open);
  };

  const handleCloseAll = () => {
    setOpen(false);
    setOpenInvitePanel(false);
    setSearchResult({
      users: null,
      companies: null,
    });
    setSearchString('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    var result = EMAIL_REGEX.test(value);
    setSearchString(value);
    if (result){
//      setNewTemplate({...newTemplate, userEmail: value})
      searchCompanies({
        variables: {email: value},
      });
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) {
      // Search companies...
      var result = EMAIL_REGEX.test(searchString)
      if (result){
        searchCompanies({
          variables: {email: searchString},
        });
      }
    }
  };

  const handleClickIssueTo = ( company: any) => {
    if (sharing === 'share') {
      setNewTemplate({
        ...newTemplate,
        requester: {id: company.id },
        otherCompanyOwners: [{email: searchString}]
      });
    } else if (sharing === 'issue') {
      setNewTemplate({
        ...newTemplate,
        responder: {id: company.id},
        otherCompanyOwners: [{email: searchString}]
      });
    }
    setIssueCompany(company);
    setOpen(false);
  };

  const handleChangeInviteInfo = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {name, value} = event.target;

    setInviteCompany({
      ...inviteCompany,
      [name]: value,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (sharing === 'share') {
      setNewTemplate({
        ...newTemplate,
        requester: {name: inviteCompany.newCompanyName },
        otherCompanyOwners: [{email: inviteCompany.ownerEmail}]
      });
    } else if (sharing === 'issue') {
      setNewTemplate({
        ...newTemplate,
        responder: {name: inviteCompany.newCompanyName },
        otherCompanyOwners: [{email: inviteCompany.ownerEmail}]
      });
    }
    setIssueCompany({
      name: inviteCompany.newCompanyName
    })
    setOpen(false)
  };

  const removeCompany = () => {
    setIssueCompany(null);
    var updatedTemplate = {
      ...newTemplate,
      otherCompanyOwners: []
    }
    if ( sharing === 'share') {
      updatedTemplate.requester = null
    } else if ( sharing === 'issue' ) {
      updatedTemplate.responder = null
    }
    setNewTemplate(updatedTemplate);
  }

  const handleImageError = (ev: any) => {
    ev.target.style.height = '0px';
    ev.target.style.textIndent = '100vw';
  }

  const companies = idx(searchResult, r => (r as any).companies)

  const renderUserList = () => {
    const users = idx(searchResult, s => (s as any).users)
    if (users && users.length) {
      return (
        <List
          component="div"
          aria-labelledby="nested-project"
          className={classes.nestedList}
        >
          {users.map(
              (user: any) => {
                return (
                  <NestedList
                    key={user.id}
                    data={user}
                    onClick={handleClickIssueTo}
                  />
                );
              }
            )}
        </List>
      );
    } else if (companies && !companies.length ) {
      return <Typography variant="h3">No match result</Typography>;
    } else {
      return null;
    }
  };
 return (
    <div className={classes.root}>
      <div style={{display: 'flex'}}>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <Typography variant="h6" className={clsx(classes.secondary, showWarning == true && classes.warning)}>
            Issue to*
          </Typography>
        </div>
      </div>


      <div id="A" style={{ maxWidth: '90%' }}>

        {issueCompany && (
          <StyledItem
            label={issueCompany.name as string}
            logo={issueCompany.logoUrl as string}
            remove={removeCompany}
          />

        )}

        <div id="B"
             style={{position: 'relative'}}
        >
         {!issueCompany && <Button onClick={toggleSetOpen} data-cy='company-plus' className={classes.addButton}>+</Button> }
          {open ? (
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Paper
                className={classes.addPanel}
                elevation={0}
              >
                <TextField
                  data-cy='email-search-input'
                  className={classes.input}
                  placeholder="Search by user email ..."
                  value={searchString}
                  onChange={handleChange}
                  onKeyUp={handleKeyUp}
                />
                <List component="div" aria-labelledby="Invite new">
                  { companies && companies.slice(0, 5).map((company: any) => {
                      return (
                        <ListItem
                          key={company.id}
                          onClick={() => handleClickIssueTo(company)}
                          disableGutters
                        >
                          {company.logoUrl && (
                            <img
                              src={company.logoUrl}
                              className={classes.companyLogo}
                              alt={company.name}
                              onError={handleImageError}
                            />
                          )}
                          <ListItemText primary={company.name as string} />
                        </ListItem>
                      );
                    })}
                </List>

                {renderUserList()}

                {openInvitePanel ? (
                  <form onSubmit={handleSubmit}>
                    <Typography variant="h6" style={{marginTop: '24px'}}>
                      New user and company
                    </Typography>
                    <TextField
                      data-cy='invite-email'
                      type="email"
                      label="Email"
                      name="ownerEmail"
                      value={inviteCompany.ownerEmail}
                      className={classes.input}
                      onChange={handleChangeInviteInfo}
                      required
                    />
                    <TextField
                      data-cy='invite-company'
                      label="Company"
                      name="newCompanyName"
                      value={inviteCompany.newCompanyName}
                      className={classes.input}
                      onChange={handleChangeInviteInfo}
                      required
                    />
                    <Button data-cy='invite-button' type="submit" className={classes.addLink}>
                      Invite new user and company
                    </Button>
                  </form>
                ) : (
                  <Button
                    data-cy='add-owner-button'
                    className={classes.addLink}
                    onClick={() => setOpenInvitePanel(true)}
                  >
                    + Add owner
                  </Button>
                )}
              </Paper>
            </ClickAwayListener>
          ) : null}
        </div>
      </div>
    </div>
  );
}
