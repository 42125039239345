import React, {useEffect, useRef, useState} from 'react';
import idx from 'idx';
import {useDispatchContext, useStateContext} from '../../../store';
import { uniqueArrayOfObjectsByKey } from '../../../helpers/helpers';
import { useLazyListLabels } from '../../../graphql/queries/ListLabels';
import MessageLabelTag from '../../../tailwind/messageBox/MessageLabelTag';
import AddMessageLabelTag from '../../../tailwind/messageBox/AddMessageLabelTag';

type ComponentProps = {
  labelsChanged(labels: any[]): void, // Called when a label has been selected
  selectedLabels: any[], // Provide selected labels to display them.
};

/**
 * Component responsible for selecting labels and displaying them.
 *
 * @param {ComponentProps} props - The props containing label-related functions and selected labels.
 * @returns {JSX.Element} - The JSX element representing the label selection container.
 */
export default function ListLabelsContainer(props: ComponentProps) {
  const { selectedLabels, labelsChanged } = props;
  const containerRef: any = useRef<any>(null);
  const dispatch = useDispatchContext()
  const state = useStateContext();
  const currentCompanyId = idx(state, state => state.currentUser.lastViewedCompanyId);
  const listLabelTypes = idx(state, state => state.listLabelTypes) || [];
  const [getListLabels] = useLazyListLabels({
    companyId: currentCompanyId
  });
  const [labelSelectedList, setLabelSelectedList] = useState<any[]>([]); // List of selected labels

  useEffect(() => {
    setLabelSelectedList(selectedLabels || []);
  }, [selectedLabels]);

  const updateSelectedLabels = (items: any[]) => {
    labelsChanged(items);
    setLabelSelectedList(items);
  }

  const onSelectLabelTag = (tag: any) => {
    const items = uniqueArrayOfObjectsByKey([...labelSelectedList, tag], 'name');
    updateSelectedLabels(items);
  }

  const onRemoveSelectedTag = (tag: any) => {
    const items = labelSelectedList.filter((it:any) => it.name !== tag.name);
    updateSelectedLabels(items);
  }

  const onLabelTagsChanged = (newLabelId: string) => {
    getListLabels().then((response: any) => {
      const listLabelTypes = response?.data?.listLabels;
      if (listLabelTypes) {
        // Update the selected labels
        const persistedLabel = listLabelTypes.find((it:any) => it.id === newLabelId);

        const items = labelSelectedList.filter((it:any) => listLabelTypes.some((ml:any) => ml.id === it.id)).concat(persistedLabel ? [persistedLabel] : []).map((it:any) => {
          const msgLabel = listLabelTypes.find((ml:any) => ml.id === it.id);
          return {...it, name: msgLabel.name, color: msgLabel.color}
        });
        updateSelectedLabels(items);
        dispatch({
          type: 'SET_LIST_LABEL_TYPE',
          listLabelTypes: listLabelTypes
        });
      }
    });
  }
  
  return (
    <div className="flex items-center w-full flex-wrap" ref={containerRef}>
      <div data-cy='msg-tags-container' className="flex items-center flex-wrap -mr-[8px]">
        {props.selectedLabels.map((label:any, index: number) => {
          return <MessageLabelTag key={label.name} label={label} onRemoveSelectedTag={onRemoveSelectedTag} canRemove={true} truncate={false} className="my-1" />
        })}
        <div className="flex ml-1 relative">
          <AddMessageLabelTag
            labels={listLabelTypes || []}
            selectedLabels={selectedLabels}
            onSelectTag={onSelectLabelTag}
            onLabelTagsChanged={onLabelTagsChanged}
            dropdownClassName={'w-[200px] top-[20px]'}
            entityType={"List"}
          />
        </div>
      </div>
    </div>
  );
}
