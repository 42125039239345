import React, { useReducer, createContext, useContext } from 'react';
import {
  NotificationType,
  TaskTag,
  ClassificationsTasks,
  ModifySpaceObjectModalState,
  UserSpacePermissions,
  ShareModalState,
  MessageLabelType,
  ListLabelType,
  ResolveObject,
  OktaSignInResponseType,
  TimePeriod
} from '../constants/types';

export interface PrimaryTotal {
  balance: any;
  task_id: any;
}

export interface SecondaryTotal {
  balance: any;
  task_id: any;
}

export interface FileDownloadStatus {
  isOpen: boolean,
  type: string,
  payload: any,
  downloadId: number,
}

type State = {
  selectedCompany: string;
  currentUser: any;
  userLists: any;
  userSelectedLists: any;
  userSelectedSections: any;
  selectedListIds: any;
  selectedSectionIds: any;
  notification: NotificationType;
  taskUpload: any;
  sideBarOpen: boolean;
  versionUpload?: any;
  rejectedTask?: string | null;
  updateLeftSideNav?: boolean;
  dependencyQueryUpdate: number;
  addedTags: TaskTag[];
  addedClassifications: ClassificationsTasks[];
  isSpaceUpdated: boolean;
  createModalOpen: boolean;
  spaceAccessLevel: string;
  taskPageGrouping: string;
  modifySpaceObjectModalState: ModifySpaceObjectModalState;
  userSpacePermissions: UserSpacePermissions;
  shareModalState: ShareModalState;
  fileUploadStatusState: any; // TODO: type this (just making it work right meow)
  fileDownloadStatusState: FileDownloadStatus;
  breadcrumbIds: (string | number)[];
  tableSetAllFiltersRef: any;
  widgetHeight: number | 174;
  primaryTotal: any;
  secondaryTotal: any;
  messageLabelTypes: MessageLabelType[];
  listLabelTypes: ListLabelType[];
  resolveObjects: ResolveObject[];
  timePeriods: TimePeriod[];
  sidePanelMessagePaneRef: any;
  selectedTask: any;
  navigateToMessage: string;
  oktaSignInResponse: OktaSignInResponseType | null | undefined;
  currentOktaIntegration: any;
  oktaConfigIsReady: any;
  queryTasksFilters: any;
  excelBatchFilesUploaded: any;
  forceRefreshTasks: string;
  forceRefreshSkinnyLists: string;
  listCustomTaskStatuses: any[];
  listGroupedCustomTaskStatuses: any;
};

type Action =
  | { type: 'SET_SELECTED_COMPANY'; companyId: string }
  | { type: 'SET_CURRENT_USER'; user: any }
  | { type: 'SET_TASK_PAGE_GROUPING'; taskPageGrouping: string }
  | { type: 'SET_USER_LISTS'; userLists: any }
  | { type: 'SET_USER_SELECTED_TASK_PAGE_OPTIONS'; userSelectedLists: any, userSelectedSections: any } //These values are being used on the task page to query data
  | { type: 'UPDATE_PROJECT_SELECTION_OPTONS'; selectedListIds: any, selectedSectionIds: any } //These values are being used to update the selected data in the ProjectSelection dropdowns
  | { type: 'SET_TABLE_SET_ALL_FILTERS_REF'; tableSetAllFiltersRef: any }
  | { type: 'SET_WIDGET_HEIGHT'; widgetHeight: any }
  | { type: 'SET_PRIMARY_TOTAL'; primaryTotal: any }
  | { type: 'SET_SECONDARY_TOTAL'; secondaryTotal: any }
  | { type: 'SET_NOTIFICATION'; notification: NotificationType }
  | { type: 'SET_TASK_UPLOAD'; taskUpload: any }
  | { type: 'SET_VERSION_UPLOAD'; versionUpload: any }
  | { type: 'SET_SIDE_BAR_OPEN'; sideBarOpen: boolean }
  | { type: 'SET_REJECTED_TASK'; rejectedTask: string | null }
  | { type: 'SET_UPDATE_LEFT_SIDE_NAV'; updateLeftSideNav: boolean }
  | { type: 'SET_USER_FILTERS'; filters: any }
  | { type: 'DEPEDENCIES_UPDATED' }
  | { type: 'ADD_NEW_TAG'; addedTag: TaskTag }
  | { type: 'SET_MESSAGE_LABEL_TYPE'; messageLabelTypes: any }
  | { type: 'SET_LIST_LABEL_TYPE'; listLabelTypes: any }
  | { type: 'SET_RESOLVE_OBJECTS'; resolveObjects: any }
  | { type: 'SET_TIME_PERIODS'; timePeriods: any }
  | { type: 'SET_SIDE_PANEL_MESSAGE_PANE_REF'; sidePanelMessagePaneRef: any }
  | { type: 'SET_NAVIGATE_TO_MESSAGE'; navigateToMessage: string }
  | { type: 'ADD_NEW_CLASSIFICATION'; addedClassification: ClassificationsTasks }
  | { type: 'CHANGE_CURRENT_SPACE'; newSpace: any }
  | { type: 'SET_IS_SPACE_UPDATED'; isUpdated: boolean }
  | { type: 'SET_CREATE_SPACE_MODAL_OPEN'; isOpen: boolean }
  | { type: 'SET_SPACE_ACCESS_LEVEL'; spaceAccessLevel: string }
  | {
    type: 'SET_MODIFY_SPACE_OBJECT_MODAL';
    modifySpaceObjectModalState: ModifySpaceObjectModalState;
  }
  | {
    type: 'SET_USER_SPACE_PERMISSIONS';
    userSpacePermissions: UserSpacePermissions;
  }
  | { type: 'SET_SHARE_MODAL_STATE'; shareModalState: ShareModalState }
  | { type: 'SET_FILE_UPLOAD_STATUS_STATE'; fileUploadStatusState: {} }
  | { type: 'SET_FILE_DOWNLOAD_STATUS_STATE'; fileDownloadStatusState: {} }
  | { type: 'SET_SELECTED_TASK'; selectedTask: {} }
  | { type: 'SET_BREADCRUMB_IDS'; breadcrumbIds: (string | number)[] }
  | { type: 'SET_OKTA_SIGN_IN_RESPONSE'; oktaSignInResponse: OktaSignInResponseType}
  | { type: 'SET_CURRENT_OKTA_INTEGRATION'; currentOktaIntegration: any}
  | { type: 'SET_OKTA_CONFIG_IS_READY'; oktaConfigIsReady: any}
  | { type: 'SET_QUERY_TASKS_FILTERS'; queryTasksFilters: any}
  | { type: 'SET_EXCEL_BATCH_FILES_UPLOADED'; excelBatchFilesUploaded: any }
  | { type: 'SET_FORCE_REFRESH_TASKS'; forceRefreshTasks: string }
  | { type: 'SET_FORCE_REFRESH_SKINNY_LISTS'; forceRefreshSkinnyLists: string }
  | { type: 'SET_LIST_CUSTOM_TASK_STATUSES'; listCustomTaskStatuses: any[] }
  | { type: 'SET_LIST_GROUPED_CUSTOM_TASK_STATUSES'; listGroupedCustomTaskStatuses: any };

const rootReducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'SET_USER_FILTERS':
      var user = JSON.parse(JSON.stringify(state.currentUser));
      if (!action.filters) return state;
      for (var [filterType, filterValue] of Object.entries(action.filters)) {
        user.filters[filterType] = filterValue;
      }
      return {
        ...state,
        currentUser: user,
      };
    case 'SET_SELECTED_COMPANY':
      return {
        ...state,
        selectedCompany: action.companyId,
      };
      break;
    case 'SET_CURRENT_USER':
      return {
        ...state,
        currentUser: action.user,
        taskPageGrouping: action.user?.filters?.taskPageGroupBySort || 'section'
      };
      break;
    case 'SET_TASK_PAGE_GROUPING':
      return {
        ...state,
        taskPageGrouping: action.taskPageGrouping
      }
    case 'SET_USER_LISTS':
      return {
        ...state,
        userLists: action.userLists,
      };
      break;
    case 'SET_MESSAGE_LABEL_TYPE':
      return {
        ...state,
        messageLabelTypes: action.messageLabelTypes,
      };
      break;
    case 'SET_LIST_LABEL_TYPE':
      return {
        ...state,
        listLabelTypes: action.listLabelTypes,
      };
      break;
    case 'SET_RESOLVE_OBJECTS':
      return {
        ...state,
        resolveObjects: action.resolveObjects,
      };
      break;
    case 'SET_TIME_PERIODS':
      return {
        ...state,
        timePeriods: action.timePeriods,
      };
    case 'SET_SIDE_PANEL_MESSAGE_PANE_REF':
        return {
          ...state,
          sidePanelMessagePaneRef: action.sidePanelMessagePaneRef,
        };
        break;
    case 'SET_NAVIGATE_TO_MESSAGE':
        return {
          ...state,
          navigateToMessage: action.navigateToMessage,
        };
        break;
    case 'SET_USER_SELECTED_TASK_PAGE_OPTIONS':
      return {
        ...state,
        userSelectedLists: action.userSelectedLists,
        userSelectedSections: action.userSelectedSections
      };
      break;
    case 'UPDATE_PROJECT_SELECTION_OPTONS':
      return {
        ...state,
        selectedListIds: action.selectedListIds,
        selectedSectionIds: action.selectedSectionIds
      };
      break;
    case 'SET_TABLE_SET_ALL_FILTERS_REF':
      return {
        ...state,
        tableSetAllFiltersRef: action.tableSetAllFiltersRef
      }
      break;
    // State to auto recalculate the task sidebar widget height
    case 'SET_WIDGET_HEIGHT':
      return {
        ...state,
        widgetHeight: action.widgetHeight
      }
      break;
    case 'SET_PRIMARY_TOTAL':
        return {
          ...state,
          primaryTotal: action.primaryTotal
        }
        break;
    case 'SET_SECONDARY_TOTAL':
      return {
        ...state,
        secondaryTotal: action.secondaryTotal
      }
      break;
    case 'SET_REJECTED_TASK':
      return {
        ...state,
        rejectedTask: action.rejectedTask,
      };
      break;
    case 'SET_UPDATE_LEFT_SIDE_NAV':
      return {
        ...state,
        updateLeftSideNav: action.updateLeftSideNav,
      };
      break;
    case 'SET_NOTIFICATION':
      return {
        ...state,
        notification: action.notification,
      };
      break;
    case 'SET_VERSION_UPLOAD':
      return {
        ...state,
        versionUpload: action.versionUpload,
      };
    case 'SET_TASK_UPLOAD':
      return {
        ...state,
        taskUpload: action.taskUpload,
      };
      break;
    case 'SET_SIDE_BAR_OPEN':
      return {
        ...state,
        sideBarOpen: action.sideBarOpen,
      };
    case 'DEPEDENCIES_UPDATED':
      return {
        ...state,
        dependencyQueryUpdate: state.dependencyQueryUpdate + 1,
      };
      break;
    case 'ADD_NEW_TAG':
      return {
        ...state,
        addedTags: [...state.addedTags, action.addedTag],
      };
      break;
    case 'ADD_NEW_CLASSIFICATION':
      return {
        ...state,
        addedClassifications: [...state.addedClassifications, action.addedClassification],
      };
      break;
    case 'SET_IS_SPACE_UPDATED':
      return {
        ...state,
        isSpaceUpdated: action.isUpdated,
      };
    case 'SET_CREATE_SPACE_MODAL_OPEN':
      return {
        ...state,
        createModalOpen: action.isOpen,
      };
      break;
    case 'SET_USER_SPACE_PERMISSIONS':
      return {
        ...state,
        userSpacePermissions: action.userSpacePermissions,
      };
      break;
    case 'SET_MODIFY_SPACE_OBJECT_MODAL':
      return {
        ...state,
        modifySpaceObjectModalState: action.modifySpaceObjectModalState,
      };
      break;
    case 'SET_SHARE_MODAL_STATE':
      return {
        ...state,
        shareModalState: action.shareModalState,
      };
      break;
    case 'SET_FILE_UPLOAD_STATUS_STATE':
      return {
        ...state,
        fileUploadStatusState: action.fileUploadStatusState,
      };
      break;
    case 'SET_FILE_DOWNLOAD_STATUS_STATE':
      return {
        ...state,
        fileDownloadStatusState: action.fileDownloadStatusState,
      };
    case 'SET_SELECTED_TASK':
      return {
        ...state,
        selectedTask: action.selectedTask
      }
      break;
    case 'SET_BREADCRUMB_IDS':
      return {
        ...state,
        breadcrumbIds: action.breadcrumbIds
      }
      break;
    case 'SET_OKTA_SIGN_IN_RESPONSE':
      return {
        ...state,
        oktaSignInResponse: action.oktaSignInResponse
      }
      break;
    case 'SET_CURRENT_OKTA_INTEGRATION':
        return {
          ...state,
          currentOktaIntegration: action.currentOktaIntegration
        }
      break;
    case 'SET_OKTA_CONFIG_IS_READY':
        return {
          ...state,
          oktaConfigIsReady: action.oktaConfigIsReady
        }
      break;
    case 'SET_EXCEL_BATCH_FILES_UPLOADED':
      return {
        ...state,
        excelBatchFilesUploaded: action.excelBatchFilesUploaded,
      };
    case 'SET_QUERY_TASKS_FILTERS':
      return {
        ...state,
        queryTasksFilters: action.queryTasksFilters
      }
    case 'SET_FORCE_REFRESH_TASKS':
      return {
        ...state,
        forceRefreshTasks: action.forceRefreshTasks
      }
    case 'SET_FORCE_REFRESH_SKINNY_LISTS':
      return {
        ...state,
        forceRefreshSkinnyLists: action.forceRefreshSkinnyLists
      }

    case 'SET_LIST_CUSTOM_TASK_STATUSES':
      return {
        ...state,
        listCustomTaskStatuses: action.listCustomTaskStatuses
      }
    case 'SET_LIST_GROUPED_CUSTOM_TASK_STATUSES':
      return {
        ...state,
        listGroupedCustomTaskStatuses: action.listGroupedCustomTaskStatuses
      }
    default:
      return state;
  }
};

const initialState: State = {
  selectedCompany: '',
  sideBarOpen: true,
  currentUser: {
    __typename: 'User',
    id: '',
    email: '',
    fullName: '',
    displayName: '',
    profileUrl: '',
    lastViewedCompanyId: '',
    ownedCompanies: [],
    allCompanies: [],
    companyRole: 0,
    companies: [],
    teams: [],
    roles: [],
    notifications: [],
  },
  userLists: [],
  userSelectedLists: [],
  userSelectedSections: [],
  selectedListIds: null,
  selectedSectionIds: null,
  notification: {
    variant: 'success',
    message: '',
  },
  taskUpload: {
    taskId: '',
    listId: '',
    taskStatus: '',
    reconTag: '',
  },
  versionUpload: null,
  rejectedTask: null,
  updateLeftSideNav: false,
  dependencyQueryUpdate: 1,
  addedTags: [],
  addedClassifications: [],
  // currentSpace: '',
  isSpaceUpdated: false,
  createModalOpen: false,
  spaceAccessLevel: 'owner',
  taskPageGrouping: 'section',
  modifySpaceObjectModalState: {
    isModalOpen: false,
    typeOfModification: null,
    objectToModify: null,
    objectType: null,
  },
  userSpacePermissions: {
    create: true,
    edit: true,
    view: true,
    share: true,
    download: true,
    manage: true,
    collaborate: true,
    build: true,
  },
  shareModalState: {
    isModalOpen: false,
    objectToShare: undefined,
    objectType: undefined,
  },
  fileUploadStatusState: {
    isOpen: false,
    selectedTask: {},
    files: {},
  },
  fileDownloadStatusState: {
    isOpen: false,
    type: '',
    payload: {},
    downloadId: 0,
  },
  breadcrumbIds: [],
  tableSetAllFiltersRef: null,
  widgetHeight: 174,
  primaryTotal: {},
  secondaryTotal: {},
  messageLabelTypes: [],
  listLabelTypes: [],
  resolveObjects: [],
  timePeriods: [],
  sidePanelMessagePaneRef: {},
  selectedTask: {},
  navigateToMessage: '',
  oktaSignInResponse: undefined,
  currentOktaIntegration: undefined,
  oktaConfigIsReady: null,
  queryTasksFilters: {},
  excelBatchFilesUploaded: null,
  forceRefreshTasks: '',
  forceRefreshSkinnyLists: '',
  listCustomTaskStatuses: [],
  listGroupedCustomTaskStatuses: {}
};

type ContextType = {
  state: State;
  dispatch: (action: Action) => void;
};

type DispatchContextType = (action: Action) => void;
type StateContextType = State;

const DispatchContext = createContext<DispatchContextType>(() => undefined);
const StateContext = createContext<StateContextType>(initialState);

const Context = createContext<ContextType>({
  dispatch: () => undefined,
  state: initialState,
});

export const Provider = (props: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        {props.children}
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export const useDispatchContext = () => {
  return useContext(DispatchContext);
};

export const useStateContext = () => {
  return useContext(StateContext)
}
