import React, { useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import ProjectOptionGroup from '../../common/ProjectOptionsGroup';
import { IProject, IProjectOptionsSelected, IProjectType, INewCompanyType } from '../../common/interfaces';
import ExternalCompany from '../../common/ExternalCompany';
import ExternalUsers from '../../common/ExternalUsers';
import idx from 'idx';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: '0px 50px',
			overflow: 'auto scroll',
			'&::-webkit-scrollbar': {
				width: '0.6em',
				height: '0.6em',
			}
		},
		flex: {
			display: 'flex',
		},
		align: {
			justifyContent: 'center',
			alignItems: 'center',
		},
		container: {
			height: '100%',
			width: '100%',
		},
		column: {
			flexDirection: 'column',
		},
		row: {
			flexDirection: 'row',
		},
		t1: {
			fontSize: "22px",
			lineHeight: "27px",
			color: "#2C2C2C",
			opacity: 1,
			fontWeight: "normal",
		},
		t2: {
			fontSize: "18px",
			lineHeight: "22px",
			color: "#707070",
			opacity: 1,
			fontWeight: "bold",
		},
		margin: {
			margin: 10
		},
		typoMargin: {
			margin: '20px 0px',
		}
	})
);

interface IProjectsType {
	projectOptions: IProjectType,
	projectOptionsSelected: IProjectOptionsSelected,
	isAdmin: boolean,
	externalCompanyId: string,
	onOptionChanged(selectedOption: string): void,
	newTemplate: IProject,
	setNewTemplate: React.Dispatch<React.SetStateAction<IProject>>;
	inviteCompany: INewCompanyType,
	setInviteCompany: React.Dispatch<React.SetStateAction<INewCompanyType>>,
	additionalExternalOwners: any[];
	setAdditionalExternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function ProjectType(props: IProjectsType) {
	const classes = useStyles();
	const { projectOptions, projectOptionsSelected, isAdmin, externalCompanyId, onOptionChanged, newTemplate, setNewTemplate, inviteCompany, setInviteCompany,
		additionalExternalOwners, setAdditionalExternalOwners
	} = props;

	return (
		<div className={clsx(classes.root, classes.container)}>
			<div className={clsx(classes.flex, classes.align)}>
				<Typography className={clsx(classes.t1, classes.margin)}>What type of project are you creating?</Typography>
			</div>
			<ProjectOptionGroup projectOptions={projectOptions} onOptionChange={onOptionChanged} selectedValue={projectOptionsSelected.projectType} />
			{ (projectOptionsSelected.projectType === projectOptions.request.value || (projectOptions.share && projectOptionsSelected.projectType === projectOptions.share.value)) &&
				<div className={clsx(classes.flex, classes.row)} style={{ justifyContent: 'space-evenly' }}>
					<div className={clsx(classes.flex, classes.column)} style={{ alignItems: 'center' }}>
						<div className={clsx(classes.flex, classes.column)}>
							<Typography className={clsx(classes.typoMargin, classes.t2)}>External Company*</Typography>
							<ExternalCompany sharing={projectOptionsSelected.projectType} newTemplate={newTemplate} setNewTemplate={setNewTemplate}
								inviteCompany={inviteCompany} setInviteCompany={setInviteCompany} showWarning={false}
								additionalExternalOwners = {additionalExternalOwners} setAdditionalExternalOwners = {setAdditionalExternalOwners}
								/>
						</div>
					</div>
					<div className={clsx(classes.flex, classes.column)} style={{ alignItems: 'center' }}>
						<div className={clsx(classes.flex, classes.column)}>
							<Typography className={clsx(classes.typoMargin, classes.t2)}>External Project Owners*</Typography>
							<ExternalUsers newTemplate={newTemplate} setNewTemplate={setNewTemplate} externalCompanyId={externalCompanyId} isAdmin={isAdmin}
							additionalExternalOwners = {additionalExternalOwners} setAdditionalExternalOwners = {setAdditionalExternalOwners}
							/>
						</div>
					</div>
				</div>
			}
		</div>
	)
}