import React from 'react';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import { Typography } from '@material-ui/core';
import { useStyles } from './Style';
import { displayCurrency } from '../../constants/currency';
import {Tooltip} from '@material-ui/core';

interface MoneyDisplayProps {
  value: string | number;
  style?: any;
  displaySymbol?: boolean; // change this to displaySymbol? and change it in every place in React.
  customSymbol?: string; // if this is passed, show it instead of a dollar sign
  isPercentage?: boolean;
  canConvertToMBT?: boolean; // if this is passed, show the number in millions, billions, or trillions. Ex: 118M, 67B, 10T.
};

export function MoneyDisplay(props: MoneyDisplayProps) {
  const classes = useStyles();
  const isNegative = Number(props.value) < 0;

  const getCurrency = props.customSymbol ? displayCurrency(props.customSymbol) : props.displaySymbol ? '$' : '';
  
  const MONEY_UNITS = ['M', 'B', 'T'];

  /**
   * Formats the given balance number and returns it as a string representation.
   * If the number is less than 100 million, it adds commas as thousand separators.
   * If the number is between 100 million and 1 billion, it converts it to millions with one decimal place and appends the corresponding money unit.
   * If the number is between 1 billion and 1 trillion, it converts it to billions with one decimal place and appends the corresponding money unit.
   * If the number is greater than or equal to 1 trillion, it converts it to trillions with one decimal place and appends the corresponding money unit.
   *
   * @param {number} n - The balance number to be formatted.
   * @returns {string} - The formatted string representation of the balance.
   */
  const formatBalance = (n: number) => {
    // Format number with commas as thousand separators
    if (n < 1e8) return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Convert to millions and append money unit
    if (n >= 1e8 && n < 1e9) return +(n / 1e6).toFixed(1) + MONEY_UNITS[0];
    
    // Convert to billions and append money unit
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + MONEY_UNITS[1];
    
    // Convert to trillions and append money unit
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + MONEY_UNITS[2];
  };

  /**
   * Formats the given value as a number and returns it as a string representation.
   * If the value is provided and positive, it formats it using the formatBalance function.
   * If the value is provided and negative, it formats its absolute value using the formatBalance function and wraps it in parentheses.
   * If the value is not provided, it returns the value as is.
   *
   * @returns {string} - The formatted string representation of the number.
   */
  const formatNumber = (): string => {
    if (props.value) {
      let formattedValue;
      if (Number(props.value) < 0) {
        // Format negative value with parentheses
        formattedValue = '(' + formatBalance(-1 * Number(props.value)) + ')';
      } else {
        // Format positive value
        formattedValue = formatBalance(Number(props.value));
      }
      
      // Concatenate currency symbol and formatted value
      return getCurrency + ' ' + formattedValue;
    }

    return props.value as string;
  }

  const numberHandler = (shouldTransform: boolean) => (
    <NumberFormat
      className={clsx(classes.input)}
      style={{cursor: 'default', whiteSpace:'nowrap', color: '#606060', display: 'flex', ...props.style}}
      value={Math.abs(Number(props.value))}
      thousandSeparator
      decimalScale={2}
      displayType='text'
      fixedDecimalScale
      prefix={isNegative ? `${getCurrency} (` : `${getCurrency} `}
      suffix={isNegative && props.isPercentage ? ' %)' : isNegative ? ')' : props.isPercentage ? ' %' : ''}
      renderText={(value: any) => <div>{shouldTransform ? (props.canConvertToMBT ? formatNumber() : value) : value}</div>}
    />
  )

  return Number(props.value)
    ? 
    <Tooltip title={props.canConvertToMBT ? MONEY_UNITS.some((el: any) => formatNumber().includes(el)) ? <span className='flex'>{numberHandler(false)}</span> : '' : ''}>
      <span className='flex'>{numberHandler(true)}</span>
    </Tooltip>
    : <Dash getCurrency={getCurrency} customSymbol={props.customSymbol} />
}

interface DashProps {
  customSymbol?: string;
  getCurrency?: string;
};

const Dash = (props: DashProps) => {
  const classes = useStyles();
  const { customSymbol, getCurrency } = props;
  
  return (
    <Typography className={clsx(classes.input)}>
      {`${customSymbol ? getCurrency : '$'}\u00A0\u00A0\u00A0\u00A0\u00A0-\u00A0\u00A0\u00A0\u00A0\u00A0`}
    </Typography>
  );
}
