import {createMutationHook, gql} from '../graphqlHelpers';

export const useCreateMessageLabelType = createMutationHook(gql`
  mutation CreateMessageLabelType (
    $name: String!,
    $color: String!
  ) {
    createMessageLabelType(name: $name, color: $color){
      success
      id
    }
  }
`);

