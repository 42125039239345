import React, { Fragment } from 'react';
import { MoneyDisplay } from '../MoneyDisplay';

type FluxGrowthProps = {
  growthValue: number;
  varianceGrowthIsLong?: boolean;
};

/**
 * `FluxGrowth` component reusable anywhere accross the app.
 * 
 * @return {React.JSXElementConstructor} The `FluxGrowth` component.
 */
export default function FluxGrowth(props: FluxGrowthProps) {
  const { growthValue, varianceGrowthIsLong } = props
  const growthFontSize = varianceGrowthIsLong ? 9 : 10

  return (
    <Fragment>
      { growthValue ? (
          growthValue >= 0 ?
          (<span className="flex items-center font-bold ml-auto text-[#80B63E]" style={{ fontSize: growthFontSize }}>
            <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 5L5 1.5M5 1.5L8.5 5M5 1.5V10.5" stroke="#80B63E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span className='pl-1'>
              {growthValue !== 0 ? <span className='flex'>{
                <MoneyDisplay 
                  value={growthValue} 
                  style={{ fontWeight: 700, fontSize: growthFontSize }}
                  isPercentage
                  canConvertToMBT
                /> 
              } %</span> : <span className='px-5'>-</span>}
            </span>
          </span>) :
          (<span className='flex items-center font-bold ml-auto text-[#EC5E5E]' style={{ fontSize: growthFontSize }}>
            <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.5 7L5 10.5M5 10.5L1.5 7M5 10.5L5 1.5" stroke="#EC5E5E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {growthValue !== 0 ? <span className='pl-1 flex'>{
              <MoneyDisplay 
                value={growthValue} 
                style={{ fontWeight: 700, fontSize: growthFontSize }}
                isPercentage
                canConvertToMBT
              /> 
            } %</span> : <span className='px-5'>-</span>}
          </span>)
      ) : (
        <span className='flex items-center font-bold ml-auto text-gray-300' style={{ fontSize: growthFontSize }}>
          <span className='pl-1'>
            <span className=''>0 %</span>
          </span>
        </span>
        )}
    </Fragment>
  );
}
