import { createMutationHook, gql } from '../graphqlHelpers';

const useLogFileDownload = createMutationHook(gql`
  mutation LogFileDownload(
    $id: ID!
    $filename: String!
    $projId: ID, 
    $taskId: ID
    $workspaceId: ID
  ) {
    logFileDownload(
      id: $id
      filename: $filename, 
      projId: $projId, 
      taskId: $taskId
      workspaceId: $workspaceId
    ) {
      success
    }
  }
`);

export default useLogFileDownload;
