import React, { useEffect, useRef, useState } from "react";
import { PencilIcon, TrashIcon, XMarkIcon, CheckIcon } from "@heroicons/react/24/outline";
import { classNames, measureTextInPixel } from "../../helpers/helpers";
import { randomColors } from "../../constants/theme";
import { ChromePicker } from "react-color";
import { useCreateMessageLabelType } from "../../graphql/mutations/CreateMessageLabelType";
import { useUpdateMessageLabelType } from "../../graphql/mutations/UpdateMessageLabelType";
import { useDeleteMessageLabelType } from "../../graphql/mutations/DeleteMessageLabelType";
import { useStateContext } from "../../store";
import idx from 'idx';
import { useAddUpdateEntityLabelType } from "../../graphql/mutations/AddUpdateEntityLabelType";
import { useDeleteEntityLabelType } from "../../graphql/mutations/DeleteEntityLabelType";

type ComponentProps = {
  labels: any[];
  className?: string;
  onLabelTagsChanged(newId?:string): void,
  entityType?: string
};

type onSaveLabelPayload = {
  id: string;
  oldName: string;
  newName: string;
  oldColor: string;
  newColor: string;
}

/** Component to customize labels
 * @param `props.labels` - The component attribute that contains all existing labels
 * @param `props.className` - The component attribute that contains the root class name
 * @param `props.onLabelTagsChanged` - The component function to call when the labels tags changed like added/edited/removed
 * @return {React.JSXElementConstructor} The output component.
 */
export default function CustomizeMessageLabels(props: ComponentProps) {
  const [newLabelIsActive, setNewLabelIsActive] = useState<boolean>(false);
  const [newLabel, setNewLabel] = useState<any>(null);
  const [allLabels, setAllLabels] = useState<any[]>([]);
  const [userCanDelete, setUserCanDelete] = useState<boolean>(false);
  const [createMessageLabelType] = useCreateMessageLabelType({});
  const [updateMessageLabelType] = useUpdateMessageLabelType({});
  const [deleteMessageLabelType] = useDeleteMessageLabelType({});
  const [addUpdateEntityLabelType] = useAddUpdateEntityLabelType({});
  const [deleteEntityLabelType] = useDeleteEntityLabelType({});
  const state = useStateContext();
  const userRole = idx(state, state => state.currentUser.companyRole);
  
  useEffect(() => {
    setUserCanDelete(userRole <= 3);
  }, [userRole]);

  useEffect(() => {
    if (props.labels) {
      setAllLabels(props.labels.map((it:any) => {
        return {...it, editing: false, canEdit: true, canDelete: userCanDelete}
      }));
    }
  }, [props.labels, userCanDelete]);

  const getGqlTypeName = () => {
    if (props.entityType === "Message") {
      return "MessageLabel";
    } else if (props.entityType === "List") {
      return "EntityLabel";
    }
  }

  const checkLabelExit = (name: string) => {
    return allLabels.some((it:any) => it.name.toLowerCase() === name.toLowerCase())
  }

  const onClickAddNewLabel = () => {
    const random = Math.floor(Math.random() * randomColors.length);
    setNewLabel({id: null, name: '', color: randomColors[random], __typename: getGqlTypeName()});
    setNewLabelIsActive(true);
  }

  const onCancelEdit = (id: string, name: string) => {
    setNewLabelIsActive(false);
  }

  const onSave = (payload: onSaveLabelPayload) => {
    const { id, oldName, newName, oldColor, newColor } = payload;
    if (!id) {
      // new label
      if (!checkLabelExit(newName)) {
        if (props.entityType === "Message") {
          createMessageLabelType({
            variables: {
              name: newName,
              color: newColor
            }
          }).then((response: any) => {
            setNewLabelIsActive(false);
            if (response?.data?.createMessageLabelType?.success) {
              props.onLabelTagsChanged();
              const newItem = {id: response?.data?.createMessageLabelType?.id, name: newName, color: newColor,  __typename: "MessageLabel", canEdit: true, canDelete: userCanDelete};
              setAllLabels([{...newItem}, ...allLabels]);
            }
          });
        } else if (props.entityType === "List") {
          addUpdateEntityLabelType({
            variables: {
              name: newName,
              color: newColor,
              etype: "List"
            }
          }).then((response: any) => {
            setNewLabelIsActive(false);
            if (response?.data?.addUpdateEntityLabelType?.success) {
              props.onLabelTagsChanged();
              const newItem = {id: response?.data?.addUpdateEntityLabelType?.id, name: newName, color: newColor,  __typename: "EntityLabel", canEdit: true, canDelete: userCanDelete};
              setAllLabels([{...newItem}, ...allLabels]);
            }
          });
        }
      } else {
        setNewLabelIsActive(false);
      }
    } else {
      // Update label
      if (oldName !== newName || oldColor !== newColor) {
        // check label already exist
        if (oldName !== newName ? !checkLabelExit(newName) : true) {
          if (props.entityType === "Message") {
            updateMessageLabelType({
              variables: {
                id: id,
                name: newName,
                color: newColor
              }
            }).then((response: any) => {
              if (response?.data?.updateMessageLabelType?.success) {
                props.onLabelTagsChanged();
                setAllLabels(allLabels.map((it:any) => {
                  if (it.id === id) {
                    return { ...it, name: newName, color: newColor}
                  }
                  return {...it}
                }));
              }
            });
          } else if (props.entityType === "List") {
            addUpdateEntityLabelType({
              variables: {
                id: id,
                name: newName,
                color: newColor,
                etype: "List"
              }
            }).then((response: any) => {
              if (response?.data?.addUpdateEntityLabelType?.success) {
                props.onLabelTagsChanged();
                setAllLabels(allLabels.map((it:any) => {
                  if (it.id === id) {
                    return { ...it, name: newName, color: newColor}
                  }
                  return {...it}
                }));
              }
            });
          }
        }
      }
    }
  }

  const onDelete = (id: string) => {
    if (props.entityType === "Message") {
      deleteMessageLabelType({
        variables: {
          id
        }
      }).then((response: any) => {
        if (response?.data?.deleteMessageLabelType?.success) {
          props.onLabelTagsChanged();
          setAllLabels(allLabels.filter((it:any) => it.id !== id));
        }
      });
    } else if (props.entityType === "List") {
      deleteEntityLabelType({
        variables: {
          id
        }
      }).then((response: any) => {
        if (response?.data?.deleteEntityLabelType?.success) {
          props.onLabelTagsChanged();
          setAllLabels(allLabels.filter((it:any) => it.id !== id));
        }
      });
    }
  }

  return (
    <div data-cy="msg-labels-customize-container" className="mt-2 max-h-[400px] overflow-auto">
      {newLabelIsActive ?
        <CustomizeLabelForm id={newLabel.id} name={newLabel.name} color={newLabel.color} isAddNew={true} canEdit={true} canDelete={true} onCancel={onCancelEdit} onSave={onSave} onDelete={onDelete} />
        : <div data-cy="add-new-label" className="px-2 py-[0.62rem] hover:bg-gray-180 cursor-pointer" onClick={onClickAddNewLabel}>+ Add a new label</div>
      }
      {allLabels.map((label:any) => {
        return <CustomizeLabelForm key={label.name} id={label.id} name={label.name} color={label.color} canEdit={label.canEdit} canDelete={label.canDelete} onCancel={onCancelEdit} onSave={onSave} onDelete={onDelete} />
      })}
    </div>
  );
}

type CustomizeLabelFormProps = {
  id: string;
  name: string;
  color: string;
  isAddNew?: boolean;
  canEdit: boolean;
  canDelete: boolean;
  onCancel(id: string, name: string): void;
  onSave(payload: onSaveLabelPayload): void;
  onDelete(id: string): void;
}
const CustomizeLabelForm: React.FC<CustomizeLabelFormProps> = ({id, name, color, isAddNew, canEdit, canDelete, onCancel, onSave, onDelete}) => {
  const inputLabelRef: any = useRef<any>(null);
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const [colorPickerColor, setColorPickerColor] = useState<string>("");
  const [tempLabelEdited, setTempLabelEdited] = useState<string>("");
  const [tempLabelColor, setTempLabelColor] = useState<string>("");
  const [editing, setEditing] = useState<boolean>(isAddNew || false);
  const [showConfirmDeletetion, setShowConfirmDeletetion] = useState<boolean>(false);

  useEffect(() => {
    if (editing) {
      setAutoHeightLabelInput();
      setColorPickerColor(color);
      setTempLabelEdited("");
      setTempLabelColor("");
      inputLabelRef.current.value = name;
      inputLabelRef.current.focus();
    }
  }, [editing]);

  const onFocusInput = (e:any) => {
  }

  const setAutoHeightLabelInput = () => {
    inputLabelRef.current.style.height = "20px";
    inputLabelRef.current.style.height = inputLabelRef.current.scrollHeight +"px";
    inputLabelRef.current.style['line-height'] = "1.25";
    inputLabelRef.current.style.resize = "none";
    inputLabelRef.current.style.overflow = "hidden";
  }

  const onInputChange = (e:any) => {
    setAutoHeightLabelInput();
  }

  const onInputKeyUp = (event:any) => {
    // enter key + shift 
    if (event.keyCode === 13 && event.shiftKey) {
      return;
    }

    // enter key
    if (event.keyCode === 13) {
      event.preventDefault();
      onClickSave({id, oldName: name, newName: inputLabelRef.current.value, oldColor:color, newColor: colorPickerColor});
    }
  }

  const onInputKeyDown = (event:any) => {
    // enter key
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  const onClickEdit = (e: any) => {
    setColorPickerColor(color);
    setEditing(true);
  }

  const onCancelEdit = (e:any) => {
    setEditing(false);
    onCancel(id, name);
  }

  const onCancelDelete = (e:any) => {
    setShowConfirmDeletetion(false);
  }

  const onClickDelete = () => {
    setShowConfirmDeletetion(true);
  }

  const onConfirmDelete = (id: string) => {
    onDelete(id);
    setShowConfirmDeletetion(false);
  }

  const onClickSave = (payload: onSaveLabelPayload) => {
    payload.newName = payload.newName.trim().replace(/\n/g, "");
    onSave(payload);
    setTempLabelEdited(payload.newName);
    setTempLabelColor(payload.newColor);
    setEditing(false);
  }

  const onClickLabelColor = () => {
    setDisplayColorPicker(true);
  }

  const onCloseLabelColor = (e:any) => {
    setDisplayColorPicker(false);
  }

  const handleColorChange = (color: any, event: any) => {
    setColorPickerColor(color.hex);
  }

  const handleKeyDown = (event:any) => {
    if (event.keyCode === 13) {
      if (displayColorPicker) {
        setDisplayColorPicker(false);
      }
    }
  }

  return <div tabIndex={-1} onKeyDown={handleKeyDown} data-cy="customize-label-item" className="flex items-center justify-between p-2 hover:bg-gray-180 focus:outline-none focus:ring-0">
      <div className="flex items-center">
        <span
          style={{ backgroundColor: editing ? colorPickerColor : (tempLabelColor || color)}}
          className={classNames(editing ? "cursor-pointer": "", "inline-block h-6 w-6 flex-shrink-0 rounded-full")}
          aria-hidden="true"
          onClick={() => editing ? onClickLabelColor(): null}
        />
        { displayColorPicker ? <div className="absolute z-[2] top-[3rem] left-[4rem]">
          <div className="fixed top-0 right-0 bottom-0 left-0" onClick={ onCloseLabelColor }/>
          <ChromePicker color={colorPickerColor} onChange={handleColorChange} disableAlpha={true} />
          </div> : null }
        {editing ? 
          <textarea
            rows={1}
            ref={inputLabelRef}
            defaultValue={name}
            className="resize-none border-0 font-semibold text-xs ml-2 pl-0 py-0 pt-[2px] w-[20rem] text-gray-500 bg-transparent focus:border-b focus:border-b-blue-100"
            placeholder={!name ? "enter label name..." : ""}
            onFocus={onFocusInput}
            onChange={onInputChange}
            onKeyUp={onInputKeyUp}
            onKeyDown={onInputKeyDown}
          /> : <div className="flex flex-col items-start justify-start">
              <div className={classNames(showConfirmDeletetion ? "line-through text-red-600" : "", canDelete ? "max-w-[325px]" : "max-w-[350px]", "font-semibold text-xs ml-2 block truncate")} title={measureTextInPixel(tempLabelEdited || name, 12) > (canDelete ? 315 : 335) ? tempLabelEdited || name : ""}>
                {tempLabelEdited || name}
              </div>
              { showConfirmDeletetion ? <div className="text-xs font-semibold ml-2 text-red-600">Delete label?</div> : null}
            </div>
        }
      </div>
      <div className="flex items-center focus:outline-none transition ease-in duration-100">
        {showConfirmDeletetion && <>
          <div
            data-cy={`actions-message-confirm-delete`}
            title="Confirm"
            onClick={() => onConfirmDelete(id)}
          >
            <CheckIcon className="mx-1 h-4 w-4 cursor-pointer text-red-600 hover:text-red font-bold" aria-hidden="true" />
          </div>
          <div
            data-cy={`actions-message-cancel-delete`}
            title="Cancel"
            onClick={onCancelDelete}
          >
            <XMarkIcon className="mx-1 h-4 w-4 cursor-pointer text-gray-400 hover:text-black" aria-hidden="true" />
          </div>
        </>
        }
        {editing && <>
          <div
            data-cy={`actions-message-save`}
            title="Save"
            onClick={() => onClickSave({id, oldName: name, newName: inputLabelRef.current.value, oldColor:color, newColor: colorPickerColor})}
          >
            <CheckIcon className="mx-1 h-4 w-4 cursor-pointer text-gray-400 hover:text-black" aria-hidden="true" />
          </div>
          <div
            data-cy={`actions-message-cancel-edit`}
            title="Cancel"
            onClick={onCancelEdit}
          >
            <XMarkIcon className="mx-1 h-4 w-4 cursor-pointer text-gray-400 hover:text-black" aria-hidden="true" />
          </div>
        </>
        }
        {(!editing && !showConfirmDeletetion && canEdit) &&
          <div
            data-cy={`actions-message-edit`}
            title="Edit"
            onClick={onClickEdit}
          >
            <PencilIcon className="mx-1 h-4 w-4 cursor-pointer text-gray-400 hover:text-black" aria-hidden="true" />
          </div>
        }
        {(!editing && !showConfirmDeletetion && canDelete) &&
          <div
            data-cy={`actions-message-delete`}
            title="Delete"
            onClick={() => onClickDelete()}
          >
            <TrashIcon className="mx-1 h-4 w-4 cursor-pointer text-gray-400 hover:text-black" aria-hidden="true" />
          </div>
        }
      </div>
  </div>
}
