import {createQueryHook, gql} from '../graphqlHelpers';
//import {ListFiles, ListFilesVariables} from './__generated__/ListFiles';

const taskFiles = createQueryHook<any, any>(gql`
  query TaskFiles($id: ID!) {
    task(id: $id) {
      id
      files {
        id
        fileName
        fileLocation
        alterable
        createdAt
        updatedAt
      }
    }
  }
`);

export default taskFiles
