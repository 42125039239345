import {gql} from '../graphqlHelpers';
import { useQuery } from '@apollo/client';

// This Query will return the `TaskDataSummary` matching the current viewing task.
// The returned result with have the following format and properties.
const query = gql`
  query TaskDataSummary($taskId: ID!) {
    taskDataSummary(taskId: $taskId) {
      id
      primaryReconId
      primaryReconName
      primaryReconValue
      primarySourceType
      secondaryReconId
      secondaryReconName
      secondaryReconValue
      secondarySourceType
      primarySecondaryDifferenceValue
      primarySecondaryDifferencePercentage
      reconcilingItemId
      reconcilingItemName
      reconcilingItemValue
      reconcilingDifferenceValue
      reconcilingDifferencePercentage
      priorMonthId
      priorMonthValue
      priorMonthDifference
      priorMonthPercentage
      priorQuarterId
      priorQuarterValue
      priorQuarterDifference
      priorQuarterPercentage
      priorYearId
      priorYearValue
      priorYearDifference
      priorYearPercentage
      customAnalysisId
      customAnalysisValue
      customAnalysisDifference
      customAnalysisPercentage
      finalRecon
      isCurrent
      companyId
      listId
      taskId
      sectionId
      createdAt
      updatedAt
    }
  }
`

export const useLazyTaskDataSummaryQuery = (taskId: any) => {
  return useQuery(query, { variables: { taskId: taskId }});
}
