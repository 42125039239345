import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatchContext } from '../store';
import { useSetFilters } from './SetFilters';

interface Notification {
  id: string | null;
  notifiableId: string;
  notifiableType: string;
  pane: number;
  url: string;
  notifiable?: any;
}

/* Used to take a notification object and navigate to the correct
 * place in the app
 * The interface will make sure that the object passed has the correct properties
 */
export function useNotificationNavigation() {
  // send a gql mutation to update the BE of the users' new viewing/filter state
  // update the global store to reflect the choices.
  // navigate (if need be) to a new page
  const dispatch = useDispatchContext();
  const history = useHistory();
  const [notification, setNotification] = useState<any>(null)
  // eslint-disable-next-line
  const setFilters = useSetFilters();

  useEffect(() => {
    if (!notification) { return; }
    setFilters(notification, { resetTaskFilters: true});
  }, [notification])

  /* Function which encloses our hooks above */
  return function notificationNavigator(notification: Notification) {
    
    const parsedFilters = parseForFilters(notification);
    // If // If not, we just want to change the url
    if (parsedFilters) {
      setNotification(parsedFilters);

      dispatch({
        type: 'SET_USER_FILTERS',
        filters: parsedFilters
      });

      if (parsedFilters?.taskPageLists) {
        dispatch({
          type: 'UPDATE_PROJECT_SELECTION_OPTONS',
          selectedListIds: [parsedFilters?.taskPageLists[0]],
          selectedSectionIds: null
        })
      }
    }
    
    // Get message ID from notification url in order to redirect directly to this message
    dispatchMessageIdToNavigate(notification, dispatch);
    // remove url params, which might be included for external navigation.
    if (notification.url.includes('https')){
      window.open(notification.url, "_blank");
    } else {
      history.push(notification.url.split('?')[0])
    }
  }
}

/* HELPERS */
/* take the notification object and parses it into the format(s) required
 * to update the user's filters */
function parseForFilters(notification: Notification) {
  var parsedFilters: any
  // easiest way to define which filters we need is to, determine which
  // notifiableType the notification is.
  switch (notification.notifiableType) {
    case 'List': {
      parsedFilters = {
        listPagePane: notification.pane,
        listPageSelectedLists: [notification.notifiableId]
      };
      break;
    } case 'Task': {
      // parsedFilters = {
      //   taskPageLists: [notification.notifiable.listId],
      //   taskPageSelectedTasks: [notification.notifiableId],
      //   taskPagePane: notification.pane
      // };
      parsedFilters = {
        taskPageLists: [notification.notifiable.listId],
        customTaskPageSelectedTaskIds: [notification.notifiableId],
        taskPagePane: notification.pane
      }
      break;
    }
  }
  return parsedFilters;
}

function dispatchMessageIdToNavigate(notification: any, dispatch: any) {
  // If 'msgId=' query params exist in URL, dispatch the message id into the store
  const list = notification.url.split('&');
  const msg = list.find((msg: string) => msg.includes('msgId='));
  
  if (msg) {
    const pane = list.find((pane: string) => pane.includes('pane='));
    const paneNumber = pane.substring(5);
    const msgId = msg.substring(6);

    dispatch({
      type: 'SET_NAVIGATE_TO_MESSAGE',
      navigateToMessage: paneNumber + '_-_' + msgId,
    });
  }
}
