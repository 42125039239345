import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import idx from 'idx';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ClickAwayListener,
  List,
  ListItem,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Check, ArrowDropDown } from '@material-ui/icons';
import { Header } from '../common/Header';
import { MoneyDisplay } from '../common/MoneyDisplay';
import { useReconSummary } from '../../graphql/queries/ReconSummary';
import { useSetFilters } from '../../hooks/SetFilters';
import { COLORS } from '../../constants/theme';
import { unique } from '../../helpers/helpers';
import { useStyles } from '../common/Style';
import UserImage from '../common/UserImage';
import { ReconPercent } from './ReconPercent';
import CircularLoader from "../common/CircularLoader";
import { sortingFunctionHelper } from './reconHelpers';
import EntityBadges from '../migration/TaskList/EntityBadges';
import { exportProjectSummary } from '../../api/lists';
import { useDispatchContext, useStateContext } from '../../store';
import _ from 'lodash';
import { useBulkUpdateIntacctRecons } from '../../graphql/mutations/BulkUpdateIntacctRecons'
import { Alert, Color } from '@material-ui/lab';
import { useLazyProgressReportQuery } from '../../graphql/queries/ProgressReport';
import { displayCurrency } from '../../constants/currency';

export function ReconSummary(props: any) {
  const dispatch = useDispatchContext();
  const ownerContainer = useRef(null);
  const sectionContainer = useRef(null);
  const [ownerData, setOwnerData] = useState<any>(null);
  const [sectionData, setSectionData] = useState<any>(null);

  useEffect(
    function () {
      if (ownerContainer.current && ownerData) {
        var key = ['rec', 'non']
        var initStackedBarChart = {
          draw: function (config: any) {
            var domEle = config.element;
            var stackKey = config.key;
            var data = config.data;
            const max = Math.max.apply(null, (data.map((d: any) => d.rec)))
            const max2 = Math.max.apply(null, (data.map((d: any) => d.non)))

            const cool = Math.max(max, max2);
            const h = [0, 30, 70, 100, 130, 160]
            const longest = Math.max.apply(null, (data.map((d: any) => d.date.length)));
            const offset = longest * 3.9;
            var margin = { top: 20, right: 20, bottom: 30, left: 50 };
            var width = 480 - margin.left - margin.right;
            var height = h[data.length];
            var xScale = d3.scaleLinear().rangeRound([0, width]) as any;
            var yScale = d3.scaleBand().rangeRound([height, 0]).padding(0.1);
            var yAxis = d3.axisLeft(yScale);
            var svg = d3.select('#' + domEle).append('svg')
              .attr('width', width + margin.left + margin.right)
              .attr('height', height + margin.top + margin.bottom)
              .append('g')
              .attr('transform', function () {
                if (!config.axis) { return 'translate(-42, 20)' }
                return 'translate(' + margin.left + ',' + margin.top + ')'
              });

            var stack = d3.stack()
              .keys(stackKey)
              .offset(d3.stackOffsetNone);

            var layers = stack(data);
            yScale.domain(data.map(function (d: any) { return d.date; }));
            xScale.domain([0, cool * 2]).nice();

            const ok: any = [COLORS.notification, '#f2f2f2']

            var layer = svg.selectAll('.layer')
              .data(layers)
              .enter().append('g')
              .attr('class', 'layer')
              .style('fill', function (d, i) { return ok[i] });

            layer.selectAll('rect')
              .data(function (d) { return d; })
              .enter().append('rect')
              // @ts-ignore
              .attr('y', function (d: any) { return yScale(d.data.date); })
              .attr('x', function (d: any) { return xScale(d[0]) + offset; })
              .attr('height', 24)
              .attr('width', function (d) { return xScale(d[1]) - xScale(d[0]) })

            layer.selectAll('text')
              .data(function (d) { return d; })
              .enter().append('text')
              .attr('x', function (d: any) { return xScale(d[0]) + offset + 8; })
              // @ts-ignore
              .attr('y', function (d: any) { return yScale(d.data.date) + 17; })
              .style('fill', 'white')
              .style('font-family', '"Montserrat", sans-serif')
              .style('font-weight', '800')
              .style('font-size', '10px')
              .text(function (d) {
                if (d[0] !== 0) {
                  return d.data.non
                } else {
                  if (d.data.rec > 0) { return d.data.rec } else if (Number(d.data.non)) { return d.data.non } else return '';
                }
              });

            if (config.axis) {
              svg.append('g')
                .attr('class', 'axis axis--y')
                .attr('transform', `translate(${offset},0)`)
                .call(yAxis);
              d3.selectAll('.axis>.tick>text')
                .each(function (d, i) {
                  d3.select(this).style('font-size', '10px')
                    .style('font-family', '"Montserrat", sans-serif');
                });

              d3.selectAll('.domain')
                .each(function (d, i) {
                  d3.select(this).style('fill', 'none')
                    .style('stroke', 'none');
                });

              d3.selectAll('.axis>.tick>line')
                .each(function (d, i) {
                  d3.select(this).style('fill', 'none')
                    .style('stroke', 'none');
                });
            }
          }
        }

        initStackedBarChart.draw({
          data: ownerData,
          key: key,
          element: 'owner-chart'
        });

        initStackedBarChart.draw({
          data: sectionData,
          key: key,
          element: 'section-chart',
          axis: true
        });
      }
    },
    [ownerData, ownerContainer.current])

  const classes = useStyles();

  const [refactor, setRefactor] = useState<boolean>(false);
  const sizeW = (document.getElementsByTagName('body')[0] as any).scrollWidth;
  useEffect(() => {
    if (window.innerHeight !== sizeW) setRefactor(true)
  }, [sizeW])

  const history = useHistory();
  /* TODO: Explain main state mgmt */
  const [listId, setListId] = useState<string>(
    idx(props, p => p.location.state.listId)
  );
  const reconSummary = useReconSummary({ listId });
  const listData = idx(reconSummary, (r: any) => r.data.list);

  const [getProjectProgressReport, {data: projectProgressReportData}] = useLazyProgressReportQuery({listId});
  /** If the user is on the requester side of the project, we need to
   *  only show the 'delivered' tasks */
  const isRequester = idx(listData, l => l.companyPosition === 'requester');
  const tasks = idx(listData, (l: any) => l.tasks.filter((t: any) =>
    isRequester
      ? Boolean(Number(t.reconciliations)) && t.responderDelivered
      : Boolean(Number(t.reconciliations))
  )) || [];
  const amountReconciled = tasks.filter((t: any) => t.isReconciled).length;
  const percentReconciled = (amountReconciled / tasks.length) * 100;

  // filter / sorting state variables:
  const [sectionFilter, setSectionFilter] = useState<string[]>([]);
  const [reconStatusFilter, setReconStatusFilter] = useState<string[]>([]);
  const [nameSort, setNameSort] = useState<string | boolean>(false);
  const [dueDateSort, setDueDateSort] = useState<string | boolean>(false);
  const [differenceSort, setDifferenceSort] = useState<string | boolean>(false);
  const [activeSort, setActiveSort] = useState<string | false>(false);
  const [primarySort, setPrimarySort] = useState<string | boolean>(false);
  const [secondarySort, setSecondarySort] = useState<string | boolean>(false);

  const updateNameSort = () => {
    var newSort;
    switch (nameSort) {
      case false: newSort = 'ASC'; break;
      case 'ASC': newSort = 'DESC'; break;
      case 'DESC': newSort = false; break;
      default: newSort = false;
    }
    setNameSort(newSort);
    setActiveSort(newSort ? 'name' : false);
  }

  async function downloadReport() {
    dispatch({
      type: 'SET_NOTIFICATION',
      notification: {
        variant: 'success',
        message: 'We have received your request and are preparing your report for export.',
      }
    });
    let url = await exportProjectSummary(listId)

    if (url) {
      window.open(url, '_blank');
    }
  }

  const updateDueDateSort = () => {
    var newSort;
    switch (dueDateSort) {
      case false: newSort = 'ASC'; break;
      case 'ASC': newSort = 'DESC'; break;
      case 'DESC': newSort = false; break;
      default: newSort = false;
    }
    setDueDateSort(newSort);
    setActiveSort(newSort ? 'dueDate' : false);
  }

  const updateDifferenceSort = () => {
    var newSort;
    switch (differenceSort) {
      case false: newSort = 'ASC'; break;
      case 'ASC': newSort = 'DESC'; break;
      case 'DESC': newSort = false; break;
      default: newSort = false;
    }
    setDifferenceSort(newSort);
    setActiveSort(newSort ? 'difference' : false);
  }

  const updatePrimarySort = () => {
    let newSort;
    switch (primarySort) {
      case false: newSort = 'ASC'; break;
      case 'ASC': newSort = 'DESC'; break;
      case 'DESC': newSort = false; break;
      default: newSort = false;
    }
    setPrimarySort(newSort);
    setActiveSort(newSort ? 'primary' : false);
  }

  const updateSecondarySort = () => {
    let newSort;
    switch (secondarySort) {
      case false: newSort = 'ASC'; break;
      case 'ASC': newSort = 'DESC'; break;
      case 'DESC': newSort = false; break;
      default: newSort = false;
    }
    setSecondarySort(newSort);
    setActiveSort(newSort ? 'secondary' : false);
  }

  // display state variables
  const [showSectionFilter, setShowSectionFilter] = useState<boolean>(false);
  const [showStatusFilter, setShowStatusFilter] = useState<boolean>(false);

  /* For section summary: flat array of unique sections */
  const sections = idx(tasks, (t: any) => {
    const mappedSections = t.map((i: any) => i.section && i.section.name)
      .filter((t: any) => Boolean(t));
    return unique(mappedSections);
  }) || [];

  /* For owner summary: a flat array of owners, fullName or email as id */
  const owners = idx(tasks, (task: any) => {
    const mappedOwners = task.map((i: any) => i.userOwners)
      .flat()
      .filter((u: any) => Boolean(u))
      .map((a: any) => a.fullName || a.email);

    return unique(mappedOwners);
  }) || [];

  //Determine if the project contains an Intacct Reconciliation
  const hasIntacctConnection = idx(listData, (listData) => {
    return listData.tasks.some((task:any) => {return task.primarySourceType === 3 || task.secondarySourceType === 3});
  })

  useEffect(() => {
    const d = owners.map((o: any, i: number) => {
      const ownerTasks = tasks.filter((t: any) =>
        t.userOwners.map((o: any) => o.fullName || o.email).includes(o)
      )
      const activeRecons = ownerTasks.filter((t: any) =>
        t.reconciliations
      )
      const activeReconsAmount = activeRecons.length;
      const reconciledTaskAmount = activeRecons.filter((t: any) =>
        t.isReconciled
      ).length
      const pro = tasks.map((i: any) => i.userOwners)
        .flat()
        .filter((u: any) => Boolean(u))
        .find((x: any) => x.email === o || x.fullName === o)

      return ({
        fullName: o,
        profileUrl: idx(pro, p => p.profileUrl),
        date: o,
        rec: reconciledTaskAmount,
        non: activeReconsAmount - reconciledTaskAmount
      })
    }).sort((one: any, two: any) => {
      one.tot = one.rec + one.non;
      two.tot = two.rec + two.non;
      if (one.tot > two.tot) return -1;
      if (one.tot < two.tot) return 1;

      if (one.date > two.date) return -1;
      if (one.date < two.date) return 1;
      return 0;
    }).slice(0, 5).reverse();

    setOwnerData(d);

    const e = sections.map((s: any, i: number) => {
      const sectionTasks = tasks.filter((t: any) =>
        t.section && t.section.name === s
      );
      const activeRecons = sectionTasks.filter((t: any) =>
        t.reconciliations
      )
      const activeReconsAmount = activeRecons.length;
      const reconciledTaskAmount = activeRecons.filter((t: any) =>
        t.isReconciled
      ).length
      const shorten = s.length > 25 ? s.substring(0, 23) + '...' : s
      return ({
        date: shorten,
        rec: reconciledTaskAmount,
        non: activeReconsAmount - reconciledTaskAmount,
        tot: activeReconsAmount
      })
    }).sort((one: any, two: any) => {
      if (one.tot > two.tot) return -1;
      if (one.tot < two.tot) return 1;

      if (one.date > two.date) return -1;
      if (one.date < two.date) return 1;
      return 0;
    }).slice(0, 5).reverse();

    setSectionData(e);
  }, [reconSummary.loading, reconSummary.called, reconSummary.data])

  const handleSectionFilterClick = (c: any) => {
    const section = c.props.children;
    sectionFilter.includes(section)
      ? setSectionFilter(sectionFilter.filter((a: string) => section !== a))
      : setSectionFilter([...sectionFilter, section])
  };

  const handleStatusFilterClick = (c: any) => {
    const status = c.props.children;
    reconStatusFilter.includes(status)
      ? setReconStatusFilter(
        reconStatusFilter.filter((a: string) => status !== a)
      )
      : setReconStatusFilter([...reconStatusFilter, status])
  };

  const navigateToReconSettings = () => {
    if (!listData.id) { return; }
    history.push({
      pathname: '/app/recon_settings',
      state: {
        listId: listData.id,
        lastPage: props.location.state.lastPage
      }
    });
  }

  const navigateToLastMainPage = () => {
    history.push(props.location.state.lastPage || '/app/lists');
  }

  const axis = ownerData && ownerData.length && ownerData.map((o: any) => ({ ...o })).reverse();

  const applySorting = sortingFunctionHelper(activeSort, nameSort, dueDateSort, differenceSort, primarySort, secondarySort);

  const [bulkUpdateIntacctReconsMutation, { loading: bulkUpdateIntacctReconsMutationLoading, data: bulkUpdateIntacctReconsMutationData, error: bulkUpdateIntacctReconsMutationError }] = useBulkUpdateIntacctRecons({ listId: listId });

  const debouncedSave = useRef(_.debounce(() => bulkUpdateIntacctReconsMutation(), 3000, { leading: true, trailing: false })).current

  const refreshIntacctHandler = () => {
    debouncedSave()
  }

  const handlReconSnackBarClose = () => {
    setReconSnackbarOpen(false);
    setReconRefreshSucces("success");
    setReconRefreshMessage("Reconciliation values are being refreshed.")
  }

  const [reconSnackbarOpen, setReconSnackbarOpen] = useState<boolean>(false);
  const [reconRefreshSuccess, setReconRefreshSucces] = useState<Color>("success");
  const [reconRefreshMessaage, setReconRefreshMessage] = useState<string>("Reconciliation values are being refreshed.")

  useEffect(() => {

    //Loading Action
    if (bulkUpdateIntacctReconsMutationLoading) {
      //Currently does nothing
    }

    //Error Action
    if (bulkUpdateIntacctReconsMutationError) {
      setReconSnackbarOpen(true);
      setReconRefreshSucces("error");
      setReconRefreshMessage("There was an error refreshing your reconciliation values")
    }

    //Success Action
    if (bulkUpdateIntacctReconsMutationData) {
      setReconSnackbarOpen(true);
      setReconRefreshSucces("success");
      setReconRefreshMessage("Reconciliation values are being refreshed!")
    }
  }, [bulkUpdateIntacctReconsMutationData, bulkUpdateIntacctReconsMutationError, bulkUpdateIntacctReconsMutationLoading])

  const downloadProjectProgressReport = () => {
    dispatch({
      type: 'SET_NOTIFICATION',
      notification: {
        variant: 'success',
        message: 'We have received your request and are preparing your report for export.',
      }
    });

    getProjectProgressReport().then((response: any) => {
      const url = response.data.list.projectProgressReport;

      if(url){
        window.open(url, '_blank');
      }
    })
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={reconSnackbarOpen}
        autoHideDuration={3000}
        onClose={handlReconSnackBarClose}
      >
        <Alert severity={reconRefreshSuccess}>{reconRefreshMessaage}</Alert>
      </Snackbar>
      <Header
        onClose={navigateToLastMainPage}
        onClickBack={navigateToLastMainPage}
      />

      <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 50px 0px 50px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h2'>
            {`${idx(listData, (l: any) => l.name)}\u00A0\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0\u00A0`}
          </Typography>
          <Typography style={{ marginRight: 16 }} variant='h6'>Reconciliation Summary</Typography>
          {(isRequester === false) && <Button onClick={navigateToReconSettings}>Go To Reconciliation Settings</Button>}
          <Button style={{ border: ' 1px solid ##3A84FF!important', borderRadius: '3px' }} onClick={downloadReport}>Download Report</Button>
          <Button onClick={downloadProjectProgressReport}>Download Project Progress Report</Button>
          {hasIntacctConnection && <Button onClick={refreshIntacctHandler}>Refresh Reconciliation Values</Button>}
        </div>

        <div style={{ marginTop: '24px', display: 'flex', marginBottom: '24px', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: 280 }}>
            <Typography variant='h5'> Reconciliations Complete</Typography>
            <ReconPercent percent={percentReconciled || 0.0} />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: 380, marginRight: 40 }}>
            <Typography variant='h5'> Reconciliations by Section (Top 5 Sections)</Typography>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <svg
                id='section-chart'
                width={idx(sectionData, s => s.length) ? 500 : 0}
                height={idx(sectionData, o => o.length) ? ((sectionData.length) * 40 + 30) : 0}
                ref={sectionContainer}
                style={{ cursor: 'default' }}
              />
              {idx(sectionData, s => s.length === 0) &&
                <Typography>No data</Typography>
              }
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: 500, marginLeft: 20 }}>
            <Typography variant='h5'> Reconciliations by Task Owner (Top 5 Task Owners)</Typography>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 24 }}>
                  {!!axis && axis.map((o: any, i: number) => <div key={i} style={{ height: 24, width: 40, marginBottom: 7, display: 'flex', alignItems: 'center', justifyContent: 'center' }}><UserImage user={o} style={{ width: 20, height: 20, fontSize: 10 }} /></div>)}
                </div>
                <svg
                  id='owner-chart'
                  width={idx(ownerData, o => o.length) ? 540 : 0}
                  height={idx(ownerData, o => o.length) ? ((ownerData.length) * 40 + 30) : 0}
                  ref={ownerContainer}
                />
              </div>
              {idx(ownerData, o => o.length === 0) &&
                <Typography>No data</Typography>
              }
            </div>
          </div>
        </div>

        {reconSummary.loading
          ? <div style={{ textAlign: 'center' }}>
            <CircularLoader height={'50vh'} />
          </div>
          :
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <HeaderCell
                  onClick={updateNameSort}
                  label='Name'
                  isActive={activeSort === 'name'}
                  className={classes.leftAlign}
                />
                <TableCell
                  className={classes.showIconOnHover}
                  onClick={() => setShowSectionFilter(true)}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 48 }}>
                    <span>Section</span>
                    <ArrowDropDown
                      style={{
                        visibility: sectionFilter.length ? 'visible' : undefined,
                        color: sectionFilter.length ? COLORS.primary : 'black'
                      }}
                    />
                    <div style={{ position: 'relative' }}>
                      {showSectionFilter &&
                        <ClickAwayListener onClickAway={() => setShowSectionFilter(false)}>
                          <div style={{ position: 'absolute', left: -11, top: 24 }}>
                            <SectionFilterBox
                              options={
                                sections.map((s: any, i: number) => <Typography key={i}>{s}</Typography>)
                              }
                              handleClick={handleSectionFilterClick}
                              handleClickAll={() => setSectionFilter([])}
                              selected={sectionFilter}
                            />
                          </div>
                        </ClickAwayListener>
                      }
                    </div>
                  </div>
                </TableCell>

                <HeaderCell
                  onClick={updateDueDateSort}
                  label='Due Date'
                  isActive={activeSort === 'dueDate'}
                />
                <TableCell
                  className={classes.showIconOnHover}
                  onClick={() => setShowStatusFilter(true)}
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 48 }}>
                    <span>Status</span>
                    <ArrowDropDown style={{
                      visibility: reconStatusFilter.length ? 'visible' : undefined,
                      color: reconStatusFilter.length ? COLORS.primary : 'black'
                    }}
                    />
                    <div style={{ position: 'relative' }}>
                      {showStatusFilter &&
                        <ClickAwayListener onClickAway={() => setShowStatusFilter(false)}>
                          <div style={{ position: 'absolute', left: -11, top: 24 }}>
                            <SectionFilterBox
                              options={[
                                <Typography key={1} style={{ color: 'green', fontWeight: 'bold' }}>Reconciled</Typography>,
                                <Typography key={2} style={{ color: 'orange', fontWeight: 'bold' }}>Not Reconciled</Typography>
                              ]}
                              handleClick={handleStatusFilterClick}
                              handleClickAll={() => setReconStatusFilter([])}
                              selected={reconStatusFilter}
                            />
                          </div>
                        </ClickAwayListener>
                      }
                    </div>
                  </div>
                </TableCell>

                <HeaderCell
                  onClick={updateDifferenceSort}
                  label='Difference'
                  isActive={activeSort === 'difference'}
                />
                <HeaderCell
                  onClick={updatePrimarySort}
                  label='Primary Source'
                  isActive={activeSort === 'primary'}
                  className={classes.primarySource}
                />
                <HeaderCell
                  onClick={updateSecondarySort}
                  label='Secondary Source'
                  isActive={activeSort === 'secondary'}
                  className={classes.secondarySource}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.sort((task1: any, task2: any) => Number(task1.listNumber) - Number(task2.listNumber))
                .filter((task: any) => sectionFilter.length
                  ? sectionFilter.includes(idx(task, t => t.section.name))
                  : true
                ).filter((task: any) => {
                  const status = task.isReconciled ? 'Reconciled' : 'Not Reconciled'
                  return reconStatusFilter.length
                    ? reconStatusFilter.includes(status)
                    : true
                }).sort(applySorting)
                .map((task: any, index: number) =>
                  <ReconSummaryRow scrollShow={refactor} task={task} key={index} />
                )}
              {tasks.length === 0 && <Typography style={{ marginTop: 20 }}>You have no active reconciliations</Typography>}
            </TableBody>
          </Table>
        }
      </div>
    </>
  )
}

function ReconSummaryRow(props: any) {
  const { task } = props;
  const classes = useStyles();
  const setFilters = useSetFilters();
  const history = useHistory();
  const state = useStateContext();
  const dispatch = useDispatchContext();
  const dateFormat = { month: 'short', day: 'numeric', year: 'numeric' };
  const formattedDate = new Date(task.dueDate).toLocaleDateString(undefined, dateFormat);

  const navigateToTask = () => {
    //ADD DISPATCH HERE FOR STATS
    setFilters({
      taskPageLists: [task.list.id],
      customTaskPageSelectedTaskIds: [task.id]
    }, { resetTaskFilters: true })

    //Currently does not work, because the main layout is unmounted
    dispatch({
      type: 'UPDATE_PROJECT_SELECTION_OPTONS',
      selectedListIds: [task.list.id],
      selectedSectionIds: null
    })

    dispatch({
      type: 'SET_TASK_PAGE_GROUPING',
      taskPageGrouping: state.currentUser.filters.taskPageGroupBySort,
    });

    setTimeout(() => history.push('/app/newtasks'), 300);
  }

  const openLink = (sourceType: string) => {
    const hasLink = idx(task, (t: any) =>
      t[sourceType + 'Source'].fileVersion.fileLocation
    );
    if (hasLink) { window.open(hasLink) }
  }

  //These will probably need to change in the future
  const primaryFileName = idx(task, (t: any) =>
    t.primarySource.fileVersion.fileName
  )
  const secondaryFileName = idx(task, (t: any) =>
    t.secondarySource.fileVersion.fileName
  )

  const tableCellStyling = task.tags.length ? classes.reconTableCell : '';
  const paddingTopStyling = task.tags.length ? '8px' : '0px';

  
  return (
    <TableRow>
      <TableCell className={tableCellStyling} style={{ width: 550 }} onClick={navigateToTask}>
        <Tooltip title={task.name} aria-label="task-name" classes={{ tooltip: classes.tooltipFont }}>
          <Typography className={classes.ellipsis} style={{ maxWidth: 300, paddingTop: paddingTopStyling }}>
            {task.name}
          </Typography>
        </Tooltip>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 450, marginBottom: '6px' }}>
          <EntityBadges entities={task.tags} trimmed={false} containerStyles={{ margin: '2px 2px 2px 0px' }} />
        </div>
      </TableCell>
      <TableCell className={tableCellStyling} style={{ width: 200 }}>
        <div className={classes.flexCenter} style={{ justifyContent: 'flex-start' }}>
          <Typography className={classes.ellipsis} style={{ maxWidth: 240, fontStyle: idx(task, t => t.section.name) ? 'none' : 'italic', paddingTop: paddingTopStyling }}>
            {idx(task, t => t.section.name) || 'No Section'}
          </Typography>
        </div>
      </TableCell>
      {(true || !props.scrollShow) && <TableCell style={{ width: 200 }} className={tableCellStyling}>
        <div className={classes.flexCenter} >
          <Typography style={{ fontStyle: task.dueDate ? 'none' : 'italic', paddingTop: paddingTopStyling }}>
            {task.dueDate ? formattedDate : 'No Due Date'}
          </Typography>
        </div>
      </TableCell>}
      <TableCell style={{ width: 200 }} className={tableCellStyling}>
        <div className={classes.flexCenter} >
          {task.isReconciled
            ? <Typography style={{ color: 'green', fontWeight: 'bold', paddingTop: paddingTopStyling }}>Reconciled</Typography>
            : <Typography style={{ color: 'orange', fontWeight: 'bold', paddingTop: paddingTopStyling }}>Not reconciled</Typography>
          }
        </div>
      </TableCell>
      <TableCell style={{ minWidth: 190 }} className={tableCellStyling}>
        <div className={useStyles().flexCenter} style={{ justifyContent: 'space-between' }}>
          <Typography style={{ paddingTop: paddingTopStyling }}>$</Typography>
          {task.reconDifference && Number(task.reconDifference)
            ? <MoneyDisplay value={task.reconDifference} style={{ paddingTop: paddingTopStyling }} displaySymbol customSymbol={task.currency} />
            : <Typography style={{ marginRight: 14, paddingTop: paddingTopStyling }}>-</Typography>
          }
        </div>
      </TableCell>
      <TableCell style={{ minWidth: 200, borderLeft: '1px solid rgba(128, 128, 128, .3)' }} onClick={() => openLink('primary')} className={tableCellStyling}>
        <div className={useStyles().flexCenter} style={{ cursor: "pointer", justifyContent: 'space-between', paddingTop: paddingTopStyling }}>
          <p className='typography !leading-[16px]'>
            {displayCurrency(task.currency)}
          </p>
          <Tooltip 
            title={primaryFileName
              ? `Primary source: ${primaryFileName} \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0   (click to download)`
              : 'No associated file for this source'
            }
            placement='right'
          >
            <div style={{ width: 'fit-content' }}>
              {Number(task.primaryTotal)
                ? <MoneyDisplay
                  value={task.primaryTotal || '0'}
                  style={{ color: COLORS.primary }}
                  customSymbol={task.currency}
                  displaySymbol
                />
                : <Typography style={{ marginRight: 14, paddingTop: paddingTopStyling }}>-</Typography>
              }
            </div>
          </Tooltip>
        </div>
      </TableCell>
      <TableCell style={{
        minWidth: 200,
        borderRight: '1px solid rgba(128, 128, 128, .3)',
        borderLeft: '1px solid rgba(128, 128, 128, .3)',
      }}
        onClick={() => openLink('secondary')}
        className={tableCellStyling}>
        <div className={useStyles().flexCenter} style={{ cursor: "pointer", justifyContent: 'space-between', paddingTop: paddingTopStyling }}>
          <p className='typography !leading-[16px]'>
            {displayCurrency(task.currency)}
          </p>
          <Tooltip
            title={secondaryFileName
              ? `Secondary source: ${secondaryFileName}`
              : 'No associated file for this source'
            }
            placement='right'
          >
            <div style={{ width: 'fit-content' }}>
              {Number(task.secondaryTotal)
                ? <MoneyDisplay
                  value={task.secondaryTotal || '0'}
                  style={{ color: COLORS.primary }}
                  customSymbol={task.currency}
                  displaySymbol
                />
                : <Typography style={{ marginRight: 14 }}>-</Typography>
              }
            </div>
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  );
}

function SectionFilterBox(props: any) {
  return (
    <Paper style={{ boxShadow: 'none', display: 'flex', flexDirection: 'column', border: '1px solid #CACACA' }}>
      <List>
        <ListItem>
          <Typography variant='h6' onClick={props.handleClickAll}>Select All</Typography>
        </ListItem>
        {props.options.map((o: any, i: number) =>
          <ListItem key={i} style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => props.handleClick(o)} tabIndex={0}>
            {o}
            <Check style={{ marginLeft: 8, visibility: props.selected.includes(o.props.children) ? 'inherit' : 'hidden' }} />
          </ListItem>
        )}
      </List>
    </Paper>
  )
}

interface HeaderCellProps {
  isActive: boolean;
  label: string;
  className?: string;
  onClick?: any
}

function HeaderCell(props: HeaderCellProps) {
  const classes = useStyles();
  return (
    <TableCell
      className={clsx(classes.showIconOnHover, props.className)}
      onClick={props.onClick}
    >
      <div
        className={classes.flexCenter}
        style={{ height: 48 }}
      >
        <span style={{ whiteSpace: 'nowrap' }}>{props.label}</span>
        <ArrowDropDown
          style={{
            visibility: props.isActive ? 'visible' : undefined,
            color: props.isActive ? COLORS.primary : 'black'
          }}
        />
      </div>
    </TableCell>);
}
