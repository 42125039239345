import React from 'react'
import ProjectsNumber from '../ProjectTypesStep/components/ProjectsNumber';
import ProjectDetailStep from './components/ProjectDetailStep'
import { IProjectNumber, IProjectType, IProjectOptionsSelected, IProject, INewCompanyType } from '../common/interfaces';
import { TemplateLists_templateLists } from '../../../../../graphql/queries/__generated__/TemplateLists';
import ProjectDetailRecon from './components/ProjectDetailRecon';
interface IProjectDetailsStep {
	activeSubStep: number;
	submitTitle: string;
	isAdmin: boolean;
	newTemplate: IProject;
	setNewTemplate: React.Dispatch<React.SetStateAction<IProject>>;
	externalCompanyId: string;
	selectedTemplate: any;
	projectOptionsSelected: IProjectOptionsSelected;
	setProjectOptionsSelected: React.Dispatch<React.SetStateAction<IProjectOptionsSelected>>;
	setSelectedTemplate: React.Dispatch<React.SetStateAction<any>>;
	reconSettingsClicked(): void;
	inviteCompany: INewCompanyType;
	setInviteCompany: React.Dispatch<React.SetStateAction<INewCompanyType>>;
	additionalInternalOwners: any[];
	additionalExternalOwners: any[];
	setAdditionalInternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
	setAdditionalExternalOwners: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function ProjectTypesStep(props: IProjectDetailsStep) {
	const { activeSubStep, submitTitle, isAdmin, projectOptionsSelected, externalCompanyId, setProjectOptionsSelected,
		newTemplate, setNewTemplate, selectedTemplate, setSelectedTemplate, reconSettingsClicked, inviteCompany, setInviteCompany,
		additionalInternalOwners, additionalExternalOwners, setAdditionalInternalOwners, setAdditionalExternalOwners } = props;

	const updateListRecon = (field: string, value: string) => {
		var newValue;
		field === 'type'
			? newValue = { reconciliations: value }
			: newValue = { reconEndDate: new Date(value) }
		setNewTemplate({ ...newTemplate, ...newValue })
		if (field === 'type') {
			const recon = Boolean(Number(value)) ? '1' : '0'
			setSelectedTemplate({ ...selectedTemplate, tasks: selectedTemplate.tasks.map((t: any) => ({ ...t, reconciliations: recon })) })
		}
	}

	// pass updated task, will use the name to find the task within the selectedtemplate
	// object, and add the new properties.
	const updateTaskRecon = (task: any) => {
		const copy = {
			...selectedTemplate,
			tasks: selectedTemplate.tasks.map((t: any) => {
				return t.name === task.name ? ({ ...t, ...task }) : t
			})
		}
		setSelectedTemplate(copy);
	}

	const updateAllTasks = (key: any, value: any) => {
		const copy = {
			...selectedTemplate,
			tasks: selectedTemplate.tasks.map((t: any) =>
				({ ...t, [key]: value })
			)
		}
		setSelectedTemplate(copy);
	}

	return (
		<>
			{
				activeSubStep === 0 &&
				<ProjectDetailStep submitTitle={submitTitle} isAdmin={isAdmin} externalCompanyId={externalCompanyId} selectedTemplate={selectedTemplate} newTemplate={newTemplate}
					projectOptionsSelected={projectOptionsSelected} setProjectOptionsSelected={setProjectOptionsSelected} setNewTemplate={setNewTemplate}
					updateListRecon={updateListRecon} reconSettingsClicked={reconSettingsClicked} inviteCompany={inviteCompany} setInviteCompany={setInviteCompany}
					additionalInternalOwners = {additionalInternalOwners} setAdditionalInternalOwners = {setAdditionalInternalOwners}
					additionalExternalOwners={additionalExternalOwners} setAdditionalExternalOwners={setAdditionalExternalOwners} />
			}
			{
				activeSubStep === 1 &&
				<ProjectDetailRecon listData={{ ...selectedTemplate, ...newTemplate }} updateList={updateListRecon} updateTask={updateTaskRecon} updateAllTasks={updateAllTasks} />
			}
		</>
	)
}
